<lp-ui-accordion id="{{id}}">
  <lp-ui-accordion-group heading="metadataApplicationItemDetails.locationSectors.titleTab" [open]=true>
    <form id="formLocationSectors" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>

      <!--<div class="form-group col-xl-12">
        <lp-label-field for="sector.sector" class="{{arrayClassesElements.get('defaultLabel')}}" text="locations.locationsSectors.title"></lp-label-field>
        <lp-zoom-field id="sector.sector" name="sector.sectorr" zoomId="sector" [(ngModel)]="data.sector"
        class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField form-control">
        </lp-zoom-field>
      </div> -->

      <div class="text-end">
        <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
      </div>
    </form>
  </lp-ui-accordion-group>

  <lp-ui-accordion-group heading="Debugger Mon Composant" *ngIf="debugg">
    <div>
      <pre>{{ data | json }}</pre>
    </div>
  </lp-ui-accordion-group>
</lp-ui-accordion>