import { OnDestroy, Component, OnInit } from '@angular/core';
import { UiSyncService } from '../../services/ui-sync.service';
import { Subscription } from 'rxjs';
import { Util } from 'app/statics/utils';

@Component({
  selector: 'lp-ui-zoom-image',
  templateUrl: './lp-ui-zoom-image.component.html'
})
export class LpUiZoomImageComponent implements OnInit, OnDestroy {
  public url: String;
  private subscription: Subscription;

  constructor(protected uiSyncService: UiSyncService) {}

  public ngOnInit(): void {
    this.subscription = this.uiSyncService.displayZoomImageEvtEmitter.subscribe((url: String) => {
      this.url = url;
    });
  }

  public ngOnDestroy(): void {
    if (!Util.isNullOrUndefined(this.subscription)) { this.subscription.unsubscribe(); }
  }

  public hideZoom(): void {
    this.url = undefined;
  }

}
