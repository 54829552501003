import { Component, Inject } from '@angular/core';
import * as _ from 'underscore'
import { LpModalComponent } from '../lp-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormStackService } from 'app/services/form-stack.service';
import { LpVisualSettings } from 'app/meta/visual-settings';
import { VisualSettingsProvider } from 'app/providers/visual-settings.provider';
import { UserService } from 'app/services/user.service';

@Component({
  selector: 'lp-modal-visual-settings',
  templateUrl: './lp-modal-visual-settings.component.html'
})
export class LpModalVisualSettingsComponent extends LpModalComponent {
  public visualSettings: LpVisualSettings;

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formstackService: FormStackService,
    private visualSettingsProvider: VisualSettingsProvider,
    private userService: UserService
  ) {
    super(dialogRef, data);
  }

  public async save(): Promise<void> {
    await this.visualSettingsProvider.putVisualSettingsByAgencyId(this.formstackService.VisualSettings, '*');
    await this.userService.setVisualSettings(this.formstackService.VisualSettings);
  }



}
