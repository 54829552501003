import { LpMeta, propertyMeta } from './meta';
import { FtMeta } from './ft-meta';
import { LpPaymentMethod } from './payment-method';
import { LpAuxiliaryAccount } from './auxiliary-account';
import { LpMainAccount } from './main-account';
import { LpCompany } from './company';
import { Type } from 'class-transformer';

export class LpAccounting extends LpMeta {
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('string')
    public term: String;
    @Type(() => FtMeta)
    public thirdPartyType: FtMeta = new FtMeta();
    @Type(() => LpAuxiliaryAccount)
    public auxiliaryAccount: LpAuxiliaryAccount = new LpAuxiliaryAccount();
    @Type(() => LpPaymentMethod)
    public paymentMethod: LpPaymentMethod = new LpPaymentMethod();
    @Type(() => LpMainAccount)
    public collectiveAccount: LpMainAccount = new LpMainAccount();
    @Type(() => FtMeta)
    public documentType: FtMeta = new FtMeta();
    @Type(() => LpCompany)
    public company: LpCompany = new LpCompany();
}
