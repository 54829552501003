<div class="modal-header">
  <h4 class="modal-title pull-left">{{'preferences.showWidgetPreview' | translate}}</h4>

  <div class="pull-right">
    <lp-button-cancel (action)="close()"></lp-button-cancel>
  </div>
</div>
<div class="modal-body">
  <div class="row">
    <div *ngIf="sanitizedUrl">
      <iframe [src]="sanitizedUrl" width="100%" height="100%" style="min-height: 600px;"></iframe>
    </div>
  </div>
  <!--<lp-ui-accordion class="row" [menu]=true>
    <lp-ui-accordion-group heading="dashboard.general" [open]=true key="general" >
      <div>
        <div class="row">
          <lp-label-field for="dashboardName" class="{{arrayClassesElements.get('defaultLabel')}}" text="{{'widget.title' | translate}}"></lp-label-field>
          <lp-text-field readonly="true" [ignoreChange]="true" id="widgetTitle" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
            cssClass="form-control" name="widgetTitle" [(ngModel)]="widget.title"></lp-text-field>
        </div>
        <div class="row">
          <lp-label-field for="dashboardName" class="{{arrayClassesElements.get('defaultLabel')}}" text="{{'widget.description' | translate}}"></lp-label-field>
          <lp-text-field readonly="true" [ignoreChange]="true" id="widgetName" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
            cssClass="form-control" name="widgetName" [(ngModel)]="widget.description"></lp-text-field>
        </div>
      </div>
    </lp-ui-accordion-group>
  </lp-ui-accordion>-->
</div>