import { Component, OnInit, Input } from '@angular/core';
import { AbstractItemComponent } from '../abstract-item.component';

@Component({
  selector: 'lp-panel',
  templateUrl: './panel.component.html'
})
export class PanelComponent extends AbstractItemComponent implements OnInit {

  // Le paramètre CssClass permet d'attribuer aux balises du composant, des noms de classe spécifiques css.
  @Input() public role: String;

  public ngOnInit(): void {
    super.ngOnInit();
  }

}
