import { LpMeta, propertyMeta } from './meta';

export class LpPricing extends LpMeta {
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('string')
    public code: String
}


