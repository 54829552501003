import { Component, OnInit, Output, Input, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateFieldComponent } from '../date-field.component';
import { Util } from 'app/statics/utils';

@Component({
  selector: 'lp-time',
  templateUrl: './time.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeComponent),
      multi: true }
  ]
})
export class TimeComponent extends DateFieldComponent implements OnInit {

  /**Le paramètre Name permet de donner un nom personnalisé au composant.
   *
   */
 @Input() public name: string;

  /**Le paramètre InputText sera le nom du champ dans lequel les données seront émises.
   */
  @Input() public inputText: string;

  /**Le paramètre Rows permet de régler la hauteur.
   */
  @Input() public rows: string;

  /**Le paramètre Type permet de configurer le type des balises HTML qui viennent constituer le composant.
   */
  @Input() public type: String = 'text';



  /**Le output TextFieldEmitter va permettre l'exécution de la fonction qui lui est associée.
   * La fonction associée est déclarée dans le template où le composant est utilisé.
   */
  @Output() public textFieldEmitter: EventEmitter<any> = new EventEmitter();

  public ngOnInit(): void {
    super.ngOnInit();
    this.inputText = this.name;
  }

  public setPreviousValue(event: any): void {
    this.previousValue = event.srcElement.value;
  }

  public pushChange(event?: any): void {
    super.pushChange();
    if (this.previousValue && this.previousValue !== event.srcElement.value
        && (Util.isNullOrUndefined(this.ignoreChange) || !this.ignoreChange)) {
        this.setHasChanged(true, this);
    }
    this.setDisplayValue(this.displayValue);
  }
}
