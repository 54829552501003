<div style="width:100%;height:100%">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'memos.titleList' | translate}}
      <sup *ngIf="changeService.getHasChanged()" class="cursor-pointer" title="{{'general.modificationNotSave' | translate}}"> *</sup>
    </h4>


    <div class="pull-right">
      <lp-button-new (action)="newMemos()"></lp-button-new>
      <lp-button-cancel (action)="checkAndConfirmChanges()"></lp-button-cancel>
    </div>
  </div>
  <div class="modal-body">
    <div class="col-4  pe-5 memo-list-tab">

      <ngx-datatable *ngIf="memos" class="material memos" [rows]="memos" [columnMode]="'force'"
        [headerHeight]="35" [footerHeight]="35" [rowHeight]="'auto'" [messages]="{emptyMessage: 'memos.emptyList' | translate, totalMessage: 'general.totalItemsList' | translate }"
        [externalPaging]="true" [limit]="memosPerPage" (page)='changePage($event)' [offset]="actualPageNumber - 1" [count]="nbMemos">

        <ngx-datatable-column 
          *ngFor="let column of columns" 
          name="{{column.name | translate}}" 
          prop="{{column.prop}}"
          [width]="(column.prop ==='action') ?  50 : ''" 
          [resizeable]="false" 
          [sortable]="false" 
          [draggable]="false" 
          [canAutoResize]="(column.prop ==='action') ?  false : true">
          <ng-template let-row="row" ngx-datatable-cell-template >
            <div *ngIf="column.prop !== 'action'" class="p-2" (click)="selectItem(row);"
             [class.current-memo]="currentMemo && currentMemo.id === row.id && column.prop !== 'action'">
              {{row.attachmentKind.wording}}
            <span *ngIf="row['wording'] !== null && row['wording'] !== ''">: {{row.wording}}</span>
            </div>
            <div *ngIf="column.prop == 'action'" class="table-icon p-2">
              <i class="icon vega-dustbin" (click)="deleteMemo(row.id);"></i>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
    </div>
    <form  #form="ngForm" ngNativeValidate *ngIf="!loading" class="col-8 border-left">
      <div *ngIf="currentMemo && formstackService.specificApplicationItem && !formstackService.specificApplicationItem.useJsonEditForm">
        <div class="col-6">
          <div class="form-group col-12" *ngIf="currentMemo?.date">
            <lp-label-field class="control-label input-smcol-md-5  col-xl-3" text="memos.date"></lp-label-field>
            <lp-text-field className="textField" name="memos.date" class="col-md-10 col-lg-8 col-xl-7" cssClass="form-control" [ngModel]="datetimeService.dateTimeFormat(currentMemo.date)" [readonly]=true></lp-text-field>
          </div>
          <div class="form-group col-12">
            <lp-label-field for="attachmentKind" [required]="true" class="control-label input-smcol-md-5  col-xl-3"
              text="memos.attachmentKind"></lp-label-field>
              <lp-dropdown-field class="col-md-10 col-lg-8 col-xl-7" className="textField" cssClass="form-control" id="attachmentKind"
              name="memos.attachmentKind" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'attachmentKind'},{'key':'linkedObject', 'value': formstackService.currentApplicationItem.linkedObject }]}"
              [(ngModel)]="currentMemo.attachmentKind"></lp-dropdown-field>
          </div>
          <div class="form-group col-12">
            <lp-label-field for="wording" [required]="true" class="control-label input-smcol-md-5  col-xl-3"
              text="memos.wording"></lp-label-field>
            <lp-text-field id="wording" className="textField" class="col-md-10 col-lg-8 col-xl-7" cssClass="form-control"
              name="memos.wording" [(ngModel)]="currentMemo.wording" [required]=true
              type="text"></lp-text-field>
          </div>
          <div class="form-group col-12  d-none">
            <lp-label-field for="documentNumber" class="control-label input-smcol-md-5  col-xl-3"
              text="memos.documentNumber"></lp-label-field>
            <lp-text-field id="documentNumber" className="textField" class="col-md-10 col-lg-8 col-xl-7" cssClass="form-control"
              name="memos.documentNumber" [(ngModel)]="currentMemo.documentNumber" id="documentNumber"
              type="text"></lp-text-field>
          </div>
          <div class="form-group col-12">
            <lp-label-field for="txt" class="control-label input-smcol-md-5 col-xl-3" text="memos.txt"></lp-label-field>
            <lp-textarea-field id="txt" className="textField" class="col-md-10 col-lg-8 col-xl-7" cssClass="form-control"
              name="memos.txt" rows="5" [(ngModel)]="currentMemo.txt"></lp-textarea-field>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group col-12">
            <div class="col-12">
              <lp-drop-file [ignoreChange]="true" label='memos.dragFile' name="memos.file" filename="memo" (uploaded)="uploadedFile($event)" [isBinding]="false"></lp-drop-file>
            </div>
          </div>
          <div class="form-group col-12">
            <lp-file-carousel [(ngModel)]="currentMemo._files" id="_files" name="_files"></lp-file-carousel>
          </div>
        </div>
      </div>
        
      <lp-dynamic-main-form *ngIf="currentMemo && formstackService.specificApplicationItem && formstackService.specificApplicationItem.useJsonEditForm" [data]="currentMemo" [specificApplicationItem]="true" (uploaded)="uploadedFile($event)"></lp-dynamic-main-form>
      
      <div class="col-12" *ngIf="currentMemo">
        <button class="transparent-btn pull-right mr-5 mb-4" (click)="saveMemo(form.valid)">
          <i class="vega-diskette"></i>
        </button>
      </div>     
    </form>    
  </div>
</div>