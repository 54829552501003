<lp-label-field *ngIf="label" for="{{id}}" class="control-label" [hidden]="this.formStackService.getFields(name).hidden"
[ignoreChange]="ignoreChange" text="{{label}}"  [required]="this.formStackService.getFields(name).mandatory"></lp-label-field>

<div class="uploadfilecontainer" appDragDrop [class.dragOver]="uiSyncService.dragOver">
    <input hidden type="file" #fileInput (change)="pushChange($event, $event.target.files)" title="" [(ngModel)]="fileInpuText">
    <div class="btnUpload text-center pt-3 pb-3" *ngIf="!displayValue || displayValue == ''">
            {{label | translate}}<br/>
        <span class="btn btn-primary mt-2" (click)="fileInput.click()">{{'general.button.chooseFile' | translate}}</span>

    </div>
    <div *ngIf="displayValue" class="p-2">
        <span class="btn col-12 btn-primary btn-download mb-4" (click)="downloadFile()">{{'general.button.downloadFile' | translate}}</span>
        <span class="btn col-12 btn-outline-danger btn-delete" (click)="deleteAttachment()">{{'general.button.removeFile' | translate}}</span>
    </div>
</div>