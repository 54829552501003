<lp-ui-accordion id="{{id}}">
    <lp-ui-accordion-group heading="metadataApplicationItemDetails.financialElementsLinks.titleTab" [open]=true>
      <form id="formFinancialElementsLinks" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>

        <div class="form-group col-xl-12">
          <lp-label-field for="financialElementsLinks.financialType" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="damages.financialType"></lp-label-field>
          <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control"
                name="financialElementsLinks.financialType" [(ngModel)]="data.financialType" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'financialType'}]}">
          </lp-dropdown-field>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="financialElementsLinks.thirdParty" class="{{arrayClassesElements.get('defaultLabel')}}"
               text="damages.thirdParty"></lp-label-field>
          <lp-zoom-field id="financialElementsLinks.thirdParty" name="financialElementsLinks.thirdParty" zoomId="financialElementsLinks.thirdParty"
          class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField form-control" [(ngModel)]="data.thirdParty"> </lp-zoom-field>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="financialElementsLinks.date" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.date"></lp-label-field>
          <lp-date [(ngModel)]="data.date" class="{{arrayClassesElements.get('defaultInput')}}" id="financialElementsLinks.date"
          cssClass="form-control" name="financialElementsLinks.date" placeholder="damages.date"
          [required]=true></lp-date>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="financialElementsLinks.hasEstimate" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.hasEstimate"></lp-label-field>
          <lp-boolean-switch-box name="financialElementsLinks.hasEstimate" switchName="hasEstimate" id="financialElementsLinks.hasEstimate" [(ngModel)]="data.hasEstimate"></lp-boolean-switch-box>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="financialElementsLinks.wording" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.wording"></lp-label-field>
          <lp-text-field id="financialElementsLinks.wording" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="financialElementsLinks.wording"
             [(ngModel)]="data.wording" placeholder="damages.wording"></lp-text-field>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="financialElementsLinks.responsabilities" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.responsabilities"></lp-label-field>
          <lp-number id="financialElementsLinks.responsabilities" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="financialElementsLinks.responsabilities"
             [(ngModel)]="data.responsabilities"></lp-number>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="financialElementsLinks.income" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.income"></lp-label-field>
          <lp-number id="financialElementsLinks.income" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="financialElementsLinks.income"
            [(ngModel)]="data.income"></lp-number>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="financialElementsLinks.isExclusionExportation" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.isExclusionExportation"></lp-label-field>
          <lp-boolean-switch-box name="financialElementsLinks.isExclusionExportation" switchName="isExclusionExportation" id="financialElementsLinks.isExclusionExportation" [(ngModel)]="data.isExclusionExportation"></lp-boolean-switch-box>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="financialElementsLinks.referenceA" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.referenceA"></lp-label-field>
          <lp-text-field id="financialElementsLinks.referenceA" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="financialElementsLinks.referenceA"
            [(ngModel)]="data.referenceA" placeholder="damages.referenceA"></lp-text-field>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="financialElementsLinks.referenceB" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.referenceB"></lp-label-field>
          <lp-text-field id="financialElementsLinks.referenceB" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="financialElementsLinks.referenceB"
            [(ngModel)]="data.referenceB" placeholder="damages.referenceB"></lp-text-field>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="financialElementsLinks.dateEnd" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.dateEnd"></lp-label-field>
          <lp-date [(ngModel)]="data.dateEnd" class="{{arrayClassesElements.get('defaultInput')}}" id="financialElementsLinks.dateEnd"
          cssClass="form-control" name="financialElementsLinks.dateEnd" placeholder="damages.dateEnd"
          [required]=true></lp-date>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="financialElementsLinks.hasTabNumber" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.hasTabNumber"></lp-label-field>
          <lp-boolean-switch-box name="hfinancialElementsLinks.asTabNumber" switchName="hasTabNumber" id="financialElementsLinks.hasTabNumber" [(ngModel)]="data.hasTabNumber"></lp-boolean-switch-box>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="financialElementsLinks.baseAmount" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.baseAmount"></lp-label-field>
          <lp-number id="financialElementsLinks.baseAmount" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="financialElementsLinks.baseAmount"
            [(ngModel)]="data.baseAmount"></lp-number>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="financialElementsLinks.rateDiscount" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.rateDiscount"></lp-label-field>
          <lp-number id="financialElementsLinks.rateDiscount" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="financialElementsLinks.rateDiscount"
          [(ngModel)]="data.rateDiscount"></lp-number>
        </div>

        <div class="text-end">
          <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
        </div>
      </form>
    </lp-ui-accordion-group>
    <lp-ui-accordion-group heading="Debugger Mon Composant" *ngIf="debugg">
      <div>
        <pre>{{ data | json }}</pre>
      </div>
    </lp-ui-accordion-group>
  </lp-ui-accordion>