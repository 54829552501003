import { Component } from '@angular/core';
import { Accordion } from 'app/models/accordion';
import { EditRows } from 'app/models/edit-rows';
import { SubGroups } from 'app/models/sub-groups';
import { FtProvider } from 'app/providers/ft.provider';
import { FormStackService } from 'app/services/form-stack.service';
import { JsdataService } from 'app/services/jsdata.service';
import { RightColService } from 'app/services/root/rightCol.service';
import { Util } from 'app/statics/utils';

const PATH: string = 'technical-customisation';
const GENERAL_PICTURES_PATH: string = 'technical/ft?object=attachmentKind&filter=PHOTO';
const DEFAULT_TECHNICAL_CUSTOMISATION_AGENCY_ID: string = "*";
@Component({
  selector: 'lp-ihubcheckplus-settings',
  templateUrl: './ihubcheckplus-settings.component.html'
})
export class iHubCheckPlusSettingsComponent {

  public isADefaultTechnicalCustomisation: boolean = true;
  public generalPictures: Array<any> = new Array<any>();
  public generalPicturesMap: Map<string, any> = new Map<string, any>();
  public generalPicturesOfUserMap: Map<string, any> = new Map<string, any>();


  constructor (
      public formStackService: FormStackService,
      private jsdataService: JsdataService,
      private rightColService: RightColService,
      private ftProvider : FtProvider
    ) {}

  public async ngOnInit(): Promise<void> {
    await this.setGeneralPicturesMap();
    setTimeout(async() =>{
      this.isADefaultTechnicalCustomisation = this.testDefaultTechnicalCustomisation();
      await this.setGeneralPicturesOfUserMap()
    }, 1000)
  }

  public typeof(value:any) {
    return typeof value;
  }

  public getDataRef(path: any): any {
    return this.jsdataService.getDataRef(this.formStackService.CurrentApplicationItemDetailRepositoryData, path);
  }

  public setDataRef(path: any, value: any): any {
    return this.jsdataService.setDataRef(this.formStackService.CurrentApplicationItemDetailRepositoryData, path, value);
  }

  public isMultipleInputPerLine(fields: Array<EditRows>): boolean {
    return fields.length > 2;
  }

  public async save(): Promise<void> {
    const url: string = await this.buildURL();
    await this.transformBodyForPOSTRequest();
    await this.rightColService.saveDetailRepository(url);
    this.isADefaultTechnicalCustomisation = this.testDefaultTechnicalCustomisation();
  }

  public getRowSize(accordion: Accordion, row: EditRows, subgroup: SubGroups): string {
    if (!Util.isNullOrUndefined(row) && !Util.isNullOrUndefined(row.columns) && row.columns > 0) {
      return 'form-group col-xl-' + Math.ceil(12 / row.columns).toString();
    } else if (!Util.isNullOrUndefined(subgroup) && !Util.isNullOrUndefined(subgroup.columns) && subgroup.columns > 0) {
      return  'form-group col-xl-' + Math.ceil(12 / subgroup.columns).toString();
    } else if (!Util.isNullOrUndefined(accordion) && !Util.isNullOrUndefined(accordion.columns) && accordion.columns > 0) {
      return 'form-group col-xl-' + Math.ceil(12 / accordion.columns).toString();
    }
  }

  public getCssClass(accordion: Accordion, row: EditRows, subgroup: SubGroups): string {
    if (!Util.isNullOrUndefined(row) && !Util.isNullOrUndefined(row.columns) && row.columns === 1) {
      return 'col-lg-11 col-md-7';
    } else if (!Util.isNullOrUndefined(subgroup) && !Util.isNullOrUndefined(subgroup.columns) && subgroup.columns === 1) {
      return 'col-lg-11 col-md-7';
    } else if (!Util.isNullOrUndefined(accordion) && !Util.isNullOrUndefined(accordion.columns) && accordion.columns === 1) {
      return 'col-lg-11 col-md-7';
    } else {
      return 'col-lg-10 col-md-7';
    }
  }

  public testDefaultTechnicalCustomisation(): boolean {
    let result: boolean = false;
    if (
      !Util.isNullOrUndefined(this.formStackService) &&
      !Util.isNullOrUndefined(this.formStackService.CurrentApplicationItemDetailRepositoryData) &&
      !Util.isNullOrUndefined(this.formStackService.CurrentApplicationItemDetailRepositoryData['agencyId']) && 
      this.formStackService.CurrentApplicationItemDetailRepositoryData['agencyId'] === DEFAULT_TECHNICAL_CUSTOMISATION_AGENCY_ID) {
      result = true;
    }
    return result;
  }

  public async reinit(): Promise<void> {
    await this.transformBodyForDeleteRequest();
    await this.rightColService.delete();
    this.isADefaultTechnicalCustomisation = this.testDefaultTechnicalCustomisation();
  }

  public haveThisPicture(id: string): boolean {
    return (this.generalPicturesOfUserMap.get(id)) ? true : false;
  }

  public addOrDeleteGeneralPicture(picture: any): void {
    if (Util.isNullOrUndefined(this.generalPicturesOfUserMap.get(picture.id))) {
      this.generalPicturesOfUserMap.set(picture.id, picture);
    } else {
      this.generalPicturesOfUserMap.delete(picture.id);
    }
    this.formStackService.CurrentApplicationItemDetailRepositoryData['generalPictures'] = this.convertMapToArray(this.generalPicturesOfUserMap);
  }


  private async buildURL(): Promise<string> {
    let url: string = `${PATH}/${this.formStackService.CurrentKy}`;
    if (this.testDefaultTechnicalCustomisation()) {
      url = `${PATH}`;
    }
    return url;
  }

  private async transformBodyForPOSTRequest(): Promise<void> {
    if (this.testDefaultTechnicalCustomisation()) {
      this.formStackService.CurrentApplicationItemDetailRepositoryData.id = null;
      this.formStackService.CurrentApplicationItemDetailRepositoryData.agencyId = this.formStackService.CurrentKy;
    }
  }

  private async transformBodyForDeleteRequest(): Promise<void> {
    if (this.testDefaultTechnicalCustomisation() === false) {
      this.formStackService.CurrentApplicationItemDetailRepositoryData.id = this.formStackService.CurrentKy;
    }
  }

  private async getGeneralPictures(): Promise<any> {
    return (await this.ftProvider.getPaginatedDataFT(GENERAL_PICTURES_PATH, null, null)).body;
  }

  private async setGeneralPicturesMap(): Promise<any> {
    this.generalPictures = await this.getGeneralPictures();
    for (let picture of this.generalPictures) {
      this.generalPicturesMap.set(picture.id, picture)
    }
  }

  private async setGeneralPicturesOfUserMap(): Promise<any> {
    const generalPicturesOfuser = this.formStackService.CurrentApplicationItemDetailRepositoryData['generalPictures'];
    for (let picture of generalPicturesOfuser) {
      this.generalPicturesOfUserMap.set(picture.id, picture)
    }
  }

  private convertMapToArray(map: Map<string, any>): Array<any> {
    const arr = [];
    map.forEach((name, value) => arr.push(name));
    return arr;
  }
}
