import { Component, Input, ChangeDetectorRef, forwardRef } from '@angular/core';
import { AbstractItemComponent } from '../abstract-item.component';
import { Subscription } from 'rxjs';
import { ChangeService } from 'app/services/change.service';
import { EvalService } from 'app/services/eval.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { FormStackService } from 'app/services/form-stack.service';
import { UiSyncService } from 'app/services/ui-sync.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lp-html-field',
  templateUrl: './html-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HTMLFieldComponent),
      multi: true
    }
  ]
})
export class HTMLFieldComponent extends AbstractItemComponent implements ControlValueAccessor {

  /**Le paramètre id permet de configurer un id spécifique pour le composant.
  */
  @Input() public id: string;

  /**Le paramètre Binding représente les données reçus par le composant par l'intermédaire du [(ngModel)].
   */
  @Input() public binding: string;

  /**Le paramètre Name permet de donner un nom personnalisé au composant.
   */
  @Input() public name: string;

  /**Le paramètre InputLabel sera le nom du champ dans lequel les données seront émises.
   */
  @Input() public inputLabel: string;

  /**Le paramètre html représente une alternative au paramètre Binding provenant du [(ngModel)].
   *Soit le label peut prendre comme chaine de caractère Binding, soit il prendra Text.
   */
  @Input() public html: string;

  /**Le paramètre For est une aide à la séléction, ou une référence. Lorsque l'on clique sur le label,
   * grâce à l'intermédiaire du paramètre for, le champs correspondant au label sera automatiquement séléctionné.
   * Démarche de fonctionnement : for = "for[NomDuChampDuFormulaire]"
   */
  @Input() public for: string;

  /**Le paramètre Title permet de configuré un titre au composant.
   */
  @Input() public required: Boolean = false;

  @Input() public hidden: Boolean;

  public displayValue: String;
  protected backendMetadata: Subscription;

  constructor(
    public changeService: ChangeService,
    public evalService: EvalService,
    public formMetadataProvider: FormMetadataProvider,
    public formStackService: FormStackService,
    public uiSyncService: UiSyncService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    super(
      changeService,
      evalService,
      formMetadataProvider,
      formStackService,
      uiSyncService
      );      
  }

  public writeValue(value: any): void {
    this.displayValue = value;
  }

  public propagateChange = (_: any) => { };

  public propagateTouch = (_: any) => { };

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

}
