
import { LpMeta } from 'app/meta/meta';
import { PaginatedData } from 'app/models/paginated-data';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { ChangeService } from 'app/services/change.service';
import { EvalService } from 'app/services/eval.service';
import { ModalService } from 'app/services/modal.service';
import { Util } from 'app/statics/utils';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ChoiceFieldComponent } from '../choice-field.component';
import { FormStackService } from 'app/services/form-stack.service';
import { UiSyncService } from 'app/services/ui-sync.service';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { ConfigService } from 'app/services/config.service';
@Component({
  selector: 'lp-chips',
  templateUrl: './chips.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => chipsComponent),
      multi: true
    }
  ]
})
export class chipsComponent extends ChoiceFieldComponent implements OnInit {
  /**Le paramètre Name permet de donner un nom personnalisé au composant.
   *
   */
  @Input() public name: string;

  /**Le paramètre Checked est le booléen qui sera passé à TRUE, pour venir pré-cocher la checkbox.
   * Le booléen est utilisé dans la fonction checkedBox().
   */
  @Input() public checked: boolean;

  /**Le paramètre BindingKey est le champs principale dont on veut binder la valeur.
   * Le string est utilisé dans la fonction checkedBox() et emit().
   */
  @Input() public bindingKey: string;

  /**Le paramètre add permet d'emettre les données et d'éxécuter la fonction d'ajout de la structure.
   */
  @Output() public add: EventEmitter<any> = new EventEmitter();

  /**Le paramètre remove permet de supprimer les données et d'éxécuter la fonction de suppression de la structure.
   */
  @Output() public remove: EventEmitter<any> = new EventEmitter();

  /**Le paramètre singleCol permet d'afficher les résultats sur 1 colonne (4 par défaut).
   *
   */
  @Input() public singleCol: Boolean = false;

  @Input() public verb: string;
  @Input() public parentData: string;
  @Input() public api: string;
  @Input() protected filter: String;
  @Input() protected object: String;
  @Input() protected prop: String;
  
  @Input() public url: string = "technical/ft";

  protected selected: any[] = [];
  private active: boolean = true;

  constructor(
    public alocproProvider: AlocproProvider,
    public modalService: ModalService,
    public formStackService: FormStackService,
    public uiSyncService: UiSyncService,
    public translate: TranslateService,
    public changeService: ChangeService,
    public evalService: EvalService,
    public formMetadataProvider: FormMetadataProvider,
    public metaFactoryService: MetaFactoryService, 
    protected configService: ConfigService) {
    super(changeService, evalService, formStackService, formMetadataProvider, uiSyncService, metaFactoryService, configService);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.checked = false;
    this.initData();
  }

  public initData(): void {
    this.refreshUpperList().then(() => {
      this.refreshDownList();
    });
  }

  public addItem(event: any): void {
    if (!this.formStackService.isDataReadOnly()) {
      let lpMeta: LpMeta = new LpMeta();
      lpMeta[this.prop.toString()] = {'id': event.id};
      this.alocproProvider.post(`${this.verb}/${this.formStackService.CurrentKy}/${this.api}`, lpMeta).then((id: string) => {
        this.modalService.success(this.translate.instant('general.modalService.additionDone'),
        this.translate.instant('general.modalService.success'));
        this.initData();
        if (!this.ignoreChange) {
          this.setHasChanged(true, this);
        }
      });
    }
  }

  public removeItem(event: any): void {
    if (!this.formStackService.isDataReadOnly()) {
      this.alocproProvider.delete(`${this.verb}/${this.formStackService.CurrentKy}/${this.api}`, event.id).then(() => {
        this.modalService.success(this.translate.instant('general.modalService.deletionCompleted'),
        this.translate.instant('general.modalService.deletion'));
        this.initData();
        if (!this.ignoreChange) {
          this.setHasChanged(true, this);
        }
      });
      }
  }

  public refreshUpperList(): Promise<void> {
    return new Promise<void>( (resolve, reject) => {
      this.alocproProvider.getPaginatedData(`${this.verb}/${this.formStackService.CurrentKy}/${this.api}`, null, null)
      .then((record: PaginatedData) => {
        this.displayValue = record.body;
        resolve();
      });
    });
  }

  public refreshDownList(): Promise<void> {
    return new Promise<void>( (resolve, reject) => {
      let params: Map<string, string>;
      if (this.url == 'technical/ft') {
        params = this.getParams();         
      } else {
        params = new Map();     

      }
      this.alocproProvider.getPaginatedData(this.url, null, params).then((recordAvailable: PaginatedData) => {
        if (!Util.isNullOrUndefined(recordAvailable) && !Util.isNullOrUndefined(recordAvailable.body)) {
          this.values = new Array();
          Object.assign(this.values, recordAvailable.body);
          this.refreshValues();
          resolve();
        }
      });
    });
  }

  private refreshValues(): void  {
    if (!Util.isNullOrUndefined(this.displayValue)) {
      for (let element of this.displayValue) {
        this.values.forEach((v: any, i: number) => {
          if (element[this.prop.toString()].id === v.id) {
            this.values.splice(i, 1);
          }
        });
      }
    }
  }

  private getParams(): Map<string, string> {
    let params: Map<string , string> = new Map();
    if (!Util.isNullOrUndefined(this.object)) {
      params.set('object', this.object.toString());
    }
    if (this.filter) {
      params.set('filter', this.filter.toString());
    }
    if ( this.active === true) {
      params.set('active', 'true');
    }
    return params;
  }

}
