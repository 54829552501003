import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, SHORT_TERM_CONTRACTS_MEDIAS } from '../detail-forms.component';

@Component({
  selector: 'lp-short-term-contracts-medias',
  templateUrl: './short-term-contracts-medias.component.html'
})
export class ShortTermContractsMediasComponent extends DetailFormsComponent implements OnInit {

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.init();
    this.setId(SHORT_TERM_CONTRACTS_MEDIAS);
    this.initUrl();
    this.verb = 'short-term-contracts';
    this.api = 'medias';
    this.object = 'mediaType';
    this.filter = 'F450CD';
    this.prop = 'mediaType';
  }

public  init(): void {
    this.loadObjectRootFormSubscription = this.uiSyncService.loadObjectEvtEmitter.subscribe(() => {
      // this.setInformations();
    });
    // this.data.assign(this.formStackService.currentDetailLineData);
  }
}
