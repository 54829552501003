import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from 'app/services/snack-bar.service';
import { lastValueFrom } from 'rxjs';

const MESSAGE: string = 'general.softwareUpdateAlert';
const UPDATE: string = 'general.softwareUpdateRequest';
const CLOSED: string = 'general.softwareUpdateRequestRefused';

@Component({
  selector: 'lp-snack-bar',
  templateUrl: './lp-snack-bar.component.html'
})
export class LpSnackBarComponent {

  public msg: string = '';
  public updateMsg: string = '';
  public dismissMsg: string = '';

  constructor(
    private translateService: TranslateService,
    private snackBarService: SnackBarService
  ) {}

   ngOnInit(): void {
    this.init();
   }

   private async init(): Promise<void> {
    this.msg = await lastValueFrom(this.translateService.get(MESSAGE));
    this.updateMsg = await lastValueFrom(this.translateService.get(UPDATE));
    this.dismissMsg = await lastValueFrom(this.translateService.get(CLOSED));
   }

  public async dismiss(): Promise<void> {
   await this.snackBarService.dismiss();
  }

  public async updateApplication(): Promise<void> {
    await this.snackBarService.updateApplication();
  }
  
}
