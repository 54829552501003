import { Component, OnInit } from '@angular/core';
import { RootformComponent } from '../rootform/rootform.component';

@Component({
  selector: 'lp-addresses',
  templateUrl: './addresses.component.html'
})
export class AddressesComponent extends RootformComponent implements OnInit {

  public ky: string = null;

  public async ngOnInit(): Promise<void> {
    await super.build('addresses', this.route.snapshot.params['ky']);
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.data = this.formStackService.currentData;
   }
  }
}
