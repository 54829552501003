import { LpMeta, propertyMeta } from '../meta';
import { FtMeta } from '../ft-meta';
import { Type } from 'class-transformer';
import { LpInspectionDamageCategory } from './inspectiondamage-category';

export class LpInspectionElementZone extends LpMeta {
    @propertyMeta('string')
    public id: String;
    @propertyMeta('boolean')
    public isActive: Boolean;
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('string')
    public order: String;
    @propertyMeta('string')
    public filter: String;
    @propertyMeta('string')
    public filter2: String;
    @Type(() => FtMeta)
    public damageCategory: LpInspectionDamageCategory;
}
