import {NgModule} from '@angular/core';
import {ApplicationItemActionsComponent} from './application-item-actions/application-item-actions.component';
import {ApplicationItemDetailsComponent} from './application-item-details/application-item-details.component';
import {MemoComponent} from './memo/memo.component';
import {FilePickerComponent} from './file-picker/file-picker.component';
import {SchedulerComponent} from './scheduler/scheduler.component';
import {AppModuleUI} from 'app/ui/app.module.ui';
import {AppModuleLp} from '../lp/app.module.lp';
import {AlocproThirdPartyLibsModule} from '../alocpro-third-party-libs.module';
import { DynamicDetailFormComponent } from './dynamic-form/dynamic-detail-form/dynamic-detail-form.component';
import { FieldsComponent } from './dynamic-form/fields/fields.component';
import { DynamicMainFormComponent } from './dynamic-form/dynamic-main-form/dynamic-main-form.component';
import { LpModalTraceComponent } from 'app/ui/lp-modal/lp-modal-trace/lp-modal-trace.component';
import { SchedulerZoomComponent } from 'app/schedulerZoom/schedulerZoom.component';
import { LpModalPromiseZoomSchedulerComponent } from 'app/ui/lp-modal/lp-modal-zoom-scheduler/lp-modal-zoom-scheduler-scheduler.component';


@NgModule({
  imports: [
    AlocproThirdPartyLibsModule,
    AppModuleLp,
    AppModuleUI
  ],
  declarations: [
    ApplicationItemActionsComponent,
    ApplicationItemDetailsComponent,
    FilePickerComponent,
    MemoComponent,
    SchedulerComponent,
    SchedulerZoomComponent,
    LpModalPromiseZoomSchedulerComponent,
    DynamicMainFormComponent,
    DynamicDetailFormComponent,
    FieldsComponent,
    LpModalTraceComponent
  ],
  exports: [
    ApplicationItemActionsComponent,
    ApplicationItemDetailsComponent,
    FilePickerComponent,
    MemoComponent,
    SchedulerComponent,
    SchedulerZoomComponent,
    LpModalPromiseZoomSchedulerComponent,
    DynamicMainFormComponent,
    DynamicDetailFormComponent,
    FieldsComponent,
    LpModalTraceComponent
  ]

})
export class FormComponentModule {
}
