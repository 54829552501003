
<button *ngIf="ihmService.ShowSwitcher && displayAddButtom && !displayWheel" type="button" class="btn btn-outline-primary pull-right createContract ms-2" (click)="createLink()">+</button>
<button *ngIf="ihmService.ShowSwitcher && !displayWheel && !displayAddButtom" type="button" class="btn btn-outline-primary pull-right addContract ms-2" (click)="saveNewContract()">{{'multiproduct.addContract' | translate}}</button>

<div #container class="switcher-container mb-2" *ngIf="displayWheel || ihmService.ShowSwitcher" [class.row]="isToLong">
  <ng-container *ngFor="let item of list.body; let i = index" >
    <span *ngIf="displayWheel" [class.col]="isToLong" class="d-inline-block switcher-item ps-3 pe-3">{{ loading | translate}} ..</span>
    <span [class.col]="isToLong" *ngIf="!displayWheel && item[label] !== newContractId" class="d-inline-block switcher-item ps-3 pe-3" [class.selected]="item[label] == formStackService.currentData.id" (click)="selectItem(item[label], i)">
      
      <span *ngIf="!displayWheel && 
        item['movement'] 
        && item['movement']['equipment']
        && item['movement']['equipment']['registration']
        && item['movement']['equipment']['registration']['registration'] && item[label] !== NEW" title="{{item['category']['wording']}} / {{item['movement']['equipment']['registration']['registration']}}">{{item['category']['wording']}} / {{item['movement']['equipment']['registration']['registration']}}</span>
        
      <span *ngIf="displayWheel
      || item['movement'] !== null
      || item['movement']['equipment'] !== null
      || item['movement']['equipment']['registration'] !== null
      || item['movement']['equipment']['registration']['registration'] && item[label] === NEW" title="{{item['category']['wording']}}">{{item['category']['wording']}}</span>
    </span>
    <span *ngIf="!displayWheel && item[label] === newContractId" [class.col]="isToLong" [class.selected]="item[label] == formStackService.currentData.id" (click)="selectItem(item[label], i)" class="d-inline-block switcher-item ps-3 pe-3">{{item[label] | translate}}</span>
  </ng-container> 
</div>
