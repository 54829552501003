


<!-- ajout d'un input fields FieldsId. si fieldId alors zoom locpro-->
<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" titleTranslationKey="short-term-contracts.shortTermContract"></lp-top-banner>
<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">
  <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''"
    (dblclick)="reInitCols()"></div>
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>
  <div class="container container-2-cols left" #containerCols>
    <div class="card-box col-md-6 label-2-colonnes" #col1>
      <form id="formulaire" *ngIf="uiSyncService.IsReadyToDisplayForm" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
        <lp-switcher *ngIf="!uiSyncService.IsNewForm && showSwitcher()"></lp-switcher>
        <lp-common-tools *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id" [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="deleteButton"
          (showPrintOutput)="changeShowZoomPrint($event)" [showPrintInput]="showZoomPrint"></lp-common-tools>
        <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
        <lp-ui-accordion class="row" [menu]=true>
          <lp-ui-accordion-group heading="short-term-contracts.informations" [open]=true key="infos">
            <div class="container">
              <div class="row">
                <div class="form-group col-xl-6">
                  <!--<lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" id="rentalType"
                    cssClass="form-control" [(ngModel)]="data.rentalType" name="rentalType" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'rentalType'}]}"></lp-dropdown-field>-->
                  <lp-zoom-field zoomId="rentalType" label="short-term-contracts.rentalType" id="rentalType" name="rentalType" cssClass="form-control"
                  class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.rentalType"> </lp-zoom-field>
                </div>
                <div class="form-group col-xl-6">
                  <!--<lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" id="status"
                    cssClass="form-control" name="status" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'shortTermContractStatus'}]}"
                    [(ngModel)]="data.status"></lp-dropdown-field>-->
                  <lp-zoom-field zoomId="status" id="status" name="status" cssClass="form-control" label="short-term-contracts.state"
                  class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.status"> </lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-text-field id="contractNumber" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="contractNumber" [(ngModel)]="data.contractNumber" label="short-term-contracts.contractNumber"
                    type="text"></lp-text-field>
                </div>
                <div class="form-group col-xl-6">
                  <lp-text-field id="bookingCode" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="bookingCode" [(ngModel)]="data.bookingCode" label="short-term-contracts.booking"
                    type="text">
                  </lp-text-field>
                </div>
              </div>


              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="customer" [(ngModel)]="data.customer" name="customer" zoomId="customer" label="general.other.customer"
                   class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" cssClass="form-control" > </lp-zoom-field>
                </div>
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="driver" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" cssClass="form-control" label="short-term-contracts.mainDriver"
                    [(ngModel)]="data.driver" name="driver" zoomId="driver"> </lp-zoom-field>
                </div>
              </div>


              <div class="row">
                <div class="form-group col-xl-6">
                  <!--<lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" id="cancelReason" label="short-term-contracts.cancellationReason"
                    cssClass="form-control" name="cancelReason" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'cancelReason'}]}"
                    [(ngModel)]="data.cancelReason"></lp-dropdown-field>-->
                  <lp-zoom-field zoomId="cancelReason" id="cancelReason" name="cancelReason" cssClass="form-control" label="short-term-contracts.cancelReason"
                    class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.cancelReason"> </lp-zoom-field>
                </div>
              </div>
            </div>
          </lp-ui-accordion-group>

          <lp-ui-accordion-group heading="short-term-contracts.start" [open]=false key="depart">
            <div class="container">
              <div class="row mb-4">
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="startLocation" class="{{arrayClassesElements.get('defaultInput')}}" zoomId="startLocation" label="short-term-contracts.startLocation"
                    className="zoomField" cssClass="form-control"  [(ngModel)]="data.startLocation" name="startLocation" ></lp-zoom-field>
                </div>
                <div class="form-group col-xl-4">
                <lp-date-time [(ngModel)]="data.startDate" class="{{arrayClassesElements.get('defaultInput')}}" id="startDate"
                    cssClass="form-control" name="startDate" [min]="data.startLocation" label="short-term-contracts.startDate"></lp-date-time>
                </div>
              </div>
              <div class="row mb-4">
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="plannedReturnLocation" class="{{arrayClassesElements.get('defaultInput')}}" zoomId="plannedReturnLocation" 
                    className="zoomField" cssClass="form-control" [(ngModel)]="data.plannedReturnLocation" name="plannedReturnLocation" label="short-term-contracts.plannedReturnLocation" >
                  </lp-zoom-field>
                </div>
                <div class="form-group col-xl-6">
                  <lp-date-time [(ngModel)]="data.plannedReturnDate"  class="{{arrayClassesElements.get('defaultInput')}}"
                    id="plannedReturnDate" cssClass="form-control" name="plannedReturnDate" label="short-term-contracts.plannedReturnDate"></lp-date-time>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-number id="wantedDays" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="wantedDays" min="0" [(ngModel)]="data.wantedDays" label="short-term-contracts.wantedDays"
                    type="text"></lp-number>
                </div>
                <div class="form-group col-xl-6">
                  <lp-number id="wantedMileage" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="wantedMileage" min="0" label="short-term-contracts.wantedMileage"
                    [(ngModel)]="data.wantedMileage" type="text"></lp-number>
                </div>
              </div>
            </div>
          </lp-ui-accordion-group>


          <lp-ui-accordion-group heading="short-term-contracts.equipment" [open]=false key="equipment">
            <div class="container">
              <div class="row">
                <div class="form-group col-xl-6">
                  <!--<lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" id="category"
                    cssClass="form-control" name="category" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'category'}]}"
                    [(ngModel)]="data.category"></lp-dropdown-field>-->
                    <lp-zoom-field zoomId="category" id="category" name="category" label="short-term-contracts.category"
                    cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.category"> </lp-zoom-field>
                </div>
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="movement.equipment" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" label="short-term-contracts.movement.equipment"
                    cssClass="form-control" zoomId="equipment" [(ngModel)]="data.movement.equipment" name="movement.equipment">
                  </lp-zoom-field>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-number id="movement.startMileage" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="short-term-contracts.movement.startMileage"
                    cssClass="form-control" name="movement.startMileage" min="0" [(ngModel)]="data.movement.startMileage"
                    type="text"></lp-number>
                </div>
                <div class="form-group col-xl-6">
                  <lp-fuel-gauge id="movement.startFuel" class="{{arrayClassesElements.get('defaultInput')}}"
                  name="movement.startFuel" label="short-term-contracts.movement.startFuel"
                    [(ngModel)]="data.movement.startFuel"></lp-fuel-gauge>
                </div>
              </div>
            </div>
          </lp-ui-accordion-group>          <lp-ui-accordion-group heading="short-term-contracts.pricingTtitle" [open]=false key="pricing">
            <div class="container">
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="pricing" class="{{arrayClassesElements.get('defaultInput')}}" zoomId='pricing' className="zoomField"  
                  cssClass="form-control" [(ngModel)]="data.pricing" name="pricing" label="short-term-contracts.pricing">
                  </lp-zoom-field>
                </div>
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="pricingMethod" class="{{arrayClassesElements.get('defaultInput')}}" zoomId='pricingMethod' 
                  className="zoomField" cssClass="form-control" [(ngModel)]="data.pricingMethod" name="pricingMethod" label="short-term-contracts.pricingMethod"
                  >
                  </lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-number id="discountRate" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="discountRate" min="0" label="short-term-contracts.discountRate"
                    [(ngModel)]="data.discountRate" type="text" iconClass="fa fa-percent"></lp-number>
                </div>
                <div class="form-group col-xl-6">
                  <lp-currency id="discountAmount" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="discountAmount" min="0" label="short-term-contracts.discountAmount"
                    [(ngModel)]="data.discountAmount" type="text"></lp-currency>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-text-field id="promotionalCode" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="promotionalCode" label="short-term-contracts.promotionalCode"
                    [(ngModel)]="data.promotionalCode" type="text"></lp-text-field>
                </div>
                <div class="form-group col-xl-6">

                </div>
              </div>

              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-number id="includedMileage" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="includedMileage" min="0" label="short-term-contracts.includedMileage"
                    [(ngModel)]="data.includedMileage" type="text"></lp-number>
                </div>
                <div class="form-group col-xl-6">
                  <lp-number id="includedDays" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="includedDays" min="0" label="short-term-contracts.includedDays"
                    [(ngModel)]="data.includedDays" type="text"></lp-number>
                </div>
              </div>

              <div class="row">
                  <div class="form-group col-xl-6">
                    <lp-number id="includedMileageByDay" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                      cssClass="form-control" name="includedMileageByDay" min="0" label="short-term-contracts.includedMileageByDay"
                      [(ngModel)]="data.includedMileageByDay" type="text"></lp-number>
                  </div>
                  <div class="form-group col-xl-6">

                  </div>
                </div>


            </div>
          </lp-ui-accordion-group>

          <lp-ui-accordion-group heading="short-term-contracts.voucherTitle" [open]=false key="voucher">
            <div class="container">
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-currency id="deposit" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="deposit" [(ngModel)]="data.deposit" label="short-term-contracts.deposit"
                    type="text"></lp-currency>
                </div>
                <div class="form-group col-xl-6">
                  <lp-text-field id="voucher" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="voucher" [(ngModel)]="data.voucher" label="short-term-contracts.voucher"
                    type="text"></lp-text-field>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-date-time [(ngModel)]="data.voucherDate" class="{{arrayClassesElements.get('defaultInput')}}" id="voucherDate"
                    cssClass="form-control" name="voucherDate" label="short-term-contracts.voucherDate"></lp-date-time>
                </div>
                <div class="form-group col-xl-6">
                  <lp-number id="invoiceMaxDays" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="invoiceMaxDays" min="0" label="short-term-contracts.invoiceMaxDays"
                    [(ngModel)]="data.invoiceMaxDays" type="text"></lp-number>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-currency id="voucherAmount" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="voucherAmount"  [(ngModel)]="data.voucherAmount"
                    type="text" label="short-term-contracts.voucherAmount"></lp-currency>
                </div>
                <div class="form-group col-xl-6">
                  <lp-text-field id="customerReference" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="customerReference"
                    [(ngModel)]="data.customerReference" type="text" label="short-term-contracts.customerReference"></lp-text-field>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-text-field id="itvCode" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="itvCode" [(ngModel)]="data.itvCode" label="short-term-contracts.itvCode"
                    type="text"></lp-text-field>
                </div>
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="merchant"  class="{{arrayClassesElements.get('defaultInput')}}" zoomId='merchant'  label="short-term-contracts.merchant"
                     className="zoomField" cssClass="form-control" [(ngModel)]="data.merchant" name="merchant"></lp-zoom-field>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-text-field id="transportReference2" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="transportReference2" label="short-term-contracts.transportReference2"
                    [(ngModel)]="data.transportReference2"
                    type="text"></lp-text-field>
                </div>
                <div class="form-group col-xl-6">
                  <lp-text-field id="transportReference1" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="transportReference1" label="short-term-contracts.transportReference1"
                    [(ngModel)]="data.transportReference1" type="text"></lp-text-field>
                </div>
              </div>
            </div>
          </lp-ui-accordion-group>

          <lp-ui-accordion-group heading="short-term-contracts.return" [open]=false key="retour">
            <div class="container">
              <div class="row">
                <div class="form-group col-xl-6">
                    <lp-zoom-field id="returnLocation" class="{{arrayClassesElements.get('defaultInput')}}" zoomId="returnLocation"  label="short-term-contracts.returnLocation"
                    className="zoomField" cssClass="form-control" [(ngModel)]="data.returnLocation" name="returnLocation">
                  </lp-zoom-field>
                </div>
                <div class="form-group col-xl-6">
                  <lp-date-time [ngModel]="data.returnDate" class="{{arrayClassesElements.get('defaultInput')}}" id="returnDate"
                    cssClass="form-control" name="returnDate" [disabled]="true" label="short-term-contracts.returnDate"></lp-date-time>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-number id="movement.returnMileage" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="short-term-contracts.movement.returnMileage"
                    cssClass="form-control" name="movement.returnMileage" min="0" [(ngModel)]="data.movement.returnMileage"
                   type="text" [disabled]="true"></lp-number>
                </div>
                <div class="form-group col-xl-6">
                  <lp-fuel-gauge id="movement.returnFuel" class="{{arrayClassesElements.get('defaultInput')}}" name="movement.returnFuel"
                    [(ngModel)]="data.movement.returnFuel" [disabled]="true" label="short-term-contracts.movement.returnFuel"></lp-fuel-gauge>
                </div>
              </div>

            </div>
          </lp-ui-accordion-group>

          <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
            <div>
              <pre>{{ data | json }}</pre>
            </div>
          </lp-ui-accordion-group>
        </lp-ui-accordion>
      </div>
        <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
      </form>
    </div>
    
    <div class="col-md-6 card-box" #col2>
      <lp-right-col></lp-right-col>
    </div>
    
  </div>

  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>
