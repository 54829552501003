
<!--use of 'hidden' instead of '*ngIf' because we want fomStackService.currentFields to contain all fields, even hidden ones-->
<div [hidden]="!((f.hidden !== true || f.hidden === null || f.hidden === undefined) && availableEval === true)">
    <!--<div *ngIf="f.hideLabel === null || f.hideLabel === undefined || f.hideLabel === false">-->
    <lp-label-field *ngIf="!f.label" for="id" class="control-label" [ignoreChange]="f.params.ignoreChange" text="&nbsp;" [required]="f.mandatory"></lp-label-field>
        
    <lp-label-field *ngIf="f.label && !f.prop" class="control-label" text="{{textForLabelField}}"></lp-label-field>
    
    <lp-html-field *ngIf="f.type === 'html'"  [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}" [html]="f.defaultValue"></lp-html-field>
    <!--<</div>-->
    <lp-hidden-field *ngIf="f.type === 'hidden'" id="{{f.prop}}" className="textField" cssClass="form-control"
    name="{{namePrefix + f.prop}}" [ignoreChange]="f.params.ignoreChange" type="{{f.params.type}}"
    [ngModelOptions]="{standalone: true}"  [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)"
    ></lp-hidden-field>

    <lp-address *ngIf="f.type === 'address'" id="{{f.prop}}" [required]="f.mandatory" name="{{namePrefix + f.prop}}" [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)" [mainDataProperty]="f.params.mainDataProperty"  [addressDataToBind]="f.params.addressDataToBind"
    [label]="(f.label) ? translationPrefix + f.label: ''" className="textField" cssClass="form-control" [prop]=f.prop [type]="f.params.type"></lp-address>

    <lp-text-field *ngIf="f.type === 'text'" id="{{f.prop}}" className="textField" cssClass="form-control" [style]="getStyle(f.style, namePrefix + f.prop)"
        minlength="{{f.params.minLength}}"  maxlength="{{f.params.maxLength}}"
        name="{{namePrefix + f.prop}}" [ignoreChange]="f.params.ignoreChange" [ngModelOptions]="{standalone: true}"  [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)"
        [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}" [required]="f.mandatory" [readonly]="f.readonly" [maxLength]="f.params.maxLength" [autocomplete]="cc-csc" [label]="(f.label) ? translationPrefix + f.label: ''"></lp-text-field>

    <lp-textarea-field *ngIf="f.type === 'textarea'"  id="{{f.prop}}" className="textField" [style]="getStyle(f.style, namePrefix + f.prop)"
        [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}" [required]="f.mandatory" [readonly]="f.readonly" [ignoreChange]="f.params.ignoreChange"
        cssClass="textareaField" name="{{namePrefix + f.prop}}" [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)"  [label]="(f.label) ? translationPrefix + f.label: ''" [rows]="f.params.rows"></lp-textarea-field>
        
    <lp-editor-field *ngIf="f.type === 'editor'"  id="{{f.prop}}" className="textField" [style]="getStyle(f.style, namePrefix + f.prop)"
    [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}" [required]="f.mandatory" [readonly]="f.readonly" [ignoreChange]="f.params.ignoreChange"
    cssClass="textareaField" name="{{namePrefix + f.prop}}" [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)"  [label]="(f.label) ? translationPrefix + f.label: ''" [rows]="f.params.rows" [mode]="f.params.mode" [theme]="f.params.theme"></lp-editor-field>
    
    <lp-time *ngIf="f.type === 'time'"  type="time" name="{{namePrefix + f.prop}}" 
        id="{{f.prop}}" [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)" [required]="f.mandatory" [readonly]="f.readonly" [ignoreChange]="f.params.ignoreChange" [style]="getStyle(f.style, namePrefix + f.prop)"
        cssClass="form-control" [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}"  [label]="(f.label) ? translationPrefix + f.label: ''"></lp-time>

    <lp-date-time *ngIf="f.type === 'datetime'" [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)"  [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}" id="{{f.prop}}" [style]="getStyle(f.style, namePrefix + f.prop)"
        cssClass="form-control" name="{{namePrefix + f.prop}}" [required]="f.mandatory" [readonly]="f.readonly" [ignoreChange]="f.params.ignoreChange"  [label]="(f.label) ? translationPrefix + f.label: ''"></lp-date-time>

    <lp-date-time-range *ngIf="f.type === 'datetimerange'" [ngModel]="getDataRef(f.prop)" [startDate]="f.params.startDate" [secondDate]="f.params.secondDate" (ngModelChange)="setDataRef(f.prop, $event)"  [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}" id="{{f.prop}}" [style]="getStyle(f.style, namePrefix + f.prop)"
        cssClass="form-control" name="{{namePrefix + f.prop}}" [required]="f.mandatory" [readonly]="f.readonly" [ignoreChange]="f.params.ignoreChange"  [label]="(f.label) ? translationPrefix + f.label: ''"></lp-date-time-range>

    <lp-date  *ngIf="f.type === 'date'" [ngModelOptions]="{standalone: true}"  [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)" id="{{f.prop}}"
        cssClass="form-control" name="{{namePrefix + f.prop}}" [ignoreChange]="f.params.ignoreChange"  [label]="(f.label) ? translationPrefix + f.label: ''" [style]="getStyle(f.style, namePrefix + f.prop)"
        [readonly]="f.readonly" [required]="f.mandatory" [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}"></lp-date>

    <lp-date-month *ngIf="f.type === 'datemonth'"  [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)"  [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}" [ignoreChange]="f.params.ignoreChange"
        [readonly]="f.readonly" [required]="f.mandatory"  id="{{f.prop}}" cssClass="form-control" name="{{namePrefix + f.prop}}"  [label]="(f.label) ? translationPrefix + f.label: ''" [style]="getStyle(f.style, namePrefix + f.prop)"></lp-date-month>

    <lp-boolean-switch-box *ngIf="f.type === 'boolean'" name="{{namePrefix + f.prop}}" 
        switchName="{{namePrefix + f.prop}}" id="{{f.prop}}"  [label]="(f.label) ? translationPrefix + f.label: ''"
        [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)" [required]="f.mandatory" [ignoreChange]="f.params.ignoreChange" [readonly]="f.readonly" [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}"></lp-boolean-switch-box>
    <lp-dropdown-field *ngIf="f.type === 'dropdown' && f.params.path && !f.params.values && !f.params.locProZoomObject && !f.params.locProZoomAttribut" className="textField" cssClass="form-control" [ignoreChange]="f.params.ignoreChange"
        id="{{f.prop}}" name="{{namePrefix + f.prop}}" [outputType]="f.outputType" [label]="(f.label) ? translationPrefix + f.label: ''"
        [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)" [path]="f.params.path" [required]="f.mandatory" [readonly]="f.readonly" [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}" ></lp-dropdown-field>

    <lp-dropdown-field *ngIf="f.type === 'dropdown' && !f.params.path && f.params.values && !f.params.locProZoomObject && !f.params.locProZoomAttribut" className="textField" cssClass="form-control" [ignoreChange]="f.params.ignoreChange" 
        id="{{f.prop}}" name="{{namePrefix + f.prop}}" [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)" [outputType]="f.outputType" [label]="(f.label) ? translationPrefix + f.label: ''"
        [required]="f.mandatory" [readonly]="f.readonly" [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}" [values]=f.params.values></lp-dropdown-field>

    <lp-dropdown-field *ngIf="f.type === 'dropdown' && !f.params.path && !f.params.values && f.params.locProZoomObject && f.params.locProZoomAttribut" className="textField" cssClass="form-control" [ignoreChange]="f.params.ignoreChange" 
        id="{{f.prop}}" name="{{namePrefix + f.prop}}" [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)" [outputType]="f.outputType" [idField]="f.params.idField" [labelField]="f.params.labelField" [label]="(f.label) ? translationPrefix + f.label: ''"
        [required]="f.mandatory" [readonly]="f.readonly" [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}" [locProZoomObject]="f.params.locProZoomObject" [locProZoomAttribut]="f.params.locProZoomAttribut"></lp-dropdown-field>

        <!--{{data |json}}-->
    <lp-switch-box *ngIf="f.type === 'switch'" inputBox="tabSwitch" name="{{namePrefix + f.prop}}" 
        id="{{f.prop}}" (change)="changeRelation()" [values]="f.prop" [ignoreChange]="f.params.ignoreChange"
        switchName="{{namePrefix + f.prop}}" [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)" [readonly]="f.readonly" 
        [required]="f.mandatory" [required]="f.mandatory"  [label]="(f.label) ? translationPrefix + f.label: ''"></lp-switch-box>

    <lp-zoom-field *ngIf="f.type === 'zoom'" id="{{f.prop}}" zoomId='{{f.params.zoomId}}' className="zoomField form-control"  [label]="(f.label) ? translationPrefix + f.label: ''" [style]="getStyle(f.style, namePrefix + f.prop)"
    [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)" [required]="f.mandatory" name="{{namePrefix + f.prop}}" [disabled]="f.readonly" [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}" [ignoreChange]="f.params.ignoreChange" [readonly]="f.readonly"></lp-zoom-field>

    <lp-number *ngIf="f.type === 'number'" id="{{f.prop}}" cssClass="form-control" [required]="f.mandatory" [readonly]="f.readonly" [ignoreChange]="f.params.ignoreChange" [style]="getStyle(f.style, namePrefix + f.prop)"
        [ngClass]="f.params.class ? f.params.class : ''" name="{{namePrefix + f.prop}}"  [label]="(f.label) ? translationPrefix + f.label: ''" [integer]="f.params.integer"
        [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)" minlength="{{f.params.minlength}}"></lp-number>

    <lp-currency *ngIf="f.type === 'currency'" id="{{f.prop}}" className="textField" [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}"  [label]="(f.label) ? translationPrefix + f.label: ''" [style]="getStyle(f.style, namePrefix + f.prop)"
        cssClass="form-control" name="{{namePrefix + f.prop}}" [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)" type="text" [required]="f.mandatory" [readonly]="f.readonly" [ignoreChange]="f.params.ignoreChange"></lp-currency>

    <lp-fuel-gauge *ngIf="f.type === 'gauge'" id="{{f.prop}}" [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}" name="gauge"   [label]="(f.label) ? translationPrefix + f.label: ''" [style]="getStyle(f.style, namePrefix + f.prop)"
    [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)" name="{{namePrefix + f.prop}}" [required]="f.mandatory" [readonly]="f.readonly" [ignoreChange]="f.params.ignoreChange"></lp-fuel-gauge>
    
    <lp-drop-file *ngIf="f.type === 'dropfile'" [ignoreChange]="f.params.ignoreChange" [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)" [label]="(f.label) ? translationPrefix + f.label: ''" name="{{namePrefix + f.prop}}" filename="f.params.fileName" (uploaded)="uploadedFile($event, f.params.output, data)" [isBinding]="f.params.isBinding"></lp-drop-file>

    <lp-file-carousel *ngIf="f.type === 'fileCarousel'" [label]="(f.label) ? translationPrefix + f.label: ''" [ngModel]="getDataRef(f.prop)" (ngModelChange)="setDataRef(f.prop, $event)" (remove)="removeFileCarousel($event, f.params.removeEval, data)" id="{{f.prop}}" name="{{namePrefix + f.prop}}"></lp-file-carousel>
</div>