<div *ngIf="ihmStackService.ShowReport">
  <div class="{{cssClass}} p-0 mb-0 mt-2 panel-group card-box" ngDefaultControl>
    <div [ngClass]="'panel card panel-primary open m-0'">
        <div [ngClass]="'panel-heading card-header'" >
          <h5 *ngIf="!reportService.modelName && ihmStackService.showPrintReportPDF"> 
            {{ reportService.titleReportFromPrintReport | translate }}</h5>
          <h5 *ngIf="!reportService.modelName && !ihmStackService.showPrintReportPDF">
            {{ reportService.titleReport | translate }}</h5>
          <h5 *ngIf="reportService.modelName">{{ reportService.titleReport | translate }}
            ({{ reportService.modelName }})</h5>
          
          <div [ngClass]="'title-buttons'">
            <!--<a *ngIf="ihmStackService.ShowPrintReportPDF && !ihmStackService.ShowPrintReport" class="ms-2 btn btn-outline-primary" title="{{'report.return' | translate}}"
              aria-label="Return" (click)="returnToForm(editionType)">
              <i class="fa fa-arrow-left"></i>
            </a>-->
            <a *ngIf="ihmStackService.ShowPrintReportForm" class="ms-2 btn btn-outline-primary" title="{{'report.return' | translate}}"
              aria-label="Return" (click)="return()">
              <i class="fa fa-arrow-left"></i>
            </a>
            <lp-button-cancel *ngIf="(closable)" (action)="closeAll()"></lp-button-cancel>
          </div>
        </div>
        <div class="modal-body">
          <div *ngIf="ihmStackService.ShowPrintReportList" class="row">

          </div>

          <!--Liste-->
          <lp-report-list *ngIf="ihmStackService.ShowPrintReportList"></lp-report-list>

          <!--Formulaire-->
          <lp-report-parameters *ngIf="ihmStackService.ShowPrintReportForm" 
          [reportKy]="ky"
          [customerInvoiceKy]="customerInvoiceKy" ></lp-report-parameters>

          <!--Visionneuse-->
          <lp-pdf-viewer *ngIf="ihmStackService.ShowPrintReportPDF && reportService.srcPdf && reportService.srcPdf.length > 0" [pdfSrc]="reportService.srcPdf"></lp-pdf-viewer>

          <div *ngIf="appearBanner" class="alert alert-danger" role="alert">
            {{'general.incompleteList' | translate}}
          </div>
        </div>
    </div>
  </div>

</div>