<lp-ui-accordion id="{{id}}">
  <lp-ui-accordion-group heading="short-term-contracts.shortTermContractsMedias.title" [open]=true>
    <lp-chips bindingKey="id" id="typeClient" idField="id"
      className="checkList" cssClass="" name="medias" [verb]="verb" [api]="api" [parentData]="formStackService.currentData" [object]="object" [filter]="filter" [prop]="prop" labelField="wording"
      [singleCol]="false" [readonly]="formStackService.currentData._readOnly"></lp-chips>

  </lp-ui-accordion-group>
  <lp-ui-accordion-group heading="Debugger: Tous les médias" *ngIf="debugg">
    <div>
      Elements selectionnés
      <pre>{{ binding | json }}</pre>
      Elements disponibles (table FT)
      <pre>{{ available | json }}</pre>
    </div>
  </lp-ui-accordion-group>
</lp-ui-accordion>
