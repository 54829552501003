<lp-ui-accordion id="{{id}}">
  <lp-ui-accordion-group heading="metadataApplicationItemDetails.contacts.editContacts" [open]=true>
    <form id="formContact" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>
      <!-- TODO when swagger will be update -->
      <div class="form-group col-xl-12">
        <lp-label-field for="contact-details.type" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.contacts.type"></lp-label-field>
        <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control" id="contact-details.type"
          name="contact-details.type" [(ngModel)]="data.type" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'contactDetailsType'}]}"></lp-dropdown-field>
      </div>
      <div class="form-group col-xl-12">
        <lp-label-field for="contact-details.data" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.contacts.data"></lp-label-field>
        <lp-text-field id="contact-details.data" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="contact-details.data"
          [(ngModel)]="data.data"></lp-text-field>
      </div>
      <div class="form-group col-xl-12">
        <lp-label-field for="contact-details.wording" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="metadataApplicationItemDetails.contacts.wording"></lp-label-field>
        <lp-text-field id="contact-details.wording" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="contact-details.wording"
          [(ngModel)]="data.wording"></lp-text-field>
      </div>
      <div class="text-end">
        <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
      </div>
    </form>
  </lp-ui-accordion-group>
</lp-ui-accordion>