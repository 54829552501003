<span [class.radio-readonly]="readonly" [ngClass]="responsivClass" *ngIf="values">
  <label *ngFor="let value of values;"  [ngClass]="{'col-md-6 col-lg-4 col-xl-3': !singleCol}" class="custom-control custom-radio {{className}} {{cssClass}}"
    [class.disabled]="disabled">
    <input type="radio" name="{{radioName}}" [checked]="displayValue == value[idField]" [disabled]="disabled"
      [required]="this.formStackService.getFields(name).mandatory && !this.formStackService.getFields(name).hidden" [value]="value[idField]" (click)="emit($event)" class="custom-control-input d-none">
    <span class="custom-control-indicator"></span>
    <span class="custom-control-description">{{value[labelField]}}</span>

  </label>
</span>

