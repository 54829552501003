import { Component, forwardRef} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ProgressBarService } from 'app/services/progress-bar.service';

@Component({
  selector: 'lp-progress-bar',
  templateUrl: 'lp-progress-bar.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpProgressBarComponent),
      multi: true
    }
  ]
})


export class LpProgressBarComponent {

  constructor(public progressBarService: ProgressBarService) {

  }
}
