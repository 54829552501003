<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" titleTranslationKey="damages.title"></lp-top-banner>
<div class="container-fluid container-fluid-2-cols"  *ngIf="uiSyncService.IsReadyToDisplayForm  && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">
    <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''" (dblclick)="reInitCols()"></div>
    <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>
    <div class="container container-2-cols left" #containerCols>
        <div class="card-box col-md-6 label-2-colonnes" #col1>
            <form id="formulaire" *ngIf="formStackService.currentData" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
                <!--  composant pour afficher lien flottant à droite du formulaire-->
                <lp-common-tools  *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [deleteButton]="deleteButton" [positionFromRight]="dragRight ? dragRight : null"  [ky]="data.id" ></lp-common-tools>
                <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
                <lp-ui-accordion class="row" [menu]=true>
                    <lp-ui-accordion-group heading="damages.designation" [open]=true key="general">
                        <div class="container">
                        
                        <div class="row">
                            <div class="form-group col-xl-6">
                                <lp-text-field id="id" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="damages.id"
                                    cssClass="form-control" name="id" [(ngModel)]="data.id"></lp-text-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-xl-6">
                                <lp-zoom-field zoomId="equipment" id="equipment" name="equipment" cssClass="form-control" 
                                    class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.equipment" label="damages.equipment"> 
                                </lp-zoom-field>
                            </div>
                            <div class="form-group col-xl-6">
                                <lp-text-field id="freeRegistration" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="damages.freeRegistration"
                                    cssClass="form-control" name="freeRegistration" [(ngModel)]="data.freeRegistration"></lp-text-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-xl-6">
                                <lp-number id="mileage" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="mileage" label="damages.mileage"
                                    [(ngModel)]="data.mileage"></lp-number>
                            </div>
                            <div class="form-group col-xl-6">
                                <lp-boolean-switch-box name="isPostPoned" switchName="isPostPoned" id="isPostPoned" [(ngModel)]="data.isPostPoned" label="damages.isPostPoned"></lp-boolean-switch-box>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-xl-6">
                                <lp-zoom-field zoomId="insurancePolicy" id="insurancePolicy" name="insurancePolicy" cssClass="form-control" 
                                    class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.insurancePolicies" label="damages.insurancePolicy"> 
                                </lp-zoom-field>
                            </div>
                            <div class="form-group col-xl-6">
                                <lp-text-field id="freeInsurancePolicy" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="damages.freeInsurancePolicy"
                                    cssClass="form-control" name="freeInsurancePolicy" [(ngModel)]="data.freeInsurancePolicy"></lp-text-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-xl-6">
                                <lp-zoom-field zoomId="movement" id="movement" name="movement" cssClass="form-control" 
                                    class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.movement" label="damages.movement"> 
                                </lp-zoom-field>
                            </div>
                            <div class="form-group col-xl-6">
                                <lp-zoom-field zoomId="location" id="location" name="location" cssClass="form-control" 
                                    class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.location" label="damages.location"> 
                                </lp-zoom-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-xl-6">
                                <lp-zoom-field zoomId="managementLocation" id="managementLocation" name="managementLocation" cssClass="form-control" 
                                    class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.managementLocation" label="damages.managementLocation"> 
                                </lp-zoom-field>
                            </div>
                            <div class="form-group col-xl-6">
                                <lp-zoom-field zoomId="company" id="company" name="company" cssClass="form-control" 
                                    class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.company" label="damages.company"> 
                                </lp-zoom-field>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-xl-6">
                                <lp-zoom-field zoomId="thirdParty" id="thirdParty" name="thirdParty" cssClass="form-control" 
                                    class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.thirdParty" label="damages.thirdParty"> 
                                </lp-zoom-field>
                            </div>
                            <div class="form-group col-xl-6">
                                <lp-zoom-field zoomId="driver" id="driver" name="driver" cssClass="form-control" 
                                    class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.driver" label="damages.driver"> 
                                </lp-zoom-field>
                            </div>
                        </div>

                        </div>
                    </lp-ui-accordion-group>
                </lp-ui-accordion>

                <lp-ui-accordion class="row" [menu]=true>
                        <lp-ui-accordion-group heading="damages.definition" [open]=true key="general">
                            <div class="container">

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-date id="completeFileDate" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" label="damages.completeFileDate"
                                            name="completeFileDate" [(ngModel)]="data.completeFileDate"></lp-date>
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-text-field id="opponentInsurancePolicy" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="damages.opponentInsurancePolicy"
                                            cssClass="form-control" name="opponentInsurancePolicy" [(ngModel)]="data.opponentInsurancePolicy"></lp-text-field>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-text-field id="opposingRegistration" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="damages.opposingRegistration"
                                            cssClass="form-control" name="opposingRegistration" [(ngModel)]="data.opposingRegistration"></lp-text-field>
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-date id="date" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" label="damages.date"
                                            name="date" [(ngModel)]="data.date"></lp-date>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-date id="closingDate" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" label="damages.closingDate"
                                            name="closingDate" [(ngModel)]="data.closingDate"></lp-date>
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-text-field id="declaration" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="damages.declaration"
                                            cssClass="form-control" name="declaration" [(ngModel)]="data.declaration"></lp-text-field>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="liabilityRate" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="liabilityRate" label="damages.liabilityRate"
                                            [(ngModel)]="data.liabilityRate"></lp-number>
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="driverLiabilityRate" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="driverLiabilityRate" label="damages.driverLiabilityRate"
                                            [(ngModel)]="data.driverLiabilityRate"></lp-number> 
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="opposingLiabilityRate" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                                            name="opposingLiabilityRate" label="damages.opposingLiabilityRate"
                                            [(ngModel)]="data.opposingLiabilityRate"></lp-number>
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-zoom-field zoomId="invoice" id="invoice" name="invoice" cssClass="form-control" 
                                            class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.invoice" label="damages.invoice"> 
                                        </lp-zoom-field>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-boolean-switch-box name="generateAMovement" switchName="generateAMovement" id="generateAMovement" label="damages.generateAMovement" [(ngModel)]="data.generateAMovement"></lp-boolean-switch-box> 
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="expertiseValue" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="expertiseValue"
                                           label="damages.expertiseValue"  [(ngModel)]="data.expertiseValue"></lp-number>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="rateReclamationCost" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="rateReclamationCost"
                                           label="damages.rateReclamationCost" [(ngModel)]="data.rateReclamationCost"></lp-number>
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="estimatedAmount" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="estimatedAmount"
                                           label="damages.estimatedAmount" [(ngModel)]="data.estimatedAmount"></lp-number>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="marketValue" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="marketValue"
                                            label="damages.marketValue" [(ngModel)]="data.marketValue"></lp-number>                                                
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="customerDeductible" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="customerDeductible"
                                           label="damages.customerDeductible" [(ngModel)]="data.customerDeductible"></lp-number>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="rcDeductible" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="rcDeductible"
                                             label="damages.rcDeductible" [(ngModel)]="data.rcDeductible"></lp-number>                                                
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="guaranteeFund" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="guaranteeFund"
                                           label="damages.guaranteeFund" [(ngModel)]="data.guaranteeFund"></lp-number>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="manualBilling" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="manualBilling"
                                            label="damages.manualBilling" [(ngModel)]="data.manualBilling"></lp-number>                                                
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="bodyEstimation" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="bodyEstimation"
                                           label="damages.bodyEstimation" [(ngModel)]="data.bodyEstimation"></lp-number>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="residualAmount" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="residualAmount"
                                           label="damages.residualAmount" [(ngModel)]="data.residualAmount"></lp-number>                                                 
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="netCarryingAmount" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="netCarryingAmount"
                                           label="damages.netCarryingAmount" [(ngModel)]="data.netCarryingAmount"></lp-number> 
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="salvageValue" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="salvageValue"
                                            [(ngModel)]="data.salvageValue" label="damages.salvageValue"></lp-number>                                                                                    
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-text-field id="declaration" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                                            cssClass="form-control" name="declaration" [(ngModel)]="data.declaration" label="damages.declaration"></lp-text-field>                                                                                     
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="averageMonthlyRentalAmount" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="averageMonthlyRentalAmount"
                                            [(ngModel)]="data.averageMonthlyRentalAmount" label="damages.averageMonthlyRentalAmount"></lp-number>                                                
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="monthsOfUse" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="monthsOfUse"
                                            [(ngModel)]="data.monthsOfUse" label="damages.monthsOfUse"></lp-number>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="theoricalOdometerReturn" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="theoricalOdometerReturn"
                                            [(ngModel)]="data.theoricalOdometerReturn" label="damages.theoricalOdometerReturn"></lp-number>                                                
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="monthsToMaturity" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="monthsToMaturity"
                                            [(ngModel)]="data.monthsToMaturity" label="damages.monthsToMaturity"></lp-number>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="theoricalOdometerActual" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="theoricalOdometerActual"
                                                [(ngModel)]="data.theoricalOdometerActual" label="damages.theoricalOdometerActual"></lp-number>                                                
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-boolean-switch-box name="hasEarlyRestitutionIndicator" label="damages.hasEarlyRestitutionIndicator"
                                        switchName="hasEarlyRestitutionIndicator" id="hasEarlyRestitutionIndicator" [(ngModel)]="data.hasEarlyRestitutionIndicator"></lp-boolean-switch-box>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="amountOfDamage" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="amountOfDamage"
                                            [(ngModel)]="data.amountOfDamage" label="damages.amountOfDamage"></lp-number>                                                
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="rateDiscountedDepreciationBodywork" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="rateDiscountedDepreciationBodywork"
                                            [(ngModel)]="data.rateDiscountedDepreciationBodywork" label="damages.rateDiscountedDepreciationBodywork"></lp-number>                                                
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="amountDiscountedDepreciationBodywork" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="amountDiscountedDepreciationBodywork"
                                            [(ngModel)]="data.amountDiscountedDepreciationBodywork" label="damages.amountDiscountedDepreciationBodywork"></lp-number>                                                
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-label-field for="netCostDamages" class="{{arrayClassesElements.get('defaultLabel')}}"
                                            label="damages.netCostDamages"></lp-label-field>
                                        <lp-number id="netCostDamages" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="netCostDamages"
                                            [(ngModel)]="data.netCostDamages"></lp-number>                                                
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-boolean-switch-box name="hasIndicatorApplicationMinorityKilometerCosts" label="damages.hasIndicatorApplicationMinorityKilometerCosts"
                                        switchName="hasIndicatorApplicationMinorityKilometerCosts" id="hasIndicatorApplicationMinorityKilometerCosts"
                                        [(ngModel)]="data.hasIndicatorApplicationMinorityKilometerCosts"></lp-boolean-switch-box>                                               
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-boolean-switch-box name="hasIndicatorApplicationExcessKilometerCosts" label="damages.hasIndicatorApplicationExcessKilometerCosts"
                                        switchName="hasIndicatorApplicationExcessKilometerCosts" id="hasIndicatorApplicationExcessKilometerCosts"
                                        [(ngModel)]="data.hasIndicatorApplicationExcessKilometerCosts"></lp-boolean-switch-box>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="compensationCostExcessKm" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="compensationCostExcessKm"
                                            [(ngModel)]="data.compensationCostExcessKm" label="damages.compensationCostExcessKm"></lp-number>                                                
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="compensationCostMinorityKm" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="compensationCostMinorityKm"
                                            [(ngModel)]="data.compensationCostMinorityKm" label="damages.compensationCostMinorityKm"></lp-number>                                                
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-date id="effectiveDateQuotation" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}"
                                            name="effectiveDateQuotation" [(ngModel)]="data.effectiveDateQuotation" label="damages.effectiveDateQuotation"></lp-date>                                                 
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="additionalDiscount" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="additionalDiscount"
                                            [(ngModel)]="data.additionalDiscount" label="damages.additionalDiscount"></lp-number>                                                
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-date id="validationDate" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}"
                                            name="validationDate" [(ngModel)]="data.validationDate" label="damages.validationDate"></lp-date>                                                
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-boolean-switch-box name="hasIndicatorInclusionQuotas" switchName="hasIndicatorInclusionQuotas" label="damages.hasIndicatorInclusionQuotas"
                                        id="hasIndicatorInclusionQuotas" [(ngModel)]="data.hasIndicatorInclusionQuotas"></lp-boolean-switch-box>                                                
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-boolean-switch-box name="hasInsuranceValidationIndicator" label="damages.hasInsuranceValidationIndicator"
                                        switchName="hasInsuranceValidationIndicator" id="hasInsuranceValidationIndicator" [(ngModel)]="data.hasInsuranceValidationIndicator"></lp-boolean-switch-box>                                                
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-date id="insuranceValidationDate" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}"
                                            name="insuranceValidationDate" [(ngModel)]="data.insuranceValidationDate" label="damages.insuranceValidationDate"></lp-date>                                               
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-boolean-switch-box name="hasIndicatorApplicationKilometersCosts" switchName="hasIndicatorApplicationKilometersCosts" label="damages.hasIndicatorApplicationKilometersCosts"
                                         id="hasIndicatorApplicationKilometersCosts" [(ngModel)]="data.hasIndicatorApplicationKilometersCosts"></lp-boolean-switch-box>                                                                                     
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-boolean-switch-box name="hasIndicatorApplicatonProRatedContractualCosts" switchName="hasIndicatorApplicatonProRatedContractualCosts" label="damages.hasIndicatorApplicatonProRatedContractualCosts"
                                         id="hasIndicatorApplicatonProRatedContractualCosts" [(ngModel)]="data.hasIndicatorApplicatonProRatedContractualCosts"></lp-boolean-switch-box>                                                                                     
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-boolean-switch-box name="isInvoicingMeterCosts" switchName="isInvoicingMeterCosts" id="isInvoicingMeterCosts"
                                        label="damages.isInvoicingMeterCosts" [(ngModel)]="data.isInvoicingMeterCosts"></lp-boolean-switch-box>                                                                                     
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-date id="returnDateSimulation" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}"
                                           label="damages.returnDateSimulation" name="returnDateSimulation" [(ngModel)]="data.returnDateSimulation"></lp-date>                                                                                     
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="returnOdometerSimulation" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="returnOdometerSimulation"
                                            label="damages.returnOdometerSimulation" [(ngModel)]="data.returnOdometerSimulation"></lp-number>                                                                                     
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-boolean-switch-box name="isReinvoicingAppraisalCosts" switchName="isReinvoicingAppraisalCosts"  label="damages.isReinvoicingAppraisalCosts"
                                        id="isReinvoicingAppraisalCosts" [(ngModel)]="data.isReinvoicingAppraisalCosts"></lp-boolean-switch-box>                                                                                    
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="amountExpertiseCosts" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="amountExpertiseCosts"
                                            [(ngModel)]="data.amountExpertiseCosts" label="damages.amountExpertiseCosts"></lp-number>                                                                                     
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="amountDamageBeforeWriteDown" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="amountDamageBeforeWriteDown"
                                            [(ngModel)]="data.amountDamageBeforeWriteDown" label="damages.amountDamageBeforeWriteDown"></lp-number>                                                                                    
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="rationBetweenExcessAndMinorityMeterCost" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="rationBetweenExcessAndMinorityMeterCost"
                                            [(ngModel)]="data.rationBetweenExcessAndMinorityMeterCost" label="damages.rationBetweenExcessAndMinorityMeterCost"></lp-number>                                                                                     
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="costWithoutDeductible" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="costWithoutDeductible"
                                            [(ngModel)]="data.costWithoutDeductible" label="damages.costWithoutDeductible"></lp-number>                                                                                    
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="costWithDeductible" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="costWithDeductible"
                                            [(ngModel)]="data.costWithDeductible" label="damages.costWithDeductible"></lp-number>                                                                                     
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="earlyRestitutionFinancialRent" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="earlyRestitutionFinancialRent"
                                            [(ngModel)]="data.earlyRestitutionFinancialRent" label="damages.earlyRestitutionFinancialRent"></lp-number>                                                                                    
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="earlyRestitutionServiceRent" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="earlyRestitutionServiceRent"
                                            [(ngModel)]="data.earlyRestitutionServiceRent" label="damages.earlyRestitutionServiceRent"></lp-number>                                                                                     
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="averageAmountServiceRents" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="averageAmountServiceRents"
                                            [(ngModel)]="data.averageAmountServiceRents" label="damages.averageAmountServiceRents"></lp-number>                                                                                    
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="amountServiceRentDue" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="amountServiceRentDue"
                                            [(ngModel)]="data.amountServiceRentDue" label="damages.amountServiceRentDue"></lp-number>                                                                                     
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="deductibleOdometerApplied" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="deductibleOdometerApplied"
                                            [(ngModel)]="data.deductibleOdometerApplied" label="damages.deductibleOdometerApplied"></lp-number>                                                                                    
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="runningOdometer" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="runningOdometer"
                                            [(ngModel)]="data.runningOdometer" label="damages.runningOdometer"></lp-number>                                                                                     
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="totalFinancialRentDue" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="totalFinancialRentDue"
                                            [(ngModel)]="data.totalFinancialRentDue" label="damages.totalFinancialRentDue"></lp-number>                                                                                    
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-label-field for="odometer2" class="{{arrayClassesElements.get('defaultLabel')}}"
                                           ></lp-label-field>
                                        <lp-number id="odometer2" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="odometer2"
                                            [(ngModel)]="data.odometer2" label="damages.odometer2"></lp-number>                                                                                     
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="form-group col-xl-6">
                                        <lp-number id="deductibleBreakGlass" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="deductibleBreakGlass"
                                            [(ngModel)]="data.deductibleBreakGlass" label="damages.deductibleBreakGlass"></lp-number>                                                                                    
                                    </div>
                                    <div class="form-group col-xl-6">
                                        <lp-number id="deductibleTheftOrFire" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="deductibleTheftOrFire"
                                            [(ngModel)]="data.deductibleTheftOrFire" label="damages.deductibleTheftOrFire"></lp-number>                                                                                     
                                    </div>
                                </div>
                                
                        </div>
                    </lp-ui-accordion-group>
                <lp-ui-accordion-group heading="Debugger Sinistre" *ngIf="debugg">
                    <div>
                    <pre>{{ data | json }}</pre>
                    </div>
                </lp-ui-accordion-group>
                </lp-ui-accordion>
            </div>
                <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
            </form>
        </div>
        <div class="col-md-6 card-box" #col2>
            <lp-right-col></lp-right-col>
        </div>
    </div>
        <div class="clearfix"></div>
    </div>
    <div class="clearfix"></div>