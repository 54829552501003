import { Component, OnInit, Input, forwardRef, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileFieldComponent } from '../file-field.component';

@Component({
  selector: 'lp-basic-file-field',
  templateUrl: './basic-file-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BasicFileFieldComponent),
      multi: true
    }
  ]
})
export class BasicFileFieldComponent extends FileFieldComponent implements OnInit, OnDestroy {
  @Input() public cssDownloadButton: Boolean;
  /**
   * Si le fichier attendu est une image, on propsose ou non un aperçu.
   */
  @Input() public image: Boolean = false;

  /**
   *  Extention autorisées.
   *  extention: file_extension|audio/*|video/*|image/*|media_type
   */
  @Input() public accept: String = '';

  /**
   * Intitulés des boutons.
   */
  @Input() public downloadable: Boolean = true;
  @Input() public idInput: string = 'id-' + Math.floor((10000) * Math.random() + 1);

  public async ngOnInit(): Promise<void> {
    try {
      await super.ngOnInit();
    } catch (error) {
      throw error;
    }
  }

  public async ngOnDestroy(): Promise<void> {
    try {
      await super.ngOnDestroy();
    } catch (error) {
      throw error;
    }
  }

  public async pushChange(event?: any): Promise<void> {
    try {
      super.pushChange();
      const fileList: FileList = event.target.files;
      if (fileList.length > 0) {
        const file: File = fileList[0];
        if (file.size <= this.configService.get('maxUploadFile')) {
          const myReader: FileReader = new FileReader();
          myReader.onloadend = async (e) => {
            this.currentTypeMime = await this.base64MimeType(myReader.result);
            await this.setIconTypeMime(this.currentTypeMime);
            this.setDisplayValue(myReader.result);
            this.isImageType();
          }
          myReader.readAsDataURL(file);
        } else {
          event.target.value = null;
          event.target.files = null;
          this.setDisplayValue(null);
          this.errorTooLarge();
        }
      }
      if (!this.ignoreChange) {
        this.setHasChanged(true, this);
      }
    } catch (error) {
      throw error;
    }
  }

  public deleteFile(): void {
    this.displayValue = null;
    this.currentTypeMime = null;
    if (!this.ignoreChange) {
      this.setHasChanged(true, this);
    }
  }

}
