<div class="modal-header">
  <h4 class="modal-title pull-left">{{'traces.titleList' | translate}}</h4>

  <div class="pull-right">
    <span (click)="changeConfigPanel()">
      <i class="icon fa fa-cogs icon-tableSetings ediTableSettings"></i>
    </span>
    <lp-button-cancel (action)="close()"></lp-button-cancel>
  </div>
</div>
<div class="modal-body">

  
  <ngx-datatable 
    class="material table-height-35" 
    [rows]="traces"
    [columnMode]="'force'"
    [headerHeight]="35"
    [rowHeight]="'auto'"
    [messages]="{emptyMessage: 'general.emptyMessage' |translate}" [rowClass]="rowClass"
    [footerHeight]="(traceStruct?.details[0]?.pagination || traceStruct?.details[0]?.bulk) ? 35 : 0"
    (activate)="selectItem($event);" 
    [loadingIndicator]="loadingIndicator"
    (page)="changePage($event)"
    (sort)="onSort($event)"
    width="100%">

    <ngx-datatable-column *ngFor="let col of getDisplayedColumns() index as colIndex"
      name="{{(col.wording !== null && col.wording !== undefined) ? col.wording : ((col.prop === 'actionColumn') ? '' : traceStruct?.details[0]?.key + '.' + col.name |translate)}}"
      [width]="col.width" [resizeable]="col.resizeable"
      [sortable]="col.sortable" [draggable]="col.draggable"
      data-prop="{{col.prop}}" [canAutoResize]="col.canAutoResize" [hidden]="!col.isDisplayed">

      <!-- template de base -->
      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row" let-i="index">

        <div>
          <lp-cell *ngIf="col.type != 'enum'"
            [type]="col.type"
            [value]="jsdataService.getDataRef(traces[rowIndex], col.prop)">
          </lp-cell>
          <div *ngIf="col.type === 'enum'">{{'traces.' + jsdataService.getDataRef(traces[rowIndex], col.prop) | translate}}</div>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer
      *ngIf="traceStruct?.details[0]?.pagination || traceStruct?.details[0]?.bulk">
      <ng-template ngx-datatable-footer-template>
        <lp-footer *ngIf="traceStruct?.details[0]?.pagination"
          (changePage)="changePage($event, traceStruct?.details[0]?.bulk)"
          [dataCount]="nbTraces"
          [linesPerPage]="userService.getCurrentUser().preference.linesPerPage" [currentPage]="currentPage"
          [allLines]=true [showAddRow]="traceStruct?.details[0]?.bulk" (addRow)="addRow($event)">
        </lp-footer>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>

  <div *ngIf="showConfigPanel" class="detail-config-tab">
    <lp-ui-sort-list [isBulk]="traceStruct?.details[0]?.bulk" [isNotADetailButAModal]='true' [list]="traceStruct.details[0].columns" prefix="{{traceStruct?.details[0]?.key + '.'}}" label="title" prop="isDisplayed"></lp-ui-sort-list>
  </div>

  <lp-ui-accordion class="row" [menu]=true *ngIf="showDetails">
    <lp-ui-accordion-group heading="{{contentTitle}}" [open]=true key="infos">
      <div class="container">
        <div class="row">
          <div class="form-group col-xl-12">
            <p> {{trace.content}} </p>
          </div>
        </div>
      </div>
    </lp-ui-accordion-group>
  </lp-ui-accordion>

</div>
