<div class="lp-ui-loader {{placement}}" [class.show]="active" [class.no-overlay]="!overlay">
  <div class="overlay" *ngIf="overlay"></div>

  <div>
    <span class="svg">
      <lp-svg-loader></lp-svg-loader>
    </span>
    <span *ngIf="message !==''">{{message}}</span>
  </div>
</div>
