import 'reflect-metadata';
import { PropertyDescriptor } from '../models/property-descriptor';
import { plainToClassFromExist } from 'class-transformer';
import { Util } from 'app/statics/utils';

const DEFAULT_EMPY_STRING: String = null;
const DEFAULT_EMPY_NUMBER: Number = null;
const DEFAULT_EMPY_DATE: Date = null;
const DEFAULT_EMPY_BOOLEAN: Boolean = true;

const dataHeaders: Array<String> = ['_xVegaAttachmentsCount',  '_xVegaEdisCount', '_xVegaWorkflowsCount', '_readOnly', '_currentActionData', '_unoptimizedKeys'];
export const READ_ONLY_FIELD_NAME: String = '_readOnly';
export const CURRENT_ACTION_DATA: String = '_currentActionData';
export const RELATION_SON: String = 'son';
export const RELATION_FATHER: String = 'father';

export function propertyMeta(type: string): any {
    return function (target: any, propertyKey: string): any {
        let propDesc: PropertyDescriptor = { name: propertyKey, type: type };
        Reflect.defineMetadata(Symbol(propertyKey), propDesc, target);
    }
}


export class LpMeta {
    @propertyMeta('string')
    public id: String;
    @propertyMeta('string')
    public details: String;
    @propertyMeta('boolean')
    public _readOnly: Boolean = false;
    @propertyMeta('number')
    public _xVegaWorkflowsCount: Number = 0;
    @propertyMeta('number')
    public _xVegaAttachmentsCount: Number = 0;
    @propertyMeta('number')
    public _xVegaEdisCount: Number = 0;
    public _currentActionData?:  any;
    

    protected _unoptimizedKeys: String[];

    constructor() {
        this.metaCreate(this);
    }

    public assign(data: any): LpMeta {
        /*Object.keys(data).forEach((key) => (data[key] == null) && delete data[key]);
        return plainToClassFromExist(this, data);*/

        let tableKey: string[] = Object.keys(data);
        for (let i: number = 0; i<tableKey.length; i=i+1) {
            if (data[tableKey[i]] !== null && typeof data[tableKey[i]] === 'object') {
                let tableSubKey: string[] = Object.keys(data[tableKey[i]]);
                try {
                    for (let j: number = 0; j<tableSubKey.length; j=j+1) {
                        if (data[tableKey[i]][tableSubKey[j]] == null && typeof this[tableKey[i]][tableSubKey[j]] === 'object' ) {
                        //if (data[tableKey[i]][tableSubKey[j]] == null && tableSubKey[j] !== 'id' && tableSubKey[j] !== 'details') {
                            delete data[tableKey[i]][tableSubKey[j]];
                        }
                    }
                } catch (e) {
                    console.log(typeof data + ' : '+ tableKey[i] + '     ' + e)
                }
            }
            if (data[tableKey[i]] === null) {
                delete data[tableKey[i]];
            }
        }

        return plainToClassFromExist(this, data);        
    }

    public optimized(): any {
        let optData: any = new Object();
        Object.keys(this).forEach((key) => {
            if (this[key] instanceof LpMeta) {
                if (!Util.isNullOrUndefined(this._unoptimizedKeys) && this._unoptimizedKeys.indexOf(key) > -1) {
                    optData[key] = this[key].optimized();
                } else if (this[key].id != null) {
                    optData[key] = { id: this[key].id };
                } else {
                    optData[key] = null;
                }
            } else if (dataHeaders.indexOf(key) == -1 ) {
                optData[key] = this[key];
            }
        });
        return optData;
    }

    public isSubObject(key: String): boolean {
        return (this._unoptimizedKeys && this._unoptimizedKeys.indexOf(key) > -1);
    }

    public renew(): void {
        let propertiesList: Array<any> = Reflect.getMetadataKeys(this);
        for (let property of propertiesList) {
            let propDesc: PropertyDescriptor = Reflect.getMetadata(property, this)
            if (propDesc !== undefined) {
                if (propDesc.type === 'string') {
                    this[propDesc.name] = DEFAULT_EMPY_STRING;
                } else if (propDesc.type === 'number') {
                    this[propDesc.name] = DEFAULT_EMPY_NUMBER;
                } else if (propDesc.type === 'date') {
                    this[propDesc.name] = DEFAULT_EMPY_DATE;
                } else if (propDesc.type === 'boolean') {
                    this[propDesc.name] = DEFAULT_EMPY_BOOLEAN;
                }
            }
        }
        propertiesList = Object.keys(this);
        for (let property of propertiesList) {
            if (this[property] instanceof LpMeta) {
                this[property].renew();
            }
        }
    }

    public hasPropertyIsActive(): boolean {
        let propertiesList: Array<any> = Reflect.getMetadataKeys(this);
        for (let property of propertiesList) {
            let propDesc: PropertyDescriptor = Reflect.getMetadata(property, this)
            if (propDesc.name === 'isActive') {
                return true;
            }
        }
        return false;
    }

    private metaCreate(obj: any): void {
        let propertiesList: Array<any> = Reflect.getMetadataKeys(obj);
        for (let property of propertiesList) {
            let propDesc: PropertyDescriptor = Reflect.getMetadata(property, obj)
            if (obj[propDesc.name] === undefined) {
                if (propDesc.type === 'string') {
                    obj[propDesc.name] = DEFAULT_EMPY_STRING;
                } else if (propDesc.type === 'number') {
                    obj[propDesc.name] = DEFAULT_EMPY_NUMBER;
                } else if (propDesc.type === 'date') {
                    obj[propDesc.name] = DEFAULT_EMPY_DATE;
                } else if (propDesc.type === 'boolean') {
                    obj[propDesc.name] = DEFAULT_EMPY_BOOLEAN;
                }
            }
        }
    }
}
