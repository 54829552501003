import { LpMeta, propertyMeta } from './meta';

export class LpLink extends LpMeta {
    @propertyMeta('string')
    public fatherWording: String;
    @propertyMeta('string')
    public sonWording: String;
    @propertyMeta('boolean')
    public isActive: Boolean;
}
