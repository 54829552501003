import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../../../services/modal.service';
import { LpModalComponent } from '../../lp-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UiSyncService } from 'app/services/ui-sync.service';
import { LpEdi } from 'app/meta/edi';
import { EdiProvider } from 'app/providers/edi.provider';
import { FormStackService } from 'app/services/form-stack.service';
import { BEFORE_DELETE_EVENT, EvalService, MAIN } from 'app/services/eval.service';
import { ConfigService } from 'app/services/config.service';
import { UserHistoryService } from 'app/services/user-history.service';
@Component({
  selector: 'lp-modal-edi-confirm',
  templateUrl: './lp-edi-confirm.component.html'
})
export class LpModalEdiConfirmComponent extends LpModalComponent {

  public relatedObject: String;
  public relatedObjectId: String;
  public ky: string;
  public verb: string;
  public listeMessages: any = {
    emptyMessage: '',
    totalMessage: ''
  };
  public edis: LpEdi[] = [];
  public currentEdi: LpEdi = new LpEdi();
  public showCancelAction: Boolean = false;
  public ediPerPage: number = this.configService.get('edisLimitInList');
  public actualPageNumber: number = 1;
  public nbEdi: number = 0;

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    public modalService: ModalService,
    private ediProvider: EdiProvider,
    public uiSyncService: UiSyncService,
    private formStackService: FormStackService,
    private evalService: EvalService,
    private configService: ConfigService,
    private userHstoryService: UserHistoryService
  ) {
    super(dialogRef, data);
  }

  public closeModal(modal: String): void {
    this.close();
  }

  public async deleteEdi(): Promise<void> {
    try {
      await this.evalService.executeContrainte(
        BEFORE_DELETE_EVENT, MAIN , 'EDI', '*', this.data, null, null, this.formStackService.currentData);
      await this.ediProvider.deleteEDI();
      this.userHstoryService.addEvent('delete', this.formStackService.currentApplicationItem, this.formStackService.currentData.id, this.formStackService.currentApplicationItem.verb, null, this.translate.instant('userHistory.EDI')+' (ID '+this.ediProvider.ediSelectedForDelete+')');
      this.actionAfterDeleteEdis()
    } catch (error) {
      this.uiSyncService.loader(false);
      throw error;
    }
  }

  private actionAfterDeleteEdis(): void {
    this.uiSyncService.loader(false);
    this.modalService.success(this.translate.instant('general.modalService.deletionCompleted'),
      this.translate.instant('general.modalService.success'));
    this.close();
  }


}
