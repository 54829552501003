
<!--<div class="under-menu" id="under-menu" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)">
  <div class="container-fluid row ps-0 pe-0">
    <i *ngIf="showTable" class="icon fa fa-cogs icon-tableSetings mt-2 pt-1 text-white" (click)="showListColumn = !showListColumn"></i>
    <div class="mb-0 col-11 page-anchors navbar-custom">
      <h2>        
        <i class="{{formStackService.currentApplicationItem.classes}}"></i>{{formStackService.currentApplicationItem.title | translate}}        
      </h2>
      <lp-ui-accordion-menu></lp-ui-accordion-menu>
    </div>
    <div class="clearfix"></div>
  </div>
</div>-->
<div class="container-fluid container-fluid-2-cols">
<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" titleTranslationKey="pricingDetails.pricingDetails"></lp-top-banner>
<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)">
  <div class="DIVlistActiveColumn p-0" [class.d-none]="!showListColumn">
    <a class="ms-2 btn btn-danger panel-close pull-right mt-2 me-3" aria-label="Close" (click)="showListColumn = !showListColumn">
      <i class="fa fa-times"></i>
    </a>
    <h3 class="mt-3 ms-3 mb-2">
      {{'pricingDetails.configure' | translate}}
    </h3>
    
    <lp-ui-accordion [closeOthers]="true" class="pricing-details-config-panel">
      <lp-ui-accordion-group heading="pricingDetails.manageColumn" [open]=true>      
        <lp-ui-sort-list [list]="availableColums" prefix="pricingDetails." label="title" prop="active"></lp-ui-sort-list>
      </lp-ui-accordion-group>
    </lp-ui-accordion>
  </div>
  <div class="container container-2-cols left">
    <div class="card-box col-lg-3 col-md-4 label-2-colonnes">
      <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
        <lp-common-tools *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id"
          [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="deleteButton"
          [showPrintInput]="false"></lp-common-tools>
        <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
          <lp-ui-accordion [menu]=true>
              <lp-ui-accordion-group heading="pricingDetails.selection" [open]=true key="selection">
                <div class="container p-0">
                  <!-- entête de tarif -->
                  <div class="row">
                    <div class="form-group col-12">
                        <lp-zoom-field id="pricing" zoomId='pricing' className="zoomField" cssClass="form-control" [(ngModel)]="data.pricing"
                          name="pricing" [ignoreChange]="true" label="pricingDetails.pricing">
                        </lp-zoom-field>                          
                    </div>
                  </div>
                  
                  <!-- Début de validité -->
                  <div class="row">
                    <div class="form-group col-12">
                      <lp-zoom-field className="zoomField" cssClass="form-control" [(ngModel)]="data.pricingTime" label="pricingDetails.pricingTime"
                        name="pricingTime" id="pricingTime" zoomId="pricingTime" [ignoreChange]="true">
                      </lp-zoom-field>         
                    </div>
                  </div>
                  
                  <!-- Tiers -->
                  <div class="row">
                    <div class="form-group col-12">
                        <lp-zoom-field id="thirdParty" zoomId="thirdParty" className="zoomField" cssClass="form-control" [(ngModel)]="data.thirdParty"
                        libSearch="id" addModifyVerb="third-parties" name="thirdParty" [ignoreChange]="true" label="pricingDetails.thirdParty">
                        </lp-zoom-field>         
                    </div>
                  </div>

                  <!-- Pays -->
                  <div class="row">
                    <div class="form-group col-12">
                      <!--<lp-dropdown-field  cssClass="form-control" className="textField" id="country" name="country" [ignoreChange]="true" [ignoreChange]="true" label="pricingDetails.country"
                      [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'country'}]}" [(ngModel)]="data.country"></lp-dropdown-field>-->
                      <lp-zoom-field zoomId="country" id="country" name="country" cssClass="form-control" label="pricingDetails.country"
                        class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.country"> </lp-zoom-field>
                    </div>
                  </div>

                  <!-- Société -->
                  <div class="row">
                    <div class="form-group col-12">
                      <lp-zoom-field id="companies" cssClass="form-control" [(ngModel)]="data.company" [ignoreChange]="true" label="pricingDetails.company"
                      className="zoomField" zoomId="companies" name="company" [ignoreChange]="true">
                      </lp-zoom-field>
                    </div>
                  </div>

                  <!-- Secteur
                  <div class="row">
                    <div class="form-group col-12">
                      Secteur d'agence : --- A faire ---
                    </div>
                  </div> -->

                  <!-- Agence -->
                  <div class="row">
                    <div class="form-group col-12">
                      <lp-zoom-field zoomId='locations' className="zoomField" cssClass="form-control" label="pricingDetails.location" [(ngModel)]="data.location"
                      name="location" id="location" [ignoreChange]="true">
                      </lp-zoom-field>
                    </div>
                  </div>

                  <!-- Modèle -->
                  <div class="row">
                    <div class="form-group col-12">
                      <lp-zoom-field zoomId='models' className="zoomField" cssClass="form-control" label="pricingDetails.model" [(ngModel)]="data.model" name="model"
                      id="model" [ignoreChange]="true">
                      </lp-zoom-field>
                    </div>
                  </div>

                  <!-- Type d'équipement -->
                  <div class="row">
                    <div class="form-group col-12">
                      <!--<lp-dropdown-field  cssClass="form-control" className="textField" id="equipmentType" name="equipmentType" [ignoreChange]="true" label="pricingDetails.equipmentType"
                      [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'equipmentType'}]}" [(ngModel)]="data.equipmentType"></lp-dropdown-field>-->
                      <lp-zoom-field zoomId="equipmentType" id="equipmentType" name="equipmentType" cssClass="form-control" label="pricingDetails.equipmentType"
                        class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.equipmentType"> </lp-zoom-field>
                    </div>
                  </div> 

                  <!-- Catégoprie -->
                  <div class="row">
                    <div class="form-group col-12">
                      <!--<lp-dropdown-field  cssClass="form-control" className="textField" id="category" name="category" label="pricingDetails.category"
                      [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'category'}]}" [(ngModel)]="data.category" [ignoreChange]="true"></lp-dropdown-field>-->
                      <lp-zoom-field zoomId="category" id="category" name="category" cssClass="form-control" label="pricingDetails.category"
                        class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.category"> </lp-zoom-field>
                    </div>
                  </div>

                  <!-- Catégoprie ACRISS -->
                  <div class="row">
                    <div class="form-group col-12">
                      <!--<lp-dropdown-field  cssClass="form-control" className="textField" id="acriss" name="acriss" label="pricingDetails.acriss"
                      [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'acriss'}]}" [(ngModel)]="data.acriss" [ignoreChange]="true"></lp-dropdown-field>-->
                      <lp-zoom-field zoomId="acriss" id="acriss" name="acriss" cssClass="form-control" label="pricingDetails.acriss"
                        class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.acriss"> </lp-zoom-field>
                    </div>
                  </div>
                  

                  <!-- Tarif groupe
                  <div class="row">
                    <div class="form-group col-12">
                        Tarif groupe : --- A faire ---
                    </div>
                  </div> -->

                  <!-- Prestation -->
                  <div class="row">
                    <div class="form-group col-12">
                      <lp-zoom-field zoomId='services' className="zoomField" cssClass="form-control" label="pricingDetails.service" [(ngModel)]="data.service"
                      name="service" id="service" [ignoreChange]="true">
                      </lp-zoom-field>
                    </div>
                  </div>
              </div>
            </lp-ui-accordion-group>
            <!--<lp-ui-accordion-group heading="pricingDetails.selection2" key="selection2">
                
                <div class="row">
                  <div class="form-group col-12">
                    <lp-zoom-field zoomId='pricingMethod' className="zoomField" cssClass="form-control" label="pricingDetails.pricingMethod"
                    [(ngModel)]="data.pricingMethod" name="pricingMethod" id="pricingMethod">
                    </lp-zoom-field>
                  </div>
                </div>

                
                <div class="row">
                  <div class="form-group col-12">
                    <lp-dropdown-field  cssClass="form-control" className="textField" id="calculationMode" name="calculationMode" label="pricingDetails.calculationMode"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'calculationMode'}]}" [(ngModel)]="data.calculationMode"></lp-dropdown-field>
                  </div>
                </div>

                
                <div class="row">
                  <div class="form-group col-12">
                    Mode d'utilisation : --- A faire ---
                  </div>
                </div>

                Quantité - NON DISPO dans le back
                <div class="row">
                  <div class="form-group col-12">
                    Quantité : Zoom sur LocPro / Number dans le swagger
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                    <lp-dropdown-field  cssClass="form-control" className="textField" id="currency" name="currency" label="pricingDetails.currency"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'currency'}]}" [(ngModel)]="data.currency"></lp-dropdown-field>
                  </div>
                </div>

                
                <div class="row">
                  <div class="form-group col-12">
                      Mode de la ligne : --- A faire ---
                  </div>
                </div>


                <div class="row">
                  <div class="form-group col-12">
                    <lp-zoom-field className="zoomField" cssClass="form-control" [(ngModel)]="data.user" name="user" id="user" zoomId="user" label="pricingDetails.user">
                    </lp-zoom-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                    <lp-dropdown-field  cssClass="form-control" className="textField" id="axisValue1" name="axisValue1" label="pricingDetails.axisValue1"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'axisValue'}]}" [(ngModel)]="data.axisValue1"></lp-dropdown-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                    <lp-dropdown-field  cssClass="form-control" className="textField" id="axisValue2" name="axisValue2" label="pricingDetails.axisValue2"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'axisValue'}]}" [(ngModel)]="data.axisValue2"></lp-dropdown-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                    <lp-dropdown-field  cssClass="form-control" className="textField" id="axisValue3" name="axisValue3" label="pricingDetails.axisValue3"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'axisValue'}]}" [(ngModel)]="data.axisValue4"></lp-dropdown-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                      Données comm. : --- A faire ---
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                      Type de tiers : --- A faire ---
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                      Rattachement d'évènement : --- A faire ---
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                      Référence tiers : --- A faire ---
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                    <lp-dropdown-field  cssClass="form-control" className="textField" id="documentType" name="documentType" label="pricingDetails.documentType"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'documentType'}]}" [(ngModel)]="data.documentType"></lp-dropdown-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                      Champ significatif : --- A faire ---
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                    <lp-dropdown-field  cssClass="form-control" className="textField" id="brand" name="brand" label="pricingDetails.brand"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'brand'}]}" [(ngModel)]="data.brand"></lp-dropdown-field>
                  </div>
                </div>

            </lp-ui-accordion-group>              
            <lp-ui-accordion-group heading="pricingDetails.selection3" key="selection3">

                <div class="row">
                  <div class="form-group col-12">
                    <lp-dropdown-field  cssClass="form-control" className="textField" id="expenseType" name="expenseType" label="pricingDetails.expenseType"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'expenseType'}]}" [(ngModel)]="data.expenseType"></lp-dropdown-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                    <lp-dropdown-field  cssClass="form-control" className="textField" id="fleetPlanType" name="fleetPlanType" label="Details.fleetPlanType"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'fleetPlanType'}]}" [(ngModel)]="data.fleetPlanType"></lp-dropdown-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                      Champ significatif : --- Manque la FT ---
                  
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                    <lp-dropdown-field  cssClass="form-control" className="textField" id="family" name="family" label="pricingDetails.family"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'family'}]}" [(ngModel)]="data.family"></lp-dropdown-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                      Statut 1 : --- Manque la FT ---
                
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                      Statut 2 : --- Manque la FT ---
                
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                      Niveau d'utilisateur : Zoom sur LocPro / String dans le swagger
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-12">
                      Budget : Zoom sur LocPro / String dans le swagger
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                    <lp-dropdown-field  cssClass="form-control" className="textField" id="discountType" name="discountType" label="pricingDetails.discountType"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'discountType'}]}" [(ngModel)]="data.discountType"></lp-dropdown-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                      Activité : --- A faire ---
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                    <lp-dropdown-field  cssClass="form-control" className="textField" id="documentTypeA" name="documentTypeA" label="pricingDetails.documentTypeA"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'documentType'}]}" [(ngModel)]="data.documentTypeA"></lp-dropdown-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                      Options ou équipements : --- A faire ---
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                    <lp-dropdown-field  cssClass="form-control" className="textField" id="volumeBonus" name="volumeBonus" label="pricingDetails.volumeBonus"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'volumeBonus'}]}" [(ngModel)]="data.volumeBonus"></lp-dropdown-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                    <lp-dropdown-field  cssClass="form-control" className="textField" id="locationKind" name="locationKind" label="pricingDetails.locationKind"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'locationKind'}]}" [(ngModel)]="data.locationKind"></lp-dropdown-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-12">
                    <lp-zoom-field className="zoomField" cssClass="form-control" [(ngModel)]="data.equipment" name="equipment"
                    label="pricingDetails.equipment" id="equipment" zoomId="equipments">
                    </lp-zoom-field>
                  </div>
                </div>

            </lp-ui-accordion-group>
            <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
              <div>
                <pre>{{ data | json }}</pre>
              </div>
            </lp-ui-accordion-group>--> 

          </lp-ui-accordion>
          
        </div>
        <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
            
        <i class="icon vega-search pricing-detail-search-btn" (click)="search()"></i>
      </form>
    </div>

    <div class="col-md-6 card-box col-lg-9 col-md-8 ps-3 pe-3 panel-group" #col2>
      <lp-right-col></lp-right-col>
    </div>
    <!--div class="col-lg-9 col-md-8 card-box tableWithInput ps-3 pe-3 panel-group">  
      <div *ngIf="showTable" [ngClass]="'panel card panel-primary open'">
        <div [ngClass]="'panel-heading card-header'">
          <h5>{{'pricingDetails.titlePanel' | translate}} </h5>
        </div>
        <div class="modal-body overflow-hidden">

          <ngx-datatable 
              [rows]="listPricingDetails"
              [headerHeight]="35"
              [rowHeight]="'auto'"
              [messages]="{emptyMessage: 'general.emptyMessage' |translate}"       
              class="material fullscreen table-height-35"
              [columnMode]="force"
              (sort)="onSort($event)"
              [scrollbarH]="true">
    
            <ngx-datatable-column *ngFor="let col of activeColums() index as colIndex"
              name="{{'pricingDetails.' + col.title |translate}}"
              [resizeable]="col.resizeable" [sortable]="col.sortable" [draggable]="false" [width]="(col.width && col.sortable)? col.width + 10 : col.width" data-prop="{{col.prop}}"
              [canAutoResize]="col.canAutoResize">
            
              <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row" let-i="index">                         
                <lp-cell
                  *ngIf="!col.modify" [type]="col.type" 
                  [value]="uiSyncService.getValueFromPath(row, col.prop)">
                </lp-cell>
                <lp-text-field
                  *ngIf="col.modify && (!col.type || col.type === 1)"
                  id="{{col.prop}}-{{rowIndex}}" name="{{col.prop}}-{{rowIndex}}" [(ngModel)]="row[col.prop]"></lp-text-field>
                <lp-number *ngIf="col.modify && col.type === 2"
                  id="{{col.prop}}-{{rowIndex}}" name="{{col.prop}}-{{rowIndex}}" [(ngModel)]="row[col.prop]"></lp-number>
                <lp-currency
                *ngIf="col.modify && (!col.type || col.type === 10)"
                  id="{{col.prop}}-{{rowIndex}}" name="{{col.prop}}-{{rowIndex}}" [(ngModel)]="row[col.prop]"></lp-currency>
    
                <div *ngIf="isLoading && col.prop !== 'actionColumn' && !col.modify && (isLoadingID == row.id || row.id == 'footer_table')" class="loading">
                  <lp-ui-loader-mini></lp-ui-loader-mini>              
                </div>
              </ng-template>
            </ngx-datatable-column>enregistrer les lignes
          </ngx-datatable>
          <i class="vega-diskette pricing-detail-save-btn pull-right" title="{{'general.button.saveLines' |translate}}" (click)="saveLInes()"></i>
          <i class="vega-delete pricing-detail-save-btn pull-right me-4" title="{{'general.button.cancelChanges' |translate}}" (click)="search()"></i>
        </div>
      </div>
    </div>-->
  </div>
  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>