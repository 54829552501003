import { LpMeta } from './meta';

export class LpLogin extends LpMeta {

    public username: String;
    public password: String;

    constructor(name: String, pass: String) {
        super();
        this.username = name;
        this.password = pass;
    }
}
