import { Component, Input, OnInit } from '@angular/core';
import { InputField } from 'app/models/input-field';
import { LpMeta } from 'app/meta/meta';
import { FormStackService } from 'app/services/form-stack.service';
import { EvalService } from 'app/services/eval.service';
import { Util } from 'app/statics/utils';
import { UiSyncService } from 'app/services/ui-sync.service';
import { JsdataService } from 'app/services/jsdata.service';

@Component({
  selector: 'lp-fields',
  templateUrl: './fields.component.html',
  styleUrls: [ './fields.component.scss']
})
export class FieldsComponent implements OnInit {

  @Input() public f: InputField;
  @Input() public data: LpMeta | any;
  @Input() public detailId: string = '';
  @Input() public customClass: string;
  @Input() public translationPrefix: string = '';
  @Input() public namePrefix: string = ''
  @Input() public isDetail: boolean
  @Input() public id: string;
  public availableEval: boolean = true;
  public textForLabelField: string = '';

  constructor(public formStackService: FormStackService,
              private evalService: EvalService,
              private uisyncService: UiSyncService,
              private jsdataService: JsdataService) {}

  public async ngOnInit(): Promise<void> {
    if (Util.isNullOrUndefined(this.f.hidden) || !this.f.hidden) {
      this.displayItem();
    }
    if (Util.isNullOrUndefined(this.id)) {
      this.id = this.detailId + this.f.prop
    }
    this.uisyncService.availableEvalForJsonInputField.subscribe(() => {
      this.displayItem();
    });
    if(Util.isNullOrUndefined(this.translationPrefix)){
      this.translationPrefix = '';
    }
    await this.translate(this.f);
  }

  public getDataRef(path: any): any {
    return this.jsdataService.getDataRef(this.data, path);
  }

  public setDataRef(path: any, value: any): any {
    return this.jsdataService.setDataRef(this.data, path, value);
  }

  public uploadedFile(event: any, output: string, data: LpMeta): void {
    if (output !== null && output !== undefined && output !== '') {
      this.evalService.eval(output, data, null, {'value': event});
    }
  }

  public getStyle(style1: any, name: any) {
    let style2: any = this.formStackService.getFields(name)?.style;
    if (!Util.isNullOrUndefined(style1) && !Util.isNullOrUndefined(style2)) {
      return Object.assign(style1,style2);
    } else if (!Util.isNullOrUndefined(style1) && Util.isNullOrUndefined(style2)) {
      return style1;
    } else if (!Util.isNullOrUndefined(style2)) {
      return style2;
    } else {
      return [];
    }
  }

  private displayItem(): void {
    if (!Util.isNullOrUndefined(this.f.availableEval)) {
      this.evalService.evalWithReturnStatement(this.f.availableEval, this.data).then(d => {
        this.availableEval = d;
      });
    }
  }

  private async translate(f: InputField): Promise<void> {
    try {
      if ( !Util.isNullOrUndefined(f) && f.label && !f.prop) {
        this.textForLabelField = `${this.translationPrefix}${f.label}`;
      }
    } catch (error) {
      throw error;
    }
  }

}
