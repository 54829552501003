import { ApplicationItemDetail } from 'app/models/application-item-detail';
import { fromEvent } from 'rxjs';
import { FormStackService } from './form-stack.service';
import { RightColService } from './root/rightCol.service';
import { UiSyncService } from './ui-sync.service';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { ConfigService } from './config.service';
import { ApplicationItemAction } from 'app/models/application-item-actions';
import * as _ from 'underscore';
import { ModalService } from './modal.service';
import { ApplicationItemActionsComponent } from 'app/FormComponents/application-item-actions/application-item-actions.component';

@Injectable({
  providedIn: 'root',
})
export class HostListenerService {
  constructor(
    private formStackService: FormStackService,
    private rightColService: RightColService,
    private uiSyncService: UiSyncService,
    private configService: ConfigService,
    private modalService: ModalService) { }

  public loadEvent(): void {
    // Raccourci clavier pour la navigation dans les applications items details
    fromEvent(window, 'keydown').pipe(
        filter( (event: KeyboardEvent) => event.ctrlKey && event.shiftKey && event.key !== 'F' && event.key.indexOf('F') === 0)
      )
      .subscribe((event: KeyboardEvent) => {
      this.onChangeSubAPI(event);
    });

    // Raccourci clavier pour la navigation dans les applications items actions
    fromEvent(window, 'keydown').pipe(
        filter( (event: KeyboardEvent) => event.ctrlKey && event.shiftKey)
      )
      .subscribe((event: KeyboardEvent) => {
      this.searchShortCutInActions(event);
    });

    // Permet de bloquer les popover ouverte (exemple : résumé dans le breadcrumb)
    fromEvent(window, 'keydown').pipe(
      filter( (event: KeyboardEvent) => !event.ctrlKey && !event.shiftKey && event.key === this.configService.get('shortcut').lockPopover )
    )
    .subscribe((event: KeyboardEvent) => {
      this.uiSyncService.lockPopover();
    });

    // Permet de bloquer les popover ouverte (exemple : résumé dans le breadcrumb)
    fromEvent(window, 'popstate').subscribe((event: KeyboardEvent) => {
      this.formStackService.clearLastStack();
    });

    // Utilisé dans le rootform lors du drag&drop pour décaler les colonnes
    fromEvent(window, 'dragstart').subscribe((event: any) => {
      event.dataTransfer.setData('text/plain', event.target.id);
      this.uiSyncService.lastDragEvent = event;
    });

    // Utilisée dans le composant drop-file
    fromEvent(window, 'dragover').subscribe((event: any) => {
      event.preventDefault();
      event.stopPropagation();
      this.uiSyncService.dragOver = true;
    });

    // Dragleave listener
    fromEvent(window, 'dragleave').subscribe((event: any) => {
      this.uiSyncService.dragOver = false;
    });
  }

  private async searchShortCutInActions(event: KeyboardEvent): Promise<void> {
    
    let action: ApplicationItemAction = _.find(this.formStackService.currentApplicationItem.actions, function (action: ApplicationItemAction): boolean {
      return (!action.disabled && action.RefAction !== undefined && action.RefAction.toUpperCase() === event.key);
    });

    if (action !== undefined) {
      if (!action.childFormLink && !action.disabled && action.classes !== 'disabled')  {
        this.modalService.modalPromise(ApplicationItemActionsComponent, {
          width : '600px',
          backdropClass: 'commonToolsBackdropClass',
          data: {
            rootFormAction: action
          }
        });
      } else if(action.childFormLink && !action.disabled) {
        await this.formStackService.addStackIem(null, undefined, action.childFormLink.childVerb.toString(), action.childFormLink.ky.toString(), false, false);
      }

      event.preventDefault();
      event.stopPropagation();      
    }
  }

  private onChangeSubAPI(event: KeyboardEvent): void {
      let numSubApi: number = parseInt(event.key.replace('F', ''), null);
      if (this.formStackService.currentApplicationItem.details[numSubApi - 1] &&
        this.formStackService.currentApplicationItem.details[numSubApi - 1].enable === true ) {
        const item: ApplicationItemDetail = this.formStackService.currentApplicationItem.details[numSubApi - 1];
        for(let d of this.formStackService.currentApplicationItem.details){
          d.active = false
        }
        item.active = true;
        this.rightColService.currentApplicationItemDetailLoading = item.id;
        this.formStackService.initLpLine(this.formStackService.currentApplicationItemDetail.key.toString())
        this.rightColService.displaySubAPIDetailsTab(item, this.formStackService.currentData);
        
      }
  }
}
