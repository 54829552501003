
<lp-ui-accordion id="{{id}}">
  <lp-ui-accordion-group heading="metadataApplicationItemDetails.depositForm.title" [open]=true>
    <form id="formDeposit" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>
      <div class="form-group col-xl-12">
        <lp-label-field for="deposit.depositStatus" class="{{arrayClassesElements.get('defaultLabel')}}"
        text="metadataApplicationItemDetails.depositForm.status" [required]="true"></lp-label-field>
        <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" id="deposit.depositStatus" [required]="true"
          cssClass="form-control" name="deposit.depositStatus" [(ngModel)]="data.status"
          [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'depositStatus'}]}"></lp-dropdown-field>
      </div>

      <div class="form-group col-xl-12">
        <lp-label-field for="deposit.date" class="{{arrayClassesElements.get('defaultLabel')}}"
        text="metadataApplicationItemDetails.depositForm.date"></lp-label-field>
        <lp-date [(ngModel)]="data.date" class="{{arrayClassesElements.get('defaultInput')}}" id="deposit.date"
          cssClass="form-control" name="depositsdate"
          ></lp-date>
      </div>

      <!--<div class="form-group col-xl-12">
        <lp-label-field for="dealNumber" class="{{arrayClassesElements.get('defaultLabel')}}"
        text="metadataApplicationItemDetails.depositForm.dealNumber"></lp-label-field>
        <lp-text-field id="dealNumber" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="dealNumber"
          [(ngModel)]="data.dealNumber"></lp-text-field>
      </div>-->
               
      <div class="form-group col-xl-12">
        <lp-label-field for="deposit.amount" class="{{arrayClassesElements.get('defaultLabel')}}" text="metadataApplicationItemDetails.depositForm.amount" [required]="true"></lp-label-field>
        <lp-currency id="deposit.amount" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" name="deposit.amount" [(ngModel)]="data.amount" minlength="3" [required]="true"></lp-currency>
      </div>                          

      <div class="form-group col-xl-12">
        <lp-label-field for="deposit.paymentMethod" class="{{arrayClassesElements.get('defaultLabel')}}" 
        text="metadataApplicationItemDetails.depositForm.paymentMethod" [required]="true"></lp-label-field>
        <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" id="deposit.paymentMethod"
      cssClass="form-control" name="deposit.paymentMethod" [(ngModel)]="data.paymentMethod" [path]="{'verb': 'payment-methods'}" [required]="true"></lp-dropdown-field>
      </div>
      <div class="form-group col-xl-12">
        <lp-label-field for="deposit.wording" class="{{arrayClassesElements.get('defaultLabel')}}" text="metadataApplicationItemDetails.depositForm.wording"></lp-label-field>
        <lp-text-field id="deposit.wording" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="deposit.wording"
          [(ngModel)]="data.wording"></lp-text-field>
      </div>  

      <!--<div class="form-group col-xl-12">
        <lp-label-field for="cardValidityDate" class="{{arrayClassesElements.get('defaultLabel')}}"
        text="metadataApplicationItemDetails.depositForm.cardValidityDate"></lp-label-field>
        <lp-date-month [(ngModel)]="data.cardValidityDate" class="{{arrayClassesElements.get('defaultInput')}}"
          id="cardValidityDate" cssClass="form-control" name="cardValidityDate"></lp-date-month>
      </div>

      <div class="form-group col-xl-12">
        <lp-label-field for="cryptogram" class="{{arrayClassesElements.get('defaultLabel')}}"
        text="metadataApplicationItemDetails.depositForm.cryptogram"></lp-label-field>
        <lp-number id="cryptogram" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="cryptogram" minlength="3" maxlength="3"
          [(ngModel)]="data.cryptogram"></lp-number>
      </div>

      <div class="form-group col-xl-12">
        <lp-label-field for="groupingCode" class="{{arrayClassesElements.get('defaultLabel')}}"text="metadataApplicationItemDetails.depositForm.groupingCode"></lp-label-field>
        <lp-text-field id="groupingCode" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="groupingCode"
           [(ngModel)]="data.groupingCode"></lp-text-field>
      </div>  

      <div class="form-group col-xl-12">
        <lp-label-field for="agreementDeadLine" class="{{arrayClassesElements.get('defaultLabel')}}"
        text="metadataApplicationItemDetails.depositForm.agreementDeadLine"></lp-label-field>
        <lp-date [(ngModel)]="data.agreementDeadLine" class="{{arrayClassesElements.get('defaultInput')}}" id="agreementDeadLine"
          cssClass="form-control" name="date"
          ></lp-date>
      </div> 

      <div class="form-group col-xl-12">
        <lp-label-field for="documentNumber" class="{{arrayClassesElements.get('defaultLabel')}}" 
        text="metadataApplicationItemDetails.depositForm.documentNumber"></lp-label-field>
        <lp-text-field id="documentNumber" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="documentNumber"
          [(ngModel)]="data.documentNumber"></lp-text-field>
      </div>

      <div class="form-group col-xl-12">
        <lp-label-field for="cancellationDate" class="{{arrayClassesElements.get('defaultLabel')}}"
        text="metadataApplicationItemDetails.depositForm.cancellationDate"></lp-label-field>
        <lp-date [(ngModel)]="data.cancellationDate" class="{{arrayClassesElements.get('defaultInput')}}" id="cancellationDate"
          cssClass="form-control" name="date"
          ></lp-date>
      </div>

      <div class="form-group col-xl-12">
        <lp-label-field for="location" class="{{arrayClassesElements.get('defaultLabel')}}"
        text="metadataApplicationItemDetails.depositForm.location" [required]="true"></lp-label-field>
        <lp-zoom-field for="location" id="location" class="{{arrayClassesElements.get('defaultInput')}}"
            addModifyVerb="locations" kySearch="id" libSearch="wording" zoomId='location'
            className="zoomField" cssClass="form-control" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="data.location" [inputSearch]="data.location?.id" searchTextField="id" isLocProZoom="false"
            [tableBindValues]="['id', 'wording','details']" [tableShowField]="['id','wording']" [modal]="true"
            name="location" [readonly]="true">
          </lp-zoom-field>
      </div>

      <div class="form-group col-xl-12">
        <lp-label-field for="depositKind" class="{{arrayClassesElements.get('defaultLabel')}}" 
        text="metadataApplicationItemDetails.depositForm.kind"></lp-label-field>
        <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" id="depositKind"
          cssClass="form-control" name="depositKind" [(ngModel)]="data.kind"
          [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'depositKind'}]}"></lp-dropdown-field>
      </div>

      <div class="form-group col-xl-12"> 
        <lp-label-field for="company" class="{{arrayClassesElements.get('defaultLabel')}}"
        text="metadataApplicationItemDetails.depositForm.company" [required]="true"></lp-label-field>
        <lp-zoom-field id="companies" class="{{arrayClassesElements.get('defaultInput')}}"
        addModifyVerb="companies" kySearch="id" libSearch="wording" zoomId='companies' className="zoomField"
        cssClass="form-control" [(ngModel)]="data.company" [inputSearch]="data.company?.id" searchTextField="id"
        [tableBindValues]="['id', 'corporateName']" [tableShowField]="['id', 'corporateName']" 
         name="company" [readonly]="true"
        isLocProZoom="false"
        [modal]="true" idRootFormdetail="{{idRootFormdetail}}"
        [dataRootFormdetail]="data"></lp-zoom-field>
      </div>-->
      
      


      <div class="text-end">
        <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
      </div>
    </form>
  </lp-ui-accordion-group>
  <lp-ui-accordion-group heading="Debugger formulaire" *ngIf="debugg">
    <div>
      <pre>{{ data | json }}</pre>
    </div>
  </lp-ui-accordion-group>
</lp-ui-accordion>
