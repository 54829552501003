<lp-ui-accordion id="{{id}}">
  <lp-ui-accordion-group heading="third-parties.mandates.titleForm" [open]=true>
    <form id="formmandates" #formmandates="ngForm" (ngSubmit)="submit()" ngNativeValidate>
      <div class="form-group col-xl-12">
        <lp-label-field class="{{arrayClassesElements.get('defaultLabel')}}"
          text="third-parties.mandates.rib" for="mandatess.rib"></lp-label-field>
        <lp-zoom-field id="thirdPartiesMandates.rib" zoomId="mandates.ribs" name="thirdPartiesMandates.rib" [(ngModel)]="data.rib"
        class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField form-control" [readonly]="true">
        </lp-zoom-field>
      </div>

      <div class="form-group col-xl-12">
        <lp-label-field for="thirdPartiesMandates.rum" class="{{arrayClassesElements.get('defaultLabel')}}" text="third-parties.mandates.rum"></lp-label-field>
        <lp-text-field id="thirdPartiesMandates.rum" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="thirdPartiesMandates.rum"
           [(ngModel)]="data.rum"></lp-text-field>
      </div>

      <div class="form-group col-xl-12">
        <lp-label-field for="thirdPartiesMandates.signatureDate" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="third-parties.mandates.signatureDate"></lp-label-field>
        <lp-date id="thirdPartiesMandates.signatureDate" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" name="thirdPartiesMandates.signatureDate"
          [(ngModel)]="data.signatureDate"></lp-date>
      </div>

      <div class="form-group col-xl-12">
        <lp-label-field for="thirdPartiesMandates.utilisationDate" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="third-parties.mandates.utilisationDate"></lp-label-field>
        <lp-date id="thirdPartiesMandates.utilisationDate" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" name="thirdPartiesMandates.utilisationDate"
          [(ngModel)]="data.utilisationDate"></lp-date>
      </div>

      <div class="form-group col-xl-12">
        <lp-label-field for="thirdPartiesMandates.hasBeenUpdated" class="{{arrayClassesElements.get('defaultLabel')}}" text="third-parties.mandates.hasBeenUpdated"></lp-label-field>
        <lp-boolean-switch-box name="hasBeenUpdated" switchName="hasBeenUpdated" [(ngModel)]="data.hasBeenUpdated"></lp-boolean-switch-box>
      </div>
      
      <div class="form-group col-xl-12">
        <lp-label-field for="thirdPartiesMandates.mandateType" class="{{arrayClassesElements.get('defaultLabel')}}" text="third-parties.mandates.mandateType"></lp-label-field>
        <lp-text-field id="thirdPartiesMandates.mandateType" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="thirdPartiesMandates.mandateType"
           [(ngModel)]="data.mandateType" maxLength="4"></lp-text-field>
      </div>
      
      <div class="form-group col-xl-12">
        <lp-label-field for="thirdPartiesMandates.status" class="{{arrayClassesElements.get('defaultLabel')}}" text="third-parties.mandates.status"></lp-label-field>
        <lp-text-field id="thirdPartiesMandates.status" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="thirdPartiesMandates.status"
           [(ngModel)]="data.status"></lp-text-field>
      </div>

      <div class="form-group col-xl-12">
        <lp-label-field class="{{arrayClassesElements.get('defaultLabel')}}"
          text="third-parties.mandates.company" for="mandatess.companies"></lp-label-field>
        <lp-zoom-field id="thirdPartiesMandates.companies" zoomId="mandates.companies" name="thirdPartiesMandates.companies" [(ngModel)]="data.company"
        class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField form-control">
        </lp-zoom-field>
      </div>

      <div class="form-group col-xl-12">
        <lp-label-field for="thirdPartiesMandates.isActive" class="{{arrayClassesElements.get('defaultLabel')}}" text="third-parties.mandates.isActive"></lp-label-field>
        <lp-boolean-switch-box name="isActive" switchName="isActive" [(ngModel)]="data.isActive"></lp-boolean-switch-box>
      </div>

      <div class="text-end">
        <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
      </div>
    </form>
  </lp-ui-accordion-group>
  <lp-ui-accordion-group heading="Debugger mandates" *ngIf="debugg">
    <div>
      <pre>{{ data | json }}</pre>
    </div>
  </lp-ui-accordion-group>
</lp-ui-accordion>
