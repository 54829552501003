<div class="body-theme {{formStackService.CurrentVerb}}" [class.mainZoomBody]="formStackService.mainZoomBody"
     [class.not-connected]="!userConnected">
  <lp-ui-zoom-image></lp-ui-zoom-image>
  <lp-ui-zoom-pdf></lp-ui-zoom-pdf>
  <lp-ui-zoom-txt></lp-ui-zoom-txt>
  <lp-ui-loader></lp-ui-loader>
  <lp-progress-bar></lp-progress-bar>
  <lp-menu *ngIf="userConnected" [class.mainZoomShowed]="!uiSyncService.isKyForm && !uiSyncService.isNewForm"></lp-menu>
  <router-outlet></router-outlet>
</div>
