<lp-label-field *ngIf="label" for="{{id}}" class="control-label" [hidden]="this.formStackService.getFields(name) != null && this.formStackService.getFields(name).hidden"
[ignoreChange]="ignoreChange" text="{{label}}"  [required]="this.formStackService.getFields(name).mandatory"></lp-label-field>

<span ng-controller="textField" *ngIf="this.formStackService.getFields(name) != null && !this.formStackService.getFields(name).hidden">
  <input id="for{{id}}" name="name" type="{{inputType}}" class="{{className}} {{cssClass}}" [ngStyle]="style" title=""
    [(ngModel)]="displayValue"  minlength="{{minlength}}" maxlength="{{maxLength}}" on-focusout="pushChange();" (focus)="focus($event)" (keydown)="keyDown($event)"
     placeholder="{{placeholder | translate}}" [disabled]="this.formStackService.getFields(name).readonly" [required]="this.formStackService.getFields(name).mandatory && !this.formStackService.getFields(name).hidden" [readonly]="this.formStackService.getFields(name).readonly" autocomplete="{{autocomplete}}"
     [pattern]="pattern" [hidden]="hidden" (keyup)="change($event)"/>
  <i class="input-notif"></i>

  <span class="input-group-btn" *ngIf="type === 'password'">
    <button class="btn waves-effect waves-light btn-primary" type="button" (click)="changePasswordMode()" title="Afficher / Masquer le mot de passe." tabindex="-1">
      <i class="fa {{iconPassword}}"></i>
    </button>
  </span>

  <span class="input-group-addon" *ngIf="iconClass !== undefined && type !== 'password'">
    <i class="{{iconClass}}"></i>
  </span>
</span>