
<div *ngIf="type === formatService.type.Boolean" class="text-center">
  <span [class.boolean-true]="text" [class.boolean-false]="!text"></span>
</div>


<div *ngIf="type === formatService.type.Html && type !== formatService.type.Boolean"><span [innerHTML]="text"> </span></div>

<div *ngIf="type !== formatService.type.Boolean && type !== formatService.type.Html" 
  [class.text-end]="type === formatService.type.Number || type === formatService.type.Currency">{{text}}</div>
