import { LpMeta, propertyMeta } from './meta';

export class LpDriverInformations extends LpMeta {
    @propertyMeta('date')
    public birthDate: Date;
    @propertyMeta('string')
    public birthPlace: String;
    @propertyMeta('string')
    public birthNationality: String;
    @propertyMeta('string')
    public driverLicenseIssuingDate: String;
    @propertyMeta('string')
    public driverLicenseExpirationDate: String;
    @propertyMeta('string')
    public driverLicenseIssuingAuthority: String;
    @propertyMeta('string')
    public driverLicenseIssuingPlace: String;
    @propertyMeta('string')
    public driverLicenseType: String;
    @propertyMeta('string')
    public driverLicenseNumber: String;
    @propertyMeta('string')
    public passportIssuingDate: String;
    @propertyMeta('string')
    public passportExpirationDate: String;
    @propertyMeta('string')
    public passportIssuingAuthority: String;
    @propertyMeta('string')
    public passportIssuingPlace: String;
    @propertyMeta('string')
    public passportNumber: String;
}
