import { Component, OnInit, Input } from '@angular/core';
import { ValueFieldComponent } from '../value-field.component';
@Component({
  template: ''
})
export class InputFieldComponent extends ValueFieldComponent implements OnInit {
  /**
   *  Le paramètre Minlength correspond au nombre minimum de caractères du champ.
   */
  @Input() public minlength: Number = 0;

  /**
   *  Le paramètre Maxlength correspond au nombre maximum de caractères du champ.
   */
  @Input() public maxlength: Number;

  /**
   *  Le paramètre iconClass permet de afficher un icon si il est renseigné. (ex: "fa fa-credit-card" pour une carte).
   */
  @Input() public iconClass: string;


  /**
   *  Le paramètre autocomplete permet de de masquer l'autocompletion.
   */
  @Input() public autocomplete: String = this.configService.get('autocomplete');
}
