import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, INVOICE_LINES } from '../detail-forms.component';
import { LpLine } from 'app/meta/line';


@Component({
  selector: 'lp-invoice-lines',
  templateUrl: './invoice-lines.component.html'
})
export class InvoiceLinesComponent extends DetailFormsComponent implements OnInit {

  public data: LpLine = null;

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.arrayClassesElements.set('defaultLabel', this.configService.get('constanteClasses').DEFAULT_LABEL);
    this.arrayClassesElements.set('defaultInput', this.configService.get('constanteClasses').DEFAULT_INPUT);
    this.arrayClassesElements.set('defaultPostcode', this.configService.get('constanteClasses').DEFAULT_POSTE_CODE);
    this.setId(INVOICE_LINES);
    this.initUrl();
    // this.init();
  }

    public init(): void {
    this.data.assign(this.formStackService.currentDetailLineData);
  }

  protected initUrl(): void {
    if (this.object === 'short-term-contracts') {
      this.urlGet = '/invoices/{{customerInvoice.id}}/lines';
    } else {
      this.urlGet = '/invoices/{{id}}/lines';
    }
    this.urlEdit = this.urlGet;
  }
}
