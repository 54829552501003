import { Injectable } from '@angular/core';
import { AlocproProvider } from './alocpro.provider';
import { DomSanitizer} from '@angular/platform-browser';

@Injectable({
    providedIn: 'root'
})

export class ConstraintProvider {

    constructor(private alocproProvider: AlocproProvider, private _sanitizer: DomSanitizer) { }

    public async getConstraint(zone: string, moment?: string, variant?: string, lpService?: boolean, screen?: string): Promise<any>{
        return this._sanitizer.bypassSecurityTrustHtml(await this.alocproProvider.getConstraint(zone, moment, variant, lpService, screen));
    }
}
