<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" titleTranslationKey="mainAccounts.mainAccounts"></lp-top-banner>


<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">     
        <!--  bloc permettant de faire un drag & drop pour modifier la taille des colonnes -->
        <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''" (dblclick)="reInitCols()"></div>
        <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>

        <div class="container container-2-cols left" #conatinerCols>
            <div class="card-box col-md-6 label-2-colonnes" #col1>
                <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
                    <!--  composant pour afficher lien flottant à droite du formulaire-->
                    <lp-common-tools  *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [deleteButton]="deleteButton" [positionFromRight]="dragRight ? dragRight : null"  [ky]="ky" ></lp-common-tools>
                    <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
                    <lp-ui-accordion class="row" [menu]=true>
                            <lp-ui-accordion-group heading="mainAccounts.title" [open]=true key="general">
                                <div class="container">

                                    <div class="row">
                                        <div class="form-group col-xl-6">
                                            <lp-text-field id="id" className="textField" label="mainAccounts.id"
                                            class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="id"
                                                [(ngModel)]="data.id" type="text" [readonly]="true"></lp-text-field>
                                        </div>
                                        <div class="form-group col-xl-6">
                                            <lp-text-field id="wording" className="textField" label="mainAccounts.wording"
                                            class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="wording"
                                                [(ngModel)]="data.wording" type="text"></lp-text-field>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="form-group col-xl-6">
                                            <lp-text-field id="leadingWord" className="textField" label="mainAccounts.leadingWord"
                                                class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="leadingWord"
                                                    [(ngModel)]="data.leadingWord" type="text">
                                            </lp-text-field>
                                        </div>
                                        <div class="form-group col-xl-6">
                                            <lp-text-field id="code" className="textField" label="mainAccounts.code"
                                            class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="code"
                                            [(ngModel)]="data.code" type="text"></lp-text-field>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="form-group col-xl-6">
                                            <!--<lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" label="mainAccounts.accountType" className="textField" cssClass="form-control" id="accountType"
                                                name="accountType" [(ngModel)]="data.accountType" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'accountType'}]}"></lp-dropdown-field>-->
                                            <lp-zoom-field id="accountType" class="{{arrayClassesElements.get('defaultInput')}}" zoomId="accountType"  label="mainAccounts.accountType"
                                                className="zoomField" cssClass="form-control" [(ngModel)]="data.accountType" name="accountType">
                                            </lp-zoom-field>
                                        </div>
                                        <div class="form-group col-xl-6">
                                            <!--<lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" label="mainAccounts.collectiveAccountKind" className="textField" cssClass="form-control" id="collectiveAccountKind"
                                                name="collectiveAccountKind" [(ngModel)]="data.collectiveAccountKind" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'collectiveAccountKind'}]}"></lp-dropdown-field>-->
                                            <lp-zoom-field id="collectiveAccountKind" class="{{arrayClassesElements.get('defaultInput')}}" zoomId="collectiveAccountKind"  label="mainAccounts.collectiveAccountKind"
                                                className="zoomField" cssClass="form-control" [(ngModel)]="data.collectiveAccountKind" name="collectiveAccountKind">
                                            </lp-zoom-field>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="form-group col-xl-6">
                                            <lp-zoom-field id="company" class="{{arrayClassesElements.get('defaultInput')}}" zoomId="company"  label="mainAccounts.company"
                                            className="zoomField" cssClass="form-control" [(ngModel)]="data.company" name="company">
                                            </lp-zoom-field>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="form-group col-xl-6">
                                            <lp-boolean-switch-box class="{{arrayClassesElements.get('defaultInput')}}" id="isCentralize" label="mainAccounts.isCentralize"
                                            name="isCentralize" switchName="isCentralize" [(ngModel)]="data.isCentralize"></lp-boolean-switch-box>
                                        </div>
                                        <div class="form-group col-xl-6">
                                            <lp-boolean-switch-box class="{{arrayClassesElements.get('defaultInput')}}" id="isManuelSettlement" label="mainAccounts.isManuelSettlement"
                                             name="isManuelSettlement" switchName="isManuelSettlement" [(ngModel)]="data.isManuelSettlement"></lp-boolean-switch-box>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="form-group col-xl-6">
                                            <lp-boolean-switch-box class="{{arrayClassesElements.get('defaultInput')}}" id="isLettering" label="mainAccounts.isLettering"
                                             name="isLettering" switchName="isLettering" [(ngModel)]="data.isLettering"></lp-boolean-switch-box>
                                        </div>
                                        <div class="form-group col-xl-6">
                                            <lp-boolean-switch-box class="{{arrayClassesElements.get('defaultInput')}}" id="isVAT" name="isVAT" label="mainAccounts.isVAT"
                                            switchName="isVAT" [(ngModel)]="data.isVAT"></lp-boolean-switch-box>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="form-group col-xl-6">
                                            <lp-boolean-switch-box class="{{arrayClassesElements.get('defaultInput')}}" label="mainAccounts.isScoring" id="isScoring"
                                             name="isScoring" switchName="isScoring" [(ngModel)]="data.isScoring"></lp-boolean-switch-box>
                                        </div>
                                        <div class="form-group col-xl-6">
                                            <lp-boolean-switch-box class="{{arrayClassesElements.get('defaultInput')}}" id="isAnalytic" label="mainAccounts.isAnalytic"
                                            name="isAnalytic" switchName="isAnalytic" [(ngModel)]="data.isAnalytic"></lp-boolean-switch-box>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="form-group col-xl-6">
                                            <lp-boolean-switch-box class="{{arrayClassesElements.get('defaultInput')}}" label="mainAccounts.isAccountingProvision"
                                            id="isAccountingProvision" name="isAccountingProvision" switchName="isAccountingProvision" [(ngModel)]="data.isAccountingProvision"></lp-boolean-switch-box>
                                        </div>
                                    </div>
                                    
                            </div>
                        </lp-ui-accordion-group>
                    <lp-ui-accordion-group heading="Debugger Comptabilité" *ngIf="debugg">
                        <div>
                        <pre>{{ data | json }}</pre>
                        </div>
                    </lp-ui-accordion-group>
                    </lp-ui-accordion>
                </div>
                    <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
                </form>
            </div>
            <div class="col-md-6 card-box" #col2>
                <lp-right-col></lp-right-col>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
    <div class="clearfix"></div>