
<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" titleTranslationKey="reports.reports"></lp-top-banner>

<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)">
  
  <div class="DIVlistActiveColumn p-0" [class.d-none]="!showListColumn">
    <a class="ms-2 btn btn-danger panel-close pull-right mt-2 me-3" aria-label="Close" (click)="showListColumn = !showListColumn">
      <i class="fa fa-times"></i>
    </a>
    <h3 class="mt-3 ms-3 mb-2">
      {{'reports.configure' | translate}}
    </h3>
    
    <lp-ui-accordion [closeOthers]="true" class="pricing-details-config-panel">
      <lp-ui-accordion-group heading="pricingDetails.manageColumn" [open]=true>      
        <lp-ui-sort-list [list]="availableColums" prefix="pricingDetails." label="title" prop="active"></lp-ui-sort-list>
      </lp-ui-accordion-group>
    </lp-ui-accordion>
  </div>
  
  <div class="container container-2-cols left">
    <div class="card-box col-lg-3 col-md-4 label-2-colonnes pe-1">
      <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
        <lp-common-tools *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id"
          [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="deleteButton"
          [showPrintInput]="false"></lp-common-tools>
        <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
          <lp-ui-accordion [menu]=true>
              <lp-ui-accordion-group heading="pricingDetails.selection" [open]=true key="selection">
                <div class="container p-0">
               
                  <div class="row">
                    <div class="form-group col-12">
                      <lp-dropdown-field  cssClass="form-control" className="textField" id="reportingModule" name="reportingModule" [ignoreChange]="true" label="reports.reportingModule"
                      [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'reportingModule'}, {'key':'active', 'value': 'true'}]}" idField="object" labelField="wording" [(ngModel)]="data.reportingModule" (change)='chooseReportingModule()'></lp-dropdown-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="form-group col-12">
                      <lp-report-list [isInStatisticScreen]=true [data]='datasForList'></lp-report-list>
                    </div>
                  </div>

              </div>
            </lp-ui-accordion-group>
            

          </lp-ui-accordion>
          
        </div>
        <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
            
        <!--<i class="icon vega-search pricing-detail-search-btn" (click)="search()"></i>-->
      </form>
    </div>
    <div class="col-md-6 card-box col-lg-9 col-md-8 ps-3 pe-3 panel-group" #col2>
      <div class="row">
        <div class="form-group col-12">
          <lp-ui-accordion *ngIf="reportService && reportService.itemSelectedInList || reportService && reportService.srcPdf">
            <lp-ui-accordion-group heading="{{'reports.title' | translate}} - {{reportService.titleReport}} ({{reportService.modelName}})" [open]=true>
              <lp-report-parameters *ngIf="reportService && reportService.itemSelectedInList && reportService && !reportService.srcPdf" [itemSelectedForRefresh]='reportService.itemSelectedInList' [reportKy]='reportService.itemSelectedInList'></lp-report-parameters>
              <lp-pdf-viewer *ngIf="reportService && reportService.srcPdf && reportService.srcPdf.length > 0" [pdfSrc]="reportService.srcPdf"></lp-pdf-viewer>
            </lp-ui-accordion-group>
          </lp-ui-accordion>
        </div>
      </div>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>