import { Subject } from 'rxjs';

export const environment: any = {
  production: false,
  newdataKeyWord: 'new',
  verbose: true,
  firebaseConfig: {
    apiKey: "AIzaSyCZx9Xu1N4xKKUwIjb30DB33l9psBb37Wk",
    authDomain: "gyzmo-labs-push-notifications.firebaseapp.com",
    databaseURL: "https://gyzmo-labs-push-notifications.firebaseio.com",
    projectId: "gyzmo-labs-push-notifications",
    storageBucket: "gyzmo-labs-push-notifications.appspot.com",
    messagingSenderId: "1051326005741",
    appId: "1:1051326005741:web:0ce547e853a49a727867e4",
    measurementId: "G-PEDGFJPTPJ"
  },
  MY_MOMENT_FORMATS: {
    parseInput: 'L LT',
    fullPickerInput: 'L LT',
    datePickerInput: 'L',
    timePickerInput: 'LT',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
  DefaultIntl: {
    cancelBtnLabel: '',
    setBtnLabel: '',
    rangeFromLabel: '',
    rangeToLabel: '',
    changes: new Subject
  }
};
