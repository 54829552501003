import { Component, OnInit } from '@angular/core';
import { LpPaymentMethod } from 'app/meta/payment-method';
import { RootformComponent } from '../rootform/rootform.component';

@Component({
  selector: 'lp-payment-method',
  templateUrl: './payment-method.component.html'
})
export class PaymentMethodComponent extends RootformComponent implements OnInit {
  public ky: string = null;
  public data: LpPaymentMethod = null;

  public async ngOnInit(): Promise<void> {
    await super.build('payment-methods', this.route.snapshot.params['ky']);
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.data.assign(this.formStackService.currentData);
    }
  }
}
