import { Component, OnInit, OnChanges, ViewChild, Inject } from '@angular/core';
import { FormStackService } from 'app/services/form-stack.service';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { LpMeta } from 'app/meta/meta';
import { VersionProvider } from 'app/providers/version.provider';
import { LpModalComponent } from '../lp-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService } from 'app/services/config.service';

@Component({
  selector: 'lp-debug',
  templateUrl: './lp-modal-debug.component.html'
})
export class LpModalDebugComponent extends LpModalComponent implements OnInit, OnChanges {
  public arrayClassesElements: Map<String, String> = new Map<String, String>();
  public jsonEditorOptions: JsonEditorOptions;
  public formStackServiceData: LpMeta;
  public appVersion: any;
  public repositoryVersion: any;
  @ViewChild('editor', { static: true }) public editor: JsonEditorComponent;

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formStackService: FormStackService,
    private versionProvider: VersionProvider,
    private configService: ConfigService) {
      super(dialogRef, data);
    }


  public ngOnInit(): void {
    this.jsonEditorOptions = new JsonEditorOptions();
    this.jsonEditorOptions.modes = ['tree', 'view']; // set all allowed modes
    this.arrayClassesElements.set('defaultLabel', this.configService.get('constanteClasses').DEFAULT_LABEL);
    this.arrayClassesElements.set('defaultInput', this.configService.get('constanteClasses').DEFAULT_INPUT);
    this.arrayClassesElements.set('defaultPostcode', this.configService.get('constanteClasses').DEFAULT_POSTE_CODE);
    this.versionProvider.getRepositoryVersion().then((version: any) => {
      this.repositoryVersion = version;
    });
    this.versionProvider.getVersion().then((version: any) => {
      this.appVersion = version;
    });
    this.loadFormStackInput();
  }

  public async ngOnChanges(): Promise<void> {
    return this.loadFormStackInput();
  }

  public setTextAndCode(): void {
    this.jsonEditorOptions.modes = ['text', 'code'];
  }

  public async loadFormStackInput(): Promise<void> {
    try {
      this.formStackServiceData = await this.formStackService.getFormStackServiceCurrentData();
    } catch (error) {
      throw error;
    }
  }
}
