import { UiSyncService } from 'app/services/ui-sync.service';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from 'app/services/report.service';
import { LoggerService } from 'app/services/logger.service';
import { PaginatedData } from 'app/models/paginated-data';
import { FormStackService } from 'app/services/form-stack.service';

 // valeur par defau
@Component({
    selector: 'lp-print-report',
    templateUrl: './lp-print-report.component.html'
  })
export class LpPrintReportComponent implements OnInit {

    public idReport: string;
    public reportDataKY: string;
    public title: string;
    private KEY_SPINNER: string = 'loading.loadingDoc';
    private REPORT_PARAMS: string = 'reportParams';

    constructor(
        public reportService: ReportService,
        private translate: TranslateService,
        private uiSyncService: UiSyncService,
        private loggerService: LoggerService,
        private formStackService: FormStackService
        ) { }

    public ngOnInit(): void {
        this.title = this.reportService.getTitle();
    }

    public async sendRequest(): Promise<void> {
        try {
            this.uiSyncService.loader(true, false, this.translate.instant(this.KEY_SPINNER));
            this.reportService.hideReportPDF();
            if ( this.reportService.reportExists()) {
                this.idReport = await this.getReportIdFromCustom();
                const titleAndParameters: PaginatedData = await this.reportService.getParametersDataKy(this.idReport);
                let binaryReport: ArrayBuffer = await this.reportService.execute(titleAndParameters.body[this.REPORT_PARAMS], this.reportService.isSpoolerMode);
                if ( binaryReport ) {
                    this.reportService.pdfExported(new Uint8Array(binaryReport));
                    await this.checkAndUpdateTitle(titleAndParameters);
                }
                this.reportService.showReportPDF();
            }
        } catch (e) {
            this.loggerService.error(e);
            throw e;
        }
    }

    private async checkAndUpdateTitle(title: PaginatedData): Promise<void> {
        if (title && title.body && title.body['wording'] && title.body['wording'] !== '' ||  title.body['wording'] !== null) {
            this.title =  title.body['wording'];
        }
        if (title && title.body && title.body['modelName'] && title.body['modelName'] !== '' ||  title.body['modelName'] !== null) {
            this.reportService.modelName =  title.body['modelName'];
        }
        this.reportService.updateTitle(this.title);
        this.reportService.titleReportFromPrintReport = this.title;
    }

    private async getReportIdFromCustom(): Promise<string> {
        let reportIdFromCustom: string;
        if ( this.formStackService && this.formStackService.currentApplicationItemDetail && 
             this.formStackService.currentApplicationItemDetail.reportObject &&
            this.formStackService.currentApplicationItemDetail.reportObject.reportId ) {
                reportIdFromCustom = this.formStackService.currentApplicationItemDetail.reportObject.reportId;
            }
        return reportIdFromCustom;    
    }
}
