import { Component, OnInit} from '@angular/core';
import { NewsProvider } from 'app/providers/news.provider';
import { LpNews } from 'app/meta/news';
import { NewsService } from 'app/services/news.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lp-news',
  templateUrl: './lp-news.component.html'
})
export class LpNewsComponent implements OnInit {

  public news: LpNews = new LpNews();
  public newsText: string = '';
  private subscriptionForRefreshNews: Subscription;

  constructor(
    private newsProvider: NewsProvider,
    private newsService: NewsService) {}

  public async ngOnInit(): Promise<void> {
    await this.subscribe();
    await this.getNews();
  }

  public ngOnDestroy(): void {
    this.unsubscribe();
  }

  public async getNews(): Promise<void> {
    try {
      this.news = new LpNews();
      const news: LpNews = await this.newsProvider.getNewsById('1');
      this.news = news;
      this.newsText = news.text;
    } catch (error) {
      this.news = new LpNews();
    }
  }

  private async subscribe(): Promise<void> {
    this.subscriptionForRefreshNews = this.newsService.refreshNewsEvtEmitter.subscribe( async () => {
      await this.getNews();
    });
  }

  private unsubscribe(): void {
    if (this.subscriptionForRefreshNews) {
      this.subscriptionForRefreshNews.unsubscribe();
    }
  }

}
