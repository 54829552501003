import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChoiceFieldComponent } from '../choice-field.component';
import { ChangeService } from 'app/services/change.service';
import { PaginatedData } from 'app/models/paginated-data';
import { Util } from 'app/statics/utils';
import { EvalService, MAIN, FOCUS_IN_EVENT } from 'app/services/eval.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { UiSyncService } from 'app/services/ui-sync.service';
import { FormStackService } from 'app/services/form-stack.service';
import { FtProvider } from 'app/providers/ft.provider';
import { ZoomService } from 'app/services/zoom.service';
import { ApplicationItem } from 'app/models/application-item';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { ConfigService } from 'app/services/config.service';

@Component({
  selector: 'lp-dropdown-field',
  templateUrl: './dropdown-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownFieldComponent),
      multi: true
    }
  ]
})
export class DropdownFieldComponent extends ChoiceFieldComponent implements OnInit {

  @Input() public locProZoomObject: string;
  @Input() public locProZoomAttribut: string;

  /**Le paramètre id permet de configurer un id spécifique pour le composant.
  */
  @Input() public id: string;

  /**Le paramètre Name permet de donner un nom personnalisé au composant.
   */
  @Input() public name: string;

  @Input() public active: boolean = true;

  /**Le paramètre Value représente les valeurs provenant du paramètre Val.
   */
  @Input() public value: string;


  @Input() public tableFilterStart: string
  @Input() public tableFilterEnd: string

  /**Le paramètre path permet d'aller chercher des valeurs à partir d'un chemin.
   * Utilisé avec le service Ftservice.
   * PRIORITAIRE PAR RAPPORT A LA PROPRIETE VALEURS
   */
  @Input()set path(path: any) {
    this.setValuesFromPath(path);
  }
  @Input() public outputType: String = 'object';
  @Input() public object: String;

  /**Le output DropDownEmitter va permettre l'exécution de la fonction qui lui est associée.
   * La fonction associée est déclarée dans le template où le composant est utilisé.
   */
  @Output() public dropDownEmitter: EventEmitter<any> = new EventEmitter();
  constructor(
    protected changeService: ChangeService,
    private ftProvider: FtProvider,
    protected evalService: EvalService,
    protected formMetadataProvider: FormMetadataProvider,
    protected uiSyncService: UiSyncService,
    public formStackService: FormStackService,
    private zoomService: ZoomService,
    protected metaFactoryService: MetaFactoryService,
    protected configService: ConfigService
  ) {
    super(changeService, evalService, formStackService, formMetadataProvider, uiSyncService, metaFactoryService, configService);
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    await this.hydrate();
  }

  public async setValuesFromPath(path: any): Promise<any> {
    const regex: RegExp = RegExp('{{.*}}', 'g');
    let path2: any = JSON.parse(JSON.stringify(path));

    if(!Util.isNullOrUndefined(path2) && !Util.isNullOrUndefined(path2.queryParams)){
      for (let i: number = 0; i < path2.queryParams.length; i++) {
        if (regex.exec(path2.queryParams[i].value) !== null) {
          path2.queryParams[i].value =
            await this.evalService.summaryEval(null, path2.queryParams[i].value.replace('{{', '').replace('}}', ''),
            this.formStackService.currentData);
        }
      }
    }

    this.ftProvider.getPaginatedDataFT(path2.verb, path2, this.active, this.tableFilterStart
          , this.tableFilterEnd).then((record: PaginatedData) => {
      if (!Util.isNullOrUndefined(record) && !Util.isNullOrUndefined(record.body)) {
        this.values = record.body;
      }
    });
  }

  public pushChange(event?: any): void {
    console.log(event.target.value);
    super.pushChange();
    if (event && this.previousValue !== event.target.value && (Util.isNullOrUndefined(this.ignoreChange) || !this.ignoreChange)) {
      this.setHasChanged(true, this);
    }
    let tempEvent: any = event;
      if (tempEvent.target.value === '') {
        this.setDisplayValue(tempEvent.target.value);
     } else {
       if (this.outputType === 'object') {
         if (!this.displayValue || this.displayValue.id) {
           this.setDisplayValue({});
         }
         this.setDisplayValue({'id': tempEvent.target.value});
       } else {
         this.setDisplayValue(tempEvent.target.value);
       }
     }
     this.putOnFocusOut();
     this.dropDownEmitter.emit(this.displayValue);

     this.change(event, true);
  }

  public setDisplayValue(value: any): void {
    if ((Util.isNullOrUndefined(this.outputType) || this.outputType === 'object') && value && !value.id) {
      super.setDisplayValue({'id': value});
    } else {
      super.setDisplayValue(value);
    }
  }

  public async focus(event: any): Promise<void> {
    let d: string = event.srcElement.value;
    this.setPreviousValue(d);
    if(this.formStackService.currentApplicationItem){
      let applicationItem: ApplicationItem = this.formStackService.currentApplicationItem;
      let data: any = this.formStackService.currentData;
      await this.evalService.executeContrainte(FOCUS_IN_EVENT, MAIN
        , applicationItem.ecrId.toString(), this.name.toString()
        , data, this.formStackService.currentFields, this.previousValue);
    }
}

  public async emitEvent(): Promise<void> {
    if (!Util.isNullOrUndefined(this.formStackService.currentApplicationItem)
        && !Util.isNullOrUndefined(this.formStackService.currentApplicationItem.ecrId)) {
      this.focusOutCustomCode();
    }
  }

  private async hydrate(): Promise<void> {
    if (!Util.isNullOrUndefined(this.locProZoomAttribut) && !Util.isNullOrUndefined(this.locProZoomObject) && Util.isNullOrUndefined(this.path)) {
      await this.getZoomFullDataList();
    }
  }

  private async getZoomFullDataList(): Promise<void> {
    try {
      if (!Util.isNullOrUndefined(this.locProZoomObject) && !Util.isNullOrUndefined(this.locProZoomAttribut)) {
        let p: PaginatedData = await this.zoomService
          .getZoomFullDataList(this.locProZoomObject, this.locProZoomAttribut);
        this.values = p.body
      }
    } catch (e) {
      console.log(e);
    }
  }

}
