import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UiSyncService } from '../../services/ui-sync.service';
import { TranslateService } from '@ngx-translate/core';
import { UserInterface, MenuItem, Preference } from '../../models/user.interface';
import { UserService } from '../../services/user.service';
import { ModalService } from '../../services/modal.service';
import { LanguageService } from '../../services/language.service';
import { LoginProvider } from 'app/providers/login.provider';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { LpMeta } from 'app/meta/meta';
import { RepositoryProvider } from 'app/providers/repository.provider';
import { RouterService, ROOT } from 'app/services/router.service';
import { LpModalPromiseErrorComponent } from 'app/ui/lp-modal/lp-modal-error/lp-modal-error.component';
import { HttpError } from 'app/models/http-error';
import { HttpErrorResponse } from '@angular/common/http';
import { BulkService } from 'app/services/bulk.service';
import { ChangeService } from 'app/services/change.service';
import { ReportsProvider } from 'app/providers/reports.provider';
import { ZoomProvider } from 'app/providers/zoom.provider';
import { ConfigService } from 'app/services/config.service';
import { FormStackService } from 'app/services/form-stack.service';
import { UserHistoryService } from 'app/services/user-history.service';
import { MessagingService } from 'app/services/messaging.service'; 
import { Dashboard } from 'app/models/dashboard';
import { DashboardProvider } from 'app/providers/dashboard.provider';
import { LpVisualSettings } from 'app/meta/visual-settings';
import { VisualSettingsProvider } from 'app/providers/visual-settings.provider';

@Component({
  selector: 'lp-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, AfterViewInit {

  public username: String = '';
  public password: String = '';
  public usernameForgotPwd: LpMeta = new LpMeta();
  public isForgotPwd: Boolean = false;
  public multilanguage: Boolean = false;
  public nativePasswords: Boolean;
  @ViewChild('usernameInput') public usernameInput: ElementRef;
  private lang: Map<String, string> = new Map();

  /** Le paramètre InputType est utilisé dans le propriété "type" de l'input. */
   public inputType: String = 'password';
   /** Le paramètre IconPassword est utilisé pour changer l'icon du mot de passe (affiché/caché). */
   private iconPassword: String = 'fa-eye-slash';

  constructor(private routerService: RouterService,
    protected uiSyncService: UiSyncService,
    private alocproProvider: AlocproProvider,
    private bulkService: BulkService,
    private repositoryProvider: RepositoryProvider,
    private translate: TranslateService,
    private loginProvider: LoginProvider,
    private userService: UserService,
    private modalService: ModalService,
    private languageService: LanguageService,
    private changeService: ChangeService,
    private reportProvider: ReportsProvider,
    private zoomProvider: ZoomProvider,
    private configService: ConfigService,
    private formStackService: FormStackService,
    private userHstoryService: UserHistoryService, 
    private MessagingService: MessagingService,
    private dashboardProvider: DashboardProvider,
    private visualSettingsProvider: VisualSettingsProvider) {
    this.lang.set('001', 'fr');
    this.lang.set('002', 'en');
    this.multilanguage = this.configService.get('multilanguage');
    this.nativePasswords = this.configService.get('nativePasswords');
    this.modalService.closeAllModal();
  }

  public ngOnInit(): void {
    if (this.userService.getCurrentUser()) {
      this.alocproProvider.logout();
      this.repositoryProvider.logout();
      this.userService.userLogout();
    }
  }

  public ngAfterViewInit(): void {
    this.usernameInput.nativeElement.focus();
  }

  public async onSubmit(): Promise<void> {
    if (this.username !== '' && this.password !== '') {
      this.uiSyncService.loader(true, true, this.translate.instant('loading.user'));
      try {
        await this.login();
        
        if (this.userService.urlReferer !== null && this.userService.urlReferer !== undefined) {
          this.routerService.navigate(this.userService.urlReferer);
          this.userService.urlReferer = null;
        } else {
          this.routerService.navigate(ROOT);
        }
        this.userHstoryService.addEvent('login', this.formStackService.currentApplicationItem, null, null, this.translate.instant('login.history')+ " " + this.userService.getCurrentUser().thirdParty.firstName + ' '+this.userService.getCurrentUser().thirdParty.lastName);
      } catch (error) {   
        this.uiSyncService.loader(false);
      }
    } else {
      let error: HttpError = new HttpError(new HttpErrorResponse({}), this.configService);
      error.statusCode = 400;
      error.translateKey = 'login.emptyField';
      this.modalService.modalPromise(LpModalPromiseErrorComponent, {
        backdropClass: 'alertBackdropClass'
      }, error);
      // this.modalService.error(this.translate.instant('login.emptyField'), this.translate.instant('login.errorAuth'));
    }
  }

  public setLanguage(lang: string): void {
    this.languageService.setLanguage(lang);
  }

  public onSubmitForgotPwd(): void {
    this.uiSyncService.loader(true, true, this.translate.instant('loading.sendForgotPassowrd'));
    this.alocproProvider.put('/users/lost-password', '', this.usernameForgotPwd).then(() => {
      this.modalService.success(this.translate.instant('general.modalService.successForgotPassowrd'),
        this.translate.instant('general.modalService.forgotPassowrd'));
      this.uiSyncService.loader(false);
      this.isForgotPwd = !this.isForgotPwd;
      this.usernameForgotPwd['username'] = '';
    }).catch((error) => {
      this.modalService.error(this.translate.instant('general.modalService.errorForgotPassowrd'),
        this.translate.instant('general.modalService.forgotPassowrd'));
      this.uiSyncService.loader(false);
    });
  }

  // permet de masque ou de rendre visibles es charactères du champ mot de passe.
  public changePasswordMode(): void {
    if (this.iconPassword === 'fa-eye-slash') {
      // Rendre le mot de passe lisible
      this.iconPassword = 'fa-eye';
      this.inputType = 'text';
    } else {
      // Caché le mot de passe
      this.iconPassword = 'fa-eye-slash';
      this.inputType = 'password';
    }
  }

  private async login(): Promise<void> {
    try {
      let user: UserInterface = await this.repositoryProvider.login(this.username.toString(), this.password.toString());
      this.userService.clear();
      this.MessagingService.requestPerm().subscribe();
      if (!user) {
        this.uiSyncService.loader(false);
        this.modalService.error(this.translate.instant('login.errorAuth'), this.translate.instant('login.connexion'));
        throw (new Error());
      } else {
        this.initUser(user);
        this.initProviders();
        this.formStackService.clear();
        this.changeService.clearAll();
        await this.getMenu();
        await this.getPreference();
        await this.getDashboard();
        await this.getVisualSettings();
      }
      return;
    } catch (error) {
      throw (error);
    }
  }

  private initProviders(): void {
    this.repositoryProvider.clearCache();
    this.alocproProvider.checkIdentificationData();
    this.alocproProvider.clearCache();
    this.zoomProvider.clearMapZoomForDropDown();
    this.bulkService.clearCache();
    this.reportProvider.clearCache()
  }


  private initUser(user: UserInterface): void {
    this.userService.setCurrentUser(user);
    this.setLanguage(this.lang.get(user.language.toString()));
  }

  private async getMenu(): Promise<void> {
    try {
      this.uiSyncService.loader(true, true, this.translate.instant('loading.menu'));
      let menu: MenuItem[] = await this.loginProvider.getFullMenu()
      this.userService.setMenu(menu);
      this.uiSyncService.loader(false);
      return;
    } catch (error) {
      throw (error);
    }
  }

  private async getPreference(): Promise<void> {
    try {
      let preference: Preference = await this.repositoryProvider.getPreferences()
      this.userService.userLogin(preference);
      return;
    } catch (error) {
      throw (error);
    }
  }

  private async getDashboard(): Promise<void> {
    try {
      let dashboard: Dashboard = await this.dashboardProvider.getMyDashboardLevel();
      this.userService.setDashboard(dashboard);
      return;
    } catch (error) {
      throw (error);
    }
  }

  private async getVisualSettings(): Promise<void> {
    try {
      let visualSettings: LpVisualSettings = await this.visualSettingsProvider.getVisualSettingsByAgencyId('*');
      this.userService.setVisualSettings(visualSettings);
      return;
    } catch (error) {
      throw (error);
    }
  }
}
