
<!-- ajout d'un input fields FieldsId. si fieldId alors zoom locpro-->
<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" titleTranslationKey="examples.examples"></lp-top-banner>

<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">
    <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''"
      (dblclick)="reInitCols()"></div>
    <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>
    <div class="container container-2-cols left" #containerCols>
        <div class="card-box col-md-6 label-2-colonnes" #col1>
            <form id="formulaire" *ngIf="uiSyncService.IsReadyToDisplayForm" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
                <lp-common-tools *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id" [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="deleteButton"
                    (showPrintOutput)="changeShowZoomPrint($event)"
                    [showPrintInput]="showZoomPrint"></lp-common-tools>
                <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">

               <lp-ui-accordion class="row" [menu]=true>
                    <lp-ui-accordion-group heading="examples.object" [open]=true key="infos">
                        <div class="container">
                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" id="dropdown" label="examples.dropdown"
                                    cssClass="form-control" [(ngModel)]="data.dropDown" name="dropdown" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'rentalType'}]}"></lp-dropdown-field>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-zoom-field id="zoom" [(ngModel)]="data.zoom" name="zoom" zoomId="zoom"  label="examples.zoom"
                                        class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" cssClass="form-control" > </lp-zoom-field>
                                </div>
                            </div>
                        </div>
                    </lp-ui-accordion-group>
                </lp-ui-accordion>

                <lp-ui-accordion class="row" [menu]=true>
                    <lp-ui-accordion-group heading="examples.text" [open]=true key="infos" id="number">
                        <div class="container">
                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-text-field id="text" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="examples.text"
                                        cssClass="form-control" name="text" [(ngModel)]="data.string" type="text"></lp-text-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-xl-12">
                                        <lp-textarea-field id="textarea" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="examples.textarea"
                                        cssClass="textareaField" name="textarea" [(ngModel)]="data.string"> </lp-textarea-field>
                                </div>
                            </div>
                        </div>
                    </lp-ui-accordion-group>
                </lp-ui-accordion>

                <lp-ui-accordion class="row" [menu]=true>
                    <lp-ui-accordion-group heading="examples.numbers" [open]=true key="infos" id="number">
                        <div class="container">
                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-currency id="currency" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="examples.currency"
                                        cssClass="form-control" name="currency" min="0"
                                        [(ngModel)]="data.number" type="text"></lp-currency>
                                </div>
                            </div>


                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-fuel-gauge id="gauge" class="{{arrayClassesElements.get('defaultInput')}}" name="gauge" label="examples.gauge"
                                        [(ngModel)]="data.number"></lp-fuel-gauge>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-number id="number" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                                        cssClass="form-control" name="number" min="0" [(ngModel)]="data.number" label="examples.number"
                                        type="text"></lp-number>
                                </div>
                            </div>
                        </div>
                    </lp-ui-accordion-group>
                </lp-ui-accordion>

               <lp-ui-accordion class="row" [menu]=true>
                    <lp-ui-accordion-group heading="examples.booleans" [open]=true key="infos" id="boolean">
                        <div class="container">
                            <div class="row">
                                <div class="form-group col-xl-12">
                                        <lp-boolean-switch-box name="checkbox" switchName="checkbox" label="examples.checkbox" [(ngModel)]="data.boolean"
                                        class="{{arrayClassesElements.get('defaultInput')}}"></lp-boolean-switch-box>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <!--<lp-check-list-box bindingKey="id" id="typeClient" idField="id"
                                        className="checkList" cssClass="" name="medias" [verb]="verb" [api]="api" label="examples.checklist"
                                        [parentData]="formStackService.currentData" [object]="object" [filter]="filter" [prop]="prop" labelField="wording"
                                        [singleCol]="false"></lp-check-list-box>-->
                                </div>
                            </div>
                          
                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-radio-box  label="examples.radio" [values]="  [ { 'databaseAlias': 'primary', 'id': 'oui', 'wording': 'Présent', 'isActive': true, 'details': 'inspection-damage-values/PRESENT' }, { 'databaseAlias': 'primary', 'id': 'non', 'wording': 'Absent', 'isActive': true } ]" [(ngModel)]="data.string" name="radio"></lp-radio-box>
                                </div>
                            </div>

                        </div>
                    </lp-ui-accordion-group>
                </lp-ui-accordion>

                <lp-ui-accordion class="row" [menu]=true>
                    <lp-ui-accordion-group heading="examples.dates" [open]=true key="infos">
                        <div class="container">        
                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-date [(ngModel)]="data.date" class="{{arrayClassesElements.get('defaultInput')}}" id="date" label="examples.date"
                                        cssClass="form-control" name="date"></lp-date>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-date-time [(ngModel)]="data.date" class="{{arrayClassesElements.get('defaultInput')}}" id="datetime" label="examples.datetime"
                                        cssClass="form-control" name="datetime"></lp-date-time>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-date-month [(ngModel)]="data.date" class="{{arrayClassesElements.get('defaultInput')}}"  label="examples.datemonth"
                                        id="datemonth" cssClass="form-control" name="datemonth"></lp-date-month>
                                </div>
                            </div>
                        </div>
                    </lp-ui-accordion-group>
                </lp-ui-accordion>

               <lp-ui-accordion class="row" [menu]=true>
                    <lp-ui-accordion-group heading="examples.file" [open]=true key="infos">
                        <div class="container"> 
                            <div class="row"> 
                                <div class="form-group col-xl-12">
                                    <div class="col-md-10 col-lg-8 col-xl-12">
                                        <lp-basic-file-field [(ngModel)]="data.file" [downloadable]="false" name="file" filename="file  jt " label="examples.file"
                                        [image]="true" accept="image/*"></lp-basic-file-field>
                                    </div>
                                </div>
                            </div>

                            <div class="row"> 
                                <div class="form-group col-12">
                                    <div class="col-12">
                                        <lp-drop-file [ignoreChange]="true" label='memos.dragFile' name="file" filename="memo" (uploaded)="uploadedFile($event)" [isBinding]="false"></lp-drop-file>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </lp-ui-accordion-group>
                </lp-ui-accordion>

                <lp-ui-accordion class="row" [menu]=true>
                    <lp-ui-accordion-group heading="Zoom-fields" [open]=true key="infos">
                        <div class="container">    
                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-text-field id="id" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="id" label="Documentation"
                                        [(ngModel)]="data.zoom.documentLink" [disabled]="true"></lp-text-field>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-zoom-field id="thirdParty" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" label="Zoom ( Normal ) - Doc: 8.3.1"
                                        cssClass="form-control" [(ngModel)]="data.zoom.thirdParty" name="thirdParty" zoomId="zoom"></lp-zoom-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-zoom-field [readonly]='true' id="customCodeZoom" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" label="Zoom ( Code Custom ) - Doc: 8.3.2"
                                        cssClass="form-control" [(ngModel)]="data.customCodeZoom" name="customCodeZoom" zoomId="customCodeZoom"></lp-zoom-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-zoom-field [readonly]='true' id="example1" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" label="Zoom ( isString – path – addModifyverb ===  null ) - Config = pas d'exemple - Doc: 8.3.3"
                                        cssClass="form-control" [(ngModel)]="data.zoom.example1" name="example1" zoomId="zoom"></lp-zoom-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-zoom-field id="language" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" label="Zoom ( isString – path – addModifyverb !==  null ) - Config: OK - Doc: 8.3.4"
                                        cssClass="form-control" [(ngModel)]="data.zoom.language" name="language" zoomId="language"></lp-zoom-field>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-zoom-field [readonly]='true' id="example2" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" label="Zoom ( isString – pas de path – addModifyverb ===  null ) - Config : pas d'exemple - Doc: 8.3.5"
                                        cssClass="form-control" [(ngModel)]="data.example2" name="example2" zoomId="zoom"></lp-zoom-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-zoom-field id="zipCode" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" label="Zoom ( isString – pas de path – addModifyverb !==  null ) - Config : Ok - Doc: 8.3.6"
                                        cssClass="form-control" [(ngModel)]="data.zoom.mainAddress.zipCode" name="zipCode" zoomId="zipCode"></lp-zoom-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-zoom-field [readonly]='true' id="example3" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" label="Zoom ( non isString – path – addModifyverb ===  null ) - Config : pas d'exemple - Doc: 8.3.7"
                                        cssClass="form-control" [(ngModel)]="data.zoom.example3" name="example3" zoomId="zoom"></lp-zoom-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-zoom-field id="country" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" label="Zoom ( non isString – path – addModifyverb !==  null ) - Config : Ok - Doc: 8.3.8"
                                        cssClass="form-control" [(ngModel)]="data.zoom.mainAddress.country" name="country" zoomId="country"></lp-zoom-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-zoom-field id="familyMethod" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" label="Zoom ( non isString – pas de path – addModifyverb ===  null ) - Config: Ok - Doc: 8.3.9"
                                        cssClass="form-control" [(ngModel)]="data.zoom.pricingMethod" name="familyMethod" zoomId="familyMethod"></lp-zoom-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col-xl-12">
                                    <lp-zoom-field id="pricing" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" label="Zoom ( non isString – pas de path – addModifyverb !==  null ) - Config : Ok - Doc: 8.3.10"
                                        cssClass="form-control" [(ngModel)]="data.zoom.tariff" name="pricing" zoomId="pricing"></lp-zoom-field>
                                </div>
                            </div>

                        </div>
                    </lp-ui-accordion-group>
                </lp-ui-accordion>
            
                <lp-ui-accordion class="row" [menu]=true>
                    <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
                        <div>
                          <pre>{{ data | json }}</pre>
                        </div>
                    </lp-ui-accordion-group>
                </lp-ui-accordion>
                </div>    
                <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
            </form>
        </div>

        <div class="col-md-6 card-box" #col2>
            <lp-right-col></lp-right-col>
        </div>
    </div>
    
  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>