<div class="row">
  <div class="col-4 pe-3 border-end">

    <ngx-datatable *ngIf="columns" class="material" [rows]="datas" [columnMode]="'force'"
      [headerHeight]="35" [footerHeight]="35" [rowHeight]="'auto'" [messages]="{emptyMessage: 'generalParams.emptyList' | translate, totalMessage: 'general.totalItemsList' | translate }"
      [externalPaging]="true" [limit]="memosPerPage" (page)='changePage($event)' [offset]="actualPageNumber - 1" [count]="(datas && datas.length && datas.length !== undefined)? datas.length : 0" (activate)="selectItem($event);" >

      <ngx-datatable-column 
        *ngFor="let column of columns" 
        name="{{column.name | translate}}" 
        prop="{{column.prop}}"
        [width]="(column.prop ==='action') ?  50 : ''" 
        [resizeable]="false" 
        [sortable]="false" 
        [draggable]="false" 
        [canAutoResize]="(column.prop ==='action') ?  false : true"
        >
        <ng-template let-row="row" ngx-datatable-cell-template >
          <span *ngIf="column.prop != 'action'" class="d-flex align-items-center ps-1 pe-1">{{row[column.prop]}}</span>
          <div *ngIf="column.prop == 'action'" class="table-icon ps-1">
            <i class="icon vega-dustbin" (click)="deleteGeneralParameter(row.id);"></i>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
  <form  #form="ngForm" ngNativeValidate class=" pb-0 ps-2 col-8 border-left">
    <div>
      
      <div class="row">

        <div class="form-group col-6 pb-0">
          <lp-text-field id="table" className="textField"
            class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
            name="general_parameters.table" label="general_parameters.table"
            [(ngModel)]="currentGeneralparameter.table" [disabled]=false
            [ignoreChange]="true">
          </lp-text-field>
        </div>

        <div class="form-group col-6">
          <lp-text-field id="code" className="textField"
            class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
            name="general_parameters.argument" label="general_parameters.argument"
            [(ngModel)]="currentGeneralparameter.code" [disabled]=true
            [ignoreChange]="true"></lp-text-field>
        </div>

        <div class="form-group col-6">
          <lp-text-field id="wording" className="textField"
            class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
            name="general_parameters.libelle" label="general_parameters.libelle"
            [(ngModel)]="currentGeneralparameter.wording" [disabled]=true
            [ignoreChange]="true"></lp-text-field>
        </div>

        <div class="form-group col-6">
          <lp-text-field id="value" className="textField"
            class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
            name="general_parameters.value" label="general_parameters.value"
            [(ngModel)]="currentGeneralparameter.value" [disabled]=true
            [ignoreChange]="true"></lp-text-field>
        </div>

        <div class="form-group col-6">
            <lp-text-field id="order" className="textField"
            class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
            name="general_parameters.ordre" label="general_parameters.ordre"
            [(ngModel)]="currentGeneralparameter.order" [disabled]=true
            [ignoreChange]="true"></lp-text-field>
        </div>

        <div class="form-group col-6">
          <lp-zoom-field
            class="{{arrayClassesElements.get('defaultInput')}}"
            id="company"
            [ignoreChange]="true"
            addModifyVerb="companies"
            libSearch="wording"
            zoomId='company'
            className="zoomField"
            cssClass="form-control"
            [showModifyButton]="false"
            [ngModelOptions]="{standalone: true}"
            [(ngModel)]="currentGeneralparameter.company"
            [inputSearch]="currentGeneralparameter.company?.id"
            searchTextField="id"
            [modal]="true"
            [tableShowField]="['id','corporateName']"
            name="company"
            kySearch="id"
            locProZoomObject="location"
            locProZoomAttribut="company"
            label="general_parameters.company"></lp-zoom-field>
        </div>

      </div>
    </div>
      
    <!--<lp-dynamic-main-form *ngIf="currentMemo && formstackService.specificApplicationItem && formstackService.specificApplicationItem.useJsonEditForm" [data]="currentMemo" [specificApplicationItem]="true" (uploaded)="uploadedFile($event)"></lp-dynamic-main-form>  -->  
  </form>    
</div>
