import { Component, OnInit} from '@angular/core';
import { LpLinks } from 'app/meta/links';
import { LinksProvider } from 'app/providers/links.provider';
import { LinksService } from 'app/services/links.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'lp-links',
  templateUrl: './lp-links.component.html'
})
export class LpLinksComponent implements OnInit{

  public links: Array<LpLinks> = new Array<LpLinks>();

  constructor(
    private linksProvider: LinksProvider,
    private linksService: LinksService
    ) {
  }

  public async ngOnInit(): Promise<void> {
    await this.getAllLinks();
  }

  public async addLink(): Promise<void> {
    this.links.push(new LpLinks());
  }

  public async saveLink(link: LpLinks): Promise<void> {
    if ( link && !isNullOrUndefined(link.id)) {
      await this.linksProvider.putLinksById(link, String(link.id));
    } else {
      await this.linksProvider.postLinks(link);
    }
    await this.getAllLinks();
    this.linksService.refreshlinks();
  }

  public async deleteLink(link: LpLinks, index: number): Promise<void> {
    if (link && !isNullOrUndefined(link.id)) {
      await this.linksProvider.deleteLinksById(link);
      await this.getAllLinks();
      this.linksService.refreshlinks();
    } else {
      this.links.splice(index, 1);
    }
  }

  private async getAllLinks(): Promise<void> {
    this.links = await this.linksProvider.getLinksList();
  }
  
}
