import { LpMeta, propertyMeta } from './meta';
import { Type } from 'class-transformer';
import { FtMeta } from './ft-meta';

export class LpMandate extends LpMeta {
    @Type(() => FtMeta)
    public rib: FtMeta = new FtMeta();
    @propertyMeta('string')
    public rum: String;
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('date')
    public signatureDate: Date;
    @propertyMeta('date')
    public utilisationDate: Date;
    @propertyMeta('boolean')
    public hasBeenUpdated: Boolean;
    @propertyMeta('string')
    public mandateType: String;
    @propertyMeta('boolean')
    public isActive: Boolean;
    @propertyMeta('string')
    public status: String;
    @Type(() => FtMeta)
    public company: FtMeta = new FtMeta();
}
