import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, CIRCUMSTANCE_LINKS } from '../detail-forms.component';
import { LpCircumstanceLink } from 'app/meta/circumstance-link';

@Component({
  selector: 'lp-circumstance-links',
  templateUrl: './circumstance-links.component.html'
})

export class CircumstanceLinksComponent extends DetailFormsComponent implements OnInit {

  public data: LpCircumstanceLink = null;

  public init(): void {
    this.data.assign(this.formStackService.currentDetailLineData);
  }

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(CIRCUMSTANCE_LINKS);
    this.initUrl();
    // this.init();
  }

}
