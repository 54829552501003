import { LpMeta, propertyMeta } from './meta';
import { Type } from 'class-transformer';
import { LpCompany } from './company';
import { LpLocation } from './location';

export class LpPaymentMethod extends LpMeta {
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('boolean')
    public isActive: Boolean;
    @propertyMeta('boolean')
    public discount: Boolean;
    @propertyMeta('boolean')
    public accounting: Boolean;
    @Type(() => LpCompany)
    public company: LpCompany = new LpCompany();
    @Type(() => LpLocation)
    public location: LpLocation = new LpLocation();
    @Type(() => LpMeta)
    public relatedPaymentMethod: LpMeta = new LpMeta();
}
