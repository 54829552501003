<notification></notification>
<div id="menu" class="w3-sidebar w3-bar-block">
  <div class="left-menu">
    <div class="outbar-container">
      <lp-outbar *ngIf="showVisualSettingsMenu()"></lp-outbar>
    </div>
    <!--<ul class="bottom p-0 text-center" *ngIf="!production">
      <li role="menuitem">
        <lp-user-history></lp-user-history>
      </li>
      <li role="menuitem">
        <lp-report-spooler *ngIf="!production"></lp-report-spooler>
        
      </li>
    </ul>-->
  </div>

  <div class="container-fluid">
    <div class="topbar-left-menu pull-left mb-0">
      <h1 id="titre" (click)="goHome()" class="cursor-pointer" title="{{'general.backToHomePage' | translate}}">
        <span class="logo-lg"></span>
      </h1>
      <lp-search-bar *ngIf="showSearchBar()"></lp-search-bar>
    </div>

    <ul class="list-unstyled topbar-right-menu pull-right mb-0">
      <li *ngIf="userHasRightsForFastActions()" class="action-list pull-left">
        <div class="btn-group" dropdown placement="bottom right" *ngIf="userService.getCurrentUser()">
          <button id="button-alignment" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-alignment"
            class="nav-link nav-user">
            <div  class="noimg noActionImg"></div>
            <i class="fa fa-caret-down" aria-hidden="true"></i>
            <i class="fa fa-caret-up" aria-hidden="true"></i>
          </button>
            <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-account" role="menu" aria-labelledby="button-alignment">
              <li role="menuitem">
                <a class="dropdown-item" (click)="showModalAddCompany()" title="{{'actionlist.addCompanyIHubCheckPlus' | translate}}">
                  <i class="vega-companies" aria-hidden="true"></i>
                  <span>{{'actionlist.addCompanyIHubCheckPlus' | translate}}</span>
                </a>
              </li>
            </ul>
        </div>
      </li>
      <li class="notification-list pull-left">
        <div class="btn-group" dropdown placement="bottom right" *ngIf="userService.getCurrentUser()">
          <button id="button-alignment" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-alignment"
            class="nav-link nav-user">
            <img *ngIf="userService.getCurrentUser().photo" src="{{userService.getCurrentUser().photo}}" alt="{{userService.getCurrentUser().description}}" class="rounded-circle" />
            <div *ngIf="!userService.getCurrentUser().photo" class="noimg"></div>
            <i class="fa fa-caret-down" aria-hidden="true"></i>
            <i class="fa fa-caret-up" aria-hidden="true"></i>
          </button>
          <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown-account" role="menu" aria-labelledby="button-alignment">
            <li class="row">
              <div class="col col-md-5 col-photo">
                <img *ngIf="userService.getCurrentUser().photo" src="{{userService.getCurrentUser().photo}}" alt="{{userService.getCurrentUser().wording}}" class="rounded-circle img-user-dropdown" />
                <div *ngIf="!userService.getCurrentUser().photo" class="noimg-user-dropdown"></div>
              </div>
              <div class="col col-md-7 me-0">
                {{'general.menu.hello' | translate }}
                <br/>
                <span>{{userService.getCurrentUser().wording}}</span>
                <br/>
                <span class="currentLocation">{{userService.getCurrentUser().location.id}} - {{userService.getCurrentUser().location.wording}}</span>
              </div>
            </li>
            <li class="divider dropdown-divider" *ngIf="userService.getCurrentUser().isMultiLocation"></li>
            <li role="menuitem" *ngIf="userService.getCurrentUser().isMultiLocation">
              <a class="dropdown-item" (click)="changeLocation()">
                <i class="vega-locations" aria-hidden="true"></i>
                <span>{{'changeLocation.menu' | translate}}</span>
              </a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li *ngIf="nativePasswords" role="menuitem">
              <a class="dropdown-item" (click)="changePassword()">
                <i class="vega-changePassword" aria-hidden="true"></i>
                <span>{{'changePassword.menu' | translate}}</span>
              </a>
            </li>
            <li *ngIf="nativePasswords && showMenu" class="divider dropdown-divider"></li>
            <li role="menuitem" *ngIf="showMenu">
              <a class="dropdown-item pt-0 pb-0" (click)="showModalPreferences()">
                <i class="vega-preferences" aria-hidden="true"></i>
                <span>{{'preferences.preference' | translate}}</span>
              </a>
            </li>
            <li role="menuitem" *ngIf="userService.isAdmin() && showMenu">
              <a class="dropdown-item pt-0 pb-0" (click)="navigate()">
                <i class="vega-screenTest" aria-hidden="true"></i>
                <span>{{'preferences.screenTest' | translate}}</span>
              </a>
            </li>
            <li role="menuitem" *ngIf="userService.isAdmin() && showMenu">
              <a class="dropdown-item pt-0 pb-0" (click)="showModalDebug()">
                <i class="vega-debugg" aria-hidden="true"></i>
                <span>{{'preferences.debugg' | translate}}</span>
              </a>
            </li>
            <li role="menuitem mb-1 mt-1" *ngIf="userService.isAdmin() && showMenu">
              <a class="dropdown-item pt-0 pb-0" (click)="showModalCustomization()">
                <i class="vega-customization" aria-hidden="true"></i>
                <span>{{'preferences.customization' | translate}}</span>
              </a>
            </li>
            <li role="menuitem mb-1 mt-1" *ngIf="showMenu">
              <a class="dropdown-item pt-0 pb-0" (click)="showModalDashboard()">
                <i class="vega-customization" aria-hidden="true"></i>
                <span>{{'preferences.showDashboard' | translate}}</span>
              </a>
            </li>
            <li role="menuitem mb-1 mt-1" *ngIf="showMenu">
              <a class="dropdown-item pt-0 pb-0" (click)="showModalVisualSettings()">
                <i class="vega-customization" aria-hidden="true"></i>
                <span>{{'preferences.showVisualSettings' | translate}}</span>
              </a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem" *ngIf="userWorkflows">
              <a class="dropdown-item" (click)="getUserModalWorkflows()">
                <i class="vega-workflows" aria-hidden="true"></i>
                <span>{{'workflows.workflows' | translate}}</span>
              </a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <div class="lpSwitchTheme dropdown-item ">
                <i class="vega-theme"></i>
                <div>
                  <span *ngFor="let theme of themes" class="mt-1 mb-1">
                    <span (click)="changeLpTheme(theme.name,theme.primaryColor)" class="theme {{theme.btnClass}}">{{'theme.'+theme.btnClass | translate}}</span>                    
                  </span>
                </div>
              </div>
            </li>
            <li class="divider dropdown-divider" *ngIf="multilanguage"></li>
            <li role="menuitem" *ngIf="multilanguage">
              <div class="dropdown-item" >                
                  <i class="vega-languages"></i>
                <img class="flag me-1" src="/assets/img/flags/France.png" (click)="setLanguage('fr')">
                <img class="flag" src="/assets/img/flags/United-Kingdom.png" (click)="setLanguage('en')">
              </div>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <a class="dropdown-item" (click)="showModalConfidentiality()">
                <i class="vega-confidentiality" aria-hidden="true"></i>
                <span>{{'preferences.confidentialite' | translate}}</span>
              </a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <a class="dropdown-item" (click)="logOut()">
                <i class="vega-logout" aria-hidden="true"></i>
                <span>{{'preferences.logout' | translate}}</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
      <li *ngIf="typeheadMenu" class="sitemap-btn pull-left">
        <i class="vega-menu" aria-hidden="true" title="{{'general.menu.sitemap' | translate}}" (click)="toggleSitemap($event)"
          id="toggleSitemap"></i>
      </li>
    </ul>
    <div class="clearfix"></div>
  </div>

  <div *ngIf="typeheadMenu && uiSyncService.sitemap" class="sitemap-container" [class.show]="uiSyncService.sitemap" (click)="clickedInside($event)">
    <div class="title">
      {{'general.other.functionalities' | translate}}
      <i class="vega-delete" aria-hidden="true" title="{{'general.button.close' | translate}}" (click)="toggleSitemap($event)"></i>
    </div>
    <lp-sitemap [searchVal]="uiSyncService.searchVal" [typeheadMenu]="typeheadMenu"></lp-sitemap>
  </div>
</div>
