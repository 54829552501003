<lp-ui-accordion id="{{id}}"> 
    <lp-ui-accordion-group heading="locations.locationsSituations.title" [open]=true>
      <lp-date-time id="startDate" cssClass="form-control" label="locations.locationsSituations.startDate"
      cssClass="{{arrayClassesElements.get('defaultInput')}}" name="startDate"
                    [(ngModel)]="data.startDate"></lp-date-time>
      <lp-date-time id="endDate"  label="locations.locationsSituations.endDate"
      cssClass="locations-group" name="endDate"
                    [(ngModel)]="data.endDate"></lp-date-time>
      <lp-label-field for="locations.locationsSituations.owner" class="{{arrayClassesElements.get('defaultLabel')}}" text="locations.locationsSituations.owner"></lp-label-field>
      <!--<lp-text-field id="locations.locationsSituations.owner" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="locations.locationsSituations.owner"
          [(ngModel)]="data.owner.id"></lp-text-field>-->
      <lp-zoom-field id="locations.locationsSituations.owner" zoomId="accountings.companies" name="locations.locationsSituations.owner"  class="{{arrayClassesElements.get('defaultInput')}}"
           className="zoomField form-control" [(ngModel)]="data.owner"> </lp-zoom-field>
      <lp-label-field for="locations.locationsSituations.status" class="{{arrayClassesElements.get('defaultLabel')}}" text="locations.locationsSituations.status"></lp-label-field>
      <lp-text-field id="locations.locationsSituations.status" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="locations.locationsSituations.status"
          [(ngModel)]="data.status"></lp-text-field>             
  </lp-ui-accordion-group>
</lp-ui-accordion>
