import { ChangeDetectorRef, Component, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LpPricingDetail } from 'app/meta/pricing-detail';
import { PricingDetailsColumn } from 'app/models/pricing-details-column';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { MemoProvider } from 'app/providers/memo.provider';
import { RepositoryProvider } from 'app/providers/repository.provider';
import { BulkService } from 'app/services/bulk.service';
import { ChangeService } from 'app/services/change.service';
import { ConfigService } from 'app/services/config.service';
import { EvalService } from 'app/services/eval.service';
import { FormStackService } from 'app/services/form-stack.service';
import { IhmStackService } from 'app/services/ihm-stack.service';
import { LoggerService } from 'app/services/logger.service';
import { ModalService } from 'app/services/modal.service';
import { ReportService } from 'app/services/report.service';
import { FormService } from 'app/services/root/form.service';
import { RightColService } from 'app/services/root/rightCol.service';
import { RouterService } from 'app/services/router.service';
import { UiSyncService } from 'app/services/ui-sync.service';
import { UserService } from 'app/services/user.service';
import { ZoomService } from 'app/services/zoom.service';
import * as _ from 'underscore'
import { Util } from 'app/statics/utils';
import { RootformComponent } from '../rootform/rootform.component';
import { PricingService } from 'app/services/pricing.service';
import { PricingCustomService } from 'app/services/pricing-custom.service';

const VERB: string = 'reports';
const EDITION_TYPE: string = 'ETAT';
@Component({
  selector: 'lp-reports',
  templateUrl: './reports.component.html'
})

export class ReportsComponent extends RootformComponent implements OnInit {

  public showListColumn: Boolean = false;
  public listPricingDetails: LpPricingDetail[] = [];
  public availableColums: PricingDetailsColumn[];
  public topBanner: boolean = false;
  public checkboxValues: any;
  public reportKy: string;
  public pdfSrc: Uint8Array;
  public ky: string;
  public datasForList: any;

  constructor(
    protected log: LoggerService,
    protected route: ActivatedRoute,
    protected routerService: RouterService,
    public alocproProvider: AlocproProvider,
    public zoomService: ZoomService,
    protected repositoryProvider: RepositoryProvider,
    public uiSyncService: UiSyncService,
    public vcr: ViewContainerRef,
    protected changeDetectorRef: ChangeDetectorRef,
    protected translate: TranslateService,
    protected renderer: Renderer2,
    protected modalService: ModalService,
    public formStackService: FormStackService,
    protected changeService: ChangeService,
    public formService: FormService,
    public memoProvider: MemoProvider,
    public userService: UserService,
    public evalService: EvalService,
    public ihmStackService: IhmStackService,
    public rightColService: RightColService,
    public bulkService: BulkService,
    public reportService: ReportService,
    protected configService: ConfigService,
    protected pricingService: PricingService,
    protected pricingCustomService: PricingCustomService
    ) {
      super(log, route, routerService, alocproProvider, zoomService, repositoryProvider, 
        uiSyncService, vcr, changeDetectorRef, translate, renderer, modalService,
        formStackService, changeService, formService, memoProvider, userService, 
        evalService, ihmStackService, rightColService, bulkService, configService,
        pricingService, pricingCustomService);
     }

  public async ngOnInit(): Promise<void> {
    this.ihmStackService.ShowSubAPIDetailsTable = false;
    await super.build(VERB, this.configService.get('newdataKeyWord'));
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.formStackService.CurrentApplicationItemDetailData = null;
      this.data = this.formStackService.currentData;
      this.ihmStackService.displaySubAPIDetails()
    }
    this.topBanner = true;
    await this.initReportsIfKY();
  }

  public activeColums(): PricingDetailsColumn[] {
    let activeColums: PricingDetailsColumn[] = [];
    for (let index: number = 0; index < this.availableColums.length; index++) {
      const column: PricingDetailsColumn = this.availableColums[index];
      if (column['active']) {
        activeColums.push(column);
      }
    }
    return activeColums;
  }

  public onSort(event: any): void {
    if (Util.isNullOrUndefined(event.prevValue)) {
      this.listPricingDetails =  _.sortBy(this.listPricingDetails, event.sorts[0].prop);
    } else {
      this.listPricingDetails = this.listPricingDetails.reverse()
    }
  }

  public async search(): Promise<void> {
    try {
      if (this.data && this.data['reportingModule'] && this.data['reportingModule']['object']) {
        this.datasForList = await this.reportService.list(this.data['reportingModule']['object'], EDITION_TYPE);
      }
    } catch (error) {
      throw error;
    }
  }

  public async chooseReportingModule(): Promise<void> {
    try {
      if ( !Util.isNullOrUndefined(this.data) && !Util.isNullOrUndefined(this.data['reportingModule']) && !Util.isNullOrUndefined(this.data['reportingModule']['id']) && this.data['reportingModule']['id'] !== 'null') {
        this.datasForList = await this.reportService.list(this.data['reportingModule'].id, EDITION_TYPE);
      } else {
        this.datasForList = [];
      }
    } catch (error) {
      throw error;
    }
  }

  private async initReportsIfKY(): Promise<void> {
    try {
      if (this.route && this.route.snapshot && this.route.snapshot.params && this.route.snapshot.params['ky']) {
        this.datasForList = await this.reportService.list(this.route.snapshot.params['ky'], EDITION_TYPE);
        this.data['reportingModule'].id = this.route.snapshot.params['ky']
      }
    } catch (error) {
      throw error;
    }
  }

}
