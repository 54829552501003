import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../../../services/modal.service';
import { LpModalComponent } from '../../lp-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LpEdi } from 'app/meta/edi';
import { ConfigService } from 'app/services/config.service';

@Component({
  selector: 'lp-modal-edi-exit',
  templateUrl: './lp-edi-exit.component.html'
})
export class LpModalEdiExitComponent extends LpModalComponent {

  public relatedObject: String;
  public relatedObjectId: String;
  public ky: string;
  public verb: string;
  public listeMessages: any = {
    emptyMessage: '',
    totalMessage: ''
  };
  public edis: LpEdi[] = [];
  public currentEdi: LpEdi = new LpEdi();
  public showCancelAction: Boolean = false;
  public ediPerPage: number = this.configService.get('edisLimitInList');
  public actualPageNumber: number = 1;
  public nbEdi: number = 0;
  protected currentIdForDelete: String = '';


  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    public modalService: ModalService,
    private configService: ConfigService
  ) {
    super(dialogRef, data);
  }

}
