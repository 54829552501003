
<div class="col col-md-3 ">
  <div class="col col-md-10 offset-md-1">
    <div class="logos mb-3"></div>
    <div class="card card-box login-card pb-0">

      <div class="login-bloc">
        <div class="text-center">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="200px" height="80px" viewBox="0 20 100 50" preserveAspectRatio="xMidYMid">
            <g transform="translate(20 50)">
            <circle cx="0" cy="0" r="6" fill="#353535">
              <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
            </circle>
            </g><g transform="translate(40 50)">
            <circle cx="0" cy="0" r="6" fill="#666666">
              <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
            </circle>
            </g><g transform="translate(60 50)">
            <circle cx="0" cy="0" r="6" fill="#9b9b9b">
              <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
            </circle>
            </g><g transform="translate(80 50)">
            <circle cx="0" cy="0" r="6" fill="#d4d4d4">
              <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"></animateTransform>
            </circle>
            </g>
            </svg>
          <h4 id="h4-titleLoginPage" class="text-uppercase font-bold mb-3">{{'maintenance.title' | translate}}</h4>
        </div>
        <div class="ps-3 pe-3 pb-5 pt-3 text-center">          
          {{'maintenance.description' | translate}}
        </div>
      </div>
    </div>
    <!-- end card-box-->
    <div class="col-12 text-center mt-2">
      <p class="text-muted">
        <a href="http://www.vega-systems.com" target="_blank">www.vega-systems.com</a>
      </p>
    </div>
  </div>
</div>