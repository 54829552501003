import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../../../services/modal.service';
import { LpWorkflow } from 'app/meta/workflow';
import { WorkflowProvider } from 'app/providers/workflow.provider';
import { Util } from 'app/statics/utils';
import { LpModalComponent } from '../../lp-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LpModalWorkflowEditComponent } from '../lp-modal-workflow-edit/lp-workflow-edit.component';
import { LpModalWorkflowConfirmComponent } from '../lp-modal-workflow-confirm/lp-workflow-confirm.component';
import { FormStackService } from 'app/services/form-stack.service';
import { ApplicationItem } from 'app/models/application-item';
import { ApplicationItemProvider } from 'app/providers/application-item.provider';
import { ApplicationItemDetail, ApplicationItemDetailColumn } from 'app/models/application-item-detail';
import { PaginatedData } from 'app/models/paginated-data';
import { UserService } from 'app/services/user.service';
import { UiSyncService } from 'app/services/ui-sync.service';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { JsdataService } from 'app/services/jsdata.service';
import { ConfigService } from 'app/services/config.service';

const DUSTBIN: string = 'icon vega-dustbin';
const NEW: string = 'NEW';
const WORKFLOWS = 'workflows';
const BULK_ACTION_DELETE: string = 'delete';

@Component({
  selector: 'lp-modal-workflow-list',
  templateUrl: './lp-workflow-list.component.html'
})
export class LpModalWorkflowListComponent extends LpModalComponent implements OnInit, OnDestroy {

  public loadingIndicator: boolean = false;
  public ky: string;
  public verb: string;
  public columns: Object[] = [];
  public currentPage: Number;
  public workflowStruct: ApplicationItem = new ApplicationItem();
  public workflows: LpWorkflow[] = [];
  public currentWorkflow: LpWorkflow = new LpWorkflow();
  public showCancelAction: Boolean = false;
  public workflowPerPage: number = this.configService.get('workflowsLimitInList');
  public actualPageNumber: number = 1;
  public nbWorkflow: number = 0;
  private isMe: boolean;
  public showConfigPanel: Boolean = false;

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    public modalService: ModalService,
    private workflowProvider: WorkflowProvider,
    private applicationItemProvider: ApplicationItemProvider,
    private formStackService: FormStackService,
    private userService: UserService,
    private uiSyncService: UiSyncService,
    private alocproProvider: AlocproProvider,
    public jsdataService: JsdataService,
    private configService: ConfigService
  ) {
    super(dialogRef, data);
  }

  public ngOnDestroy(): void {
    if (this.modalService && !Util.isNullOrUndefined(this.modalService.isMe) && this.modalService.isMe === true ) {
      this.modalService.isMe = false;
    }
  }

  public async ngOnInit(): Promise<void> {
    this.isMe = this.modalService.isMe;
    this.workflowStruct = await this.applicationItemProvider.getObjectDetails(WORKFLOWS);
    this.workflows = await this.refreshWorkflowsList();
  }

  public async changePage(pageInfo: any): Promise<void> {
    this.actualPageNumber = pageInfo.currentPage;
   this.workflows = await this.refreshWorkflowsList();
  }
  
  public changeConfigPanel(): void {
    this.showConfigPanel = !this.showConfigPanel;
  }
  public async actionOnWorkflow(value: any): Promise<void> {
    if (
      (value.type === 'click' && value.event &&
      value.event.srcElement && value.event.srcElement.className !== DUSTBIN)
      || (value === NEW)) {
      this.modalEditWorkflow();
      await this.setWorkFlowSelectedOrNot(value);
    } else if (value.type === 'click' && value.event &&
    value.event.srcElement && value.event.srcElement.className === DUSTBIN) {
      await this.modalDeleteWorkflow(value.row.id);
    }
  }

  public async modalDeleteWorkflow(workflowID: String): Promise<void> {
    this.workflowProvider.workflowSelectedForDelete = workflowID;
    await this.modalService.modalPromise(LpModalWorkflowConfirmComponent, {
      backdropClass: 'alertBackdropClass'
    });
    this.workflows = await this.refreshWorkflowsList();
    this.workflowProvider.workflowSelectedForDelete = null;
  }

  public async modalEditWorkflow(): Promise<void> {
    await this.modalService.modalPromise(LpModalWorkflowEditComponent,  {
      maxHeight : '90vh',
      backdropClass: 'commonToolsBackdropClass'
    });
    this.workflows = await this.refreshWorkflowsList();
    this.workflowProvider.workflowSelectedForDelete = null;
  }

  public async refreshWorkflowsList(): Promise<LpWorkflow[]> {
    let wfTemp: LpWorkflow[] = [];
    this.loadingIndicator = true;
    if ( !Util.isNullOrUndefined(this.workflowProvider.workflowSelectedForDelete) &&
       this.actualPageNumber > 1 && this.workflows && this.workflows.length === 1 ) {
        this.actualPageNumber = this.actualPageNumber - 1;
    }
    const params: Array<string> = this.setParams();
    this.workflows = [];
    let result = await this.workflowProvider.getWorkflows(params[0], params[1],
      this.actualPageNumber, this.workflowPerPage, this.isMe)
        if (!Util.isNullOrUndefined(result)) {
          this.nbWorkflow = 0;
          let workflowsTemp: LpWorkflow[] = [];
          let nbWorkflowTemp: number = 0;
          if (!Util.isNullOrUndefined(result.count)) {
            nbWorkflowTemp = result.count;
          }
          if (!Util.isNullOrUndefined(result.body) && result.body.length !== 0) {
            result.body.forEach( async (element) => {
              workflowsTemp.push(element as LpWorkflow);
            });
            wfTemp = workflowsTemp;
          this.nbWorkflow = nbWorkflowTemp;
          }
        }
        this.loadingIndicator = false;
        return wfTemp;
  }

  private setParams(): Array<string> {
    let params: Array<string> = [null, null];
    if ( this.formStackService &&
      this.formStackService.currentApplicationItem &&
      this.formStackService.currentApplicationItem.linkedObject !== null &&
      this.formStackService.currentApplicationItem.linkedObject !== undefined) {
        params[0] = String(this.formStackService.currentApplicationItem.linkedObject);
    }
    if ( this.formStackService &&
      this.formStackService.currentData &&
      this.formStackService.currentData.id !== null &&
      this.formStackService.currentData.id !== undefined ) {
        params[1] = String(this.formStackService.currentData.id);
    }
    return params;
  }

  private async setWorkFlowSelectedOrNot(value: any): Promise<void> {
    if (value && value.row && value.row.id && (value.row.id !== null || value.row.id !== undefined) ) {
      this.workflowProvider.workflowSelected = value.row.id;
    }
  }


  public getDisplayedColumns(): Array<ApplicationItemDetailColumn> {
    let activeColums: ApplicationItemDetailColumn[] = [];
    for (let index: number = 0; index < this.workflowStruct?.details[0]?.columns.length; index++) {
      if (this.workflowStruct?.details[0]?.columns[index].isDisplayed) {
        activeColums.push(this.workflowStruct?.details[0]?.columns[index]);
      }
    }
    return activeColums;
  }

  public rowClass = (row: any) => {
    if (!Util.isNullOrUndefined(row)) {
      return {
        'footer-table': row.id === 'footer_table',
        'barre': row.action === BULK_ACTION_DELETE
      }
    }
  }

  public onSort(event: any): void {
    this.loadingIndicator = true;
    this.currentPage = 1;
    this.alocproProvider.getPaginatedData(
      this.uiSyncService.formatURL(this.formStackService.currentApplicationItemDetail.path, this.workflows),
      this.currentPage,
      ApplicationItemDetail.buildQueryParams('GET', this.formStackService.currentApplicationItemDetail),
      false,
      Number(this.userService.getCurrentUser().preference.linesPerPage),
      event.sorts[0].dir.toUpperCase(),
      event.sorts[0].prop)
      .then((records: PaginatedData) => {
        this.formStackService.CurrentApplicationItemDetailData = records.body;
        this.formStackService.CurrentApplicationItemDetailDataReadonly = records._readonly;
        this.loadingIndicator = false;
      });
  }


}
