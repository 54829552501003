import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { ValueFieldComponent } from '../value-field.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Util } from 'app/statics/utils';

@Component({
  selector: 'lp-fuel-gauge',
  templateUrl: './lp-fuel-gauge.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpFuelGaugeComponent),
      multi: true
    }
  ]
})
export class LpFuelGaugeComponent extends ValueFieldComponent implements OnInit {

  @Input() public min: number = 0;
  @Input() public max: number = this.configService.get('maxFuelGauge');
  @Input() public step: number = 1;
  @Input() public showLabel: Boolean = true;

  public async pushChange(event?: any): Promise<void> {
    super.pushChange();
    if (!this.ignoreChange) {
      this.setHasChanged(true, this);
    }
    super.change(event);
  }

  public focus(event: any): void {
    let d: number;
    if (!Util.isNullOrUndefined(event) && !Util.isNullOrUndefined(event.target) && !Util.isNullOrUndefined(event.target.value)) {
      d = parseInt(event.target.value, 10);
    }
    this.setPreviousValue(d)
  }

  public async emitForEval(): Promise<void> {  
    this.focusOutCustomCode(); 
  }
}
