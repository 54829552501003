import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { DateTimeAdapter} from '@danielmoncada/angular-datetime-picker';
import { DateFieldComponent } from '../date-field.component';
import { ChangeService } from 'app/services/change.service';
import { EvalService, FOCUS_OUT_EVENT, MAIN, FOCUS_OUT_CHANGE_EVENT, FOCUS_IN_EVENT } from 'app/services/eval.service';
import { DatetimeService } from 'app/services/datetime.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { Util } from 'app/statics/utils';
import { FormStackService } from 'app/services/form-stack.service';
import { UiSyncService } from 'app/services/ui-sync.service';
import { ApplicationItem } from 'app/models/application-item';
import { LpMeta } from 'app/meta/meta';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { ConfigService } from 'app/services/config.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'lp-date',
  templateUrl: './date.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateComponent),
      multi: true
    }
  ]
})
export class DateComponent extends DateFieldComponent implements OnInit {

  /**Le paramètre id permet de configurer un id spécifique pour le composant.
  */
  @Input() public id: string;

  /**Le paramètre Name permet de donner un nom personnalisé au composant.
   *
   */
  @Input() public name: string;

  /**Le paramètre InputDate sera le nom du champ dans lequel les données seront émises.
   */
  @Input() public inputDate: string;

  /**Le paramètre Type permet de configurer le type des balises HTML qui viennent constituer le composant.
   */
  @Input() public type: any;

  @Output() public pickerClose: EventEmitter<void> = new EventEmitter()

  @Input() public isTouched: Boolean;
  /**Le paramètre ValidDate est un booléen qui aura la valeur TRUE,
   * lorsque la date aura le format adéquat pour être affichée dans le composant.
   */
  public validDate: Boolean = true;

    /**Le paramètre InternValue représente, la date interne avec le format souhaité.
     */
  public internalValue: string;

  constructor(protected dateTimeAdapter: DateTimeAdapter<any>, protected changeService: ChangeService, protected evalService: EvalService,
      protected dateTimeService: DatetimeService, protected formMetadataProvider: FormMetadataProvider,
      public formStackService: FormStackService, protected uiSyncService: UiSyncService, public metaFactoryService: MetaFactoryService,
      public configService: ConfigService,private changeDetectorRef: ChangeDetectorRef) {
      super(dateTimeAdapter, changeService, evalService, formMetadataProvider, uiSyncService, formStackService, metaFactoryService, configService)
    }

  public ngOnInit(): void {
    this.typeBinding = 'Date';
    super.ngOnInit();
  }

  public keyDown (event: any, key: string): void {
    if (event.key === 'Enter') {
      this.pushChange(event)
    }
  }

  public focusPicker(event: any): void {
    if (!Util.isNullOrUndefined(this.displayValue) && this.displayValue !== '') {
      this.setPreviousValue(this.displayValue.split('T')[0])
    } else {
      this.setPreviousValue('')
    }
  }

  public async focus(event: any): Promise<void> {
    let d: string = this.dateTimeService.dateDBFormatComplete_2(event.srcElement.value).split('T')[0];
    this.setPreviousValue(d)
    let applicationItem: ApplicationItem = this.formStackService.currentApplicationItem;
    let data: any = this.formStackService.currentData;
    await this.evalService.executeContrainte(FOCUS_IN_EVENT, MAIN
      , applicationItem.ecrId.toString(), this.name.toString()
      , data, this.formStackService.currentFields, this.previousValue);
  }

  public async pushChange(event?: any): Promise<void> {
    super.pushChange();
    this.setDisplayValue(this.dateTimeService.dateComponent(this.displayValue));
    //this.putOnFocusOut();    
    this.focusOutCustomCode();
    this.pickerClose.emit(event);
  }

  protected transform(value: any): void {
    if (!Util.isNullOrUndefined(value) && value !== '') {
      const dateString: string = formatDate(new Date(value),this.configService.get('formats').date_db_format_complete,this.configService.get('moment').LOCALE_TIME);
      this.displayValue = this.dateTimeService.dateComponent(dateString);
    } else {
      this.displayValue = null;
    }
    this.changeDetectorRef.detectChanges();
  }

  protected async focusOutCustomCode() : Promise<void> {
    let applicationItem: ApplicationItem;
    let data: LpMeta;
    if (!this.formStackService.getFields(this.name).readonly) {      
      if(this.formStackService.useSpecificApplicationItem(this.name)) {
        applicationItem = this.formStackService.specificApplicationItem;
        data = this.formStackService.specificData;
      } else {
        applicationItem = this.formStackService.currentApplicationItem;
        data = this.formStackService.currentData;
      }
      if (!Util.isNullOrUndefined(this.displayValue) && this.previousValue !== this.displayValue.split('T')[0]) {
        if (Util.isNullOrUndefined(this.ignoreChange) || !this.ignoreChange) {
          this.setHasChanged(true, this);
        }  
        await this.evalService.executeContrainte(FOCUS_OUT_CHANGE_EVENT, MAIN
          , applicationItem.ecrId.toString(), this.name.toString()
          , data, this.formStackService.currentFields, this.previousValue);
      }
    }
    await this.evalService.executeContrainte(FOCUS_OUT_EVENT, MAIN
      , applicationItem.ecrId.toString(), this.name.toString()
      , data, this.formStackService.currentFields);
  }

}
