import { HttpProvider } from './http.provider';
import { Injectable } from '@angular/core';
import { PaginatedData } from 'app/models/paginated-data';
import { Util } from 'app/statics/utils';
import { AlocproProvider } from './alocpro.provider';
import { GeneralParameters } from 'app/models/general-parameters';

const ACTIVE: string = 'active'
const GENERAL_PARAMETERS: string = 'technical/general-parameters';

@Injectable({
    providedIn: 'root'
  })
export class FtProvider {

    protected MapCache: Map<String, any> = new Map;

    constructor(public aloc: AlocproProvider, public http: HttpProvider) {}

    /*
     *  les methode vont mettre à jour "p" avec les paramètres HTTP
     *  on passe en param la map qu'on aliment dans chaque methode
     */
    public async getPaginatedDataFT(path: any, FTObject: any, isActive: boolean, tableFilterStart?: string
        , tableFilterEnd?: string, page?: Number, limit?: Number, order?: String, sort?: String): Promise<PaginatedData> {
        let p: Map<string, string> = new Map<string, string>();
        this.handleParams(p, FTObject, isActive, tableFilterStart, tableFilterEnd);
        this.handlePagination(p, page, limit, order, sort);
        return await this.aloc.getPaginatedData(path, null, p);
    }

    public async getGeneralParameters(queryParams: Map<string, string>): Promise<any> {
        try {
            let url: string = GENERAL_PARAMETERS;
            if ( !Util.isNullOrUndefined(queryParams) && queryParams.size >=1) {
                url = `${url}?`;
                queryParams.forEach( (value: string, key: string) => {
                    url = url + `${key}=${value}&`;
                });
            }
            return await this.aloc.getGeneralParameters(url, true);
        } catch (error) {
            throw error;
        }
    }

    public async getListGeneralParameters(): Promise<Array<GeneralParameters>> {
        try {
            const url: string = `${GENERAL_PARAMETERS}`
            return await this.aloc.getGeneralParameters(url, true);
        } catch (error) {
            throw error;
        }
    }

    public async deleteGeneral_Parameters(id: string, data: any): Promise<void> {
        try {
            await this.aloc.delete(GENERAL_PARAMETERS, id, data);
          } catch (error) {
            throw error;
          }        
      }

      public async createGeneralParameters(data: any): Promise<void> {
        try {
        await this.aloc.post(GENERAL_PARAMETERS, data);
        } catch (error) {
            throw error;
          }        
      }    
      
      public async saveGeneral_Parameters(id: string, data: any): Promise<void> {
          try {
            await this.aloc.put(GENERAL_PARAMETERS, id, data);
          } catch (error) {
            console.log(error);
            throw error;
          }        
    }       

    private handleParams(p: Map<string, string>, FTObject: any, isActive: boolean, tableFilterStart: string, tableFilterEnd: string): void {
        this.handleActiveParams(p, isActive)
        this.handleFTObject(p, FTObject);
        this.handleStartEndParams(p, tableFilterStart, tableFilterEnd);
    }

    private handleActiveParams(p: Map<string, string>, isActive: boolean): void {
        if (Util.isNullOrUndefined(isActive) || isActive) {
            p = p.set(ACTIVE, 'true');
        } else {
            p = p.set(ACTIVE, 'false');
        }
    }

    private handleFTObject(p: Map<string, string>, FTobject: any): void {
        if (!Util.isNullOrUndefined(FTobject) &&  !Util.isNullOrUndefined(FTobject.queryParams)) {
            FTobject.queryParams.forEach((object: any) => {
                p = p.set(object.key, object.value);
            });
        }
    }

    private handleStartEndParams(p: Map<string, string>, tableFilterStart: string, tableFilterEnd: string): void {
        if (!Util.isNullOrUndefined(tableFilterStart) && !Util.isNullOrUndefined(tableFilterEnd)) {
            p = p.set('tableFilterStart', tableFilterStart);
            p = p.set('tableFilterEnd', tableFilterEnd);
        }
    }

    private handlePagination(p: Map<string, string>, page?: Number, limit?: Number, order?: String, sort?: String): void {
        if (!Util.isNullOrUndefined(page)) {
            p = p.set('_page', page.toString());
            if (!Util.isNullOrUndefined(limit)) {
             p = p.set('_limit', limit.toString());
            }
        } else if (Util.isNullOrUndefined(limit)) {
            p = p.set('_limit', 'NOLIMIT');
        } else {
            p = p.set('_limit', limit.toString());
        }
        if (!Util.isNullOrUndefined(order)) {
            p = p.set('_order', order.toString());
        }
        if (!Util.isNullOrUndefined(sort)) {
            p = p.set('_sort', sort.toString());
        }
    }
}

