import { LpMeta, propertyMeta } from './meta';
import { LpThirdParty } from './third-party';
import { LpLocation } from './location';
import { LpCompany } from './company';
import { LpEquipment } from './equipment';
import { LpMovement } from './movement';
import { FtMeta } from './ft-meta';
import { Type } from 'class-transformer';

export class LpFine extends LpMeta {
    @propertyMeta('string')
    public number: String;
    @propertyMeta('string')
    public increasedFineNumber: String;
    @propertyMeta('string')
    public place: String;
    @propertyMeta('string')
    public registration: String;
    @Type(() => FtMeta)
    public nature: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public level: FtMeta = new FtMeta();
    @Type(() => LpThirdParty)
    public driver: LpThirdParty = new LpThirdParty();
    @Type(() => LpThirdParty)
    public customer: LpThirdParty = new LpThirdParty();
    @Type(() => LpLocation)
    public location: LpLocation = new LpLocation();
    @Type(() => LpMovement)
    public movement: LpMovement = new LpMovement();
    @Type(() => LpCompany)
    public company: LpCompany = new LpCompany();
    @Type(() => LpEquipment)
    public equipment: LpEquipment = new LpEquipment();
}
