<form class="app-search menu-search">
    <ng-template #typeHeadTopMenuTemplate let-menu="item" let-index="index">
      <span class="item-container">
        <i class="{{menu.icon}}"></i>
        <span>{{menu.label | translate}}</span>
      </span>
    </ng-template>
    <input type="text" placeholder="{{'general.research.search' | translate}}..." class="form-control"
      [typeaheadItemTemplate]="typeHeadTopMenuTemplate" typeaheadOptionField="translated"
      (typeaheadOnSelect)="onSelectItemMenu($event)" [typeaheadScrollable]="false" [typeaheadOptionsLimit]="1000000"
      [typeaheadLatinize]="true" [typeahead]="typeheadMenu" [(ngModel)]="searchVal" name="sitemap" autocomplete="off" #sitemap/>

    <i class="icon vega-search"></i>
  </form>