import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, ADDITIONAL_DRIVERS } from '../detail-forms.component';
import { LpAdditionalDriver } from 'app/meta/additional-driver';

@Component({
  selector: 'lp-additional-drivers',
  templateUrl: './additional-drivers.component.html'
})
export class AdditionalDriversComponent extends DetailFormsComponent implements OnInit {

  public data: LpAdditionalDriver = null;

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(ADDITIONAL_DRIVERS);
    this.initUrl();
    // this.data.index = Number(this.formStackService.CurrentApplicationItemDetailDataCount) + 1;
    // this.active = false;
  }
}
