<ng-template #popTemplate>
  <div *ngIf="contentTooltip" [innerHtml]="contentTooltip"></div>



  <ngx-datatable *ngIf="tableTooltip && tableTooltip.length > 0" class="material table-p-1 mb-1 mt-1" [rows]="tableTooltip" [columnMode]="'force'"
    [rowHeight]="'auto'" [headerHeight]="0" >
    <ngx-datatable-column name="">
      <ng-template let-column="column" let-row="row" ngx-datatable-cell-template>
        <lp-cell [value]="row.label | translate"></lp-cell> 
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <lp-cell [type]="row.type" [infos]="row.value.infos" [value]="row.value"></lp-cell>   
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>

</ng-template>
<ng-template #content>
  <ng-content></ng-content>
</ng-template>
<span *ngIf="contentTooltip !=='' || (tableTooltip && tableTooltip.length > 0)" [popover]="popTemplate" triggers="mouseenter"
  (mouseleave)="mouseleave(pop)" #pop="bs-popover" [outsideClick]="true" placement="{{placement}}" (onShown)="isOpen=true"
  (onHidden)="isOpen=false">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</span>
<span *ngIf="(!contentTooltip || contentTooltip ==='') && (!tableTooltip || tableTooltip.length === 0)">
  <ng-container *ngTemplateOutlet="content"></ng-container>
</span>
