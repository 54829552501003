import { Injectable } from '@angular/core';
import { RepositoryProvider } from './repository.provider'
import { ModalService } from 'app/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { LpNews } from 'app/meta/news';

const VISUAL_SETTINGS: string = 'news';

@Injectable({
    providedIn: 'root'
})

export class NewsProvider {

    constructor(
        private repositoryProvider: RepositoryProvider,
        private translate: TranslateService,
        private modalService: ModalService
        ) {};
    

        public async getNewsList(): Promise<LpNews> {
            return await this.repositoryProvider.get(VISUAL_SETTINGS);
        }

        public async getNewsById(id: string): Promise<LpNews> {
            return await this.repositoryProvider.get(`${VISUAL_SETTINGS}/${id}`, null, null, true);
        }

        public async putNewsById(news: LpNews, id: string): Promise<void> {
            return await this.repositoryProvider.put(`${VISUAL_SETTINGS}/${id}`, null, news).then(() => {
                this.modalService.success(this.translate.instant('general.modalService.successNews'),
                  this.translate.instant('general.modalService.success'));
              }).catch((error) => {
                this.modalService.error(this.translate.instant('general.modalService.errorNews'),
                  this.translate.instant('general.modalService.erreur'));
              });;
        }

        public async postNews(news: LpNews): Promise<LpNews> {
            return await this.repositoryProvider.post(VISUAL_SETTINGS, news);
        }

        public async deleteNewsById(news: LpNews): Promise<LpNews> {
            return await this.repositoryProvider.delete(VISUAL_SETTINGS, news);
        }

}
