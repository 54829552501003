import { LpMeta, propertyMeta } from './meta';
import { LpEquipment } from './equipment';
import { FtMeta } from './ft-meta';
import { LpThirdParty } from './third-party';
import { LpModel } from './model';
import { LpCompany } from './company';
import { LpInvoice } from './invoice';
import { Type } from 'class-transformer';

export class LpMovement extends LpMeta {
    @propertyMeta('number')
    public startMileage: Number;
    @propertyMeta('number')
    public startFuel: Number;
    @propertyMeta('number')
    public returnMileage: Number;
    @propertyMeta('number')
    public returnFuel: Number;
    @propertyMeta('date')
    public startDate: Date;
    @propertyMeta('date')
    public returnDate: Date;
    @Type(() => LpEquipment)
    public equipment: LpEquipment = new LpEquipment();
    @propertyMeta('string')
    public equipmentComment: String;
    @propertyMeta('string')
    public registration: String;
    @propertyMeta('string')
    public startComment: String;
    @propertyMeta('string')
    public returnComment: String;
    @propertyMeta('boolean')
    public isClosed: Boolean = false;
    @propertyMeta('string')
    public documentNumber: String;
    @propertyMeta('number')
    public level: Number;
    @propertyMeta('string')
    public grouping: String;
    @propertyMeta('number')
    public failurePrevention: Number;
    @propertyMeta('string')
    public unit: String;
    @Type(() => FtMeta)
    public category: FtMeta = new FtMeta();
    @propertyMeta('string')
    public originUpdate: String;
    @propertyMeta('string')
    public reference: String;
    @propertyMeta('date')
    public billingStartDate: Date;
    @propertyMeta('date')
    public billingEndDate: Date;
    @propertyMeta('string')
    public lastBillingPeriodDate: String;
    @propertyMeta('number')
    public billingQuantity: Number;
    @propertyMeta('number')
    public stockQuantity: Number;
    @propertyMeta('number')
    public packQuantity: Number;
    @propertyMeta('number')
    public unitAmountExcludingTax: Number;
    @propertyMeta('number')
    public devUnitAmountExcludingTax: Number;
    @Type(() => FtMeta)
    public documentType: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public reason: FtMeta = new FtMeta();
    @Type(() => LpInvoice)
    public event: LpInvoice = new LpInvoice();
    @Type(() => FtMeta)
    public startLocation: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public returnLocation: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public startPosition: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public returnPosition: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public position: FtMeta = new FtMeta();
    @Type(() => LpThirdParty)
    public customer: LpThirdParty = new LpThirdParty();
    @Type(() => LpThirdParty)
    public driver: LpThirdParty = new LpThirdParty();
    @Type(() => LpModel)
    public model: LpModel = new LpModel();
    @Type(() => LpCompany)
    public company: LpCompany = new LpCompany();
    @Type(() => FtMeta)
    public service: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public relatedTables: FtMeta = new FtMeta();
}
