<div class="modal-body p-0 modal-sm modalError">
    <form #form="ngForm">      
      <p class="titleError text-left m-0 mb-3">
        {{title}}
      </p>

      <div  class="text-center">
          <div class="ps-2 pe-2">
            {{"httpError.expiredSession" | translate}}
          </div>
          <hr class="m-3"/>       
      </div>

      <div class="row w-100 m-0">
        <div class="col text-end">
          <button type="button" class="modalCloseBtn btn-sm btn-secondary me-2" (click)="close()">{{'general.button.close' | translate}}</button>
        </div>
      </div>
    </form>
</div>
