import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, LINK_DAMAGE_HISTORICAL_OPERATIONS } from '../detail-forms.component';
import { LpLinkDamageHistoricalOperation } from 'app/meta/link-damage-historical-operation';

@Component({
  selector: 'lp-link-damage-historical-operations',
  templateUrl: './link-damage-historical-operations.component.html'
})

export class LinkDamageHistoricalOperationsComponent extends DetailFormsComponent implements OnInit {

  public data: LpLinkDamageHistoricalOperation = null;

  public init(): void {
    this.data.assign(this.formStackService.currentDetailLineData);
  }

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(LINK_DAMAGE_HISTORICAL_OPERATIONS);
    this.initUrl();
    // this.init();
  }

}
