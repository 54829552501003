import { Component, OnInit, Input, Attribute } from '@angular/core';

@Component({
  selector: 'lp-card',
  templateUrl: './card.component.html'
})
export class LpCardComponent implements OnInit {

  @Input() public accentColor: string;
  @Input() public outlineColor: string;
  public noBorder: string;
  public noMargin: string;

  constructor(
    @Attribute('no-border') border: string,
    @Attribute('no-margin') margin: string,
  ) {
    this.noBorder = (border !== null) ? 'border-0' : '';
    this.noMargin = (margin !== null) ? 'm-0' : '';
  }

  public ngOnInit(): void {
    this.accentColor = (this.accentColor) ? 'card-accent-' + this.accentColor : '';
    this.outlineColor = (this.outlineColor) ? 'card-outline-' + this.outlineColor : '';
  }


}
