<div class="modal-body p-0 modal-sm modalError">
    <form #form="ngForm">      
      <p class="titleError text-left m-0 mb-3">
        {{title}}
      </p>

      <div  class="text-center">
        <div *ngFor="let error of modalService.arrayHttpError">
          <div class="ps-2 pe-2">
            {{translateByDefault(error) | translate}}
        
          </div>
          <hr class="m-3"/>
        </div>

        
        <div *ngIf="showDetail && isAdminOrNotLogin()">
          <div *ngFor="let error of modalService.arrayHttpError" class="ps-2 pe-2">
            <p class="mb-0 m-3">{{error.statusCode}} - <span *ngIf="error.message !=''">{{error.message}}</span><span *ngIf="error.message ==''">{{error.error}}</span></p>  
          </div>
          <hr class="m-3"/>
        </div>
        
      </div>

      <div class="row w-100 m-0">
        <div  *ngIf="isAdminOrNotLogin()" class="col text-small pt-1 cursor-pointer text-dark">
          <small (click)="showDetail=!showDetail" class="cursor-pointer" [class.d-none]="!showDetail">{{'general.button.lessDetails' | translate}}</small>
          <small (click)="showDetail=!showDetail" class="cursor-pointer" [class.d-none]="showDetail">{{'general.button.moreDetails' | translate}}</small>
        </div>
        <div class="col text-end">
          <button type="button" class="modalCloseBtn btn btn-secondary me-2" (click)="close()">{{'general.button.close' | translate}}</button>
        </div>
      </div>
    </form>
</div>
