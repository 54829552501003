import { LpMeta, propertyMeta } from './meta';
import { FtMeta } from './ft-meta';
import { Type } from 'class-transformer';
import { LpUser } from './user';

export class LpWorkflow extends LpMeta {
    @Type(() => FtMeta)
    public activity: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public priority: FtMeta = new FtMeta();
    @propertyMeta('string')
    public relatedObject: String;
    @propertyMeta('string')
    public triggerDate: String;
    @Type(() => FtMeta)
    public type: FtMeta = new FtMeta();
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('date')
    public lastUpdateDate: Date;
    @propertyMeta('string')
    public documentNumber: String;
    @propertyMeta('date')
    public closureDate: Date;
    @propertyMeta('boolean')
    public isCancelled: Boolean;
    @propertyMeta('string')
    public remarq: String;
    @Type(() => LpUser)
    public recipient: LpUser = new LpUser();
}
