
import { ZoomProvider } from './zoom.provider';
import { WidgetInterface } from 'app/models/widget';
import { Injectable } from '@angular/core';
import { Util } from 'app/statics/utils';
import { ConstraintProvider } from './constraint.provider';

@Injectable({
    providedIn: 'root'
})

export class TempWidgetProvider {

    constructor(private widgetProvider: ZoomProvider, private constraintProvider: ConstraintProvider) { }

    public async getWidgetData(config: WidgetInterface, screen?: string): Promise<any> {
        if (config.type === 99 ) {
            return await this.constraintProvider.getConstraint(config.settings.providers[0].constraintZone, null, null, true, screen);
        } else {
            let locProZoomObject: String = this.getLocProZoomObjectFromConfig(config);
            let locProZoomAttribut: String = this.getLocProZoomAttributeFromConfig(config);
            let id: string = this.getIdFromConfig(config);
            return await this.widgetProvider.getWidgetData(id, config.type, locProZoomObject, locProZoomAttribut);
        }
    }

    private getLocProZoomAttributeFromConfig(config: WidgetInterface): String {
        let locProZoomAttribut: String;
        if (!Util.isNullOrUndefined(config.settings.providers)) {
          locProZoomAttribut = config.settings.providers[0].locProZoomAttribut;
        }
        if (config.type === 8 && !Util.isNullOrUndefined(config.settings.config)) {
          locProZoomAttribut = config.settings.config.locProZoomAttribut
        }
        return locProZoomAttribut;
      }

      private getLocProZoomObjectFromConfig(config: WidgetInterface): String {
        let locProZoomObject: String;
        if (!Util.isNullOrUndefined(config.settings.providers)) {
          locProZoomObject = config.settings.providers[0].locProZoomObject
        }
        if (config.type === 8 && !Util.isNullOrUndefined(config.settings.config)) {
          locProZoomObject = config.settings.config.locProZoomObject
        }
        return locProZoomObject;
      }

      private getIdFromConfig(config: WidgetInterface): string {
        let id: string = null;
        if (!Util.isNullOrUndefined(config.settings.providers)) {
          id = config.settings.providers[0].id
        }
        if (config.type === 8 && !Util.isNullOrUndefined(config.settings.config)) {
          id = config.settings.config.id
        }
        return id;
      }
}
