import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputFieldComponent } from '../input-field.component';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';
import { ConfigService } from 'app/services/config.service';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { UiSyncService } from 'app/services/ui-sync.service';
import { FormStackService } from 'app/services/form-stack.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { EvalService } from 'app/services/eval.service';
import { ChangeService } from 'app/services/change.service';
import { Util } from 'app/statics/utils';

@Component({
  selector: 'lp-json-field',
  templateUrl: './json-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JsonFieldComponent),
      multi: true
    }
  ]
})
export class JsonFieldComponent extends InputFieldComponent implements OnInit {

  /**Le paramètre id permet de configurer un id spécifique pour le composant.
  */
  @Input() public id: string;
  @Input() public name: string;

  @Input() public modes: any = ['code', 'form', 'text', 'tree', 'view'];

  
  @ViewChild("editor", {static: true}) public editor: JsonEditorComponent;
  
  public jsonEditorOptions: JsonEditorOptions = new JsonEditorOptions();

  constructor(
    protected changeService: ChangeService,
    protected evalService: EvalService,
    protected formMetadataProvider: FormMetadataProvider,
    public formStackService: FormStackService,
    protected uiSyncService: UiSyncService, 
    protected metaFactoryService: MetaFactoryService,
    protected configService: ConfigService
     ) {
      super(changeService, evalService, formStackService, formMetadataProvider, uiSyncService, metaFactoryService, configService);     
      
  }

  public ngOnInit() {
    this.jsonEditorOptions.modes = this.modes;
  }

  public pushChange(): void {
    if (!Util.isNullOrUndefined(this.displayValue) && this.previousValue !== this.displayValue
          && (Util.isNullOrUndefined(this.ignoreChange) || !this.ignoreChange)) {
      this.setHasChanged(true, this);
    }
    this.putOnFocusOut();
    this.propagateChange(this.editor.get());
    this.uiSyncService.emitAvailableEvalForJsonInputField();
  }
  
}
 