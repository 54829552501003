import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { InputFieldComponent } from '../input-field.component';
import { ConfigService } from 'app/services/config.service';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { UiSyncService } from 'app/services/ui-sync.service';
import { FormStackService } from 'app/services/form-stack.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { EvalService } from 'app/services/eval.service';
import { ChangeService } from 'app/services/change.service';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { EditorConfig } from '@ckeditor/ckeditor5-core/src/editor/editorconfig';


@Component({
  selector: 'lp-wysiwyg-editor-field',
  templateUrl: './wysiwyg-editor.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpWysiwygEditorFieldComponent),
      multi: true
    }
  ]
})
export class LpWysiwygEditorFieldComponent extends InputFieldComponent implements OnInit {
  
  @Input() public editorData: String = '';
  @Input() public id: string;
  @Input() public name: string;

  public Editor = ClassicEditor;
  public formattedData: String;
  public validationComplete: Boolean = false;
  public loadingButton: Boolean = false;
  public options: EditorConfig = { 
    toolbar: {items: [ 'heading', '|', 'bold', 'italic', 'link'] },
    link : {
      decorators: {
        openInNewTab: {
            mode: 'manual',
            label: 'Ouvrir dans un nouvel onglet',
            defaultValue: true,        
            attributes: {
                target: '_blank',
                rel: 'noopener noreferrer'
            }
        }
      }
    }
  };

  constructor(
    protected changeService: ChangeService,
    protected evalService: EvalService,
    protected formMetadataProvider: FormMetadataProvider,
    public formStackService: FormStackService,
    protected uiSyncService: UiSyncService, 
    protected metaFactoryService: MetaFactoryService,
    protected configService: ConfigService
     ) {
      super(changeService, evalService, formStackService, formMetadataProvider, uiSyncService, metaFactoryService, configService);     
      
  }

  public ngOnInit(): void {
  }

  public onChange( {editor}) {
    this.editorData = editor.getData();
    this.propagateChange(editor.getData());
  }
  
}
 