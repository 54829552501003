
      <mat-progress-spinner
      class="example-margin"
        [color]="color"
        [mode]="mode"
        [value]="value">
      </mat-progress-spinner>
      <!--style="
        stroke: #3f51b5;
        position: fixed;
        z-index: 999;
        height: 2em;
        width: 2em;
        overflow: show;
        margin: auto;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.3)"-->