import { Injectable } from '@angular/core';
import { LpPricingDetail } from 'app/meta/pricing-detail';
import { AlocproProvider, PRICING_DETAILS } from 'app/providers/alocpro.provider';
import { PaginatedData } from 'app/models/paginated-data';
import { LpMeta } from 'app/meta/meta';
import { Pricing } from 'app/meta/pricings/pricings';
import { PricingProvider } from 'app/providers/pricing.povider';

@Injectable({
    providedIn: 'root'
  })
export class PricingService {

    constructor(private alocproProvider: AlocproProvider, private pricingPovider: PricingProvider) { }

    public async getPricingList(filters: LpMeta): Promise<LpPricingDetail[]> {
        let data: PaginatedData = await this.alocproProvider.getPricingDetailsList(filters);
        let pricingDetailList: LpPricingDetail[] = await this.hydrateAllPricingDetails(data);
        return pricingDetailList;
    }

      // fction for stime \\
      public async getDetailsData(type: string, data: LpMeta): Promise<Pricing>{
          let url: string = this.buildUrl(data);
          return await this.pricingPovider.getDetailsData(type, url);
      }

      // TODO
      // prend l'objet data en params (champs du formulaire de geuche formStackService.currentData) et sort une url ex : /pricing?pdv=00032&univer=MOBILITE&category=A&etc....
      private buildUrl(data: LpMeta) : string {
          //cf quickquote component buildBestDealUrl
          return "";
      }
      //////////\\\\\\\\\\\

    private async hydrateAllPricingDetails(data: PaginatedData): Promise<LpPricingDetail[]> {
        let pricingDetailListAllHydrates: LpPricingDetail[] = [];
        if (data.body !== null) {
            for (let p of data.body) {
                let pricing: LpPricingDetail = new LpPricingDetail();
                pricing.assign(p);
                pricingDetailListAllHydrates.push(await this.hydratePricingDetail(pricing));
            }
        }
        return pricingDetailListAllHydrates;
    }

    private async hydratePricingDetail(pricingDetail: LpPricingDetail): Promise<LpPricingDetail> {
        return await this.alocproProvider.find(PRICING_DETAILS, pricingDetail.id) as LpPricingDetail;
    }

}
