import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'app/services/modal.service';
import { PaginatedData } from 'app/models/paginated-data';
import { Util } from 'app/statics/utils';
import { LpMeta } from 'app/meta/meta';
import { LpModalComponent } from '../lp-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UiSyncService } from 'app/services/ui-sync.service';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { Subscription } from 'rxjs';
import { ApplicationItemDetail, ApplicationItemDetailColumn } from 'app/models/application-item-detail';
import { ApplicationItem } from 'app/models/application-item';
import { FormStackService } from 'app/services/form-stack.service';
import { ApplicationItemProvider } from 'app/providers/application-item.provider';
import { JsdataService } from 'app/services/jsdata.service';
import { UserService } from 'app/services/user.service';
import { FormatService } from 'app/services/format.service';

const TRACES = 'traces';
const BULK_ACTION_DELETE: string = 'delete';

@Component({
  selector: 'lp-modal-trace',
  templateUrl: './lp-modal-trace.component.html',
})
export class LpModalTraceComponent extends LpModalComponent implements OnInit, OnDestroy {
  public loadingIndicator: boolean = false;
  public traces: LpMeta[];
  public nbTraces: Number = 0;
  public showTable: Boolean;
  public modalReady: Boolean = false;
  public currentPage: Number = 1;
  public actualPageNumber: number = 1;
  public linesPerPage: Number = 10;
  public columns: Object[] = [];
  public mapActiveRows: Map<String, Boolean> = new Map();
  public traceStruct: ApplicationItem = new ApplicationItem();
  public showConfigPanel: Boolean = false;
  public showDetails: boolean = false;
  public trace: string;
  public contentTitle: string
  private subscriptionAfterOpened: Subscription;


  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected uiSyncService: UiSyncService,
    public modalService: ModalService,
    public formStackService: FormStackService,
    public applicationItemProvider: ApplicationItemProvider,
    public jsdataService: JsdataService,
    public userService: UserService,
    public alocproProvider: AlocproProvider,
    public formatService: FormatService,
    protected translate: TranslateService) {
      super(dialogRef, data);
      this.showTable = false;
      this.linesPerPage = new Number(userService.getCurrentUser().preference.linesPerPage);
  }

  public async ngOnInit(): Promise<void> {
    this.traceStruct = await this.applicationItemProvider.getObjectDetails(TRACES);
    this.traces = await this.refreshTracesList();
    this.subscriptionAfterOpened = this.modalService.getAfterOpened().subscribe(() => this.modalReady = true);
  }

  public getDisplayedColumns(): Array<ApplicationItemDetailColumn> {
    let activeColums: ApplicationItemDetailColumn[] = [];
    for (let index: number = 0; index < this.traceStruct?.details[0]?.columns.length; index++) {
      if (this.traceStruct?.details[0]?.columns[index].isDisplayed) {
        activeColums.push(this.traceStruct?.details[0]?.columns[index]);
      }
    }
    return activeColums;
  }

  public changeConfigPanel(): void {
    this.showConfigPanel = !this.showConfigPanel;
  }

  public async changePage(pageInfo: any): Promise<void> {
    this.actualPageNumber = pageInfo.currentPage;
    this.linesPerPage = pageInfo.linesPerPage;
    this.traces = await this.refreshTracesList();
  }

  public async refreshTracesList(): Promise<LpMeta[]> {
    let tracesTmp: LpMeta[] = [];
    this.loadingIndicator = true;
    if (this.actualPageNumber > 1 && this.traces && this.traces.length === 1) {
      this.actualPageNumber = this.actualPageNumber - 1;
    }
    this.traces = [];
    let result: PaginatedData = await this.alocproProvider.getPaginatedData(`${this.data.verb}/${this.data.ky}/traces`, this.actualPageNumber, null, null, this.linesPerPage);
    if (!Util.isNullOrUndefined(result)) {
      this.nbTraces = 0;
      let tracesTemp: LpMeta[] = [];
      let nbTracesTemp: number = 0;
      if (!Util.isNullOrUndefined(result.count)) {
        nbTracesTemp = result.count;
      }
      if (!Util.isNullOrUndefined(result.body) && result.body.length !== 0) {
        result.body.forEach(async (element) => {
          tracesTemp.push(element as LpMeta);
        });
        tracesTmp = tracesTemp;
        this.nbTraces = nbTracesTemp;
      }
    }
    this.loadingIndicator = false; this.showTable = true;
    return tracesTmp;
  }


  public rowClass = (row: any) => {
    if (!Util.isNullOrUndefined(row)) {
      return {
        'footer-table': row.id === 'footer_table',
        'barre': row.action === BULK_ACTION_DELETE
      }
    }
  }

  public onSort(event: any): void {
    this.loadingIndicator = true;
    this.currentPage = 1;
    this.alocproProvider.getPaginatedData(
      this.uiSyncService.formatURL(this.formStackService.currentApplicationItemDetail.path, this.traces),
      this.currentPage,
      ApplicationItemDetail.buildQueryParams('GET', this.formStackService.currentApplicationItemDetail),
      false,
      this.linesPerPage,
      event.sorts[0].dir.toUpperCase(),
      event.sorts[0].prop)
      .then((records: PaginatedData) => {
        this.formStackService.CurrentApplicationItemDetailData = records.body;
        this.formStackService.CurrentApplicationItemDetailDataReadonly = records._readonly;
        this.mapActiveRows.clear();
        this.formStackService.CurrentApplicationItemDetailData.forEach(row => {
          this.mapActiveRows.set(row.id, true);
        });
        this.loadingIndicator = false;
      });
  }

  public selectItem(event: Event): void {
    if(event.type === 'click'){
      this.trace = event['row']
      this.showDetails = true;
      if(event['row'].date.indexOf('Z')){
        event['row'].date = event['row'].date.substr(0, event['row'].date.length - 1)
      }
      this.contentTitle = this.formatService.formatValue(5, event['row'].date) + ": " + this.translate.instant("traces." + event['row'].action) + " par " +  event['row'].user.wording 
    }
  }

  public ngOnDestroy(): void {
    
    if (this.subscriptionAfterOpened) { this.subscriptionAfterOpened.unsubscribe(); }
  }
}
