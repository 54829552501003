import { Injectable } from '@angular/core';
import { LpMeta } from 'app/meta/meta';
import { ZoomCriteria, ZoomMeta } from 'app/meta/zoom/zoomMeta';
import { Util } from 'app/statics/utils';
import { ZoomConfig, ZoomMetaData } from 'app/models/zoom-metada';
import { HttpParams } from '@angular/common/http';
import { UserService } from 'app/services/user.service';
import { PaginatedData } from 'app/models/paginated-data';
import { AlocproProvider } from './alocpro.provider';
import { RepositoryProvider } from 'app/providers/repository.provider';
import { UiSyncService } from 'app/services/ui-sync.service';
import { FormStackService } from 'app/services/form-stack.service';
import { JsdataService } from 'app/services/jsdata.service';

const EXECUTE: String = '/execute';
const TECH_ZOOM_LINKS: String = 'technical/zoom-links';
const TECH_ZOOM_LINKS_WITHOUT_SLASH: String = '/technical/zoom-links';
const PARAMETERS: String = 'parameters';

@Injectable({
    providedIn: 'root'
  })
export class ZoomProvider {

    public mapCacheExecuteZoomDropDown: Map<String, any> = new Map();

    constructor(
        protected aloc: AlocproProvider,
        protected repositoryProvider: RepositoryProvider,
        private uiSyncService: UiSyncService,
        private formStackService: FormStackService,
        private userService: UserService,
        private jsdataService: JsdataService
        //public http: HttpProvider
        ) {}

        public async executeWithCache(zoomConfig: ZoomConfig, object: LpMeta, criteria: Array<ZoomCriteria>,
            limit?: string, page: Number = 1, isDropDownMode?: boolean): Promise<PaginatedData> {
            try {
                if (!Util.isNullOrUndefined(isDropDownMode) && isDropDownMode === true && !Util.isNullOrUndefined(zoomConfig) && !Util.isNullOrUndefined(zoomConfig.locProZoomId)) {
                    if (!this.existsInDropDownCache(zoomConfig) || zoomConfig.zoomParameter.criterias.length > 0) {
                        const paginatedData: PaginatedData = await this.execute(zoomConfig, object, criteria, limit, page);
                        if (zoomConfig.zoomParameter.criterias.length === 0) {
                            this.setCacheForZoomDropDown(zoomConfig.locProZoomId, paginatedData, isDropDownMode);
                        }
                        return paginatedData;
                    } 
                    return this.getCacheForZoomDropDown(zoomConfig.locProZoomId);
                } else {
                    return await this.execute(zoomConfig, object, criteria, limit, page);
                }
            } catch (error) {
                throw error;
            }
        } 
     
        public setCacheForZoomDropDown(locProZoomId: string, data: any, isDropDownMode: boolean): void {
            try {
                if ( !Util.isNullOrUndefined(isDropDownMode) && isDropDownMode === true) {
                    this.mapCacheExecuteZoomDropDown.set(locProZoomId, data);
                }
            } catch (error) {
                throw error;
            }
        }

        public getCacheForZoomDropDown(locProZoomId: string): PaginatedData {
            try {
                return this.mapCacheExecuteZoomDropDown.get(locProZoomId);
            } catch (error) {
                throw error;
            }
        }

        public existsInDropDownCache(zoomConfig: ZoomConfig): boolean {
            let existsInCache: boolean = false;
            if ( !Util.isNullOrUndefined(zoomConfig.locProZoomId) && !Util.isNullOrUndefined(this.getCacheForZoomDropDown(zoomConfig.locProZoomId))) {
                existsInCache = true;
            }
            return existsInCache;
        }

        public clearMapZoomForDropDown(): void {
            this.mapCacheExecuteZoomDropDown.clear();
        }

    public execute(config: ZoomConfig, object: LpMeta, criteria: Array<ZoomCriteria>,
        limit?: string, page: Number = 1): Promise<PaginatedData> {
        if (!Util.isNullOrUndefined(config) && !Util.isNullOrUndefined(config.linesPerPage)) {
            limit = config.linesPerPage.toString();
        } else if (Util.isNullOrUndefined(limit) && this.userService.getCurrentUser().preference) {
            limit = this.userService.getCurrentUser().preference.linesPerPage;
        }
            if (!Util.isNullOrUndefined(config) && !Util.isNullOrUndefined(config.isLocProZoom) && config.isLocProZoom) {
                return this.getSomeDataGenericZoom(config.locProZoomId, this.buildParamsGenericZoom(criteria), limit, page);
            } else {
                return new Promise<PaginatedData>((resolve) => {
                    this.getZoomDataList(config, object, null, null, page).then( (data: PaginatedData) => {
                        resolve(data);
                    });
                });
            }
    }

    public getZoomDataList(config: ZoomConfig, formData: LpMeta, filter?: string, limit?: string, page?: Number): Promise< PaginatedData> {
        return new Promise<PaginatedData>((resolve) => {
            let filters: Map<string, string>;
                filters = new Map();
            if (!Util.isNullOrUndefined(filter)) {
                filters.set('search', filter);
            }
            if (!Util.isNullOrUndefined(limit)) {
                filters.set('_limit', limit);
            } else if (!Util.isNullOrUndefined(config) && !Util.isNullOrUndefined(config.linesPerPage)) {
                filters.set('_limit', config.linesPerPage.toString());
            } else if (Util.isNullOrUndefined(limit) && this.userService.getCurrentUser().preference) {
                limit = this.userService.getCurrentUser().preference.linesPerPage;
            }

            this.aloc.getPaginatedData(this.uiSyncService.formatURL(config.zoomParameter.path, formData).toString(),
            page, filters, true).then((paginatedData: PaginatedData) => {
                resolve(paginatedData);
            });
        });
    }

    public async getDataWithURL(url: string): Promise<PaginatedData> {
        try {
            return await this.aloc.getPaginatedData(url);
        } catch (error) {
            throw error;
        }
    }

    public getRowData(addModifyVerb: string, value: string, verbSearch?: String, headers?: any): Promise<LpMeta> {
        return new Promise<LpMeta>((resolve, reject) => {
            let p: HttpParams = new HttpParams();
            let url: string = this.uiSyncService.formatURL(addModifyVerb, this.formStackService);
            if (!Util.isNullOrUndefined(verbSearch) && !Util.isNullOrUndefined(value)) {
                p = p.set(verbSearch.toString(), value);
                url += `${p.toString()}`
                this.aloc.find(url, null, null, headers).then((data: LpMeta) => {
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });
            } else {
                this.aloc.find(url, value, null, headers).then((data: LpMeta) => {
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });
            }

        });
    }

    public getParameters(attributZoom: string, objectZoom: string): Promise<ZoomMeta> {
        return new Promise<ZoomMeta>((resolve) => {
            let map: HttpParams = new HttpParams();
            map = map.set('attribute', attributZoom);
            map = map.set('object', objectZoom);
            let url: string = `${TECH_ZOOM_LINKS}/${PARAMETERS}?${map.toString()}`;
            
            this.aloc.findForZoom(url).then( (data: ZoomMeta) => {
                data.fields.forEach(f => {
                    if (!Util.isNullOrUndefined(f.type)) {
                        f.type = this.formatType(f.type.toString());
                    } else {
                        f.type = 1;
                    }
                });

                for (let i = 0; i < data.criterias.length; i++) {
                    if (!Util.isNullOrUndefined(data.criterias[i].defaultValue)) {
                        /* Permet de passer des valeurs par défaut dans les critère en allant les chercher dans les datas.
                        * Pour passer le chemin au critère, utilsser le champs "valeur par défaut" dans locpro.
                        * Pour la colonne de gauche passer du style: {{currentData.category.id}}
                        * Pour la colonne de droite passer du style: {{currentDetailLineData.category.id}}
                        */
                        const found: any = data.criterias[i].defaultValue.match('\{{2}(.*)\}{2}');
                        if (!Util.isNullOrUndefined(found)) {                     
                            data.criterias[i].dynamicDefaultValue = found[1];
                            data.criterias[i].defaultValue = this.setcriteriaDefaultValue(data.criterias[i]);
                        } else if(data.criterias[i].dynamicDefaultValue && Util.isNullOrUndefined(data.criterias[i].defaultValue)) {
                            data.criterias[i].defaultValue = this.setcriteriaDefaultValue(data.criterias[i]);
                        }
                    }
                }   
                resolve(data);
            });
        }).catch((e) => {
            console.log(e);
            throw e;
        });
    }

    public getParametersByID(id: string): Promise<ZoomMeta> {
        return new Promise<ZoomMeta>((resolve) => {
            let url: string = `${TECH_ZOOM_LINKS}/${id}/${PARAMETERS}`;
            this.aloc.findForZoom(url).then((data: ZoomMeta) => {
                data.fields.forEach(f => {
                    if (!Util.isNullOrUndefined(f.type)) {
                        f.type = this.formatType(f.type.toString());
                    } else {
                        f.type = 1;
                    }
                });
                data.criterias.forEach((criteria: ZoomCriteria) => {
                    if (!Util.isNullOrUndefined(criteria.defaultValue)) {
                        /* Permet de passer des valeurs par défaut dans les critère en allant les chercher dans les datas.
                        * Pour passer le chemin au critère, utilsser le champs "valeur par défaut" dans locpro.
                        * Pour la colonne de gauche passer du style: {{currentData.category.id}}
                        * Pour la colonne de droite passer du style: {{currentDetailLineData.category.id}}
                        */
                        const found: any = criteria.defaultValue.match('\{{2}(.*)\}{2}');
                        if (!Util.isNullOrUndefined(found) && Util.isNullOrUndefined(criteria.defaultValue)) {
                            criteria.defaultValue = this.jsdataService.getDataRef(this.formStackService, found[1]);
                        }
                    }
                });
                resolve(data);
            });
        });
    }


    public buildParamsGenericZoom(filters: Array<ZoomCriteria>): Map<string, string> {
        let criteria: Map<string, string> = new Map()
        if (filters && filters.length !== 0) {
            this.storeDefaultFilters(filters).forEach((value, filter) => {
                criteria.set(filter, value);
            });
        }
        return criteria;
    }

    public getDataListMetadata(addModifyVerb: String): Promise<ZoomMetaData> {
        return new Promise<ZoomMetaData>((resolve) => {
            if (!Util.isNullOrUndefined(addModifyVerb)) {
                this.repositoryProvider.getZoomMetadata(addModifyVerb.toString()).then((recordMeta: ZoomMetaData) => {
                    resolve(recordMeta);
                });
            }
        })
    }

    public getRowWithFilters(config: ZoomConfig, filters: Array<ZoomCriteria>, formData?: LpMeta,
        limit?: string, page: Number = 1): Promise<PaginatedData> {
        if (Util.isNullOrUndefined(limit) && this.userService.getCurrentUser().preference) {
            limit = config.linesPerPage.toString();
        }
        if (!Util.isNullOrUndefined(config.isLocProZoom) && config.isLocProZoom) {
            let url: string = `${TECH_ZOOM_LINKS}/${config.locProZoomId}${EXECUTE}`;
            return new Promise<PaginatedData>((resolve, reject) => {
                this.aloc.getPaginatedData(url, page, this.buildParamsGenericZoom(filters), null, limit).then((resp: PaginatedData) => {
                    resolve(resp);
                }).catch((err) => {
                    reject(err);
                });
            });
        } else {
            return new Promise<any>((resolve) => {
                this.getZoomDataList(config, formData, filters[0].defaultValue, null, page).then((data: PaginatedData) => {
                    resolve(data);
                });
            });
        }
    }

    public async getZoomFullDataList(attributZoom: string, objectZoom: string): Promise<PaginatedData> {
        try {
            const zoomMeta: ZoomMeta = await this.getParameters(attributZoom, objectZoom);
            let datas: PaginatedData = this.getCacheForZoomDropDown(zoomMeta.id);
            if (Util.isNullOrUndefined(datas)) {
                datas = await this.getSomeDataGenericZoom(zoomMeta.id, null, 'NOLIMIT');
                this.setCacheForZoomDropDown(zoomMeta.id, datas, true);
            }
            return this.getCacheForZoomDropDown(zoomMeta.id);
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    public setcriteriaDefaultValue(criteria: ZoomCriteria): string {
        if (criteria.dynamicDefaultValue.indexOf(' | ') != -1) {
            var pathes = criteria.dynamicDefaultValue.split(' | ');
            for (let i = 0; i < pathes.length; i++) {
                const path = pathes[i];
                if (this.jsdataService.hasDataRef(this.formStackService, path)) {                    
                    return this.jsdataService.getDataRefForZoom(this.formStackService, path);
                }                    
            }
        } else {
            return this.jsdataService.getDataRefForZoom(this.formStackService, criteria.dynamicDefaultValue);
        }
    }

    public async getWidgetData(widgetIdDataSourceId: string, widgetType: Number, object?: String
        , attribut?: String, zoneName?: string): Promise<any> {
        let params: ZoomMeta;
        let d: PaginatedData;
        if (!Util.isNullOrUndefined(widgetIdDataSourceId) && Util.isNullOrUndefined(zoneName)) {
            params = await this.getParametersByID(widgetIdDataSourceId);
            d = await this.getSomeDataGenericZoom(widgetIdDataSourceId);
        } else if (!Util.isNullOrUndefined(attribut) && !Util.isNullOrUndefined(object) && Util.isNullOrUndefined(zoneName)) {
            params = await this.getParameters(attribut.toString(), object.toString());
            d = await this.getSomeDataGenericZoom(params.id);
        }
        if (widgetType === 2 || widgetType === 3 || widgetType === 9 ||
            widgetType === 10 || widgetType === 11 || widgetType === 12 || widgetType === 13 || widgetType === 14 || widgetType === 15) {
            return await this.transformDataForWidget(params, d.body);
        } else if (widgetType === 8) {
            return this.transformDataForTable(params, d.body);
        } else if (widgetType === 99 && !Util.isNullOrUndefined(zoneName)) {
            return await this.aloc.find('technical/scripts', zoneName);
        }
        /*return new Promise<any>((resolve, reject) => {
            let v: any = [
                {
                  "name": "Germany",
                  "value": 40632,
                },
                {
                  "name": "United States",
                  "value": 50000,
                },
                {
                  "name": "France",
                  "value": 36745,
                },
                {
                  "name": "United Kingdom",
                  "value": 36240,
                },
                {
                  "name": "Spain",
                  "value": 33000,
                },
                {
                  "name": "Italy",
                  "value": 35800,
                }
              ];
              resolve(v);
        });*/
    }

    public async getZoomLinksList(attributZoom: string, objectZoom: string): Promise<any> {
        let url: string = `${TECH_ZOOM_LINKS_WITHOUT_SLASH}?attribute=${attributZoom}&object=${objectZoom}`;
        return await this.aloc.findForZoom(url);
    } 

    private transformDataForWidget(params: ZoomMeta, data: LpMeta[]): any {
        let finalData: Array<any> = [];
        data.forEach((d: LpMeta) => {
            let tempRow: any = {};
            params.fields.forEach((f) => {
                    tempRow[f.wording] = d[f.id]
            });
            finalData.push(tempRow);
        });
        return finalData;
    }

    private transformDataForTable(params: ZoomMeta, data: LpMeta[]): any  {
        let finalObjectData: any = {};
        finalObjectData.data = data;
        finalObjectData.param = params;
        return finalObjectData;
    }

    private storeDefaultFilters(filters: Array<ZoomCriteria>): Map<string, string> {
        let filterMap: Map<string, string> = new Map();
        filters.forEach( (criteria) => {
         if (criteria && criteria.defaultValue && criteria.paramName) {
           filterMap.set(criteria.paramName, criteria.defaultValue);
         }
       })
       return filterMap;
    }

    private getSomeDataGenericZoom(idZoom: string, filters?: Map<string, string>, limit?: string, page?: Number): Promise<PaginatedData> {
        return new Promise<PaginatedData>((resolve, reject) => {
            this.aloc.getPaginatedData(`${TECH_ZOOM_LINKS}/${idZoom}${EXECUTE}`, page, filters, null, limit).then((resp: PaginatedData) => {
                resolve(resp);
            }).catch((err) => {
                reject(err);
            });
        });
    }

    private formatType(type: string): number {
        if (type === 'date' || type === '4') {
            return 4;
        } else if (type === 'devise'|| type === '10') {
            return 10;
        } else if (type === 'dateTime' || type === '5') {
            return 5;
        } else if (type === 'html'|| type === '99') {
            return 99;
        } else if (type === 'entier'|| type === '2') {
            return 2;
        } else if (Util.isNullOrUndefined(type)) {
            return 1;
        } else {
            return 1;
        }
    }
}
