import { Injectable, EventEmitter } from '@angular/core';
import { UserInterface, MenuItem, Module, Preference } from '../models/user.interface';
import { LocalStorageService } from './local-storage.service';
import { Util } from 'app/statics/utils';
// import { RepositoryProvider } from 'app/providers/repository.provider';
// import { AlocproProvider } from 'app/providers/alocpro.provider';
import { CryptoService } from './crypto.service';
import { ConfigService } from './config.service';
import { Dashboard } from 'app/models/dashboard';
import { LpVisualSettings } from 'app/meta/visual-settings';
// import { ZoomService } from './zoom.service';
// import { BulkService } from './bulk.service';

const USER_LP3K: string = 'user-lp3k';
const USER_EVENTS: string = 'userEvent';
const USER_LANG: string = 'userLang';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public userConnectedEvtEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  public urlReferer: String;
  private currentUser: UserInterface;

  constructor(
    private localStorageService: LocalStorageService,
    // private repositoryProvider: RepositoryProvider,
    // private alocproProvider: AlocproProvider,
    // private zoomService: ZoomService,
    // private bulkService: BulkService,
    private cryptoService: CryptoService,
    private configService: ConfigService
    ) {}

  public hashPassword(password: string):any {
   return this.cryptoService.getSHA256(password);
  }

  public setCurrentUser(user: UserInterface): void {
    this.currentUser = user;
    this.localStorageService.setObject(USER_LP3K, user);
    this.localStorageService.setObject(USER_EVENTS, []);
  }

  public setMenu(menus: MenuItem[]): void {
    this.currentUser.outbar = this.cleanUserMenu(menus);
    this.currentUser.menu = menus;
    this.localStorageService.setObject(USER_LP3K, this.currentUser);
  }

  public setDashboard(dashooard: Dashboard): void {
    this.currentUser.dashboard = dashooard;
    this.localStorageService.setObject(USER_LP3K, this.currentUser);
  }

  public setVisualSettings(visualSettings: LpVisualSettings): void {
    this.currentUser.visualSettings = visualSettings;
    this.localStorageService.setObject(USER_LP3K, this.currentUser);
  }

  public getVisualSettings(): LpVisualSettings {
    return this.currentUser.visualSettings;
  }

  public getDashboard(): Dashboard {
    let user: UserInterface = JSON.parse(this.localStorageService.get(USER_LP3K).toString());
    const dashboard: Dashboard = user.dashboard;
    return dashboard;
  }

  public setLanguage(lang: string): void {
    this.localStorageService.setObject(USER_LANG, lang);
  }

  public getCurrentLang(): string {
    if (this.localStorageService.getObject(USER_LANG) === null) {
      this.setLanguage(this.configService.get('default_language'));
    }
    return this.localStorageService.getObject(USER_LANG);
  }

  public getMenu(): MenuItem[] {
    if (!Util.isNullOrUndefined(this.localStorageService.get(USER_LP3K))) {
      let user: UserInterface = JSON.parse(this.localStorageService.get(USER_LP3K).toString());
      this.currentUser.menu = user.menu;
      return this.currentUser.menu;
    }
  }

  public setModules(modules: Module[]): void {
    this.currentUser.modules = modules;
    this.localStorageService.setObject(USER_LP3K, this.currentUser);
  }


  public getCurrentUser(): UserInterface {
    if (this.currentUser && this.currentUser.hasOwnProperty('token')) {
      return this.currentUser;
    } else {
      return this.currentUser = this.localStorageService.getObject(USER_LP3K);
    }
  }

  public clear(): void {
     this.localStorageService.removeAll();
  }

  public userLogin(preference: Preference): void {
    this.currentUser.preference = preference;
    this.setCurrentUser(this.currentUser);
    this.userConnectedEvtEmitter.emit(true);
  }

  public refreshUser(user: UserInterface): void {
    this.currentUser.accessToken = user.accessToken;
    this.currentUser.token = user.accessToken;
    this.currentUser.refreshToken = user.refreshToken;
    this.localStorageService.setObject(USER_LP3K, this.currentUser);
  }

  public userLogout(): void {
    // this.repositoryProvider.clearCache();
    // this.alocproProvider.clearCache();
    // this.zoomService.clearCache();
    // this.bulkService.clearCache();
    this.currentUser = undefined;
    this.userConnectedEvtEmitter.emit(false);
    this.clear();
  }

  public setpreference(key: string, value: string): void {
    this.currentUser.preference[key] = value;
    this.setCurrentUser(this.currentUser);
  }

  public addUserEvent(eventType: String, route: String, title: String = '', description: String = '', icon: String = '', data: any): any {
    let userEvent: any = this.localStorageService.getObject(USER_EVENTS);
    userEvent = userEvent.slice(userEvent.length - 5, userEvent.length - 1)
    const newEvent: any = {
      'type': eventType,
      'route': route,
      'title': title,
      'description': description,
      'icon': icon,
      'data': data
    };
    if (userEvent.length === 0 ||
      (userEvent[userEvent.length - 1]['type'] !== newEvent['type'] || userEvent[userEvent.length - 1]['route'] !== newEvent['route'])) {
      userEvent.push(newEvent);
      this.localStorageService.setObject(USER_EVENTS, userEvent);
      return userEvent.length - 1;
    } else {
      if (userEvent.length === 0) {
        return false;
      } else {
        return userEvent.length - 1;
      }
    }
  }

  public modifyUserEventType(index: number, eventType: String, title: String = '',  data?: any): void {
    let userEvent: any = this.localStorageService.getObject(USER_EVENTS);
    userEvent[index]['type'] = eventType;
    userEvent[index]['title'] = title;
    if (data) {
      userEvent[index]['data'] = data;
      this.localStorageService.setObject(USER_EVENTS, userEvent);
    }
  }

  public getUserEvents(): Array<any> {
    let temp: Array<any> = this.localStorageService.getObject(USER_EVENTS);
    if (!Util.isNullOrUndefined(temp)) {
      return temp.reverse();
    } else {
      return [];
    }
    // return this.localStorageService.getObject(USER_EVENTS);
  }

  public isAdmin(): boolean {
    return (!Util.isNullOrUndefined(this.getCurrentUser().level) && this.getCurrentUser().level === '0');
  }

  public hasRefreshToken(): boolean {
    return (!Util.isNullOrUndefined(this.getCurrentUser()) && !Util.isNullOrUndefined(this.getCurrentUser().refreshToken));
  }

  private cleanUserMenu(menus: MenuItem[]): MenuItem[] {
    let outbarMenu: MenuItem[] = [];
    if (menus) {
      for (let i: number = 0; i < menus.length; i = i + 1) {
        if ( menus && menus.length > 0 && menus[i].isOutbar && menus[i].isOutbar !== null && menus[i].isOutbar !== undefined) {
            outbarMenu.push(menus[i]);
        }
      }
    }
    return outbarMenu;
  }
}
