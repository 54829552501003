import { Component} from '@angular/core';

@Component({
  selector: 'lp-button-search',
  templateUrl: './lp-button-search.component.html'
})
export class LpButtonSearchComponent {
  constructor() { }

}
