import { LpMeta, propertyMeta } from './meta';
import { Type } from 'class-transformer';
import { FtMeta } from './ft-meta';
import { LpDamageValue } from './damage-value';

export class LpLinkDamageValue extends LpMeta {
    @Type(() => FtMeta)
    public damageType: FtMeta = new FtMeta();
    @propertyMeta('number')
    public impactSeverityLevel: Number;
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('number')
    public impactASeverityLevel: Number;
    @propertyMeta('string')
    public wordingA: String;
    @propertyMeta('string')
    public expertCode: String;
    @propertyMeta('string')
    public wordingExpert: String;
    @propertyMeta('string')
    public counterExpertCode: String;
    @propertyMeta('string')
    public counterExpertWording: String;
    @propertyMeta('number')
    public amountExpertOpnionValue: Number;
    @propertyMeta('number')
    public invoicedPercentage: Number;
    @propertyMeta('boolean')
    public hasIndicatorCustomerRecognized: Boolean;
    @propertyMeta('boolean')
    public hasInvoicedIndicator: Boolean;
    @propertyMeta('boolean')
    public hasInsuredIndicator: Boolean;
    @propertyMeta('number')
    public amountVADInvoiced: Number;
    @propertyMeta('string')
    public referenceLinkDamage: String;
    @propertyMeta('boolean')
    public hasVOIndicator: Boolean;
    @propertyMeta('boolean')
    public hasApplicationDepreciationDiscount: Boolean;
    @propertyMeta('boolean')
    public hasApplicationDeductible: Boolean;
    @Type(() => LpDamageValue)
    public damageValue: LpDamageValue = new LpDamageValue();
}
