import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, Input, OnInit, OnDestroy, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { InputFieldComponent } from '../input-field.component';
import { ChangeService } from 'app/services/change.service';
import { EvalService, MAIN, FOCUS_IN_EVENT } from 'app/services/eval.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Util } from 'app/statics/utils';
import { UiSyncService } from 'app/services/ui-sync.service';
import { FormStackService } from 'app/services/form-stack.service';
import { FormatService } from 'app/services/format.service';
import { ApplicationItem } from 'app/models/application-item';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { ConfigService } from 'app/services/config.service';

@Component({
  selector: 'lp-number',
  templateUrl: './number.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberComponent),
      multi: true
    }
  ]
})
export class NumberComponent extends InputFieldComponent implements OnInit, OnDestroy, AfterContentChecked {

  @Input() public step: Number;

  @Input() public min: Number = 0;

  @Input() public max: Number;

  @Input() public id: string;

  @Input() public style: string;

  @Input() public integer: Boolean = false;

  protected numberType: String = "number";

  public valueShowed: String = '';

  public invalid: Boolean = false;

  public subscriptionLanguage: Subscription;

  constructor(protected changeService: ChangeService, protected evalService: EvalService,
    protected formMetadataProvider: FormMetadataProvider, protected translateService: TranslateService,
    public formStackService: FormStackService, protected uiSyncService: UiSyncService,
    protected formatService: FormatService,
    protected metaFactoryService: MetaFactoryService,
    protected configService: ConfigService,
    protected changeDetectorRef: ChangeDetectorRef) {
        super(changeService, evalService, formStackService, formMetadataProvider, uiSyncService, metaFactoryService, configService);
    }

  public ngOnInit(): void {
    this.typeBinding = 'number';
    super.ngOnInit();
    this.subscriptionLanguage = this.translateService.onLangChange.subscribe(() => {
      this.valueShowed = this.formatService.formatNumber(this.displayValue, this.numberType);
    });
  }

  public ngAfterContentChecked(): void {
    if (!Util.isNullOrUndefined(this.untouchedValue) && Util.isNullOrUndefined(this.displayValue)) {
      this.transform(this.untouchedValue);
    }
  }

  public ngOnDestroy(): void {
    if (!Util.isNullOrUndefined(this.subscriptionLanguage)) {
      this.subscriptionLanguage.unsubscribe();
    }
  }


  public onKeypressEvent(event: any, id: string): void {
    setTimeout(() => {
      if( isFinite(event.key)  && event.keyCode !== 32 && event.keyCode !== 'KeyM' && event.target.value ) {
        let baseValue = event.target.value;
        if ( baseValue.includes(",") && typeof event.target.value === "string") {
          baseValue = Number(event.target.value.replace(',', '.'));
        }
        this.uiSyncService.evalFunctionForGrid.emit([event, id, Number(baseValue)]);
      }
    }, 500 )
  }

  
  public keyDown (event: any, id: string){
    if (event.key === 'Enter') {
      this.pushChange(event)
    }
    if(event.code === "Backspace" && Number(event.target.value.replace(',', '.')) > 0){
      let baseValue = event.target.value;
      baseValue = baseValue.substring(0, event.target.value.length-1);
      baseValue = Number(baseValue.replace(',', '.'))
      this.uiSyncService.evalFunctionForGrid.emit([event, id, Number(baseValue)]);
      event.target.value = baseValue;
    } 
    if(this.integer && !isFinite(event.key)){
      return false;
    } 
  }

  public async focus(event: any): Promise<void> {
    this.invalid = false;
    let applicationItem: ApplicationItem = this.formStackService.currentApplicationItem;
    let data: any = this.formStackService.currentData;
    await this.evalService.executeContrainte(FOCUS_IN_EVENT, MAIN
      , applicationItem.ecrId.toString(), this.name.toString()
      , data, this.formStackService.currentFields, this.previousValue);

    let d: string;
    if (!Util.isNullOrUndefined(this.displayValue)) {
      d = this.displayValue;
    } else {
      d = '';
    }

    if (this.formatService.DECIMAL_SEPARATOR.has(this.translateService.getDefaultLang().toUpperCase())) {
      this.valueShowed = d.toString().replace('.', ',');
    } else {
      this.valueShowed = d;
    }
    this.setPreviousValue(d);
  }

  public async pushChange(event?: any): Promise<void> {     
    super.pushChange();   
    let value: number = null;
    if (event && event. srcElement && event.srcElement.value !== '') {
      value = event.srcElement.value;
      if (value.toString().includes(',')) {
        value = event.srcElement.value.replace(',', '.');
      }
    }
    this.setDisplayValue(value);
    //this.putOnFocusOut();      
    this.focusOutCustomCode();
  }

  public setDisplayValue(value: number, notChangeDisplay?: Boolean): void {
    this.untouchedValue = value;
    if (isNaN(value)) {
      return;
    }
    if (!Util.isNullOrUndefined(value)) {      
        this.transform(value, notChangeDisplay);
    } else {
      this.valueShowed = null;
      this.displayValue = null;
    }
    this.propagateChange(value);
    this.uiSyncService.emitAvailableEvalForJsonInputField();
  }

  public numberOnly(event): boolean { 
    const charCode = (event.which) ? event.which : event.keyCode; 
    return (charCode > 31 && (charCode < 48 || charCode > 57));
  }

  protected transform(value: number, notChangeDisplay?: Boolean): void {
    if(this.integer && !Util.isNullOrUndefined(value) && value.toString().indexOf('.') > -1){
      value = Math.round(value);
    }
    if (this.integer && value !== null && value !== undefined) {
      if (value.toString().includes('.')) {
        this.invalid = true;
      } else {
        this.invalid = false;
      }
      /*let temp: string[] = value.toString().split('.');
      value = Number(temp[0]);      */
    } 
    if (!Util.isNullOrUndefined(value)) {
      if (!notChangeDisplay) {
        this.valueShowed = this.formatService.formatNumber(value, this.numberType);
      }
      this.displayValue = Number(value);
    } else {
      this.valueShowed = '';
      this.displayValue = null;
    }
    this.changeDetectorRef.detectChanges();
  }

  /* public numberOnly(event): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      return !(this.integer && charCode > 31 && (charCode < 48 || charCode > 57))
  } */

  public getPattern(): String {
    return this.integer ? "[0-9\\s]*" : "[0-9,.\\s]*";
  }

  public getTitle(): String {
    return this.integer ? this.translateService.instant('input.integer'): null;
  }
}
