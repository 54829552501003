import { Injectable } from '@angular/core';
import { LpShortTermContract } from 'app/meta/short-term-contract';
import { PaginatedData } from 'app/models/paginated-data';
import { MulitProductsProvider } from 'app/providers/multi-products.provider';
import { LpModalChangeComponent } from 'app/ui/lp-modal/lp-modal-change/lp-modal-change.component';
import { ChangeService } from './change.service';
import { FormStackService } from './form-stack.service';
import { ModalService } from './modal.service';

@Injectable({
    providedIn: 'root'
  })
export class MultiProductsService {

    public list: PaginatedData = {count: 0, body: [], _readonly: false};

    constructor(
        private multiProductsProvider: MulitProductsProvider,
        private formStackService: FormStackService,
        private modalService: ModalService,
        private changeService: ChangeService
        ) { }

    public async getShortTermContractsList(groupingCode: string): Promise<PaginatedData> {
        let data: PaginatedData = await this.multiProductsProvider.getList(groupingCode);
        return data;
    }

    public async getShortTermContractsById(id: string): Promise<PaginatedData> {
        let data: PaginatedData = await this.multiProductsProvider.getId(id);
        return data;
    }

    public async postShortTermContractForMultiProducts(groupingCode: string, stc: LpShortTermContract): Promise<string> {
        let newKy: string = await this.multiProductsProvider.post(groupingCode, stc);
        return newKy;
    } 

    public assignDatas(oldFormStack: FormStackService): void {
        this.formStackService.currentData['customer'] = oldFormStack.currentData['customer'];
        this.formStackService.currentData['startDate'] = oldFormStack.currentData['startDate'];
        this.formStackService.currentData['plannedReturnDate'] = oldFormStack.currentData['plannedReturnDate'];
        let categ = this.formStackService.currentFields.get('category')
        categ.readonly = false;
        this.formStackService.currentFields.set('category', categ)
        let equipment = this.formStackService.currentFields.get('movement.equipment')
        equipment.readonly = false;
        this.formStackService.currentFields.set('movement.equipment', equipment)
      }
    
    public async modaleChange(): Promise<boolean> {
        const result: boolean = await this.modalService.modalPromise(LpModalChangeComponent, {​
            backdropClass:'alertBackdropClass',
            id : 'modalWithHeader'
        }​);
        return result;
    }

    public async detectChange(): Promise<boolean> {
        if (this.changeService.getIsLastChanged() && this.changeService.getIsLastChanged() === true) {
            const result: boolean = await this.modaleChange();
            if (result && result === true) {
            this.changeService.clearAndReinit();
            }
            return result;
        } else {
            return true;
        }
    }

}
