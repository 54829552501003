import { Component, Inject } from '@angular/core';
import { ModalService } from 'app/services/modal.service';
import { FormStackService } from 'app/services/form-stack.service';
import { TranslateService } from '@ngx-translate/core';
import { LpModalComponent } from '../lp-modal.component';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { UserService } from 'app/services/user.service';
import { UiSyncService } from 'app/services/ui-sync.service';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'lp-modal-promise-zoom',
  templateUrl: './lp-modal-zoom.component.html'
})
export class LpModalPromiseZoomComponent extends LpModalComponent {

  public showDetail: boolean = false;

  constructor(
    public formstackService: FormStackService,
    public translateService: TranslateService,
    public dialogRef: MatDialogRef<LpModalComponent>,
    public modalService: ModalService,
    public userService: UserService,
    public uiSyncService: UiSyncService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    super(dialogRef, data);
    router.events.pipe(
      filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.close();
      });
  }
  public afterZoomInit() {
    // temps de l'animation d'ouverture de la pop-up
    setTimeout(() => {
      this.uiSyncService.displayZoomEvt(this.data.param, this.data.guid);
    }, 100);
       
  }
}
