<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm"
    titleTranslationKey="pricingMethods.pricingMethods"></lp-top-banner>
<div class="container-fluid container-fluid-2-cols"
    *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)"
    (dragover)="dragover($event)" (drop)="drop($event)">
    <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0"
        [style.left]="dragLeft ? dragLeft+'px' : ''" (dblclick)="reInitCols()"></div>
    <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>
    <div class="container p-0">
        <div class="container-2-cols left" #containerCols>
            <div class="card-box col-md-6 label-2-colonnes" #col1>
                <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
                    <lp-common-tools
                        *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject"
                        [ky]="data.id" [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="deleteButton"
                        (showPrintOutput)="changeShowZoomPrint($event)" [showPrintInput]="showZoomPrint">
                    </lp-common-tools>
                    <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
                        <lp-ui-accordion class="row" [menu]=true>

                            <lp-ui-accordion-group heading="general.other.general" [open]=true key="general">
                                <div class="container">
                                    <div class="row mb-4">
                                        <div class="form-group col-xl-6">
                                            <lp-zoom-field id="familyMethod"
                                                class="{{arrayClassesElements.get('defaultInput')}}" label="pricingMethods.familyMethod"
                                                zoomId='familyMethod' className="zoomField" cssClass="form-control"
                                                [(ngModel)]="data.familyMethod" name="familyMethod">
                                            </lp-zoom-field>
                                        </div>
                                        <div class="form-group col-xl-6">
                                            <lp-zoom-field id="group" label="pricingMethods.calculationMode"
                                                class="{{arrayClassesElements.get('defaultInput')}}" zoomId='calculationMode'
                                                className="zoomField" cssClass="form-control" [(ngModel)]="data.calculationMode"
                                                name="calculationMode">
                                            </lp-zoom-field>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="form-group col-xl-6">
                                            <lp-zoom-field id="group" label="pricingMethods.group"
                                                class="{{arrayClassesElements.get('defaultInput')}}" zoomId='group'
                                                className="zoomField" cssClass="form-control" [(ngModel)]="data.group"
                                                name="group"></lp-zoom-field>
                                        <lp-text-field id="baseWording" className="textField" label="pricingMethods.baseWording"
                                            class="{{arrayClassesElements.get('defaultInput')}}"
                                            cssClass="form-control" name="baseWording" [(ngModel)]="data.baseWording"
                                            type="text"></lp-text-field>
                                        </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="form-group col-xl-6">
                                            <lp-zoom-field id="company" label="pricingMethods.company"
                                                class="{{arrayClassesElements.get('defaultInput')}}" zoomId='company'
                                                className="zoomField" cssClass="form-control" [(ngModel)]="data.company"
                                                name="company">
                                            </lp-zoom-field>
                                        <lp-text-field id="baseWording" className="textField" label="pricingMethods.code"
                                            class="{{arrayClassesElements.get('defaultInput')}}"
                                            cssClass="form-control" name="code" [(ngModel)]="data.code"
                                            type="text"></lp-text-field>
                                        </div>
                                    </div>
                                </div>
                            </lp-ui-accordion-group>

                            <lp-ui-accordion-group heading="pricingMethods.package" [open]=true key="package">
                                <div class="container">
                                    <div class="row mb-4">
                                        <div class="form-group col-xl-6">
                                            <lp-number id="dayPlan" cssClass="form-control" label="pricingMethods.dayPlan"
                                                class="{{arrayClassesElements.get('defaultInput')}}" name="dayPlan"
                                                [(ngModel)]="data.dayPlan" minlength="1"></lp-number>
                                        </div>
                                        <div class="form-group col-xl-6">
                                            <lp-number id="minimumDays" cssClass="form-control" label="pricingMethods.minimumDays"
                                                class="{{arrayClassesElements.get('defaultInput')}}" name="minimumDays"
                                                [(ngModel)]="data.minimumDays" minlength="1"></lp-number>
                                        </div>
                                    </div>

                                    <div class="row mb-4">
                                        <div class="form-group col-xl-6">
                                            <lp-number id="kilometerPlan" cssClass="form-control" label="pricingMethods.kilometerPlan"
                                                class="{{arrayClassesElements.get('defaultInput')}}"
                                                name="kilometerPlan" [(ngModel)]="data.kilometerPlan" minlength="1">
                                            </lp-number>
                                        </div>
                                        <div class="form-group col-xl-6">
                                            <lp-number id="maximumDays" cssClass="form-control" label="pricingMethods.maximumDays"
                                                class="{{arrayClassesElements.get('defaultInput')}}" name="maximumDays"
                                                [(ngModel)]="data.maximumDays" minlength="1"></lp-number>
                                        </div>
                                    </div>

                                    <div class="row mb-4">
                                        <div class="form-group col-xl-6">
                                            <lp-number id="extraDayPlan" cssClass="form-control" label="pricingMethods.extraDayPlan"
                                                class="{{arrayClassesElements.get('defaultInput')}}" name="extraDayPlan"
                                                [(ngModel)]="data.extraDayPlan" minlength="1"></lp-number>
                                        </div>
                                        <div class="form-group col-xl-6">
                                            <lp-number id="minimumKilometers" cssClass="form-control"
                                                class="{{arrayClassesElements.get('defaultInput')}}" label="pricingMethods.minimumKilometers"
                                                name="minimumKilometers" [(ngModel)]="data.minimumKilometers"
                                                minlength="1"></lp-number>
                                        </div>
                                    </div>

                                    <div class="row mb-4">
                                        <div class="form-group col-xl-6">
                                            <lp-number id="hourPlan" cssClass="form-control" label="pricingMethods.hourPlan"
                                                class="{{arrayClassesElements.get('defaultInput')}}" name="hourPlan"
                                                [(ngModel)]="data.hourPlan" minlength="1"></lp-number>
                                        </div>
                                        <div class="form-group col-xl-6">
                                            <lp-number id="maximumKilometers" cssClass="form-control" label="pricingMethods.maximumKilometers"
                                                class="{{arrayClassesElements.get('defaultInput')}}"
                                                name="maximumKilometers" [(ngModel)]="data.maximumKilometers"
                                                minlength="1"></lp-number>
                                        </div>
                                    </div>

                                    <div class="row mb-4">
                                        <div class="form-group col-xl-6">
                                            <lp-number id="freeMinutePlan" cssClass="form-control" label="pricingMethods.freeMinutePlan"
                                                class="{{arrayClassesElements.get('defaultInput')}}"
                                                name="freeMinutePlan" [(ngModel)]="data.freeMinutePlan" minlength="1">
                                            </lp-number>
                                        </div>
                                    </div>
                                </div>
                            </lp-ui-accordion-group>

                            <lp-ui-accordion-group heading="pricingMethods.day" [open]=true key="day">
                                <div class="container">
                                    <div class="row mb-4">
                                        <div class="form-group col-xl-6">
                                            <lp-boolean-switch-box name="daysExclusion" switchName="daysExclusion" id="daysExclusion"
                                                [(ngModel)]="data.daysExclusion" label="pricingMethods.daysExclusion"></lp-boolean-switch-box>
                                        </div>
                                        <div class="form-group col-xl-6">
                                            <lp-text-field id="weekExclusion" className="textField" label="pricingMethods.weekExclusion"
                                                class="{{arrayClassesElements.get('defaultInput')}}"
                                                cssClass="form-control" name="weekExclusion" [(ngModel)]="data.weekExclusion"
                                                type="text"></lp-text-field>
                                        </div>
                                    </div>

                                    <div class="row mb-4">
                                        <div class="form-group col-xl-6">
                                            <lp-number id="departureDay" cssClass="form-control" label="pricingMethods.departureDay"
                                                class="{{arrayClassesElements.get('defaultInput')}}"
                                                name="departureDay" [(ngModel)]="data.departureDay" minlength="1">
                                            </lp-number>
                                        </div>
                                        <div class="form-group col-xl-6">
                                            <lp-time id="pricingMethods.departureHour" cssClass="form-control" label="pricingMethods.departureHour"
                                                class="{{arrayClassesElements.get('defaultInput')}}" name="pricingMethods.departureHour"
                                                [(ngModel)]="data.departureHour"></lp-time>
                                        </div>
                                    </div>

                                    <div class="row mb-4">
                                        <div class="form-group col-xl-6">
                                            <lp-number id="returnDay" cssClass="form-control" label="pricingMethods.returnDay"
                                                class="{{arrayClassesElements.get('defaultInput')}}"
                                                name="returnDay" [(ngModel)]="data.returnDay" minlength="1">
                                            </lp-number>
                                        </div>
                                        <div class="form-group col-xl-6">
                                            <lp-time id="pricingMethods.returnHour" cssClass="form-control" label="pricingMethods.returnHour"
                                                class="{{arrayClassesElements.get('defaultInput')}}" name="pricingMethods.returnHour"
                                                [(ngModel)]="data.returnHour"></lp-time>
                                        </div>
                                    </div>

                                    <div class="row mb-4">
                                        <div class="form-group col-xl-6">
                                            <lp-text-field id="departureWeek" className="textField" label="pricingMethods.departureWeek"
                                                class="{{arrayClassesElements.get('defaultInput')}}"
                                                cssClass="form-control" name="departureWeek" [(ngModel)]="data.departureWeek"
                                                type="text"></lp-text-field>
                                        </div>
                                        <div class="form-group col-xl-6">
                                            <lp-text-field id="weekArrival" className="textField" label="pricingMethods.weekArrival"
                                                class="{{arrayClassesElements.get('defaultInput')}}"
                                                cssClass="form-control" name="weekArrival" [(ngModel)]="data.weekArrival"
                                                type="text"></lp-text-field>
                                        </div>
                                    </div>
                                </div>
                            </lp-ui-accordion-group>

                            <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
                                <div>
                                    <pre>{{ data | json }}</pre>
                                </div>
                            </lp-ui-accordion-group>
                        </lp-ui-accordion>
                    </div>
                    <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm">
                    </lp-dynamic-main-form>
                </form>
            </div>
            <div class="col-md-6 card-box" #col2>
                <lp-right-col></lp-right-col>
            </div>
        </div>
    </div>

    <div class="clearfix"></div>
</div>
<div class="clearfix"></div>