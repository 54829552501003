import { LpMeta, propertyMeta } from './meta';
import { LpLinkDamageCircustanceCode } from './link-damage-circumstance-code';
import { Type } from 'class-transformer';

export class LpCircumstanceLink extends LpMeta {
    @Type(() => LpLinkDamageCircustanceCode)
    public circumstanceCode: LpLinkDamageCircustanceCode = new LpLinkDamageCircustanceCode();
    @propertyMeta('boolean')
    public isADriverChecked: boolean;
    @propertyMeta('boolean')
    public isBDriverChecked: boolean;
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('string')
    public wordingA: String;
}
