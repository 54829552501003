import { Component, OnInit, Input, forwardRef, ChangeDetectorRef } from '@angular/core';
import { ChoiceFieldComponent } from '../choice-field.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModalService } from '../../../../../../../../services/modal.service';
import { ChangeService } from 'app/services/change.service';
import { EvalService } from 'app/services/eval.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { UiSyncService } from 'app/services/ui-sync.service';
import { FormStackService } from 'app/services/form-stack.service';
import { TranslateService } from '@ngx-translate/core';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { ConfigService } from 'app/services/config.service';

@Component({
  selector: 'lp-active',
  templateUrl: './active.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActiveComponent),
      multi: true
    }
  ]
})
export class ActiveComponent extends ChoiceFieldComponent implements OnInit {

  /**Le paramètre Name permet de donner un nom personnalisé au composant.
   *
   */
  @Input() public name: string;
  /* Passer à false pour utiliser l'envoi d'une requete au backend - utilisé dans le common tools*/
  @Input() public binding: boolean = true;

  constructor(protected modalService: ModalService,
              protected uiSyncService: UiSyncService,
              protected changeService: ChangeService,
              protected evalService: EvalService,
              protected formMetadataProvider: FormMetadataProvider,
              public formStackService: FormStackService,
              private translateService: TranslateService,
              public metaFactoryService: MetaFactoryService,
              protected configService: ConfigService,
              private changeDetectorRef: ChangeDetectorRef) {
    super(changeService, evalService, formStackService, formMetadataProvider, uiSyncService, metaFactoryService, configService);

  }

  public ngOnInit(): void {
    this.typeBinding = 'boolean';
    super.ngOnInit();
  }

  public async pushChange(event?: any): Promise<void> {
    if(!this.binding) {
      try {
        super.pushChange();
        await this.formStackService.changeActivate(this.displayValue);
        this.setDisplayValue(this.displayValue);
      } catch (e) {
        this.setDisplayValue(event.target.checked);
        this.modalService.error(this.translateService.instant('general.modalService.updateFailed'));
      }
    } else {
      this.uiSyncService.changeDetector.emit();
      if (event !== undefined && event.type == "click") {
        setTimeout(() => {
          super.pushChange();
          this.changeDetectorRef.detectChanges();
        }, 0)
      }
    }
  }

  protected transform(value: any, noSetDisplayValue?: boolean): void {
    super.transform(value, noSetDisplayValue);
    this.changeDetectorRef.detectChanges();
  }

}
