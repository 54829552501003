import { Injectable } from "@angular/core";
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { AlocproProvider } from "app/providers/alocpro.provider";
import { BehaviorSubject } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";


@Injectable()
export class MessagingService{
    currentMessage = new BehaviorSubject(null);

    constructor(private angularFirebaseMessaging: AngularFireMessaging,
                private alocproProvider: AlocproProvider){
    }

    requestPerm(){
        return this.angularFirebaseMessaging.requestToken.pipe(
            switchMap(
                (value: string) => {
                    let body: any = {'token_firebase': value};
                    return this.alocproProvider.post('/technical/edis/token-firebase', body, null, [{ prop: 'noerroralert', value: 'true' }])
                }
            ),
            catchError(
                err => {
                    console.error("No Permission ", err);
                    return err;
                }
            )
        );
    }
    
    receiveMessage(){

    }
}