<div class="p-0 m-0" cdkDropList (cdkDropListDropped)="dropEvent($event)">
  <div *ngFor="let col of list; let i = index" class="ms-2 me-2 p-0" role="menuitem" cdkDrag>
    <div class="row cdk-drop-placeholder ps-2 ms-2 me-2" *cdkDragPlaceholder>
      <span *ngIf="!isBulk">{{ prefix + col.name |translate}}</span>
      <span *ngIf="isBulk">{{ col.wording |translate}}</span>
    </div>
    <div cdkDragHandle title="{{'scheduler.moveItem' | translate}}"></div>
    <label class="w-100 cursor-pointer pt-0 pb-0 ps-0 pe-5 m-0">
      <div class="input-group mb-0 pull-right ms-4 w-auto mb-2">
        <mat-slide-toggle id="{{'toggle-'+i}}" name="{{'toggle-'+i}}" [(ngModel)]="col[prop]" (ngModelChange)="saveDetailConfig()"></mat-slide-toggle>
      </div>    
      <span *ngIf="!isBulk" class="d-block mt-1 pt-1">{{ prefix + col.name |translate}}</span>      
      <span *ngIf="isBulk" class="d-block mt-1 pt-1">{{col.wording |translate}}</span>
            
    </label>
  </div>
  <button *ngIf="(formstackService && formstackService.currentApplicationItemDetail && formstackService.currentApplicationItemDetail.isCustom) || isNotADetailButAModal === true" 
    type="button" class="btn btn-danger btn-sm mb-2 mt-2 pull-right" (click)="reinstateDetailConfig()">{{'general.reinstate' | translate}}</button>
  <div class="clearfix"></div>
</div>