<div class="modal-header">
  <h4 class="modal-title pull-left">{{'preferences.showVisualSettings' | translate}}</h4>

  <div class="pull-right">
    <lp-button-cancel (action)="close()"></lp-button-cancel>
  </div>
</div>
<div class="modal-body">
  <div class="card-box" #col1>
    <form #form="ngForm" ngNativeValidate>
      <div class="container-fluid">
        <lp-ui-accordion class="row" [menu]=true>
          <lp-ui-accordion-group heading="{{'visualSettings.visualSettings' | translate}}" [open]=true key="general" >
            <div class="row">
              <div class="col-3">
              <lp-label-field text="visualSettings.displaySearchBar" for="btn-showWidget"></lp-label-field>
              <lp-boolean-switch-box [ignoreChange]="true" [readonly]="false" name="visualSettings.displaySearchBar" switchName="displaySearchBar" id="visualSettings.displaySearchBar" [(ngModel)]="formstackService.VisualSettings.displaySearchBar"></lp-boolean-switch-box>
              </div>
              <div class="col-3">
              <lp-label-field text="visualSettings.displayMenu" for="btn-showWidget"></lp-label-field>
              <lp-boolean-switch-box [ignoreChange]="true" [readonly]="false" name="visualSettings.displayMenu" switchName="displayMenu" id="visualSettings.displayMenu" [(ngModel)]="formstackService.VisualSettings.displayMenu"></lp-boolean-switch-box>
              </div>
              <div class="col-3">
              <lp-label-field text="visualSettings.displayLogo" for="btn-showWidget"></lp-label-field>
              <lp-boolean-switch-box [ignoreChange]="true" [readonly]="false" name="visualSettings.displayLogo" switchName="displayLogo" id="visualSettings.displayLogo" [(ngModel)]="formstackService.VisualSettings.displayLogo"></lp-boolean-switch-box>
              </div>
              <div class="col-12 mb-0">
                <button class="transparent-btn pull-right" (click)="save()">
                  <i class="vega-diskette"></i>
                </button>
              </div>
            </div>
          </lp-ui-accordion-group>
        </lp-ui-accordion>
      </div>
    </form>
  </div>
</div>