import { LpFamilyMethod } from './family-method';
import { LpMeta, propertyMeta } from './meta';
import { Type } from 'class-transformer';
import { LpGroup } from './group';
import { LpCalculationMode } from './calculation-mode';

export class LpPricingMethod extends LpMeta {
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('string')
    public baseWording: String;
    @propertyMeta('string')
    public code: String;
    @propertyMeta('boolean')
    public isActive: Boolean;
    @propertyMeta('number')
    public index: Number;
    @Type(() => LpFamilyMethod)
    public familyMethod: LpFamilyMethod = new LpFamilyMethod();
    @propertyMeta('number')
    public dayPlan: Number;
    @propertyMeta('number')
    public kilometerPlan: Number;
    @propertyMeta('number')
    public extraDayPlan: Number;
    @propertyMeta('number')
    public minimumDays: Number;
    @propertyMeta('number')
    public maximumDays: Number;
    @propertyMeta('number')
    public minimumKilometers: Number;
    @propertyMeta('number')
    public maximumKilometers: Number;
    @propertyMeta('boolean')
    public daysExclusion: Boolean;
    @propertyMeta('string')
    public weekExclusion: String;
    @propertyMeta('string')
    public departureWeek: String;
    @propertyMeta('number')
    public departureDay: Number;
    @propertyMeta('string')
    public departureHour: String;
    @propertyMeta('number')
    public returnDay: Number;
    @propertyMeta('string')
    public returnHour: String;
    @Type(() => LpGroup)
    public group: LpGroup = new LpGroup();
    @propertyMeta('number')
    public freeMinutePlan: Number;
    @propertyMeta('number')
    public hourPlan: Number;
    @propertyMeta('string')
    public weekArrival: String;
    @Type(() => LpCalculationMode)
    public calculationMode: LpCalculationMode = new LpCalculationMode();
}
