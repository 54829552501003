import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputFieldComponent } from '../input-field.component';
import { MAIN, FOCUS_IN_EVENT } from 'app/services/eval.service';
import { ApplicationItem } from 'app/models/application-item';
import { Util } from 'app/statics/utils';

@Component({
  selector: 'lp-editor-field',
  templateUrl: './editor-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorFieldComponent),
      multi: true
    }
  ]
})
export class EditorFieldComponent extends InputFieldComponent implements OnInit {

  /**Le paramètre Name permet de donner un nom personnalisé au composant.
   *
   */
  @Input() public name: string;

  /**Le paramètre InputText sera le nom du champ dans lequel les données seront émises.
   */
  @Input() public inputText: string;

  /**Le paramètre Rows permet de régler la hauteur.
   */
  @Input() public rows: string;

  /**Le paramètre Mde permet de régler le language utilisé (voir doc.md)
   */
  @Input() public mode: string;

  /**Le paramètre Language permet de régler le thème utilisé (voir doc.md)
   */
  @Input() public theme: string;

  /**Le paramètre Type permet de configurer le type des balises HTML qui viennent constituer le composant.
   */
  @Input() public type: string = 'text';

  /**Le paramètre Title permet de configuré un titre au composant.
   */
  @Input() public title: string;

  /**Le paramètre DefaultValue configure un texte par défaut.
   *
   */
  @Input() public defaultValue: string;

  /**Le output TextFieldEmitter va permettre l'exécution de la fonction qui lui est associée.
   * La fonction associée est déclarée dans le template où le composant est utilisé.
   */
  @Output() public textFieldEmitter: EventEmitter<any> = new EventEmitter();

  public isFixed: Boolean = false;

  public ngOnInit(): void {
    super.ngOnInit();
    this.inputText = this.name;
  }

  public async focus(event: any): Promise<void> {
    let applicationItem: ApplicationItem = this.formStackService.currentApplicationItem;
    let data: any = this.formStackService.currentData;
    await this.evalService.executeContrainte(FOCUS_IN_EVENT, MAIN
      , applicationItem.ecrId.toString(), this.name.toString()
      , data, this.formStackService.currentFields, this.previousValue);
    let d: string = event.target.value
    this.setPreviousValue(d)
  }

  public getOptions() {
    return {
      lineNumbers: true,
      theme: (!Util.isNullOrUndefined(this.theme) ? this.theme : 'neat'),
      mode: this.mode
    }
  }
}
