import { Injectable } from '@angular/core';
import { WidgetProvider } from 'app/providers/widget.provider';
import { WidgetInterface, WidgetProvidersInterface, WidgetDataSerieItem
  , WidgetDataStacked, WidgetDataSerieItemGrouped, WidgetDataGrouped } from 'app/models/widget';
import { LpMeta } from 'app/meta/meta';
import { Util } from 'app/statics/utils';

@Injectable({
    providedIn: 'root'
  })
export class WidgetService {


    constructor(private widgetProvider: WidgetProvider) { }

    public async getWidgetConfiguration(widgetId: string): Promise<WidgetInterface> {
        return await this.widgetProvider.getWidgetConfiguration(widgetId)
    }

    public async getData(widgetConfig: WidgetInterface, screen: string): Promise<any> {
      let data: any = await this.widgetProvider.getData(widgetConfig, screen);
      return this.transform(data, widgetConfig);
    }

    public transform(data: any, widgetConfig: WidgetInterface): any {
        if (widgetConfig.type === 2 || widgetConfig.type === 3 || widgetConfig.type === 15) {
          return this.transformDataPieChartBarChart(data, widgetConfig)
        } else if (widgetConfig.type === 8 ) {
          return this.transformDataTable(data)
        } else if (widgetConfig.type === 9 || widgetConfig.type === 10) {
          return this.transformDataBarHorizontalStacked(data, widgetConfig);
        } else if (widgetConfig.type === 11 || widgetConfig.type === 12 || widgetConfig.type === 13 || widgetConfig.type === 14 ) {
          return this.transformDataBarHorizontalGrouped(data, widgetConfig);
        } else if (widgetConfig.type === 99) {
          return data;
        }

    }

    public transformDataTable(data: any): Array<LpMeta> {
      return data;
    }

    private transformDataBarHorizontalGrouped(data: any, widgetConfig: WidgetInterface): Array<any> {
      let finalData: Array<any> =  [];
      let item: string = null;
      let dataHorizontalStacked: WidgetDataGrouped = {
        'name': null,
        'series': []
      };
      let dataSeriesTab: Array<WidgetDataSerieItemGrouped> = new Array<WidgetDataSerieItemGrouped>();
        widgetConfig.settings.providers.forEach((p: WidgetProvidersInterface, indexProvider: number) => {
            data.map((dataRow: any, index: number) => {
              if (index === 0) {
                item = dataRow[widgetConfig.settings.fields[indexProvider]['name']];
              } else {
                if (item !== dataRow[widgetConfig.settings.fields[indexProvider]['name']]) {
                  finalData.push(dataHorizontalStacked);
                  dataSeriesTab = new Array<WidgetDataSerieItemGrouped>();
                  item = dataRow[widgetConfig.settings.fields[indexProvider]['name']]
                }
              }

              const dataSeries: WidgetDataSerieItemGrouped = {
                'name': (
                  widgetConfig.settings.fields.length > 1 &&
                  !Util.isNullOrUndefined(dataRow[widgetConfig.settings.fields[1]['name']])) ?
                  dataRow[widgetConfig.settings.fields[1]['name']] : 'X' ,
                'value': []
              };
              let value: Array<number> = [];
              value = dataRow[widgetConfig.settings.fields[indexProvider]['value']];
              dataSeries.value = value;

              dataSeriesTab.push(dataSeries);

              dataHorizontalStacked = {
                'name': dataRow[widgetConfig.settings.fields[indexProvider]['name']],
                'series': dataSeriesTab
              };

            });
            finalData.push(dataHorizontalStacked);
        });

        return finalData;
    }

    private transformDataBarHorizontalStacked(data: any, widgetConfig: WidgetInterface): Array<any> {
      let finalData: Array<any> =  [];
      let item: string = null;
      let dataHorizontalStacked: WidgetDataStacked = {
        'name': null,
        'series': []
      };
      let dataSeriesTab: Array<WidgetDataSerieItem> = new Array<WidgetDataSerieItem>();
        widgetConfig.settings.providers.forEach((p: WidgetProvidersInterface, indexProvider: number) => {
            data.map((dataRow: any, index: number) => {
              if (index === 0) {
                item = dataRow[widgetConfig.settings.fields[indexProvider]['name']];
              } else {
                if (item !== dataRow[widgetConfig.settings.fields[indexProvider]['name']]) {
                  finalData.push(dataHorizontalStacked);
                  dataSeriesTab = new Array<WidgetDataSerieItem>();
                  item = dataRow[widgetConfig.settings.fields[indexProvider]['name']]
                }
              }

              const dataSeries: WidgetDataSerieItem = {
                'name': (
                  widgetConfig.settings.fields.length > 1 &&
                  !Util.isNullOrUndefined(dataRow[widgetConfig.settings.fields[1]['name']])) ?
                  dataRow[widgetConfig.settings.fields[1]['name']] : 'X' ,
                'value': []
              };
              let value: Array<number> = [];
              value = dataRow[widgetConfig.settings.fields[indexProvider]['value']];
              dataSeries.value = value;

              dataSeriesTab.push(dataSeries);

              dataHorizontalStacked = {
                'name': dataRow[widgetConfig.settings.fields[indexProvider]['name']],
                'series': dataSeriesTab
              };

            });
            finalData.push(dataHorizontalStacked);
        });

        return finalData;
    }

    private transformDataPieChartBarChart(data: any, widgetConfig: WidgetInterface): Array<any> {
        let finalData: Array<any> =  [];
        widgetConfig.settings.providers.forEach((p: WidgetProvidersInterface, indexProvider: number) => {
            data.map((dataRow: any, index: number) => {
                const dataItemBarPie: WidgetDataSerieItem = {
                    'name': (dataRow[widgetConfig.settings.fields[indexProvider]['name']]) ?
                      dataRow[widgetConfig.settings.fields[indexProvider]['name']] : 'Sans valeur', // un des champs name d'un des Item est null
                    'value': dataRow[widgetConfig.settings.fields[indexProvider]['value']]
                  };
                /*  (this.datasWidget && this.datasWidget.clickFilters && this.datasWidget.clickFilters[indexProvider].value) ?
                    values = this.datasWidget.clickFilters[indexProvider].value : values = dataItemBarPie.name;
                  if (this.typeWidget === 2) {
                    values = item[this.datasWidget.clickFilters[indexProvider].value];
                  }
                  if (this.datasWidget && this.datasWidget.clickFilters) {
                    keyValues = dataItemBarPie.name;
                    keyOperator = dataItemBarPie.name;
                    operator = this.datasWidget.clickFilters[indexProvider].operator;
                    keyField = dataItemBarPie.name;
                    field = this.datasWidget.clickFilters[indexProvider].field;
                    keyLabel = dataItemBarPie.name;
                    label = this.datasWidget.clickFilters[indexProvider].label;
                  }

                  // dataItems[dataItems.length] = dataItemBarPie;
                  if (this.maxPopulation && this.maxPopulation !== undefined && this.maxPopulation.length !== 0) {
                    if (dataItems.length + 1 < this.maxPopulation) {
                      dataItems[dataItems.length] = dataItemBarPie;
                    } else {
                      if (index === value.length - 1) {
                        dataItems[dataItems.length] = dataItemBarPieTemp;
                      } else {
                        const tempValue = +dataItemBarPieTemp.value;
                        const pieValue = +dataItemBarPie.value;
                        dataItemBarPieTemp.value = tempValue + pieValue;
                      }
                    }
                  } else {
                    dataItems[dataItems.length] = dataItemBarPie;
                  }

                  if (index + 1 === value.length) { countProvider = countProvider + 1; }
                  if ((countProvider === this.providers.length) && (index + 1 === value.length)
                  ) {
                    if (this.maxPopulation && this.maxPopulation.length !== 0) {
                      this.indexes.items = dataItems;
                    } else {
                      if (dataItems.length >= value.length) {
                        this.indexes.items = dataItems;
                      }
                    }
                  };*/


                  finalData.push(dataItemBarPie);
            });
        });
        return finalData;
    }
}
