import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChoiceFieldComponent } from '../choice-field.component';

@Component({
  selector: 'lp-radio-box',
  templateUrl: './radio-box.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioBoxComponent),
      multi: true
    }
  ]
})
export class RadioBoxComponent extends ChoiceFieldComponent implements OnInit {
  /**Le paramètre Name permet de donner un nom personnalisé au composant.
   *
   */
  @Input() public name: string;

  /**Le paramètre radioName permet de donner un nom au  champ.
   *
   */
  @Input() public radioName: string;

  /**Le paramètre InputBox représente le nom de la structure présente dans notre back - end, dont on veut modifier/ajouter des champs.
   *
   */
  @Input() public inputBox: string;

  /**Le paramètre Checked est le booléen qui sera passé à TRUE, pour venir pré-cocher la checkbox.
   * Le booléen est utilisé dans la fonction checkedBox().
   */
  @Input() public checked: boolean;

  /**Le paramètre singleCol permet d'afficher les résultats sur 1 colonne (4 par défaut).
   *
   */
  @Input() public singleCol: Boolean = false;

  public ngOnInit(): void {
    super.ngOnInit();
    this.checked = false;
  }

  /**La fonction emit() permet de mettre à jour la valeur bindingValue.
   * La fonction emit() est utilisé par un click, situé dans le template du composant.
   * Ici la valeur à récupérer dans le parametre event, pour ceci, nous utilisons event.target.value.
   * @param event : event
   */
  public emit(event: any): void {
    this.setDisplayValue(event.target.value);
    if (!this.ignoreChange) {
      this.setHasChanged(true, this);
    }
  }
}
