import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import { UserService } from './user.service';
import { DateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
//import { DatetimeService } from './datetime.service';
import { AlocproProvider } from 'app/providers/alocpro.provider';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(
      private translate: TranslateService,
      private dateTimeAdapter: DateTimeAdapter<any>,
      //private dateTimeService: DatetimeService,
      private userService: UserService,
      private alocproProvider: AlocproProvider) {
      registerLocaleData(localeFr, 'fr');
      registerLocaleData(localeEn, 'en');
      this.translate.addLangs(['en', 'fr']);
  }

  public setLanguage(lang: string, noChangeUserServiceLang?: Boolean): void {
    if (!noChangeUserServiceLang) {
      this.userService.setLanguage(lang);
    }
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
    this.dateTimeAdapter.setLocale(lang);
    //this.dateTimeService.locale(lang);
    this.alocproProvider.clearCache();
  }

  public getCurrentLanguage(): string {
    return this.translate.currentLang;
  }

  public getCurrentLanguageISO(): string {
    return this.translate.currentLang.toLowerCase() + '-' + this.translate.currentLang.toUpperCase();
  }
}
