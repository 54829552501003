import { Injectable } from '@angular/core';
import { Util } from 'app/statics/utils';
import { ConfigService } from './config.service';

export const RIGHT_COL_REPORT: string = 'report';
export const RIGHT_COL_ZOOM: string = 'zoom';
export const RIGHT_COL_DETAIL: string = 'detail';
export const RIGHT_COL_API: string = 'api';
export const RIGHT_COL_PRINT_REPORT: string = 'print-report';
export const RIGHT_COL_FIELD_DETAIL: string = 'field-detail';
export const RIGHT_COL_PDF: string = 'print-report-pdf';
export const RIGHT_COL_REPORT_FORM: string = 'print-report-form';
export const RIGHT_COL_REPORT_LIST: string = 'print-report-list';

@Injectable({
    providedIn: 'root'
  })
export class IhmStackService {

    private showRightZoomComponent: Boolean = false;
    private showZoomFieldDetail: Boolean = false;
    private showSubAPIDetailsTable: Boolean = false;
    private showReport: Boolean = false; // Report globale
    private showSubAPIDetailsForm: Boolean = false;
    private showTopBanner: Boolean = false;
    private showPrintReport: Boolean = true; // Bouton d'impréssion direct du contrat
    private showPrintReportPDF: Boolean = false;
    private showPrintReportList: Boolean = false;
    private showPrintReportForm: Boolean = false;
    private showSwitcher: Boolean = false;

    constructor(private configService: ConfigService) {}

    public get ShowSwitcher(): Boolean {
        return this.showSwitcher;
    }

    public set ShowSwitcher(show: Boolean) {
        this.showSwitcher = show;
    }
    
    public get ShowRightZoomComponent(): Boolean {
        return this.showRightZoomComponent;
    }

    public set ShowRightZoomComponent(show: Boolean) {
        this.showRightZoomComponent = show;
    }

    public get ShowZoomFieldDetail(): Boolean {
        return this.showZoomFieldDetail;
    }

    public set ShowZoomFieldDetail(show: Boolean) {
        this.showZoomFieldDetail = show;
    }

    public get ShowSubAPIDetailsTable(): Boolean {
        return this.showSubAPIDetailsTable;
    }

    public set ShowSubAPIDetailsTable(show: Boolean) {
        this.showSubAPIDetailsTable = show;
    }

    public get ShowSubAPIDetailsForm(): Boolean {
        return this.showSubAPIDetailsForm;
    }

    public set ShowSubAPIDetailsForm(show: Boolean) {
        this.showSubAPIDetailsForm = show;
    }

    public get ShowReport(): Boolean {
        return this.showReport;
    }

    public set ShowReport(show: Boolean) {
        this.showReport = show;
    }

    public get ShowTopBanner(): Boolean {
        return this.showTopBanner;
    }

    public set ShowTopBanner(show: Boolean) {
        this.showTopBanner = show;
    }

    public get ShowPrintReport(): Boolean {
        return this.showPrintReport;
    }

    public set ShowPrintReport(show: Boolean) {
        this.showPrintReport = show;
    }

    public get ShowPrintReportPDF(): Boolean {
        return this.showPrintReportPDF;
    }

    public set ShowPrintReportPDF(show: Boolean) {

        this.showPrintReportPDF = show;
    }

    public get ShowPrintReportForm(): Boolean {
        return this.showPrintReportForm;
    }

    public set ShowPrintReportForm(show: Boolean) {
        this.showPrintReportForm = show;
    }

    public get ShowPrintReportList(): Boolean {
        return this.showPrintReportList;
    }

    public set ShowPrintReportList(show: Boolean) {
        this.showPrintReportList = show;
    }

    public hideAll(): void {
        this.showSubAPIDetailsTable = false;
        this.showRightZoomComponent = false;
        this.showReport = false;
        this.showPrintReport = false;
        this.showZoomFieldDetail = false;
        this.showSubAPIDetailsForm = false;
        this.showPrintReportPDF = false;
        this.showPrintReportForm = false;
        this.showPrintReportList = false;
        //this.showSwitcher = false;
    }

    public hideCurrentRight(): void {
        this.showSubAPIDetailsTable = true;
        this.showRightZoomComponent = false;
        this.showReport = false;
        this.showPrintReport = true;
        this.showZoomFieldDetail = false;
        this.showSubAPIDetailsForm = false;
        this.showPrintReportPDF = false;
        this.showPrintReportForm = false;
        this.showPrintReportList = false;
    }

    public displaySubAPIDetails(ky?: string): void {
        if (!Util.isNullOrUndefined(ky) && ky === this.configService.get('newdataKeyWord')) {
            this.hideAll()
        } else {
            this.showSubAPIDetailsTable = true;
            this.showRightZoomComponent = false;
            this.showReport = false;
            this.showPrintReport = true;
            this.showZoomFieldDetail = false;
            this.showSubAPIDetailsForm = false;
            this.showPrintReportPDF = false;
            this.showPrintReportForm = false;
            this.showPrintReportList = false;
        }
    }

    public displayRightZoomComponent(): void {
        this.showRightZoomComponent = true;
        this.showSubAPIDetailsTable = false;
        this.showReport = false;
        this.showPrintReport = true;
        this.showZoomFieldDetail = false;
        this.showSubAPIDetailsForm = false;
        this.showPrintReportPDF = false;
        this.showPrintReportForm = false;
        this.showPrintReportList = false;
    }

    public displayReport(): void {
        this.showReport = true;
        this.showPrintReport = true;
        this.showRightZoomComponent = false;
        this.showSubAPIDetailsTable = false;
        this.showZoomFieldDetail = false;
        this.showSubAPIDetailsForm = false;
        this.showPrintReportPDF = false;
        this.showPrintReportForm = false;
        this.showPrintReportList = true;
    }

    public displayZoomFieldDetail(): void {
        this.showZoomFieldDetail = true;
        this.showReport = false;
        this.showPrintReport = true;
        this.showRightZoomComponent = false;
        this.showSubAPIDetailsTable = false;
        this.showSubAPIDetailsForm = false;
        this.showPrintReportPDF = false;
        this.showPrintReportForm = false;
        this.showPrintReportList = false;
    }

    public displaySubAPIDetailsForm(): void {
        this.showZoomFieldDetail = false;
        this.showReport = false;
        this.showPrintReport = true;
        this.showRightZoomComponent = false;
        this.showSubAPIDetailsTable = true;
        this.showSubAPIDetailsForm = true;
        this.showPrintReportPDF = false;
        this.showPrintReportForm = false;
        this.showPrintReportList = false;
    }

    public displayPrintReport(): void {
        this.showZoomFieldDetail = false;
        this.showReport = false;
        this.showPrintReport = true;
        this.showRightZoomComponent = false;
        this.showSubAPIDetailsTable = false;
        this.showSubAPIDetailsForm = false;
        this.showPrintReportPDF = false;
        this.showPrintReportForm = false;
        this.showPrintReportList = false;
    }

    public displayPrintReportPDF(): void {
        this.showZoomFieldDetail = false;
        this.showReport = true;
        this.showPrintReport = true;
        this.showRightZoomComponent = false;
        this.showSubAPIDetailsTable = false;
        this.showSubAPIDetailsForm = false;
        this.showPrintReportPDF = true;
        this.showPrintReportForm = false;
        this.showPrintReportList = false;
    }

    public displayPrintReportForm(): void {
        this.showZoomFieldDetail = false;
        this.showReport = true;
        this.showPrintReport = true;
        this.showRightZoomComponent = false;
        this.showSubAPIDetailsTable = false;
        this.showSubAPIDetailsForm = false;
        this.showPrintReportPDF = false;
        this.showPrintReportForm = true;
        this.showPrintReportList = false;
    }

    public displayPrintReportList(): void {
        this.showZoomFieldDetail = false;
        this.showReport = true;
        this.showPrintReport = true;
        this.showRightZoomComponent = false;
        this.showSubAPIDetailsTable = false;
        this.showSubAPIDetailsForm = false;
        this.showPrintReportPDF = false;
        this.showPrintReportForm = false;
        this.showPrintReportList = true;
    }

    public getCurrentlyDisplayed(): String {
        if (this.showReport) {
            return RIGHT_COL_REPORT;
        } else if (this.showRightZoomComponent) {
            return RIGHT_COL_ZOOM;
        } else if (this.showSubAPIDetailsTable) {
            return RIGHT_COL_API;
        } else if (this.showZoomFieldDetail) {
            return RIGHT_COL_FIELD_DETAIL;
        } else if (this.showPrintReport) {
            return RIGHT_COL_PRINT_REPORT;
        } else if (this.showPrintReportPDF) {
            return RIGHT_COL_PDF;
        } else if (this.showPrintReportForm) {
            return RIGHT_COL_REPORT_FORM;
        } else if (this.showPrintReportList) {
            return RIGHT_COL_REPORT_LIST;
        } else {
            return RIGHT_COL_DETAIL;
        }
    }
}
