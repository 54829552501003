<div class="modal-header">
  <h4 class="modal-title pull-left">{{'preferences.customization' | translate}}</h4>

  <div class="pull-right">
    <lp-button-cancel (action)="close()"></lp-button-cancel>
  </div>
</div>
<div class="modal-body">

  <mat-tab-group mat-align-tabs="start" (selectedTabChange)="onTabChanged($event);">

    <mat-tab label="{{ 'preferences.repository-tab' | translate}}">
      <div class="row justify-content-md-center m-0 mt-4">
        <div class="form-group col-2">
        </div>
        <div class="form-group col-xl-2">
          <div class="col-3 mt-2 text-end ps-0">Verb</div>
          <lp-text-field [ignoreChange]="true" id="repositoryURL" className="textField"
            class="col-9" cssClass="form-control" name="repositoryVerb"
            [(ngModel)]="repositoryVerb"></lp-text-field>
        </div>
        <div class="form-group col-xl-2">
          <div class="col-3 mt-2 text-end ps-0">Id</div>
          <lp-text-field [ignoreChange]="true" id="repositoryId" className="textField"
            class="col-9" cssClass="form-control" name="repositoryId"
            [(ngModel)]="repositoryId"></lp-text-field>
        </div>
        <div class="form-group col-2">
          <button [disabled]="!repositoryVerb || repositoryVerb == '' || !repositoryId || repositoryId == ''"
            *ngIf="!loaderRepository" (click)="getRepositoryData()"
            class="btn btn-secondary pref-btn">          
            <i class="vega-search" title="{{'preferences.search' | translate}}"></i>
          </button>
          <lp-ui-loader-mini  class="transparent-btn pref-btn ms-1 me-1 position-static loader-min" *ngIf="loaderRepository"></lp-ui-loader-mini>
          <button *ngIf="itemSearched !== null && itemSearched!== undefined" class="btn-primary btn pref-btn ms-1 me-1" (click)="saveJson(jsonRepository)">
            <i class="vega-diskette" title="{{'preferences.update' | translate}}"></i>
          </button>
        </div>
      </div>
      <div class="row justify-content-md-center m-0 mt-3">
        <div class="form-group col-xl-10">
          <lp-json-field [ignoreChange]="true" id="editorCustomCodeInput"  name="editorCustomCodeInput" [(ngModel)]="jsonRepository"></lp-json-field>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="{{ 'preferences.applicationItem-tab' | translate}}">
      <div class="row justify-content-md-center m-0 mt-4">
        <div class="text-end mt-2 col-1">Id</div>
        <div class="form-group col-xl-4">
          <lp-dropdown-field className="applicationItemVerb"
            id="applicationItemVerb" cssClass="form-control" [(ngModel)]="applicationItemVerb"
            name="applicationItemVerb" [values]="ecranNameArray" [ignoreChange]=true></lp-dropdown-field>
        </div>
        <div class="form-group col-xl-1">
          <button [disabled]="applicationItemVerb === null || applicationItemVerb.id == ''"
            (click)="getApplicationItemData(applicationItemVerb.id)" *ngIf="!loaderApplicationItem"
            class="btn btn-secondary pref-btn">          
            <i class="vega-search" title="{{'preferences.search' | translate}}"></i>
          </button>
          <lp-ui-loader-mini  class="transparent-btn pref-btn ms-1 me-1 position-static loader-min" *ngIf="loaderApplicationItem"></lp-ui-loader-mini>
          <button *ngIf="itemSearched !== null && itemSearched!== undefined" class="btn-primary btn pref-btn ms-1 me-1" (click)="saveJson(jsonApplicationItem)">
            <i class="vega-diskette" title="{{'preferences.update' | translate}}"></i>
          </button>
        </div>
      </div>
      <div class="row justify-content-md-center m-0 mt-3">
        <div class="form-group col-xl-10">
          <lp-json-field [ignoreChange]="true" id="editorApplicationItem"  name="editorApplicationItem" [(ngModel)]="jsonApplicationItem"></lp-json-field>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="{{ 'preferences.code-tab' | translate}}">
      <div class="row justify-content-md-center m-0 mt-4">
        <div class="text-end mt-2 col-1">{{'preferences.screenNumber' | translate}}</div>
        <div class="form-group col-xl-4">
          <lp-dropdown-field className="ecrId" id="ecrId"
            cssClass="form-control" [(ngModel)]="ecrId" name="ecrId" [values]="ecranIdArray" [ignoreChange]=true>
          </lp-dropdown-field>
        </div>
        <div class="form-group col-xl-1">
          <button [disabled]="ecrId === null || ecrId.id == ''" (click)="getCustomCode(ecrId.id)"
            *ngIf="!loaderCustomCode" class="btn btn-secondary pref-btn">          
            <i class="vega-search" title="{{'preferences.search' | translate}}"></i>
          </button>
          <lp-ui-loader-mini  class="transparent-btn pref-btn ms-1 me-1 position-static loader-min" *ngIf="loaderCustomCode"></lp-ui-loader-mini>
          <button *ngIf="itemSearched !== null && itemSearched!== undefined" class="btn-primary btn pref-btn ms-1 me-1" (click)="saveJson(jsonCode)">
            <i class="vega-diskette" title="{{'preferences.update' | translate}}"></i>
          </button>
        </div>
      </div>
      <div class="row justify-content-md-center m-0 mt-3">
        <div class="form-group col-xl-10">
          <lp-json-field [ignoreChange]="true" id="editorCustomCode"  name="editorCustomCode" [(ngModel)]="jsonCode"></lp-json-field>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="{{ 'preferences.function-tab' | translate}}">
      <div class="row justify-content-md-center m-0 mt-4">
        <div class="col-xl-4 text-end mt-2">Nom</div>
        <div class="form-group col-xl-4">
          <lp-dropdown-field className="applicationItemVerb"
            id="applicationItemVerb" cssClass="form-control" [(ngModel)]="function" name="function" [values]="functions"
            labelField="name" idField="id" [ignoreChange]=true></lp-dropdown-field>
        </div>
        <div class="form-group col-xl-4">
          <button [disabled]="function === null " (click)="getFunction(function.id)" *ngIf="!loaderApplicationItem"
            class="btn btn-secondary pref-btn  ms-1 me-1">
            <i class="vega-search" title="{{'preferences.search' | translate}}"></i>
          </button>
          <lp-ui-loader-mini class="transparent-btn pref-btn ms-1 me-1 position-static loader-min" *ngIf="loaderApplicationItem"></lp-ui-loader-mini>
          <button *ngIf="itemSearched !== null && itemSearched!== undefined" class="btn-primary btn pref-btn ms-1 me-1" (click)="saveJson(jsonFunction)">
            <i class="vega-diskette" title="{{'preferences.update' | translate}}"></i>
          </button>
          <button *ngIf="itemSearched === 'CUSTOME_FUNCTION'" class="ms-1 me-1 pref-btn btn btn-outline-primary"
            (click)="postFunction(jsonFunction)">
            <i class="vega-duplicate" title="{{'preferences.createNew' | translate}}"></i>
          </button>          
          <button *ngIf="itemSearched === 'CUSTOME_FUNCTION'" class="btn  ms-1 me-1 btn-outline-danger pref-btn" (click)="deleteFunction();">
            <i class="vega-dustbin" title="{{'preferences.delete' | translate}}"></i>
          </button>
        </div>
      </div>
      <div class="row justify-content-md-center m-0 mt-3">
        <div class="form-group col-xl-10">
          <lp-json-field [ignoreChange]="true" id="editorFunction"  name="editorFunction" [(ngModel)]="jsonFunction"></lp-json-field>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="{{ 'preferences.generalParam-tab' | translate}}" id="generalParam">
      <div class="row m-0 mt-4 justify-content-md-center">
        <div class="form-group col-3">
          <div class="col-3 text-end ps-0 mt-2">{{'general_parameters.table' | translate}}</div>
          <lp-text-field [ignoreChange]="true" id="generalParamURL" className="textField"
            class="col-9" cssClass="form-control" name="generalparamVerb"
            [(ngModel)]="generalparamVerb"></lp-text-field>
        </div>
        <div class="form-group col-3">
          <div class="col-3 text-end ps-0 mt-2">{{'general_parameters.argument' | translate}}</div>
          <lp-text-field [ignoreChange]="true" id="generalparamId" className="textField"
            class="col-9" cssClass="form-control" name="generalparamId"
            [(ngModel)]="generalparamId"></lp-text-field>
        </div>
        <div class="form-group col-3">
          <div class="col-3 text-end ps-0 mt-2">{{'general_parameters.company' | translate}}</div>
          <lp-zoom-field
            class="col-9"
            id="company"
            [ignoreChange]="true"
            addModifyVerb="companies"
            libSearch="wording"
            zoomId='company'
            className="zoomField"
            cssClass="form-control"
            [showModifyButton]="false"
            [ngModelOptions]="{standalone: true}"
            [(ngModel)]="data.company"
            [inputSearch]="data.company?.id"
            searchTextField="id"
            [modal]="true"
            [tableShowField]="['id','corporateName']"
            name="company"
            kySearch="id"
            locProZoomObject="location"
            locProZoomAttribut="company">
          </lp-zoom-field>  
        </div>
        <div class="form-group col-2">
          <button *ngIf="!loaderGeneralParam" (click)="getGeneralParamData()" class="btn btn-secondary pref-btn">          
            <i class="vega-search" title="{{'preferences.search' | translate}}"></i>
          </button>
          <lp-ui-loader-mini  class="transparent-btn pref-btn ms-1 me-1 position-static loader-min" *ngIf="loaderGeneralParam"></lp-ui-loader-mini>
          <button *ngIf="itemSearched !== null && itemSearched!== undefined && itemSearched === 'GENERAL_PARAMETERS'" class="btn-primary btn pref-btn ms-1 me-1" (click)="saveJson(currentGeneralParameter)">
            <i class="vega-diskette" title="{{'preferences.update' | translate}}"></i>
          </button>       
          <button class="ms-1 me-1 pref-btn btn btn-outline-primary" (click)="createGeneralParameters()">
          <i class="fa fa-plus" title="{{'preferences.createParameters' | translate}}"></i>
          </button>          
        </div>
        <hr class="col-8 mt-2 mb-2" *ngIf="showGeneralParamsForm"/>
      </div>
      <div class="row justify-content-md-center mt-3" *ngIf="showGeneralParamsForm">
        <div class="form-group col-xl-10">
          <lp-general-parameters [action]="action" [datas]="dataGeneralParameters" (emitCurrentGeneralParameter)="assignGeneralParameter($event)"></lp-general-parameters>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

</div>