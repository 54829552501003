import { LpMeta, propertyMeta } from './meta';

export class LpAccountingEvent extends LpMeta {
    @propertyMeta('string')
    public code: String;
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('number')
    public credit: Number;
    @propertyMeta('number')
    public debit: Number;
    @propertyMeta('date')
    public date: Date;
}
