import { LpMeta, propertyMeta } from '../meta';
import { LpMemo } from '../memo';
import { Type } from 'class-transformer';
import { FtMeta } from '../ft-meta';

export class LpInspectionChecklist extends LpMeta {
    @propertyMeta('date')
    public visitDate: Date;
    @propertyMeta('date')
    public updateDate: Date;
    @Type(() => LpMemo)
    public attachments: LpMemo[];
    @propertyMeta('string')
    public wording: String;
    @Type(() => FtMeta)
    public damageValue: FtMeta;
    @Type(() => FtMeta)
    public damageCategory: FtMeta;
    @Type(() => FtMeta)
    public group: FtMeta;
    @Type(() => FtMeta)
    public groupCategory: FtMeta;
    @propertyMeta('number')
    public nbMemos: Number;
}

export class LpInspectionChecklistV2 extends LpMeta {
    @propertyMeta('string')
    public id: String;
    @propertyMeta('boolean')
    public isActive: Boolean;
    @propertyMeta('number')
    public order: Number;
    @propertyMeta('string')
    public wording: String;
    @Type(() => LpElement)
    public elements: LpElement[];
}
export class LpElement extends LpMeta {
    @propertyMeta('string')
    public id: String;
    @propertyMeta('string')
    public savedValue: String;
    @propertyMeta('string')
    public technicalLink: String;
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('string')
    public checklistModelId: String;
    @propertyMeta('string')
    public defaultValue: String;
    @propertyMeta('any')
    public attachments: any[];
}
