import { LpMeta, propertyMeta } from './meta';
import { FtMeta } from './ft-meta';
import { Type } from 'class-transformer';
import { LpThirdParty } from './third-party';

export class LpThirdPartyLink extends LpMeta {
    @Type(() => LpThirdParty)
    public LpThirdParty: LpThirdParty = new LpThirdParty();
    @Type(() => FtMeta)
    public linkType: FtMeta = new FtMeta();
    @propertyMeta('string')
    public reference: String;
    @propertyMeta('date')
    public date: Date;
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('string')
    public attachment: String;
}
