import { Injectable, EventEmitter, Output } from '@angular/core';
import { AlocproProvider } from './alocpro.provider';
import { PaginatedData } from 'app/models/paginated-data';
import { HttpParams } from '@angular/common/http';
import { Util } from 'app/statics/utils';
import { RepositoryProvider } from './repository.provider';
import { LpEdi } from 'app/meta/edi';
import { ConfigService } from 'app/services/config.service';
import { HttpProvider } from './http.provider';
// import { LpEdi } from 'app/meta/edi';

const EDIS_ME: String = 'technical/edis/me';
const EDIS: String = 'technical/edis';
const OBJECTS: String = '?object=';
const KEY: String = '&key=';
// const EDIS_ACTIVITIES: string = 'technical/edis/activities';

@Injectable({
  providedIn: 'root'
})
export class EdiProvider {

  @Output() public refreshNbEdi: EventEmitter<number> = new EventEmitter<number>();

    /**
   * L'évènement refreshWorkflowListEvtEmitter est utilisé lorsque l'utilisateur à fait une mise à jour sur la liste des workflows.
   */
  public refreshEdiListEvtEmitter: EventEmitter<any> = new EventEmitter();
  public nbEdis: number = -1;
  public ediSelected: any;
  public ediSelectedForDelete: any;

  constructor(
    private alocproProvider: AlocproProvider,
    private repositoryProvider: RepositoryProvider,
    private configService: ConfigService,
    public http: HttpProvider
  ) {}

  public refreshEdiList(): void {
    this.refreshEdiListEvtEmitter.emit();
  }

  public getEdis(relatedObject: String, relatedObjectId: String, page: number, limit: Number, isMe: boolean): Promise<PaginatedData> {
    if (!isMe) {
      let params: Map<string, string> = new Map();
      params.set('object', relatedObject.toString())
      params.set('key', relatedObjectId.toString())
      return this.alocproProvider.getPaginatedData(`${EDIS}`, page, params, null, limit);
    } else {
      return this.alocproProvider.getPaginatedData(`${EDIS_ME}`, page, null, null, limit);
    }
  }

  public findEdi(id: String): Promise<any> {
    return this.alocproProvider.find(EDIS, id);
  }

  public deleteEDI(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.alocproProvider.delete(EDIS, this.ediSelectedForDelete)
        .then(() => {
          this.ediSelectedForDelete =null;
          this.refreshEdiList();
          resolve();
        });
    });
  }

  public getNbEdis(relatedObject: String, relatedObjectId: String, verb?: String): Promise<number> {
    if (this.nbEdis === -1 && !relatedObject && verb) {
     this.repositoryProvider.getApplicationItem(verb).then((res) => {
        relatedObject = res.linkedObject;
      })
    };
    return new Promise<number>((resolve, reject) => {
      if (this.nbEdis === -1 && !Util.isNullOrUndefined(relatedObjectId)) {
        let params: HttpParams = new HttpParams()
        if (!Util.isNullOrUndefined(relatedObject)) {
          params = params.set('object', relatedObject.toString());
        }
        if (!Util.isNullOrUndefined(relatedObjectId)) {
          params = params.set('key', relatedObjectId.toString());
        }
        this.alocproProvider.getTotalCount(`${this.configService.get('alocproServiceUrl')}/${EDIS}`, params).then((result: number) => {
          resolve(result);
        });
      } else {
        reject(this.nbEdis);
      }
    });
  }

  public getUserNbEdis(): Promise<number> {
    return this.alocproProvider.getTotalCount(`${this.configService.get('alocproServiceUrl')}/${EDIS_ME}`).then((result: number) => {
      return result;
    });
  }

  public putEdis(editedEdi: LpEdi): Promise<void> {
    return new Promise<void>((resolve, reject) => { console.log('passe');
      this.alocproProvider.put(EDIS.toString(), editedEdi.id as string, editedEdi)
        .then(() => {
          resolve();
        });
    });
  }

  public postEdi(newEdi: LpEdi, object: String, key: String): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let a: Promise<any>;
      a = this.http.httpPost(`${this.configService.get('alocproServiceUrl')}/${EDIS}${OBJECTS}${object}${KEY}${key}`, newEdi);
      a.then((response) => {
        this.refreshEdiList();
        resolve(resolve(response.headers.get('x-vega-id')));
      });
    });
  }

  public closeEdi(currentEdi: LpEdi): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.putEdis(currentEdi)
        .then(() => {
          resolve()
        });
    })
  }
}
