import { Injectable } from '@angular/core';
import { PaginatedData } from 'app/models/paginated-data';

@Injectable()
export class ExampleDetailsProvider {

    private applicationItemDetail: PaginatedData = JSON.parse('{"count":5, "body": [{"_readOnly":false,"id":0,"details":"details1","wording":"wording1","date":"2020-01-21T17:02:45.000Z"},{"_readOnly":false,"id":1,"details":"details2","wording":"wording2","date":"2020-02-21T17:02:45.000Z"},{"_readOnly":false,"id":2,"details":"details3","wording":"wording3","date":"2020-03-21T17:02:45.000Z"},{"_readOnly":false,"id":3,"details":"details4","wording":"wording4","date":"2020-04-21T17:02:45.000Z"},{"_readOnly":false,"id":4,"details":"details5","wording":"wording5","date":"2020-05-21T17:02:45.000Z"}]}')

    constructor() { }

    public loadDetail(): Promise<PaginatedData> {
        return new Promise((resolve) => {
            resolve(this.applicationItemDetail);
        });
    }

}
