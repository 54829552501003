export class BreadcrumbItem {
    public id: String;
    public title: String;
    public summary: ColumnsProperty[];
    public columns: ColumnsProperty[];

    constructor() {}
}

export interface ColumnsProperty {
    prop: Array<ColumnProperty>;
    title: String;
    type: Number;
}

export interface ColumnProperty {
    object?: Array<string>;
    field: string;
}
