import { Injectable } from '@angular/core';
import { FormStackService } from '../form-stack.service';
import { LpMeta } from 'app/meta/meta';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { UiSyncService } from '../ui-sync.service';
import { EvalService, BEFORE_INSERT_EVENT, INIT_NEW_EVENT, MAIN } from '../eval.service';
import { FormMetadataValues } from 'app/models/form-metadata-values';
import { RightColService } from './rightCol.service';
import { ChangeService } from '../change.service';
import { UserHistoryService } from '../user-history.service';

@Injectable({
  providedIn: 'root'
})
export class NewService {

  constructor(
    private formStackService: FormStackService,
    private alocproProvider: AlocproProvider,
    private evalService: EvalService,
    private uiSyncService: UiSyncService,
    private rightColService: RightColService,
    private changeService: ChangeService,
    private userHstoryService: UserHistoryService
    ) { }

  public async initNewForm(): Promise<void> {
    this.changeService.initChange();
    this.rightColService.closeAll();
    this.formStackService.ignoreDataLoading = false;
    this.formStackService.hydrateFromStoredProperties(this.formStackService.currentData);

    setTimeout( async () => {
      this.uiSyncService.IsNewForm = true;  
    });

    setTimeout( async () => {
      await this.getBackEndMetadata(this.formStackService.CurrentVerb);
      await this.evalService.executeContrainte(INIT_NEW_EVENT, MAIN, this.formStackService.currentApplicationItem.ecrId.toString()
          , '*', this.formStackService.currentData, this.formStackService.currentFields);
      let formMeta: FormMetadataValues = {fromPlanning: this.formStackService.isPreviousStackPlanning()
        , isNewForm: true, subApi: false};
      this.uiSyncService.emitFormMetadataLoaded(formMeta);
    });
  }

  public async submit(verb: string, data: LpMeta): Promise<string> {
    try {
      await this.evalService.executeContrainte(
        BEFORE_INSERT_EVENT, MAIN, this.formStackService.currentApplicationItem.ecrId.toString(), '*', data
            , this.formStackService.currentFields);
      const ky: string = await this.alocproProvider.post(verb, data.optimized());
      this.formStackService.currentData.id = ky;
      this.userHstoryService.addEvent('add', this.formStackService.currentApplicationItem, ky, this.formStackService.currentApplicationItem.verb);
      return ky;
    } catch (e) {
      throw e;
    }
  }

  private async getBackEndMetadata(verb: string): Promise<void> {
    await this.formStackService.getAllMetadata(verb, 'new', true);
    Promise.resolve();
  }
}

