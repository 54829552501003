import { Component, OnInit } from "@angular/core";
import { LpModalPromiseSpoolerPreviewComponent } from "app/ui/lp-modal/lp-modal-spooler-preview/lp-modal-spooler-preview.component";
import { ReportSpoolerService } from "app/services/report-spooler.service";
import { UiSyncService } from "app/services/ui-sync.service";
import { TranslateService } from "@ngx-translate/core";
import { ModalService } from "app/services/modal.service";
import { ReportService } from "app/services/report.service";
import { ActivatedRoute } from "@angular/router";

//const STATUS_FOR_DOWNLOAD: string = '90';

@Component({
    selector: 'lp-reports',
    templateUrl: './report-preview.component.html'
  })
  
  export class ReportsPreviewComponent implements OnInit {

    public loader: String;
    public srcPdf: Uint8Array;
    public title: String;

    constructor(protected uiSyncService: UiSyncService,
                protected translate: TranslateService,
                private modalService: ModalService,
                private reportService: ReportService,
                public reportSpoolerService : ReportSpoolerService,
                protected route: ActivatedRoute){
    }

    public async ngOnInit(): Promise<void> {
        this.preview(this.route.snapshot.params['ky'])
    }

    public async preview(spoolerKy: string): Promise<void> {
        this.uiSyncService.loader(true, true, this.translate.instant('general.modalService.previewPreparation'));
        try {
          await this.downloadSpooler(spoolerKy);
          await this.modalService.modalPromise(LpModalPromiseSpoolerPreviewComponent, {
            height : '85vh',
            width : '80vw',
            backdropClass: 'alertBackdropClass',
          });
          this.uiSyncService.loader(false);
        } catch (error) {
          this.uiSyncService.loader(false);
          throw error;
        }
      }

    private async downloadSpooler(spoolerKy: string): Promise<void> {
        try {
            const report: ArrayBuffer = await this.reportSpoolerService.download(spoolerKy);
            let pdfBinaryReport: Uint8Array = new Uint8Array(report);
            this.reportService.srcPdfForSpooler = pdfBinaryReport;
            this.srcPdf = pdfBinaryReport;
        } catch (error) {
          throw error;
        }
    }
}