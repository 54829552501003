import { Component, Input, OnInit } from '@angular/core';
import { FormStackService } from 'app/services/form-stack.service';
import { ZoomService } from 'app/services/zoom.service';
//import { ZoomConfig } from 'app/models/zoom-metada';
import { UiSyncService } from 'app/services/ui-sync.service';
import { ZoomIHM } from 'app/meta/zoom/zoomMeta';
import { ZoomConfig } from 'app/models/zoom-metada';
import { Util } from 'app/statics/utils';

const IDFIELD: string = 'id';
const LABELFIELD: string = 'zoomWording';
const MAIN: string = 'main';
const BASE_WORDING: string = 'baseWording';
const ID: string = 'id';
const NAME: string = 'chooser';
const COLUMNS_MODE: string = 'standard';

@Component({
  selector: 'lp-zoom-chooser',
  templateUrl: './lp-zoom-chooser.component.html'
})
export class LpZoomChooserComponent implements OnInit {

  @Input() public zoomId: string;

  public locProZoomObject: string;
  public locProZoomAttribut: string;
  public name: string = NAME;
  public values: Array<any> = new Array<any>();
  public labelField: string = LABELFIELD;
  public idField: string = IDFIELD;
  public baseWording: string = BASE_WORDING;
  public zoomIHM: ZoomIHM;

  constructor(
    public formStackService: FormStackService,
    public zoomService: ZoomService,
    public uiSyncService: UiSyncService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.locProZoomObject = String(this.formStackService.currentApplicationItem.linkedObject);
    this.locProZoomAttribut = MAIN;
    this.values = await this.getZoomLinksList();
  }

  public selectZoom(event: any): void {
    this.getZoomFullDataList(event.target.value)
  }


  public async getZoomLinksList(): Promise<any> {
    try {
      if (
        !Util.isNullOrUndefined(this.locProZoomAttribut) && !Util.isNullOrUndefined(this.locProZoomObject) &&
        this.locProZoomAttribut !== '' && this.locProZoomObject !== ''
        ) {
        return await this.zoomService.getZoomLinksList(this.locProZoomAttribut, this.locProZoomObject);
      }
    } catch (error ) {
      throw error;
    }
  }

  private async getZoomFullDataList(id: string): Promise<void> {
    try {
      this.zoomIHM = await this.zoomService.getParametersFromBackend(id);
      this.zoomService.setConfigAction(this.buildConfig(), `${MAIN}_${this.formStackService.CurrentVerb}`);
      this.uiSyncService.displayZoomEvtEmitter.emit({param: this.zoomIHM, guid: `${MAIN}_${this.formStackService.CurrentVerb}`});      
    } catch (e) {
        throw e;
    }
  }

  private buildConfig(): ZoomConfig {
    let config: ZoomConfig = new ZoomConfig();
    config.id = this.zoomIHM.id;
    config.isLocProZoom = true;
    config.locProZoomAttribut = this.locProZoomAttribut;
    config.title = this.zoomIHM.wording;
    config.urlNew = this.formStackService.CurrentVerb; 
    config.exportButton = true;
    config.isFromChild = false;
    config.kySearch = ID ;
    config.locProZoomId = this.zoomIHM.id;
    config.modal = true;
    config.addModifyVerb = this.formStackService.CurrentVerb;
    //config.url = this.formStackService.CurrentVerb;
    config.zoomParameter = {
      criteriasByLine: this.zoomIHM.criteriasByLine,
      hasButtonAdd: this.zoomIHM.hasButtonAdd,
      hasButtonCriterias: this.zoomIHM.hasButtonCriterias,
      hasButtonOk: this.zoomIHM.hasButtonOk,
      hasButtonUpdate: this.zoomIHM.hasButtonUpdate,
      columns: this.zoomIHM.columns,
      columnsMode: COLUMNS_MODE,
      criterias: this.zoomIHM.criterias,
      //path: `lp3k-backend/${this.locProZoomObject}`,
      wording: this.zoomIHM.wording,
      displayCriterias: this.zoomIHM.displayCriterias,
      order: this.zoomIHM.order,
      searchLimit: this.zoomIHM.searchLimit,
      type: this.zoomIHM.type
    }
    return config;
 }
  

}
