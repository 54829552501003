<div class="modal-header">
  <h4 class="modal-title pull-left">{{'preferences.showDashboard' | translate}}</h4>

  <div class="pull-right">
    <lp-button-cancel (action)="close()"></lp-button-cancel>
  </div>
</div>
<div class="modal-body">
  <div class="card-box" #col1>
    <form #form="ngForm" ngNativeValidate>
      <div class="container-fluid">
        <lp-ui-accordion class="row" [menu]=true>
          <lp-ui-accordion-group heading="dashboard.general" [open]=true key="general" >
            <div>
              <div class="row">
                <lp-label-field for="dashboardName" class="{{arrayClassesElements.get('defaultLabel')}}" text="{{'dashboard.label' | translate}}"></lp-label-field>
                <lp-text-field [ignoreChange]="true" id="dashboard.dashboardName" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                  cssClass="form-control" name="dashboard.dashboardName" [(ngModel)]="formstackService.dashboard.label"></lp-text-field>
              </div>                
            </div>
          </lp-ui-accordion-group>
          <lp-ui-accordion-group heading="{{'dashboard.widgets' | translate}}" [open]=true key="general" >
            <div class="btn-showWidget">
              <lp-label-field text="preferences.showActiveDashboard" for="btn-showWidget"></lp-label-field>
                <lp-boolean-switch-box [ignoreChange]="true" [readonly]="false" name="dashboard.isADriverChecked" switchName="displayActiveWidgets" id="dashboard.displayActiveWidgets" [(ngModel)]="displayActiveWidgets"></lp-boolean-switch-box>
            </div>
            <div class="row">
              <ng-template ngFor let-widget [ngForOf]="listOfWidgets">
                <div *ngIf="displayActiveWidgets || widget.active" class="col-4 mb-3">
                  <lp-form-card id="{{widget.id}}" name="{{widget.id}}" title="{{widget.title}}" 
                                description="{{widget.description}}" [class]="getType(widget.cardType)" 
                                [displayActive]="true" [bindingToActive]="widget.active" [objectToSendToEmit]="widget"></lp-form-card>
                </div>
              </ng-template>
            </div>
            <div class="row">
                <div class="col-12 alert alert-warning" role="alert">
                  {{'preferences.dashboardWarningMessage' | translate}}
                </div>
              <div class="col-12 mb-0">
                <button class="transparent-btn pull-right" (click)="saveDashboard()">
                  <i class="vega-diskette"></i>
                </button>
              </div>
            </div>
          </lp-ui-accordion-group>
          <lp-ui-accordion-group heading="{{'dashboard.links' | translate}}" [open]=true key="general" >
            <div class="row">
              <ng-template ngFor let-link [ngForOf]="links">
                <div *ngIf="link" class="col-4 mb-3">
                  <lp-form-card id="{{link.id}}" name="{{link.url}}" description="link" title="{{link.url}}" [class]="getType(1)" [displayActive]="true" [bindingToActive]="true" [objectToSendToEmit]="link"></lp-form-card>
                </div>
              </ng-template>
            </div>
          </lp-ui-accordion-group>
          <lp-ui-accordion-group heading="dashboard.informations" [open]=true key="Informations" >
            <div>
              <div class="row">
                <lp-wysiwyg-editor-field [(ngModel)]="editorData" id="wisiwygEditorNews" name="wisiwygEditorNews"></lp-wysiwyg-editor-field>                
                <div class="col-12 mb-0">
                  <button class="transparent-btn pull-right" (click)="saveNews()">
                    <i class="vega-diskette"></i>
                  </button>
                  <button *ngIf="news && news.id" class="transparent-btn pull-right" (click)="deleteNews()">
                    <i class="vega-dustbin"></i>
                  </button>
                </div>
              </div>                
            </div>
          </lp-ui-accordion-group>
          <!--<lp-ui-accordion-group heading="Outbar" [open]=true >
            <div class="row">
              <div class="form-group col-xl-12">
                <lp-outbar-preferences></lp-outbar-preferences>
              </div>
            </div>
          </lp-ui-accordion-group>-->
        </lp-ui-accordion>

      </div>
    </form>
  </div>
</div>