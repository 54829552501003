export const INPUT_FIELD_TYPE_LABEL: String = 'label';
export const INPUT_FIELD_TYPE_HTML: String = 'html';
export const INPUT_FIELD_TYPE_SEPARATOR: String = 'separator';
export const INPUT_FIELD_TYPE_TEXT: String = 'text';
export const INPUT_FIELD_TYPE_NUMBER: String = 'number';
export const INPUT_FIELD_TYPE_CURRENCY: String = 'currency';
export const INPUT_FIELD_TYPE_DROPDOWN: String = 'dropdown';
export const INPUT_FIELD_TYPE_ZOOM: String = 'zoom';
export const INPUT_FIELD_TYPE_DATETIME: String = 'datetime';
export const INPUT_FIELD_TYPE_DATE: String = 'date';
export const INPUT_FIELD_TYPE_GAUGE: String = 'gauge';
export const INPUT_FIELD_TYPE_BOOLEAN: String = 'boolean';


export interface InputField {
    prop: String,
    label: String,
    readonly: Boolean;
    mandatory: Boolean;
    defaultValue: any;
    defaultValueEval: String;
    availableEval?: string;
    hidden?: boolean
    afterBindEval: String;
    type: String;
    params?: any;
    hideLabel?: boolean
    id?: String;
    style?: any;
    classes?: string;
}
