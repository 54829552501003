<!--Details des zooms-->
<lp-field-details *ngIf="ihmStackService.ShowZoomFieldDetail"></lp-field-details>

<!--zoom -->
<lp-zoom *ngIf="ihmStackService.ShowRightZoomComponent" [modal]=false></lp-zoom>

<!--table générique -->
<lp-application-item-details *ngIf="ihmStackService.ShowSubAPIDetailsTable && formStackService?.currentApplicationItemDetail?.editFormActive === false"></lp-application-item-details>

<!--Sous APIs-->
<lp-sub-api *ngIf="rightColService.displaySubApi()"></lp-sub-api>

<!--zoom pour les impressions--->
<lp-report *ngIf="ihmStackService.ShowReport"
(isShownOutput)="changeShowZoomPrint($event)"></lp-report>

<lp-print-report *ngIf="reportService.displayPrintShortCut() && !ihmStackService.ShowReport && rightColService.displayReportButton()" ></lp-print-report>
<lp-mail-report *ngIf="reportService.displayPrintShortCut() && !ihmStackService.ShowReport && rightColService.displayReportButton()" [isShortcut]="true"></lp-mail-report>