<div class="row justify-content-center" *ngIf="bordertop">
  <hr class="col-sm-10 mb-1 mt-2" />
</div>
<div class="row">
  <p *ngIf="label" class="lp-subgroup-title col-12 mt-2 mb-2">{{label |translate}}</p>
</div>
<ng-content></ng-content>

<div class="row justify-content-center" *ngIf="borderbottom">
        <hr class="col-sm-10 mb-2 mt-1" />
</div>