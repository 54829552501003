import { LpMeta, propertyMeta } from './meta';
import { Type } from 'class-transformer';
import { LpThirdParty } from './third-party';
import { LpFinancialType } from './financial-type';

export class LpFinancialElementsLinks extends LpMeta {

    @Type(() => LpFinancialType)
    public financialType: LpFinancialType = new LpFinancialType();
    @Type(() => LpThirdParty)
    public thirdParty: LpThirdParty = new LpThirdParty();
    @propertyMeta('date')
    public date: Date;
    @propertyMeta('boolean')
    public hasEstimate: boolean;
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('number')
    public responsabilities: number;
    @propertyMeta('number')
    public income: number;
    @propertyMeta('boolean')
    public isExclusionExportation: boolean;
    @propertyMeta('string')
    public referenceA: String;
    @propertyMeta('string')
    public referenceB: String;
    @propertyMeta('date')
    public dateEnd: Date;
    @propertyMeta('boolean')
    public hasTabNumber: boolean;
    @propertyMeta('number')
    public baseAmount: number;
    @propertyMeta('number')
    public rateDiscount: number;

}
