<carousel [interval]="false" class="carousel-memo">
    <slide *ngFor="let base64 of displayValue;">
        <img src="{{base64}}" *ngIf="fileService.isImage(base64)" (click)="uiSyncService.displayZoomImage(base64)"/>
        <div *ngIf="!fileService.isImage(base64)" class="icon-file">
        <i class="fa {{getIcon(base64)}}" aria-hidden="true" (click)="zoomIconFile(base64)" [class.zoom]="fileService.isTxt(base64) || fileService.isPdf(base64)" title="{{getIconLabel(base64) |translate}}"></i>                        
        </div>
        <p class="text-center mt-2 mb-0">
        <i class="vega-download me-4" aria-hidden="true" (click)="downloadFile(base64)"></i>
        <i class="vega-dustbin" aria-hidden="true" (click)="deleteDocument(base64)"></i>
        </p>
    </slide>                  
</carousel>