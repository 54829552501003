import { Component, Input } from '@angular/core';
import { FormatService } from 'app/services/format.service';
import { SafeHtml } from '@angular/platform-browser';
import { DatetimeService } from 'app/services/datetime.service';

@Component({
  selector: 'lp-cell',
  templateUrl: './lp-cell.component.html'
})
export class LpCellComponent {

  @Input() set type(type: Number) {
    this._type = type;
    this.formatText();
  }
  get type(): Number {
    return this._type ;
  }
  @Input() set value(value: String) {
    this._value = value;
    this.formatText();
  }
  @Input() set infos(infos: any) {
    this._infos = infos;
    this.formatText();
  }

  public text: String |SafeHtml;
  private _value: String;
  private _type: Number;
  private _infos: any;

  constructor(public formatService: FormatService,
              private datetimeService: DatetimeService) { }

  private formatText(): void {
    if (this._type === 4 && this._value) {
      if (this.datetimeService.isValid(this._value.toString())) {
        this.text = this.formatService.formatValue(this._type, this._value.toString());
      }
    } else if (this._type === 5 && this._value) {
      if (this.datetimeService.isValid(this._value.toString())) {
        this.text = this.formatService.formatValue(this._type, this._value.toString());
      }
    } else {
      this.text = this.formatService.formatValue(this._type, this._value, this._infos);
    }
  }
}
