import { Type } from 'class-transformer';
import { LpCompany } from './company';
import { LpLocation } from './location';
import { LpMeta, propertyMeta } from './meta';
import { LpSectorKind } from './sector-kind';
import { LpThirdParty } from './third-party';

export class LpLocationsSectors extends LpMeta {
    @propertyMeta('date')
    public startDate: Date;
    @propertyMeta('date')
    public endDate: Date;
    @Type(() => LpCompany)
    public company: LpCompany = new LpCompany();
    @Type(() => LpLocation)
    public location: LpLocation = new LpLocation();
    @propertyMeta('string')
    public filter: String;
    @propertyMeta('string')
    public filterA: String;
    @Type(() => LpSectorKind)
    public sectorKindA: LpSectorKind = new LpSectorKind();
    @Type(() => LpSectorKind)
    public sectorKind: LpSectorKind = new LpSectorKind();
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('string')
    public leadingWord: String;
    @propertyMeta('boolean')
    public isActive: Boolean;
    @Type(() => LpThirdParty)
    public owner: LpThirdParty = new LpThirdParty();
    @propertyMeta('string')
    public status: String;
}
