import { LpMeta, propertyMeta } from './meta';
import { FtMeta } from './ft-meta';
import { Type } from 'class-transformer';

export class LpCrashLink extends LpMeta {
    @propertyMeta('string')
    public wording: String;
    @Type(() => FtMeta)
    public crashType: FtMeta = new FtMeta();
}
