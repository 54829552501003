import { LpMeta, propertyMeta } from './meta';
import { FtMeta } from './ft-meta';
import { LpPersonalModel } from './personal-model';
import { LpRegistration } from './registration';
import { LpThirdParty } from './third-party';
import { Type } from 'class-transformer';
import { LpCompany } from './company';
import { LpLocation } from './location';

export class LpEquipment extends LpMeta {
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('string')
    public serialNumber: String;
    @propertyMeta('number')
    public mileageOldValue: Number;
    @propertyMeta('number')
    public mileageOldValue2: Number;
    @propertyMeta('number')
    public mileageValue: Number;
    @propertyMeta('number')
    public mileageValue2: Number;
    @propertyMeta('date')
    public mileageDate: Date;
    @propertyMeta('date')
    public mileageDate2: Date;
    @propertyMeta('date')
    public dateIn: Date;
    @propertyMeta('date')
    public dateOut: Date;
    @propertyMeta('string')
    public color: String;
    @Type(() => FtMeta)
    public type: FtMeta = new FtMeta();
    @Type(() => LpRegistration)
    public registration: LpRegistration = new LpRegistration();
    @Type(() => LpPersonalModel)
    public personalModel: LpPersonalModel = new LpPersonalModel();
    @propertyMeta('string')
    public costCenter2: String;
    @propertyMeta('string')
    public costCenter1: String;
    @propertyMeta('string')
    public costCenter3: String;
    @propertyMeta('string')
    public lastMovement: String;
    @propertyMeta('number')
    public longitude: Number;
    @propertyMeta('number')
    public latitude: Number;
    @propertyMeta('date')
    public positionDate: Date;
    @propertyMeta('boolean')
    public isInsured: Boolean;
    @propertyMeta('date')
    public lastMcpeDate: Date;
    @propertyMeta('boolean')
    public isMCPE: Boolean;
    @propertyMeta('date')
    public fabricationDate: Date;
    @propertyMeta('boolean')
    public isBuyBack: Boolean;
    @propertyMeta('boolean')
    public isAxlTaxEligible: Boolean;
    @propertyMeta('boolean')
    public isCarStickerEligible: Boolean;
    @propertyMeta('date')
    public firstRegistrationDate: Date;
    @propertyMeta('boolean')
    public isPlanned: Boolean;
    @propertyMeta('boolean')
    public isActive: Boolean;
    @propertyMeta('string')
    public registrationOwnerDescription: String;
    @propertyMeta('string')
    public keyword: String;
    @propertyMeta('number')
    public maxCounter: Number;
    @propertyMeta('date')
    public sellingDate: Date;
    @propertyMeta('number')
    public sellingAmount: Number;
    @propertyMeta('number')
    public purchaseInvoiceNumber: Number;
    @propertyMeta('date')
    public purchaseDate: Date;
    @propertyMeta('number')
    public purchaseAmountVAT: Number;
    @propertyMeta('number')
    public purchaseAmountHT: Number;
    @propertyMeta('number')
    public detentionDuration: Number;
    @propertyMeta('date')
    public previsionnalDateIn: Date;
    @propertyMeta('date')
    public previsionnalDateOut: Date;
    @Type(() => FtMeta)
    public analytic: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public position: FtMeta = new FtMeta();
    @Type(() => LpThirdParty)
    public owner: LpThirdParty = new LpThirdParty();
    @Type(() => LpThirdParty)
    public provider: LpThirdParty = new LpThirdParty();
    @Type(() => LpThirdParty)
    public financer: LpThirdParty = new LpThirdParty();
    @Type(() => LpThirdParty)
    public registrationOwner: LpThirdParty = new LpThirdParty();
    @Type(() => FtMeta)
    public model: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public purchaseType: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public propertyType: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public patternOut: FtMeta = new FtMeta();
    @propertyMeta('string')
    public registrationNumber: String;
    @propertyMeta('string')
    public department: String;
    @propertyMeta('string')
    public category: String;
    @Type(() => LpCompany)
    public company: LpCompany = new LpCompany();
    @Type(() => LpLocation)
    public location: LpLocation = new LpLocation();

    protected _unoptimizedKeys: String[] = ['personalModel', 'registration'];
}
