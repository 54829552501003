import { Injectable} from '@angular/core';
import { AlocproProvider } from './alocpro.provider';
import { LpFullCompany } from 'app/meta/fullCompany';

const IHUBCHECK_PLUS: string = 'ihubcheckplus'

@Injectable({
  providedIn: 'root'
})
export class IHubCheckPlusProvider {

  constructor(
    private alocproProvider: AlocproProvider,
  ) {}

  public async postCompany(company: LpFullCompany): Promise<string> {
    try {
      return await this.alocproProvider.post(IHUBCHECK_PLUS, company);
    } catch (error) {
      throw error;
    }
  }

}
