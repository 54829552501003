import { Component, Input, ViewEncapsulation, OnInit, OnDestroy, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { UserService } from './services/user.service';
import { UiSyncService } from './services/ui-sync.service';
import { Subscription } from 'rxjs';
import { RouterService, LOGIN, ROOT } from './services/router.service';
import { SoftwareUpdateService } from './services/software-update.service';
import { DOCUMENT } from '@angular/common';
import { Util } from 'app/statics/utils';
import { HostListenerService } from './services/host-listener.service';
import { ConfigService } from './services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { FormStackService } from './services/form-stack.service';

@Component({
  selector: 'lp-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {

  @Input() public className: string;
  public userConnected: Boolean;
  private subscription: Subscription;
  private subscriptionTheme: Subscription;

  // TODO SUB
  constructor(
    public formStackService: FormStackService,
    private uiSyncService: UiSyncService,
    private userService: UserService,
    private router: RouterService,
    private softwareUpdateService: SoftwareUpdateService,
    private hostListenerService : HostListenerService,
    private location: Location,
    private configService: ConfigService,
    private translate: TranslateService,  
    @Inject(DOCUMENT) private document: Document) {
      this.hostListenerService.loadEvent();
      this.translate.get('environment.DefaultIntl.rangeFromLabel').subscribe(async (value: string) => {
        environment.DefaultIntl.rangeFromLabel = value;
      });
      this.translate.get('environment.DefaultIntl.rangeToLabel').subscribe(async (value: string) => {
        environment.DefaultIntl.rangeToLabel = value;
      });
    }

  public ngOnInit(): void {
    this.subscription = this.userService.userConnectedEvtEmitter.subscribe((connected) => {
      if ( connected) {
        this.userConnected = true;
      } else {
        this.userConnected = false;
      }
    });

    this.subscriptionTheme = this.uiSyncService.changeThemeEvtEmitter.subscribe((theme) => {
      this.document.body.classList.value = theme;
    });

    let theme: String = this.configService.get('themes')[0].name;
    let primaryColor: String = this.configService.get('themes')[0].primaryColor;
    if (!this.userService.getCurrentUser()) {
      // Si il n'y a pas du'tilisateur courant, on regirige l'utilisateur vers la page de login
      if (this.softwareUpdateService.maintenance !== true) {
        
        if (this.location.path()  !== `${ROOT}${LOGIN}`) {
          this.userService.urlReferer = this.location.path();
        }
        this.router.navigate(LOGIN);
      }
      this.userConnected = false;
    } else {
      this.userConnected = true;
      if (this.userService.getCurrentUser().preference !== undefined &&
      this.userService.getCurrentUser().preference.defaultTheme !== null) {
        if (!Util.isNullOrUndefined(this.userService.getCurrentUser().preference.defaultTheme)) {
          theme = this.userService.getCurrentUser().preference.defaultTheme.toString();
          // todo => prise en compte de la couleur principale. 
        }
      }
    }
    this.document.body.classList.add(theme.toString());
    this.uiSyncService.primaryColor = primaryColor.toString();
    this.softwareUpdateService.initApplicationUpdating();
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if ( this.subscriptionTheme ) {
      this.subscriptionTheme.unsubscribe();
    }
  }
}
