import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, LOCATION_SECTOR } from '../detail-forms.component';
import { LpLocationsSectors } from 'app/meta/locations-sectors';

@Component({
  selector: 'lp-locations-sector',
  templateUrl: './location-sector.component.html'
})
export class LocationSectorsComponent extends DetailFormsComponent implements OnInit {

  public data: LpLocationsSectors = null;

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(LOCATION_SECTOR);
    this.initUrl();
    // this.init();
  }

    public init(): void {
    this.data.assign(this.formStackService.currentDetailLineData);
  }

}
