import { Injectable } from '@angular/core';
import { RepositoryProvider } from './repository.provider'
import { ModalService } from 'app/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { LpLinks } from 'app/meta/links';
import { LpMeta } from 'app/meta/meta';

const LINKS: string = 'links';

@Injectable({
    providedIn: 'root'
})

export class LinksProvider {

    constructor(
        private repositoryProvider: RepositoryProvider,
        private translate: TranslateService,
        private modalService: ModalService
        ) {};
    

        public async getLinksList(): Promise<LpLinks[]> {
            return await this.repositoryProvider.get(LINKS, null, null, true);
        }

        public async getLinksById(id: string): Promise<LpLinks> {
            return await this.repositoryProvider.get(`${LINKS}/${id}`, null, null, true);
        }

        public async putLinksById(link: LpLinks, id: string): Promise<void> {
            return await this.repositoryProvider.put(`${LINKS}/${id}`, null, link).then(() => {
                this.modalService.success(this.translate.instant('general.modalService.successNews'),
                  this.translate.instant('general.modalService.success'));
              }).catch((error) => {
                this.modalService.error(this.translate.instant('general.modalService.errorNews'),
                  this.translate.instant('general.modalService.erreur'));
              });;
        }

        public async postLinks(link: LpLinks): Promise<LpLinks> {
            return await this.repositoryProvider.post(LINKS, link);
        }

        public async deleteLinksById(link: LpLinks): Promise<void> {
            return await this.repositoryProvider.delete(LINKS, link as any as LpMeta);
        }

}
