import { Component, Output, EventEmitter, Input, OnInit, ViewChild, ElementRef, AfterViewInit, OnChanges } from '@angular/core';
import { Util } from 'app/statics/utils';
import { ZoomService } from 'app/services/zoom.service';
import { UserService } from 'app/services/user.service';
import { ConfigService } from 'app/services/config.service';

@Component({
  selector: 'lp-footer',
  templateUrl: './lp-footer.component.html',
  host: {
    '(window:resize)': 'onResize($event)'
  }
})
export class LpFooterComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() public allLines: Boolean;
  @Input() public dataCount: number;
  @Input() public currentPage: number = 1;
  @Input() public linesPerPage: String;
  @Input() public exportButton: Boolean = false;
  @Input() public zoomId: String;
  @Input() public guid: string;
  @Input() public showAddRow: Boolean = false;
  // Pour rafraichir la largeur du footer lorsque l'on modifie le dashboard.
  @Input() public refreshWidth: Number;
  public availablesLinesPerPage: Array<Object> = new Array<Object>();
  public smallWidth: Boolean;
  @ViewChild('footer') public footer: ElementRef;
  @ViewChild('pager') public pager: ElementRef;
  // fonction lors  du change de page
  @Output() private changePage: EventEmitter<any> = new EventEmitter();
  @Output() private export: EventEmitter<any> = new EventEmitter();
  @Output() private addRow: EventEmitter<any> = new EventEmitter();

  constructor(
    private el: ElementRef,
    private zoomService: ZoomService,
    private userService: UserService,
    private configService: ConfigService) {
  }

  public ngOnInit(): void {
    this.chargeAllLinesOptions();
  }

  public ngAfterViewInit(): void {
    this.setFooterLabel();
  }

  public onResize(event: any): void {
    this.setFooterLabel();
  }

  public ngOnChanges(event: any): void {
    this.setFooterLabel();
  }

  public changePageEmit(event?: any): void {
    if (Util.isNullOrUndefined(event)) {
      this.currentPage = 1;
    } else {
      this.currentPage = event.page;
    }
    this.changePage.emit({currentPage: this.currentPage, linesPerPage: this.linesPerPage});
  }

  public changeLinesPerpage(): void {
    this.changePageEmit();
   if (!Util.isNullOrUndefined(this.zoomId)) {
      this.zoomService.getConfig(this.guid).linesPerPage = this.linesPerPage;
    }
  }

  public exportEmit(): void {
    this.export.emit();
  }

  public addRowEmit(): void {
    this.addRow.emit();
  }

  private chargeAllLinesOptions(): void {
    if ( this.allLines && this.allLines === true && this.userService.isAdmin()) {
      let tempAvailabilities: Array<Object> = new Array<Object>();
      tempAvailabilities = this.configService.get('allLinesPerPage');
      this.availablesLinesPerPage = tempAvailabilities;
    } else {
      this.availablesLinesPerPage = this.configService.get('availablesLinesPerPage');
    }
  }

  private setFooterLabel(): void {
    // data pager (300px) + strandard left blocs (310px) = footer minwidth (610)
    if (this.el.nativeElement.offsetWidth < 610 ) {
      this.smallWidth = true;
    } else {
      this.smallWidth = false;
    }
  }
}
