import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, HISTORICAL_MILEAGE } from '../detail-forms.component';
import { LpHistoricalMileage } from 'app/meta/historical-mileage';

@Component({
  selector: 'lp-historical-mileages',
  templateUrl: './historical-mileages.component.html'
})
export class HistoricalMileagesComponent extends DetailFormsComponent implements OnInit {

  public data: LpHistoricalMileage = null;

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(HISTORICAL_MILEAGE);
    this.initUrl();
    this.init();
  }

  public init(): void {
    // this.data.assign(this.formStackService.currentDetailLineData);
  }

}
