
import { ZoomConfig } from './zoom-metada';
import { DetailEditForm } from './detail-edit-form';
import { LpMeta } from 'app/meta/meta';
import { InputField } from './input-field';
import { Util } from 'app/statics/utils';

export class ApplicationItemDetail {
    public _ready: Boolean = false;
    public _count: number;
    public id: number;
    /**
 * Clé de traduction contenant seulement le dernière niveau.
 * Les autres niveau étant "applicationItemDetail" puis l'Id de la classe.
 * Exemple: pour la class contenant l'id 'contractInvoiceLines', si la propriété title contient 'monTitre'
 * la clé finale sera "applicationItemDetail.contractInvoiceLines.monTitre"
 */
    public title: String;

    /**
     * True si on renvoi vers un autre écran, False si on affiche un formulaire sous le tableau.
     */
    public enable: Boolean;
    public externalAddForm: Boolean;
    public externalAddFormPath: String;
    public externalModifyForm: Boolean;
    public externalFilledForm: Boolean = false;
    public externalModifyFormPath: String;
    public add: Boolean;
    public modify: Boolean;
    public delete: Boolean;
    public bulk: Boolean;
    public path: String;
    public pathOnRepository: Boolean = false;
    public pagination: Boolean;
    public reloadAfterSubmit?: boolean = false;
    public hasMetadata: Boolean;
    public icon: string
    public label: string
    public active: Boolean
    public availableEval: string
    public data: LpMeta;
    public childZoom: ZoomConfig[];
    public editForm: DetailEditForm;
    /**
     * Si externalEditForm = false; alors on doit indiquer l'ID du composant à afficher pour le formulaire.
     *
     */
    public editFormId: String;
    public originDetailId?: number;

    /**
     * Si externalEditForm = false; Afficher le formulaire d'édition d'un nouvel item en même temps que la liste
     *
     */
    public editFormActive: Boolean = false;
    public useJsonEditForm: Boolean;

    /**
     * Affichage d'une ligne de récapitulatif en haut ou en bas (top/bottom).
     */
    public TableSummaryRow: Boolean;
    public TableSummaryPosition: String;

    /**
     * Tableau comportant les infos des collones  à afficher.
     */
    public columns: ApplicationItemDetailColumn[];

    public key: String;
    public summaryEval: string;
    public reportObject: ReportObject;
    public exportable: boolean = false;
    public isCustom: boolean;
    public queryParams?: Array<QueryParams>;
    public cellStyles?: CellStyle[];
    public cellFunctions?: CellFunction[];
    public focusOutAction?: string;
    public saveByButton?: boolean = false;
    public detectChanges?: boolean = true;
    public hidden?: boolean = false;

    public static buildQueryParams(requestedMethod: string, details: ApplicationItemDetail):  Map<string, string> {
        let map: Map<string, string> = new Map();
        if(!Util.isNullOrUndefined(details.queryParams) && !Util.isNullOrUndefined(requestedMethod)){
            for(let q of details.queryParams){
                if(!Util.isNullOrUndefined(q.key) && !Util.isNullOrUndefined(q.value) && q.method === requestedMethod || q.method === '*'){
                    map.set(q.key, q.value);
                }
            }
        }
        return map
    }
}

export class ApplicationItemDetailColumn {
    /**
     * Comme pour la classe ApplicationItemDetail, ne saisir que le dernier niveau pour former une clé du style:
     * "applicationItemDetail.contractInvoiceLines.monTitre"
     */
    public title: String;
    public name: String;
    public prop: String;
    public type: Number = 1;
    public width: Number;
    public summaryFunc: any;
    public modify: Boolean;
    public modifyAccordingProperty?: string;
    public active: Boolean = true;
    public amountCanBeUpdated?: Boolean = true 
    public quantityCanBeUpdated?: Boolean = true 

    /* pour ngx-datatable */
    public resizeable: Boolean = false;
    public sortable: Boolean = true;
    public draggable: Boolean = false;
    public canAutoResize: Boolean = true;
    public minWidth: Number;
    public isDisplayed: boolean = true;
    public inputField: InputField;
    public classes?: String;
    public duplicate?: boolean;
    public evalFunction?: string;
}

export class ReportObject {
    public reportId: string;
    public reportKyData: string;
    public reportTitle: string;
    public reportDefaultMailAddressEval: string;
}

export class QueryParams {
    method: string;
    key: string;
    value: string;
}

export class CellStyle {
    condition: string;
    cellStyle: string;
    componentStyle: string;
}

export class CellFunction {
    function: string;
}

