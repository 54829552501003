import { LpMovement } from '../movement';
import { LpShortTermContract } from '../short-term-contract';
import { SchedulerEquipment } from './scheduler-equipment';
import { LpEquipment } from '../equipment';
import { LpMeta } from '../meta';
import { Util } from 'app/statics/utils';


export class SchedulerMovement  {

    public displayTextForScheduler: String;

    public id: String;
    public details: String;
    public documentNumber: String;
    public undefinedReturnDate: Boolean;
    public startDate: string;
    public startMileage: Number;
    public startFuel: String;
    public returnDate: string;
    public returnMileage: Number;
    public returnFuel: String
    // TODO basicMeta
    public position: any;
    public isClosed: Boolean;
    // TODO basicMeta
    public customer:  any;
    // TODO basicMeta
    public driver: any;
    public relatedObject: String;

    public getFTMetaFromSchedulerMovement(equipment: SchedulerEquipment, isCreation: boolean): LpMeta {
        let obj: any;

        if (Util.isNullOrUndefined(this.relatedObject) || this.relatedObject === 'movements') {
            obj = new LpMovement();
            obj.equipment = this.getEquipmentFromPlanning(equipment);
            obj.details = this.details;
            obj.id = this.id;
            obj.returnDate = this.returnDate;
        } else {
            obj = new LpShortTermContract();
            obj.movement = new LpMovement();
            obj.movement.equipment = this.getEquipmentFromPlanning(equipment);
            obj.details = this.relatedObject;
            obj.id = this.relatedObject.split('/')[1];
            obj.plannedReturnDate = this.returnDate;
            obj.status = new LpMeta();
            obj.status.id = '2';
        }
        if (isCreation) {
            obj.category = equipment.category;
        }
        obj.wording = '';
        obj.startDate = this.startDate;

        return obj;
    }

    public getEquipmentFromPlanning(equipment: SchedulerEquipment): LpEquipment {
        const eq: LpEquipment = new LpEquipment();
        eq.id = equipment.id;
        eq.details = equipment.details
        eq.registration = equipment.registration;
        eq.wording = equipment.label;
        eq.category = equipment.category;
        return eq;
    }
}
