import { SchedulerFilter } from './scheduler-filter';
import { SchedulerSpecialDay, SchedulerEquipmentLabel } from './scheduler-data';
import { Util } from 'app/statics/utils';

export class SchedulerConfig {
    public isCustom?: boolean;
    public specialDay: Map<String, SchedulerSpecialDay>;
    public weekend: Map<String, boolean>;
    public weekendColor: String;
    public affichage: String;
    public lineStyleColor: Map<String, String>;
    public isCollisionAllowed: boolean;
    public eventColor: Map<String, String>;
    public equipmentTextAlign: String;
    public equipmentLabels: Array<SchedulerEquipmentLabel>;
    public eventLabels: Array<any>;
    public dx: Number;
    public dy: Number;
    public event_dy: Number;
    public isNowDisplayed: Boolean;
    public showEquipmentAvailable: Boolean;
    public availableEquimentColor: String;
    public eval: any;
    public displayReturnDelayed: boolean
    public filter: SchedulerFilter;
    public bufferBefore: number;
    public showUnaffectedMovements: Boolean;
    public firstHour?: String;
    public lastHour?: String
    public id: string;

    constructor() {
        this.lineStyleColor = new Map();
        this.specialDay = new Map();
        this.weekend = new Map();
        this.eventColor = new Map();
        this.equipmentLabels = [];
        this.eventLabels = [];
    }

    public deserializeJSONConfig(JSON: any): void {
        // return new Promise<void>((resolve, reject) => {
            // this.lineStyleColor = this.JSONtoStrStrMap(JSON.lineColor);
            this.specialDay = this.getSpecialDaysFromConf(JSON.specialDay);
            this.weekend = this.JSONtoStrBooMap(JSON.weekEnd);
            this.eventColor = this.JSONtoStrStrMap(JSON.eventColor);
            // this.filter = this.JSONtoFilter(JSON.filter);
            this.eventLabels = JSON.eventLabels;
            this.weekendColor = JSON.weekendColor;
            this.isCollisionAllowed = JSON.isCollisionAllowed;
            this.showUnaffectedMovements = JSON.showUnaffectedMovements;
            this.equipmentTextAlign = JSON.equipmentTextAlign;
            this.equipmentLabels = JSON.equipmentLabels;
            this.isNowDisplayed = JSON.isNowDisplayed;
            this.dx = JSON.dx;
            this.dy = JSON.dy;
            this.event_dy = JSON.event_dy;
            this.availableEquimentColor = JSON.availableEquimentColor;
            this.showEquipmentAvailable = true; // JSON.showEquipmentAvailable;
            this.eval = JSON.eval;
            this.displayReturnDelayed = JSON.displayReturnDelayed;
            this.bufferBefore = JSON.bufferBefore;
            this.firstHour = JSON.firstHour
            this.lastHour = JSON.lastHour
        // });
    }

    public deserializeJSONFilter(JSON: any): void {
        // return new Promise<void>((resolve, reject) => {
            this.filter = JSON.filter;
        // });
    }

    public serializeJSON(id: string): String {
        let jsonString: string = '{';
        jsonString += id
        jsonString += '"config" : {';
        let i: number = 1;
        if (this.specialDay.size > 0) {
            let specialDay: string = '"specialDay" : [';
            this.specialDay.forEach((value: SchedulerSpecialDay, key: String) => {
                if (i === this.specialDay.size) {
                    specialDay +=  '{"' + key + '":' + JSON.stringify(value) + '}';
                } else {
                    specialDay +=  '{"' + key + '":' + JSON.stringify(value) + '},';
                }
                i++
            })
            specialDay += '],';
            jsonString += specialDay;
        }
        if (this.weekend.size > 0) {
            let weekEnd: string = '"weekEnd" : [';
            i = 1;
            this.weekend.forEach((value: boolean, key: String) => {
                if (i === this.weekend.size) {
                    weekEnd +=  '{"' + key + '":' + JSON.stringify(value) + '}';
                } else {
                    weekEnd +=  '{"' + key + '":' + JSON.stringify(value) + '},';
                }
                i++
            })
            weekEnd += '],';
            jsonString += weekEnd;
        }
        if (!Util.isNullOrUndefined(this.weekendColor)) {
            jsonString += '"weekendColor":' + JSON.stringify(this.weekendColor) + ',';
        }
        if (this.eventLabels.length > 0) {
            jsonString += '"eventLabels":' + JSON.stringify(this.eventLabels) + ',';
        }
        if (this.eventColor.size > 0) {
            let eventColor: string = '"eventColor" : [';
            i = 1;
            this.eventColor.forEach((value: String, key: String) => {
                if (i === this.eventColor.size) {
                    eventColor +=  '{"' + key + '":' + JSON.stringify(value) + '}';
                } else {
                    eventColor +=  '{"' + key + '":' + JSON.stringify(value) + '},';
                }
                i++
            })
            eventColor += '],';
            jsonString += eventColor;
        }
        if (this.equipmentLabels.length > 0) {
            jsonString += '"equipmentLabels":' + JSON.stringify(this.equipmentLabels) + ',';
        }
        if (!Util.isNullOrUndefined(this.eval)) {
            jsonString += '"eval":' + JSON.stringify(this.eval) + ',';
        }
        if (!Util.isNullOrUndefined(this.showEquipmentAvailable)) {
            jsonString += '"showEquipmentAvailable":' + JSON.stringify(this.showEquipmentAvailable) + ',';
        }
        if (!Util.isNullOrUndefined(this.availableEquimentColor)) {
            jsonString += '"availableEquimentColor":' + JSON.stringify(this.availableEquimentColor) + ',';
        }
        if (!Util.isNullOrUndefined(this.displayReturnDelayed)) {
            jsonString += '"displayReturnDelayed":' + JSON.stringify(this.displayReturnDelayed) + ',';
        }
        if (!Util.isNullOrUndefined(this.isCollisionAllowed)) {
            jsonString += '"isCollisionAllowed":' + JSON.stringify(this.isCollisionAllowed) + ',';
        }
        if (!Util.isNullOrUndefined(this.showUnaffectedMovements)) {
            jsonString += '"showUnaffectedMovements":' + JSON.stringify(this.showUnaffectedMovements) + ',';
        }
        if (!Util.isNullOrUndefined(this.isNowDisplayed)) {
            jsonString += '"isNowDisplayed":' + JSON.stringify(this.isNowDisplayed) + ',';
        }
        if (!Util.isNullOrUndefined(this.dx)) {
            jsonString += '"dx":' + JSON.stringify(this.dx) + ',';
        }
        if (!Util.isNullOrUndefined(this.dy)) {
            jsonString += '"dy":' + JSON.stringify(this.dy) + ',';
        }
        if (!Util.isNullOrUndefined(this.event_dy)) {
            jsonString += '"event_dy":' + JSON.stringify(this.event_dy) + ',';
        }
        if (!Util.isNullOrUndefined(this.firstHour)) {
            jsonString += '"firstHour":' + JSON.stringify(this.firstHour) + ',';
        }
        if (!Util.isNullOrUndefined(this.lastHour)) {
            jsonString += '"lastHour":' + JSON.stringify(this.lastHour) + ',';
        }
        if (!Util.isNullOrUndefined(this.bufferBefore)) {
            jsonString += '"bufferBefore":' + JSON.stringify(this.bufferBefore);
        }
        /* if (!Util.isNullOrUndefined(this.filter)) {
            jsonString += '"filter":{' + this.filter.stringifyFilter() + '}';
        } */
        jsonString += '}}';
        return jsonString
    }

    /*
    private fillIdentification(user?: string, profile?: string, location?: string, company?: string) {
        let id = '';
        if (!Util.isNullOrUndefined(user)) {
            id += '"user": "' + user + '",';
        } else {
            id += '"user": "*",';
        }
        if (!Util.isNullOrUndefined(profile)) {
            id += '"profile": "' + profile + '",';
        } else {
            id += '"profile": "*",';
        }
        if (!Util.isNullOrUndefined(location)) {
            id += '"location": "' + location + '",';
        } else {
            id += '"location": "*",';
        }
        if (!Util.isNullOrUndefined(company)) {
            id += '"company": "' + company + '",';
        } else {
            id += '"company": "*",';
        }
        return id;
    }
    */

    private getSpecialDaysFromConf(jsonObj: any): Map<String, any> {
        let map: Map<String, any> = new Map();
        for (let day of jsonObj) {
            for (let o of Object.keys(day)) {
                map.set(o.toString(), {end_date: day[o].end_date.substr(0,  (day[o].end_date.length - 1)),
                    start_date: day[o].start_date.substr(0, (day[o].start_date.length - 1 )), zones: day[o].zones, css: day[o].css} );
            }
        }
        return map
    }

    private JSONtoStrStrMap(jsonObject: any): Map<String, String> {
        let map: Map<String, String> = new Map();
        for (let o of jsonObject) {
            for (let k of Object.keys(o)) {
                map.set(k, o[k]);
            }
        }
        return map;
    }


    private JSONtoStrBooMap(jsonObject: any): Map<String, boolean> {
        let map: Map<String, boolean> = new Map();
        for (let o of jsonObject) {
            for (let k of Object.keys(o)) {
                map.set(k, !!o[k]);
            }
        }
        return map;
    }

    /*private JSONtoFilter(jsonObject: any): SchedulerFilter {
        if (!Util.isNullOrUndefined(jsonObject.administrativeLocation)) {
            this.filter.administrativeLocation.id = jsonObject.administrativeLocation.id;
            this.filter.administrativeLocation.wording = jsonObject.administrativeLocation.wording;
        }
        if (!Util.isNullOrUndefined(jsonObject.actualLocation)) {
            this.filter.actualLocation.id = jsonObject.actualLocation.id;
            this.filter.actualLocation.wording = jsonObject.actualLocation.wording;
        }
        if (!Util.isNullOrUndefined(jsonObject.registration)) {
            this.filter.registration.id = jsonObject.registration.id;
            this.filter.registration.registration = jsonObject.registration.registration;
        }
        if (!Util.isNullOrUndefined(jsonObject.category)) {
            this.filter.category.id = jsonObject.category.id;
            this.filter.category.wording = jsonObject.category.registration;
        }
        if (!Util.isNullOrUndefined(jsonObject.brand)) {
            this.filter.brand.id = jsonObject.brand.id;
            this.filter.brand.wording = jsonObject.brand.registration;
        }
        if (!Util.isNullOrUndefined(jsonObject.type)) {
            this.filter.type.id = jsonObject.type.id;
            this.filter.type.wording = jsonObject.type.registration;
        }
        if (!Util.isNullOrUndefined(jsonObject.family)) {
            this.filter.family.id = jsonObject.family.id;
            this.filter.family.wording = jsonObject.family.registration;
        }
        if (!Util.isNullOrUndefined(jsonObject.model)) {
            this.filter.model.id = jsonObject.model.id;
            this.filter.model.wording = jsonObject.model.registration;
        }
        if (!Util.isNullOrUndefined(jsonObject.owner)) {
            this.filter.owner.id = jsonObject.owner.id;
            this.filter.owner.firstName = jsonObject.owner.firstName;
            this.filter.owner.lastName = jsonObject.owner.lastName;
        }
        if (!Util.isNullOrUndefined(jsonObject.company)) {
            this.filter.company.id = jsonObject.company.id;
            this.filter.company.corporateName = jsonObject.company.corporateName;
        }
        if (!Util.isNullOrUndefined(jsonObject.company)) {
            this.filter.company.id = jsonObject.company.id;
            this.filter.company.corporateName = jsonObject.company.corporateName;
        }
        this.filter.isActive = true;
        return this.filter;
    }*/
}


/*    public administrativeLocation: LpLocation;
    public actualLocation: LpLocation;
    public registration: LpRegistration;
    public category: FtMeta;
    public brand: FtMeta;
    public type: FtMeta;
    public family: FtMeta;
    public model: LpMetaModel;
    public owner: LpThirdParty;
    public company: LpCompany;
    public isActive: Boolean;
    public startDate: String;
    public endDate: String;
*/
