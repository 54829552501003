import { LpMeta } from '../meta';

export class LpInspectionPOST extends LpMeta {
    public relativeKey: String;
    public field: String;
    public object: Object;
    public kind: Object;


    public constructor(relativeKey: String, field: String, object: String, kind: String) {
        super();
        this.relativeKey = relativeKey;
        this.field = field;
        this.object = {'id': object};
        this.kind = {'id': 'K575TI8OPE-' + kind};
        this.id = undefined;
        this.details = undefined;
        this._readOnly = undefined;
    }
}
