import { Component, Input } from '@angular/core';

@Component({
  selector: 'lp-subgroup',
  templateUrl: './lp-subgroup.component.html'
})
export class LpSubgroupComponent {

  @Input() public label: String;
  @Input() public bordertop: Boolean = false;
  @Input() public borderbottom: Boolean = false;

  constructor() { }
}
