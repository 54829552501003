import { Injectable } from "@angular/core";
import { LpSpoolerEditionStatus } from "app/meta/spooler-edition-status";
import { PaginatedData } from "app/models/paginated-data";
import { ReportsProvider } from "app/providers/reports.provider";
import { environment } from "environments/environment";
import { interval, Subscription } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
export class ReportSpoolerService {
    public waitingList: LpSpoolerEditionStatus[];
    public isRunning: boolean = false;
    private subscribeTest: Subscription;
    public showSpoolerList: Boolean = false;

    constructor(private reportProvider: ReportsProvider) { 
      if( !environment.production) {
       this.runApplicationCheck();
      }
    }

    /** 
     * Permet de lancer le test vers le backend 
     * A utiliser lorsqu'on lance une impression.
     */
    public startTest() {
      this.isRunning = true;          
      const source = interval(1000);
      if (this.subscribeTest) {
        this.subscribeTest.unsubscribe();
      }
      this.subscribeTest = source.subscribe(val => this.runApplicationCheck());
      setTimeout(() => {
        this.stopTest();
      }, 10000);
    }


    /** 
     * Permet d'arrêter le test vers le backend 
     * A utiliser lorsqu'aucune édition est en attente.
     */
    public stopTest() {
      this.isRunning = false;
      this.subscribeTest.unsubscribe();
    } 




    /** 
     * Permet d'appeler le provider pour lancer l'appel vers le backend.
     * A utiliser lorsqu'aucune édition est en attente.
     */
    public async runApplicationCheck() {
      const paginatedData: PaginatedData = await this.reportProvider.getReportsSpoolers();
      this.waitingList = paginatedData.body as LpSpoolerEditionStatus[];
    }

    /** 
     * Permet d'appeler le provider pour lancer l'appel vers le backend.
     * A utiliser pour télécharger une édition.
     */
     public async download(ky: string): Promise<ArrayBuffer> {
      //return await this.reportProvider.download(ky);
      return await this.reportProvider.preview(ky);
    }

    public async clearList(): Promise<void> {
      await this.reportProvider.clearSpoolerList();
    }
}