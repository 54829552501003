import { LpMeta } from './meta';
import { Type } from 'class-transformer';
import { LpPricing } from './pricing';
import { LpThirdParty } from './third-party';
import { LpCompany } from './company';
import { FtMeta } from './ft-meta';
import { LpLocation } from './location';
import { LpModel } from './model';
import { LpService } from './service';

export class LpPricingDetailsFilters extends LpMeta {

     @Type(() => LpPricing)
    public pricing: LpPricing = new LpPricing();
    @Type(() => LpMeta)
    public pricingTime: LpMeta = new LpMeta();
    @Type(() => LpThirdParty)
    public thirdParty: LpThirdParty = new LpThirdParty();
    @Type(() => FtMeta)
    public country: FtMeta = new FtMeta();
    @Type(() => LpCompany)
    public company: LpCompany = new LpCompany();
    @Type(() => LpLocation)
    public location: LpLocation = new LpLocation();
    // secteur à ajouter
    @Type(() => LpModel)
    public model: LpModel = new LpModel();
    @Type(() => FtMeta)
    public equipmentType: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public category: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public acriss: FtMeta = new FtMeta();
    // tarif groupe à ajouter
    @Type(() => LpService)
    public service: LpService = new LpService();

    constructor(pricing: LpPricing, pricingTime: LpMeta, thirdParty: LpThirdParty, country: FtMeta,
        company: LpCompany, location: LpLocation, model: LpModel, equipmentType: FtMeta, category: FtMeta,
        acriss: FtMeta, service: LpService) {
            super();
            this.pricing = pricing
            this.pricingTime = pricingTime
            this.thirdParty = thirdParty
            this.country = country
            this.company = company
            this.location = location
            this.model = model
            this.equipmentType = equipmentType
            this.category = category
            this.acriss = acriss
            this.service = service
    }
}
