import { Injectable } from '@angular/core';
import { AlocproProvider } from './alocpro.provider';
import { LpReport } from 'app/meta/report';
import { HttpParams } from '@angular/common/http';
import { RepositoryProvider } from '../providers/repository.provider';
import { LpReportParameters, LpReportListItem } from 'app/meta/report-parameters';
import { DatetimeService } from '../services/datetime.service';
import { ZoomMetaData } from 'app/models/zoom-metada';
import { Util } from 'app/statics/utils';
import { PaginatedData } from 'app/models/paginated-data';
import { ConfigService } from 'app/services/config.service';

const PATH: string = 'reports';
const VERB_REPORTS: string = 'reports';
const VERB_SPOOLERS: string = 'spoolers';
const VERB_PARAMETERS: string = 'parameters';
const VERB_EXECUTE: string = 'execute';
const LIST: string = 'list';
const SPOOLER: string = 'spoolers';
const PARAMETERS: string = 'parameters';
const PREVIEW: string = 'previews';
export const ZOOMID: string = 'report';
export const ID_COMPONENT: string = 'zooms';


// @Directive()
@Injectable({
    providedIn: 'root'
  })
export class ReportsProvider {

    public httpParams: HttpParams;
    public srcPdfFromPrintReport: ArrayBuffer;

    private mapList: Map<string, LpReport> = new Map();
    private mapParameters: Map<string, LpReportParameters[]> = new Map();
    private mapExecute: Map<string, ArrayBuffer> = new Map();
    private mapMetadata: Map<string, ZoomMetaData> = new Map();
    private doCacheList: boolean = this.configService.get('reportCache').list;
    private doCacheParameters: boolean = this.configService.get('reportCache').parameters;
    private doCacheExecute: boolean = this.configService.get('reportCache').execute;
    private doCacheMetadata: boolean = this.configService.get('reportCache').metadata;
    private titleFromPrintReport: string;

    constructor(public aloc: AlocproProvider, public repositoryProvider: RepositoryProvider, public datetimeService: DatetimeService,
                private configService: ConfigService) {}

    // TODO REVIEW PROMISE ou AWAIT + try catch ?
    public async getReportList(object?: string): Promise<LpReport> {
        return new Promise<LpReport>((resolve, reject) => {
            this.getListAccordingCache(this.doCacheList, object).then( (reports: LpReport) => {
                resolve(reports)
            });
        });
    }

    public async getParameters(ky: string): Promise<Array<LpReportParameters>> {
        return new Promise<Array<LpReportParameters>>(async(resolve) => {
            await this.getParametersAccordingCache(this.doCacheParameters, ky).then((parameters: Array<LpReportParameters>) => {
                resolve(parameters);
            });
        });
    }

    public async getParametersDataKy(ky: string): Promise<PaginatedData> {
        return new Promise<PaginatedData>(async(resolve) => {
            await this.getParametersData(ky).then((data: PaginatedData) => {
                resolve(data);
            });
        });
    }

    public async executeReport(ky: string, parameters: Array<LpReportParameters>, customerInvoiceKy?: string, isSpoolerMode?: boolean): Promise<ArrayBuffer> {
        try{
            let params: String = this.computeParams(parameters, ky, customerInvoiceKy);
            let binaryReport: ArrayBuffer = await this.getExecuteAccordingCache(this.doCacheExecute, params, ky, isSpoolerMode, true)
            return binaryReport;
        } catch (e) {
            throw e 
        }
    }

    public async executeReportWithQueryParam(
        ky: string, parameters: Array<LpReportParameters>, customerInvoiceKy?: string, mailAddress?: string, isSpoolerMode?: boolean): Promise<ArrayBuffer> {
        return new Promise<ArrayBuffer>(async (resolve) => {
            let params: String = this.computeParams(parameters, ky, customerInvoiceKy);
            params = params + `&sendMail=true&mail=${mailAddress}`;
            await this.getExecuteAccordingCache(this.doCacheExecute, params, ky, isSpoolerMode, true).then((binaryReport: ArrayBuffer) => {
                resolve(binaryReport);
            });
        });
    }

    public loadMetaData(): Promise<ZoomMetaData> {
        return new Promise<any>((resolve) => {
            this.getMetadataAccordingCache(this.doCacheMetadata).then((metadata: ZoomMetaData) => {
                resolve(metadata);
            });
        });
      }

    public setProperty(map: Map<string, string>, key: string, value: any): void {
        map.set(key, value);
    }

    public getProperty(map: Map<string, string>, key: string): any {
        return map.get(key);
    }

    public removeProperty(map: Map<string, string>, key: string): void {
        map.delete(key);
    }

    public set SrcPdfFromPrintReport(value: ArrayBuffer) {
        this.srcPdfFromPrintReport = value;
    }

    public get SrPdfFromPrintReport(): ArrayBuffer {
        return this.srcPdfFromPrintReport;
    }

    public set TitleFromPrintReport(value: string) {
        this.titleFromPrintReport = value;
    }

    public get TitleFromPrintReport(): string {
        return this.titleFromPrintReport;
    }

    public getMetadataAccordingCache(isCached: Boolean): Promise<ZoomMetaData> {
        if (isCached) {
            return new Promise<ZoomMetaData>((resolve) => {
                if ( this.mapMetadata.get(`${ZOOMID}_${ID_COMPONENT}`) === undefined ) {
                    this.repositoryProvider.getZoomMetadata(ZOOMID).then((recordMeta: ZoomMetaData) => {
                        this.mapMetadata.set(`${ZOOMID}_${ID_COMPONENT}`, recordMeta);
                        resolve(recordMeta);
                    });
                } else {
                        resolve(this.mapMetadata.get(`${ZOOMID}_${ID_COMPONENT}`));
                }
        });
        } else {
            return new Promise<ZoomMetaData>((resolve) => {
                this.repositoryProvider.getZoomMetadata(ZOOMID).then((recordMeta: ZoomMetaData) => {
                    resolve(recordMeta);
                });
            });
        }
    }

    public clearCache(): void {
        this.mapExecute.clear();
        this.mapList.clear();
        this.mapMetadata.clear();
        this.mapParameters.clear();
    }

    public async getReportsSpoolers(): Promise<PaginatedData> {
        return new Promise<PaginatedData>(async(resolve) => {
            return await this.aloc.getPaginatedData(`${VERB_REPORTS}/spoolers/me`).then((data: PaginatedData) => {
                resolve(data);
            });
        });
    }

    public async getReportsSpooler(id: string): Promise<PaginatedData> {
        return new Promise<PaginatedData>(async(resolve) => {
            return await this.aloc.getPaginatedData(`${VERB_REPORTS}/spoolers/${id}`).then((data: PaginatedData) => {
                resolve(data);
            });
        });
    }

    public delete(ky: String): Promise<void> {
        return this.aloc.delete('reports/spoolers', ky);    
    }

    public async download(ky: String): Promise<ArrayBuffer> {
        return await this.aloc.getEncodingByPath(`${VERB_REPORTS}/${VERB_SPOOLERS}/${ky}`, true);
    }

    public async preview(ky: String): Promise<ArrayBuffer> {
        return await this.aloc.getEncodingByPath(`${VERB_REPORTS}/${VERB_SPOOLERS}/${ky}/${PREVIEW}`, true);
    }

    public async clearSpoolerList(): Promise<void> {
        return await this.aloc.delete(`${VERB_REPORTS}/${VERB_SPOOLERS}/${PREVIEW}`, null);
    }

    public async clearParametersCache(): Promise<void> {
        this.mapParameters.clear();
    }

    private async getExecuteAccordingCache(isCached: boolean, params: String, ky: String, isSpoolerMode?: boolean, getFullHttpResponse: boolean = false): Promise<any> {
        try { 
            if(Util.isNullOrUndefined(isSpoolerMode) || !isSpoolerMode){
                if (isCached) {
                    if (!Util.isNullOrUndefined(this.mapExecute.get(`${VERB_REPORTS}/${ky}/${VERB_EXECUTE}${params}`))) {
                        return await this.mapExecute.get(`${VERB_REPORTS}/${ky}/${VERB_EXECUTE}${params}`);
                    } else {
                        let report: any =  await this.aloc.getEncodingByPath(`${VERB_REPORTS}/${ky}/${VERB_EXECUTE}${params}`, true, null, getFullHttpResponse)
                        this.mapExecute.set(`${VERB_REPORTS}/${ky}/${VERB_EXECUTE}${params}`, report);
                        return {report: report.body, mode: report.status === 200 ? "report": "spooler"}
                    }
                } else {
                    let report: any = await this.aloc.getEncodingByPath(`${VERB_REPORTS}/${ky}/${VERB_EXECUTE}${params}`, true, null, getFullHttpResponse)
                    return {report: report.body, mode: report.status === 200 ? "report": "spooler"}
                }
            }else {
                await this.aloc.post(`${VERB_REPORTS}/${ky}/${SPOOLER}?key=${params.substring(4 )}`, null)
                return null;
            }

        } catch(e) {
            throw e
        }
    }

    private async getParametersData(ky: String): Promise<PaginatedData> {
        return new Promise<PaginatedData>(async(resolve) => {
            return await this.aloc.getPaginatedData(`${VERB_REPORTS}/${ky}/${VERB_PARAMETERS}`).then((data: PaginatedData) => {
                resolve(data);
            });
        });
    }

    private async getParametersAccordingCache(isCached: boolean, ky: String): Promise<Array<LpReportParameters>> {
        if (isCached) {
            return new Promise<Array<LpReportParameters>>( async(resolve) => {
                if ( this.mapParameters.get(`${PARAMETERS}_${ky}`) === undefined ) {
                    await this.aloc.getPaginatedData(`${VERB_REPORTS}/${ky}/${VERB_PARAMETERS}`).then((data: PaginatedData) => {
                        let arrayOfParameters: LpReportParameters[] = [];
                        if (!Util.isNullOrUndefined(data) && !Util.isNullOrUndefined(data.body)) {
                            let temp: any = data.body;
                            temp.reportParams.forEach((p: LpReportParameters) => {
                                const arrayOfListItem: Array<LpReportListItem> = [];
                                p.listItem.forEach((i: LpReportListItem) => {
                                    const item: LpReportListItem = new LpReportListItem();
                                    Object.assign(item, i);
                                    arrayOfListItem.push(item);
                                });
                                const par: LpReportParameters = new LpReportParameters();
                                Object.assign(par, p);
                                par.listItem = arrayOfListItem;
                                arrayOfParameters.push(par);
                            });
                        }
                        this.mapParameters.set(`${PARAMETERS}_${ky}`, arrayOfParameters);
                        resolve(arrayOfParameters);
                    });
                } else {
                    resolve(this.mapParameters.get(`${PARAMETERS}_${ky}`));
                }
            });
        } else {
            return new Promise<Array<LpReportParameters>>(async(resolve) => {
                return await this.aloc.getPaginatedData(`${VERB_REPORTS}/${ky}/${VERB_PARAMETERS}`).then((data: PaginatedData) => {
                    let arrayOfParameters: LpReportParameters[] = [];
                    if (!Util.isNullOrUndefined(data) && !Util.isNullOrUndefined(data.body)) {
                        let temp: any = data.body;
                        temp.reportParams.forEach((p: LpReportParameters) => {
                            const arrayOfListItem: Array<LpReportListItem> = [];
                            p.listItem.forEach((i: LpReportListItem) => {
                                const item: LpReportListItem = new LpReportListItem();
                                Object.assign(item, i);
                                arrayOfListItem.push(item);
                            });
                            const par: LpReportParameters = new LpReportParameters();
                            Object.assign(par, p);
                            par.listItem = arrayOfListItem;
                            arrayOfParameters.push(par);
                        });
                    }
                    resolve(arrayOfParameters);
                });
            });
        }
    }

    private getListAccordingCache(isCached: boolean, object?: String): Promise<LpReport> {
        if (isCached) {
            return new Promise<LpReport>((resolve, reject) => {
                if ( this.mapList.get(`${LIST}_${object}`) === undefined ) {
                    this.aloc.getPaginatedData(PATH + object).then((data: PaginatedData) => {
                        let temp: any = data.body;
                        this.mapList.set(`${LIST}_${object}`, temp);
                        resolve(temp);
                    });
                } else {
                    resolve(this.mapList.get(`${LIST}_${object}`));
                }
            });
        } else {
            return new Promise<LpReport>((resolve, reject) => {
                this.aloc.getPaginatedData(PATH + object).then((data: PaginatedData) => {
                    let temp: any = data.body;
                    resolve(temp);
                });
            });
        }
    }

    private computeParams(parameters: Array<LpReportParameters>, ky: String, customerInvoiceKy?: String): String {
        let params: string = '?';
        // SI on a un customerInvoiceKy
        if (!Util.isNullOrUndefined(customerInvoiceKy)) {
            ky = customerInvoiceKy
        }
        if (!Util.isNullOrUndefined(parameters)) {
            parameters.forEach((p: LpReportParameters, index: number) => {
                // Si on demande un KY et qu'il y a un params on passe le Ky (donné en parametre d'entré)
                if ( (p.name === 'KY' || p.name === 'Ky' || p.name === 'ky') && parameters.length === 1) {
                    ( customerInvoiceKy ) ? params += p.name + '=' + ky : params += p.name + '=' + p.value;
                } else if (p.type === 'DATE') {
                    params += p.name + '=' + this.datetimeService.formatDateForReportExecute(p.value) + '&';
                } else if (p.type === 'LISTE') {
                    if (p.value !== null) {
                        params += p.name + '=' + p.value.substr(0, 1) + '&'
                    }
                } else if ((p.name === 'KY' || p.name === 'Ky' || p.name === 'ky')) {
                    ( customerInvoiceKy ) ? params += p.name + '=' + ky + '&' : params += p.name + '=' + p.value + '&';
                } else {
                    if ( index <= parameters.length - 1 ) {
                        params += p.name + '=' + p.value + '&';
                    } else {
                        params += p.name + '=' + p.value;
                    }
                }
            });
}
        return params;
    }
}
