import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() { }

  public isImage(encoded: String): Boolean {
    let mime: String = this.base64MimeType(encoded);
    if (mime.search('image/') === 0) {
      return true;
    } else {
      return false;
    }
  }

  public isPdf(encoded: String): Boolean {
    let mime: String = this.base64MimeType(encoded);
    if (mime === 'application/pdf') {
      return true;
    } else {
      return false;
    }
  }

  public isTxt(encoded: String): Boolean {
    let mime: String = this.base64MimeType(encoded);
    if (mime === 'text/plain') {
      return true;
    } else {
      return false;
    }
  }

  public base64MimeType(encoded: String): String {
    let result: String = null;

    if (typeof encoded !== 'string') {
      return result;
    }

    const mime: RegExpMatchArray = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime && mime.length) {
      result = mime[1];
    }

    return result;
  }
}
