import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Util } from 'app/statics/utils';
import { DetailForm } from 'app/models/detail-form';
import { ApplicationItemAction } from 'app/models/application-item-actions';
import { FormMetadataValues } from 'app/models/form-metadata-values';
import { StackItem } from 'app/models/stackitem';
import { LpMeta } from 'app/meta/meta';
import { JsdataService } from './jsdata.service';
import { BreadcrumbItem } from 'app/models/breadcrumb-item';
import { CustomLabelsService } from './custom-labels.service';

@Injectable({
  providedIn: 'root'
})
export class UiSyncService {

  public refreshDynamicMainFormEvtEmitter: EventEmitter<Array<any>> = new EventEmitter();

  public filterPricings: EventEmitter<Array<any>> = new EventEmitter();
  
  public evalFunctionForGrid: EventEmitter<Array<any>> = new EventEmitter();

  public updateGrid: EventEmitter<Array<any>> = new EventEmitter();

  public changeDetector: EventEmitter<Array<any>> = new EventEmitter();

  public updateZoomEval: EventEmitter<Array<any>> = new EventEmitter();

  /**
   * L'évènement lockPopoverEvtEmitter est utilisé lorsque l'utilisateur a utilisé le raccourci pour bloquer l'affiche d’un pop-up (hint).
   */
  public lockPopoverEvtEmitter: EventEmitter<any> = new EventEmitter();

  /**
   * L'évènement accordionsToggleEvtEmitter est utilisé lorsque l'utilisateur veut interagir avec les accordéons.
   */
  public accordionsToggleEndEvtEmitter: EventEmitter<any> = new EventEmitter();

  /**
   * L'évènement loaderEvtEmitter est utilisé pour afficher le loader lors d'un chargement.
   */
  public loaderEvtEmitter: EventEmitter<any> = new EventEmitter();

  /**
   * L'évènement loadObjectEvtEmitter est utilisé lorsque les data principales sont chargés.
   * Exemple, lorsque l'object ShortTermContract est chargé dans le composant ShortTermContractsComponent.
   */
  public loadObjectEvtEmitter: EventEmitter<any> = new EventEmitter();

  /**
   * L'évènement displayZoomImageEvtEmitter est utilisé pour afficher un zoom sur une image avec l'url passé en paramètre.
   */
  public displayZoomImageEvtEmitter: EventEmitter<any> = new EventEmitter();
  /**
   * L'évènement displayZoomTxtEvtEmitter est utilisé pour afficher un zoom sur une image avec l'url passé en paramètre.
   */
  public displayZoomTxtEvtEmitter: EventEmitter<any> = new EventEmitter();

  /**
   * L'évènement displayZoomPdfEvtEmitter est utilisé pour afficher un zoom sur une image avec l'url passé en paramètre.
   */
  public displayZoomPdfEvtEmitter: EventEmitter<any> = new EventEmitter();
  /**
   * L'évènement sitemapItemSelectedEvtEmitter est utilisé lorsque l'utilisateur clique sur un lien dans le sitemap.
   */
  public sitemapItemSelectedEvtEmitter: EventEmitter<any> = new EventEmitter();

  /**
   * L'évènement deleteObjectSelectedEvtEmitter, est utilisé lorsque l'utilisateur veut supprimer l'objet sur lequel il travail.
   */
  public deleteObjectSelectedEvtEmitter: EventEmitter<any> = new EventEmitter();

  /**
   * L'évènement fieldDetailsEvtEmitter est utilisé lorsque l'utilisateur clique sur un zoom-field.
   * Si le zoom-field contient un objet valide, on demande l'affichage d'infos supplémentaire dans la partie de droite.
   */
  public fieldDetailsEvtEmitter: EventEmitter<any> = new EventEmitter();

  /**
   * L'évènement showMemosEvtEmitter est utilisé lorsque l'utilisateur veut afficher la liste des mémos.
   */
  public showMemosEvtEmitter: EventEmitter<any> = new EventEmitter();

  /**
   * L'évènement showWorkflowEvtEmitter est utilisé lorsque l'utilisateur veut afficher la liste des workflows.
   */
  public showWorkflowsEvtEmitter: EventEmitter<any> = new EventEmitter();

  public showMyWorkflowsEvtEmitter: EventEmitter<any> = new EventEmitter();

  /**
   * L'évènement showTracesEvtEmitter est utilisé lorsque l'utilisateur veut afficher la liste des traces.
   */
  public showTracesEvtEmitter: EventEmitter<any> = new EventEmitter();

  /**
   * L'évènement refreshMemoListEvtEmitter est utilisé lorsque l'utilisateur à fait une mise à jour sur la liste des mémos.
   */
  public changeRightBlockEvtEmitter: EventEmitter<any> = new EventEmitter();

  /**
   * L'évènement refreshScreenEvtEmitter est utilisé lorsque le système demande de rafrachir toute la page.
   */
  public refreshScreenEvtEmitter: EventEmitter<any> = new EventEmitter();

  /**
   * L'évènement showDetailFormEvtEmitter est utilisé lorsque l'utilisateur veut ajouter / modifiier un objet dans la colonne de droite.
   */
  public showDetailFormEvtEmitter: EventEmitter<any> = new EventEmitter();

   /**
   * L'évènement changeThemeEvtEmitter est utilisé lorsque l'utilisateur change de thème.
   */
  public changeThemeEvtEmitter: EventEmitter<any> = new EventEmitter();


  /**
   * L'évènement rootformColChangedEvtEmitter est utilisé lorsque l'utilsiateur change la taille des colonnes.
   */
  public rootformColChangedEvtEmitter: EventEmitter<any> = new EventEmitter();

  /**
   * L'évènement showRootFormActionEvtEmitter est utilisé lorsque l'utilsiateur demande d'afficher une action.
   * Exemple: demande de retour CD, demande de changement de véhicule, etc.
   */
  public showRootFormActionEvtEmitter: EventEmitter<any> = new EventEmitter();

  /**
   * L'évènement est utilisé pour dire au field que les metadata ont été charger et que si ils sont concerné qu'ils se mettent à jour.
   */
  public formMetadataEventEmitter: EventEmitter<FormMetadataValues> = new EventEmitter();

  // permet de recalculer si on affiche un cahmps du formulaire json cas d'utilisé pour afficher
  // le bon champ compte collectif selon client / fournisseur dans tier accountings
  public availableEvalForJsonInputField: EventEmitter<void> = new EventEmitter();

  public reloadDashboardAfterChangeLocation: EventEmitter<void>  = new EventEmitter();
  public reloadDashboardChangeWidgets: EventEmitter<void>  = new EventEmitter();
  public clickPreviewFormCard: EventEmitter<any>  = new EventEmitter();
  public clickActiveFormCard: EventEmitter<any>  = new EventEmitter();

  /**
   * permer de relancer la fonction filter poru les écrans qui en possède une (exemple écran FT)
   */
  public executeFilterFunctionEvtEmitter: EventEmitter<void>  = new EventEmitter();

  /**
   * Permet d'afficher un zoom
   */
  public displayZoomEvtEmitter: EventEmitter<any> = new EventEmitter();

  public parentVerb: String;
  public historyKey: String;
  public currentBreadCrumb: [String, StackItem][] = [];
  public lastBreadcrumbItemClickIndex: number;

  /**
   * permet d'afficher la trasition entre le changement de page;
   */
  public showTransition: boolean;
  public ft_isParamerters: boolean = true;
  public ft_isExecute: boolean = false;

  // Utilisées dans le hostlistener du menu
  public searchVal: String = '';
  public sitemap: Boolean = false;
  public clickInside: Boolean = false;

  // Utilisée dans le root form lors de la modification de largeur des colonnes
  public lastDragEvent: Event = null;

  // Utilisée dans le composant drop-file
  public dragOver: Boolean = false;

  public noTtestFormChange: Boolean = false;
  public primaryColor: string;
  public currentItemDisplayed: string;
  public isApplicationItemDetails: boolean;
  public isZoom: boolean;
  public isLpFieldDetail: boolean;
  public footerWidth: number = 0;
  public accordiongroups: Map<String, Boolean> = new Map();
  private isKyForm: boolean
  private isNewForm: boolean
  private isMainZoom: boolean
  private isReadyToDisplayForm: boolean = false;
  


  constructor(private translate: TranslateService, private jsdataService: JsdataService, private customLabelService: CustomLabelsService,/*, private configService: ConfigService*/) {
    // this.primaryColor = this.configService.get('defaultPrimaryColor');
   }
   
  public accordionsToggleEnd(id: String): void {
    this.accordionsToggleEndEvtEmitter.emit(id);
  }


  /** La fonction loadObject() est utilisé lorsque les données ont été chargées.
   * Cette fontion permet d'avertir les instances du composant zoom-field pour qu'ils se mettent à jour.
   * !!!ATTENTION!!! : utilisé dans les relations tier et equipment, permet de remettre à jour
   *        le champs enfant/parent quand on change le switch
   *  la valeur dans la grappe de données est bien mise à jour mais pas l'affichage, d'ou l'utilisation de
   *         cette eventemitter pour forcer la MAJ du champs
  */
  public loadObject(): void {
    this.loadObjectEvtEmitter.emit();
  }

  public refreshDynamicMainForm(): void {
    this.refreshDynamicMainFormEvtEmitter.emit();
  }

  public reloadDashboardAfterChangedLocation(): void {
    this.reloadDashboardAfterChangeLocation.emit();
  }

  public executeFilterFunction(): void {
    this.executeFilterFunctionEvtEmitter.emit();
  }

  /**
   * Permet d'afficher ou de masquer le loader.
   * @param active: Boolean - afficher ou non le loader
   * @param overlay: Boolean - afficher ou non le fond noir (bloque tout intéraction de l'utilisateur pendant le chargement)
   * @param message: String - afficher un message sous le loader
   * @param placement: String (top/bottom) - si on veut afficher le loader en haut ou bas de page.
   */

  public loader(active: Boolean = true, overlay: Boolean = true, message: String = null, placement: String = null): void {
    const params: any = {
      'active': active,
      'overlay': overlay,
      'message': message,
      'placement': placement
    };
    this.loaderEvtEmitter.emit(params);
  }

  public displayZoomImage(url: String): void {
    this.displayZoomImageEvtEmitter.emit(url);
  }

  public displayZoomPdf(pdf: String): void {
    this.displayZoomPdfEvtEmitter.emit(pdf);
  }

  public displayZoomTxt(txt: String): void {
    this.displayZoomTxtEvtEmitter.emit(txt);
  }

  public showRootFormAction(rootFormAction: ApplicationItemAction): void {
    this.showRootFormActionEvtEmitter.emit(rootFormAction);
  }

  public sitemapItemSelected(): void {
    this.sitemapItemSelectedEvtEmitter.emit();
  }

  public deleteObject(verb: string): void {
    this.deleteObjectSelectedEvtEmitter.emit(verb);
  }

  public changeTheme(verb: string, primaryColor: string): void {
    this.primaryColor = primaryColor;
    this.changeThemeEvtEmitter.emit(verb);
  }

  /**
   * Si params est null, on desactive l'affichage, sinon on affiche les infos.
   * @param params
   */
  /*public fieldDetails(params: FieldDetailsParameters): void {
    this.fieldDetailsEvtEmitter.emit(params);
  }*/

  public updateZoom(lpMeta: LpMeta, field: string): void {
    const array: Array<any> = [lpMeta, field];
    this.updateZoomEval.emit(array);
  }

  /** La fonction getPopoverInfoContent(), retourne un script HTML pour l'infobulle du composant lp-ui-popover-info
 *
*/
  public getPopoverInfoContent(ecrId: String, field: String): void {
  }

  /** La fonction lockPopover() est appelée lorsque l'utilisateur veut bloquer ou débloquer une infobulle.
  * Le fait de bloquer une infobulle consiste à empêcher l'infobulle de se fermer lorsque l'on déplace la sourie.
  * Pour bloquer/débloquer une infobulle, taper sur la touche configurée dans le paramètre lockPopover de l'objet environnement.
   */
  public lockPopover(): void {
    this.lockPopoverEvtEmitter.emit();
  }

  public showWorkflows(relatedObject: String, relatedObjectId: String, verb: String): void {
    this.showWorkflowsEvtEmitter.emit({'relatedObject': relatedObject, 'relatedObjectId': relatedObjectId, 'verb': verb});
  }

  public showMyWorkflows(): void {
   this.showMyWorkflowsEvtEmitter.emit();
  }

  public changeRightBlock(key: any, loader: Boolean = true, data?: any, ignoreFieldDetails?: Boolean): void {

    if (key !== 'debugg' && loader) {
      this.loader(true, true, this.translate.instant('loading.data'));
    }

    if (!ignoreFieldDetails) {
      // this.fieldDetails(null);
    }
    this.changeRightBlockEvtEmitter.emit({key : key, data: data});
  }

  public showDetailForm(params: DetailForm): void {
    this.showDetailFormEvtEmitter.emit(params);
  }

  public emitAvailableEvalForJsonInputField(): void {
    this.availableEvalForJsonInputField.emit();
  }

  public refreshScreen(): void {
    this.refreshScreenEvtEmitter.emit();
  }

  public displayZoomEvt(param?: string, guid?: string): void {
    if ((param === undefined || param === null)
      && (guid === undefined || guid === null)) {
        this.displayZoomEvtEmitter.emit();
    } else {
      this.displayZoomEvtEmitter.emit({'param': param, 'guid': guid});
    }    
  }

  /**
   * Le fonction formatUrl() permet d'insérer des valeurs dynamiquement dans une url.
   * Les infos à insérer sont écrites sous la forme {{data.machin.truc}}
   * @param url url à formater;
   * @param defaultData objet général contenant les infos à insérer dans l'url.
   */

  public formatURL(url: String, defaultData: any): string {
    const regex: RegExp = RegExp('{{([a-zA-Z._-]*)}}', 'g');
    let data: any;
    if (!Util.isNullOrUndefined(url)) {
      /*while ((find = regex.exec(url.toString())) !== null) {
        // Pour chaque occurences trouvées, on crée un tableau contenant toute l'arborescence à utiliser pour récupérer l'infos.
        let props: String[] = find[0].replace('{{', '').replace('}}', '').split('.');
        let valTemp: String = null;

        for (let i in props) {
          if (!valTemp) {
            // ZOOM on écrase a chaque fois valTemp (for) ???????????
            // Début de l'arborescence, on utilise l'objet par défaut avanbt de prendre la propriété props[i].
            valTemp = defaultData[props[i.toString()]];
          } else {
            valTemp = valTemp[props[i.toString()]];
          }
        <}
        if (!Util.isNullOrUndefined(valTemp)) {
          url = url.replace(find[0].toString(), valTemp.toString());
        }
      }*/
      let matchs: Array<String> = regex.exec(url.toString())
      if (!Util.isNullOrUndefined(matchs) && !Util.isNullOrUndefined(matchs[1]) && matchs[1].indexOf('data') >= 0) {
        data  = {data: defaultData};
      } else {
        data = defaultData;
      }
      let dataToReplace: string;
      if (matchs !== null) {
        dataToReplace = this.jsdataService.getDataRef(data, matchs[1])
        if (!Util.isNullOrUndefined(dataToReplace)) {
          url = url.replace(regex, dataToReplace);
          return url.toString();
        }
      } else {
        return url.toString();
      }
    } else {
      return null
    }
  }

  /**
   * La fonction rootformColChanged est appelée lorsque l'utilisateur change la taille des colonnes.
   * Elle est utilisé dans la fonction drop() du rootform component;
   */
  public rootformColChanged(sizes: Object): void {
    this.rootformColChangedEvtEmitter.emit(sizes);
  }

  public get IsReadyToDisplayForm(): boolean {
    return this.isReadyToDisplayForm
  }

  public set IsReadyToDisplayForm(v: boolean) {
    this.isReadyToDisplayForm = v;
  }

  public closeAll(): void {
    this.isZoom = false;
    this.isLpFieldDetail = false;
    this.isApplicationItemDetails = false;
  }

  public displayZoom(modal: Boolean): void {
    this.isZoom = true;
    this.isLpFieldDetail = false;
    if (!modal) {
      this.isApplicationItemDetails = false;
    }/* else {
      this.isApplicationItemDetails = true;
    }*/
  }

  public displayLpFieldDetail(): void {
    this.isZoom = false;
    this.isLpFieldDetail = true;
    this.isApplicationItemDetails = false;
  }

  public displayApplicationItemDetail(item?: string): void {
    if (!this.isNewForm) {
      this.isApplicationItemDetails = true;
    }
    this.isZoom = false;
    this.isLpFieldDetail = false;

    if (!Util.isNullOrUndefined(item)) {
      this.currentItemDisplayed = item;
    }
  }

  public get IsKyForm(): boolean {
    return this.isKyForm;
  }

  public set IsKyForm(v: boolean) {
      this.isKyForm = v;
      this.isNewForm = false;
      this.isMainZoom = false;
  }

  public get IsNewForm(): boolean {
      return this.isNewForm;
  }

  public set IsNewForm(v: boolean) {
      this.isKyForm = false;
      this.isNewForm = v;
      this.isMainZoom = false;
  }

  public get IsMainZoom(): boolean {
      return this.isMainZoom;
  }

  public set IsMainZoom(v: boolean) {
      this.isKyForm = false;
      this.isNewForm = false;
      this.isMainZoom = v;
  }

  public get FooterWidth(): Number {
      return this.footerWidth;
  }

  public set FooterWidth(i: Number) {
      this.footerWidth++;
  }


  /**
   *  Emission de l'evenement au fields pour prendre en compte les metadata une fois chargé
   */
  public emitFormMetadataLoaded(formMetadataValues: FormMetadataValues = {'fromPlanning': false, 'isNewForm': true, 'subApi': false})
    : void {
      this.formMetadataEventEmitter.emit(formMetadataValues);
  }

  /**
   * Permet de scroller vers un ID passé en paramètre.
   * 
   */
  public scrollTo(id: string): void {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest"
    });
  }

  public buildTopBannerTitle(breadcrumbItem: BreadcrumbItem, data : LpMeta): String {
    let tempVal: String;
    let result: String[] = [];
    if (breadcrumbItem) {
      if (breadcrumbItem.summary) {
        for (let i: number = 0; i < breadcrumbItem.summary.length; i++) {
          tempVal = this.customLabelService.displayLabel(breadcrumbItem.summary[i], data);
          if (tempVal && tempVal !== '' && tempVal !== 'null') {
            result.push(tempVal);
          }
        }
        return result.join(' - ');
      }
    }
  }
}
