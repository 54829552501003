import { Component, Input, ViewChild, ElementRef, OnChanges, OnDestroy, SimpleChanges, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UiSyncService } from 'app/services/ui-sync.service';

export const PDF: string = 'PDF';

@Component({
  selector: 'lp-pdf-viewer',
  templateUrl: './lp-pdf-viewer.component.html',
  styleUrls: ['./lp-pdf-viewer.component.scss']
})
export class LpPdfViewerComponent implements AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('iframe') public iframe: ElementRef;

  @Input() public pdfJsFolder: string;
  @Input() public externalWindow: Boolean = false;
  @Input() public showSpinner: Boolean = true;
  @Input() public downloadFileName: string;
  @Input() public openFile: Boolean = true;
  @Input() public download: Boolean = true;
  @Input() public viewBookmark: Boolean = false;

  public viewerTab: any;
  private innerSrc: Uint8Array;

  @Input()
  public set pdfSrc(innerSrc: Uint8Array) {
    this.innerSrc = new Uint8Array(innerSrc);
    this.innerSrc = innerSrc;
  }

  constructor(private translateService: TranslateService, private uiSyncService: UiSyncService) {}

  public get pdfSrc(): Uint8Array {
    return this.innerSrc;
  }

  public ngAfterViewInit(): void {
    this.uiSyncService.loader(true, true, this.translateService.instant('loading.data'));
    if (!this.externalWindow) { // Load pdf for embedded views
      this.loadPdf();
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
  }

  public ngOnDestroy(): void {
  }

  public refresh(): void { // Needs to be invoked for external window or when needs to reload pdf
    this.loadPdf();
  }

  private loadPdf(): void {
    if (!this.innerSrc) {
      this.uiSyncService.loader(false);
      return;
    }

    if (this.externalWindow && (typeof this.viewerTab === 'undefined' || this.viewerTab.closed)) {
      this.viewerTab = window.open('', '_blank');
      if (this.viewerTab == null) {
        console.log('ng2-pdfjs-viewer: For "externalWindow = true". i.e opening in new tab, to work, pop-ups should be enabled.');
        this.uiSyncService.loader(false);
        return;
      }

      if (this.showSpinner) {
        this.viewerTab.document.write(`
          <style>
          .loader {
            position: fixed;
            left: 40%;
            top: 40%;
            border: 16px solid #f3f3f3;
            border-radius: 50%;
            border-top: 16px solid #3498db;
            width: 120px;
            height: 120px;
            animation: spin 2s linear infinite;
          }
          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          </style>
          <div class="loader"></div>
        `);
      }
    }

    let fileUrl: string;
    /*if (this.innerSrc instanceof Blob) {
      fileUrl = encodeURIComponent(URL.createObjectURL(this.innerSrc));
    } else if (this.innerSrc instanceof Uint8Array) {*/
      let blob: Blob = new Blob([this.innerSrc], { type: 'application/pdf' });
      fileUrl = encodeURIComponent(URL.createObjectURL(blob));
    /*} else {
      fileUrl = this.innerSrc;
    }*/

    let viewerUrl: string;
    if (this.pdfJsFolder) {
      viewerUrl = `${this.pdfJsFolder}/web/viewer.html`;
    } else {
      viewerUrl = `assets/lib/pdfjs/web/viewer.html`;
    }


    viewerUrl += `?file=${fileUrl}`;

    if (this.downloadFileName) {
      viewerUrl += `&fileName=${this.downloadFileName}.pdf`;
    }
    if (typeof this.openFile !== 'undefined') {
      viewerUrl += `&openFile=${this.openFile}`;
    }
    if (typeof this.download !== 'undefined') {
      viewerUrl += `&download=${this.download}`;
    }
    if (typeof this.viewBookmark !== 'undefined') {
      viewerUrl += `&viewBookmark=${this.viewBookmark}`;
    }

    if (this.externalWindow) {
      this.viewerTab.location.href = viewerUrl;
    } else {
      this.iframe.nativeElement.src = viewerUrl;
    }
    this.uiSyncService.loader(false);
  }

}
