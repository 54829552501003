import { Component, Inject } from '@angular/core';
import { ChangeService } from '../../services/change.service';
import { FormStackService } from '../../services/form-stack.service';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { UserService } from 'app/services/user.service';
import { RouterService, LOGIN } from 'app/services/router.service';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RepositoryProvider } from 'app/providers/repository.provider';

@Component({
  selector: 'lp-modalform-change',
  templateUrl: './lp-modalform-change.component.html'
})
export class LpModalformChangeComponent {

  constructor(
    private routerService: RouterService,
    private alocproProvider: AlocproProvider,
    private repositoryProvider: RepositoryProvider,
    private userService: UserService,
    private formStackService: FormStackService,
    private changeService: ChangeService,
    public dialogRef: MatDialogRef<LpModalformChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  public decline(): void {
    this.dialogRef.close();
  }

  public confirm(): void {
    this.changeService.clearCurrentChanges();
    this.formStackService.lastStackKey = null;
    if (this.changeService.getPendingUrl().toString() === 'logout') {
      this.logout();
    } else {
      this.routerService.navigate(this.changeService.getPendingUrl().toString());
    }
  }

  public logout(): void {
    this.repositoryProvider.logout().then(() => {
      this.formStackService.clear();
      this.alocproProvider.logout();
      this.userService.userLogout();
      this.routerService.navigate(LOGIN);
    });
  }
}
