import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, REGISTRATIONS } from '../detail-forms.component';
import { LpRegistration } from 'app/meta/registration';

@Component({
  selector: 'lp-registrations',
  templateUrl: './registrations.component.html'
})
export class RegistrationsComponent extends DetailFormsComponent implements OnInit {

  public data: LpRegistration = null;

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(REGISTRATIONS);
    this.initUrl();
    // this.init();
  }

    public init(): void {
    this.data.assign(this.formStackService.currentDetailLineData);
  }

}
