import { Component, Input} from '@angular/core';
import { LpUiAccordionGroupComponent } from './lp-ui-accordion-group.component';
import { UiSyncService } from '../../services/ui-sync.service';



@Component({
  selector: 'lp-ui-accordion',
  templateUrl: './lp-ui-accordion.component.html',
})
export class LpUiAccordionComponent {
  @Input() public closeOthers: Boolean = false;
  @Input() public menu: Boolean = false;

  constructor(private uiSyncService: UiSyncService) {}

  /**La fonction closeOtherPanels(), permet de fermer tous les accordéons sauf celui passé en paramètre (openGroup).
  */
  public closeOtherPanels(openGroup: LpUiAccordionGroupComponent): void {
    if (!this.closeOthers) {
      return;
    }
    
    this.uiSyncService.accordiongroups.forEach((value: Boolean, key: String) => {
      this.uiSyncService.accordiongroups.set(key, false);
    });
  } 
}
