<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" titleTranslationKey="companies.company"></lp-top-banner>

<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">

  <!--  bloc permettant de faire un drag & drop pour modifier la taille des colonnes -->
  <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''" (dblclick)="reInitCols()"></div>
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>

  <div class="container container-2-cols left" #containerCols>
    <div class="card-box col-md-6 label-2-colonnes" #col1>
      <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
        <!--  composant pour afficher lien flottant à droite du formulaire -->
        <lp-common-tools *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id" [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="deleteButton" 
          (showPrintOutput)="changeShowZoomPrint($event)" [showPrintInput]="showZoomPrint"></lp-common-tools>
          <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
        <lp-ui-accordion class="row" [menu]=true>
          <lp-ui-accordion-group heading="general.other.general" [open]=true key="general">
              <div class="container">

                <div class="row">
                    <div class="form-group col-xl-6">
                      <lp-text-field id="id" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="id"  label="companies.id"
                        [(ngModel)]="data.id" [disabled]=true></lp-text-field>
                    </div>
                    <div class="form-group col-xl-6">
                        <lp-text-field id="corporateName" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="corporateName"
                        label="companies.corporateName"  [(ngModel)]="data.corporateName"></lp-text-field>
                      </div>
                </div>

                <div class="row">
                  <div class="form-group col-xl-6">
                    <lp-text-field id="address1" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="address1" label="companies.address"
                        [(ngModel)]="data.address1"></lp-text-field>
                  </div>
                  <div class="form-group col-xl-6">
                    <lp-text-field id="address2" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="address2" label="companies.address2"
                      [(ngModel)]="data.address2"></lp-text-field>
                    </div>
                </div>

                <div class="row">
                  <div class="form-group col-xl-6">
                      <lp-text-field id="postcode" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" label="companies.postcode"
                        name="postcode" [(ngModel)]="data.postcode"></lp-text-field>
                  </div>
                  <div class="form-group col-xl-6">
                      <lp-text-field id="city" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="city" label="companies.city" [(ngModel)]="data.city"></lp-text-field>
                  </div>
                </div>

                <div class="row d-flex justify-content-center">
                  <hr class="col-sm-10 mb-3 mt-2" />
                </div>

                <div class="row">
                  <div class="form-group col-xl-6">
                    <lp-text-field id="phone" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="phone" label="companies.phone"
                      [(ngModel)]="data.phone"></lp-text-field>
                  </div>
                  <div class="form-group col-xl-6">
                    <lp-text-field id="fax" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="fax" label="companies.fax"
                      [(ngModel)]="data.fax"></lp-text-field>
                  </div>
                </div>

                <div class="row">
                  <div class="form-group col-xl-6">
                    <lp-text-field id="mail" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="mail" label="companies.mail"
                      [(ngModel)]="data.mail" type="mail"></lp-text-field>
                  </div>
                  
                </div>

                <div class="row">
                  <div class="form-group col-xl-6">
                    <lp-text-field id="siret" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="siret" label="companies.siret"
                          [(ngModel)]="data.siret"></lp-text-field>
                  </div>
                  <div class="form-group col-xl-6">
                    <lp-text-field id="APE" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="APE" label="companies.APE"
                      [(ngModel)]="data.APE"></lp-text-field>
                  </div>
                  
                </div>

                <!-- Field TVA intracom -->
                <div class="row">
                  <div class="form-group col-xl-6">
                    <lp-text-field id="establishment" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="establishment" label="companies.establishment"
                      [(ngModel)]="data.establishment"></lp-text-field>
                  </div>
                  <div class="form-group col-xl-6">
                    <!--<lp-dropdown-field id="vatDomain" class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control" name="vatDomain"
                     label="companies.vatDomain" [(ngModel)]="data.vatDomain" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'vatDomain'}]}"></lp-dropdown-field>-->
                    <lp-zoom-field id="vatDomain" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" 
                     cssClass="form-control" [(ngModel)]="data.vatDomain" zoomId="vatDomain" name="vatDomain" label="companies.vatDomain"></lp-zoom-field>
                    </div>
                </div>

                </div>
          </lp-ui-accordion-group>
          <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
            <div>
              <pre>{{ data | json }}</pre>
            </div>
          </lp-ui-accordion-group>
        </lp-ui-accordion>
      </div>
        <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
      </form>
    </div>
    <div class="col-md-6 card-box" #col2>
      <lp-right-col></lp-right-col>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>