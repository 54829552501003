import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ProgressBarService {
    // Couleur de la progress bar
    public active: Boolean = false;
    // Valeur à l'instant T
    public value: number;
    // Value maximum (100%)
    public max: number;
    // Titre en dessus de la progressbar
    public title: String;

    constructor() {

    }

    public init(max: number, title?: String): void {
        this.value = 0;
        this.max = max;
        this.active = true;
        this.title = title;
    }
}
