<lp-ui-accordion id="{{id}}">
    <lp-ui-accordion-group heading="metadataApplicationItemDetails.circumstanceLinks.titleTab" [open]=true>
      <form id="formCircumstanceLinks" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>

        <div class="form-group col-xl-12">
          <lp-label-field for="damages.circumstanceCode" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="damages.circumstanceCode"></lp-label-field>
          <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control"
                name="damages.circumstanceCode" [(ngModel)]="data.circumstanceCode" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'circumstanceLink'}]}">
          </lp-dropdown-field>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="damages.isADriverChecked" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="damages.isADriverChecked"></lp-label-field>
          <lp-boolean-switch-box name="damages.isADriverChecked" switchName="isADriverChecked" id="damages.isADriverChecked" [(ngModel)]="data.isADriverChecked"></lp-boolean-switch-box>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="damages.isBDriverChecked" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="damages.isBDriverChecked"></lp-label-field>
          <lp-boolean-switch-box name="damages.isBDriverChecked" switchName="isBDriverChecked" id="damages.isBDriverChecked" [(ngModel)]="data.isBDriverChecked"></lp-boolean-switch-box>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="damages.wording" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.wording"></lp-label-field>
          <lp-text-field id="damages.wording" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="damages.wording"
           [(ngModel)]="data.wording" placeholder="damages.wording"></lp-text-field>
        </div>

        <div class="form-group col-xl-12">
          <lp-label-field for="damages.wordingA" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.wordingA"></lp-label-field>
          <lp-text-field id="damages.wordingA" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="damages.wordingA"
            [(ngModel)]="data.wordingA" placeholder="damages.wordingA"></lp-text-field>
        </div>

        <div class="text-end">
          <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
        </div>
      </form>
    </lp-ui-accordion-group>
    <lp-ui-accordion-group heading="Debugger Mon Composant" *ngIf="debugg">
      <div>
        <pre>{{ data | json }}</pre>
      </div>
    </lp-ui-accordion-group>
  </lp-ui-accordion>