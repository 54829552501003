import { Injectable } from '@angular/core';
import { DatetimeService } from './datetime.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { LanguageService } from './language.service';
import { UserService } from './user.service';
import { Util } from 'app/statics/utils';

export const currency:  any = { style: 'currency', currency: 'EUR' };
@Injectable({
    providedIn: 'root'
  })
export class FormatService {

    public type: any = {
        'Text':         <Number>1,
        'Number':       <Number>2,
        'Select' :      <Number>3,
        'Short_date' :  <Number>4,
        'Date_hours' :  <Number>5,
        'Hours' :       <Number>6,
        'Boolean' :     <Number>7,
        'Integer' :     <Number>8,
        'Year' :        <Number>9,
        'Currency':     <Number>10,
        'DayOfWeek':    <Number>11,
        'AccoutingType': <Number>12,
        'Html':         <Number>99
    }

    public daysOfWeek: Map<String, String> = new Map();
    public widgetType: Map<Number, String> = new Map();

    public DECIMAL_SEPARATOR: Map<string, string> = new Map();

    constructor(protected translate: TranslateService,
        private datetimeService: DatetimeService,
        private _sanitizer: DomSanitizer,
        private languageService: LanguageService,
        private userService: UserService) {            
            this.setWidgetType();
            this.setDaysOfWeek();
            this.setDecimalSeparator();
    }

    /**
     * Formate le contenu du paramètre value selon le type passé en paramètre.
     * @param type
     * @param value
     * @param decimal - Utilisé avec les type Number et Currency.
     */
    public formatValue(type: Number, value: String, infos?: any): String | SafeHtml {
        if (value === null || value === undefined ) {
            return '';
        }
        let result: String | SafeHtml;
        switch (type) {
            case (this.type.Number): {
                let intValue: number = parseInt(value.toString(), 10);
                if (!isNaN(intValue)) {
                    return this.formatNumber(parseInt(value.toString(), 10));
                } else {
                    return null;
                }
                break;
            }

            case (this.type.Short_date): {
                if (value === '' || !this.datetimeService.isValid(value.toString())) {
                    return '';
                }
                // result = this.datetimeService.dateUiFormat(value.toString());
                value = this.datetimeService.deleteZtoDate(value);
                result = this.datetimeService.displayDateFormat(value.toString(), this.userService.getCurrentLang().toString());
                break;
            }
            case (this.type.Date_hours): {
                if (value === '' || this.datetimeService.newDate(value.toString()).toString() === 'Invalid Date') {
                    return '';
                }
                // result = this.datetimeService.dateTimeFormat(new Date(value.toString()).toISOString());
                value = this.datetimeService.deleteZtoDate(value);
                result = this.datetimeService.displayDateHourFormat(value.toString(), this.userService.getCurrentLang().toString());
                break;
            }
            case (this.type.Currency): {
                // TODO devise et décimale à aller chercher!
                let floatValue: number = parseFloat(value.toString());
                if (!isNaN(floatValue)) {
                    result = this.formatCurrency(floatValue);
                } else {
                    result = null;
                }
                break;
            }
            case (this.type.DayOfWeek): {
                result = this.translate.instant( this.daysOfWeek.get(value.toString()).toString());
                break;
            }
            case (this.type.Html): {
                result = value;
                if (value) {
                    result = this._sanitizer.bypassSecurityTrustHtml(value.toString());
                }

                break;
            }
            case (this.type.Boolean): {
                if ( Util.isNullOrUndefined(value) ) {
                    result = '';
                } else {
                    result = this.translate.instant(value.toString());
                }

                break;
            }
            case (this.type.AccoutingType): {
                if (value === '1') {
                    result = this.translate.instant( 'general.clientType');
                } else if (value === '2') {
                    result = this.translate.instant( 'general.supplierType');
                } else {
                    result = '';
                }

                break;
            }
            default: result = value;
        }
        return result;
    }

    public replaceFloatSeprator(n: string): number {
        return parseFloat(n.replace(',', '.'));
    }

    public formatNumber(value: number, type: String = "number"): string {
        let value2 = new Number(value);
        if (type === "currency") {
            return value2.toLocaleString(this.languageService.getCurrentLanguageISO(),{         
                minimumFractionDigits: 2, 
                maximumFractionDigits: 2
            });
        } else {
            return value2.toLocaleString(this.languageService.getCurrentLanguageISO(),{          
                maximumFractionDigits: 2
            });
        }        
    }

    public replaceDeviseSeprator(n: string): number {
        let temp: string = n.replace('€', '');
        temp = temp.replace('$', '');
        temp = temp.replace('£', '');
        return this.replaceFloatSeprator(temp.replace(' ', ''));
    }

    public formatCurrency(value: number): string {
        return new Intl.NumberFormat(this.languageService.getCurrentLanguageISO(), currency).format(value);
    }

    private setWidgetType(): void {
        this.widgetType.set(1, 'area');
        this.widgetType.set(2, 'bar');
        this.widgetType.set(3, 'column');
        this.widgetType.set(4, 'html');
        this.widgetType.set(5, 'line');
        this.widgetType.set(6, 'pie');
        this.widgetType.set(7, 'progressdoughnut');
        this.widgetType.set(8, 'zoom');
    }

    private setDaysOfWeek(): void {
        this.daysOfWeek.set('1', 'dropdown.sunday');
        this.daysOfWeek.set('2', 'dropdown.monday');
        this.daysOfWeek.set('3', 'dropdown.tuesday');
        this.daysOfWeek.set('4', 'dropdown.wednesday');
        this.daysOfWeek.set('5', 'dropdown.thursday');
        this.daysOfWeek.set('6', 'dropdown.friday');
        this.daysOfWeek.set('7', 'dropdown.saturday');
    }

    private setDecimalSeparator(): void {
        this.DECIMAL_SEPARATOR.set('FR', ',');
    }
}
