<div class="modal-header mb-3">
  <h4 class="modal-title pull-left">{{ 'edis.edition' | translate }}</h4>

  <div class="pull-right">
    <lp-button-cancel (action)="closeModal('editModal')"></lp-button-cancel>
  </div>
</div>

<div class="container label-2-colonnes">
  <form #form="ngForm" ngNativeValidate>
    <div *ngIf="!formStackService.specificApplicationItem || !formStackService.specificApplicationItem.useJsonEditForm">
      <div class="row">
        <div class="form-group col col-md-6">
          <lp-dropdown-field id="natureLink" class="{{configService.get('constanteClasses').DEFAULT_INPUT}}" className="textField"
            cssClass="form-control" name="edis.natureLink" [required]="true" label="edis.natureLink"
            [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'natureLink'}]}"
            [(ngModel)]="currentEdi.natureLink" [required]=false></lp-dropdown-field>
        </div>
        <div class="form-group col col-md-6">
          <lp-zoom-field for="company" id="company" class="{{configService.get('constanteClasses').DEFAULT_INPUT}}"
            className="zoomField" cssClass="form-control" [(ngModel)]="currentEdi.company" label="edis.company"
            name="edis.company" zoomId="company">
          </lp-zoom-field>
        </div>
        <div class="form-group col col-md-6">
          <lp-zoom-field for="thirdParty" id="thirdParty" class="{{configService.get('constanteClasses').DEFAULT_INPUT}}"
            className="zoomField" cssClass="form-control" [(ngModel)]="currentEdi.thirdParty" label="edis.thirdParty"
            name="edis.thirdParty" zoomId="thirdParty">
          </lp-zoom-field>
        </div>
        <div class="form-group col col-md-6">
          <lp-zoom-field for="location" id="location" class="{{configService.get('constanteClasses').DEFAULT_INPUT}}"
            className="zoomField" cssClass="form-control" [(ngModel)]="currentEdi.location" label="edis.location"
            name="edis.location" zoomId="location">
          </lp-zoom-field>
        </div>
        <div class="form-group col col-md-6">
          <lp-text-field id="thirdPartyReference" className="textField" class="{{configService.get('constanteClasses').DEFAULT_INPUT}}" label="edis.thirdPartyReference"
            cssClass="form-control" name="edis.thirdPartyReference" [(ngModel)]="currentEdi.thirdPartyReference" [required]="false" type="text">
          </lp-text-field>
        </div>
        <div class="form-group col col-md-6">
          <lp-text-field id="wording" className="textField" class="{{configService.get('constanteClasses').DEFAULT_INPUT}}" label="edis.wording"
            cssClass="form-control" name="edis.wording" [(ngModel)]="currentEdi.wording" [required]="false" type="text">
          </lp-text-field>
        </div>
        <div class="form-group col col-md-6">
          <lp-date-time id="date" cssClass="form-control" class="{{ configService.get('constanteClasses').DEFAULT_INPUT }}" label="edis.date"
            name="edis.date" [(ngModel)]="currentEdi.date" [required]="false"></lp-date-time>
        </div>
        <div class="form-group col col-md-6">
          <lp-boolean-switch-box name="edis.isActive" switchName="isActive" id="edis.isActive" [(ngModel)]="currentEdi.isActive" label="edis.isActive"></lp-boolean-switch-box>
        </div>
        <div class="form-group col col-md-6">
          <lp-date-time id="startDate" cssClass="form-control" class="{{ configService.get('constanteClasses').DEFAULT_INPUT }}" label="edis.startDate"
            name="edis.startDate" [(ngModel)]="currentEdi.startDate" [required]="false"></lp-date-time>
        </div>
        <div class="form-group col col-md-6">
          <lp-date-time id="endDate" cssClass="form-control" class="{{ configService.get('constanteClasses').DEFAULT_INPUT }}" label="edis.endDate"
            name="edis.endDate" [(ngModel)]="currentEdi.endDate" [required]="false"></lp-date-time>
        </div>
        <div class="form-group col col-md-6">
          <lp-zoom-field for="sector" id="sector" class="{{configService.get('constanteClasses').DEFAULT_INPUT}}"
            className="zoomField" cssClass="form-control" [(ngModel)]="currentEdi.sector" label="edis.sector"
            name="edis.sector" zoomId="sector">
          </lp-zoom-field>
        </div>
        <div class="form-group col col-md-6">
          <lp-zoom-field for="analytic" id="analytic" class="{{configService.get('constanteClasses').DEFAULT_INPUT}}"
            className="zoomField" cssClass="form-control" [(ngModel)]="currentEdi.analytic" label="edis.analytic"
            name="edis.analytic" zoomId="analytic">
          </lp-zoom-field>
        </div>
        <div class="form-group col col-md-6">
          <lp-zoom-field for="listOfTables" id="listOfTables" class="{{configService.get('constanteClasses').DEFAULT_INPUT}}"
            className="zoomField" cssClass="form-control" [(ngModel)]="currentEdi.listOfTables" label="edis.listOfTables"
            name="edis.listOfTables" zoomId="listOfTables">
          </lp-zoom-field>
        </div>
        <div class="form-group col col-md-6">
          <lp-text-field id="relatedKey" className="textField" class="{{configService.get('constanteClasses').DEFAULT_INPUT}}" label="edis.relatedKey"
            cssClass="form-control" name="edis.relatedKey" [(ngModel)]="currentEdi.relatedKey" [required]="true" type="text">
          </lp-text-field>
        </div>
      </div>
    </div>
    
    <lp-dynamic-main-form *ngIf="formStackService.specificApplicationItem && formStackService.specificApplicationItem.useJsonEditForm" [data]="currentEdi" [specificApplicationItem]="true"></lp-dynamic-main-form>
    <div class="col-12 m-2">
      <button class="transparent-btn pull-right" (click)="saveCurrentEdi(form.valid)" title="{{'general.save' | translate}}">
        <i class="vega-diskette"></i>
      </button>
      <button class="transparent-btn pull-right" (click)="modalDeleteEdi()" title="{{'general.delete' | translate}}"
        *ngIf="currentEdi.id">
        <i class="vega-dustbin"></i>
      </button>
    </div>
  </form>
  
</div>