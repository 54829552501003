import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, ACCOUNTING_EVENTS } from '../detail-forms.component';
import { LpAccountingEvent } from 'app/meta/accounting-event';

@Component({
  selector: 'lp-accounting-events',
  templateUrl: './accounting-events.component.html'
})
export class AccountingEventsComponent extends DetailFormsComponent implements OnInit {

  public data: LpAccountingEvent = null;

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(`${this.object}${ACCOUNTING_EVENTS}`);
    this.initUrl();
    // this.init();
  }

  public init(): void {
    this.data.assign(this.formStackService.currentDetailLineData);
  }

  protected initUrl(): void {
    this.urlGet = `/short-term-contracts/{{id}}/${this.object}-accounting-events`;
    this.urlEdit = `/short-term-contracts/{{id}}/${this.object}-accounting-events`;
  }

}
