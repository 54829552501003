import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AddressFormatPipe } from 'app/pipe/address.pipe';
import { LpChildformLinkComponent } from 'app/ui/lp-childform-link/lp-childform-link.component';
import { AlocproThirdPartyLibsModule } from '../alocpro-third-party-libs.module';
import { AbstractItemComponent } from './item/absract-item/abstract-item.component';
import { HTMLFieldComponent } from './item/absract-item/html-field/html-field.component';
import { FieldComponent } from './item/absract-item/input/field/field.component';
import { ActiveComponent } from './item/absract-item/input/field/value-field/choice-field/active/active.component';
import { BooleanSwitchBoxComponent } from './item/absract-item/input/field/value-field/choice-field/boolean-switch-box/boolean-switch-box.component';

import { NgJsonEditorModule } from 'ang-jsoneditor';
import { CheckListBoxComponent } from './item/absract-item/input/field/value-field/choice-field/check-list-box/check-list-box.component';
import { CheckboxComponent } from './item/absract-item/input/field/value-field/choice-field/checkbox/checkbox.component';
import { chipsComponent } from './item/absract-item/input/field/value-field/choice-field/chips/chips.component';
import { ChoiceFieldComponent } from './item/absract-item/input/field/value-field/choice-field/choice-field.component';
import { DropdownFieldComponent } from './item/absract-item/input/field/value-field/choice-field/dropdown-field/dropdown-field.component';
import { RadioBoxComponent } from './item/absract-item/input/field/value-field/choice-field/radio-box/radio-box.component';
import { SwitchBoxComponent } from './item/absract-item/input/field/value-field/choice-field/switch-box/switch-box.component';
import { DateFieldComponent } from './item/absract-item/input/field/value-field/date-field/date-field.component';
import { DateMonthComponent } from './item/absract-item/input/field/value-field/date-field/date-month/date-month.component';
import { DateTimeRangeComponent } from './item/absract-item/input/field/value-field/date-field/date-time-range/date-time-range.component';
import { DateTimeComponent } from './item/absract-item/input/field/value-field/date-field/date-time/date-time.component';
import { DateComponent } from './item/absract-item/input/field/value-field/date-field/date/date.component';
import { TimeComponent } from './item/absract-item/input/field/value-field/date-field/time/time.component';
import { DuplicateComponent } from './item/absract-item/input/field/value-field/duplicate-field/duplicate.component';
import { BasicFileFieldComponent } from './item/absract-item/input/field/value-field/file-field/basic-file-field/basic-file-field.component';
import { DropFileComponent } from './item/absract-item/input/field/value-field/file-field/drop-file/drop-file.component';
import { FileFieldComponent } from './item/absract-item/input/field/value-field/file-field/file-field.component';
import { LpFileCarouselComponent } from './item/absract-item/input/field/value-field/file-field/lp-file-carousel/lp-file-carousel.component';
import { ImageFieldComponent } from './item/absract-item/input/field/value-field/image-field/image-field.component';
import { AddressComponent } from './item/absract-item/input/field/value-field/input-field/address/address.component';
import { CurrencyComponent } from './item/absract-item/input/field/value-field/input-field/currency/currency.component';
import { HiddenFieldComponent } from './item/absract-item/input/field/value-field/input-field/hidden-field/hidden-field.component';
import { InputFieldComponent } from './item/absract-item/input/field/value-field/input-field/input-field.component';
import { JsonFieldComponent } from './item/absract-item/input/field/value-field/input-field/json-field/json-field.component';
import { NumberComponent } from './item/absract-item/input/field/value-field/input-field/number/number.component';
import { SearchBarComponent } from './item/absract-item/input/field/value-field/input-field/search-bar/search-bar.component';
import { TextFieldComponent } from './item/absract-item/input/field/value-field/input-field/text-field/text-field.component';
import { TextareaFieldComponent } from './item/absract-item/input/field/value-field/input-field/textarea-field/textarea-field.component';
import { LpFuelGaugeComponent } from './item/absract-item/input/field/value-field/lp-fuel-gauge/lp-fuel-gauge.component';
import { RemoveComponent } from './item/absract-item/input/field/value-field/remove-field/remove.component';
import { SignaturePadFieldComponent } from './item/absract-item/input/field/value-field/signature-pad-field/signature-pad-field.component';
import { ValueFieldComponent } from './item/absract-item/input/field/value-field/value-field.component';
import { ZoomFieldComponent } from './item/absract-item/input/field/value-field/zoom-field/zoom-field.component';
import { InputComponent } from './item/absract-item/input/input.component';
import { LabelFieldComponent } from './item/absract-item/label-field/label-field.component';
import { PanelComponent } from './item/absract-item/panel/panel.component';
import { ItemComponent } from './item/item.component';
import { LpComponent } from './lp.component';
import { SaveComponent } from './item/absract-item/input/field/value-field/save-field/save.component';
import { EditorFieldComponent } from './item/absract-item/input/field/value-field/input-field/editor-field/editor-field.component';
import { FormsModule } from '@angular/forms';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { BrowserModule } from '@angular/platform-browser';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { LpWysiwygEditorFieldComponent } from './item/absract-item/input/field/value-field/input-field/wysiwyg-editor/wysiwyg-editor.component';
@NgModule({
  imports: [
    AlocproThirdPartyLibsModule,
    NgJsonEditorModule,
    BrowserModule,
    FormsModule,
    CodemirrorModule,
    CKEditorModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AddressFormatPipe,
    ItemComponent,
    AbstractItemComponent,
    ActiveComponent,
    DuplicateComponent,
    RemoveComponent,
    SaveComponent,
    BasicFileFieldComponent,
    BooleanSwitchBoxComponent,
    CheckboxComponent,
    CheckListBoxComponent,
    chipsComponent,
    ChoiceFieldComponent,
    CurrencyComponent,
    DateFieldComponent,
    DateMonthComponent,
    DateTimeComponent,
    DateTimeRangeComponent,
    DateComponent,
    DropdownFieldComponent,
    FieldComponent,
    ImageFieldComponent,
    SignaturePadFieldComponent,
    InputComponent,
    InputFieldComponent,
    LabelFieldComponent,
    LpComponent,
    LpFuelGaugeComponent,
    NumberComponent,
    PanelComponent,
    RadioBoxComponent,
    AddressComponent,
    SearchBarComponent,
    SwitchBoxComponent,
    TextareaFieldComponent,
    EditorFieldComponent,
    TextFieldComponent,
    JsonFieldComponent,
    HiddenFieldComponent,
    TimeComponent,
    ValueFieldComponent,
    ZoomFieldComponent,
    LpChildformLinkComponent,
    DropFileComponent,
    FileFieldComponent,
    LpFileCarouselComponent,
    HTMLFieldComponent,
    LpWysiwygEditorFieldComponent
  ],
  exports: [
    AbstractItemComponent,
    ItemComponent,
    ActiveComponent,
    DuplicateComponent,
    RemoveComponent,
    SaveComponent,
    BasicFileFieldComponent,
    BooleanSwitchBoxComponent,
    CheckboxComponent,
    CheckListBoxComponent,
    chipsComponent,
    ChoiceFieldComponent,
    CurrencyComponent,
    DateFieldComponent,
    DateMonthComponent,
    DateTimeComponent,
    DateTimeRangeComponent,
    DateComponent,
    DropdownFieldComponent,
    FieldComponent,
    ImageFieldComponent,
    SignaturePadFieldComponent,
    InputComponent,
    InputFieldComponent,
    LabelFieldComponent,
    LpComponent,
    LpFuelGaugeComponent,
    NumberComponent,
    PanelComponent,
    RadioBoxComponent,
    SearchBarComponent,
    SwitchBoxComponent,
    TextareaFieldComponent,
    EditorFieldComponent,
    TextFieldComponent,    
    JsonFieldComponent,
    HiddenFieldComponent,
    TimeComponent,
    ValueFieldComponent,
    ZoomFieldComponent,
    LpChildformLinkComponent,
    DropFileComponent,
    FileFieldComponent,
    LpFileCarouselComponent,
    HTMLFieldComponent,
    AddressComponent,
    LpWysiwygEditorFieldComponent
  ],
})
export class AppModuleLp {
}
