import { Component, OnInit } from '@angular/core';
import { LpThirdParty } from '../../meta/third-party';
import { Util } from 'app/statics/utils';
import { RootformComponent } from '../rootform/rootform.component';


@Component({
  selector: 'lp-third-parties',
  templateUrl: './third-parties.component.html',
})
export class ThirdPartiesComponent extends RootformComponent implements OnInit {

  public data: LpThirdParty = null;
  public ky: string = null;

  public async ngOnInit(): Promise<void> {
    await super.build('third-parties', this.route.snapshot.params['ky']);
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.data.assign(this.formStackService.currentData);
    }
  }

  public toLowerCaseAPE(value: string): void {
    if (!Util.isNullOrUndefined(value)) {
      this.data.APE = value.toLowerCase();
    }
  }
}
