import { Component, OnInit } from '@angular/core';
import { RootformComponent } from '../rootform/rootform.component';

@Component({
  selector: 'lp-pricing-methods',
  templateUrl: './pricing-methods.component.html'
})
export class PricingMethodsComponent extends RootformComponent implements OnInit {

  public title: string;
  public ky: string = null;

  public async ngOnInit(): Promise<void> {
    await super.build('pricing-methods', this.route.snapshot.params['ky']);
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.data = this.formStackService.currentData;
    }
  }
}
