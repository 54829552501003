<lp-top-banner *ngIf="topBanner" titleTranslationKey="quickQuotes"></lp-top-banner>

<div class="container-fluid container-fluid-2-cols"
  *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)"
  (dragover)="dragover($event)" (drop)="drop($event)">
  <!--  bloc permettant de faire un drag & drop pour modifier la taille des colonnes -->
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>

  <div class="container container-2-cols left" #containerCols>
    <div class="card-box col-lg-3 col-md-4 label-2-colonnes">
      <form id="formulaire" (ngSubmit)="filter()" ngNativeValidate>
        <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
          <lp-ui-accordion class="row me-2 ms-2" [menu]=true>
            <lp-ui-accordion-group heading="ft.filter" [open]=true key="general">
              <!--Field number Fine -->


              <div class="row">
                <div class="form-group col-12">
                  <lp-zoom-field id="startLocation" class="{{arrayClassesElements.get('defaultInput')}}" label="short-term-contracts.startLocation"
                    zoomId="bestDealStartLocation" [ignoreChange]="true" className="zoomField" cssClass="form-control"
                    [(ngModel)]="data.startLocation" name="startLocation" [required]=true></lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-12">
                  <lp-date-time [(ngModel)]="data.startDate" class="{{arrayClassesElements.get('defaultInput')}}" label="short-term-contracts.startDate"
                    id="startDate" [required]=true [ignoreChange]="true" cssClass="form-control" name="startDate">
                  </lp-date-time>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-12">
                  <lp-date-time [(ngModel)]="data.returnDate" class="{{arrayClassesElements.get('defaultInput')}}" label="short-term-contracts.returnDate"
                    [ignoreChange]="true" id="returnDate" cssClass="form-control" name="returnDate" [required]=true>
                  </lp-date-time>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-12">
                  <lp-number id="wantedDays" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    [ignoreChange]="true" cssClass="form-control" name="wantedDays" min="0" label="short-term-contracts.wantedDays"
                    [(ngModel)]="data.wantedDays" type="text"></lp-number>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-12">
                  <lp-number id="wantedMileage" className="textField" label="short-term-contracts.wantedMileage"
                    class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true" cssClass="form-control"
                    name="wantedMileage" min="0" [(ngModel)]="data.wantedMileage" type="text" [required]=true>
                  </lp-number>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-12">
                  <lp-zoom-field id="pricing" class="{{arrayClassesElements.get('defaultInput')}}" label="short-term-contracts.pricing"
                    zoomId='bestDealPricing' className="zoomField" cssClass="form-control" [(ngModel)]="data.pricing"
                    name="pricing" [ignoreChange]="true">
                  </lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-12">
                  <!--<lp-dropdown-field id="type" class="{{arrayClassesElements.get('defaultInput')}}"
                    className="textField" cssClass="form-control" name="type" [ignoreChange]="true"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'equipmentType'}]}"
                    [(ngModel)]="data.type"></lp-dropdown-field>-->
                  <lp-zoom-field zoomId="equipmentType" label="equipments.type" id="equipmentType" name="equipmentType" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.type"> </lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-12">
                  <!--<lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField"
                    id="category" [ignoreChange]="true" cssClass="form-control" name="category"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'category'}]}"
                    [(ngModel)]="data.category"></lp-dropdown-field>-->
                  <lp-zoom-field zoomId="category" id="category" label="short-term-contracts.category" name="category" cssClass="form-control"
                  class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.category"> </lp-zoom-field>
                </div>
              </div>
            </lp-ui-accordion-group>

            <!--Accordion Debugger-->
            <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
              <div>
                <pre>{{ data | json }}</pre>
              </div>
            </lp-ui-accordion-group>
          </lp-ui-accordion>
        </div>
        <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
        <button type="submit" class="technical-ft-search-btn"><i class="icon vega-search"></i></button>
      </form>
    </div>
    <div class="col-md-9 card-box" #col2>
      <lp-right-col></lp-right-col>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>