import { Util } from 'app/statics/utils';
import { PaginatedData } from './paginated-data';

export interface ZoomMetaData {
    id: string,
    columns: ZoomMetaDataColumn[],
    kyField: string,
    libField: string,
    filter: string,
    path: string;
}


export interface ZoomMetaDataColumn {
    prop: string,
    name: string;
}

export class ZoomConfig {
    public zoomId: String;
    // les clés cible à rensigner dans l'objet sur le quel est bindé le zoom field
    public bindingValueKeys: String[];
    // les clés à utiliser sur la structure selectionnée pour renseigner les champs de la propriété ci dessus
    public selectedDataKeys:  String[];
    // spécifique zoom Json server
    public addModifyVerb: String;
     // clé a utiliser pour trouver la valeur de vériffication de notre recherche
    public kySearch: String = 'id';
    // public creationVerb: String;
    public urlNew: String;
    public bindingProp: String;
    public historyKey: String;
    public searchTextField?: string;
    public tableShowField?: Array<string>;
    public tableBindValues?: Array<string>;
    //  public showAddButton: Boolean;
    public modal: Boolean;
    public title: String;
    public textSearch: String;
    public isClosable: Boolean;
    public isScheduler: Boolean;
    public showLpFieldDetails: Boolean;
    public disabled: Boolean;
    public showAddButton: Boolean;
    public showModifyButton: Boolean;
    public isLocProZoom: Boolean;
    public isString: Boolean;
    public locProZoomObject: String;
    public locProZoomAttribut: String;
    public inputSearch: String;
    public parentZoomBindFields: Array<string>;
    public parentTableBindValues: Array<string>;
    public guid?: string;
    public zoomParameter?: any;
    public id: string;
    public linesPerPage: String;
    public parentGuid?: string;
    public locProZoomId?: string;
    public exportButton?: Boolean= true;
    public url?: string;
    public data?: PaginatedData;
    public path?: any;
    public isFromChild: boolean = false;
    public isMainZoom(): Boolean {
        return Util.isNullOrUndefined(this.bindingProp) && Util.isNullOrUndefined(this.bindingValueKeys);
    }

    public displayAddButton(): Boolean {
        return !Util.isNullOrUndefined(this.urlNew);
    }
}
