<lp-label-field *ngIf="label" for="{{id}}" class="control-label" [hidden]="this.formStackService.getFields(name).hidden"
[ignoreChange]="ignoreChange" text="{{translationPrefix}}{{label}}"  [required]="this.formStackService.getFields(name).mandatory"></lp-label-field>

<span  [class.readonly]="this.formStackService.getFields(name).readonly" [class.disabled]="disabled" [hidden]="this.formStackService.getFields(name).hidden">
  <div class="input-group">
    <input class="textField form-control" placeholder="{{placeholder | translate}}" [(ngModel)]="displayValue" (keydown)="keyDown($event, id)" [ngStyle]="style" [owlDateTime]="dt1"
    (focus)="focus($event)" (focusout)="pushChange()" [required]="this.formStackService.getFields(name).mandatory && !this.formStackService.getFields(name).hidden" [disabled]="this.formStackService.getFields(name).readonly" title=""/>
    <i class="input-notif"></i>
    <owl-date-time #dt1></owl-date-time>
    <input 
      [owlDateTimeTrigger]="dtPicker3"
      [owlDateTime]="dtPicker3" 
      class="textField form-control" 
      [(ngModel)]="selectedMoments"
      [ngStyle]="style"      
      type="hidden"
      [selectMode]="'range'"/>
    <span class="input-group-btn">
      <button type="button" tabindex="-1"  class="btn waves-effect waves-light btn-primary btn-calendar" [owlDateTimeTrigger]="dtPicker3" (click)="openCalendar()">
        <i class="icon vega-openingHours"></i>
      </button>
    </span>
  </div>
  <owl-date-time [pickerMode]="'dialog'" backdropClass="calendarBackdropClass" [firstDayOfWeek]=1 #dtPicker3   (afterPickerClosed)="selectedMomentsChange($event, 'focusout', id)"></owl-date-time>
</span>
<div *ngIf="validDate==false" class="alert alert-danger" required>
  <input style="width:0px;display:inline-block;border:none;background-color:#f2dede;color:#a94442;" required> Date non valide.
</div>