import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { OWL_DATE_TIME_FORMATS, OwlDateTimeIntl, OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { GeneralParametersComponent } from 'app/Forms/general_parameters/general-parameters.component';
import { AppModuleLp } from 'app/lp/app.module.lp';
import { environment } from 'environments/environment';
import { AlocproThirdPartyLibsModule } from '../alocpro-third-party-libs.module';
import { ZoomComponent } from '../zoom/zoom.component';
import { LpButtonCancelComponent } from './lp-button-cancel/lp-button-cancel.component';
import { LpButtonNewComponent } from './lp-button-new/lp-button-new.component';
import { LpButtonSearchComponent } from './lp-button-search/lp-button-search.component';
import { LpCardContentComponent } from './lp-card/card-content.component';
import { LpCardFooterComponent } from './lp-card/card-footer.component';
import { LpCardHeaderComponent } from './lp-card/card-header.component';
import { LpCardComponent } from './lp-card/card.component';
import { LpCellComponent } from './lp-cell/lp-cell.component';
import { LpCommonToolsComponent } from './lp-common-tools/lp-common-tools.component';
import { LpDashboardComponent } from './lp-dashboard/lp-dashboard.component';
import { LpFieldDetailsComponent } from './lp-field-details/lp-field-details.component';
import { LpFooterComponent } from './lp-footer/lp-footer.component';
import { LpFormCardComponent } from './lp-form-card/lp-form-card.component';
import { LpModalPromiseAlertComponent } from './lp-modal/lp-modal-alert/lp-modal-alert.component';
import { LpModalChangeLocationComponent } from './lp-modal/lp-modal-change-location/lp-modal-change-location.component';
import { LpModalChangePasswordComponent } from './lp-modal/lp-modal-change-password/lp-modal-change-password.component';
import { LpModalChangeComponent } from './lp-modal/lp-modal-change/lp-modal-change.component';
import { LpModalConfidentialityComponent } from './lp-modal/lp-modal-confidentiality/lp-modal-confidentiality.component';
import { LpModalConfirmComponent } from './lp-modal/lp-modal-confim/lp-modal-confirm.component';
import { LpModalCustomizationComponent } from './lp-modal/lp-modal-customization/lp-modal-customization.component';
import { LpModalDashboardComponent } from './lp-modal/lp-modal-dashboard/lp-modal-dashboard.component';
import { LpModalDebugComponent } from './lp-modal/lp-modal-debug/lp-modal-debug.component';
import { LpModalEdiConfirmComponent } from './lp-modal/lp-modal-edi/lp-modal-edi-confirm/lp-edi-confirm.component';
import { LpModalEdiExitComponent } from './lp-modal/lp-modal-edi/lp-modal-edi-exit/lp-edi-exit.component';
import { LpModalEdiListComponent } from './lp-modal/lp-modal-edi/lp-modal-edi-list/lp-edi-list.component';
import { LpModalPromiseErrorRefreshTokenComponent } from './lp-modal/lp-modal-error-refresh-token/lp-modal-error-refresh-token.component';
import { LpModalPromiseErrorComponent } from './lp-modal/lp-modal-error/lp-modal-error.component';
import { LpModalEvalComponent } from './lp-modal/lp-modal-eval/lp-modal-eval.component';
import { LpModalMailComponent } from './lp-modal/lp-modal-mail/lp-modal-mail.component';
import { LpModalPreferencesComponent } from './lp-modal/lp-modal-preferences/lp-modal-preferences.component';
import { LpModalSchedulerChangeComponent } from './lp-modal/lp-modal-scheduler-confirm/lp-modal-scheduler-confirm';
import { LpModalPromiseSpoolerPreviewComponent } from './lp-modal/lp-modal-spooler-preview/lp-modal-spooler-preview.component';
import { LpModalTarifComponent } from './lp-modal/lp-modal-tarif/lp-modal-tarif.component';
import { LpModalPromiseWarningComponent } from './lp-modal/lp-modal-warning/lp-modal-warning.component';
import { LpModalWidgetPreviewComponent } from './lp-modal/lp-modal-widget-preview/lp-modal-widget-preview.component';
import { LpModalWorkflowConfirmComponent } from './lp-modal/lp-modal-workflow/lp-modal-workflow-confirm/lp-workflow-confirm.component';
import { LpModalWorkflowExitComponent } from './lp-modal/lp-modal-workflow/lp-modal-workflow-exit/lp-workflow-exit.component';
import { LpModalWorkflowListComponent } from './lp-modal/lp-modal-workflow/lp-modal-workflow-list/lp-workflow-list.component';
import { LpModalPromiseZoomComponent } from './lp-modal/lp-modal-zoom/lp-modal-zoom.component';
import { LpModalComponent } from './lp-modal/lp-modal.component';
import { LpModalformChangeComponent } from './lp-modalform-change/lp-modalform-change.component';
import { LpOutbarPreferencesComponent } from './lp-outbar-preferences/lp-outbar-preferences.component';
import { LpOutbarComponent } from './lp-outbar/lp-outbar.component';
import { LpPdfViewerComponent } from './lp-pdf-viewer/lp-pdf-viewer.component';
import { LpProgressBarComponent } from './lp-progress-bar/lp-progress-barcomponent';
import { LpSpinnerComponent } from './lp-progress-spinner/spinner.component';
import { LpReportSpoolerComponent } from './lp-report-spooler/lp-report-spooler.component';
import { LpMailReportComponent } from './lp-report/lp-mail-report/lp-mail-report.component';
import { LpPrintReportComponent } from './lp-report/lp-print-report/lp-print-report.component';
import { LpReportListComponent } from './lp-report/lp-report-list/lp-report-list.component';
import { LpReportParametersComponent } from './lp-report/lp-report-parameters/lp-report-parameters.component';
import { LpReportComponent } from './lp-report/lp-report.component';
import { LpSavePreferencesComponent } from './lp-save-preferences/lp-save-preferences.component';
import { LpSitemapComponent } from './lp-sitemap/lp-sitemap.component';
import { LpSnackBarComponent } from './lp-snack-bar/lp-snack-bar.component';
import { LpSubgroupComponent } from './lp-subgroup/lp-subgroup.component';
import { LpSVGLoaderComponent } from './lp-svg-loader/lp-svg-loader.component';
import { LpSwitcherComponent } from './lp-switcher/lp-switcher.component';
import { LpTopBannerComponent } from './lp-top-banner/lp-top-banner.component';
import { LpUiAccordionGroupComponent } from './lp-ui-accordion/lp-ui-accordion-group.component';
import { LpUiAccordionComponent } from './lp-ui-accordion/lp-ui-accordion.component';
import { LpUiBreadcrumbComponent } from './lp-ui-breadcrumb/lp-ui-breadcrumb.component';
import { LpUiLoaderMiniComponent } from './lp-ui-loader-mini/lp-ui-loader-mini.component';
import { LpUiLoaderComponent } from './lp-ui-loader/lp-ui-loader.component';
import { LpUiPopoverInfoComponent } from './lp-ui-popover-info/lp-ui-popover-info.component';
import { LpUiSortListComponent } from './lp-ui-sort-list/lp-ui-sort-list.component';
import { LpUiZoomImageComponent } from './lp-ui-zoom-image/lp-ui-zoom-image.component';
import { LpUiZoomPdfComponent } from './lp-ui-zoom-pdf/lp-ui-zoom-pdf.component';
import { LpUiZoomTxtComponent } from './lp-ui-zoom-txt/lp-ui-zoom-txt.component';
import { LpUserEventComponent } from './lp-user-event/lp-user-event.component';
import { LpUserHistoryComponent } from './lp-user-history/lp-user-history.component';
import { WidgetComponent } from './lp-widget/widget.component';
import { LpZoomChooserComponent } from './lp-zoom-chooser/lp-zoom-chooser.component';
import { UiAccordionMenuComponent } from './ui-accordion-menu/ui-accordion-menu.component';
import { GridsterModule } from '@khajegan/angular2gridster';
import { LpModalVisualSettingsComponent } from './lp-modal/lp-modal-visual-settings/lp-modal-visual-settings.component';
import { LpModalAddCompanyComponent } from './lp-modal/lp-modal-addcompany/lp-modal-addcompany.component';
import { LpNewsComponent } from './lp-news/lp-news.component';
import { LpLinksComponent } from './lp-links/lp-links.component';
import { LpModalLinksPreviewComponent } from './lp-modal/lp-modal-links-preview/lp-modal-links-preview.component';

@NgModule({
    imports: [
        AlocproThirdPartyLibsModule,
        AppModuleLp,
        MatDialogModule,
        OwlDateTimeModule,
        OwlMomentDateTimeModule,
        GridsterModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatTabsModule,
        MatBadgeModule,
    ],
    providers: [
        { provide: OWL_DATE_TIME_FORMATS, useValue: environment.MY_MOMENT_FORMATS },
        { provide: OwlDateTimeIntl, useValue: environment.DefaultIntl }, // Calendrier
    ],
    declarations: [
        LpUiLoaderMiniComponent,
        LpCellComponent,
        LpFooterComponent,
        LpLinksComponent,
        LpSavePreferencesComponent,
        LpModalChangeLocationComponent,
        LpModalChangePasswordComponent,
        LpCommonToolsComponent,
        LpFieldDetailsComponent,
        LpModalformChangeComponent,
        LpOutbarComponent,
        LpOutbarPreferencesComponent,
        LpPdfViewerComponent,
        LpModalPreferencesComponent,
        LpModalAddCompanyComponent,
        LpModalDashboardComponent,
        LpModalVisualSettingsComponent,
        LpModalWidgetPreviewComponent,
        LpModalLinksPreviewComponent,
        LpModalDebugComponent,
        LpModalCustomizationComponent,
        LpPrintReportComponent,
        LpMailReportComponent,
        LpReportComponent,
        LpReportListComponent,
        LpModalTarifComponent,
        LpReportParametersComponent,
        LpSitemapComponent,
        LpSubgroupComponent,
        LpTopBannerComponent,
        LpUiAccordionComponent,
        UiAccordionMenuComponent,
        LpUiAccordionGroupComponent,
        LpUiBreadcrumbComponent,
        LpSVGLoaderComponent,
        LpSwitcherComponent,
        LpUiLoaderComponent,
        LpUiLoaderMiniComponent,
        LpUiPopoverInfoComponent,
        LpUiZoomImageComponent,
        LpUiZoomPdfComponent,
        LpUiZoomTxtComponent,
        LpUserEventComponent,
        ZoomComponent,
        LpButtonNewComponent,
        LpButtonSearchComponent,
        LpButtonCancelComponent,
        LpUiSortListComponent,
        LpModalComponent,
        LpModalPromiseErrorComponent,
        LpModalPromiseAlertComponent,
        LpModalPromiseErrorRefreshTokenComponent,
        LpModalPromiseWarningComponent,
        LpModalPromiseZoomComponent,
        LpModalChangeComponent,
        LpModalConfirmComponent,
        LpModalConfidentialityComponent,
        LpModalSchedulerChangeComponent,
        LpModalEvalComponent,
        LpModalMailComponent,
        WidgetComponent,
        LpModalWorkflowListComponent,
        LpModalWorkflowConfirmComponent,
        LpModalEdiConfirmComponent,
        LpModalWorkflowExitComponent,
        LpModalEdiExitComponent,
        LpDashboardComponent,
        LpCardComponent,
        LpCardContentComponent,
        LpCardFooterComponent,
        LpCardHeaderComponent,
        LpSpinnerComponent,
        LpProgressBarComponent,
        LpModalEdiListComponent,
        LpReportSpoolerComponent,
        LpUserHistoryComponent,
        LpModalPromiseSpoolerPreviewComponent,
        GeneralParametersComponent,
        LpFormCardComponent,
        LpZoomChooserComponent,
        LpSnackBarComponent,
        LpNewsComponent
    ],
    exports: [
        LpUiLoaderMiniComponent,
        LpCellComponent,
        LpFooterComponent,
        LpLinksComponent,
        LpSavePreferencesComponent,
        LpModalChangeLocationComponent,
        LpModalChangePasswordComponent,
        LpCommonToolsComponent,
        LpFieldDetailsComponent,
        LpModalformChangeComponent,
        LpOutbarComponent,
        LpPdfViewerComponent,
        LpModalPreferencesComponent,
        LpModalAddCompanyComponent,
    	LpModalDashboardComponent,
        LpModalVisualSettingsComponent,
        LpModalWidgetPreviewComponent,
        LpModalLinksPreviewComponent,
        LpModalDebugComponent,
        LpModalCustomizationComponent,
        LpPrintReportComponent,
        LpMailReportComponent,
        LpReportComponent,
        LpReportListComponent,
        LpReportParametersComponent,
        LpSitemapComponent,
        LpSubgroupComponent,
        LpTopBannerComponent,
        LpUiAccordionComponent,
        UiAccordionMenuComponent,
        LpUiAccordionGroupComponent,
        LpUiBreadcrumbComponent,
        LpSVGLoaderComponent,
        LpSwitcherComponent,
        LpUiLoaderComponent,
        LpUiLoaderMiniComponent,
        LpUiPopoverInfoComponent,
        LpUiZoomImageComponent,
        LpUiZoomPdfComponent,
        LpUiZoomTxtComponent,
        LpUserEventComponent,
        ZoomComponent,
        LpButtonNewComponent,
        LpButtonSearchComponent,
        LpButtonCancelComponent,
        LpUiSortListComponent,
        LpOutbarPreferencesComponent,
        LpModalComponent,
        LpModalPromiseErrorComponent,
        LpModalPromiseAlertComponent,
        LpModalPromiseErrorRefreshTokenComponent,
        LpModalPromiseWarningComponent,
        LpModalPromiseZoomComponent,
        LpModalTarifComponent,
        LpModalChangeComponent,
        LpModalConfirmComponent,
        LpModalConfidentialityComponent,
        LpModalSchedulerChangeComponent,
        LpModalEvalComponent,
        LpModalMailComponent,
        WidgetComponent,
        LpModalWorkflowListComponent,
        LpModalWorkflowConfirmComponent,
        LpModalEdiConfirmComponent,
        LpModalWorkflowExitComponent,
        LpModalEdiExitComponent,
        LpDashboardComponent,
        LpCardComponent,
        LpCardContentComponent,
        LpCardFooterComponent,
        LpCardHeaderComponent,
        LpSpinnerComponent,
        LpProgressBarComponent,
        LpModalEdiListComponent,
        MatBadgeModule,
        LpReportSpoolerComponent,
        LpUserHistoryComponent,
        LpModalPromiseSpoolerPreviewComponent,
    	GeneralParametersComponent,
        LpFormCardComponent,
        LpZoomChooserComponent,
        LpSnackBarComponent,
        LpNewsComponent
  ],
})

export class AppModuleUI {
}
