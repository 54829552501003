import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { Util } from 'app/statics/utils';
import { environment } from 'environments/environment';
 
@Injectable({
  providedIn: 'root'
})
export class GoogleAlalyticsService {
 
  constructor(
    private analytics: AngularFireAnalytics,
  ) {
  }
 
  public async logEvents(url: string, params?: any): Promise<void> {
    if (environment && environment.googleAnaliticsService &&  environment.googleAnaliticsService === true) {
      if (!Util.isNullOrUndefined(params)) {
        (await this.analytics.app).analytics().logEvent(url, params);
      } else {
        (await this.analytics.app).analytics().logEvent(url);
      }
    }
  }
 
}