import { Injectable,  } from '@angular/core';

import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfigService } from 'app/services/config.service';
import { Feature, GouvAddress, GouvAPIMainAddress } from 'app/meta/gouv-address';

const ADDRESS: string = 'addressServiceUrl';


@Injectable({
    providedIn: 'root'
})
export class GouvProvider {

    constructor(private httpClient: HttpClient,
                private configService: ConfigService
                ){}

    public query(params: any, type?: string): Observable<GouvAddress[]> {
        let url: string = `${this.configService.get(ADDRESS)}/search?q=${params}`;
        if(type) {
            url = url + '&type=' + type;

            if(type == "municipality") {
                url = url +'&limit=15'
            }
        }
        return this.httpClient.get(url)
            .pipe(map((item:GouvAPIMainAddress) => 
                item.features.map((f: Feature) => 
                    f.properties
                )
            ));
        }
}
