import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, THIRD_PARTY_LINKS } from '../detail-forms.component';
import { LpThirdPartyLink } from 'app/meta/third-party-link';

@Component({
  selector: 'lp-third-party-links',
  templateUrl: './third-party-links.component.html'
})

export class ThirdPartyLinksComponent extends DetailFormsComponent implements OnInit {

  public data: LpThirdPartyLink = null;

  public init(): void {
      this.data.assign(this.formStackService.currentData);
  }

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(THIRD_PARTY_LINKS);
    this.initUrl();
    // this.init();
  }

}
