
<lp-label-field *ngIf="label" for="{{id}}" class="control-label" [hidden]="this.formStackService.getFields(name).hidden"
[ignoreChange]="ignoreChange" text="{{translationPrefix}}{{label}}"  [required]="this.formStackService.getFields(name).mandatory"></lp-label-field>

<span [class.readonly]="this.formStackService.getFields(name).readonly" [ngClass]="responsivClass" *ngIf="! this.formStackService.getFields(name).hidden"> 
  <select class="{{cssClass}} {{className}}" *ngIf="!values">
    <option  selected>...</option>
  </select>
  <select id="for{{id}}" class="{{cssClass}} {{className}}" name="{{id}}" on-focusout="emitEvent();" (focus)="focus($event)" [ngModel]="(displayValue && displayValue.id) && displayValue.id || displayValue" (change)="pushChange($event)"
    [disabled]="this.formStackService.getFields(name).readonly" [required]="this.formStackService.getFields(name).mandatory && !this.formStackService.getFields(name).hidden" *ngIf="values">
    <option value="">{{'general.selectNoValue' | translate}}</option>
    <option *ngFor="let v of values" id="{{v[labelField]}}" [value]="v[idField]" [selected]="defaultValue === v[idField]">{{v[labelField] |translate}}</option>
  </select>
</span>