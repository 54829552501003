import { Component, OnInit } from '@angular/core';
import { RootformComponent } from 'app/Forms/rootform/rootform.component';

@Component({
  selector: 'lp-home',
  templateUrl: './home.component.html'
})
export class HomeComponent extends RootformComponent implements OnInit {
  public classes: String;

public async ngOnInit(): Promise<void> {  
  await super.build('dashboard', this.route.snapshot.params['ky']);
  if (this.route.snapshot.data['type'] && this.route.snapshot.data['type'] === 404) {
      this.translate.get('general.modalService.urlError').subscribe(message => {
        this.translate.get('general.modalService.loadingFailed').subscribe(title => {
          this.modalService.error(message, title);
        });
      });
      this.classes = 'error404';
    }
  }
}
