
<lp-ui-accordion id="{{id}}">
  <lp-ui-accordion-group heading="accountingEvents.title" [open]=true>
    <form id="formAccountingEvents" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>

      <div class="form-group col-xl-12">
        <lp-label-field for="accountingEvents.code" class="{{arrayClassesElements.get('defaultLabel')}}" text="accountingEvents.code"></lp-label-field>
        <lp-text-field id="accountingEvents.code" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="accountingEvents.code"
          [(ngModel)]="data.code" placeholder="accountingEvents.code"></lp-text-field>
      </div>

      <div class="form-group col-xl-12">
        <lp-label-field for="accountingEvents.wording" class="{{arrayClassesElements.get('defaultLabel')}}" text="accountingEvents.wording"></lp-label-field>
        <lp-text-field id="accountingEvents.wording" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="accountingEvents.wording"
          [(ngModel)]="data.wording" placeholder="accountingEvents.wording"></lp-text-field>
      </div>

      <div class="form-group col-xl-12">
        <lp-label-field for="accountingEvents.credit" class="{{arrayClassesElements.get('defaultLabel')}}" text="accountingEvents.credit"></lp-label-field>
        <lp-currency id="accountingEvents.credit" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="accountingEvents.credit"
          [(ngModel)]="data.credit" placeholder="accountingEvents.credit"></lp-currency>
      </div>

      <div class="form-group col-xl-12">
        <lp-label-field for="accountingEvents.debit" class="{{arrayClassesElements.get('defaultLabel')}}" text="accountingEvents.debit"></lp-label-field>
        <lp-currency id="accountingEvents.debit" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="accountingEvents.debit"
           [(ngModel)]="data.debit" placeholder="accountingEvents.debit"></lp-currency>
      </div>

      <div class="form-group col-xl-12">
        <lp-label-field for="accountingEvents.date" class="{{arrayClassesElements.get('defaultLabel')}}"  text="accountingEvents.date"></lp-label-field>
        <lp-date [(ngModel)]="data.date" class="{{arrayClassesElements.get('defaultInput')}}" id="accountingEvents.date"
        cssClass="form-control" name="accountingEvents.date" placeholder="accountingEvents.date"
        [required]=true></lp-date>
      </div>

        
      <div class="text-end">
        <button  *ngIf="!formStackService.isDataReadOnly()"><i class="vega-diskette"></i></button>
      </div>
    </form>
  </lp-ui-accordion-group>
  <lp-ui-accordion-group heading="Debugger Comptabilité" *ngIf="debugg">
    <div>
      <pre>{{ data | json }}</pre>
    </div>
  </lp-ui-accordion-group>
</lp-ui-accordion>