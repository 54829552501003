import { Component, Inject } from '@angular/core';
import { UiSyncService } from 'app/services/ui-sync.service';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { ModalService } from 'app/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { LpChangePassword } from 'app/meta/changePassword';
import { LpModalComponent } from '../lp-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lp-modal-change-password',
  templateUrl: './lp-modal-change-password.component.html',
})
export class LpModalChangePasswordComponent extends LpModalComponent {
  public dataPassword: LpChangePassword = new LpChangePassword();

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private uiSyncService: UiSyncService,
    private alocproProvider: AlocproProvider,
    private modalService: ModalService,
    private translate: TranslateService) {
      super(dialogRef, data);
    }

  public isValid(): Boolean {

    if ( !this.dataPassword['oldPassword']
        || this.dataPassword['oldPassword'] === ''
        || !this.dataPassword['newPassword']
        || this.dataPassword['newPassword'] === ''
        || this.dataPassword['newPassword'] !== this.dataPassword['newPassword2']
    ) {
      return false;
    }
    return true;
  }

  public validForm(): void {
    this.uiSyncService.loader(true, true, this.translate.instant('loading.data'));
    this.alocproProvider.put('/users/me/reset-password', '', this.dataPassword).then(() => {
      this.modalService.success(this.translate.instant('general.modalService.successChangeLocation'),
        this.translate.instant('general.modalService.changePassword'));
      this.close();
      this.uiSyncService.loader(false);
    }).catch((error) => {
      this.modalService.error(this.translate.instant('general.modalService.errorChangePassword'),
        this.translate.instant('general.modalService.changePassword'));
        this.uiSyncService.loader(false);
    });
  }
}
