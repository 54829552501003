import { LpMeta, propertyMeta } from './meta';
import { Type } from 'class-transformer';
import { LpCompany } from './company';
import { LpThirdParty } from './third-party';
import { LpAddress } from './address';
import { LpEquipment } from './equipment';
import { LpLocation } from './location';
import { LpMovement } from './movement';
import { LpInvoice } from './invoice';
import { LpRegistration } from './registration';
import { FtMeta } from './ft-meta';

export class LpDamage extends LpMeta {

    @propertyMeta('Date')
    public completeFileDate: Date;
    @propertyMeta('Date')
    public closingDate: Date;
    @Type(() => LpAddress)
    public address: LpAddress;
    @Type(() => LpEquipment)
    public equipment: LpEquipment;
    @Type(() => LpRegistration)
    public registration: LpRegistration;
    @Type(() => LpMovement)
    public movement: LpMovement;
    @propertyMeta('number')
    public mileage: number;
    @Type(() => LpLocation)
    public location: LpLocation;
    @Type(() => LpThirdParty)
    public driver: LpThirdParty;
    @Type(() => LpThirdParty)
    public customer: LpThirdParty;
    @propertyMeta('string')
    public freeRegistration: string;

    /*@Type(() => LpInsurance)
    public insurancePolicy: InsurancePolicyBaseDTO;*/

    @propertyMeta('string')
    public freeInsurancePolicy: string;
    @propertyMeta('string')
    public opposingRegistration: string;
    @propertyMeta('string')
    public opponentInsurancePolicy: string;
    @propertyMeta('boolean')
    public isPostPoned: boolean;
    @propertyMeta('number')
    public liabilityRate: number;
    @propertyMeta('number')
    public driverLiabilityRate: number;
    @propertyMeta('number')
    public estimatedAmount: number;
    @propertyMeta('number')
    public expertiseValue: number;
    @propertyMeta('number')
    public salvageValue: number;
    @propertyMeta('number')
    public marketValue: number;
    @Type(() => LpCompany)
    public company: LpCompany;
    @Type(() => LpLocation)
    public managementLocation: LpLocation;
    @Type(() => LpInvoice)
    public invoice: LpInvoice;
    @propertyMeta('number')
    public customerDeductible: number;
    @propertyMeta('number')
    public rcDeductible: number;
    @propertyMeta('number')
    public guaranteeFund: number;
    @propertyMeta('number')
    public manualBilling: number;
    @propertyMeta('number')
    public bodyEstimation: number;
    @Type(() => FtMeta)
    public status: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public statusA: FtMeta = new FtMeta();
    @propertyMeta('number')
    public opposingLiabilityRate: number;
    @Type(() => FtMeta)
    public kind: FtMeta = new FtMeta();
    @propertyMeta('number')
    public rateReclamationCost: number;
    @propertyMeta('number')
    public averageMonthlyRentalAmount: number;
    @propertyMeta('number')
    public monthsOfUse: number;
    @propertyMeta('number')
    public theoricalOdometerReturn: number;
    @propertyMeta('number')
    public monthsToMaturity: number;
    @propertyMeta('number')
    public theoricalOdometerActual: number;
    @propertyMeta('boolean')
    public hasEarlyRestitutionIndicator: boolean;
    @propertyMeta('number')
    public amountOfDamage: number;
    @propertyMeta('number')
    public rateDiscountedDepreciationBodywork: number;
    @propertyMeta('number')
    public amountDiscountedDepreciationBodywork: number;
    @propertyMeta('number')
    public netCostDamages: number;
    @propertyMeta('boolean')
    public hasIndicatorApplicationMinorityKilometerCosts: boolean;
    @propertyMeta('boolean')
    public hasIndicatorApplicationExcessKilometerCosts: boolean;
    @Type(() => FtMeta)
    public originKilometerCosts: FtMeta = new FtMeta();
    @propertyMeta('number')
    public compensationCostExcessKm: number;
    @propertyMeta('number')
    public compensationCostMinorityKm: number;
    @propertyMeta('Date')
    public effectiveDateQuotation: Date;
    @propertyMeta('number')
    public additionalDiscount: number;
    @propertyMeta('Date')
    public validationDate: Date;
    @propertyMeta('boolean')
    public hasIndicatorInclusionQuotas: boolean;
    @propertyMeta('boolean')
    public hasInsuranceValidationIndicator: boolean;
    @propertyMeta('Date')
    public insuranceValidationDate: Date;
    @propertyMeta('boolean')
    public hasIndicatorApplicationKilometersCosts: boolean;
    @propertyMeta('boolean')
    public hasIndicatorApplicatonProRatedContractualCosts: boolean;
    @Type(() => FtMeta)
    public calculationBasisStatement: FtMeta = new FtMeta();
    @propertyMeta('boolean')
    public isInvoicingMeterCosts: boolean;
    @propertyMeta('Date')
    public returnDateSimulation: Date;
    @propertyMeta('number')
    public returnOdometerSimulation: number;
    @propertyMeta('boolean')
    public isReinvoicingAppraisalCosts: boolean;
    @propertyMeta('number')
    public amountExpertiseCosts: number;
    @propertyMeta('number')
    public amountDamageBeforeWriteDown: number;
    @Type(() => LpThirdParty)
    public thirdPartyProtocole: LpThirdParty;
    @propertyMeta('number')
    public rationBetweenExcessAndMinorityMeterCost: number;
    @propertyMeta('number')
    public costWithoutDeductible: number;
    @propertyMeta('number')
    public costWithDeductible: number;
    @propertyMeta('number')
    public earlyRestitutionFinancialRent: number;
    @propertyMeta('number')
    public earlyRestitutionServiceRent: number;
    @propertyMeta('number')
    public averageAmountServiceRents: number;
    @propertyMeta('number')
    public amountServiceRentDue: number;
    @propertyMeta('number')
    public residualAmount: number;
    @propertyMeta('number')
    public netCarryingAmount: number;
    @propertyMeta('number')
    public deductibleOdometerApplied: number;
    @propertyMeta('number')
    public runningOdometer: number;
    @propertyMeta('number')
    public totalFinancialRentDue: number;
    @propertyMeta('number')
    public odometer2: number;
    @Type(() => LpThirdParty)
    public constructionSite: LpThirdParty;
    @propertyMeta('number')
    public deductibleBreakGlass: number;
    @propertyMeta('number')
    public deductibleTheftOrFire: number;
    @propertyMeta('boolean')
    public generateAMovement: boolean ;
    @propertyMeta('string')
    public declaration: string;
}
