
import { WidgetInterface } from 'app/models/widget';
import { Injectable } from '@angular/core';
import { RepositoryProvider } from './repository.provider';
import { TempWidgetProvider } from './temp-widget.provider';
import { Widget } from 'app/models/dashboard';

@Injectable({
    providedIn: 'root'
})

export class WidgetProvider {

    constructor(private dataProvider: TempWidgetProvider, private configProvider: RepositoryProvider) {}

    public async getData(widgetConfig: WidgetInterface, screen?: string): Promise<any> {
        return await this.dataProvider.getWidgetData(widgetConfig, screen);
    }

    public async getWidgetConfiguration(widgetId: string ): Promise<WidgetInterface> {
      return await this.configProvider.getWidgetConfig(widgetId);
    }

    public async getAllWidgets(): Promise<Widget[]> {
        return await this.configProvider.getAllWidgets();
    }
}
