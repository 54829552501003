<lp-label-field *ngIf="label" for="{{id}}" class="control-label" [hidden]="this.formStackService.getFields(name).hidden"
[ignoreChange]="ignoreChange" text="{{translationPrefix}}{{label}}"  [required]="this.formStackService.getFields(name).mandatory"></lp-label-field>

<span>
    <div class="input-group" [hidden]="this.formStackService.getFields(name).hidden">
      <input id="for{{id}}" name="{{name | translate}}" [pattern]="pattern" class="{{cssClass}}" [ngStyle]="style" [(ngModel)]="valueShowed" title="" (keypress)="onKeypressEvent($event, id)" (keyup)="change($event, true)"
      on-focusout="pushChange($event);" (focus)="focus($event)" minlength="{{minlength}}" maxlength="{{maxlength}}" placeholder="{{placeholder |translate}}" [disabled]="this.formStackService.getFields(name).readonly"
        [required]="this.formStackService.getFields(name).mandatory && !this.formStackService.getFields(name).hidden" [readonly]="this.formStackService.getFields(name).readonly" [class.hasIconClass]="iconClass !== undefined" (keydown)="keyDown($event, id)" autocomplete="{{autocomplete}}"/>
      <i class="input-notif"></i>
      <input type="hidden" [(ngModel)]="displayValue" />
      <span class="input-group-addon">
          <i class="{{iconClass}}"></i>
        </span>
    </div>
  </span>