import { LpMeta, propertyMeta } from './meta';
import { Type } from 'class-transformer';
import { LpAccountType } from './account-type';
import { LpCompany } from './company';
import { LpCollectiveAccountKind } from './collective-account-kind';

export class LpMainAccount extends LpMeta {
    @Type(() => LpCollectiveAccountKind)
    public collectiveAccountKind: LpCollectiveAccountKind;
    @propertyMeta('string')
    public leadingWord: string;
    @propertyMeta('string')
    public wording: string;
    @Type(() => LpAccountType)
    public accountType: LpAccountType;
    @Type(() => LpCompany)
    public company: LpCompany;
    @propertyMeta('string')
    public code: string;
    @propertyMeta('boolean')
    public isCentralize: Boolean;
    @propertyMeta('boolean')
    public isAnalytic: Boolean;
    @propertyMeta('boolean')
    public isManuelSettlement: Boolean;
    @propertyMeta('boolean')
    public isActive: Boolean;
    @propertyMeta('boolean')
    public isLettering: Boolean;
    @propertyMeta('boolean')
    public isVAT: Boolean;
    @propertyMeta('boolean')
    public isAccountingProvision: Boolean;
    @propertyMeta('boolean')
    public isScoring: Boolean;
}
