import { Component, OnInit, Input } from '@angular/core';
import { ChangeService } from 'app/services/change.service';
import { EvalService } from 'app/services/eval.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { UiSyncService } from 'app/services/ui-sync.service';
import { FormStackService } from 'app/services/form-stack.service';
@Component({
  template: ''
})
export class LpComponent implements OnInit {

  // permet de cacher l'element en mobile, tablette ou desktop
  @Input() public hideMobile: Boolean = false;
  @Input() public hideTablet: Boolean = false;
  @Input() public hideDesktop: Boolean = false;
  @Input() public id: String;
  @Input() public name: String;
  @Input() public ignoreChange: Boolean = false;


  // contient des class CSS pour l'affichage du composant.
  public responsivClass: Array<String> = [];

  protected hasChange: Boolean = false;

  constructor(
    protected changeService: ChangeService,
    protected evalService: EvalService,
    protected formMetadataProvider: FormMetadataProvider,
    protected formStackService: FormStackService,
    protected uiSyncService: UiSyncService) {
    }

  public ngOnInit(): void {
    if (this.hideMobile) {
      this.responsivClass.push('d-none');
      this.responsivClass.push('d-sm-block');
    }
    if (this.hideTablet) {
      this.responsivClass.push('d-none');
      this.responsivClass.push('d-xl-block');
    }
    if (this.hideDesktop) {
      this.responsivClass.push('d-xl-none');
    }
  }

  protected setHasChanged(changed: Boolean, data?: any): void {
    this.hasChange = changed;
    this.changeService.push(this.name);
  }
}
