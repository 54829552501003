import { Component, OnInit } from '@angular/core';
import { UiSyncService } from 'app/services/ui-sync.service';
import { UserService } from 'app/services/user.service';



@Component({
  selector: 'lp-maintenance',
  templateUrl: './maintenance.component.html'
})
export class MaintenanceComponent implements OnInit {
  constructor(
    protected uiSyncService: UiSyncService,
    private userService: UserService) {
  }

  public ngOnInit(): void {
    if (this.userService.getCurrentUser()) {
      this.userService.userLogout();
    }
  }
}
