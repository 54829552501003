import { LpMeta, propertyMeta } from './meta';
import { Type } from 'class-transformer';
import { FtMeta } from './ft-meta';
import { LpPricing } from './pricing';
import { LpPricingMethod } from './pricing-method';
import { LpService } from './service';
import { LpPricingTime } from './pricing-time';
import { LpModel } from './model';
import { LpThirdParty } from './third-party';
import { LpLocation } from './location';
import { LpCompany } from './company';
import { LpCurrency } from './currency';
import { LpUser } from './user';
import { LpInvoice } from './invoice';
import { LpEquipment } from './equipment';

export class LpPricingDetail extends LpMeta {
    @Type(() => FtMeta)
    public category: FtMeta = new FtMeta();
    @Type(() => LpPricing)
    public pricing: LpPricing = new LpPricing();
    @Type(() => LpPricingMethod)
    public pricingMethod: LpPricingMethod = new LpPricingMethod();
    @Type(() => LpService)
    public service: LpService = new LpService();
    @Type(() => LpPricingTime)
    public pricingTime: LpPricingTime = new LpPricingTime();
    @propertyMeta('string')
    public numberDaysMax: Number;
    // TODO Revoir typage application
    @Type(() => LpMeta)
    public application: LpMeta = new LpMeta();
    @Type(() => FtMeta)
    public calculationMode: FtMeta = new FtMeta();
    @propertyMeta('string')
    public calculationModeRate: Number;
    @propertyMeta('boolean')
    public isVatExempt: Boolean;
    @propertyMeta('string')
    public unitAmount: Number;
    @propertyMeta('boolean')
    public isInvoicedToCustomer: Boolean;
    @Type(() => LpModel)
    public model: LpModel = new LpModel();
    @Type(() => FtMeta)
    public equipmentType: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public acriss: FtMeta = new FtMeta();
    @Type(() => LpLocation)
    public location: LpLocation = new LpLocation();
    @Type(() => LpThirdParty)
    public thirdParty: LpThirdParty = new LpThirdParty();
    @Type(() => LpCompany)
    public company: LpCompany = new LpCompany();
    @propertyMeta('string')
    public quantity: Number;
    @propertyMeta('string')
    public discountRate: Number;
    @Type(() => LpCurrency)
    public currency: LpCurrency = new LpCurrency();
    @propertyMeta('date')
    public firstNegociationDate: Date;
    @propertyMeta('date')
    public lastNegociationDate: Date;
    @propertyMeta('date')
    public freeDate: Date;
    @Type(() => LpUser)
    public user: LpUser = new LpUser();
    @Type(() => FtMeta)
    public country: FtMeta = new FtMeta();
    // TODO Revoir typage lineCalculationMode
    @Type(() => LpMeta)
    public lineCalculationMode: LpMeta = new LpMeta();
    @propertyMeta('string')
    public quantityMax: Number;
    @propertyMeta('string')
    public rateQuantityMax: Number;
    @propertyMeta('string')
    public amountQuantityMax: Number;
    @propertyMeta('string')
    public wording: String;
    @Type(() => FtMeta)
    public axisValue1: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public axisValue2: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public axisValue3: FtMeta = new FtMeta();
    @propertyMeta('boolean')
    public canUpdateWho: Boolean;
    @propertyMeta('boolean')
    public canUpdateQuantity: Boolean;
    @propertyMeta('boolean')
    public canUpdateUnitPrice: Boolean;
    @propertyMeta('string')
    public openingDaysToInvoiced: String;
    @propertyMeta('string')
    public initialAmount: Number;
    @propertyMeta('string')
    public rateApplied: Number;
    // TODO Revoir typage lineCalculationMode
    public thirdPartyLink: any;
    @propertyMeta('string')
    public amountA: Number;
    @propertyMeta('string')
    public amountB: Number;
    @Type(() => LpInvoice)
    public invoice: LpInvoice;
    @propertyMeta('string')
    public quantityA: Number;
    @Type(() => FtMeta)
    public documentType: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public brand: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public expenseType: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public fleetPlanType: FtMeta = new FtMeta();
    // TODO Revoir typage serviceExpenseType
    @Type(() => FtMeta)
    public serviceExpenseType: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public family: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public status: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public statusA: FtMeta = new FtMeta();
    @propertyMeta('string')
    public level: String;
    // TODO Revoir typage fundingThirdParty
    public fundingThirdParty: any;
    @propertyMeta('string')
    public fundingCode: String;
    @Type(() => FtMeta)
    public discountType: FtMeta = new FtMeta();
    @propertyMeta('date')
    public updateDate: Date;
    // TODO Revoir typage analytic
    @Type(() => FtMeta)
    public analytic: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public documentTypeA: FtMeta = new FtMeta();
    // TODO Revoir typage fundingThirdParty
    public options: any;
    @propertyMeta('boolean')
    public discountRateA: Boolean;
    @propertyMeta('boolean')
    public discountRateB: Boolean;
    @propertyMeta('string')
    public temporary: String;
    @propertyMeta('string')
    public temporaryA: String;
    @Type(() => FtMeta)
    public volumeBonus: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public locationKind: FtMeta = new FtMeta();
    @Type(() => LpEquipment)
    public equipment: LpEquipment = new LpEquipment();
    @propertyMeta('action')
    public action: String;
}
