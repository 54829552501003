import { FormatService } from 'app/services/format.service';
import { TranslateService } from '@ngx-translate/core';
//import { Util } from 'app/statics/utils';

export interface Options {
  filename: string;
  fieldSeparator: string;
  quoteStrings: string;
  decimalseparator: string;
  showLabels: boolean;
  showTitle: boolean;
  title: string;
  useBom: boolean;
}

export class CsvConfigConsts {

  public static EOL: string = '\r\n';
  public static BOM: string = '\ufeff';
  public static DEFAULT_FIELD_SEPARATOR: string = ';';
  public static DEFAULT_DECIMAL_SEPARATOR: string = '.';
  public static DEFAULT_QUOTE: string = '"';
  public static DEFAULT_SHOW_TITLE: boolean = false;
  public static DEFAULT_TITLE: string = 'Mon export';
  public static DEFAULT_FILENAME: string = 'export';
  public static DEFAULT_SHOW_LABELS: boolean = true;
  public static DEFAULT_USE_BOM: boolean = true;
}

export const ConfigDefaults: Options = {
  filename: CsvConfigConsts.DEFAULT_FILENAME,
  fieldSeparator: CsvConfigConsts.DEFAULT_FIELD_SEPARATOR,
  quoteStrings: CsvConfigConsts.DEFAULT_QUOTE,
  decimalseparator: CsvConfigConsts.DEFAULT_DECIMAL_SEPARATOR,
  showLabels: CsvConfigConsts.DEFAULT_SHOW_LABELS,
  showTitle: CsvConfigConsts.DEFAULT_SHOW_TITLE,
  title: CsvConfigConsts.DEFAULT_TITLE,
  useBom: CsvConfigConsts.DEFAULT_USE_BOM
};

export class JsonCsv {

  public data: any[];
  private columns: any[];
  private _options: Options;
  private csv: string = '';

  constructor(private formatService: FormatService, private translate: TranslateService,
    columns: any[], DataJSON: any, filename: string, options?: any) {
    const config: any = options || {};

    this.columns = columns;
    this.data = typeof DataJSON !== 'object' ? JSON.parse(DataJSON) : DataJSON;

    this._options = objectAssign({}, ConfigDefaults, config);

    if (this._options.filename) {
      this._options.filename = filename;
    }
  }

  /**
   * Generate and Download Csv
   */
  public generateCsv(): void {
    if (this._options.useBom) {
      this.csv += CsvConfigConsts.BOM;
    }

    if (this._options.showTitle) {
      this.csv += this._options.title + CsvConfigConsts.EOL;
    }

    this.getHeaders();
    this.getBody();

    if (this.csv === '') {
      console.log('Invalid data');
      return;
    }

    const blob: Blob = new Blob([this.csv], { 'type': 'text/csv;charset=utf8;' });
    if (navigator.msSaveBlob) {
      const filename: string = this._options.filename.replace(/ /g, '_') + '.csv';
      navigator.msSaveBlob(blob, filename);
    } else {
      const link: HTMLAnchorElement = document.createElement('a');

      link.href = URL.createObjectURL(blob);

      link.setAttribute('visibility', 'hidden');
      link.download = this._options.filename.replace(/ /g, '_') + '.csv';

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  /**
     * Create Headers
     */
  private getHeaders(): void {

    if (this._options.showLabels) {
      let row: string = '';


     this.columns.forEach(column => {
       if (this.formatService.type['Html'] !== column.type) {
          row += (column.wording ? this.translate.instant(column.wording) : column.prop) + this._options.fieldSeparator;
       }
      });

      row = row.slice(0, -1);
      this.csv += row + CsvConfigConsts.EOL;
    }
  }

  /**
   * Create Body
   */
  private getBody(): void {
    let val: any;
    for (let i: number = 0; i < this.data.length; i++) {
      let row: string = '';

      this.columns.forEach(column => {
        if (this.formatService.type['Html'] !== column.type) {
          let columnName: string = column.prop;
          if (column.prop.indexOf('.') > -1) {
            columnName = column.prop.split('.');
            val = this.data[i];
            for (let index: number = 0; index < columnName.length; index++) {
              const name: string = columnName[index];
              if (val && val[name]) {
                val = val[name];
              } else {
                val = null;
              }
            }
          } else {
            val = this.data[i][columnName];
          }

          // Execption pour le type currency
          if(column.type === 10) {
            column.type = 1;
          } 
          row += this.formartData(this.formatService.formatValue(column.type, val)) + this._options.fieldSeparator;
        }
      });

      row = row.slice(0, -1);
      this.csv += row + CsvConfigConsts.EOL;
    }
  }

  /**
   * Format Data
   * @param {any} data
   */
  private formartData(data: any): any {
   if (this._options.decimalseparator === 'locale' && this.isFloat(data)) {
      return data.toLocaleString();
    }

    if (this._options.decimalseparator !== '.' && this.isFloat(data)) {
      return data.toString().replace('.', this._options.decimalseparator);
    }

    if (typeof data === 'string') {
      data = data.replace(/"/g, '""');
      if (this._options.quoteStrings || data.indexOf(',') > -1 || data.indexOf('\n') > -1 || data.indexOf('\r') > -1) {
        data = this._options.quoteStrings + data + this._options.quoteStrings;
      }
      return data;
    }

    if (typeof data === 'boolean') {
      return data ? 'TRUE' : 'FALSE';
    }
    return data;
  }

  /**
   * Check if is Float
   * @param {any} input
   */
  private isFloat(input: any): boolean {
    return +input === input && (!isFinite(input) || Boolean(input % 1));
  }
}


/**
 * Convet to Object
 * @param {any} val
 */
function toObject(val: any): Object {
  if (val === null || val === undefined) {
    throw new TypeError('Object.assign cannot be called with null or undefined');
  }
  return Object(val);
}

/**
 * Assign data  to new Object
 * @param {any}   target
 * @param {any[]} ...source
 */
function objectAssign(target: any, ...source: any[]): any {
  let from: any;
  const to: Object = toObject(target);
  let symbols: any;
  const hasOwnProperty: any = Object.prototype.hasOwnProperty;
  const propIsEnumerable: any = Object.prototype.propertyIsEnumerable;

  for (let s: number = 1; s < arguments.length; s++) {
    from = Object(arguments[s]);

    for (const key in from) {
      if (hasOwnProperty.call(from, key)) {
        to[key] = from[key];
      }
    }

    if ((<any>Object).getOwnPropertySymbols) {
      symbols = (<any>Object).getOwnPropertySymbols(from);
      for (let i: number = 0; i < symbols.length; i++) {
        if (propIsEnumerable.call(from, symbols[i])) {
          to[symbols[i]] = from[symbols[i]];
        }
      }
    }
  }
  return to;
}
