<div class="lp-common-tools {{cssClass}} {{className}}" [style.right]="positionFromRight ? positionFromRight+'px' : ''">
  <a *ngIf="formStackService?.currentApplicationItemDetail?.bulk && formStackService.CurrentApplicationItemDetailData?.length > 0" class="sidebar-item duplicate-btn" title="{{'commonTools.duplicate' | translate}}" (click)="duplicateLines()">
    <i class="vega-duplicate"></i>
  </a>

  <a class="accordion-close-btn sidebar-item" (click)="accordionsToggle()" title="{{'general.openCloseAccordions' | translate}}">
    <i class="vega-accordions"></i>
  </a>

  <div class="clearfix"></div>
  <lp-active *ngIf="formStackService.currentData.hasPropertyIsActive() && this.formStackService.CurrentKy !== newdataKeyWord " [(ngModel)]="formStackService.currentData.isActive" name="isActive" title="isActive" id="isActive" [binding]="false"
  [ngStyle]="{'left': getActiveBtnPosition()}" class="sidebar-item"></lp-active>
  
  <div class="clearfix"></div>
  <a class="sidebar-item" *ngIf="!ihmService.ShowSwitcher && this.formStackService.CurrentKy !== newdataKeyWord && showSwitcher()" title="{{'multiproduct.transform' | translate}}" (click)="transformIntoMutliProducts()">
    <i class="vega-multi-product"></i>
  </a>

  <div class="clearfix"></div>

  <a class="sidebar-item" *ngIf="isShown()" [class.disabled]="isDisabled()" title="{{'general.memos' | translate}} ({{formStackService?.currentData?._xVegaAttachmentsCount}})" (click)="showMemos()">
    <i *ngIf="formStackService?.currentData?._xVegaAttachmentsCount > 0" class="vega-attachment" matBadge="{{(formStackService?.currentData?._xVegaAttachmentsCount > maxInBadge) ? '+'+maxInBadge : formStackService?.currentData?._xVegaAttachmentsCount }}" matBadgeOverlap="false"></i>
    <i *ngIf="formStackService?.currentData?._xVegaAttachmentsCount === 0" class="vega-attachment"></i>
  </a>

  <div class="clearfix"></div>
  
  <a class="sidebar-item" [class.disabled]="isDisabled()" title="{{'general.workflows' | translate}} ({{formStackService?.currentData?._xVegaWorkflowsCount}})" (click)="showWorkflows()" 
    *ngIf="id && isShown() && displayWorkflows()">
    <i *ngIf="formStackService?.currentData?._xVegaWorkflowsCount > 0" class="vega-workflows" matBadge="{{(formStackService?.currentData?._xVegaWorkflowsCount > maxInBadge) ? '+'+maxInBadge : formStackService?.currentData?._xVegaWorkflowsCount }}" matBadgeOverlap="false"></i>
    <i *ngIf="formStackService?.currentData?._xVegaWorkflowsCount === 0" class="vega-workflows"></i>
  </a>

  <div class="clearfix"></div>

  <a class="sidebar-item" title="{{'general.traces' | translate}}" [class.disabled]="isDisabled() || noTrace" *ngIf="isShown() && ( displayTraces() && !displayEdis())" (click)="showTrace()">
    <i class="vega-traces"></i>
  </a>

  <div class="clearfix"></div>

  <a class="sidebar-item" *ngIf="isShown() && ( !displayTraces() && displayEdis())" (click)="showEdis()"
  [class.available]="formStackService?.currentData?._xVegaEdisCount > 0" title="{{'general.edi' | translate}} ({{formStackService?.currentData?._xVegaEdisCount}})" >
    <i *ngIf="formStackService?.currentData?._xVegaEdisCount > 0" class="vega-edi" matBadge="{{(formStackService?.currentData?._xVegaEdisCount > maxInBadge) ? '+'+maxInBadge : formStackService?.currentData?._xVegaEdisCount }}" matBadgeOverlap="false"></i>
    <i *ngIf="formStackService?.currentData?._xVegaEdisCount === 0" class="vega-edi"></i>
  </a>

  <div class="clearfix"></div>

  <a class="sidebar-item" title="{{'general.print' | translate}}" (click)="returnShowPrint(false)" [class.disabled]="isDisabled()" *ngIf="isShown()">
    <i class="vega-print"></i>
  </a>

  <div class="clearfix"></div>

  <div class="more-item" [class.hide]="hideMoreItem" *ngIf="isShown() && (displayEdis() && displayTraces())">
    <div class="content-more" >
      <a class="btn fa fa-ellipsis-h" (click)="hideMoreItem=!hideMoreItem" title="{{'general.actionOpenCloseTools' | translate}}"></a>
      <div>
          <a class="traces sidebar-more-item" title="{{'general.traces' | translate}}" [class.disabled]="isDisabled() || noTrace" *ngIf="isShown() && displayTraces()" (click)="showTrace()">
            <i class="vega-traces"></i>
          </a>
          <a class="sidebar-more-item"title="{{'general.edi' | translate}} ({{formStackService?.currentData?._xVegaEdisCount}})" *ngIf="isShown() && displayEdis()" (click)="showEdis()">            
            <i *ngIf="formStackService?.currentData?._xVegaEdisCount > 0" class="vega-edi" matBadge="{{(formStackService?.currentData?._xVegaEdisCount > maxInBadge) ? '+'+maxInBadge : formStackService?.currentData?._xVegaEdisCount }}" matBadgeOverlap="false"></i>
            <i *ngIf="formStackService?.currentData?._xVegaEdisCount === 0" class="vega-edi"></i>
          </a>
          
          <div class="clearfix"></div>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>

   

   <!--Rachat de franchise-->
    <!--<a *ngIf="isShown() && getDisplayDeductibleBuyBacks()" class="sidebar-item" title="{{'general.buyBack' | translate}}" (click)="removeOdAddDeductibleBuyBacks(addPartial)" [class.disabled]="isDisabled()" >
    <i class="vega-customer-deposits"></i>
  </a>

  <div class="clearfix"></div>

  <a *ngIf="isShown() && getDisplayDeductibleBuyBacks()" class="sidebar-item available" title="{{'general.removeBuyBack' | translate}}" (click)="removeOdAddDeductibleBuyBacks(removePartial)" [class.disabled]="isDisabled()">
    <i class="vega-customer-deposits"></i>
  </a>

  <div class="clearfix"></div>

 <a *ngIf="false" class="sidebar-item last-sidebar-item" title="{{'general.buyBack' | translate}}" (click)="removeOdAddDeductibleBuyBacks(addFull)" [class.disabled]="isDisabled()">
    <i class="vega-customer-deposits"></i>
  </a>

  <div class="clearfix"></div>

  <a *ngIf="false" class="sidebar-item last-sidebar-item available" title="{{'general.removeBuyBack' | translate}}" (click)="removeOdAddDeductibleBuyBacks(removeFull)" [class.disabled]="isDisabled()">
    <i class="vega-customer-deposits"></i>
  </a>-->

  <div class="clearfix"></div>

  <ng-container *ngIf="isShown() && formStackService.currentApplicationItem.actions && formStackService.currentApplicationItem.actions.length > 0 &&  !formStackService.isLoadingEvalActions" [class.disabled]="isDisabled()">
    <ng-container *ngFor="let action of formStackService.currentApplicationItem.actions">
      <ng-container *ngIf="action._show && !action.hidden">
        <div class="clearfix"></div>
        
        <a *ngIf="!action.childFormLink && !action.disabled" class="sidebar-item {{action.classes}}" title="{{getActionTitle(action)}}" (click)="executeRootFormAction(action)">
          <i class="{{action.icon}}"></i>
        </a>

        <lp-childform-link *ngIf="action.childFormLink && !action.disabled"
          verb="{{uiSyncService.parentVerb}}" key="{{uiSyncService.historyKey}}" [childType]="action.childFormLink.childVerb" [ky]="action.childFormLink.ky"
          title="{{getActionTitle(action)}}" class="sidebar-item {{action.classes}}">
          <i class="{{action.icon}}"></i>
        </lp-childform-link>
        
        <a *ngIf="action.childFormLink && action.disabled" disabled class="sidebar-item disabled" title="{{'rootFormActions.'+action.title+'.title' | translate}}">
          <i class="{{action.icon}}"></i>
        </a>
      </ng-container>
    </ng-container>
  </ng-container>
  
  <div class="clearfix"></div>

  <a class="sidebar-item sidebar-item" *ngIf="formStackService.isLoadingEvalActions">
    <lp-svg-loader></lp-svg-loader>
  </a>


  <div class="clearfix"></div>
  
  <div [style.right]="positionFromRight ? (positionFromRight+55)+'px' : ''" class="submit-form-buttons d-flex">

    <button *ngIf="formStackService && formStackService.CurrentVerb && formStackService.CurrentVerb === 'zooms' && formStackService.CurrentKy !== 'new'" type="button"  name="btn-delete-stack" class="sidebar-item delete-btn no-float" (click)="zoomPreview($event)"title="{{'general.button.showZoomPreview' | translate}}" [class.disabled]="disabledZoomButton()" [disabled]="disabledZoomButton()">
      <i class="icon vega-view"></i>
    </button>

    <div *ngIf="formStackService.currentApplicationItem.defaultSaveAction === undefined || formStackService.currentApplicationItem.defaultSaveAction === null || formStackService.currentApplicationItem.defaultSaveAction === 'save-back'">

      <button *ngIf="submitButton && !formStackService.isDataReadOnly() && formStackService.stack.size > 0 "
        type="submit" name="btn-save-stack" value="{{stackLoad}}" title="{{'general.button.saveAndGoBack' | translate}}" class="sidebar-item save-btn no-float" (click)="clickSubmitButton(event); stackLoad = 1;">
        <i class="icon vega-diskette-stack"></i> 
      </button>

      <button *ngIf="submitButton && !formStackService.isDataReadOnly() && !formStackService.getLastStack()?.usePreviousAPIConfig"
        name="btn-save" value="{{!stackLoad}}" title="{{'general.button.save' | translate}}" class="sidebar-item save-btn no-float" (click)="clickSubmitButton(event); stackLoad = 0;">
        <i class="icon vega-diskette"></i>
      </button>
    </div>

    <div *ngIf="formStackService.currentApplicationItem.defaultSaveAction !== undefined && formStackService.currentApplicationItem.defaultSaveAction !== null && formStackService.currentApplicationItem.defaultSaveAction === 'save'">
      <button *ngIf="submitButton && !formStackService.isDataReadOnly() && !formStackService.getLastStack()?.usePreviousAPIConfig"
        type="submit" name="btn-save" value="{{!stackLoad}}" title="{{'general.button.save' | translate}}" class="sidebar-item save-btn no-float" (click)="clickSubmitButton(event); stackLoad = 0;">
        <i class="icon vega-diskette"></i>
      </button>

      <button *ngIf="submitButton && !formStackService.isDataReadOnly() && formStackService.stack.size > 0 "
       name="btn-save-stack" value="{{stackLoad}}" title="{{'general.button.saveAndGoBack' | translate}}" class="sidebar-item save-btn no-float" (click)="clickSubmitButton(event); stackLoad = 1;">
        <i class="icon vega-diskette-stack"></i> 
      </button>
    </div>
    <button *ngIf="formStackService.isDataReadOnly()"
      type="button" title="{{'general.readonly' | translate}}" class="sidebar-item save-btn no-float disabled">
      <i class="icon vega-diskette"></i>
      <i class="icon vega-forbidden" aria-hidden="true"></i>
    </button>

    <button *ngIf="formStackService.isDeletable()  && formStackService.stack.size > 0 " type="button"  name="btn-delete-stack" class="sidebar-item delete-btn no-float" (click)="delete($event); stackLoad = 1; false">
      <i class="vega-dustbin"></i>
    </button>

    <button *ngIf="formStackService.isDeletable()  && formStackService.stack.size <= 0" type="button" name="btn-delete" class="sidebar-item delete-btn no-float" (click)="delete($event); stackLoad = 0; false">
      <i class="vega-dustbin"></i>
    </button>
  </div>  
</div>
