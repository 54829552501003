import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, TECHNICAL_SPECIFICATION } from '../detail-forms.component';
import { LpTechnicalSpecification } from 'app/meta/technical-specification';

@Component({
  selector: 'lp-technical-specifications',
  templateUrl: './technical-specifications.component.html'
})
export class TechnicalSpecificationsComponent extends DetailFormsComponent implements OnInit {

  public data: LpTechnicalSpecification = null;

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(TECHNICAL_SPECIFICATION);
    this.initUrl();
    // this.init();
  }

  public init(): void {
    this.data.assign(this.formStackService.currentDetailLineData);
  }


}
