import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '../../../../services/modal.service';
import { LpModalComponent } from '../../lp-modal.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UiSyncService } from 'app/services/ui-sync.service';
import { ChangeService } from 'app/services/change.service';
import { FormStackService } from 'app/services/form-stack.service';
import { ApplicationItem } from 'app/models/application-item';
import { ApplicationItemProvider } from 'app/providers/application-item.provider';
import { LpEdi } from 'app/meta/edi';
import { EdiProvider } from 'app/providers/edi.provider';
import { LpModalEdiExitComponent } from '../lp-modal-edi-exit/lp-edi-exit.component';
import { LpModalEdiConfirmComponent } from '../lp-modal-edi-confirm/lp-edi-confirm.component';
import { AFTER_GET_EVENT, BEFORE_GET_EVENT, BEFORE_INSERT_EVENT, BEFORE_UPDATE_EVENT, EvalService, INIT_NEW_EVENT, MAIN } from 'app/services/eval.service';
import { ConfigService } from 'app/services/config.service';
import { UserHistoryService } from 'app/services/user-history.service';

const EDIS_PREFIX: String = 'edis';
const NEW: string = 'NEW';
@Component({
  selector: 'lp-modal-edi-edit',
  templateUrl: './lp-edi-edit.component.html'
})
export class LpModalEdiEditComponent extends LpModalComponent implements OnInit {

  public relatedObject: String;
  public relatedObjectId: String;
  public ky: string;
  public verb: string;
  public listeMessages: any = {
    emptyMessage: '',
    totalMessage: ''
  };
  public edis: LpEdi[] = [];
  public currentEdi: LpEdi = new LpEdi();
  public showCancelAction: Boolean = false;
  public ediPerPage: number = this.configService.get('edisLimitInList');
  public actualPageNumber: number = 1;
  public nbEdi: number = 0;

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    public modalService: ModalService,
    public ediProvider: EdiProvider,
    private uiSyncService: UiSyncService,
    private changeService: ChangeService,
    public formStackService: FormStackService,
    public applicationItemProvider: ApplicationItemProvider,
    private evalService: EvalService,
    public configService: ConfigService,
    private userHstoryService: UserHistoryService
  ) {
    super(dialogRef, data);
    this.applicationItemProvider.getItem('edis').then((applicationItem: ApplicationItem) => {
      this.formStackService.specificApplicationItem = applicationItem;
    })
  }

  public async ngOnInit(): Promise<void> {
    await this.init();
  }

  public async closeModal(modal: String): Promise<void> {
    if ( this.changeService.isDetailsChanged2() === true) {
      const notAbandon: boolean = await this.modalService.modalPromise(LpModalEdiExitComponent, {
        backdropClass: 'commonToolsBackdropClass'
      });
      if (notAbandon === false) {
        this.changeService.clearDetailsChanged2();
        this.ediProvider.ediSelected = null;
        this.close();
      }
    } else {
      this.ediProvider.ediSelected = null;
      this.close();
    }
  }

  public async saveCurrentEdi(valid: any): Promise<void> {
    try {
      if (valid) {
        this.uiSyncService.loader(true, true, this.translate.instant('general.modalService.updateInProgress'));
        if (this.currentEdi.id) {
          await this.update();
        } else {
          const url: string = await this.setUrl();
          await this.create(url);
          this.close();
        }
        this.changeService.clearCurrentSpecificChanges(EDIS_PREFIX);
        this.changeService.clearDetailsChanged2();
      } else {
        this.modalService.error(this.translate.instant('general.modalService.formNotValid'),
          this.translate.instant('general.modalService.error'));
      }
    } catch (error) {
      throw error;
    }
  }

  public async update(): Promise<void> {
    await this.evalService.executeContrainte(BEFORE_UPDATE_EVENT, MAIN, this.formStackService.specificApplicationItem.ecrId.toString(), '*',
    this.currentEdi, this.formStackService.currentFields);
    try {
      await this.ediProvider.putEdis(this.currentEdi);
      await this.actionAfterCreateOrUpdate();
      this.userHstoryService.addEvent('modify', this.formStackService.currentApplicationItem, this.formStackService.currentData.id, this.formStackService.currentApplicationItem.verb, null, this.translate.instant('userHistory.EDI')+' (ID '+this.currentEdi.id+')');
    } catch (error) {
      this.uiSyncService.loader(false);
    }
  }

  public async create(url: string): Promise<void> {
    await this.evalService.executeContrainte(BEFORE_INSERT_EVENT, MAIN, this.formStackService.specificApplicationItem.ecrId.toString(), '*',
    this.currentEdi, this.formStackService.currentFields);
    try {
      let id = await this.ediProvider.postEdi(this.currentEdi, this.formStackService.currentApplicationItem.linkedObject, this.formStackService.currentData.id);
      await this.actionAfterCreateOrUpdate();
      this.userHstoryService.addEvent('add', this.formStackService.currentApplicationItem, this.formStackService.currentData.id, this.formStackService.currentApplicationItem.verb, null, this.translate.instant('userHistory.EDI')+' (ID '+id+')');
    } catch (error) {
      this.uiSyncService.loader(false);
    }
  }

  public async actionAfterCreateOrUpdate(): Promise<void> {
    this.uiSyncService.loader(false);
    this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
      this.translate.instant('general.modalService.success'));
  }

  public async modalDeleteEdi(): Promise<void> {
    this.ediProvider.ediSelectedForDelete = this.currentEdi.id;
    await this.modalService.modalPromise(LpModalEdiConfirmComponent, {
      backdropClass: 'alertBackdropClass'
    });
    this.close();
    this.ediProvider.ediSelected = null;
  }

  private async init(): Promise<void> {
    if (this.ediProvider &&
      this.ediProvider.ediSelected &&
     (this.ediProvider.ediSelected !== null || this.ediProvider.ediSelected !== undefined ||
      this.ediProvider.ediSelected !== NEW ) ) {
        await this.evalService.executeContrainte(BEFORE_GET_EVENT, MAIN, this.formStackService.specificApplicationItem.ecrId.toString(), '*',
        this.currentEdi, this.formStackService.specificFields);
        this.currentEdi = await this.ediProvider.findEdi(this.ediProvider.ediSelected)
        await this.evalService.executeContrainte(AFTER_GET_EVENT, MAIN, this.formStackService.specificApplicationItem.ecrId.toString(), '*', 
        this.currentEdi, this.formStackService.specificFields);
      } else if (this.ediProvider.ediSelected === NEW) {
        this.currentEdi = new LpEdi();
        await this.evalService.executeContrainte(INIT_NEW_EVENT, MAIN, this.formStackService.specificApplicationItem.ecrId.toString(), '*',
        this.currentEdi, this.formStackService.specificFields);
      } else {
        await this.evalService.executeContrainte(INIT_NEW_EVENT, MAIN, this.formStackService.specificApplicationItem.ecrId.toString(), '*',
        this.currentEdi, this.formStackService.specificFields);
      }
      this.formStackService.specificData = this.currentEdi;
  }

  private async setUrl(): Promise<string> {
    let url: string = null;
    if ( this.modalService.isMe === false && this.formStackService &&
        (this.formStackService.CurrentVerb !== undefined && this.formStackService.CurrentKy !== undefined) ) {
        url = `${this.formStackService.CurrentVerb}/${this.formStackService.CurrentKy}`;
    }
    return url;
  }


}
