import { LpMeta, propertyMeta } from '../meta';
import { LpEquipment } from '../equipment';
import { LpModel } from '../model';
import { LpUser } from '../user';
import { FtMeta } from '../ft-meta';
import { Type } from 'class-transformer';
import { LpMovement } from '../movement';
import { LpMemo } from '../memo';
import { LpInspectionChecklist } from './inspection-checklist';
import { LpInspectionFace } from './inspection-face';
import { Util } from 'app/statics/utils';


export class LpInspection extends LpMeta {
    @propertyMeta('string')
    public id: String;
    @propertyMeta('date')
    public visitDate: Date;
    @propertyMeta('date')
    public updateDate: Date;
    @Type(() => LpMemo)
    public attachments: LpMemo[];
    @propertyMeta('string')
    public groupingCode: String;
    @propertyMeta('string')
    public relativeKey: String;
    @Type(() => LpEquipment)
    public equipment: LpEquipment = new LpEquipment();
    @Type(() => LpMovement)
    public movement: LpMovement = new LpMovement();
    @Type(() => LpMovement)
    public movementEquipment: LpMovement = new LpMovement();
    @Type(() => LpModel)
    public model: LpModel = new LpModel();
    @Type(() => LpUser)
    public user: LpUser = new LpUser();
    @Type(() => FtMeta)
    public object: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public kind: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public inspectionKind: FtMeta = new FtMeta();
    @propertyMeta('string')
    public databaseAlias: String;
    @propertyMeta('string')
    public field: String;
    @propertyMeta('number')
    public mileage: Number;
    @propertyMeta('number')
    public fuel: Number;
    @propertyMeta('number')
    public fuel2: Number;
    @propertyMeta('number')
    public driverMail: Number;
    @Type(() => LpInspectionChecklist)
    public checklists: LpInspectionChecklist[];
    @Type(() => LpInspectionFace)
    public faces: LpInspectionFace[];

    public assign(data: any): LpMeta {
        // Fonctions assign modifiée car à l'ouverture d'une inspection, les faces domages n'était pas typés LpInspectionFace
        data = super.assign(data);
        if (!Util.isNullOrUndefined(data.faces)) {
            let newFaces: LpInspectionFace[] = [];
            for (let i: number = 0; i < data.faces.length; i++) {
                let newFace: LpInspectionFace = new LpInspectionFace();
                newFace.assign(data.faces[i]);
                newFaces.push(newFace);
            };
            data.faces = newFaces;
        }
        return data;
    }
}
