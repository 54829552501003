import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, FINANCIAL_ELEMENTS_LINKS } from '../detail-forms.component';
import { LpFinancialElementsLinks } from 'app/meta/financial-elements-links';

@Component({
  selector: 'lp-financial-elements-links',
  templateUrl: './financial-elements-links.component.html'
})

export class FinancialElementsLinksComponent extends DetailFormsComponent implements OnInit {

  public data: LpFinancialElementsLinks = null;

  public init(): void {
    // this.data.assign(this.formStackService.currentDetailLineData);
  }

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(FINANCIAL_ELEMENTS_LINKS);
    this.initUrl();
    this.init();
  }

}
