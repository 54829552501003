import { LpMeta, propertyMeta } from './meta';
import { Type } from 'class-transformer';
import { LpOperationKind } from './operation-kind';

export class LpLinkDamageHistoricalOperation extends LpMeta {
    @Type(() => LpOperationKind)
    public operationKind: LpOperationKind = new LpOperationKind();
    @propertyMeta('date')
    public date: Date;
    @propertyMeta('string')
    public wording: String;
}
