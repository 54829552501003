import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputFieldComponent } from '../input-field.component';
import { MAIN, FOCUS_IN_EVENT } from 'app/services/eval.service';
import { ApplicationItem } from 'app/models/application-item';

@Component({
  selector: 'lp-textarea-field',
  templateUrl: './textarea-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaFieldComponent),
      multi: true
    }
  ]
})
export class TextareaFieldComponent extends InputFieldComponent implements OnInit {

  /**Le paramètre Name permet de donner un nom personnalisé au composant.
   *
   */
  @Input() public name: string;

  /**Le paramètre InputText sera le nom du champ dans lequel les données seront émises.
   */
  @Input() public inputText: string;

  /**Le paramètre Rows permet de régler la hauteur.
   */
  @Input() public rows: string;

  /**Le paramètre Type permet de configurer le type des balises HTML qui viennent constituer le composant.
   */
  @Input() public type: string = 'text';

  /**Le paramètre Title permet de configuré un titre au composant.
   */
  @Input() public title: string;

  /**Le paramètre DefaultValue configure un texte par défaut.
   *
   */
  @Input() public defaultValue: string;

  /**Le output TextFieldEmitter va permettre l'exécution de la fonction qui lui est associée.
   * La fonction associée est déclarée dans le template où le composant est utilisé.
   */
  @Output() public textFieldEmitter: EventEmitter<any> = new EventEmitter();

  public ngOnInit(): void {
    super.ngOnInit();
    this.inputText = this.name;
  }

  public async focus(event: any): Promise<void> {
    let applicationItem: ApplicationItem = this.formStackService.currentApplicationItem;
    let data: any = this.formStackService.currentData;
    await this.evalService.executeContrainte(FOCUS_IN_EVENT, MAIN
      , applicationItem.ecrId.toString(), this.name.toString()
      , data, this.formStackService.currentFields, this.previousValue);
    let d: string = event.target.value
    this.setPreviousValue(d)
  }

  public async pushChange(event?: any, emitEvent?: String, key?: String): Promise<void> {
    super.pushChange();
    if (!this.ignoreChange) {
      this.setHasChanged(true, this);
    }
    this.setDisplayValue(event.srcElement.value.toString());
    this.putOnFocusOut();  
    this.focusOutCustomCode();    
  }
}
