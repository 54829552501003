import { Component, OnInit} from '@angular/core';
import { LpExpense } from '../../meta/expense';
import { LpMemo } from 'app/meta/memo';
import { Subscription } from 'rxjs';
import { RootformComponent } from '../rootform/rootform.component';

@Component({
  selector: 'lp-expenses',
  templateUrl: './expenses.component.html'
})

export class ExpensesComponent extends RootformComponent implements OnInit {

  public data: LpExpense = null;
  public ky: string = null;
  public memos: LpMemo[] = [];
  public refreshMemoListSubscription: Subscription;

  public async ngOnInit(): Promise<void> {
    await super.build('expenses', this.route.snapshot.params['ky']);
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.data.assign(this.formStackService.currentData);
    }
    this.refreshMemoListSubscription = this.memoProvider.refreshMemoListEvtEmitter.subscribe( (memos: Array<LpMemo>) => {
      this.memoProvider.getFullMemos('expenses', this.ky).then((fullMemos: LpMemo[]) => {
        this.memos = fullMemos;
      }) ;
    });
  }

  public click(event: Event): void {
    let newTab: Window = window.open();
    newTab.document.body.innerHTML = '<img src="' + this.data['file'] + '"/>';
  }
}
