import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'lp-button-new',
  templateUrl: './lp-button-new.component.html'
})
export class LpButtonNewComponent {
  @Output() public action: EventEmitter<any> = new EventEmitter();
  @Input() public title: String = 'general.button.new';

  constructor() { }

}
