

<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" titleTranslationKey="users.user"></lp-top-banner>

<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">

  <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''" (dblclick)="reInitCols()"></div>
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>

  <div class="container container-2-cols left" #containerCols>
    <div class="card-box col-md-6 label-2-colonnes" #col1>
      <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
        <lp-common-tools *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id" [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="deleteButton" 
          (showPrintOutput)="changeShowZoomPrint($event)" [showPrintInput]="showZoomPrint"></lp-common-tools>
          <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
        <lp-ui-accordion class="row" [menu]=true>
          <lp-ui-accordion-group heading="general.other.general" icon="fa fa-info-circle" [open]=true key="general">
            <div class="container">

              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-text-field id="id" className="textField" label="users.id"
                    class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="id"
                    [(ngModel)]="data.id" type="text"></lp-text-field>
                </div>
                <div class="form-group col-xl-6">
                    <lp-text-field id="wording" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                      name="wording" [(ngModel)]="data.wording" label="users.libelle"
                      type="text"></lp-text-field>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-xl-6">
                    <lp-zoom-field id="thirdParty" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" cssClass="form-control" [(ngModel)]="data.thirdParty" 
                    addModifyVerb="third-parties" name="thirdParty" zoomId="thirdParty" label="users.attachedThird">
                    </lp-zoom-field>
                  </div>
                <div class="form-group col-xl-6">
                  <lp-text-field id="email" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                      name="email" [(ngModel)]="data.email" type="text" label="users.email"></lp-text-field>
                </div>
              </div>
              
              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-text-field id="username" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                      name="username" [(ngModel)]="data.username" type="text" label="users.username"></lp-text-field>
                </div>
                <div class="form-group col-xl-6" *ngIf="!data.id || data.id === null">
                  <lp-text-field id="password" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" label="users.password"
                      name="password" [(ngModel)]="data.password"></lp-text-field>
                </div>
              </div>

              <div class=row>
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="location" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" cssClass="form-control" label="users.location" 
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="data.location" name="location" zoomId="location">
                  </lp-zoom-field>
                </div>
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="company" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" cssClass="form-control" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="data.company" name="company" zoomId="company" label="users.company">
                  </lp-zoom-field>
                </div>
              </div>

              <div class="row">
                  <div class="form-group col-xl-6">
                    <!--<lp-dropdown-field id="profile" class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control" label="users.profile"
                      name="profile" [(ngModel)]="data.profile" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'profile'}]}"></lp-dropdown-field>-->
                    <lp-zoom-field id="profile" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" cssClass="form-control" [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="data.profile" name="profile" zoomId="profile" label="users.profile">
                    </lp-zoom-field>
                  </div>
                  <!-- field not present on Swagger -->
                    <div class="form-group col-xl-6">
                    <lp-text-field id="level" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" label="users.level"
                          name="level" [(ngModel)]="data.level" type="text"></lp-text-field>
                  </div>
                </div>

              <div class="row"> 
                <div class="form-group col-xl-6">
                  <div class="col-md-10 col-lg-8 col-xl-6">
                    <lp-basic-file-field [(ngModel)]="data.photo" [downloadable]="false" name="photo" filename="expenses" [image]="true" accept="image/*" label="users.photo"></lp-basic-file-field>
                  </div>
                </div>
              </div>
            </div>
          </lp-ui-accordion-group>

          <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
            <div>
              <pre>{{ data | json }}</pre>
            </div>
          </lp-ui-accordion-group>
        </lp-ui-accordion>
      </div>
        <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
      </form>
    </div>
    <div class="col-md-6 card-box" #col2>
      <lp-right-col></lp-right-col>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>