import { ComponentType } from '@angular/cdk/portal';
import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

export const CLASS: string = 'modal-body';
@Component({
  selector: 'lp-modal',
  templateUrl: './lp-modal.component.html'
})
export class LpModalComponent {

  public class: string = CLASS;

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  public close(result?: any): void {
    this.dialogRef.close(result);
  }

  public async modalPromise(component: ComponentType<any>): Promise<boolean> {
    try {
      return await this.modalPromise(component);
    } catch (error) {
      throw error;
    }
  }

}
