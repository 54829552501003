import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { DateTimeAdapter} from '@danielmoncada/angular-datetime-picker';
import { DateFieldComponent } from '../date-field.component';
import { ChangeService } from 'app/services/change.service';
import { EvalService, FOCUS_OUT_EVENT, MAIN, FOCUS_IN_EVENT } from 'app/services/eval.service';
import { DatetimeService } from 'app/services/datetime.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { Util } from 'app/statics/utils';
import { FormStackService } from 'app/services/form-stack.service';
import { UiSyncService } from 'app/services/ui-sync.service';
import { ApplicationItem } from 'app/models/application-item';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { ConfigService } from 'app/services/config.service';

@Component({
  selector: 'lp-date-time',
  templateUrl: './date-time.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeComponent),
      multi: true
    }
  ]
})
export class DateTimeComponent extends DateFieldComponent implements OnInit {

  /**Le paramètre id permet de configurer un id spécifique pour le composant.
  */
  @Input() public id: string;

  /**Le paramètre Name permet de donner un nom personnalisé au composant.
   *
   */
  @Input() public name: string;

  /**Le paramètre InputDate sera le nom du champ dans lequel les données seront émises.
   */
  @Input() public inputDate: string;

  /**Le paramètre Type permet de configurer le type des balises HTML qui viennent constituer le composant.
   */
  @Input() public type: any;

  @Input() public isTouched: Boolean;

  /**Le paramètre ValidDate est un booléen qui aura la valeur TRUE,
   * lorsque la date aura le format adéquat pour être affichée dans le composant.
   */
  public validDate: Boolean = true;

  constructor(protected dateTimeAdapter: DateTimeAdapter<any>, protected changeService: ChangeService, protected evalService: EvalService,
      protected dateTimeService: DatetimeService, protected formMetadataProvider: FormMetadataProvider,
      public formStackService: FormStackService, protected uiSyncService: UiSyncService, public metaFactoryService: MetaFactoryService,
      public configService : ConfigService) {
      super(dateTimeAdapter, changeService, evalService, formMetadataProvider, uiSyncService, formStackService, metaFactoryService, configService);
    }

  public ngOnInit(): void {
    this.typeBinding = 'Date';
    super.ngOnInit();
  }

  public keyDown (event: any, key: string): void {
    if (event.key === 'Enter') {
      this.pushChange(event, FOCUS_OUT_EVENT, key)
    }
  }

  public async focus(event: any): Promise<void> {
    let d: string = this.dateTimeService.dateDBFormatComplete_2(event.srcElement.value);
    this.setPreviousValue(d)
    let applicationItem: ApplicationItem = this.formStackService.currentApplicationItem;
    let data: any = this.formStackService.currentData;
    await this.evalService.executeContrainte(FOCUS_IN_EVENT, MAIN
      , applicationItem.ecrId.toString(), this.name.toString()
      , data, this.formStackService.currentFields, this.previousValue);
  }

  public focusPicker(event: any): void {
    this.setPreviousValue(this.displayValue)
  }

  public async pushChange(event?: any, emitEvent?: string, key?: string): Promise<void> {
    super.pushChange();
    this.setDisplayValue(this.dateTimeService.dateComponent(this.displayValue));
    this.putOnFocusOut();
    this.focusOutCustomCode();   
  }

  protected transform(value: any): void {
    if (!Util.isNullOrUndefined(value)) {
      this.displayValue = this.dateTimeService.deleteZtoDate(value);
    } else {
      this.displayValue = null;
    }
  }
}