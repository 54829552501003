
import { Injectable } from '@angular/core';
import { LpShortTermContract } from 'app/meta/short-term-contract';
import { AlocproProvider } from './alocpro.provider';

const STC: string = 'short-term-contracts';
const MULTI_STC: string = 'multi-short-term-contracts';

@Injectable({
    providedIn: 'root'
})
export class MulitProductsProvider {

    constructor(
        private alocproProvider: AlocproProvider
    ) {}

    public async getList(groupingCode: string): Promise<any> {
        try {
            const url: string = `${MULTI_STC}/${groupingCode}`;
            return await this.alocproProvider.getPaginatedData(url);
        } catch (error) {
            throw error;
        }
    }

    public async getId(id: string): Promise<any> {
        try {
            const url: string = `${STC}/${id}`;
            return await this.alocproProvider.getPaginatedData(url);
        } catch (error) {
            throw error;
        }
    }

    public async post(groupingCode: string, stc: LpShortTermContract): Promise<any> {
        try {
            const url: string =  `${MULTI_STC}/${groupingCode}/${STC}`;
            return await this.alocproProvider.post(url, stc);
        } catch (error) {
            throw error;
        }
    }
}
