import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './Forms/login/login.component';
import { ExpensesComponent } from './Forms/expenses/expenses.component';
import { ShortTermContractsComponent } from './Forms/short-term-contracts/short-term-contracts.component';
import { UsersComponent } from './Forms/user/user.component';
import { LocationsComponent } from './Forms/locations/locations.component';
import { CompaniesComponent } from './Forms/companies/companies.component';
import { ModelsComponent } from './Forms/models/models.component';
import { EquipmentsComponent } from './Forms/equipments/equipments.component';
import { FinesComponent } from './Forms/fines/fines.component';
import { ThirdPartiesComponent } from './Forms/third-parties/third-parties.component';
import { InvoicesComponent } from './Forms/invoices/invoices.component';
import { PagesGuardService } from './services/pages-guard.service';
import { PlanningComponent } from './Forms/planning/planning.component';
import { MovementsComponent } from './Forms/movements/movements.component';
import { AddressesComponent } from './Forms/addresses/addresses.component';
import { ServicesComponent } from './Forms/services/services.component';
import { PaymentMethodComponent } from './Forms/payment-method/payment-method.component';
import { MainAccountsComponent } from './Forms/main-accounts/main-accounts.component';
import { DamagesComponent } from './Forms/damages/damages.component';
import { PricingDetailsComponent } from './Forms/pricing-details/pricing-details.component';
import { FTComponent } from './Forms/ft/ft.component';
import { QuickQuoteComponent } from './Forms/quick-quote/quick-quote.component';
import { ExamplesComponent } from './Forms/examples/examples.component';
import { environment } from 'environments/environment';
import { CustomersComponent } from './Forms/customers/customers.component';
import { DriversComponent } from './Forms/drivers/drivers.component';
import { PricingTimesComponent } from './Forms/pricing-times/pricing-times.component';
import { PricingMethodsComponent } from './Forms/pricing-methods/pricing-methods.component';
import { MaintenanceComponent } from './Forms/maintenance/maintenance.component';
import { ReportsComponent } from './Forms/reports/reports.component';
import { ReportsPreviewComponent } from './Forms/report-preview/report-preview.component';
import { PricingsComponent } from './Forms/pricings/pricings.component';
import { ZoomsComponent } from './Forms/zooms/zooms.component';

export const ROUTES: Routes = [
  {
    path: '',
    canActivate: [PagesGuardService],
    component: HomeComponent
  },
  {
    path: 'login',
    children: [
      { path: '', component: LoginComponent },
    ]
  },
  {
    path: 'maintenance',
    children: [
      { path: '', component: MaintenanceComponent },
    ]
  }, {
    path: 'expenses',
    canActivateChild: [PagesGuardService],
    children: [
      { path: '', component: ExpensesComponent },
      { path: ':ky', component: ExpensesComponent },
    ]
  }, {
    path: 'short-term-contracts',
    canActivateChild: [PagesGuardService],
    children: [
      { path: '', component: ShortTermContractsComponent },
      { path: ':ky', component: ShortTermContractsComponent },
    ]
  }, {
    path: 'users',
    canActivateChild: [PagesGuardService],
    children: [
      { path: '', component: UsersComponent },
      { path: ':ky', component: UsersComponent },
    ]
  }, {
    path: 'locations',
    canActivateChild: [PagesGuardService],
    children: [
      { path: '', component: LocationsComponent },
      { path: ':ky', component: LocationsComponent },
    ]
  }, {
    path: 'companies',
    canActivateChild: [PagesGuardService],
    children: [
      { path: '', component: CompaniesComponent },
      { path: ':ky', component: CompaniesComponent },
    ]
  }, {
    path: 'pricing-times',
    canActivateChild: [PagesGuardService],
    children: [
      { path: '', component: PricingTimesComponent },
      { path: ':ky', component: PricingTimesComponent },
    ]
  }, {
    path: 'pricing-details',
    canActivateChild: [PagesGuardService],
    children: [
      { path: '', component: PricingDetailsComponent },
    ]
  }, {
    path: 'models',
    canActivateChild: [PagesGuardService],
    children: [
      { path: '', component: ModelsComponent },
      { path: ':ky', component: ModelsComponent },
    ]
  }, {
    path: 'equipments',
    canActivateChild: [PagesGuardService],
    children: [
      { path: '', component: EquipmentsComponent },
      { path: ':ky', component: EquipmentsComponent },
    ]
  }, {
    path: 'fines',
    canActivateChild: [PagesGuardService],
    children: [
      { path: '', component: FinesComponent },
      { path: ':ky', component: FinesComponent },
    ]
  }, {
    path: 'third-parties',
    canActivateChild: [PagesGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      { path: '', component: ThirdPartiesComponent },
      { path: ':ky', component: ThirdPartiesComponent },
    ]
  }, {
    path: 'customers',
    canActivateChild: [PagesGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      { path: '', component: CustomersComponent },
      { path: ':ky', component: CustomersComponent },
    ]
  }, {
    path: 'drivers',
    canActivateChild: [PagesGuardService],
    runGuardsAndResolvers: 'always',
    children: [
      { path: '', component: DriversComponent },
      { path: ':ky', component: DriversComponent },
    ]
  }, {
    path: 'invoices',
    canActivateChild: [PagesGuardService],
    children: [
      { path: '', component: InvoicesComponent },
      {path: ':ky', component: InvoicesComponent },
    ]
  }/* , {
    path: 'inspections',
    canActivateChild: [PagesGuardService],
    children: [
      {path: '', component: InspectionsComponent },
      {path: ':ky', component: InspectionsComponent },
    ]
  }*/ , {
    path: 'planning',
    canActivateChild: [PagesGuardService],
    children: [
      { path: '', component: PlanningComponent },
      { path: ':ky', component: PlanningComponent },
    ]
  }, {
    path: 'movements',
    canActivateChild: [PagesGuardService],
    children: [
      { path: '', component: MovementsComponent },
      { path: ':ky', component: MovementsComponent },
    ]
}, {
  path: 'addresses',
  canActivateChild: [PagesGuardService],
  children: [
    { path: '', component: AddressesComponent },
    { path: ':ky', component: AddressesComponent },
  ]
}, {
  path: 'services',
  canActivateChild: [PagesGuardService],
  children: [
    { path: '', component: ServicesComponent },
    { path: ':ky', component: ServicesComponent },
  ]
}, {
  path: 'payment-methods',
  canActivateChild: [PagesGuardService],
  children: [
    { path: '', component: PaymentMethodComponent },
    { path: ':ky', component: PaymentMethodComponent },
  ]
}, {
  path: 'main-accounts',
  canActivateChild: [PagesGuardService],
  children: [
    { path: '', component: MainAccountsComponent },
    { path: ':ky', component: MainAccountsComponent },
  ]
}, {
  path: 'damages',
  canActivateChild: [PagesGuardService],
  children: [
    { path: '', component: DamagesComponent },
    { path: ':ky', component: DamagesComponent },
  ]
},
{
  path: 'technical/ft',
  canActivateChild: [PagesGuardService],
  children: [
    { path: '', component: FTComponent },
    { path: ':ky', component: FTComponent },
  ]
},
{
  path: 'quick-quotes',
  canActivateChild: [PagesGuardService],
  children: [
    { path: '', component: QuickQuoteComponent},
    { path: ':ky', component: QuickQuoteComponent },
  ]
},
{
  path: 'pricing-methods',
  canActivateChild: [PagesGuardService],
  children: [
    { path: '', component: PricingMethodsComponent},
    { path: ':ky', component: PricingMethodsComponent },
  ]
},
{
  path: 'examples',
  canActivateChild: [PagesGuardService],
  children: [
    { path: environment.newdataKeyWord, component: ExamplesComponent},
    { path: '1', component: ExamplesComponent}
  ]
},
{
  path: 'reports',
  canActivateChild: [PagesGuardService],
  children: [
    { path: '', component: ReportsComponent},
    { path: ':ky', component: ReportsComponent}
  ]
},
{
  path: 'report-preview',
  canActivateChild: [PagesGuardService],
  children: [
    { path: ':ky', component: ReportsPreviewComponent },
  ]
},
{
  path: 'pricings-national',
  canActivateChild: [PagesGuardService],
  component: PricingsComponent,
},
{
  path: 'pricings-custom',
  canActivateChild: [PagesGuardService],
  component: PricingsComponent,
},
{
  path: 'zooms',
  children: [
    { path: '', component: ZoomsComponent },
    { path: ':ky', component: ZoomsComponent },
  ]
},
{path: '404', component: HomeComponent, data: {'type': 404}},
{path: '**', redirectTo: '/404'}
];
