import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LpMeta } from 'app/meta/meta';
import { ZoomCriteria } from 'app/meta/zoom/zoomMeta';
import { ApplicationItem } from 'app/models/application-item';
import { FormMetadata } from 'app/models/form-metadata';
import { Guid } from 'app/models/guid';
import { HttpError } from 'app/models/http-error';
import { PaginatedData } from 'app/models/paginated-data';
import { ZoomConfig } from 'app/models/zoom-metada';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { ZoomProvider } from 'app/providers/zoom.provider';
import { ChangeService } from 'app/services/change.service';
import { ConfigService } from 'app/services/config.service';
import { EvalService, FOCUS_IN_EVENT, FOCUS_OUT_CHANGE_EVENT, FOCUS_OUT_EVENT, MAIN } from 'app/services/eval.service';
import { FieldDetailsService } from 'app/services/fieldDetails.service';
import { FormStackService } from 'app/services/form-stack.service';
import { IhmStackService } from 'app/services/ihm-stack.service';
import { JsdataService } from 'app/services/jsdata.service';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { ModalService } from 'app/services/modal.service';
import { LpModalPromiseErrorComponent } from 'app/ui/lp-modal/lp-modal-error/lp-modal-error.component';
import { LpModalPromiseZoomSchedulerComponent } from 'app/ui/lp-modal/lp-modal-zoom-scheduler/lp-modal-zoom-scheduler-scheduler.component';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Util } from 'app/statics/utils';
import { GUID_FOR_MAIN_ZOOM } from '../../../../../../../services/root/mainZoom.service';
import { UiSyncService } from '../../../../../../../services/ui-sync.service';
import { ZoomService } from '../../../../../../../services/zoom.service';
import { ValueFieldComponent } from '../value-field.component';

const timer = ms => new Promise(res => setTimeout(res, ms));

@Component({
  selector: 'lp-zoom-field',
  templateUrl: './zoom-field.component.html',
  // tslint:disable-next-line:use-host-property-decorator
  host: {
    '(document:click)': 'hostClick($event)',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ZoomFieldComponent),
      multi: true,
    },
  ],
})
export class ZoomFieldComponent extends ValueFieldComponent implements OnInit, OnDestroy, ControlValueAccessor {
  /* si on travail avec un string et pas un objet en retour de selection */
  @Input() public isString: Boolean = false;
  /* permet d'avoir le bouton pour ouvir le planning en modal */
  @Input() public isScheduler: Boolean = false;
  /**Le paramètre id permet de configurer un id spécifique pour le composant.
   * @DEPRECATED */
  @Input() public id: string;
  /**Le paramètre CssClass permet d'attribuer aux balises du composant, des noms de classe spécifiques css.
   * * @DEPRECATED */
  @Input() public cssClass: string;
  /**Le paramètre ClassName permet d'attribuer aux balises du composant, des noms de classe spécifiques.
   * * @DEPRECATED */
  @Input() public className: string;

  /**Le paramètre Binding représente les données reçus par le composant par l'intermédaire du [(ngModel)].*/
  // @Input() public binding: LpMeta;
  /**Le paramètre Required permet de rendre obligatoire le remplissage d'un champ et bloquer la validation du formulaire. */
  @Input() public required: Boolean = false;

  /**Le paramètre addModifyVerb représente la table principale sur lequel sera efféctuée la vérification de l'id.
   *
   */
  @Input() public addModifyVerb: string;

  /**Le paramètre KySearch représente la clef de la table principale sur lequel sera efféctuée la recherche
   * @ZOOM_NON_LOCPRO */
  @Input() public kySearch: string;

  /**Le paramètre libSearch représente le texte affiché dans la sélection.
   * @ZOOM_NON_LOCPRO */
  @Input() public libSearch: string;

  /* Le paramètre verbSearch représente le verb utilisé lorsque l'on veut faire une recherche sur un autre champs que l'ID
   * Exemple: zipcode pour les code postaux. requete du genre http://.../?zipCode=VALUE plutot que http://.../zipCode/VALUE*/
  @Input() public verbSearch: string;
  /**Le paramètre tableShowField représente le tableau qu'on bind à notre objet data */
  @Input() public tableBindValues: string[] = ['id', 'wording', 'details'];
  /**Le paramètre tableShowField représente le tableau de champs affiché dans le champ visible par l'utilisateur.  : firstname, lastname*/
  @Input() public tableShowField: string[] = ['id', 'wording'];

  /** TODO A REVOIR / a renommer */
  @Input() public searchTextField: String = 'id';
  /** TODO A REVOIR / a renommer */
  @Input() public inputSearch: string;

  @Input() public parentZoomGuid: string;

  /** Le paramètre contentTooltip contient le texte à afficher dans l'infobulle
   *  @NEVER_USED */
  @Input() public contentTooltip: String = '';

  /** Le paramètre placementTooltip permet de définir le placement de l'infobulle
   *  @NEVER_USED */
  @Input() public placementTooltip: String = 'top';

  /** Id du childZoom en mode locpro ou du zoom dans le json-server*/
  @Input() public zoomId: string;
  /** Le paramètre parentZoomBindFields est un tableau des nom de propriété à binder dans le formData (Objet data de l'écran).
   * tous les items de ce tableau doivent correspondre aux items du tableau parentTableBindValues.
   */
  @Input() public parentZoomBindFields: string[];
  /** Le paramètre parentTableBindValues est un tableau des nom de propriété à binder à partir du data (Objet data de de ce zoomfield).
   * tous les items de ce tableau doivent correspondre aux items du tableau parentZoomBindFields.*/
  @Input() public parentTableBindValues: string[];
  /** Le paramètre titleZoom est un string correspondant au titre du zoom utilisé lors de la recherche.  */
  @Input() public title: string;
  /* disable zoom field ex: return Location CCD */
  @Input() public disabled: Boolean = false;

  /* @NEVER_USED */
  /* ancienne propriété pour le stackItem
   @NEVER_USED */
  @Input() public urlNew: String;

  /* permet d'afficher le plus pour ajouter */
  @Input() public showAddButton: Boolean = false;
  /* permet dafficher le crayon de mofification */
  @Input() public showModifyButton: Boolean = true;
  /* Forcer le mode "modal" */
  @Input() public modal: Boolean = false;

  /* id du details (additionalDriver) de la partie de droite
   * @DEPRECATED  ancien stackItem*/
  @Input() public idRootFormdetail: String;
  /* data de la partie de droite
   * @DEPRECATED  ancien stackItem*/
  @Input() public dataRootFormdetail: LpMeta;

  /* Etat du lpFieldDetail a Droite : ouvert ou fermé
   TODO Exporter dans uiSync ?? */
  @Input() public displayLpFieldDetails: boolean = true;
  /* attribut du zoom LocPro*/
  @Input() public locProZoomAttribut: String = '';
  /* objet du zoom LocPro*/
  @Input() public locProZoomObject: String;
  /* utiliser pour afficher les critère du zoomcomponent
   Que pour du zoom de zoom */
  @Input() public inLocProZoom: boolean = false;
  @Input() public functionToExecute: Function;
  @Input() public hidden: Boolean = false;
  @Input() public readonly: Boolean = false;
  @Input() public label: string;

  /* evènement utilisé lors du click sur le planning en modal pour selectionner un equipement*/
  @Output() public putEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() public closeParent: EventEmitter<any> = new EventEmitter();

  public classError404: boolean = false;

  /**Le paramètre Data représente le stockage des données lorsque l'on fait une recherche à l'aide du filtre.
   * Les resultats de cette recherche sont automatiquement stockés dans ce paramètre.
   */
  public data: Array<any> = new Array;
  public searched: String;
  /**Le paramètre TextDataSearch représente la zone de recherche qui apparait lorsque l'on édite une chaine de caractère.
   * En résumé, il indique ou non, la présence du filtre.
   */
  public textDataSearch: Boolean;
  public newdataKeyWord: string = this.configService.get('newdataKeyWord');
  public isMainData: boolean = false;
  public loading: Boolean = false;
  private subscriptionDisplayZoom: Subscription;
  private reInitZoom: Subscription;
  private refreshZoomDisplay: Subscription;
  private changeRightBlockEvtEmitterSubscribe: Subscription;
  private evalEventEmitterSubscribe: Subscription;
  private selectEventEmitterSubscribe: Subscription;
  private zoomUpdate: Subscription;
  private initZoom: Subscription;
  private zoomConfig: ZoomConfig;
  private hasChanged: Number = 0;
  private guid: string;

  private isAlredySetFormMetadata: boolean = false;
  /**Le paramètre tableBindValues représente le tableau de champs sur lequel devront être bindée les nouvelles données.
   */

  private zoomParameterMocked: ZoomConfig = JSON.parse(
    '{"columns": [{"wording": "general.other.id","prop": "id","canAutoResize": false,"width": 150,"isDisplayed": true},{"wording": "general.other.wording","prop": "wording","summaryFunc": "sum","canAutoResize": false,"isDisplayed": true}],"columnsMode": "force","criterias": [],"criteriasByLine": 4,"displayCriterias": true,"hasButtonAdd": false,"hasButtonCriterias": false,"hasButtonOk": true,"hasButtonUpdate": false,"id": null,"order": "1","searchLimit": 30,"type": 3,"wording": "Client" }');

  private originalValue: any;

  constructor(
    private _eref: ElementRef,
    private zoomService: ZoomService,
    public formStackService: FormStackService,
    private ihmService: IhmStackService,
    private zoomProvider: ZoomProvider,
    private modalService: ModalService,
    protected uiSyncService: UiSyncService,
    protected formMetadataProvider: FormMetadataProvider,
    protected translate: TranslateService,
    protected changeService: ChangeService,
    protected evalService: EvalService,
    private detailService: FieldDetailsService,
    private jsdataService: JsdataService,
    protected metaFactoryService: MetaFactoryService,
    protected configService: ConfigService) {
    super(changeService, evalService, formStackService, formMetadataProvider, uiSyncService, metaFactoryService, configService);
  }

  public async ngOnInit(): Promise<void> {
    try {
      this.guid = Guid.newGuid().toString();
      let fields: FormMetadata = this.formStackService.currentFields.get(this.name);
      if (!Util.isNullOrUndefined(fields)) {
        fields.zoomId = this.zoomId;
        this.formStackService.currentFields.set(this.name, fields);
      }
      super.ngOnInit();
      await this.initialization();
    } catch (error) {
      throw error;
    }
  }

  public async initialization(): Promise<void> {
    try {
      await this.init();
      await this.prepareForDropDownMode();
      await this.subscription();
      await this.setOriginalValueForDetectChanges();
      /*if (this.displayValue instanceof LpMeta && !Util.isNullOrUndefined(this.displayValue.id)){
       let found: boolean= false
       for (let t of this.zoomConfig.tableShowField)  {
       if(!Util.isNullOrUndefined(this.displayValue[t])){
       found = true
       }
       }
       if(!found){
       await this.getRowData(this.displayValue.id.toString(), null, null);
       }
       }*/
    } catch (error) {
      throw error;
    }
  }

  public async init(): Promise<void> {
    try {
      this.zoomConfig = await this.initZoomConfig(false);
      // this.disabled = this.zoomConfig.disabled;

      if (!Util.isNullOrUndefined(this.zoomConfig.showAddButton)) {
        this.showAddButton = this.zoomConfig.showAddButton;
      }

      if (!Util.isNullOrUndefined(this.zoomConfig.showModifyButton)) {
        this.showModifyButton = this.zoomConfig.showModifyButton;
      }

      this.displayLpFieldDetails = !!this.zoomConfig.showLpFieldDetails;
      this.isScheduler = this.zoomConfig.isScheduler;
      if (!this.zoomService.haveAaddModifyVerbEqualNull(this.zoomConfig)) {
        this.addModifyVerb = this.zoomConfig.addModifyVerb.toString();
      }
      if (!Array.isArray(this.tableShowField) || (Array.isArray(this.tableShowField) && this.tableShowField.length === 0)) {
        this.tableShowField = this.tableBindValues;
      }
      this.internalValue = await this.inputTextFormatter(false);
    } catch (error) {
      throw error;
    }
  }

  public async subscription(): Promise<void> {
    try {
      this.subscriptionDisplayZoom = this.uiSyncService.displayZoomEvtEmitter.subscribe((param?: any) => {
        this.loading = false;
      });
      this.changeRightBlockEvtEmitterSubscribe = this.uiSyncService.changeRightBlockEvtEmitter.subscribe((record) => {
        // Lorsque l'utilisateur change le bloc affiché à droite, on récupère son ID pour le mettre dans l'objet ItemStack
        // au cas où l'on demande l'affichage d'un formulaire enfant et que l'on revient sur ce formulaire.
        if (record.key) {
          this.idRootFormdetail = record.key;
        } else {
          this.idRootFormdetail = '';
        }
      });
      this.zoomUpdate = this.uiSyncService.updateZoomEval.subscribe(async (array: Array<any>) => {
        if (!Util.isNullOrUndefined(this.zoomConfig.bindingProp)
            && !Util.isNullOrUndefined(array[0])
            && !Util.isNullOrUndefined(array[0][this.zoomConfig.bindingProp.toString()])
            && !Util.isNullOrUndefined(array[0][this.zoomConfig.bindingProp.toString()].id)
            && this.displayValue && this.displayValue.id && this.displayValue.id && array[1] &&
            array[1] === this.id) {
          this.setDisplayValue(array[0][this.zoomConfig.bindingProp.toString()]);
          await this.getRowData(this.displayValue.id, null, null, false);
        }
      });
      this.initZoom = this.zoomService.readyToInitZoom.subscribe(async () => {
        this.zoomConfig = await this.initZoomConfig(false);
      });
      this.uiSyncService.loadObjectEvtEmitter.subscribe(async () => {
        if (Util.isNullOrUndefined(this.zoomConfig.url) && Util.isNullOrUndefined(this.zoomConfig.data) && !this.classError404) {
          this.internalValue = await this.inputTextFormatter(false);
        }
      });
      this.reInitZoom = this.zoomService.reInitZoomEvtEmitter.subscribe((id: any) => {
        if (id === this.zoomId) {
          this.deleteBinding();
        }
      });
      this.refreshZoomDisplay = this.zoomService.refreshZoomDisplayValueEvtEmitter.subscribe(() => {
        this.setDisplayValue(this.formStackService.currentData[this.id]);
      });
    } catch (error) {
      throw error;
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.reInitZoom) {
      this.reInitZoom.unsubscribe();
    }
    if (this.refreshZoomDisplay) {
      this.refreshZoomDisplay.unsubscribe();
    }
    if (this.changeRightBlockEvtEmitterSubscribe) {
      this.changeRightBlockEvtEmitterSubscribe.unsubscribe();
    }
    if (this.evalEventEmitterSubscribe) {
      this.evalEventEmitterSubscribe.unsubscribe();
    }
    if (this.selectEventEmitterSubscribe) {
      this.selectEventEmitterSubscribe.unsubscribe();
    }
    if (this.initZoom) {
      this.initZoom.unsubscribe();
    }
    if (this.zoomUpdate) {
      this.zoomUpdate.unsubscribe();
    }
    if (this.subscriptionDisplayZoom) {
      this.subscriptionDisplayZoom.unsubscribe();
    }
    this.classError404 = false;
    // this.zoomService.clearConfig(this.guid);
  }

  public propagateChange = (_: any) => {
  };

  public scheduler(): void {
    this.zoomService.loadingEquipmentZoom = true;
    this.modalService.modalPromise(LpModalPromiseZoomSchedulerComponent, {
      height: '90vh',
      width: '80vw',
      backdropClass: ['darkBackdropClass', 'backdropScheduler'],
      id : 'modalWithHeader',
      data: {},
    });
    this.zoomService.selectItemFromSchedulerZoomEvtEmitter.subscribe((row: any) => {
      this.zoomService.setConfig(this.zoomConfig, this.guid).then(() => {
        this.getRowData(row.id.toString(), undefined, undefined, undefined, true);
      }).catch((err) => {
      });
    });
  }

  public keyD(event: any, value: String): void {
    if (event.key === 'Enter' || event.key === 'Tab') {
      if (Util.isNullOrUndefined(value) || value === '') {
        this.deleteBinding();
      } else {
        this.updateDefaultValueField(event, value, null);
      }
    }
  }

  public async search(event?: any): Promise<void> {
    this.uiSyncService.loader(true, false, this.translate.instant(this.translate.instant('loading.data')));
    this.loading = true;
    return new Promise<void>(async (resolve) => {
      if (!this.zoomConfig.modal) {
        this.ihmService.displayRightZoomComponent();
      } else {
        this.ihmService.ShowRightZoomComponent = false;
      }
      if (this.parentZoomGuid !== null && this.parentZoomGuid !== undefined) {
        this.zoomConfig.parentGuid = this.parentZoomGuid;
        this.closeParent.emit();
      }
      setTimeout(async () => {
        await this.zoomService.displayZoom(this.zoomConfig, this.guid, this.locProZoomAttribut.toString(), this.parentZoomGuid).then(() => {
          this.selectEventEmitterSubscribe = this.zoomService.selectEventEmitter.subscribe(async (row: Object) => {
            this.previousValue = Object.assign({}, this.displayValue);
            if (this.classError404 === true) {
              this.classError404 = false;
            }
            if (this.zoomService.currentGuid === this.guid) {
              if (!this.zoomService.isStringMode(this.zoomConfig)) {
                this.searched = null;
              }
              const id: String = row['id'];
              if (!Util.isNullOrUndefined(this.zoomService.getConfig(this.guid).url)) {
                let d: LpMeta = new LpMeta();
                d.id = id;
                await this.getRowData(id.toString(), null, row['search'], null, true);
              } else if (this.zoomService.getConfig(this.guid).isLocProZoom) {
                if (!this.zoomService.hasParentZoom(this.guid)
                    && (this.zoomService.getConfig(this.guid).locProZoomAttribut.indexOf(GUID_FOR_MAIN_ZOOM) < 0
                    || this.zoomService.getConfig(this.guid).locProZoomAttribut === "vatDomain")) {
                  await this.getRowData(id.toString(), null, row['search'], null, true);
                } else {
                  this.inputTextFormatter(true).then((internalvalue: string) => {
                    this.internalValue = internalvalue;
                  });
                }
              } else {
                await this.getRowData(id.toString(), null, row['search'], null, true);
              }
              this.compareAndSetChanges(this.displayValue);
              let applicationItem: ApplicationItem;
              let data: LpMeta;
              if (this.formStackService.useSpecificApplicationItem(this.name)) {
                applicationItem = this.formStackService.specificApplicationItem;
                data = this.formStackService.specificData;
              } else {
                applicationItem = this.formStackService.currentApplicationItem;
                data = this.formStackService.currentData;
              }
              ;
              if (this.displayValue && this.previousValue.id !== this.displayValue.id) {

                // if (Util.isNullOrUndefined(this.ignoreChange) || !this.ignoreChange) {
                // this.setHasChanged(true, this);
                //}
                await this.evalService.executeContrainte(FOCUS_OUT_CHANGE_EVENT, MAIN
                  , applicationItem.ecrId.toString(), this.name.toString()
                  , data, this.formStackService.currentFields, this.previousValue);
              }
              this.evalService.executeContrainte(FOCUS_OUT_EVENT, MAIN,
                applicationItem.ecrId.toString(), this.name.toString()
                , data, this.formStackService.currentFields);
            }
            // this.zoomService.clearConfig(this.guid);
            resolve();
          });
        });
      }, 0);
    });
  }

  /*
   used to be linked to (focus) event on the template
   public focus(event: any): void {
   let d: string = event.target.value
   this.setPreviousValue(d)
   this.searched = '';
   }
   */

  public async focusout(event: Event, inputSearched: String, e?: string, key?: string, previousSearch?: String): Promise<void> {
    this.loading = true;
    this.previousValue = Object.assign({}, this.displayValue);
    if (!Util.isNullOrUndefined(this.zoomConfig) && !Util.isNullOrUndefined(this.zoomConfig.url)) {
      this.classError404 = false;
      if (Util.isNullOrUndefined(inputSearched) || inputSearched === '') {
        inputSearched = previousSearch;
      }
      if (!Util.isNullOrUndefined(this.zoomConfig) && this.zoomService.isStringMode(this.zoomConfig)) {
        let d: String;
        d = inputSearched;
        await this.zoomService.setConfig(this.zoomConfig, this.guid);
        if (!Util.isNullOrUndefined(d)) {
          await this.getRowData(d.toString(), null, null, null, true);
        }
        if (!Util.isNullOrUndefined(inputSearched) && inputSearched !== '') {
          this.internalValue = this.displayValue;
        } else {
          this.internalValue = null;
        }
      } else {
        let d: LpMeta = new LpMeta();
        d.id = inputSearched;
        await this.zoomService.setConfig(this.zoomConfig, this.guid);
        if (!Util.isNullOrUndefined(d) && !Util.isNullOrUndefined(d.id)) {
          await this.getRowData(d.id.toString(), null, null, null, true);
        }
        if (!Util.isNullOrUndefined(inputSearched) && inputSearched !== '') {
          if (!Util.isNullOrUndefined(this.displayValue) && !Util.isNullOrUndefined(this.displayValue.id)) {
            if (this.classError404 === false) {
              this.internalValue = this.displayValue[this.zoomConfig.tableShowField[0]];
              //this.internalValue = this.displayValue.id;
            }
          }
        } else {
          this.internalValue = null;
          this.searched = null;
          this.displayValue = null;
        }
      }
      if (this.classError404 === false) {
        this.propagateChange(this.displayValue);
      }
      this.loading = false;
    } else {
      // this.classError404 = false;
      let tempTarget: any = event.target;
      if (tempTarget.value === '') {
        this.deleteBinding();
        let applicationItem: ApplicationItem;
        let data: LpMeta;
        if (this.formStackService.useSpecificApplicationItem(this.name)) {
          applicationItem = this.formStackService.specificApplicationItem;
          data = this.formStackService.specificData;
        } else {
          applicationItem = this.formStackService.currentApplicationItem;
          data = this.formStackService.currentData;
        }
        //if (!Util.isNullOrUndefined(this.displayValue) && this.previousValue.id !== this.displayValue.id) {
        if (this.zoomService.isStringMode(this.zoomConfig)) {
          if (this.previousValue !== this.displayValue) {
            await this.evalService.executeContrainte(FOCUS_OUT_CHANGE_EVENT, MAIN
              , applicationItem.ecrId.toString(), this.name.toString()
              , data, this.formStackService.currentFields, this.previousValue);
          }
        } else {
          if (!Util.isNullOrUndefined(this.displayValue) && this.previousValue.id !== this.displayValue.id) {
            await this.evalService.executeContrainte(FOCUS_OUT_CHANGE_EVENT, MAIN
              , applicationItem.ecrId.toString(), this.name.toString()
              , data, this.formStackService.currentFields, this.previousValue);
          }
        }
        this.evalService.executeContrainte(FOCUS_OUT_EVENT, MAIN,
          applicationItem.ecrId.toString(),
          this.name.toString(), data, this.formStackService.currentFields);
        this.loading = false;
      } else {
        if (Util.isNullOrUndefined(inputSearched) || inputSearched === '') {
          inputSearched = previousSearch;
        }
        if (previousSearch !== inputSearched) {
          this.zoomService.setConfig(this.zoomConfig, this.guid).then(() => {
            if (this.zoomService.isStringMode(this.zoomConfig)) {
              let criterias: ZoomCriteria[] = this.zoomService.getConfig(this.guid).zoomParameter.criterias;
              criterias[0].defaultValue = inputSearched.toString();
              this.zoomProvider.getRowWithFilters(
                this.zoomService.getConfig(this.guid), criterias, this.formStackService.currentData, '1', 1)
                .then((paginatedData: PaginatedData) => {
                  if (paginatedData.count > 0) {
                    let applicationItem: ApplicationItem;
                    let data: LpMeta;
                    if (this.formStackService.useSpecificApplicationItem(this.name)) {
                      applicationItem = this.formStackService.specificApplicationItem;
                      data = this.formStackService.specificData;
                    } else {
                      applicationItem = this.formStackService.currentApplicationItem;
                      data = this.formStackService.currentData;
                    }
                    this.getRowData(paginatedData.body[0]['field0'], this.verbSearch, inputSearched, null, true).then(async () => {
                      if (this.previousValue.id !== this.displayValue.id) {
                        await this.evalService.executeContrainte(FOCUS_OUT_CHANGE_EVENT, MAIN
                          , applicationItem.ecrId.toString(), this.name.toString()
                          , data, this.formStackService.currentFields, this.previousValue);
                      }
                      await this.evalService.executeContrainte(FOCUS_OUT_EVENT, MAIN,
                        applicationItem.ecrId.toString(), this.name.toString()
                        , data, this.formStackService.currentFields);
                      this.loading = false;
                    });
                  } else {
                    let error: HttpError = new HttpError(new HttpErrorResponse({}), this.configService);
                    error.translateKey = this.translate.instant('httpError.datasNotFound');
                    this.modalService.modalPromise(LpModalPromiseErrorComponent, {
                      backdropClass: 'alertBackdropClass',
                    }, error);
                    this.internalValue = null;
                    this.loading = false;
                  }
                });
            } else {
              this.getRowData(inputSearched.toString(), this.verbSearch, inputSearched, null, true).then(async () => {
                let applicationItem: ApplicationItem;
                let data: LpMeta;
                if (this.formStackService.useSpecificApplicationItem(this.name)) {
                  applicationItem = this.formStackService.specificApplicationItem;
                  data = this.formStackService.specificData;
                } else {
                  applicationItem = this.formStackService.currentApplicationItem;
                  data = this.formStackService.currentData;
                }
                if (this.previousValue.id !== this.displayValue.id) {
                  await this.evalService.executeContrainte(FOCUS_OUT_CHANGE_EVENT, MAIN
                    , applicationItem.ecrId.toString(), this.name.toString()
                    , data, this.formStackService.currentFields, this.previousValue);
                }
                await this.evalService.executeContrainte(FOCUS_OUT_EVENT, MAIN,
                  applicationItem.ecrId.toString(), this.name.toString()
                  , data, this.formStackService.currentFields);
                this.classError404 = false;
                this.loading = false;
              }).catch((err) => {
                this.error404Appears(err);
                this.displayValue = this.internalValue = inputSearched;
                this.loading = false;
              });
            }
          }).catch((err) => {
            this.displayValue = this.internalValue = inputSearched;
          });
        } else {
          this.inputTextFormatter(false).then((internalvalue: string) => {
            this.internalValue = internalvalue;
          });
          this.loading = false;
        }
      }
    }
    this.loading = false;
    this.compareAndSetChanges(this.displayValue);
  }

  public async focusIn(): Promise<void> {
    let applicationItem: ApplicationItem = this.formStackService.currentApplicationItem;
    let data: any = this.formStackService.currentData;
    await this.evalService.executeContrainte(FOCUS_IN_EVENT, MAIN
      , applicationItem.ecrId.toString(), this.name.toString()
      , data, this.formStackService.currentFields, this.previousValue);
    this.zoomService.addModifyVerb = this.addModifyVerb;
    setTimeout(async () => {
      this.zoomService.closeZoom();
      if (this.displayValue && this.displayValue.id && !Util.isNullOrUndefined(this.zoomConfig.addModifyVerb)) {
        if (!Util.isNullOrUndefined(this.zoomConfig.showLpFieldDetails) && this.zoomConfig.showLpFieldDetails) {
          this.ihmService.hideCurrentRight();
          // this.detailService.test = 'zoom';
          let data: Boolean = await this.detailService.buildRightDetail(
            true,
            this.zoomConfig.addModifyVerb.toString(),
            this.displayValue.id.toString(),
          );
          if (!data) {
            this.classError404 = true;
          }
          this.zoomService.displayLpFieldDetail(this.displayLpFieldDetails, this.displayValue, this.zoomId, 10, this.zoomConfig);
        }
        if ((!Util.isNullOrUndefined(this.zoomConfig) && !Util.isNullOrUndefined(this.zoomConfig.url))
            || this.zoomService.isStringMode(this.zoomConfig)) {
          if (this.classError404 === false) {
            this.searched = this.displayValue.id;
          }
        } else {
          this.internalValue = this.displayValue.id;
        }
      } else if (this.displayValue
                 && this.displayValue.id
                 && Util.isNullOrUndefined(this.zoomConfig.addModifyVerb)
                 && !this.zoomService.isStringMode(this.zoomConfig)) {
        this.internalValue = this.displayValue.id;
      }

    }, 0);
  }

  public keyDown($event: KeyboardEvent, inputSearched: String): void {
    /* doublon comportement avec focuout et génère des erreur 404
     if ($event.key === 'Tab') {
     this.compareAndSetChanges(String(inputSearched));
     this.getRowData(String(inputSearched), null, null, null, true);
     }*/
  }

  /** La fonction inputTextFormatter(), formatte l'affichage du champs texte du zoom field
   * et affiche l'objet sélectionné selon les infos saisies dans le paramètre tableShowField.
   */
  public async inputTextFormatter(change: boolean): Promise<string> { // prevoir un boolean pour tester si onchangeService
    try {
      let val: string = '';
      let internalValueTemp: string = '';
      if (this.displayValue && this.zoomConfig.searchTextField && this.displayValue[this.zoomConfig.searchTextField.toString()] !== ''
          && this.displayValue[this.zoomConfig.searchTextField.toString()] !== undefined) {
        for (let j in this.zoomConfig.tableShowField) {
          if (internalValueTemp !== '') {
            internalValueTemp += ' - ';
          }
          val = await this.jsdataService.getDataRef(this.displayValue, this.zoomConfig.tableShowField[j]);
          if (val) {
            internalValueTemp += val;
          }
        }
        if (change) {
          // this.changeService.push(this.name);
          this.compareAndSetChanges(this.displayValue);
        }
      } else if (Util.isNullOrUndefined(this.zoomConfig) || (!Util.isNullOrUndefined(this.zoomConfig)
                                                        && this.zoomService.isStringMode(this.zoomConfig))) {
        internalValueTemp = this.displayValue;
      }
      return internalValueTemp;
    } catch (error) {
      throw error;
    }
  }

  public updateDefaultValueField($event: KeyboardEvent, inputSearched: String, kySearch: string): void {
    this.zoomService.updateDefaultValueFieldEvtEmitter.emit([inputSearched, this.locProZoomAttribut, $event]);
  }

  public async hostClick(event: Event): Promise<void> {
    // Lorsque l'utilisateur click
    if (this.hasChanged === 2 || this.hasChanged === 3 || this.hasChanged === 4) {
      if (this.textDataSearch) {
        if (this.hasChanged === 4) {
        }
      } else {
        if (this.internalValue === '') {
          for (let i in this.tableBindValues) {
            this.displayValue[this.tableBindValues[i]] = undefined;
          }
        }
      }
      if (this.hasChanged === 2) {
        this.hasChanged = 0;
      } else {
        this.hasChanged = 4;
      }
    } else if (this.hasChanged === 1 && !this._eref.nativeElement.contains(event.target)) {
      await this.inputTextFormatter(true).then(async (internalvalue: string) => {
        this.internalValue = internalvalue;
      });
      this.hasChanged = 0;
    }
  }

  protected async transform(value: LpMeta): Promise<void> {
    super.transform(value);
    if (!Util.isNullOrUndefined(value) && !Util.isNullOrUndefined(this.zoomConfig)) {
      this.internalValue = await this.inputTextFormatter(false);
    }
  }

  protected async setDefaultValue(formMetaData: FormMetadata, fromPlanning: boolean = true
    , isNewForm: boolean = true): Promise<void> {
    super.setDefaultValue(formMetaData, fromPlanning, isNewForm);
    if (Util.isNullOrUndefined(this.displayValue) || Util.isNullOrUndefined(this.displayValue.id)) {
      if (!Util.isNullOrUndefined(formMetaData.defaultValue) && this.isAlredySetFormMetadata === false) {
        if (this.zoomConfig.url) {
          //this.searched = formMetaData.defaultValue;
          let d: LpMeta = new LpMeta();
          d.id = formMetaData.defaultValue;
          await this.zoomService.setConfig(this.zoomConfig, this.guid);
          await this.getRowData(d.id.toString(), null, null, null, isNewForm);
          if (!Util.isNullOrUndefined(formMetaData.defaultValue)) {
            this.internalValue = this.displayValue.id;
          }
        } else {
          await this.getRowData(formMetaData.defaultValue, null, null, null, isNewForm);
        }

        this.isAlredySetFormMetadata = true;
        this.setPreviousValue(this.searched);
      }
    } else {
      if (!Util.isNullOrUndefined(this.zoomConfig) && !Util.isNullOrUndefined(this.zoomConfig.url)) {
        await this.zoomService.setConfig(this.zoomConfig, this.guid);
      }
      if (this.zoomService.isConfigDataReady(this.guid, this.zoomConfig)) {
        await this.getRowData(this.displayValue.id, null, null, isNewForm);
      } else {
        let i: number = 0;
        while (i < 20 && !this.zoomService.isConfigDataReady(this.guid, this.zoomConfig)) {
          await timer(1000);
          await this.getRowData(this.displayValue.id, null, null, isNewForm);
          i = i + 1;
        }
      }
    }
    if (!Util.isNullOrUndefined(formMetaData.readonly)) {
      this.disabled = formMetaData.readonly;
    }
  }

  private async displayInputField(data: LpMeta, isNewForm: boolean): Promise<void> {
    try {
      if (Array.isArray(data) && data.length > 0) {
        data = data[0];
      }
      if (this.zoomService.isStringMode(this.zoomConfig)) {
        this.setDisplayValue(this.internalValue);
      } else {
        this.setDisplayValue(data);
      }
      if (this.zoomConfig.parentZoomBindFields && this.zoomConfig.parentTableBindValues && isNewForm) {
        for (let index: number = 0; index < this.zoomConfig.parentTableBindValues.length; index++) {
          if (this.zoomConfig.parentZoomBindFields[index].split('.').length === 2) {
            let tempArray: Array<any> = this.zoomConfig.parentZoomBindFields[index].split('.');
            // (si . dans le zoomId alors zoom sur la partie de droite)
            if (this.name.indexOf('.') === -1 || (this.name.indexOf('.')
                                                  > -1
                                                  && this.formStackService.currentData.isSubObject(this.name.substring(0,
                this.name.indexOf('.'))))) {
              if (this.zoomConfig.parentZoomBindFields[index].indexOf('?') === -1) {
                this.formStackService.currentData[tempArray[0]][tempArray[1]] =
                  this.jsdataService.getDataRef(data, this.zoomConfig.parentTableBindValues[index]);
              } else {
                if (Util.isNullOrUndefined(this.formStackService.currentData[tempArray[0]][tempArray[1]])) {
                  this.formStackService.currentData[tempArray[0]][tempArray[1]] =
                    this.jsdataService.getDataRef(data, this.zoomConfig.parentTableBindValues[index]);
                }
              }
            } else {
              this.formStackService.currentDetailLineData[tempArray[0]][tempArray[1]] =
                this.jsdataService.getDataRef(data, this.zoomConfig.parentTableBindValues[index]);
            }
          } else {
            if (this.name.indexOf('.') === -1 || (this.name.indexOf('.')
                                                  > -1
                                                  && this.formStackService.currentData.isSubObject(this.name.substring(0,
                this.name.indexOf('.'))))) {
              if (this.zoomConfig.parentZoomBindFields[index].indexOf('?') === -1) {
                this.formStackService.currentData[this.zoomConfig.parentZoomBindFields[index]] =
                  this.jsdataService.getDataRef(data, this.zoomConfig.parentTableBindValues[index]);
              } else {
                if (typeof this.formStackService.currentData[this.zoomConfig.parentZoomBindFields[index].slice(0, -1)] === 'object' &&
                    Util.isNullOrUndefined(this.formStackService.currentData[this.zoomConfig.parentZoomBindFields[index].slice(0, -1)].id) ||
                    typeof this.formStackService.currentData[this.zoomConfig.parentZoomBindFields[index].slice(0, -1)] === 'string' &&
                    Util.isNullOrUndefined(this.formStackService.currentData[this.zoomConfig.parentZoomBindFields[index].slice(0, -1)])) {
                  this.formStackService.currentData[this.zoomConfig.parentZoomBindFields[index].slice(0, -1)] =
                    this.jsdataService.getDataRef(data, this.zoomConfig.parentTableBindValues[index]);
                }
              }
            } else {
              this.formStackService.currentDetailLineData[this.zoomConfig.parentZoomBindFields[index]] =
                this.jsdataService.getDataRef(data, this.zoomConfig.parentTableBindValues[index]);
            }
          }
        }
      }
      this.putEvtEmitter.emit();
      this.inputTextFormatter(false).then((internalvalue: string) => {
        this.internalValue = internalvalue;
      });
      // this.zoomService.clearConfig();
      // this.zoomService.closeZoom();
      // Permet la prise en compte de l'input ignoreChange pour la modification ou l'ajout de valeur dans un zoom.
      if (!this.ignoreChange) {
        // this.changeService.setHasChanged(true)
      }
    } catch (error) {
      throw error;
    }
  }

  private async initZoomConfig(showLpFieldDetails: boolean): Promise<ZoomConfig> {
    try {
      let zoomConfig: ZoomConfig = new ZoomConfig();
      zoomConfig = this.zoomService.getConfigByZoomId(this.zoomId, this.formStackService.useSpecificApplicationItem(this.name));

      if (zoomConfig === null || zoomConfig === undefined) {
        zoomConfig = new ZoomConfig();
        zoomConfig.addModifyVerb = this.addModifyVerb;
        zoomConfig.kySearch = this.kySearch;
        zoomConfig.urlNew = this.urlNew;
        zoomConfig.historyKey = this.uiSyncService.historyKey;
        zoomConfig.modal = this.modal;
        zoomConfig.isScheduler = false;
        zoomConfig.title = this.title;
        zoomConfig.textSearch = this.searchTextField;
        zoomConfig.isClosable = true;
        zoomConfig.tableBindValues = this.tableBindValues;
        zoomConfig.tableShowField = this.tableShowField;
        zoomConfig.searchTextField = this.searchTextField.toString();
        zoomConfig.showLpFieldDetails = showLpFieldDetails;
        zoomConfig.zoomId = this.zoomId;
        zoomConfig.disabled = this.disabled;
        zoomConfig.isString = this.isString;
        zoomConfig.parentZoomBindFields = this.parentZoomBindFields;
        if (!Util.isNullOrUndefined(this.locProZoomObject) && !Util.isNullOrUndefined(this.locProZoomAttribut)) {
          zoomConfig.isLocProZoom = true;
          zoomConfig.locProZoomObject = this.locProZoomObject;
          zoomConfig.locProZoomAttribut = this.locProZoomAttribut;
        } else {
          zoomConfig.isLocProZoom = false;
        }
      }
      if (!Util.isNullOrUndefined(zoomConfig.kySearch)) {
        this.kySearch = zoomConfig.kySearch.toString();
      }
      return zoomConfig;
    } catch (error) {
      throw error;
    }
  }

  private async searchInDatas(id: string): Promise<LpMeta> {
    try {
      id = await this.breakDownIdWithDash(id);
      if (!Util.isNullOrUndefined(this.zoomConfig) && !Util.isNullOrUndefined(this.zoomConfig.data)
          && !Util.isNullOrUndefined(this.zoomConfig.data.body)) {
        let line: LpMeta = _.find(this.zoomConfig.data.body, (item: LpMeta) => {
          if (item['field0'] === undefined && !Util.isNullOrUndefined(item.id)) {
            // cas où l'on reçoit data.body = [{id: , wording: }]
            return (item.id === id || item['wording'] === id);
          } else {
            // cas où l'on reçoit data.body = [{field0: , field1: }]
            return (item['field0'] === id || item['field1'] === id);
          }
        });
        return line;
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  private async breakDownIdWithDash(id: string): Promise<string> {
    try {
      if (!Util.isNullOrUndefined(id) && id.includes(' - ')) {
        const separator: string = ' - ';
        id = id.split(separator, 1)[0];
      }
      return id;
    } catch (error) {
      throw error;
    }
  }

  // isNewForm pour ne pas appliquer les parentBindZoom sur le ngOnInit et écraser les valeur du back sur un ky
  private async getRowData(id: string, verbSearch?: String, search?: String, executeFocuOut?: boolean, isNewForm?: boolean): Promise<void> {
    try {
      this.classError404 = false;
      if (!Util.isNullOrUndefined(this.zoomConfig) && !Util.isNullOrUndefined(this.zoomConfig.url)) {
        const line: LpMeta = await this.searchInDatas(id);
        if (!Util.isNullOrUndefined(line)) {
          this.searched = line[this.zoomConfig.tableShowField[0]];
          if (!Util.isNullOrUndefined(this.zoomConfig) && !Util.isNullOrUndefined(this.zoomConfig.isString)) {
            this.internalValue = line[this.zoomConfig.tableBindValues[0]];
          }
        }
        if (Util.isNullOrUndefined(line)) {
          const error: any = { status: 404 };
          this.error404Appears(error);
          this.propagateChange(this.displayValue);
        } else {
          await this.displayInputField(line, isNewForm);
        }
        this.propagateChange(this.displayValue);
        this.zoomService.loadingEquipmentZoom = false;
      } else {
        let addModifyVerb: String;
        addModifyVerb = this.zoomConfig.addModifyVerb;
        if (Util.isNullOrUndefined(addModifyVerb)) {
          let d: LpMeta = new LpMeta();
          d.assign({ id: id, _readOnly: null, details: null, wording: null });
          if (this.zoomService.isStringMode(this.zoomConfig)) {
            this.setDisplayValue(id);
            this.internalValue = id;
          }
          // cas du AddModifyVerb null, pour verification de la donnée
          const line: LpMeta = await this.searchInDatas(d.id.toString());
          if (Util.isNullOrUndefined(line)) {
            const error: any = { status: 404 };
            this.error404Appears(error);
            await this.displayInputField(d, isNewForm);
            throw new Error(this.zoomConfig.zoomId+' - dropdown zoom value not found : '+d.id.toString());
          } else {
            if (!Util.isNullOrUndefined(d) && !Util.isNullOrUndefined(line) &&
                !Util.isNullOrUndefined(line['field0'])) {
              d.id = line['field0'];
            } else if (!Util.isNullOrUndefined(d) && !Util.isNullOrUndefined(line) &&
                       !Util.isNullOrUndefined(line.id)) {
              d.id = line.id;
            }
            if (!Util.isNullOrUndefined(d) && !Util.isNullOrUndefined(line) &&
                !Util.isNullOrUndefined(line['field1'])) {
              d['wording'] = line['field1'];
            }
            await this.displayInputField(d, isNewForm);
            this.searched = await this.inputTextFormatter(false);
            this.zoomService.loadingEquipmentZoom = false;
          }
        } else {
          try {
            const data: LpMeta = await this.zoomProvider.getRowData(`${ addModifyVerb }`,
              id,
              verbSearch,
              [{ prop: 'noerroralert', value: 'true' }]);
            if (!Util.isNullOrUndefined(verbSearch) && Util.isNullOrUndefined(data)) {
              this.displayValue = '';
            } else {
              await this.displayInputField(data, isNewForm);
            }
            if (Util.isNullOrUndefined(executeFocuOut) || executeFocuOut) {
              /*let applicationItem: ApplicationItem;
               let data: LpMeta;
               if(this.formStackService.useSpecificApplicationItem(this.name)) {
               applicationItem = this.formStackService.specificApplicationItem;
               data = this.formStackService.specificData;
               } else {
               applicationItem = this.formStackService.currentApplicationItem;
               data = this.formStackService.currentData;
               }
               /*if (!this.zoomService.isDropDownMode(this.zoomConfig) && this.previousValue.id !== this.displayValue.id) {
               await this.evalService.executeContrainte(FOCUS_OUT_CHANGE_EVENT, MAIN
               , applicationItem.ecrId.toString(), this.name.toString()
               , data, this.formStackService.currentFields, this.previousValue);
               }
               await this.evalService.executeContrainte(FOCUS_OUT_EVENT, MAIN
               , applicationItem.ecrId.toString(), this.name.toString()
               , data, this.formStackService.currentFields);*/
            }
            if (this.zoomService.isStringMode(this.zoomConfig)) {
              if (Util.isNullOrUndefined(search) || search === '') {
                this.setDisplayValue(data[this.zoomConfig.tableShowField[0]]);
                this.internalValue = data[this.zoomConfig.tableShowField[0]];
              } else {
                this.setDisplayValue(search);
                this.internalValue = search;
              }
            }
            this.zoomService.loadingEquipmentZoom = false;
          } catch (error) {
            this.classError404 = true;
            throw error;
          }
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  /**
   * La fonction deleteBinding() est appelé lorsque l'utilisateur supprimer tous les infos dans le zoom-field.
   * Elle a pour but de supprimer tous valeurs du binding.
   */
  private deleteBinding(): void {
    this.setDisplayValue(null);
    this.internalValue = '';
    this.inputSearch = '';
    // Permet la prise en compte de l'input ignoreChange lorsce qu'on vide un zoom.
    if (this.previousValue !== this.internalValue && (Util.isNullOrUndefined(this.ignoreChange) || !this.ignoreChange)) {
      // this.changeService.push(this.name);
      this.compareAndSetChanges(null);
    }
  }

  private error404Appears(error: any): void {
    if (error && error.status && error.status === 404) {
      this.classError404 = true;
    }
  }

  private async createUrlFromPath(): Promise<string> {
    if (this.zoomService.haveApath(this.zoomConfig)) {
      let url: string = '';
      let queryParams: string = '';
      let verb: string = '';
      if (this.zoomConfig.path['verb']) {
        verb = this.zoomConfig.path['verb'];
      }
      if (this.zoomConfig.path['queryParams'] && this.zoomConfig.path['queryParams'].length > 0) {
        for (let i: number = 0; i <= this.zoomConfig.path['queryParams'].length - 1; i = i + 1) {
          if (queryParams === '') {
            queryParams = `${ this.zoomConfig.path['queryParams'][i]['key'] }=${ this.zoomConfig.path['queryParams'][i]['value'] }`;
          } else {
            queryParams = `${ queryParams }&${ this.zoomConfig.path['queryParams'][i]['key'] }=${ this.zoomConfig.path['queryParams'][i]['value'] }`;
          }
        }
      }
      if (queryParams === '') {
        url = `${ verb }`;
      } else {
        url = `${ verb }?${ queryParams }`;
      }
      return url;
    }
  }

  private async prepareForDropDownMode(): Promise<void> {
    if (this.zoomService.isDropDownMode(this.zoomConfig) || this.zoomService.isDropDownModeWithAddModifyVerb(this.zoomConfig)) {
      await this.prepareConfigAndPath();
      await this.zoomService.setConfig(this.zoomConfig, this.guid);
      this.internalValue = await this.inputTextFormatter(false);
      if (!Util.isNullOrUndefined(this.displayValue) && !Util.isNullOrUndefined(this.zoomConfig.tableShowField[0]) &&
          !Util.isNullOrUndefined(this.displayValue[this.zoomConfig.tableShowField[0]])) {
        this.searched = await this.inputTextFormatter(false);
      } else if (!Util.isNullOrUndefined(this.displayValue) && this.zoomService.isStringMode(this.zoomConfig)) {
        this.searched = this.displayValue;
      }
      this.previousValue = this.internalValue;
    }
  }

  private async prepareConfigAndPath(): Promise<void> {
    this.locProZoomAttribut = '';
    this.locProZoomObject = '';
    if (this.zoomService.haveApath(this.zoomConfig)) {
      this.zoomConfig.showLpFieldDetails = false;
      this.zoomConfig.zoomParameter = this.zoomParameterMocked;
      this.zoomConfig.url = await this.createUrlFromPath();
    }
  }

  private async setOriginalValueForDetectChanges(): Promise<void> {
    if (this.zoomService.isStringMode(this.zoomConfig)) {
      this.originalValue = this.displayValue;
    } else {
      this.originalValue = { ...this.displayValue };
    }
  }

  private compareAndSetChanges(inputSearched: string): void {
    if (Util.isNullOrUndefined(this.ignoreChange) || this.ignoreChange === false) {
      let haveChanged: boolean = false;
      if (inputSearched === 'undefined') {
        inputSearched = undefined;
      }
      if (inputSearched === 'null') {
        inputSearched = null;
      }
      if (this.zoomService.isStringMode(this.zoomConfig)) {
        if (Util.isNullOrUndefined(this.originalValue) && !Util.isNullOrUndefined(inputSearched)) {
          haveChanged = true;
        } else if (!Util.isNullOrUndefined(this.originalValue) && !Util.isNullOrUndefined(inputSearched)) {
          if (this.originalValue !== inputSearched) {
            haveChanged = true;
          }
        } else if (!Util.isNullOrUndefined(this.originalValue) && Util.isNullOrUndefined(inputSearched)) {
          haveChanged = true;
        } else if (Util.isNullOrUndefined(this.originalValue) && Util.isNullOrUndefined(inputSearched)) {
          haveChanged = false;
        }
      } else {
        if ((!Util.isNullOrUndefined(this.originalValue) && Util.isNullOrUndefined(this.originalValue.id))
            && !Util.isNullOrUndefined(inputSearched)
            && !Util.isNullOrUndefined(inputSearched['id'])) {
          haveChanged = true;
        } else if ((!Util.isNullOrUndefined(this.originalValue) && !Util.isNullOrUndefined(this.originalValue.id))
                   && !Util.isNullOrUndefined(inputSearched)
                   && !Util.isNullOrUndefined(inputSearched['id'])) {
          if (this.originalValue.id !== inputSearched['id']) {
            haveChanged = true;
          }
        } else if ((!Util.isNullOrUndefined(this.originalValue) && !Util.isNullOrUndefined(this.originalValue.id))
                   && Util.isNullOrUndefined(inputSearched)) {
          haveChanged = true;
        } else if ((!Util.isNullOrUndefined(this.originalValue) && Util.isNullOrUndefined(this.originalValue.id))
                   && Util.isNullOrUndefined(inputSearched)) {
          haveChanged = false;
        }
      }
      if (haveChanged === true) {
        this.setHasChanged(true, this);
      }
    }

  }
}
