import { LpMeta, propertyMeta } from '../meta';
import { Type } from 'class-transformer';
import { LpInspectionElementZone } from './inspection-element-zone';
import { LpInspectionDamage } from './inspection-damage';

export class LpInspectionZone extends LpMeta {
    @propertyMeta('string')
    public id: String;
    @propertyMeta('boolean')
    public isActive: Boolean;
    @propertyMeta('string')
    public shapeType: String;
    @propertyMeta('string')
    public zoneClick: String;
    @propertyMeta('string')
    public coordinates: String;
    @Type(() => LpInspectionDamage)
    public damages: LpInspectionDamage[];
    @propertyMeta('boolean')
    public show: boolean;
    @Type(() => LpInspectionElementZone)
    public elements: LpInspectionElementZone[];
    
}
