import { DragDropModule } from '@angular/cdk/drag-drop';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRouteSnapshot, Router, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GridsterModule } from '@khajegan/angular2gridster';
import { environment } from 'environments/environment';
import * as Hammer from 'hammerjs';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { TranslatePoHttpLoader } from '../utils/i18n/translatePoHttpLoader';
import { AlocproThirdPartyLibsModule } from './alocpro-third-party-libs.module';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
import { AppModuleDetailForm } from './DetailForms/app.module.detailForm';
import { FormComponentModule } from './FormComponents/formComponent.module';
import { AppModuleForms } from './Forms/app.module.forms';
import { ExampleDataProvider } from './Forms/examples/provider/data/examples.data.provider';
import { ExampleApplicationItemDetailsProvider } from './Forms/examples/provider/metadata/example.application-item-details.provider';
import { ExampleApplicationItemsProvider } from './Forms/examples/provider/metadata/example.application-items.provider';
import { ExampleDetailsProvider } from './Forms/examples/provider/metadata/examples.details.provider';
import { RootformComponent } from './Forms/rootform/rootform.component';
import { HomeComponent } from './home/home.component';
import { AppModuleLp } from './lp/app.module.lp';
import { MenuComponent } from './menu/menu.component';
import { AlocproProvider } from './providers/alocpro.provider';
import { ApplicationItemProvider } from './providers/application-item.provider';
import { ConstraintProvider } from './providers/constraint.provider';
import { EdiProvider } from './providers/edi.provider';
import { FtProvider } from './providers/ft.provider';
//import { GouvProvider } from './providers/gouvProvider';
import { HttpProvider } from './providers/http.provider';
import { InspectionProvider } from './providers/inspection.provider';
import { LoginProvider } from './providers/login.provider';
import { MemoProvider } from './providers/memo.provider';
import { MenuProvider } from './providers/menu.provider';
import { PlanningProvider } from './providers/planning.provider';
import { ReportsProvider } from './providers/reports.provider';
import { RepositoryProvider } from './providers/repository.provider';
import { TempWidgetProvider } from './providers/temp-widget.provider';
import { WidgetProvider } from './providers/widget.provider';
import { WorkflowProvider } from './providers/workflow.provider';
import { ZoomProvider } from './providers/zoom.provider';
import { BulkService } from './services/bulk.service';
import { CommonToolsService } from './services/common-tools.service';
import { ConfigService } from './services/config.service';
import { CryptoService } from './services/crypto.service';
import { CustomLabelsService } from './services/custom-labels.service';
//import { DataGouvApiService } from './services/dataGouvApiService.service';
import { DatetimeService } from './services/datetime.service';
import { DownloadService } from './services/download.service';
import { EvalService } from './services/eval.service';
import { FieldDetailsService } from './services/fieldDetails.service';
import { FormStackService } from './services/form-stack.service';
import { FormatService } from './services/format.service';
import { HostListenerService } from './services/host-listener.service';
import { HttpErrorInterceptorService } from './services/http-error-interceptor.service';
import { IhmStackService } from './services/ihm-stack.service';
import { LanguageService } from './services/language.service';
import { LocalStorageService } from './services/local-storage.service';
import { LoggerService } from './services/logger.service';
import { MetaFactoryService } from './services/meta-factory.service';
import { ModalService } from './services/modal.service';
import { PagesGuardService } from './services/pages-guard.service';
import { PricingService } from './services/pricing.service';
import { ReportSpoolerService } from './services/report-spooler.service';
import { ReportService } from './services/report.service';
import { RouterService } from './services/router.service';
import { SchedulerUIService } from './services/scheduler-ui.service';
import { UiSyncService } from './services/ui-sync.service';
import { UserHistoryService } from './services/user-history.service';
import { UserService } from './services/user.service';
import { WidgetService } from './services/widget.service';
import { ZoomService } from './services/zoom.service';
import { AppModuleUI } from './ui/app.module.ui';
import { LpModalformChangeComponent } from './ui/lp-modalform-change/lp-modalform-change.component';
import { TestValidatorDirective } from './validators/test-validator.directive';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { MessagingService } from './services/messaging.service';
import { PricingProvider } from './providers/pricing.povider';
import { PricingCustomService } from './services/pricing-custom.service';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { MulitProductsProvider } from './providers/multi-products.provider';
import { MultiProductsService } from './services/multi-products.service';
import { SnackBarService } from './services/snack-bar.service';
import { SoftwareUpdateService } from './services/software-update.service';
import { NotificationComponent } from './FormComponents/notification/notification.component';
import { IHubCheckPlusProvider } from './providers/ihubcheckplus.provider';
import { InspectionsV2Component } from './Forms/inspections/inspectionsV2/inspections.component';
import { InspectionsV1Component } from './Forms/inspections/inspectionsV1/inspections.component';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';

const NUM_INSPECTION_VERSION_KEY: string = 'inspectionVersionNumber';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

declare global {
    interface Navigator {
      msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

export function createTranslateLoader(http: HttpClient): TranslatePoHttpLoader {
  return new TranslatePoHttpLoader(http, 'assets/i18n', '.po');
}

export function initializeApp(configService: ConfigService,
                              aloc: AlocproProvider,
                              repo: RepositoryProvider,
                              lang: LanguageService,
                              user: UserService): any {
  return async (): Promise<any> => {
    let temp: any = await configService.initAppConfig();
    aloc.init();
    repo.init();
    lang.setLanguage(user.getCurrentLang());
    return temp;
  };
}

function init (versionInspectionNumber: number): any {
  let routes: Routes = [];
  if ( versionInspectionNumber && !isNullOrUndefined(versionInspectionNumber) && versionInspectionNumber === 2 ) {
    routes.push({
      path: 'inspections',
      canActivateChild: [PagesGuardService],
      children: [
        {path: '', component: InspectionsV2Component },
        {path: ':ky', component: InspectionsV2Component },
      ]
    });
  } else {
    routes.push({
      path: 'inspections',
      canActivateChild: [PagesGuardService],
      children: [
        {path: '', component: InspectionsV1Component },
        {path: ':ky', component: InspectionsV1Component },
      ]
    });
  }
  return routes.concat(ROUTES);
}

export class CustomOption {
  /*animate = 'fade'; // you can override any options available
   newestOnTop = false;
   showCloseButton = true;
   positionClass = 'toast-top-center';
   maxShown = 10;
   toastLife = 5000; // default: 5000*/
}

@NgModule({
    imports: [
        AngularFireAnalyticsModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireMessagingModule,
        MatDialogModule,
        MatSnackBarModule,
        AlocproThirdPartyLibsModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule.forRoot([]),
        AccordionModule.forRoot(),
        TooltipModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        BsDropdownModule.forRoot(),
        TypeaheadModule.forRoot(),
        DragDropModule,
        AppModuleUI,
        AppModuleLp,
        FormComponentModule,
        AppModuleForms,
        AppModuleDetailForm,
        GridsterModule.forRoot(),
    ],
    declarations: [
        // Abstract one (electron aot = true)
        RootformComponent,
        RootformComponent,
        AppComponent,
        HomeComponent,
        TestValidatorDirective,
        MenuComponent,
        NotificationComponent
    ],
    providers: [
        ConfigService,
        { provide: HAMMER_GESTURE_CONFIG, useClass: HammerConfig },
        {
            provide: APP_INITIALIZER, useFactory: initializeApp, deps: [ConfigService, AlocproProvider, RepositoryProvider, LanguageService,
                UserService], multi: true,
        },
        AlocproProvider,
        FtProvider,
        InspectionProvider,
        //GouvProvider,
        ZoomProvider,
        CommonToolsService,
        HttpProvider,
        RepositoryProvider,
        WidgetProvider,
        TempWidgetProvider,
        ConstraintProvider,
        FormatService,
        UiSyncService,
        ZoomService,
        LoginProvider,
        DatetimeService,
        LocalStorageService,
        UserService,
        UserHistoryService,
        ModalService,
        MenuProvider,
        MemoProvider,
        FormStackService,
        IhmStackService,
        PagesGuardService,
        SchedulerUIService,
        LanguageService,
        EvalService,
        BulkService,
        MetaFactoryService,
        ReportsProvider,
        PricingProvider,
        WorkflowProvider,
        PlanningProvider,
        CustomLabelsService,
        LoggerService,
        FieldDetailsService,
        ExampleApplicationItemsProvider,
        ExampleApplicationItemDetailsProvider,
        ExampleDataProvider,
        ExampleDetailsProvider,
        LpModalformChangeComponent,
        ReportService,
        MessagingService,
        WidgetService,
        PricingService,
        PricingCustomService,
        HostListenerService,
        EdiProvider,
        CryptoService,
        ReportSpoolerService,
        DownloadService,
        MulitProductsProvider,
        MultiProductsService,
        SnackBarService,
        SoftwareUpdateService,
        IHubCheckPlusProvider,
        //DataGouvApiService,
        {
            provide: 'PagesGuardService',
            useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => true,
        },
        {
            provide: MAT_DIALOG_DATA,
            useValue: MAT_DIALOG_DATA, // Add any data you wish to test if it is passed/used correctly
        },
        RouterService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptorService, multi: true },
        { provide: LOCALE_ID, useValue: environment.default_language },
        ApplicationItemProvider,
        { provide: OwlDateTimeIntl, useValue: environment.DefaultIntl }, // Calendrier }
        // { provide: ToastOptions, useClass: CustomOption }
    ],
    bootstrap: [AppComponent]
})
export  class AppModule {
  constructor(
    private router: Router,
    private configService: ConfigService
  ) {
      this.configService.initAppConfig().then(() => {
        this.router.resetConfig(init(this.configService.get(NUM_INSPECTION_VERSION_KEY)));  
      });
  }
}
