import { Component, OnInit, Inject } from '@angular/core';
import { ModalService } from 'app/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore'
import { LpModalComponent } from '../lp-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService } from 'app/services/config.service';
import { DashboardProvider } from 'app/providers/dashboard.provider';
import { FormStackService } from 'app/services/form-stack.service';
import { WidgetProvider } from 'app/providers/widget.provider';
import { Dashboard, Widget } from 'app/models/dashboard';
import { Util } from 'app/statics/utils';
import { UiSyncService } from 'app/services/ui-sync.service';
import { UserService } from 'app/services/user.service';
import { FormatService } from 'app/services/format.service';
import { LpModalWidgetPreviewComponent } from '../lp-modal-widget-preview/lp-modal-widget-preview.component';
import { WidgetInterface } from 'app/models/widget';
import { Subscription } from 'rxjs';
import { ApplicationItemProvider } from 'app/providers/application-item.provider';
import { ApplicationItem } from 'app/models/application-item';
import { NewsProvider } from 'app/providers/news.provider';
import { LpNews } from 'app/meta/news';
import { NewsService } from 'app/services/news.service';
import { LpLinks } from 'app/meta/links';
import { LinksProvider } from 'app/providers/links.provider';
import { LinksService } from 'app/services/links.service';
import { LpModalLinksPreviewComponent } from '../lp-modal-links-preview/lp-modal-links-preview.component';


@Component({
  selector: 'lp-modal-dashboard',
  templateUrl: './lp-modal-dashboard.component.html'
})
export class LpModalDashboardComponent extends LpModalComponent implements OnInit {
  public arrayClassesElements: Map<String, String> = new Map<String, String>();
  public listOfWidgets: Widget[] = [];
  public dashboard: Dashboard;
  public dragLeft: number = null;
  public displayActiveWidgets: boolean = false;
  public editorData: string = '';
  private subscriptionClickPreviewFormCard: Subscription;
  private subscriptionClickActiveFormCard: Subscription;
  private subscriptionPreviewLinks: Subscription;
  public news: LpNews = new LpNews();
  public links: Array<LpLinks> = new Array<LpLinks>() ;

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formatService: FormatService,
    private modalService: ModalService,
    private translate: TranslateService,
    private configService: ConfigService,
    private dashboardProvider: DashboardProvider,
    public formstackService: FormStackService,
    private widgetProvider: WidgetProvider,
    private uiSyncService: UiSyncService,
    private userService: UserService,
    public applicationItemProvider: ApplicationItemProvider,
    private newsProvider: NewsProvider,
    private newsService: NewsService,
    private linksProvider: LinksProvider,
    private linksService: LinksService
  ) {
    super(dialogRef, data);
    this.applicationItemProvider.getItem('dashboard').then(async (applicationItem: ApplicationItem) => {
      this.formstackService.specificApplicationItem = applicationItem; 
    });
  }

  public async ngOnInit(): Promise<void> {
    this.subscribe();
    this.arrayClassesElements.set('defaultLabel', this.configService.get('constanteClasses').DEFAULT_LABEL);
    this.arrayClassesElements.set('defaultInput', this.configService.get('constanteClasses').DEFAULT_INPUT);
    this.arrayClassesElements.set('defaultPostcode', this.configService.get('constanteClasses').DEFAULT_POSTE_CODE);
    this.listOfWidgets = await this.widgetProvider.getAllWidgets();
    for (let i: number = 0; i < this.listOfWidgets.length; i++) {
       this.compareWidgets(this.listOfWidgets[i].id, i);
    }
    for (let i: number = 0; i < this.listOfWidgets.length; i++) {
      await this.deactiveWidget(this.listOfWidgets[i].id);
    }
    await this.getNews();
    await this.getLinks();
  }

  public async ngOnDestroy(): Promise<void> {
    this.unsubscribe();
  }

  public async saveDashboard(): Promise<void> {
    try {
      await this.dashboardProvider.putMyDashboardConfiguration(String(this.formstackService.dashboard.id), this.formstackService.dashboard);
      this.userService.setDashboard(this.formstackService.dashboard);
      this.modalService.success(this.translate.instant('general.modalService.recordingDone'), this.translate.instant('general.modalService.success'));
    } catch (error) {
      throw error;
    }
  }

  public async saveNews(): Promise<void> {
    try {
      this.news.text = this.editorData;
      if ( this.news && this.news.id && String(this.news.id) === '1') {
        await this.newsProvider.putNewsById(this.news, '1');
      } else {
        await this.newsProvider.postNews(this.news);
      }
      this.newsService.refreshNews();
      this.modalService.success(this.translate.instant('general.modalService.recordingDone'), this.translate.instant('general.modalService.success'));
      await this.getNews();
    } catch (error) {
      throw error;
    }
  }

  public async deleteNews(): Promise<void> {
    try {
      await this.newsProvider.deleteNewsById(this.news);
      this.news = new LpNews();
      this.editorData = '';
      this.newsService.refreshNews();
      this.modalService.success(this.translate.instant('general.modalService.recordingDone'), this.translate.instant('general.modalService.success'));
    } catch (error) {
      throw error;
    }
  }

  public getType(idType: Number) {
    //return this.formatService.widgetType.get(Math.floor(Math.random() * (8 - 1 + 1) + 1))
    return this.formatService.widgetType.get(idType);
  }

  public getActiveBtnPosition(): String {
    if (this.dragLeft) {
      return (this.dragLeft - 9) + 'px';
    }
    return 'calc(50% - 34px)';
  }
 
  public  compareWidgets(id: number, index: number): void {
    try {
      if (!Util.isNullOrUndefined(this.formstackService.dashboard) && 
          !Util.isNullOrUndefined(this.formstackService.dashboard.data) && 
          this.formstackService.dashboard.data.length > 0) {
        for (let i: number = 0; i < this.formstackService.dashboard.data.length; i++) {
          if (this.formstackService.dashboard.data[i].widgetId === id) {
            this.listOfWidgets[index].active = true;
            break;
          }
        }
      }
    } catch (error) {
      throw error;
    }
  }

  public async deactiveWidget(id: number): Promise<void> {
    try {
      for (let i: number = 0; i < this.listOfWidgets.length; i++) {
        if (Util.isNullOrUndefined(this.listOfWidgets[i].active)) {
          this.listOfWidgets[i].active = false;
        }
      }
    } catch (error) {
      throw error;
    }
  }

  public addOrRemoveWidget(id: number): void {
    
    for (let i: number = 0; i < this.listOfWidgets.length; i++) {
      if (this.listOfWidgets[i].id === id) {
        if ( this.listOfWidgets[i].active === false ) {
          this.addWidget(i);
        } else {
          this.removeWidget(i, id);
        }
        
      }
    }
  }

  public displayActiveWidget(): boolean {
    this.displayActiveWidgets = !this.displayActiveWidgets;
    return this.displayActiveWidgets;
  }

  public showModalWidgetPreview(widget: WidgetInterface): void {
    this.modalService.modalPromise(LpModalWidgetPreviewComponent, {
      height : '80vh',
      width : '70vw',
      backdropClass: 'commonToolsBackdropClass',
      widget: widget
    }).then((result: boolean) => {});
  }

  public showModalWidgetPreviewLink(link: any): void {
    this.modalService.modalPromise(LpModalLinksPreviewComponent, {
      height : '80vh',
      width : '70vw',
      backdropClass: 'commonToolsBackdropClass',
      link: link
    }).then((result: boolean) => {});
  }

  private addWidget(i: number): void {
    let newWidget = {active: null, widgetId: null, position: null} as Widget;
    this.listOfWidgets[i].position = {x: 0, y: 0, w: 5, h: 5};
    this.listOfWidgets[i].active = true;
    this.listOfWidgets[i]['widgetId'] = this.listOfWidgets[i].id;
    newWidget.active = true;
    newWidget.position = this.listOfWidgets[i].position;
    newWidget.widgetId = this.listOfWidgets[i].id;
    this.formstackService.dashboard.data.push(newWidget);
    this.uiSyncService.reloadDashboardChangeWidgets.emit();
  }

  private removeWidget(i: number, id: number): void {
    this.listOfWidgets[i].active = false;
    this.listOfWidgets[i]['widgetId'] = this.listOfWidgets[i].id;
    for (let j: number = 0; j < this.formstackService.dashboard.data.length; j++) {
      if (this.formstackService.dashboard.data[j].widgetId === id) {
        this.formstackService.dashboard.data.splice(j, 1);
      }
    }
    this.uiSyncService.reloadDashboardChangeWidgets.emit();
  }

  private async getNews(): Promise<void> {
    try {
      this.news = await this.newsProvider.getNewsById('1');
      this.editorData = this.news.text;
    } catch (error) {
      throw error;
    }
  }

  private async getLinks(): Promise<void> {
    try {
      this.links = await this.linksProvider.getLinksList();
    } catch (error) {
      throw error;
    }
  }

  private subscribe() {
    this.subscriptionClickPreviewFormCard = this.uiSyncService.clickPreviewFormCard.subscribe((data) => {
      this.showModalWidgetPreview(data);
    });
    this.subscriptionPreviewLinks = this.linksService.previewLinksEvtEmitter.subscribe((data) => {
      this.showModalWidgetPreviewLink(data);
    });
    this.subscriptionClickActiveFormCard = this.uiSyncService.clickActiveFormCard.subscribe((data) => {
      this.addOrRemoveWidget(data.id);
    });
  }

  private unsubscribe() {
    if ( !Util.isNullOrUndefined(this.subscriptionClickPreviewFormCard)) {
      this.subscriptionClickPreviewFormCard.unsubscribe();
    }
    if ( !Util.isNullOrUndefined(this.subscriptionClickActiveFormCard)) {
      this.subscriptionClickActiveFormCard.unsubscribe();
    }
    if ( !Util.isNullOrUndefined(this.subscriptionPreviewLinks)) {
      this.subscriptionPreviewLinks.unsubscribe();
    }
  }

}
