import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VersionProvider {
  private appJsonFile: string = 'version.json';

  constructor(protected http: HttpClient) { }

  public getVersion(): Promise<any> {
    const url: string = `/${this.appJsonFile}?t=${new Date().getTime()}`;
    return lastValueFrom(this.http.get(url));
  }
  public getRepositoryVersion(): Promise<any> {
    const url: string = `/lp3k-repository/version`;
    return lastValueFrom(this.http.get(url));
  }
}
