import { Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Util } from 'app/statics/utils';
import { LpModalConfirmComponent } from 'app/ui/lp-modal/lp-modal-confim/lp-modal-confirm.component';
import * as _ from 'underscore';
import { FileFieldComponent } from '../file-field.component';
@Component({
  selector: 'lp-file-carousel',
  templateUrl: './lp-file-carousel.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpFileCarouselComponent),
      multi: true
    }
  ]
})
export class LpFileCarouselComponent extends FileFieldComponent {
  public currentDeleteBase64: string;

  public zoomIconFile(encoded: String): void {
    if (this.fileService.isPdf(encoded)) {
      this.uiSyncService.displayZoomPdf(encoded);
    }
    if (this.fileService.isTxt(encoded)) {
      this.uiSyncService.displayZoomTxt(encoded);
    }
  }

  public deleteDocument(base64: string): void {
    this.currentDeleteBase64 = base64;
    this.modalService.modalPromise(LpModalConfirmComponent, {
      backdropClass: 'alertBackdropClass',
      id : 'modalWithHeader'
    }).then((result: boolean) => {
      if (result) {
        this.confirmDelete()
      };
    });

  }

  public getIcon(encoded: String): String {
    let mime: any = this.configService.get('iconTypeMime')[this.fileService.base64MimeType(encoded).toString()];
    if (!Util.isNullOrUndefined(mime)) {
      return mime.icon;
    }
    return this.configService.get('iconTypeMime')['default'].icon;
  }

  public confirmDelete(): void {
    this.setDisplayValue(_.without(this.displayValue, this.currentDeleteBase64));
    this.currentDeleteBase64 = null;
  }
}
