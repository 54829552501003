import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, CONTACTS, DRIVER_CONTACTS, CUSTOMER_CONTACTS } from '../detail-forms.component';
import { LpContact } from 'app/meta/contact';

@Component({
  selector: 'lp-contacts',
  templateUrl: './contacts.component.html'
})
export class ContactsComponent extends DetailFormsComponent implements OnInit {

  public data: LpContact = null;

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    if (this.formStackService.currentApplicationItemDetail.key === DRIVER_CONTACTS) {
      this.setId(DRIVER_CONTACTS);
      this.object = DRIVER_CONTACTS;
    } else if (this.formStackService.currentApplicationItemDetail.key === CUSTOMER_CONTACTS) {
      this.setId(CUSTOMER_CONTACTS);
      this.object = CUSTOMER_CONTACTS
    } else {
      this.setId(CONTACTS);
      this.object = CONTACTS;
    }
    this.setId(CONTACTS);
    this.initUrl();
  }

  public init(): void {
    this.data.assign(this.formStackService.currentDetailLineData);
  }

}
