import { LpReportParameters } from 'app/meta/report-parameters';
import { DatetimeService } from 'app/services/datetime.service';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RepositoryProvider } from '../../../providers/repository.provider';
import { UiSyncService } from '../../../services/ui-sync.service';
import { ZoomService } from '../../../services/zoom.service';
import { ReportsProvider } from 'app/providers/reports.provider';
import { LpReportComponent } from '../lp-report.component';
import { RightColService } from 'app/services/root/rightCol.service';
import { FormStackService } from 'app/services/form-stack.service';
import { IhmStackService } from 'app/services/ihm-stack.service';
import { ReportService } from 'app/services/report.service';
import { ModalService } from 'app/services/modal.service';

export const ZOOMID: string = 'report';
export const KEY_SPINNER: string = 'loading.data';
export const KY_LIBELLE: String = 'ky';
export const PARAMETERS: string = 'parameters';
export const LPLANGUE: string = 'lplangue';
export const SOC: string = 'LP001SOC';
export const AGE: string = 'LP030AGE';
export const SEC: string = 'LP033SEC';
export const LPTRI: string = 'LPTRI';

@Component({
  selector: 'lp-report-parameters',
  templateUrl: './lp-report-parameters.component.html',
  styleUrls: ['./lp-report-parameters.component.scss']
})
export class LpReportParametersComponent extends LpReportComponent implements OnInit {

 @ViewChild('conatinerCols') public conatinerCols: ElementRef;
 @ViewChild('col1') public col1: ElementRef;
 @ViewChild('col2') public col2: ElementRef;
 @Input() public customerInvoiceKy: string;
 @Input() public reportKy: string;
 public hasParamsToDisplay: boolean = false;
 public arrayOfParameters: Array<LpReportParameters> = [];
 protected arrayClassesElements: Map<String, String> = new Map<String, String>();
 public isAlreadyInit: boolean = false;
 @Input() set itemSelectedForRefresh(itemSelectedForRefresh: String) {
    if (this.isAlreadyInit === true) {
      this.formDetailsTab = [];
      this.arrayOfParameters = [];
      this.ngOnInit();
    }
 }

 constructor(
  protected uiSyncService: UiSyncService,
  protected translate: TranslateService,
  protected repositoryProvider: RepositoryProvider,
  protected zoomService: ZoomService,
  protected reportProvider: ReportsProvider,
  protected dateTimeService: DatetimeService,
  protected rightColService: RightColService,
  protected formStackService: FormStackService,
  public ihmStackService: IhmStackService,
  public reportService: ReportService,
  public modalService: ModalService,
  public translateService: TranslateService
  ) {
    super(
      uiSyncService,
      translate,
      repositoryProvider,
      zoomService,
      reportProvider,
      rightColService,
      formStackService,
      ihmStackService,
      dateTimeService,
      reportService,
      modalService,
      translateService);
  }

  public async ngOnInit(): Promise<void> {
    this.uiSyncService.loader(true, false, this.translate.instant('loading.loadingDoc'), 'bottom');
    if (this.reportService.itemSelectedInList && this.reportService.itemSelectedInList) {
      await this.getParameters(this.reportService.itemSelectedInList);
      this.isAlreadyInit = true;
    }
    this.uiSyncService.loadObject();
    this.uiSyncService.loader(false);
  }

  public async actionAfterParameters(parameters: Array<LpReportParameters>): Promise<void> {
    try {
      this.hasParamsToDisplay = false;
      this.arrayOfParameters = parameters;
      this.uiSyncService.loader(true, false, this.translate.instant('loading.loadingDoc'), 'bottom');
      await this.executeAfter(parameters);
    } catch(e) {
      this.reportService.itemSelectedInList = null;
      console.error(e);
      throw e
    }
  }


  private async executeAfter(parameters: Array<LpReportParameters>): Promise<void> {
    try {
      const report: ArrayBuffer = await this.reportService.execute(parameters, this.reportService.isSpoolerMode);
      this.reportService.closeParametersPanel(false);
      let pdfBinaryReport: Uint8Array = new Uint8Array(report);
      this.reportService.pdfExported(pdfBinaryReport)
      this.uiSyncService.loader(false);
    } catch(e) {
      this.reportService.closeParametersPanel(false);
      this.reportService.pdfExported(null);
      this.uiSyncService.loader(false);
      console.error(e);
      throw e
    }
  }

  private buildTwoColumns(parameters: Array<LpReportParameters>): void {
    this.hasParamsToDisplay = true;
    this.arrayOfParameters = this.initDate(parameters);
    this.buildIHMWith2Columns(this.arrayOfParameters);
  }

  private async getParameters(ky: string): Promise<void> {
    try { 
      let parameters: Array<LpReportParameters> = await this.reportService.getParameters(ky)
      if (parameters.length === 0 || parameters.length === 4) {
        await this.actionAfterParameters(parameters);
      }
      if ( (parameters.length === 2 && parameters[0].name.toLocaleLowerCase() === KY_LIBELLE &&
            parameters[1].name.toLocaleLowerCase() === LPLANGUE) || (
            parameters.length === 1  && parameters[0].name.toLocaleLowerCase() === KY_LIBELLE)
          ) {
            parameters[0].value = this.reportKy;
            await this.actionAfterParameters(parameters);
      } else if ( parameters.length === 2 ) {
        await this.actionAfterParameters(parameters);
      } else { this.buildTwoColumns(parameters); }
    } catch (e) {
      console.error(e);
      throw e
    }
  }
}
