import { Injectable } from '@angular/core';
import { FormStackService } from '../form-stack.service';
import { ZoomService } from '../zoom.service';
import { Util } from 'app/statics/utils';
import { RouterService } from '../router.service';
import { ZoomConfig } from 'app/models/zoom-metada';
import { UiSyncService } from '../ui-sync.service';
import { ChangeService } from '../change.service';

export const GUID_FOR_MAIN_ZOOM: string = 'main';

@Injectable({
  providedIn: 'root'
})
export class MainZoomService {

  constructor(private formStackService: FormStackService,
              private zoomService: ZoomService,
              private routerService: RouterService,
              private uiSyncService: UiSyncService,
              private changeService: ChangeService) { }

  public async initMainZoom(): Promise<void> {
    this.changeService.clearAll();
    this.uiSyncService.IsMainZoom = true;
    this.formStackService.clearStack();
    this.zoomService.clearMap();
    if (!Util.isNullOrUndefined(this.formStackService.currentApplicationItem)) {
      await this.displayMainZoom(this.formStackService.currentApplicationItem.mainZoomConfig);
    }
    this.uiSyncService.IsReadyToDisplayForm = true;
  }

  private async displayMainZoom(MainZoomConfig: ZoomConfig): Promise<void> { 
    await this.zoomService.displayZoom(MainZoomConfig, GUID_FOR_MAIN_ZOOM + '_' + this.formStackService.CurrentVerb);
    this.zoomService.selectEventEmitter.subscribe((row: any) => {
      const id: String = row['id'];
      if (row.guid === GUID_FOR_MAIN_ZOOM + '_' + this.formStackService.CurrentVerb) {
        if (!this.zoomService.hasParentZoom(GUID_FOR_MAIN_ZOOM + '_' + this.formStackService.CurrentVerb)) {
          this.navigate(id);
        }
      }
    });
    return;
  }

  private async navigate(id?: String): Promise<void> {
    if (id) {
      await this.routerService.navigate(this.formStackService.CurrentVerb, id);
    } else {
      await this.routerService.navigate(this.formStackService.CurrentVerb);
    }
  }

}
