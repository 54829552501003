import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { ValueFieldComponent } from '../value-field.component';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UiSyncService } from 'app/services/ui-sync.service';
import { ModalService } from 'app/services/modal.service';
import { ChangeService } from 'app/services/change.service';
import { EvalService } from 'app/services/eval.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { FormStackService } from 'app/services/form-stack.service';
import { Util } from 'app/statics/utils';
import { FileService } from 'app/services/file.service';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { ConfigService } from 'app/services/config.service';

@Component({
  template: ''
})
export class FileFieldComponent extends ValueFieldComponent implements OnInit, OnDestroy {
  public isImage: Boolean = false;
  public iconTypeMime: String = this.configService.get('iconTypeMime')['default'].icon;

  @Input() public filename: String = 'download';

  protected currentTypeMime: String = null;
  private subscription: Subscription;

  constructor(
    public uiSyncService: UiSyncService,
    protected fileService: FileService,
    protected translate: TranslateService,
    protected modalService: ModalService,
    protected changeService: ChangeService,
    protected evalService: EvalService,
    protected formMetadataProvider: FormMetadataProvider,
    public formStackService: FormStackService,
    public metaFactoryService: MetaFactoryService,
    protected configService: ConfigService
    ) {
    super(changeService, evalService, formStackService, formMetadataProvider, uiSyncService, metaFactoryService, configService);
  }

  public async ngOnInit(): Promise<void> {
    try {
      super.ngOnInit();
      await this.subscriptions();
    } catch (error) {
      throw error;
    }
  }


  public async ngOnDestroy(): Promise<void> {
    try {
      if (this.subscription) { this.subscription.unsubscribe(); }
    } catch (error) {
      throw error;
    }
  }

  public async subscriptions(): Promise<void> {
    try {
      this.subscription = this.uiSyncService.loadObjectEvtEmitter.subscribe( async () => {
        await this.loadFile();
      });
    } catch (error) {
      throw error;
    }
  }

  // mandatory for ControlValueAccessor interface
  public async writeValue(value: any): Promise<void> {
    try {
      super.writeValue(value);
      await this.loadFile();
      if (value) {
        await this.setIconTypeMime(await this.base64MimeType(value));
      }
    } catch (error) {
      throw error;
    }
  }

  public async downloadFile(base64?: String): Promise<void> {
    if (base64 === undefined || base64 === null) {
      base64 = this.displayValue;
    }
    try {
      let file: String[] = base64.split(',');
      let byteCharacters: String = atob(file[1].toString());
      let byteNumbers: Array<number> = new Array(byteCharacters.length);
      for (let i: number = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray: Uint8Array = new Uint8Array(byteNumbers);
      let blob: Blob = new Blob([byteArray], {
        type: undefined
      });

      let a: HTMLAnchorElement = document.createElement('a');
      document.body.appendChild(a);
      let url: String = window.URL.createObjectURL(blob);
      a.href = url.toString();
      this.base64MimeType(base64).then((type: string) => {
        a.download = this.filename + '.' + this.configService.get('iconTypeMime')[type].type;
        a.click();
        window.URL.revokeObjectURL(url.toString());
      });
    } catch (error) {
      throw error;
    }
  }

  protected errorTooLarge(): void {
    this.modalService.error(this.translate.instant('general.modalService.fileTooLarge'),
      this.translate.instant('general.modalService.error'));
  }
  protected errorUnknowFileType(): void {
    this.modalService.error(this.translate.instant('general.modalService.unknowFileType'),
      this.translate.instant('general.modalService.error'));
  }

  protected async base64MimeType(encoded: any): Promise<String> {
    try {
      let result: String = null;
      if (typeof encoded !== 'string') {
        return result;
      }
      const mime: RegExpMatchArray = await encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
      if (mime && mime.length) {
        result = mime[1];
      }
      return result;
    } catch (error) {
      throw error;
    }
  }

  protected async setIconTypeMime(base64: String): Promise<void> {
    try {
      if (!Util.isNullOrUndefined(base64)) {
        const tmime: Object = this.configService.get('iconTypeMime')[base64.toString()];
        if (tmime) {
          this.iconTypeMime = tmime['icon'];
        } else {
          this.iconTypeMime = this.configService.get('iconTypeMime')['default'].icon;
        }
      }
    } catch (error) {
      throw error;
    }
  }

  protected async isImageType(): Promise<void>  {
    try {
      if (this.currentTypeMime && this.currentTypeMime.search('image') === 0) {
        this.isImage = true;
      } else {
        this.isImage = false;
      }
    } catch (error) {
      throw error;
    }
  }

  private async loadFile(): Promise<void> {
    try {
      if (this.displayValue !== undefined) {
        this.currentTypeMime = await this.base64MimeType(this.displayValue);
        await this.setIconTypeMime(this.currentTypeMime);
        await this.isImageType();
      }
    } catch (error) {
      throw error;
    }
  }

}

