import { LpMeta, propertyMeta } from './meta';
import { FtMeta } from './ft-meta';
import { LpAddress } from './address';
import { LpCompany } from './company';
import { LpLocation } from './location';
// import { LpMeansOfCommunication } from './means-of-communication';
import { LpDriverInformations } from './driver-informations';
import { LpRib } from './rib';
import { LpThirdPartiesRelation } from './third-parties-relation';
import { LpInformation } from './information';
import { LpPricing } from './pricing';
import { LpAccounting } from './accounting';
import { Type } from 'class-transformer';
import { LpCommercial } from './commercial';
//import { LpMediaCode } from './media-code';
import { LpContact } from './contact';

export class LpThirdParty extends LpMeta {
    @Type(() => FtMeta)
    public civility: FtMeta = new FtMeta();
    @Type(() => LpCompany)
    public company: LpCompany = new LpCompany();
    @Type(() => LpLocation)
    public location: LpLocation = new LpLocation();
    @Type(() => LpAddress)
    public mainAddress: LpAddress = new LpAddress();

    // public addresses: LpAddress[] = [];
    // public types: FtMeta[] = [];
    @propertyMeta('string')
    public cardNumber: String;
    @propertyMeta('string')
    public cardCrypto: String;
    @propertyMeta('date')
    public cardExpirationDate: Date;
    @propertyMeta('number')
    public cardNumberLimit: Number;
    @propertyMeta('string')
    public lastName: String;
    @propertyMeta('string')
    public firstName: String;
    @Type(() => LpDriverInformations)
    public driverInformation: LpDriverInformations = new LpDriverInformations();
    @propertyMeta('boolean')
    public isActive: Boolean;
    @propertyMeta('string')
    public sirenNumber: String;
    @propertyMeta('string')
    public language: String;
    @propertyMeta('string')
    public socialSecurityNumber: String;
    @propertyMeta('string')
    public code: String;
    @propertyMeta('string')
    public directorWord: String;
    @propertyMeta('string')
    public intraCommunityVat: String;
    @propertyMeta('string')
    public APE: String;
    @propertyMeta('number')
    public commissionRate1: Number;
    @propertyMeta('number')
    public commissionRate2: Number;
    @propertyMeta('number')
    public discountRate: Number;
    @Type(() => LpContact)
    public phone: LpContact = new LpContact();
    @Type(() => LpContact)
    public mobile: LpContact = new LpContact();
    @Type(() => LpContact)
    public mail: LpContact = new LpContact();
    @Type(() => LpCommercial)
    public commercial: LpCommercial = new LpCommercial();
    /*@Type(() => LpMediaCode)
    public mediaCode: LpMediaCode = new LpMediaCode();*/
    @Type(() => FtMeta)
    public mediaCode: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public currency: FtMeta = new FtMeta();
    @Type(() => LpPricing)
    public tariff: LpPricing = new LpPricing();
    public ribs: LpRib[] = [];
    public accountings: LpAccounting[] = [];
    public relations: LpThirdPartiesRelation[] = [];
    public informations: LpInformation[];

    protected _unoptimizedKeys: String[] = ['mainAddress', 'employeeInformations', 'driverInformation', 'phone', 'mobile', 'mail'];
    /*
    public addMeanOfCommunications(): void {
        if (!this.meansOfCommunication) {
            this.meansOfCommunication = [];
        }
        let newMeansOfCommunication: LpMeansOfCommunication = new LpMeansOfCommunication();
        this.meansOfCommunication.push(newMeansOfCommunication);
    }

    public removeMeanOfCommunications(id: number): void {
        this.meansOfCommunication.splice(id, 1);
    }
    */

    /*
    public addAddresses(): void {
        if (!this.addresses) {
            this.addresses = [];
        }
        let newAddress: LpAddress = new LpAddress();
        this.addresses.push(newAddress);
    }

    public removeAddresses(id: number): void {
        this.addresses.splice(id, 1);
    }

    public addTypes(): void {
        if (!this.addresses) {
            this.addresses = [];
        }
        let newType: FtMeta = new FtMeta();
        this.types.push(newType);
    }

    public removeTypes(id: number): void {
        this.types.splice(id, 1);
    }
    */
}
