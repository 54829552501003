import { LpMeta, propertyMeta } from './meta';
import { Type } from 'class-transformer';
import { FtMeta } from './ft-meta';

export class LpKindsLink extends LpMeta {
    @Type(() => FtMeta)
    public damageType: FtMeta = new FtMeta();
    @propertyMeta('string')
    public wording: String;
}
