import { Injectable } from '@angular/core';
import { ColumnsProperty, ColumnProperty } from 'app/models/breadcrumb-item';
import { LpMeta } from 'app/meta/meta';
import { Util } from 'app/statics/utils';
import { FormatService } from './format.service';

@Injectable({
    providedIn: 'root'
  })

export class CustomLabelsService  {

    constructor(private formatService: FormatService) { }

    /**
     *  respect property like below => 26600 - Gregory Gondouin
     *
     *      "prop": [
     *           {"object":["customer", "mainAdress"], "field":"zipcode"}
     *       ],
     *
     *      OR
     *
     *       "prop": [
     *           {"object":["customer"], "field":"firstName"},
     *           {"object":["customer"], "field":"lastName"}
     *       ],
     *
     *      OR
     *
     *       "prop": [{"field":"wording"}]
     *
     *   }
     *
     *   OR
     *
     *   Immat title XX-123-XX
     *
     *   "prop": [
     *
     *           {"object":["shortthermContract", "movement", "registration"], "field":"registrationNumber"}
     *
     *           ]
     */

    public getSummaryFromApplicationItem(key: string, data: LpMeta, summary: ColumnsProperty[]): Array<string> {
        let summaryFromCurrentData: any[] = [];
        let summaryFromRepository: Array<ColumnsProperty> = [];
        summaryFromRepository = summary;
        if (summaryFromRepository && summaryFromRepository.length !== 0) {
            summaryFromRepository.forEach( (column: ColumnsProperty) => {
                summaryFromCurrentData.push(this.displayLabel(column, data));
            });
        }
        return summaryFromCurrentData;
    }

    /**
     * fonction qui renvoie un label a partir d'un objet
     */
    public displayLabel(columnsProperties: ColumnsProperty, data: LpMeta): string {
        let returnedString: string = '';
        if ( columnsProperties && columnsProperties.prop) {
            columnsProperties.prop.forEach((columnProperty: ColumnProperty, i: number) => {
                if ((Util.isNullOrUndefined(columnProperty.object) || columnProperty.object.length === 0)
                    && !Util.isNullOrUndefined(columnProperty.field)) {
                    if (!Util.isNullOrUndefined(data[columnProperty.field])) {
                        returnedString += data[columnProperty.field].toString();
                    }
                } else if (!Util.isNullOrUndefined(columnProperty.object) && columnProperty.object.length > 0
                    && !Util.isNullOrUndefined(columnProperty.field)) {
                    returnedString += this.buildDynamicProperty(columnProperty.object, columnProperty.field, data, 0);
                }
                if (i < (columnsProperties.prop.length - 1)) {
                    returnedString += ' ';
                }
            });
            if(columnsProperties.type === 5 && returnedString.indexOf('Z')> -1){
                returnedString = returnedString.substr(0, returnedString.length-1)
            }
            return this.formatService.formatValue(columnsProperties.type, returnedString).toString();
        } else {
            return '';
        }
    }

    /**
     * Fonction récursive qui construit une string a partir d'un tableau de property et de l'objet
     */
    private buildDynamicProperty(object: Array<string>, field: string, data?: any, index?: number): string {
        let temp: any;
        if (Util.isNullOrUndefined(index)) {
            index = 0;
        }

        if (index < object.length) {
            if (!Util.isNullOrUndefined(data) && !Util.isNullOrUndefined(data[object[index]])) {
                temp = data[object[index]];
            }
            return this.buildDynamicProperty(object, field, temp, (index + 1))
        } else {
            if ( Util.isNullOrUndefined(data) || Util.isNullOrUndefined(data[field])) {
                return '';
            }
            return data[field];
        }
    }
}
