import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, LINK_DAMAGE_VALUE } from '../detail-forms.component';
import { LpLinkDamageValue } from 'app/meta/link-damage-value';

@Component({
  selector: 'lp-link-damage-value',
  templateUrl: './link-damage-value.component.html'
})

export class LinkDamageValueComponent extends DetailFormsComponent implements OnInit {

  public data: LpLinkDamageValue = null;

  public init(): void {
    this.data.assign(this.formStackService.currentDetailLineData);
  }

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(LINK_DAMAGE_VALUE);
    this.initUrl();
    // this.init();
  }

}
