import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChoiceFieldComponent } from '../choice-field.component';

@Component({
  selector: 'lp-boolean-switch-box',
  templateUrl: './boolean-switch-box.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BooleanSwitchBoxComponent),
      multi: true
    }
  ]
})
export class BooleanSwitchBoxComponent extends ChoiceFieldComponent implements OnInit {

  /**Le paramètre Name permet de donner un nom personnalisé au composant.
   *
   */
  @Input() public name: string;

  /**Le paramètre switchName permet de donner un nom au  champ.
   *
   */
  @Input() public switchName: string;

  /**Le paramètre Checked est le booléen qui sera passé à TRUE, pour venir pré-cocher la checkbox.
   * Le booléen est utilisé dans la fonction checkedBox().
   */
  @Input() public checked: Boolean;

  public ngOnInit(): void {
    this.typeBinding = 'boolean';
    super.ngOnInit();
  }

  public focus(event: any): void {
    // tslint:disable-next-line: comment-format
    //  let d: boolean = event.checked
    this.setPreviousValue(this.displayValue)
  }

  /**La fonction emit() permet de mettre à jour la valeur bindingValue.
   * La fonction emit() est utilisé par un change, situé dans le template du composant.
   * Ici la valeur à récupérer dans le parametre event, pour ceci, nous utilisons event.checked.
   * @param event : event
   */
  public async pushChange(event?: any): Promise<void> {
    super.pushChange();
    this.setDisplayValue(event.checked);
    this.putOnFocusOut();  
    this.focusOutCustomCode();
  }

  protected transform(value: any): void {
    if (value === 'true' || value === true) {
      this.displayValue = true;
    } else {
      this.displayValue = false;
    }
  }
}
