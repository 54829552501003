
<div class="modal-header mb-3">
  <h4 class="modal-title pull-left m-0">{{'rootFormActions.'+data.rootFormAction.title+'.title' | translate}}</h4>
  <div class="pull-right">
    <lp-button-cancel (action)="close()"></lp-button-cancel>
  </div>
</div>
<div lass="modal-body">
  <form #formAction="ngForm" ngNativeValidate (ngSubmit)="save()">
    <div *ngFor="let field of data.rootFormAction.fields" class="{{(field.type && (field.type === INPUT_FIELD_TYPE_SEPARATOR || field.type === INPUT_FIELD_TYPE_LABEL)) ? 'col-12' : 'form-group col-xl-6 label-2-colonnes'}}">

      <div *ngIf="field.type && field.type === INPUT_FIELD_TYPE_SEPARATOR" >
        <hr *ngIf="field.border && field.border === 'top'" class="mt-4 mb-0 pt-2" />
        <p *ngIf="field.label && field.label !== ''">{{'rootFormActions.' + data.rootFormAction.title + '.' + field.label | translate}}</p>
        <hr *ngIf="field.border && field.border === 'bottom'" class="mt-0 mb-4 pt-2" />
      </div>
      
      <lp-html-field *ngIf="( field.type && field.type === INPUT_FIELD_TYPE_HTML)" [name]="getFieldName(field)" class="{{arrayClassesElements.get('defaultLabel')}} control-label" [(ngModel)]="objectSend[field.prop]"></lp-html-field>

      <lp-label-field *ngIf="!field.type || ((field.type && field.type !== INPUT_FIELD_TYPE_SEPARATOR) && (field.type && field.type !== INPUT_FIELD_TYPE_LABEL) && (field.type && field.type !== INPUT_FIELD_TYPE_HTML))" for="{{field.prop}}" class="{{arrayClassesElements.get('defaultLabel')}}"
        text="{{(data.rootFormAction.method === 'DUPLICATE' ? field.label :'rootFormActions.' + data.rootFormAction.title + '.' + field.label) | translate}}"  [required]="field.mandatory"></lp-label-field>
      <p *ngIf="field.type && field.type === INPUT_FIELD_TYPE_LABEL" class="{{field.classes}}">{{'rootFormActions.' + data.rootFormAction.title + '.' + field.label | translate}}</p>

      <!--textField-->
      <lp-text-field *ngIf=" ( field.type && field.type === INPUT_FIELD_TYPE_TEXT) || !field.type" id="action_{{field.prop}}" className="textField"
        class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" [name]="getFieldName(field)" [ignoreChange]="true"
        [(ngModel)]="objectSend[field.prop]" [disabled]="field.disabled" [required]="field.mandatory" [readonly]="field.readonly"></lp-text-field>

      <!--number-->
      <lp-number *ngIf="field.type && field.type === INPUT_FIELD_TYPE_NUMBER" id="action_{{field.prop}}" [name]="getFieldName(field)" className="textField"
        class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" [name]="getFieldName(field)" [ignoreChange]="true"
        [(ngModel)]="objectSend[field.prop]" [disabled]="field.disabled" [required]="field.mandatory" [readonly]="field.readonly" [integer]="field.params?.integer"
        [step]="field.params?.step" [min]="field.params?.min" [max]="field.params?.max"></lp-number>
        
      <!--currency-->
      <lp-currency *ngIf="field.type && field.type === INPUT_FIELD_TYPE_CURRENCY" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" id="action_{{field.prop}}" [name]="getFieldName(field)"
      [(ngModel)]="objectSend[field.prop]" [disabled]="field.disabled" [required]="field.mandatory" [readonly]="field.readonly" [ignoreChange]="true"></lp-currency>
      
      <lp-boolean-switch-box *ngIf="field.type && field.type === INPUT_FIELD_TYPE_BOOLEAN" [name]="getFieldName(field)"
      switch[name]="getFieldName(field)" id="action_{{field.prop}}" (ngModelChange)="setDataRef(field.prop, $event)"[ignoreChange]="true"
      [ngModel]="objectSend[field.prop]"  [required]="field.mandatory" [readonly]="field.readonly" class="{{arrayClassesElements.get('defaultInput')}}"></lp-boolean-switch-box>

      <lp-dropdown-field *ngIf="field.type === 'dropdown' && field.params.path && !field.params.values && !field.params.locProZoomObject && !field.params.locProZoomAttribut" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true"
        id="action_{{field.prop}}" [name]="getFieldName(field)" [outputType]="field.outputType"
        [(ngModel)]="objectSend[field.prop]"  [path]="field.params.path" [required]="field.mandatory" [readonly]="field.readonly" [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}" ></lp-dropdown-field>

    <lp-dropdown-field *ngIf="field.type === 'dropdown' && !field.params.path && field.params.values && !field.params.locProZoomObject && !field.params.locProZoomAttribut" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true"
        id="action_{{field.prop}}" [name]="getFieldName(field)" [(ngModel)]="objectSend[field.prop]"  [outputType]="field.outputType"
        [required]="field.mandatory" [readonly]="field.readonly" [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}" [values]=field.params.values></lp-dropdown-field>

    <lp-dropdown-field *ngIf="field.type === 'dropdown' && !field.params.path && !field.params.values && field.params.locProZoomObject && field.params.locProZoomAttribut" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true" 
        id="action_{{field.prop}}" [name]="getFieldName(field)" [(ngModel)]="objectSend[field.prop]"  [outputType]="field.outputType" [idField]="field.params.idField" [labelField]="field.params.labelField"
        [required]="field.mandatory" [readonly]="field.readonly" [ngClass]="{'input-smcol-md-5  col-xl-12': isDetail}" [locProZoomObject]="field.params.locProZoomObject" [locProZoomAttribut]="field.params.locProZoomAttribut"></lp-dropdown-field>
      
      <!--DateTimeField-->
      <lp-date-time *ngIf="field.type && field.type === INPUT_FIELD_TYPE_DATETIME" [name]="getFieldName(field)" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true"
        [(ngModel)]="objectSend[field.prop]" [disabled]="field.disabled" [required]="field.mandatory" [readonly]="field.readonly" (putEvtEmitter)="execafterBindEval($event, field.afterBindEval)"></lp-date-time>

      <!--DateField-->
      <lp-date *ngIf="field.type && field.type === INPUT_FIELD_TYPE_DATE" [name]="getFieldName(field)" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true"[ignoreChange]="true"
        [(ngModel)]="objectSend[field.prop]" [required]="field.mandatory" [readonly]="field.readonly" (putEvtEmitter)="execafterBindEval($event, field.afterBindEval)"></lp-date>

      <lp-fuel-gauge *ngIf="field.type && field.type === INPUT_FIELD_TYPE_GAUGE" id="{{field.prop}}" [name]="getFieldName(field)" class="{{arrayClassesElements.get('defaultInput')}}"
        [(ngModel)]="objectSend[field.prop]" [ignoreChange]="true"
        [required]="field.mandatory" [readonly]="field.readonly" [step]="field.params?.step" [min]="field.params?.min"
        [max]="field.params?.max" [disabled]="field.disabled"></lp-fuel-gauge>

      <!--zoom - à changer bien évidemment ! ;)-->
      <!--<lp-zoom-field *ngIf="field.type && field.type === INPUT_FIELD_TYPE_ZOOM" [modal]="true" id="{{field.prop}}" addModifyVerb="{{field.params.addModifyVerb}}"
        kySearch="{{field.params.kySearch}}" libSearch="{{field.params.libSearch}}" zoomId="zoom-{{field.prop}}"
        className="zoomField" cssClass="form-control" searchTextField="id" [tableBindValues]="['id']" [showAddButton]="false"
        [showModifyButton]="false" [tableShowField]="(field.params.tableShowField) ? field.params.tableShowField : ['id']" class="{{arrayClassesElements.get('defaultInput')}}"
          [name]="getFieldName(field)"  [(ngModel)]="objectSend[field.prop]" [required]="field.mandatory" 
          (putEvtEmitter)="execafterBindEval($event, field.afterBindEval)" [parentZoomBindFields]="field.params.parentZoomBindFields"
          [parentTableBindValues]="field.params.parentTableBindValues" [disabled]="field.disabled"></lp-zoom-field>-->
        <lp-zoom-field *ngIf="field.type && field.type === INPUT_FIELD_TYPE_ZOOM" [modal]="true" id="{{field.prop}}" addModifyVerb="{{field.params.addModifyVerb}}"
        kySearch="{{field.params.kySearch}}" libSearch="{{field.params.libSearch}}" zoomId="{{field.params.zoomId}}"
        className="zoomField" cssClass="form-control" searchTextField="id" [tableBindValues]="['id']" [showAddButton]="false"
        [showModifyButton]="false" [tableShowField]="field.params.tableShowField" class="{{arrayClassesElements.get('defaultInput')}}"
          [name]="getFieldName(field)" [(ngModel)]="objectSend[field.prop]" [required]="false" [readonly]="field.readonly" [ignoreChange]="true"
          (putEvtEmitter)="execafterBindEval($event, field.afterBindEval)" [parentZoomBindFields]="field.params.parentZoomBindFields"
          [parentTableBindValues]="field.params.parentTableBindValues" [disabled]="field.disabled" ></lp-zoom-field>
    </div>
    <div class="col-12 mb-2 pe-2 btn-actions">
      <button *ngIf="hasFields" class="transparent-btn pull-right mt-3" type="submit">
        <i class="vega-diskette"></i>
      </button>
      <button *ngIf="!hasFields" type="submit" class="btn btn-primary pull-right mt-3">{{'general.button.yes' | translate}}</button>
    </div>
  </form>
</div>
