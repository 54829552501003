import { LpMeta, propertyMeta } from './meta';
import { FtMeta } from './ft-meta';
import { Type } from 'class-transformer';

export class LpCompany extends LpMeta {
    public isActive: boolean;
    @propertyMeta('string')
    public corporateName: String;
    @propertyMeta('string')
    public address1: String;
    @propertyMeta('string')
    public address2: String;
    @propertyMeta('string')
    public postcode: String;
    @propertyMeta('string')
    public city: String;
    @propertyMeta('string')
    public phone: String;
    @propertyMeta('string')
    public fax: String;
    @propertyMeta('string')
    public mail: String;
    @propertyMeta('string')
    public siret: String;
    @propertyMeta('string')
    public APE: String;
    @propertyMeta('string')
    public intraCommunityVat: String;
    @propertyMeta('string')
    public establishment: String;
    @Type(() => FtMeta)
    public vatDomain: FtMeta = new FtMeta();
}
