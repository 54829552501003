import { LpMeta, propertyMeta  } from './meta';
import { FtMeta } from './ft-meta';
import { Type } from 'class-transformer';
import { LpAddressLink } from './addressLink';

export class LpAddress extends LpMeta {
    @propertyMeta('string')
    public corporateName: String;
    @propertyMeta('string')
    public address1: String;
    @propertyMeta('string')
    public address2: String;
    @propertyMeta('string')
    public address3: String;
    @propertyMeta('string')
    public address4: String;
    @propertyMeta('string')
    public zipCode: String = '';
    @propertyMeta('string')
    public city: String;
    @Type(() => FtMeta)
    public country: FtMeta = new FtMeta();
    @propertyMeta('string')
    public keyword: String;
    @propertyMeta('string')
    public inChargeOf: String;
    @Type(() => FtMeta)
    public function: FtMeta = new FtMeta();
    @propertyMeta('string')
    public siret: String;
    @propertyMeta('string')
    public streetViewMapsAngle: String;
    @propertyMeta('string')
    public analytic: String;
    @propertyMeta('number')
    public latitude: Number;
    @propertyMeta('number')
    public longitude: Number;
    @propertyMeta('boolean')
    public isActive: Boolean;
    public links: LpAddressLink[] = [];
    @Type(() => FtMeta)
    public vatDomain: FtMeta = new FtMeta();
}
