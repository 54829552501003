import { Injectable } from '@angular/core';
import { AlocproProvider } from './alocpro.provider';
import { SchedulerData, SchedulerDate } from 'app/meta/scheduler/scheduler-data';
import { XAxis } from 'app/meta/scheduler/xAxis';
import { PaginatedData } from 'app/models/paginated-data';
import { SchedulerEquipment } from 'app/meta/scheduler/scheduler-equipment';
import { SchedulerMovement } from 'app/meta/scheduler/scheduler-movements';
import { Planning } from 'app/meta/scheduler/planning';
import { DatetimeService } from 'app/services/datetime.service';
import { Util } from 'app/statics/utils';
import * as _ from 'underscore'
import { SchedulerUIService } from 'app/services/scheduler-ui.service';
import { TranslateService } from '@ngx-translate/core';

const DAYS_TO_ADD_WHILE_NAVIGATING: number = 14;

@Injectable({
    providedIn: 'root'
  })

export class PlanningProvider {
    public maxDate: string;
    public minDate: string;
    // private dataMvt: Array<SchedulerData> = [];
    // private equipments: Array<XAxis> = [];

    constructor(private alocproProvider: AlocproProvider, private datetimeService: DatetimeService
        , private schedulerUIService: SchedulerUIService, private translateService: TranslateService) {};

    public async getData(params: Map<string, string>): Promise<Planning> {
        try{
            params.set('showUnaffectedMovements', (this.schedulerUIService.showUnaffectedMovements() ? 'true' : 'false'));
            let tempDataMvt: Array<SchedulerData> = [];
            let tempEquipments: Array<XAxis> = [];
            let index: number = 2;
            let data: PaginatedData = await this.alocproProvider.getPaginatedData('equipments/plannings', null, params, null, null, 'ASC', null, null, true)
            if (data.body) {
            for (let datum of data.body) {
                let schedulerEquipment: SchedulerEquipment = new SchedulerEquipment();
                Object.assign(schedulerEquipment, datum);
                if (Util.isNullOrUndefined(schedulerEquipment.id)) {
                schedulerEquipment.label = this.translateService.instant('scheduler.unaffected')
                }
                tempEquipments.push(this.serializeEquipmentForScheduler(index, schedulerEquipment));
                for (let movement of schedulerEquipment.movements) {
                    let schedulerMovement: SchedulerMovement = new SchedulerMovement();
                    movement.undefinedReturnDate = false;
                    if(Util.isNullOrUndefined(movement.returnDate) || (movement.returnDate === '')){
                        movement.returnDate = '2099-01-01T00:00:00.000Z';
                        movement.undefinedReturnDate = true;
                    }
                    Object.assign(schedulerMovement, movement)
                    let objectTOPush: SchedulerData = this.serializeMovementForScheduler(index, schedulerEquipment, schedulerMovement);
                    objectTOPush.color =
                        this.schedulerUIService.getHexaFromClassColor(this.schedulerUIService.getEventColor(objectTOPush.position));
                    tempDataMvt.push(objectTOPush);
                }
                index += 1
            }
            }
            const d: Planning = new Planning();
            d.movements = tempDataMvt;
            d.equipments = _.sortBy(tempEquipments, 'key');
            let planningData: Planning = d;
            this.updateLoadRange(params.get( 'startDate'), params.get( 'endDate'));
            return planningData;
        } catch(e) {
            throw e;
        }
    }

    public preloadData(params: Map<string, string>, isPreviousRange: boolean): Promise<Planning|void> {
        let tempParams: Map<string, string> = this.updateDateParams(params, isPreviousRange);
        let date: SchedulerDate = {'startDate': tempParams.get('startDate'), 'endDate': tempParams.get('endDate')}
        if (!this.isAlreadyCached(date)) {
            return this.getData(tempParams);
        } else {
            return new Promise<void>((resolve) => {resolve()});
        }
    }

    public isAlreadyCached(d: SchedulerDate): boolean {
        return !this.datetimeService.isBefore(d.startDate.toString(), this.minDate) &&
        !this.datetimeService.isAfter(d.endDate.toString(), this.maxDate);
    }

    private updateDateParams(params: Map<string, string>, isPreviousRange: boolean): Map<string, string> {
        let tempParams: Map<string, string> = new Map();
        params.forEach((value: string, key: string) => {
            if (key === 'startDate') {
                tempParams.set(key, (isPreviousRange ? this.datetimeService.subtractWithTime(params.get('startDate'),
                 DAYS_TO_ADD_WHILE_NAVIGATING, 'days') : params.get('endDate')));
            } else if (key === 'endDate') {
                tempParams.set(key, (isPreviousRange ?  params.get('startDate') : this.datetimeService.addWithTime(params.get('endDate'),
                DAYS_TO_ADD_WHILE_NAVIGATING, 'days')));
            } else {
                tempParams.set(key, value);
            }
        });
        this.updateLoadRange(params.get( 'startDate'), params.get( 'endDate'));
        return tempParams;
    }

    private updateLoadRange(start: string, end: string): void {
        if (Util.isNullOrUndefined(this.maxDate)) {
            this.maxDate = end;
        } else if (this.datetimeService.isAfter(end, this.maxDate)) {
            this.maxDate  = end
        }
        if (Util.isNullOrUndefined(this.minDate)) {
            this.minDate = start;
        } else if (this.datetimeService.isBefore(start, this.minDate)) {
            this.minDate = start;
        }
    }

    private serializeMovementForScheduler(sectionId: number, schedulerEquipment: SchedulerEquipment, schedulerMovement: SchedulerMovement): SchedulerData {
        if(schedulerMovement.startDate.lastIndexOf('Z') > -1){
            schedulerMovement.startDate = schedulerMovement.startDate.slice(0, schedulerMovement.startDate.length-1);
        }
        if(schedulerMovement.returnDate.lastIndexOf('Z') > -1){
            schedulerMovement.returnDate = schedulerMovement.returnDate.slice(0, schedulerMovement.returnDate.length-1);
        }
        if (schedulerEquipment.id) {
            return { start_date: this.datetimeService.newDate(schedulerMovement.startDate), end_date: this.datetimeService.newDate(schedulerMovement.returnDate),
                sectionId: sectionId, customer: (schedulerMovement.customer ? schedulerMovement.customer.lastName : 'NC'),
                documentNumber: schedulerMovement.documentNumber, position: (schedulerMovement.position ? schedulerMovement.position.id : ''), color: '', object: schedulerMovement };
        } else  {
            return { start_date: this.datetimeService.newDate(schedulerMovement.startDate), end_date: this.datetimeService.newDate(schedulerMovement.returnDate),
                sectionId: 1, customer: (schedulerMovement.customer ? schedulerMovement.customer.lastName : 'NC'),
                documentNumber: 'NC', position: (schedulerMovement.position ? schedulerMovement.position.id : ''), color: '', object: schedulerMovement };
        }
    }

    // TODO esssyaer de renvoyer ligne de tableau + class css pour taille (%) pour avoir qqch de tabulaire
    private serializeEquipmentForScheduler(index: number, schedulerEquipment: SchedulerEquipment): XAxis {
        if (schedulerEquipment.id) {
            return {key: index, label: '', obj: schedulerEquipment};
        } else {
            return {key: 1, label: 'nc', obj: schedulerEquipment};
        }
    }

}
