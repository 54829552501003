import { Component, OnDestroy, OnInit } from '@angular/core';
import { RootformComponent } from '../rootform/rootform.component';
import { LpMeta } from 'app/meta/meta';
import * as _ from 'underscore'
import { FtParams } from 'app/meta/ft';
import { Util } from 'app/statics/utils';
import { LpModalChangeComponent } from 'app/ui/lp-modal/lp-modal-change/lp-modal-change.component';
import { Subscription } from 'rxjs';

const VERB: string = 'technical/ft';
const BULK_ACTION_DELETE: string = 'delete';
const BULK_ACTION_INSERT: string = 'insert';
// const BULK_ACTION_UPDATE: string = 'update';
const BULK_ACTION: string = 'action';
@Component({
  selector: 'lp-ft',
  templateUrl: './ft.component.html',
})
export class FTComponent extends RootformComponent implements OnInit, OnDestroy {


  public ky: string = null;
  public FTParams: Array<FtParams>;
  public FTExecute: Array<any>
  public checkboxValues: any;
  public mainZoom: boolean = true;
  private executeFilterFunction: Subscription;
  // private bulkModification: Map<number, LpMeta> = new Map();

  public async ngOnInit(): Promise<void> {
    this.translate.get('loading.data').subscribe(async (value: string) => {
      this.bulkService.linesPerPage = null;
      this.uiSyncService.loader(true, true, this.translate.instant(value));
      this.verb = VERB;
      this.ky = this.route.snapshot.params['ky'];
      await this.formStackService.initApplicationItem(this.verb);
      if (this.ky !== null && this.ky !== undefined ) {
        // this.FTParams = await this.bulkService.getParams(this.verb, this.ky);
        await this.bulkService.bulkConfig(this.verb, this.ky);
        //this.FTExecute = await this.bulkService.getData(this.verb, this.ky, null, null, params);
        this.displayTable();
        this.changeService.initChange();
      } else {
        this.displayMainZoom(this.ky);
      }
      this.initFormStack(this.ky, this.verb)
      this.initcheckbox();
      this.uiSyncService.loader(false);
      // this.uiSyncService.loadObject();
    });

    this.executeFilterFunction = this.uiSyncService.executeFilterFunctionEvtEmitter.subscribe((param?: any) => {
      this.filter();
    });
  }

  public ngOnDestroy(): void {
    this.bulkService.clearCache();
    if (this.executeFilterFunction) {
      this.executeFilterFunction.unsubscribe();
    }
  }

  public async filter(): Promise<void> {
    if (await this.playNextStep() === true) {
      this.uiSyncService.loader(true, true, this.translate.instant('general.modalService.loadRsultsTable'));
      await this.erraseUselessFilters();
      let params: Array<FtParams> = await this.bulkService.bulkConfig(this.verb, this.ky);
      this.formStackService.CurrentApplicationItemDetailData = await this.bulkService.getData(this.verb, this.ky
          , Object.keys(this.formStackService.currentData), null, null, params);
      setTimeout(() => {
        this.uiSyncService.loadObject();
      });
      this.uiSyncService.loader(false);
    }
  }

  public async submit(): Promise<void> {
    this.translate.get('loading.data').subscribe(async (value: string) => {
      try {
        this.uiSyncService.loader(true, true, this.translate.instant(value));
        // let stackIdToLoad: number = null;
        let temp: Array<any> = this.transformZoomData(this.formStackService.CurrentApplicationItemDetailData);
        await this.alocproProvider.put('technical/ft', this.ky + '/bulk', temp);
        this.modalService.success(this.translate.instant('general.modalService.recordingDone'), this.translate.instant('general.modalService.success'));
        this.uiSyncService.loader(false);
        this.filter()
      } catch (e) {
        this.uiSyncService.loader(false);
      }
    });
  }

  public onSort(event: any): void {
    this.FTExecute = _.sortBy(this.FTExecute, event.column.prop);
    if (event.newValue === 'desc') {
      this.FTExecute = this.FTExecute.reverse()
    }
  }

  public addRow(): void {
    let temp: LpMeta = new LpMeta();
    temp[BULK_ACTION] = BULK_ACTION_INSERT;
    this.FTExecute = [...this.FTExecute, temp];
  }

  public rowClass = (row: any) => {
    let object: any = {
      'footer-table': row.field0 === 'footer_table',
      'barre': row.action === BULK_ACTION_DELETE
    };
    return object;
  }

  /*public deleteBulk(row: any, index: number): void {
    let d: LpMeta = this.FTExecute.find((item: any) => item.field0 === row.field0);
    if (!Util.isNullOrUndefined(d)) {
      if (d[BULK_ACTION] === BULK_ACTION_DELETE) {
        d[BULK_ACTION] = null;
        this.bulkModification.delete(index);
      } else {
        d[BULK_ACTION] = BULK_ACTION_DELETE;
        this.bulkModification.set(index, d);
      }
    }
  }*/

  private async displayTable(): Promise<void> {
    this.mainZoom = false;
    this.uiSyncService.IsKyForm = true;
    this.uiSyncService.IsReadyToDisplayForm = true;
    this.rightColService.displaySubAPIDetailsTab(this.formStackService.currentApplicationItemDetail);
  }

  private async displayMainZoom(ky: string): Promise<void> {
    await super.build(VERB, ky);
  }

  private initFormStack(ky: string, verb: string): void {
    this.formStackService.CurrentKy = ky;
    this.formStackService.CurrentVerb = verb;
    this.formStackService.currentData =  new LpMeta();
    this.formStackService.currentData.id = ky;
    this.formStackService.CurrentApplicationItemDetailData = [];
  }

  private initcheckbox(): void {
    this.checkboxValues = [
      {'id': '', 'wording': this.translate.instant('general.boolean.all')},
      {'id': 'true', 'wording': this.translate.instant('general.boolean.yes')},
      {'id': 'false', 'wording': this.translate.instant('general.boolean.no')}
    ];
  }

  private async erraseUselessFilters(): Promise<void> {
    for (let key of Object.keys(this.formStackService.currentData)) {
      if (!Util.isNullOrUndefined(key) && key.includes('field') &&
       ( Util.isNullOrUndefined(this.formStackService.currentData[key]) || this.formStackService.currentData[key] === '' || (this.formStackService.currentData[key] instanceof Object && Util.isNullOrUndefined(this.formStackService.currentData[key].id) )) ) {
        delete this.formStackService.currentData[key];
      }
    }
  }
  
  private transformZoomData(ftExecute: Array<any>): any {
    for (let d of ftExecute){
      for(let p of Object.keys(d)){
        if(d[p] instanceof Object && !Util.isNullOrUndefined(d[p].id)){
          d[p] = d[p].id
        }
      }
    }
    return ftExecute;
  }

  public async playNextStep(): Promise<boolean> {
    try {
      if (this.changeService.getIsLastChanged() && this.changeService.getIsLastChanged() === true) {
        const result: boolean = await this.modalService.modalPromise(LpModalChangeComponent, {
          id : 'modalWithHeader'
        });
        if (result && result === true) {
          this.changeService.clearAndReinit();
        }
        return result;
      } else {
        return true;
      }
    } catch (error) {
      throw error;
    }
  }


}
