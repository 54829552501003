import { Type } from 'class-transformer';
import { LpMeta, propertyMeta } from './meta';
import { LpThirdParty } from './third-party';

export class LpLocationsSituation extends LpMeta {
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('date')
    public startDate: Date;
    @propertyMeta('date')
    public endDate: Date;
    @Type(() => LpThirdParty)
    public owner: LpThirdParty = new LpThirdParty();
    @propertyMeta('string')
    public status: String;
}
