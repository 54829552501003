<lp-ui-accordion id="{{id}}">
    <lp-ui-accordion-group heading="third-parties.accountings.title" [open]=true>
      <form id="formAccountings" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>

            <!-- Field thirdPartyType -->
            <div class="form-group col-xl-12">
              <lp-label-field for="accountings.thirdPartyType" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="third-parties.accountings.thirdPartyType" required></lp-label-field>
              <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control"
                name="accountings.thirdPartyType" [(ngModel)]="data.thirdPartyType" [values]="accountingTypes" required>
              </lp-dropdown-field>
            </div>

              <!-- Field documentType -->
            <div class="form-group col-xl-12">
              <lp-label-field for="accountings.documentType" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="third-parties.accountings.documentType"></lp-label-field>
              <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control"
                name="accountings.documentType" [(ngModel)]="data.documentType" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'documentType'}]}">
              </lp-dropdown-field>
            </div>

              <!-- Field collectiveAccount / client --> 
            <div class="form-group col-xl-12" *ngIf="data.thirdPartyType && data.thirdPartyType.id === '1' && zoomMetaSuplierCollective">
              <lp-label-field for="accountings.collectiveAccount" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="third-parties.accountings.collectiveAccount"></lp-label-field>
              <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control"
                name="accountings.collectiveAccount" [(ngModel)]="data.collectiveAccount" locProZoomAttribut="thirdParty" locProZoomObject="customercollectiveaccount" 
                idField="field0" labelField="field0">
              </lp-dropdown-field>
            </div>
            <!-- Field collectiveAccount / fournisseur -->
            <div class="form-group col-xl-12" *ngIf="data.thirdPartyType && data.thirdPartyType.id === '2' && zoomMetaSuplierCollective">
              <lp-label-field for="accountings.collectiveAccount" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="third-parties.accountings.collectiveAccount"></lp-label-field>
              <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control"
                name="accountings.collectiveAccount" [(ngModel)]="data.collectiveAccount" locProZoomAttribut="thirdParty" locProZoomObject="suppliercollectiveaccount"
                idField="field0" labelField="field0">
              </lp-dropdown-field>
            </div>
            
            <!-- Field auxiliaryAccount  -->
            <div class="form-group col-xl-12" *ngIf="data.thirdPartyType && data.thirdPartyType.id !==  null">
              <lp-label-field for="accountings.auxiliaryAccount" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="third-parties.accountings.auxiliaryAccount"></lp-label-field>                         
                <lp-zoom-field id="accountings.auxiliaryAccount" zoomId="accountings.auxiliaryAccounts" name="accountings.auxiliaryAccount" [(ngModel)]="data.auxiliaryAccount"
                  class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField form-control">
                </lp-zoom-field>

            </div>

              <!-- Field paymentMethods -->
            <div class="form-group col-xl-12">
              <lp-label-field for="accountings.paymentMethods" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="third-parties.accountings.paymentMethods"></lp-label-field>
              <lp-zoom-field id="accountings.paymentMethods" zoomId='accountings.paymentMethods'  className="zoomField form-control" 
                class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.paymentMethod" name="accountings.paymentMethods">
              </lp-zoom-field>
            </div>

            <!-- Field term-->
            <div class="form-group col-xl-12">
              <lp-label-field for="accountings.term" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="third-parties.accountings.term"></lp-label-field>
                <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control" id="accountings.term"
                  name="accountings.term" [(ngModel)]="data.term" outputType="string" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'term'}]}">
                </lp-dropdown-field>
            </div>

            <!-- Field company -->
            <div class="form-group col-xl-12">
              <lp-label-field for="accountings.company" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="third-parties.accountings.company"></lp-label-field>
              <lp-zoom-field id="accountings.company" zoomId="accountings.companies" name="accountings.company"  class="{{arrayClassesElements.get('defaultInput')}}"
                className="zoomField form-control" [(ngModel)]="data.company">
              </lp-zoom-field>
            </div>

        <div class="text-end">
          <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
        </div>
      </form>
    </lp-ui-accordion-group>
    <lp-ui-accordion-group heading="Debugger Mon Composant" *ngIf="debugg">
      <div>
        <pre>{{ data | json }}</pre>
      </div>
    </lp-ui-accordion-group>
  </lp-ui-accordion>