import { AfterContentChecked, HostListener, Output, EventEmitter } from '@angular/core';
import { ChangeDetectorRef, Component, forwardRef, Input, OnDestroy, OnInit, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Util } from 'app/statics/utils';
import { Subscription, interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AlocproProvider } from '../providers/alocpro.provider';
import { NG_VALUE_ACCESSOR, NgForm } from '@angular/forms';
import { ZoomService } from '../services/zoom.service';
import { RepositoryProvider } from '../providers/repository.provider';
import { UiSyncService } from '../services/ui-sync.service';
import { TranslateService } from '@ngx-translate/core';
import { LpMeta } from 'app/meta/meta';
import { ModalService } from 'app/services/modal.service';
import { ZoomCriteria } from 'app/meta/zoom/zoomMeta';
import { PaginatedData } from 'app/models/paginated-data';
import { DatetimeService } from 'app/services/datetime.service';
import { ZoomProvider } from 'app/providers/zoom.provider';
import { JsonCsv } from 'app/models/json-csv';
import { FormatService } from 'app/services/format.service';
import { RouterService } from 'app/services/router.service';
import { FormStackService } from 'app/services/form-stack.service';
import { UserService } from 'app/services/user.service';
import { IhmStackService } from 'app/services/ihm-stack.service';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ConfigService } from 'app/services/config.service';

// TODO ZOOM crée un locProZoomComponent

const MAIN_ZOOM: string = 'main';
@Component({
  selector: 'lp-zoom',
  templateUrl: './zoom.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ZoomComponent),
      multi: true
    }
  ]
})
export class ZoomComponent implements OnInit, AfterContentChecked, OnDestroy {
  @Input() public modal: Boolean;
  @Input() public isWidget: Boolean;
  @Input() public locProZoomObject: string;
  @Input() public locProZoomId: string;
  @Input() public widgetObject: any;

  @Input() public guid: string;
  
  @Output() private afterInit: EventEmitter<void> = new EventEmitter();
  @Output() private modalClose: EventEmitter<void> = new EventEmitter();

  @ViewChild(DatatableComponent) public table: DatatableComponent;

  public id: String;
  public zoomDataList: Array<LpMeta> = [];
  public isShown: boolean;
  public isClosable: Boolean;
  public title: String;
  public loadingIndicator: Boolean = false;
  public urlNew: String;
  public newdataKeyWord: String = this.configService.get('newdataKeyWord');
  public isLocProZoom: Boolean = true;
  public criterias: Array<ZoomCriteria> = [];
  public titleZoom: string;
  public displayMore: boolean = false;
  public showPlanning: boolean = false;
  public param: string;
  public zoomDataCount: Number;
  public currentPage: number = 1;
  public linesPerPage: string;
  public criteriaClass: String;
  public cols: any;
  public hasButtonAdd: Boolean = false;
  public hasButtonCriterias: Boolean = false;
  public hasButtonOk: Boolean = false;
  public hasButtonUpdate: Boolean = false;
  public elementTemp: HTMLElement = null;
  private backdrop: Boolean | 'static' = 'static';
  private filters: Map<string, any> = new Map();
  private lineCount: number = 0;
  private arrayColumnsWithCriterias: Array<Array<ZoomCriteria>> = new Array<Array<ZoomCriteria>>();

  private subscriptionDisplayZoom: Subscription;
  private subscriptionClose: Subscription;
  private subscriptionUpdateDefaultValueField: Subscription;
  private filterVal: String = '';
  private focusDone: boolean = false;

  constructor(
    protected alocproProvider: AlocproProvider,
    protected repositoryPovider: RepositoryProvider,
    public zoomService: ZoomService,
    protected zoomProvider: ZoomProvider,
    protected routerService: RouterService,
    protected uiSyncService: UiSyncService,
    protected translate: TranslateService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected modalService: ModalService,
    protected dateTimeService: DatetimeService,
    private formatService: FormatService,
    public formStackService: FormStackService,
    public myElement: ElementRef,
    private renderer: Renderer2,
    private userService: UserService,
    public ihmService: IhmStackService,
    public configService: ConfigService) { }

  // escape listener
  @HostListener('document:keydown', ['$event']) public onKeyDown(evt: KeyboardEvent): void {
    
  }

  public ngOnInit(): void {
    if (this.userService.getCurrentUser() && this.userService.getCurrentUser().preference !== null
      && this.userService.getCurrentUser().preference !== undefined
      && this.userService.getCurrentUser().preference.linesPerPage) {
      this.linesPerPage = this.userService.getCurrentUser().preference.linesPerPage;
    } else {
      this.linesPerPage = this.configService.get('linesPerPage');
    }

    if (!this.modal) {
      this.backdrop = false;
    }
    this.subscriptionDisplayZoom = this.uiSyncService.displayZoomEvtEmitter.subscribe(async(param?: any) => {
      if ( !Util.isNullOrUndefined(this.zoomService.getConfig(param.guid)) && !Util.isNullOrUndefined(this.zoomService.getConfig(param.guid).url)) {
        this.guid = param.guid;
        this.id = this.zoomService.getId(this.guid);
        this.titleZoom = this.zoomService.getTitle(this.guid);
        this.lineCount = this.zoomService.getLineCount(this.guid);
        this.hasButtonAdd = this.zoomService.hasButtonAdd(this.guid);
        this.hasButtonCriterias = this.zoomService.hasButtonCriterias(this.guid);
        this.hasButtonOk = this.zoomService.hasButtonOk(this.guid);
        this.hasButtonUpdate = this.zoomService.hasButtonUpdate(this.guid);
        this.param = param.param;
        this.zoomService.zoomStackParam.set(this.zoomService.zoomStackParam.size, param.param)
        this.initZoomBehavior(this.guid);
        const pag: PaginatedData = this.zoomService.getConfig(this.guid).data;
        this.cols = this.zoomService.getConfig(this.guid).zoomParameter.columns;
        this.zoomDataList = pag.body;
        this.zoomDataCount = pag.count;
        this.currentPage = 1;
        this.loadingIndicator = false;
        this.uiSyncService.loader(false);
      } else {
        this.focusDone = false;
        this.uiSyncService.loader(false);
        if (param !== undefined && param !== null && param.guid !== undefined && param.guid !== null && !this.isWidget) {
          this.guid = param.guid;
        }
        if (!Util.isNullOrUndefined(param.guid) && param.guid === this.guid) {
          if (!Util.isNullOrUndefined(param.param)) {
            this.zoomService.zoomStackParam.set(this.zoomService.zoomStackParam.size, param.param)
          }
          this.initZoomBehavior(this.guid);
          if (this.modal === this.zoomService.getConfig(this.guid).modal) {
            this.id = this.zoomService.getId(this.guid);
            this.titleZoom = this.zoomService.getTitle(this.guid);
            this.criterias = this.zoomService.getCriterias(this.guid);
            this.lineCount = this.zoomService.getLineCount(this.guid);
            this.hasButtonAdd = this.zoomService.hasButtonAdd(this.guid);
            this.hasButtonCriterias = this.zoomService.hasButtonCriterias(this.guid);
            this.hasButtonOk = this.zoomService.hasButtonOk(this.guid);
            this.hasButtonUpdate = this.zoomService.hasButtonUpdate(this.guid);
            this.setColumns(this.zoomService.getConfig(this.guid).zoomParameter.criterias, this.lineCount);
            if (Util.isNullOrUndefined(this.zoomService.getConfig(this.guid).zoomParameter.type)
              || this.zoomService.getConfig(this.guid).zoomParameter.type !== 3
              || this.areDefaultValues() === true) {
              this.loadingIndicator = true;
              let data: PaginatedData = await this.zoomProvider.executeWithCache(this.zoomService.getConfig(this.guid), this.formStackService.currentData,
              this.zoomService.getConfig(this.guid).zoomParameter.criterias, null, null, this.zoomService.isDropDownMode(this.zoomService.getConfig(this.guid)))
              this.currentPage = 1;
              this.zoomDataList = data.body;
              this.zoomDataCount = data.count;
              this.loadingIndicator = false;
              this.uiSyncService.loader(false);
            }
            this.cols = this.getVisibleCols();
          } else {
            if (Util.isNullOrUndefined(this.isShown) || !this.isShown) {
              this.isShown = false;
              this.ihmService.ShowRightZoomComponent = false
              if (this.isModal()) {
                this.close();
              }
            } else {
              if (Util.isNullOrUndefined(this.zoomService.getConfig(this.guid))) {
                this.isShown = false;
                if (this.isModal()) {
                  this.close();
                }
              } else {
                this.criterias = this.zoomService.getConfig(this.guid).zoomParameter.criterias;
                this.setColumns(this.criterias, this.zoomService.getConfig(this.guid).zoomParameter.criteriasByLine);
              }
            }
          }
        }
      }
      this.isShown = true;
    });
    this.subscriptionClose = this.zoomService.closeEventEmitter.subscribe((close: boolean) => {
      this.isShown = false;
      if (this.isModal()) {
        this.close();
      }
    });
    this.subscriptionUpdateDefaultValueField = this.zoomService.updateDefaultValueFieldEvtEmitter.subscribe((data: any) => {
      this.updateDefaultValueField(data);
    });
    if (!this.focusDone) {
      this.focusOnFirstCriteria();
    }

    this.afterInit.emit();
  }

  public ngOnDestroy(): void {
    if (this.subscriptionClose) {
      this.subscriptionClose.unsubscribe();
    }
    if (this.subscriptionDisplayZoom) {
      this.subscriptionDisplayZoom.unsubscribe();
    }
    if (this.subscriptionUpdateDefaultValueField) {
      this.subscriptionUpdateDefaultValueField.unsubscribe();
    }
    if (this.guid !== undefined || this.guid !== null) {
      // this.zoomService.clearConfig(this.guid);
    }
  }

  public close(): void {
    this.modalClose.emit();
  }

  public isMainZoom(): boolean {
    let isMainZoom: boolean = false;
    (this.zoomService && this.zoomService.getConfig(this.guid) &&
      this.zoomService.getConfig(this.guid).locProZoomAttribut &&
      this.zoomService.getConfig(this.guid).locProZoomAttribut.toLowerCase() === MAIN_ZOOM) ? isMainZoom = true : isMainZoom = false;
    return isMainZoom;
  }

  public isALocProZoom(): boolean {
    let isALocProZoom: boolean = false;
    (this.zoomService && this.zoomService.getConfig(this.guid) &&
      this.zoomService.getConfig(this.guid).isLocProZoom &&
      this.zoomService.getConfig(this.guid).isLocProZoom === true) ? isALocProZoom = true : isALocProZoom = false;
    return isALocProZoom;
  }

  public thereIsAListOfZoom(): boolean {
    let thereIsAListOfZoom: boolean = false;
    (this.zoomService.getConfig(this.guid) && this.zoomService.hasParentZoom(this.guid))
      ? thereIsAListOfZoom = true : thereIsAListOfZoom = null;
    return thereIsAListOfZoom;
  }

  public areDefaultValues(): boolean {
    for (let i: number = 0; i < this.criterias.length; i = i + 1) {
      if (this.criterias[i] && !Util.isNullOrUndefined(this.criterias[i].defaultValue) && this.criterias[i].defaultValue !== '') {
        return true;
      }
    }
    return false;
  }

  public focusOnFirstCriteria(): void {
    if (this.criterias && this.criterias[0] && this.criterias[0].paramName) {
      try {
        const element: HTMLElement = this.renderer.selectRootElement('#forcriteria-' + this.criterias[0].paramName);
        if (!Util.isNullOrUndefined(element)) {
          this.elementTemp = element;
          element.focus();
          setTimeout(() => {
            this.focusDone = true;
          }, 0)
          return;
        }
      } catch (error) { }
    }
  }

  public updateDefaultValueField(data: any): void {
    let isSameWord: boolean = true;
    for (let i: number = 0; i < this.criterias.length; i = i + 1) {
      if (this.criterias[i].paramName === data[1]) {
        if (this.criterias[i].defaultValue !== data[0]) {
          this.criterias[i].defaultValue = data[0];
          isSameWord = false;
        }
      }
    }
    if (isSameWord === false) {
      this.submit();
    }
  }

  public ngAfterContentChecked(): void {
    if (this.focusDone === false) {
      this.focusOnFirstCriteria();
    }
  }

  public selectItem(value: Event): void {
    if (this.hasButtonOk) {
      let v: any = value;
      let tempValue: any = value;
      if (value.type === 'click') {
        if (this.zoomService.getConfig(this.guid).isString) {
          if (!Util.isNullOrUndefined(this.zoomService.getConfig(this.guid).url)) {
            this.id = '';
            this.zoomService.selectRowbis(v, this.id.toString(),
            this.zoomService.zoomStackParam.get(this.zoomService.zoomStackParam.size - 1), tempValue.row, this.guid, this.zoomService.getConfig(this.guid).locProZoomAttribut);
         } else {
          this.zoomService.selectRowbis(v, this.id.toString(),
          this.zoomService.zoomStackParam.get(this.zoomService.zoomStackParam.size - 1), Util.isNullOrUndefined(tempValue.row.field0) ? tempValue.row : tempValue.row.field0, this.guid,
          this.zoomService.getConfig(this.guid).zoomParameter.criterias[0]?.defaultValue);
         }
        } else {
          if (!Util.isNullOrUndefined(this.zoomService.getConfig(this.guid).url)) {
             this.id = '';
             this.zoomService.selectRowbis(v, this.id.toString(),
             this.zoomService.zoomStackParam.get(this.zoomService.zoomStackParam.size - 1), tempValue.row, this.guid, this.zoomService.getConfig(this.guid).locProZoomAttribut);
          } else {
            this.zoomService.selectRowbis(v, this.id.toString(),
            this.zoomService.zoomStackParam.get(this.zoomService.zoomStackParam.size - 1), Util.isNullOrUndefined(tempValue.row.field0) ? tempValue.row : tempValue.row.field0, this.guid
            , this.zoomService.getConfig(this.guid).locProZoomAttribut);
          }
        }
        this.closeModal(this.guid);

        if (!this.zoomService.hasParentZoom(this.guid) && this.zoomService.getConfig(this.guid)
         && this.zoomService.getConfig(this.guid).locProZoomAttribut !== 'main') {
          this.ihmService.displayRightZoomComponent();
        } else
        if (!this.modal || (this.zoomService.getConfig(this.guid) && this.zoomService.getConfig(this.guid).locProZoomAttribut === 'main')) {
          this.ihmService.displaySubAPIDetails(this.formStackService.CurrentKy);
        } else if (this.modal && (this.zoomService.getConfig(this.guid) && this.zoomService.getConfig(this.guid).locProZoomAttribut !== 'main')) {
          // si on est un zoom à droite
        } else {
          // this.ihmService.displaySubAPIDetails();
        }
      }
    }
  }

  public onHidden(): void {
    this.isShown = false;
    if (this.isModal()) {
      this.close();
    }
  }

  public transformDefaultValueForDropDown(): void {
    if (!Util.isNullOrUndefined(this.zoomService.getConfig(this.guid).zoomParameter.criterias)) {
      for (let indexCriteria: number = 0;
        indexCriteria <= this.zoomService.getConfig(this.guid).zoomParameter.criterias.length;
        indexCriteria = indexCriteria + 1) {
        if (this.zoomService.isDropDown(this.criterias[indexCriteria]) === true && this.criterias[indexCriteria].defaultValue &&
          this.criterias[indexCriteria].defaultValue['id']) {
          this.criterias[indexCriteria].defaultValue = this.criterias[indexCriteria].defaultValue['id'];
        }
      }
    }
  }

  public submit(): void {
    this.loadingIndicator = true;
    this.uiSyncService.loader(true, false, this.translate.instant(this.translate.instant('loading.data')));
    this.currentPage = 1;
    this.transformDefaultValueForDropDown();
    this.zoomProvider.getRowWithFilters(
      this.zoomService.getConfig(this.guid), this.zoomService.getConfig(this.guid).zoomParameter.criterias,
      this.formStackService.currentData, this.linesPerPage, this.currentPage)
      .then((data: PaginatedData) => {
        this.zoomDataList = data.body;
        this.currentPage = 1;
        this.zoomDataCount = data.count;
        this.loadingIndicator = false;
        this.uiSyncService.loader(false);
      });
  }

  public updateDate(zoomForm: NgForm, event: any, field: string, value: string): void {
    if (event && field && value) {
      this.filters.delete(field);
    } else {
      this.filters.set(field, event);
    }
  }

  public formatDate(date: string): string {
    let dateFormated: string = '';
    if (date && date.length !== 0) {
      dateFormated = this.dateTimeService.dateFormatFR(date);
    }
    return dateFormated
  }

  public cancel(): void {
    this.closeModal(null);
    if (this.zoomService.hasParentZoom(this.guid)) {
      this.zoomService.getConfig(this.guid).isFromChild = true
    }
    if (!Util.isNullOrUndefined(this.zoomService.getConfig(this.guid))) {
      this.zoomService.displayZoom(this.zoomService.getConfig(this.guid), this.guid);
    }
  }

  public closeModal(guid: string): void {
    // this.uiSyncService.displayApplicationItemDetail();
    if (!Util.isNullOrUndefined(this.id) || this.id !== '') {
        let guidTemp: string = this.zoomService.getConfig(guid).parentGuid;
        this.zoomService.clearConfig(this.guid);
        this.guid = guidTemp;
    }
    if (!Util.isNullOrUndefined(this.zoomService.getConfig(guid)) && this.zoomService.getConfig(guid).modal) {
      this.zoomService.displayZoom(this.zoomService.getConfig(guid), this.zoomService.getParentZoomGuid(this.guid));
      this.zoomService.clearConfig(this.guid)
    } else {
      // this.ihmService.ShowRightZoomComponent = false;
      this.isShown = false;
      if (this.isModal()) {
        // event pour close les zoom filtre sur le planning
        if(!Util.isNullOrUndefined(this.zoomService.getParentZoomGuid(this.guid))){
          this.zoomService.closeZoom();
        }
       } else if(this.isModal() === false) {
          this.ihmService.displaySubAPIDetails(this.formStackService.CurrentKy);
       }
    }
    this.close();
  }

  public isModal(): Boolean {
    if (!Util.isNullOrUndefined(this.zoomService.getConfig(this.guid))) {
      if (this.modal === this.zoomService.getConfig(this.guid).modal) {
        return this.zoomService.getConfig(this.guid).modal;
      } else {
        return false;
      }
    } else {
      return undefined;
    }
  }

  public getbackdrop(): Boolean | String {
    if (this.zoomService.getConfig(this.guid) && this.zoomService.getConfig(this.guid).modal) {
      return true;
    } else {
      return this.backdrop;
    }
  }

  public navigate(verb: String, ky: String): void {
    let stop: Boolean = false;
    interval(200)
      .pipe(takeWhile(() => !stop))
      .subscribe(i => {
        stop = true;
        this.routerService.navigate(verb, ky);
      });
  }

  public onSort(event: any): void {
    this.loadingIndicator = true;
    let criteriaSort: ZoomCriteria = {
      defaultValue: event.sorts[0].prop,
      paramName: '_sort',
      isMandatory: null,
      isReadOnly: null,
      attribute: null,
      type: null,
      wording: null,
      isHidden: null
    }
    this.criterias.push(criteriaSort);
    let criteriaOrder: ZoomCriteria = {
      defaultValue: event.sorts[0].dir.toUpperCase(),
      paramName: '_order',
      isHidden: null,
      isMandatory: null,
      isReadOnly: null,
      type: null,
      wording: null,
      attribute: null
    }
    this.criterias.push(criteriaOrder);
    this.zoomProvider.getRowWithFilters(this.zoomService.getConfig(this.guid),
      this.criterias, this.formStackService.currentData, this.linesPerPage, this.currentPage).then((data: PaginatedData) => {
        this.zoomDataList = data.body
        this.loadingIndicator = false;
        this.currentPage = 1;
        this.zoomDataCount = data.count;
      });
  }

  public exportCSV(): void {
    if (this.isLocProZoom) {
      this.zoomProvider.getRowWithFilters(this.zoomService.getConfig(this.guid), this.criterias, this.formStackService.currentData, 'NOLIMIT')
        .then((data: PaginatedData) => {
          const jsonCsv: JsonCsv = new JsonCsv(this.formatService, this.translate, this.zoomService.getConfig(this.guid)
            .zoomParameter.columns, data.body, 'export' + Date.now());
          jsonCsv.generateCsv();
        });
    } else {
      this.zoomProvider.getZoomDataList(this.zoomService.getConfig(this.guid),
        this.formStackService.currentData, this.filterVal.toString(), 'NOLIMIT').then((data: PaginatedData) => {
          const jsonCsv: JsonCsv = new JsonCsv(this.formatService, this.translate,
            this.zoomService.getConfig(this.guid).zoomParameter.columns, data.body, 'export' + Date.now());
          jsonCsv.generateCsv();
        });
    }
  }

  public changePage(obj: any): void {
    this.loadingIndicator = true;
    this.linesPerPage = obj.linesPerPage;
    this.currentPage = obj.currentPage;
    this.zoomProvider.getRowWithFilters(
      this.zoomService.getConfig(this.guid), this.criterias, this.formStackService.currentData, obj.linesPerPage, obj.currentPage)
      .then((data: PaginatedData) => {
        this.zoomDataList = data.body;
        this.loadingIndicator = false;
      });
  }

  protected propagateChange = (_: any) => {
  };

  private getVisibleCols(): any {
    let cols: any = this.zoomService.getConfig(this.guid).zoomParameter.columns;
    if (!this.zoomService.getConfig(this.guid).isLocProZoom) {
      return cols;
    } else {
      let visibleCols: any[] = [];
      let noWidth: Boolean = false;
      for (let i: number = 0; i < cols.length; i++) {
        if (cols[i].width === '' || parseInt(cols[i].width, 10) > 0) {
          if (cols[i].width === '') {
            noWidth = true;
          }
          visibleCols.push(cols[i]);
        }
      }

      // Si toutes les tailles sont fixées, on supprime la taille de la dernière colonne pour lui faire prendre toute la place qu'il reste.
      if (!noWidth && visibleCols.length) {
        visibleCols[visibleCols.length - 1].width = '';
      }

      return visibleCols;
    }
  }

  private initZoomBehavior(guid: string): void {
    this.arrayColumnsWithCriterias = [];
    this.isShown = true;
    this.zoomDataList = [];
    this.title = this.zoomService.getTitle(guid);
    if ( !Util.isNullOrUndefined(this.zoomService.getConfig(this.guid) &&
         !Util.isNullOrUndefined(this.zoomService.getConfig(this.guid).urlNew))) {
      this.urlNew = this.zoomService.getConfig(this.guid).urlNew;
    }
    if ( !Util.isNullOrUndefined(this.zoomService.getConfig(this.guid)) &&
         !Util.isNullOrUndefined(this.zoomService.getConfig(this.guid).isClosable)) {
          this.isClosable = this.zoomService.getConfig(this.guid).isClosable;
    } else { 
      this.isClosable = false;
    }
    if ( !Util.isNullOrUndefined(this.zoomService.getConfig(this.guid)) &&
         !Util.isNullOrUndefined(this.zoomService.getConfig(this.guid).isLocProZoom)) {
          this.isLocProZoom = this.zoomService.getConfig(this.guid).isLocProZoom;
    } else { 
      this.isLocProZoom = false;
    }
  }

  private setColumns(criterias: Array<ZoomCriteria>, numberLine: number): void {
    if (numberLine === 0) {
      numberLine = 3;
    }

    numberLine = 6;
    this.criteriaClass = 'col-md-4 col-lg-2';
    /*switch (numberLine) {
      case 1: {
        this.criteriaClass = 'col-md-12';
        break;
      }
      case 2: {
        this.criteriaClass = 'col-md-6';
        break;
      }
      case 3: {
        this.criteriaClass = 'col-md-4';
        break;
      }
      case 4: {
        this.criteriaClass = 'col-md-3';
        break;
      }
      case 6: {
        this.criteriaClass = 'col-md-2';
        break;
      }
    }*/

    let arrayCriterias: Array<ZoomCriteria> = new Array<ZoomCriteria>();
    criterias.forEach((criteria: ZoomCriteria, index: number) => {
      if (arrayCriterias.length < numberLine) {
        arrayCriterias.push(criteria);
      } else if (arrayCriterias.length === numberLine) {
        this.arrayColumnsWithCriterias.push(arrayCriterias);
        arrayCriterias = [];
        arrayCriterias.push(criteria);
      }
      if (index === criterias.length - 1) {
        this.arrayColumnsWithCriterias.push(arrayCriterias);
        arrayCriterias = [];
      }
    });
  }

}
