import { LpMeta, propertyMeta } from './meta';
import { FtMeta } from './ft-meta';
import { Type } from 'class-transformer';

export class LpPersonalModel extends LpMeta {
    @propertyMeta('number')
    public tankCapacity: Number;
    @propertyMeta('number')
    public fiscalPower: Number;
    @propertyMeta('string')
    public mineType: String;
    @Type(() => FtMeta)
    public category: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public counterKind: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public state: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public energy: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public kind: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public body: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public family: FtMeta = new FtMeta();
    @propertyMeta('string')
    public registrationJ: String;
    @propertyMeta('string')
    public registrationJ2: String;
    @propertyMeta('string')
    public registrationU1: String;
    @propertyMeta('number')
    public doors: Number;
    @Type(() => FtMeta)
    public maintenanceModelsRules: FtMeta = new FtMeta();
    @propertyMeta('number')
    public registrationP2: Number;
    @propertyMeta('number')
    public registrationS1: Number;
    @propertyMeta('number')
    public registrationS2: Number;
    @propertyMeta('number')
    public displacement: Number;
    @propertyMeta('number')
    public registrationG1: Number;
    @propertyMeta('number')
    public registrationF3: Number;
    @propertyMeta('number')
    public registrationF2: Number;
    @propertyMeta('number')
    public payload: Number;
    @propertyMeta('string')
    public registrationD2: String;
    @propertyMeta('string')
    public registrationD3: String;
    @propertyMeta('number')
    public registrationF1: Number;
    @propertyMeta('number')
    public registrationG: Number;
    @propertyMeta('number')
    public registrationV7: Number;
    @propertyMeta('string')
    public registrationV9: String;
    @Type(() => FtMeta)
    public brand: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public acris: FtMeta = new FtMeta();
    @propertyMeta('string')
    public eanCode: String;
    @propertyMeta('string')
    public registrationU2: String;
    @propertyMeta('string')
    public urbanConsumption: String;
    @propertyMeta('string')
    public highwayConsumption: String;
    @propertyMeta('string')
    public motorCode: String;
    @propertyMeta('number')
    public mixedConsumption: Number;
    @propertyMeta('string')
    public tyresType: String;
    @Type(() => FtMeta)
    public family1: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public family2: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public family3: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public mcpeModel: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public tyreState: FtMeta = new FtMeta();
    @propertyMeta('boolean')
    public isLimitedSerie: Boolean;
    @Type(() => FtMeta)
    public breakState: FtMeta = new FtMeta();
}
