import { Component, OnInit } from '@angular/core';
import { LpUser } from '../../meta/user';
import { RootformComponent } from '../rootform/rootform.component';

@Component({
  selector: 'lp-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UsersComponent extends RootformComponent implements OnInit {

  public data: LpUser = null;
  public ky: string = null;

  public async ngOnInit(): Promise<void> {
    await super.build('users', this.route.snapshot.params['ky']);
    if (this.formStackService.currentData !== null && this.formStackService.currentData !== undefined ) {
      this.data.assign(this.formStackService.currentData);
    }
  }
}
