import { Injectable } from '@angular/core';
import { FormStackService } from '../form-stack.service';
import { StackItem } from 'app/models/stackitem';
import { RouterService } from '../router.service';
import { UiSyncService } from '../ui-sync.service';
import { Util } from 'app/statics/utils';
import * as _ from 'underscore'
import { FOCUS_OUT_EVENT, EvalService, MAIN, FOCUS_OUT_CHANGE_EVENT } from '../eval.service';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(
    private formStackService: FormStackService,
    private routerService: RouterService,
    private uiSyncService: UiSyncService,
    private evalService: EvalService) { }

  public async initHisto(stackIdToLoad?: number, previouslySaved?: boolean): Promise<void> {
    try {
      await this.getCurrentFromHisto(stackIdToLoad, previouslySaved);
      await this.updateMap(stackIdToLoad);

      this.uiSyncService.IsKyForm = true;
      this.routerService.navigate(this.formStackService.CurrentVerb, this.formStackService.CurrentKy);
    } catch (e) {
      throw (e);
    }
  }

  private async getCurrentFromHisto(stackIdToLoad?: number, previouslySaved?: boolean): Promise<void> {
    this.formStackService.initFromHistoryId(stackIdToLoad, previouslySaved);
    if (!Util.isNullOrUndefined(this.formStackService.BindingProp)) {
      this.evalService.executeContrainte(
        FOCUS_OUT_CHANGE_EVENT, MAIN, this.formStackService.currentApplicationItem.ecrId.toString(), this.formStackService.BindingProp.toString(),
        this.formStackService.currentData, this.formStackService.currentFields);
      this.evalService.executeContrainte(
        FOCUS_OUT_EVENT, MAIN, this.formStackService.currentApplicationItem.ecrId.toString(), this.formStackService.BindingProp.toString(),
        this.formStackService.currentData, this.formStackService.currentFields);
        
    }
  }

  private async updateMap(id: number): Promise<void> {
    _.each(Array.from(this.formStackService.stack), (item: [string, StackItem], index: number) => {
      if (index >= id) {
        this.formStackService.ignoreDataLoading = true;
        this.formStackService.stack.delete(item[0]);
      }
    });
  }

}

