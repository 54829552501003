  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'workflows.titleList' | translate}}</h4>

    <div class="pull-right">
      <span class="ms-2 me-2" (click)="changeConfigPanel()">
        <i class="icon fa fa-cogs icon-tableSetings ediTableSettings"></i>
      </span>
      <lp-button-new (click)="actionOnWorkflow('NEW')"></lp-button-new>
      <lp-button-cancel (action)="close()"></lp-button-cancel>
    </div>
  </div>
  <div class="modal-body">
    <ngx-datatable 
    (activate)="actionOnWorkflow($event)"
    class="material table-height-35"
    [rows]="workflows"
    [columnMode]="'force'" [headerHeight]="35" [rowHeight]="'auto'"
    [messages]="{emptyMessage: 'general.emptyMessage' |translate}" 
    [rowClass]="rowClass"
    [footerHeight]="(workflowStruct?.details[0]?.pagination || workflowStruct?.details[0]?.bulk) ? 35 : 0"
    [loadingIndicator]="loadingIndicator"
    (page)="changePage($event)"
    (sort)="onSort($event)"
    width="100%">

    <ngx-datatable-column *ngFor="let col of getDisplayedColumns() index as colIndex"
      name="{{(col.wording !== null && col.wording !== undefined) ? col.wording : ((col.prop === 'actionColumn') ? '' : workflowStruct?.details[0]?.key + '.' + col.name |translate)}}"
      [width]="col.width" [resizeable]="col.resizeable"
      [sortable]="col.sortable" [draggable]="col.draggable"
      data-prop="{{col.prop}}" [canAutoResize]="col.canAutoResize" [hidden]="!col.isDisplayed">

      <!-- template de base -->
      <ng-template ngx-datatable-cell-template let-rowIndex="rowIndex" let-value="value" let-row="row" let-i="index">

        <div>
          <lp-cell
            [type]="col.type"
            [value]="jsdataService.getDataRef(workflows[rowIndex], col.prop)">
          </lp-cell>
        </div>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-footer
      *ngIf="workflowStruct?.details[0]?.pagination || workflowStruct?.details[0]?.bulk">
      <ng-template ngx-datatable-footer-template>
        <lp-footer *ngIf="workflowStruct?.details[0]?.pagination" [guid]="guid"
          (changePage)="changePage($event, workflowStruct?.details[0]?.bulk)"
          [dataCount]="nbWorkflows"
          [linesPerPage]="userService.getCurrentUser().preference.linesPerPage" [currentPage]="currentPage"
          [allLines]=true [showAddRow]="workflowStruct?.details[0]?.bulk" (addRow)="addRow($event)">
        </lp-footer>
      </ng-template>
    </ngx-datatable-footer>
  </ngx-datatable>

  <div *ngIf="showConfigPanel" class="detail-config-tab">
    <lp-ui-sort-list [isBulk]="workflowStruct?.details[0]?.bulk" [isNotADetailButAModal]='true' [list]="workflowStruct.details[0].columns" prefix="{{workflowStruct?.details[0]?.key + '.'}}" label="title" prop="isDisplayed"></lp-ui-sort-list>
  </div>
  </div>
