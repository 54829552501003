import { Injectable } from '@angular/core';
import { BreadcrumbItem } from '../models/breadcrumb-item';
import { RepositoryProvider } from '../providers/repository.provider'


@Injectable({
    providedIn: 'root'
})

export class MenuProvider {

        /**
     * Tableau contenant le cache de toutes les requêtes autorisées à être en cache.
     */
    private iconCache: Map<String, BreadcrumbItem> = new Map;


    constructor(private repositoryService: RepositoryProvider) {};
    /**
     * La fonction getIcon() retourne la classe de l'icon selon le verb demandé.
     * @param verb
     */
    public getBreadcrumb(verb: String): Promise<any> {
        if (this.iconCache.has(verb)) {
            return new Promise((resolve) => {
                resolve(this.iconCache.get(verb));
            })
        } else {
            return new Promise<any>((r) => {
                this.repositoryService.getBreadcrumbItem(verb).then((res) => {
                    let icon: BreadcrumbItem = new BreadcrumbItem();
                    Object.assign(icon, res);
                    this.iconCache.set(verb, icon);
                    r(icon);
                });
            }).catch((err) => {});
        }
    }
}
