
<div class="panel-group">
  <div [ngClass]="'panel card panel-primary open'">
    <div [ngClass]="'panel-heading card-header'" [class.mb-2]="followLinks">
      <h5>{{title |translate}}</h5>
      <div [ngClass]="'title-buttons'">
        <ul *ngIf="followLinks" class="scheduler-details-icons p-0 pt-0">
          <li *ngFor="let link of followLinks" class="scheduler-details-icon ms-2">
            <!-- TODO différencier le conducteur du client (les deux hint son "tier"-->
            <lp-childform-link *ngIf="link.details" verb="planning" key="planning" class="scheduler-details-icon-link" childType="{{link.details.split('/')[0]}}"
            ky="{{link.details.split('/')[1]}}">
              <i class="icon {{link.icon.classes}}" title="{{link.icon.title | translate}}"></i>
            </lp-childform-link>
          </li>
        </ul>
        <lp-button-cancel *ngIf="showCloseBtn" (action)="closeModal()"></lp-button-cancel>
      </div>
    </div>
    <div class="modal-body">
        <table *ngIf="detailService.values && detailService.metadataDetails && detailService.metadataDetails.columns" class="col-12 table-details">
          <tbody>
            <tr *ngFor="let column of detailService.metadataDetails.columns; let i = index">
              <ng-container>
                <td valign="middle">{{'zoomDetails.' + detailService.verb + '.' + detailService.metadataDetails.columns[i].name | translate}}</td>
                <td valign="middle">{{detailService.values[i]}}</td>
              </ng-container>
            </tr>
          </tbody>          
        </table>
        <div *ngIf="!detailService.values || !detailService.metadataDetails || !detailService.metadataDetails.columns" class="alert alert-danger" role="alert">
          {{'zoomDetails.error404' | translate}}
        </div>
      <!--</ng-template>-->
    </div>
  </div>
</div>