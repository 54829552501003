import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  private configuration: MatSnackBarConfig<any> = {horizontalPosition: 'right', panelClass : ['update-snackbar', 'bg-primary', 'text-white']};

  constructor(
    private snackBar: MatSnackBar
  ) {}

  public async openSnackBar(component: any): Promise<void> {
    this.snackBar.openFromComponent(component, this.configuration);
  }

  public async dismiss(): Promise<void> {
    this.snackBar.dismiss();
   }

  public updateApplication(): void {
    window.location.reload();
  }
}
