import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, LOCATION_CONTACTS } from '../detail-forms.component';
import { LpContact } from 'app/meta/contact';

@Component({
  selector: 'lp-location-contacts',
  templateUrl: './locationContacts.component.html'
})
export class LocationContactsComponent extends DetailFormsComponent implements OnInit {

  public data: LpContact = null;

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    this.setId(LOCATION_CONTACTS);
    this.initUrl();
    // this.init();
  }

  public init(): void {
    this.data.assign(this.formStackService.currentDetailLineData);
  }
}
