<div *ngIf="image && displayValue && isImage">
  <lp-image-field [url]="displayValue" cssClass="file mb-3" [zoom]=true alt=""></lp-image-field>
</div>
<div *ngIf="displayValue">
  <a *ngIf="downloadable" class="{{cssDownloadButton}} btn btn-outline-primary mb-3 ps-4 pe-4 btn-lg" (click)="downloadFile()"><i class="fa {{iconTypeMime}} me-3"></i>{{'general.download' | translate}}</a>  
</div>
<label *ngIf="!disabled && !readonly" for="{{idInput}}" class="btn btn-outline-success btn-sm m-0 me-2">{{(displayValue ? 'general.editFile' : 'general.addFile') | translate}}</label>
<button *ngIf="!disabled && displayValue && !readonly" class="btn btn-outline-danger btn-sm" (click)="deleteFile()">{{'general.deleteFile' | translate}}</button>
  
<input *ngIf="!disabled && !readonly" type="file" (change)="pushChange($event)" id="{{idInput}}"  class="form-control-file" accept="{{accept}}" value="">
<input type="hidden" [(ngModel)]="displayValue" name="{{name | translate}}"/>