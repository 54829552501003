import { HttpErrorResponse } from '@angular/common/http';
import { Util } from 'app/statics/utils';
import { ConfigService } from 'app/services/config.service';
export class HttpError {
    public error: String;
    public message: String;
    public translateKey: String;
    public statusCode: number;
    public prop: String;
    public additionalInformation: String;

    constructor(e: HttpErrorResponse, configService: ConfigService) {
        if (!Util.isNullOrUndefined(e.error)) {
            if (e.url.indexOf(configService.get('repositoryServiceUrl')) >= 1 || e.url.indexOf(configService.get('alocproServiceUrl')) >= 1) {
                this.message = '';

                this.prop = '';
                if (e.error.translateKey === 'httpError.badRequest.MANDATORY_FIELD_IS_MISSING') {
                    const tabWord: String[] = e.error.message.split(' ');
                    if (tabWord.length >= 3) {
                        this.prop = tabWord[2];
                    }
                    this.translateKey = e.error.translateKey + '_WITH_PROP';
                } else {
                    if (Util.isNullOrUndefined(e.error.translateKey)) {
                        this.translateKey = '';
                    } else {
                        this.translateKey = e.error.translateKey
                    }
                }
                this.error = e.statusText;
                this.statusCode = e.status;
            } else {
                this.message = e.error.message;
                this.statusCode = e.error.status;
                this.error = e.error.error;
                this.translateKey = e.error.translateKey;
            }
        } else {
            this.message = e.statusText;
            this.statusCode = e.status;
            this.error = '';
            this.translateKey = '';
        }
    }

    public getErrorTitle(): string {
        return `${this.message} ${this.error}`;
    }
}
