<lp-ui-accordion class="row" [menu]=true>
  <div *ngFor="let s of formStackService.currentApplicationItemDetail.editForm.accordions; let i = index">
      <!--use of 'hidden' instead of '*ngIf' because we want fomStackService.currentFields to contain all fields, even hidden ones-->
      <lp-ui-accordion-group heading="{{s.title}}" [open]="s.open" key="{{'tab-'+i}}">
          <div *ngIf="s.rows">
              <div *ngFor="let r of s.rows; let j = index" class="row" [ngClass]="r.classes">
                  <div *ngFor="let f of r.fields; let j = index" [ngClass]="getRowSize(s, r)" [class.d-none]="f.type === 'hidden'">
                      <lp-fields [f]="f" [data]="formStackService.CurrentApplicationItemDetailRepositoryData" [ngClass]="getCssClass(s, r, subgroup)" [isDetail]="false" namePrefix="{{namePrefix}}"></lp-fields>
                  </div>
              </div>
          </div>
          <div *ngIf="s.subgroup"> 
              <div *ngFor="let subgroup of s.subgroup">
                  <lp-subgroup [bordertop]="true" label="{{subgroup.title}}">
                      <div *ngFor="let r of subgroup.rows; let j = index" class="row" [ngClass]="r.classes">
                          <div *ngFor="let f of r.fields; let j = index" [ngClass]="getRowSize(s, r, subgroup)">
                              <lp-fields [f]="f" [data]="formStackService.CurrentApplicationItemDetailRepositoryData" [ngClass]="getCssClass(s, r, subgroup)" [isDetail]="false" namePrefix="{{namePrefix}}"></lp-fields>
                          </div>
                      </div>
                  </lp-subgroup>
              </div>
          </div>

          <div *ngIf="s.dynamicRows && generalPictures"> 
            <div *ngFor="let picture of generalPictures let in = index">
              <div *ngFor="let dynamicRow of s.dynamicRows;">
                <hr *ngIf="in > 0"/>
                <div *ngFor="let r of dynamicRow.rows; let j = index" class="row" [ngClass]="r.classes">
                    <div *ngFor="let f of r.fields; let j = index" [ngClass]="getRowSize(s, r, dynamicRow)">
                        <lp-fields [f]="f" [data]="picture" [ngClass]="getCssClass(s, r, dynamicRow)" [isDetail]="false" namePrefix="{{namePrefix}}"></lp-fields>
                    </div>
                    <div [ngClass]="getRowSize(s, r, dynamicRow)">
                        <lp-label-field class="control-label" text=""></lp-label-field>
                        <lp-boolean-switch-box name="name" id="id" class="pt-2" [ngClass]="getCssClass(s, r, dynamicRow)" (click)="addOrDeleteGeneralPicture(picture)"
                        [ignoreChange]="true" [ngModel]="haveThisPicture(picture.id)" [label]="label"></lp-boolean-switch-box>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </lp-ui-accordion-group>
  </div>
  <div class="text-end buttons">
    <button class="valid-detailForm" (click)="reinit()"><i class="vega-reset"></i></button>
    <button class="valid-detailForm" (click)="save()"><i class="vega-diskette"></i></button>
  </div>
</lp-ui-accordion>
<lp-ui-accordion heading="Debugger Mon Composant">
    <div>
      <pre>{{ formStackService.CurrentApplicationItemDetailRepositoryData | json }}</pre>
    </div>
  </lp-ui-accordion>