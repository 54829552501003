import { LpMeta, propertyMeta } from '../meta';
import { FtMeta } from '../ft-meta';
import { Type } from 'class-transformer';

export class LpInspectionDamageCategory extends LpMeta {
    @propertyMeta('string')
    public wording:              String;
    @propertyMeta('boolean')
    public isActive:             Boolean;
    @Type(() => FtMeta)
    public possibleValues:       FtMeta[];
}
