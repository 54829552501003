import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ChangeService } from 'app/services/change.service';
import { EvalService, FOCUS_IN_EVENT, MAIN } from 'app/services/eval.service';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';
import { TranslateService } from '@ngx-translate/core';
import { FormStackService } from 'app/services/form-stack.service';
import { UiSyncService } from 'app/services/ui-sync.service';
import { FormatService } from 'app/services/format.service';
import { NumberComponent } from '../number/number.component';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { ApplicationItem } from 'app/models/application-item';
import { ConfigService } from 'app/services/config.service';
import { Util } from 'app/statics/utils';

@Component({
  selector: 'lp-currency',
  templateUrl: './currency.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyComponent),
      multi: true
    }
  ]
})
export class CurrencyComponent extends NumberComponent implements OnInit, OnDestroy {

  /**Le output CurrencyEmmitter va permettre l'exécution de la fonction qui lui est associée.
   * La fonction associée est déclarée dans le template où le composant est utilisé.
   */
  @Output() public currencyEmitter: EventEmitter<any> = new EventEmitter();

  /** Input qui permettra de passer la devise de l'utilisateur
   * A CONTINUER - IMPLEMENTATION NON MIS EN PLACE
  */
  @Input() public currencyFormat: string;


  @Input() public pattern: string;

  public iconClass: string = 'fa fa-eur';
  
  protected numberType: String = "currency";

  constructor(protected changeService: ChangeService, protected evalService: EvalService,
    protected formMetadataProvider: FormMetadataProvider, protected translateService: TranslateService,
    public formStackService: FormStackService, protected uiSyncService: UiSyncService, protected formatService: FormatService, protected metaFactoryService: MetaFactoryService,
    protected configService: ConfigService, protected changeDetectorRef: ChangeDetectorRef) {
    super(changeService, evalService, formMetadataProvider, translateService, formStackService, uiSyncService, formatService, metaFactoryService, configService, changeDetectorRef);
  }

  /* public async pushChange(event?: any): Promise<void> {
    super.pushChange();
  } */

  public async focus(event: any): Promise<void> {
    let applicationItem: ApplicationItem = this.formStackService.currentApplicationItem;
    let data: any = this.formStackService.currentData;
    await this.evalService.executeContrainte(FOCUS_IN_EVENT, MAIN
      , applicationItem.ecrId.toString(), this.name.toString()
      , data, this.formStackService.currentFields, this.previousValue);

    let d: string;
    if (!Util.isNullOrUndefined(this.displayValue)) {
      d = this.displayValue;
    } else {
      d = '';
    }

    if (this.formatService.DECIMAL_SEPARATOR.has(this.translateService.getDefaultLang().toUpperCase())) {
      this.valueShowed = d.toString().replace('.', ',');
    } else {
      this.valueShowed = d;
    }
    this.setPreviousValue(d);
  }

  public keyDown(event: any, id: string): boolean {
    super.keyDown(event, id);
    return true;
  }

  public onKeypressEvent(event: any, id: string): void {
    super.onKeypressEvent(event, id);
  }
}
