<lp-label-field *ngIf="label" for="{{id}}" class="control-label" [hidden]="this.formStackService.getFields(name).hidden"
[ignoreChange]="ignoreChange" text="{{translationPrefix}}{{label}}"  [required]="this.formStackService.getFields(name).mandatory"></lp-label-field>
<span [class.ng-touched]="isTouched" [class.readonly]="readonly" [class.disabled]="disabled" [hidden]="this.formStackService.getFields(name).hidden">
    <div class="input-group">
      <input [owlDateTime]="dt1" class="textField form-control" (click)="isTouched=!isTouched" placeholder="{{placeholder | translate}}" (keydown)="keyDown($event, id)" [ngStyle]="style"
        [(ngModel)]="displayValue" (focus)="focus($event)" (focusout)="pushChange($event)"  [disabled]="this.formStackService.getFields(name).readonly" [required]="this.formStackService.getFields(name).mandatory && !this.formStackService.getFields(name).hidden" [readonly]="readonly" title="">
      <i class="input-notif"></i>
      <span *ngIf="!formStackService ||
        !formStackService.currentApplicationItemDetail || 
        !formStackService.currentApplicationItemDetail.focusOutAction" class="input-group-btn">
        <button type="button" tabindex="-1" class="btn waves-effect waves-light btn-primary btn-calendar" (click)="isTouched=!isTouched" [owlDateTimeTrigger]="dt1">
          <i class="icon vega-openingHours"></i>
        </button>
      </span>
    </div>
    <owl-date-time #dt1 [pickerType]="'calendar'" backdropClass="calendarBackdropClass" [pickerMode]="'dialog'" [firstDayOfWeek]=1 (afterPickerOpen)="focusPicker($event)" (afterPickerClosed)="pushChange($event)"></owl-date-time>
  </span>
  <div *ngIf="validDate==false" class="alert alert-danger" required>
    <input style="width:0px;display:inline-block;border:none;background-color:#f2dede;color:#a94442;" required> Date non valide.
  </div>
