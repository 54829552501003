import { Injectable } from '@angular/core';
import { StackItem } from '../models/stackitem';
import { LpMeta } from 'app/meta/meta';
import { ApplicationItem } from 'app/models/application-item';
import { ApplicationItemProvider } from 'app/providers/application-item.provider';
import { ApplicationItemDetail } from 'app/models/application-item-detail';
import * as _ from 'underscore';
import { Util } from 'app/statics/utils';
import { FormMetadata } from 'app/models/form-metadata';
import { FormMetadataProvider } from 'app/providers/form-metadata.provider';

import { FtMeta } from 'app/meta/ft-meta';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { RouterService } from './router.service';
import { IhmStackService } from './ihm-stack.service';
// import { ChangeService } from './change.service';
// import { ExampleApplicationItemsProvider } from 'app/Forms/examples/provider/metadata/example.application-items.provider';
import { PricingService } from './pricing.service';
import { LpPricingDetail } from 'app/meta/pricing-detail';

import { MainEditForm } from 'app/models/main-detail-forms';
import { Accordion } from 'app/models/accordion';
import { EditRows } from 'app/models/edit-rows';
import { InputField } from 'app/models/input-field';
import { ApplicationItemAction } from 'app/models/application-item-actions';
import { JsdataService } from './jsdata.service';

import { MetaFactoryService } from './meta-factory.service';
import { ConfigService } from './config.service';
import { Dashboard } from 'app/models/dashboard';
import { LpVisualSettings } from 'app/meta/visual-settings';
import { UserService } from './user.service';

export const SCREEN_TEST: string = 'examples';

@Injectable({
    providedIn: 'root'
  })
export class FormStackService {
    public isStackLink: Boolean;
    public lastStackKey: String;
    public stack: Map<String, StackItem > = new Map();
    public currentData: LpMeta;
    public specificData: LpMeta;
    public currentDetailLineData: LpMeta;
    public currentApplicationItemDetail: ApplicationItemDetail;
    public currentApplicationItem: ApplicationItem;
    public specificApplicationItem: ApplicationItem;
    public isCurrentDataPreloaded: Boolean = false;
    public isLoadingEvalActions: Boolean = false;
    public ignoreDataLoading: Boolean = false;
    public storedProperties: Map<string, any> = new Map();
    public currentFields: Map<String, FormMetadata> = new Map();
    public specificFields: Map<String, FormMetadata> = new Map();
    public currentDetailsCount: Map<String, Number> = new Map();
    public dashboard: Dashboard;
    public mainZoomBody: Boolean = false;
    private isPlanningPrevious: boolean;
    private currentApplicationItemDetailData: Array<LpMeta>;
    private currentApplicationItemDetailDataCount: Number;
    private currentApplicationItemDetailDataReadonly: Boolean;
    private currentApplicationItemDetailRepositoryData: any;
    private currentVerb: string;
    private currentKy: string;
    private currentMetadata: Map<string, FormMetadata> = new Map();
    private isKyForm: boolean
    private isNewForm: boolean
    private isMainZoom: boolean
    private visualSettings: LpVisualSettings;
    // utiliser pour prendre des données du planning au CCD ou mvt

    private bindingProp: String;

    constructor(private applicationItemProvider: ApplicationItemProvider,
                private ihmService: IhmStackService,
                private formMetadataProvider: FormMetadataProvider,
                private alocproProvider: AlocproProvider,
                private routerService: RouterService,
                private pricingService: PricingService,
                // private changeService: ChangeService,
                // private exampleApplicationItemProvider: ExampleApplicationItemsProvider
                private jsdataService: JsdataService,
                private lpFactory: MetaFactoryService,
                private configService: ConfigService,
                private userService: UserService
                ) {}

    public get VisualSettings(): LpVisualSettings {
        return this.visualSettings;
    }

    public set VisualSettings(visualSettings: LpVisualSettings) {
        this.visualSettings = visualSettings;
    }
    
    public get Dashboard(): Dashboard {
        return this.dashboard;
    }

    public set Dashboard(dashboard: Dashboard) {
        this.dashboard = dashboard;
    }
            
    public get IsKyForm(): boolean {
        return this.isKyForm;
    }

    public set IsKyForm(v: boolean) {
        this.isKyForm = v;
        this.isNewForm = false;
        this.isMainZoom = false;
    }

    public get IsNewForm(): boolean {
        return this.isNewForm;
    }

    public set IsNewForm(v: boolean) {
        this.isKyForm = false;
        this.isNewForm = v;
        this.isMainZoom = false;
    }

    public get IsMainZoom(): boolean {
        return this.isMainZoom;
    }

    public set IsMainZoom(v: boolean) {
        this.isKyForm = false;
        this.isNewForm = false;
        this.isMainZoom = v;
    }

    public get CurrentVerb(): string {
        return this.currentVerb;
    }

    public set CurrentVerb(verb: string) {
        this.currentVerb = verb;
    }

    public get CurrentKy(): string {
        return this.currentKy;
    }

    public set CurrentKy(ky: string) {
        this.currentKy = ky;
    }

    public get BindingProp(): String {
        return this.bindingProp;
    }

    public set BindingProp(bindingProp: String) {
        this.bindingProp = bindingProp;
    }

    public get IsPlanningPrevious(): boolean {
        return this.isPlanningPrevious;
    }

    public set IsPlanningPrevious(b: boolean) {
        this.isPlanningPrevious = b;
    }

    public get CurrentMetadata(): Map<string, FormMetadata> {
        return this.currentMetadata
    }

    public set CurrentMetadata(metadata: Map<string, FormMetadata>) {
        this.currentMetadata = metadata;
    }

    public async getFormStackServiceCurrentData(): Promise<LpMeta> {
        try {
            return this.currentData;
        } catch (error) {
            throw error;
        }
    }

    /*public get CurrentApplicationItem(): ApplicationItem {
        return this.currentApplicationItem;
    }

    public set CurrentApplicationItem(value: ApplicationItem) {
        this.currentApplicationItem = value;
    }*/

    public get CurrentApplicationItemDetailData(): Array<LpMeta> {
        return this.currentApplicationItemDetailData;
    }

    public set CurrentApplicationItemDetailData(value: Array<LpMeta>) {
        this.currentApplicationItemDetailData = value;
    }

    public get CurrentApplicationItemDetailRepositoryData(): any {
        return this.currentApplicationItemDetailRepositoryData;
    }

    public set CurrentApplicationItemDetailRepositoryData(value: any) {
        this.currentApplicationItemDetailRepositoryData = value;
    }

    public get CurrentApplicationItemDetailDataCount(): Number {
        return this.currentApplicationItemDetailDataCount;
    }

    public set CurrentApplicationItemDetailDataCount(value: Number) {
        this.currentApplicationItemDetailDataCount = value;
    }

    public get CurrentApplicationItemDetailDataReadonly(): Boolean {
        return this.currentApplicationItemDetailDataReadonly;
    }

    public set CurrentApplicationItemDetailDataReadonly(value: Boolean) {
        this.currentApplicationItemDetailDataReadonly = value;
    }

   /*public get CurrentApplicationItemDetailTitle(): string {
        return this.currentApplicationItemDetailTitle;
    }

    public set CurrentApplicationItemDetailTitle(value: string) {
        this.currentApplicationItemDetailTitle = value;
    }

    public get CurrentApplicationItemDetailId(): number {
        return this.currentApplicationItemDetailId;
    }

    public set CurrentApplicationItemDetailId(id: number) {
        this.currentApplicationItemDetailId = id;
    }
    */
    public get CurrentApplicationItemDetail(): ApplicationItemDetail {
        return this.currentApplicationItemDetail;
    }

    public set CurrentApplicationItemDetail(detail: ApplicationItemDetail) {
        this.currentApplicationItemDetail = detail;
    }

    public setCurrentActionData(data: LpMeta) {
        this.currentData._currentActionData = data;
    }

    public clearCurrentActionData() {
        delete this.currentData._currentActionData;
    }

    public initLpMeta(verb: string): void {
        if (!this.isCurrentDataPreloaded) {
            this.currentData = this.lpFactory.getRessource(verb);
        }
    }

    public initLpLine(key: string): void {
        /*if(this.currentDetailLineData instanceof LpMeta){
            this.currentDetailLineData.renew();
        } else {*/
        this.currentDetailLineData = this.lpFactory.getRessource(key);
    }

    public hasIdPath(): boolean {
        let pattern: RegExp = /\{\{.*\}\}/;
        return this.currentApplicationItemDetail.path.match(pattern).length >= 1;
    }

    public initFromHistoryId(stackIdToLoad: number, previouslySaved?: boolean): void {
        let stack: StackItem;
        if (Util.isNullOrUndefined(stackIdToLoad)) {
          stack = this.getLastStack();
        } else {
          stack = this.stack.get(Array.from(this.stack)[stackIdToLoad][0]);
        }
        this.CurrentVerb = stack.currentVerb;
        this.CurrentKy = stack.currentKy;
        this.currentApplicationItem = stack.currentApplicationItem;
        this.CurrentApplicationItemDetailData = stack.currentApplicationItemDetailData;
        this.CurrentApplicationItemDetailDataCount = stack.currentApplicationItemDetailDataCount;
        this.currentApplicationItemDetail = stack.currentApplicationItemDetail;
        this.CurrentMetadata = stack.currentMetadata;
        this.bindingProp = stack.bindingProp;
        if (!Util.isNullOrUndefined(stack.bindingProp) && previouslySaved) {
          stack.currentData[stack.bindingProp.toString()] = this.currentData;
        }
        this.currentData = stack.currentData;
        this.isCurrentDataPreloaded = true;
    }

    public async addStackIem(nextData: LpMeta, bindingProp: String, verb: string, ky: string, isCurrentDataPreloaded?: boolean
        , usePreviousAPIConfig?: boolean)
        : Promise<void> {
        return new Promise<void>((resolve) => {
            if (!Util.isNullOrUndefined(isCurrentDataPreloaded)) {
                this.isCurrentDataPreloaded = isCurrentDataPreloaded;
            }
            // pour éviter une reference dans la stack 
            let newData: LpMeta = new LpMeta().assign(this.currentData);
            let stackItem: StackItem = new StackItem(this.currentVerb, this.CurrentKy, this.currentApplicationItem,
                this.CurrentApplicationItemDetailData, this.CurrentApplicationItemDetailDataCount, this.currentApplicationItemDetail,
                this.CurrentMetadata, newData, nextData, bindingProp, this.currentDetailLineData, usePreviousAPIConfig);
            this.stack.set(this.currentVerb, stackItem);
            this.routerService.navigate(verb, ky);
            resolve();
        });
    }

    public async currentDataAssign(verb: string, ky: string): Promise<void> {
        if (Util.isNullOrUndefined(this.isCurrentDataPreloaded) || !this.isCurrentDataPreloaded) {
            this.currentData.assign(await this.alocproProvider.find(verb, ky));
        } else {
            this.isCurrentDataPreloaded = false;
            this.currentData = new LpMeta();
            this.currentData.assign(this.getLastStack().nextData);
        }
        return Promise.resolve();
    }

    public async deleteMultipleItems(item: string): Promise<void> {
        if (item !== null || item !== undefined) {
            let done: boolean = false;
            this.stack.forEach((element: StackItem, key: String) => {
                if ( !done ) {
                    if ( key === item ) {
                        this.stack.delete(key);
                        done = true;
                    }
                } else if ( done ) {
                    this.stack.delete(key);
                }
            });
        }
    }

    /*public getApplicationItem(verb?: string): Promise<ApplicationItem> {
        return new Promise<ApplicationItem>((resolve) => {
            // TODO on doit toujours passer ici !!!
            if (verb !== this.currentApplicationItem.verb ) {
                this.initApplicationItem(verb).then(() => {
                    resolve(this.currentApplicationItem);
                });
            } else {
                resolve(this.currentApplicationItem);
            }
        });
    }*/

    public async getApplicationItem(currentVerb?: string): Promise<ApplicationItem> {
        if (Util.isNullOrUndefined(this.currentApplicationItem) || this.currentApplicationItem.verb !== currentVerb) {
            this.currentApplicationItem = await this.initApplicationItem(this.currentVerb);
        }
        return this.currentApplicationItem;
    }

    public async getAllMetadata(verb: string, ky: string, loadDefaultBackendMetadataValue: boolean): Promise<void> {
        this.currentMetadata.clear();
        await this.getApiMetadata(verb, loadDefaultBackendMetadataValue);
        // await _.each(this.currentApplicationItem.details, async (d: ApplicationItemDetail) => {
        if( !Util.isNullOrUndefined(this.currentApplicationItem.details)){
            for (let i: number = 0 ; i < this.currentApplicationItem.details.length; i++) {
                if (this.currentApplicationItem.details[i].enable) {
                    await this.getDetailsApiMetadata(verb, ky, this.currentApplicationItem.details[i].path.split('/')[2]);
                }
            }
        }
        return Promise.resolve();
    }

    public async getApiMetadata(verb: string, loadDefaultBackendMetadataValue: boolean): Promise<void> {
        try {
            let data: Array<FormMetadata> = await this.formMetadataProvider.getApiMetadata(verb)
            let finalData: Array<FormMetadata> = []
            data.forEach(val => finalData.push(Object.assign({​​​​​}​​​​​, val)));
            if (finalData && finalData.length !== 0) {
                for(let element of finalData){
                    if (!Util.isNullOrUndefined(element)) {
                        if (!loadDefaultBackendMetadataValue && !Util.isNullOrUndefined(element.defaultValue)) {
                            delete element.defaultValue;
                        }
                        this.currentMetadata.set(element.name, element);
                        if(!Util.isNullOrUndefined(element.defaultValue) && (this.isNull(this.currentData[element.name]) || typeof this.currentData[element.name] === "boolean")){
                            if (this.currentData[element.name] instanceof LpMeta) {
                                this.currentData[element.name].id = element.defaultValue;
                            } else {
                                this.currentData[element.name] = element.defaultValue;
                            }
                        }
                    }
                }
            }
            return;
        } catch (e) {
            console.error(e);
            //throw e;
        }
    }

    public getDetailsApiMetadata(verb: string, ky: string, details: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.formMetadataProvider.getDetailMetadata(verb, ky, details).then((data: Array<FormMetadata>) => {
                if (data && data.length !== 0) {
                    data.forEach((element: FormMetadata) => {
                        if (!Util.isNullOrUndefined(element)) {
                            this.currentMetadata.set(`${details}.${element.name}`, element);
                        }
                    });
                }
                resolve();
            }, (error) => {
                resolve();
            });
        });
    }

    public getRootFormDetail(key: Number): ApplicationItemDetail {
        let tempItem: ApplicationItemDetail;
        _.each(this.currentApplicationItem.details, (d: ApplicationItemDetail) => {
            if (d.id === key) {
                tempItem = d;
            }
        })
        return tempItem;
    }

    public getApplicationItemDetail(key: number): ApplicationItemDetail {
        let tempItem: ApplicationItemDetail = null;
        if (!Util.isNullOrUndefined(this.currentApplicationItem)) {
            _.each(this.currentApplicationItem.details, (d: ApplicationItemDetail) => {
                if (d.id === key) {
                    tempItem = d;
                }
            });
        }
        return tempItem;
    }

    public async initApplicationItem(verb: string): Promise<ApplicationItem> {
        /*if ( verb === SCREEN_TEST) {
            let temp: ApplicationItem = await this.exampleApplicationItemProvider.getAppicationItem()
            this.currentApplicationItem = temp;
            this.currentApplicationItemDetail = new ApplicationItemDetail();
            Object.assign(this.currentApplicationItemDetail, temp.details[0])
            return Promise.resolve(temp);
        } else {*/
            let tempApplicationItem: ApplicationItem = await this.applicationItemProvider.getObjectDetails(verb);
            this.currentApplicationItem = tempApplicationItem;
            if (!Util.isNullOrUndefined(tempApplicationItem.details) && tempApplicationItem.details.length > 0) {
                this.currentApplicationItemDetail = new ApplicationItemDetail();
                Object.assign(this.currentApplicationItemDetail, this.getActiveItem())
            } else {
                this.currentApplicationItemDetail = undefined;
            }
            return Promise.resolve(tempApplicationItem);
          // }
    }

    public async initVisualSettings(): Promise<void> {
        this.VisualSettings = this.userService.getVisualSettings()
    }


    public pushFields(key: String, value: FormMetadata): void {
        this.currentFields.set(key, value);
    }

    public getFields(key: String): FormMetadata {
        return this.currentFields.get(key);
    }

    public clearFields(): void {
        this.currentFields.clear();
    }

    public addStack(key: String, value: StackItem): void {
        this.lastStackKey = key;
        this.stack.set(key, value);
    }

    public removeStack(key: String): void {
        this.stack.delete(key);
    }

    public removeManyLinesStack(StartKey: String): void {
        let deleteH: boolean = false;
        this.stack.forEach((value: Object, key: String) => {
            if (key === StartKey) {
                deleteH = true;
            }
            if (deleteH) {
                this.removeStack(key);
            } else {
                this.lastStackKey = key;
            }
          });

    }

    public getStack(key: String): StackItem {
        return this.stack.get(key);
    }

    public clearStack(): void {
        this.lastStackKey = null;
        this.stack.clear();
    }

    public getAllStack(): Map<String, StackItem> {
        return this.stack;
    }

    public getLastStack(): StackItem {
        const temp: Array<any> = Array.from(this.stack)[this.stack.size - 1];
        if (temp) {
            return temp[1];
        }
        return undefined;
    }

    public async clearLastStack(): Promise<void> {
        if (this.stackExist()) {
            this.stack.delete(Array.from(this.stack)[this.stack.size - 1][0])
        }
    }

    public isPreviousStackPlanning(): boolean {
        if (this.stackExist()) {
           return Array.from(this.stack)[this.stack.size - 1][1].currentVerb === 'planning'
        } else {
            return false;
        }
    }

    public isPreviousStackQuickQuotes(): boolean {
        if (this.stackExist()) {
            return Array.from(this.stack)[this.stack.size - 1][1].currentVerb === 'quick-quotes'
         } else {
             return false;
         }
    }

    public ApplyExtraData(dataToAdd: LpMeta): void {
        Object.keys(dataToAdd).forEach((key) => {
            this.currentData[key] = dataToAdd[key];
        });
    }

    public isDataReadOnly(): Boolean {
        return this.currentData && this.currentData._readOnly;
    }

    public isApplicationItemDataReadOnly(): Boolean {
        return (this.currentApplicationItemDetailDataReadonly || (this.currentData && this.currentData._readOnly));
    }


    public stackExist(): Boolean {
        return this.stack.size > 0;
    }

    public async defineEnableDetail(): Promise<void> {
        return new Promise<void>(async (resolve) => {
            for (let i: number = 0; i < this.currentApplicationItem.details.length; i++) {
                this.currentApplicationItem.details[i].enable = await this.testIfDetailExists(this.currentApplicationItem.details[i]);
            }
            this.ihmService.ShowTopBanner = true;
            resolve();
        });
    }

    public async defineEnableDetailToFalse(): Promise<void> {
        return new Promise<void>(async (resolve) => {
            for (let i: number = 0; i < this.currentApplicationItem.details.length; i++) {
                this.currentApplicationItem.details[i].enable = false;
            }
            this.ihmService.ShowTopBanner = true;
            resolve();
        });
    }

    public buildDetailUrl(path: String, data?: LpMeta): String {
        let d: any = Util.isNullOrUndefined(data) ? {data: this.currentData} : {data: data};
        let pattern: RegExp = /\{\{(.*)\}\}/;
        let matches: Array<string> = path.match(pattern);
        if (!Util.isNullOrUndefined(matches) && !Util.isNullOrUndefined(matches[1])) {
            d = this.jsdataService.getDataRef(d, matches[1])
            let finalUrl: string = null;
            if (!Util.isNullOrUndefined(d)) {
                finalUrl = path.replace(pattern, d);
            } else {
                finalUrl = path.toString();
            }
            return finalUrl;
        } else {
            return path.toString()
        }
    }

    public isDeletable(): boolean {
        if (!Util.isNullOrUndefined(this.currentApplicationItem.delete) && !this.currentApplicationItem.delete) {
            return false;
        } else {
            return !this.isDataReadOnly() && this.currentKy !== undefined && this.CurrentKy !== null
                        && !this.isNew();
        }
    }

    public isNew(): boolean {
        return this.currentKy === this.configService.get('newdataKeyWord');
    }

    public async changeActivate(active: boolean): Promise<void> {
        if (!active) {
            await this.alocproProvider.putActivate(this.CurrentVerb, this.CurrentKy);
        } else {
            await this.alocproProvider.putDeactivate(this.CurrentVerb, this.CurrentKy);
        }
    }

    public storeProperties(detailData: LpMeta, data: LpMeta): void {
        this.storedProperties.clear();
        Object.keys(detailData).forEach((key: string) => {
            this.storedProperties.set(key, detailData[key]);
        });
        Object.keys(data).forEach((key: string) => {
            if (!this.storedProperties.has(key)) {
                this.storedProperties.set(key, data[key]);
            }
        });
    }

    public hydrateFromStoredProperties(inputData: LpMeta): LpMeta {
        if (!Util.isNullOrUndefined(this.storedProperties) && this.storedProperties.size > 0) {
            this.storedProperties.forEach((value: any, key: string) => {
                Object.keys(inputData).forEach((LpMetaKey: string) => {
                    if (key === LpMetaKey) {
                        inputData[LpMetaKey] = value
                    }
                });
            });
            this.storedProperties.clear();
            return inputData;
        } else {
            return this.currentData;
        }
    }

    public async getPricingList(filters: LpMeta): Promise<LpPricingDetail[]> {
        try {
            return await this.pricingService.getPricingList(filters);
        } catch (e) {
            console.error (e);
        }
    }

    public editFormToAction(editForm: MainEditForm, title: String): ApplicationItemAction {
      let action: ApplicationItemAction = {
        _show: false,
        id: 'duplicate',
        classes: '',
        icon: '',
        title: title,
        method: 'DUPLICATE'
      };
      action.fields = [];
      for (let i: number = 0; i < editForm.accordions.length; i++) {
        const accordion: Accordion = editForm.accordions[i];
        for (let j: number = 0; j < accordion.rows.length; j++) {
          const row: EditRows = accordion.rows[j];
          for (let k: number = 0; k < row.fields.length; k++) {
            let field: InputField =  Object.assign({}, row.fields[k]);
            //field.defaultValue = this.currentData[field.prop.toString()];
            field.id = 'id' + field.prop;
            action.fields.push(field);
          }
        }
      }
      return action;
    }

    public hydrateMapFromArrayOfKey(d: Array<string>): Map<string, string> {
        let searched: Map<string, string> = new Map();
        for (let key of Object.keys(this.currentData)) {
          if (key !== '_readOnly' && key !== 'details' && key !== 'id' && key !== '_xVegaWorkflowsCount' && key !== '_xVegaAttachmentsCount' && key !== '_xVegaEdisCount') {
            if(this.currentData[key] !== null){
              if (this.currentData[key] instanceof LpMeta || this.currentData[key] instanceof Object ) {
                if(this.currentData[key]['id'] !== null){
                  searched.set(key, this.currentData[key]['id']);
                }
              } else {
                searched.set(key, this.currentData[key]);
              }
            }
          }
        }
        return searched;
    }   
  
    public useSpecificApplicationItem(key: String) {
        return !Util.isNullOrUndefined(this.specificApplicationItem) 
        && key.indexOf('.') > 0
        && key.substring(0, key.indexOf('.')) === this.specificApplicationItem.verb;
    }

    public clear(): void {
        this.currentVerb = null;
        this.currentApplicationItem = null;
        this.currentApplicationItemDetail = null;
    }
    /*
    private DefineCurrentApplicationItemDetail(applicationItem: ApplicationItem): ApplicationItemDetail {
        let id: number = _.findIndex(applicationItem.details, (i) => {
            return i.id === this.currentApplicationItemDetail.id;
        });
        return (id === -1 ? applicationItem.details[0] : applicationItem.details[id]);
    }
    */
/*
    private getValueFromInterpolation(detailPath: String, pattern: RegExp, data: any): String {
        let kyToReplace: string = detailPath.match(pattern)[1];
        if (kyToReplace.indexOf('.') > 0) {
            for (let i: number = 0; i < kyToReplace.split('.').length; i++) {
                if (!Util.isNullOrUndefined(data)) {
                    data = data[kyToReplace.split('.')[i]];
                }
            }
        } else {
            // return data.data[kyToReplace].toString();
            data = data.data[kyToReplace];
        }
        if (!Util.isNullOrUndefined(data)) {
            return data.toString();
        } else {
            return null;
        }
    }*/

    private isNull(object: any): boolean {
        if (object instanceof LpMeta || object instanceof FtMeta) {
            return  Util.isNullOrUndefined(object.id);
        } else {
            return  Util.isNullOrUndefined(object);
        }
    }

    private async testIfDetailExists(detail: ApplicationItemDetail): Promise<Boolean> {
        return new Promise<Boolean>(async (resolve, reject) => {
            let url: String = this.buildDetailUrl(detail.path);
            if (Util.isNullOrUndefined(url)) {
                resolve(false);
            } else {
                try {
                    await this.alocproProvider.getPaginatedData(url);
                    resolve(true);
                } catch (e) {
                    reject(false);
                }
            }
        });
    }
    
    private getActiveItem(): ApplicationItemDetail {
        let detail: ApplicationItemDetail =  this.currentApplicationItem.details[0];
        let hasActiveDetail: boolean = false;
        for(let d of this.currentApplicationItem.details){
            if(d.active && !hasActiveDetail){
                detail = d;
                hasActiveDetail = true;
            } else {
                d.active = false;    
            }
        }
        if(!hasActiveDetail){
            this.currentApplicationItem.details[0].active = true;
        }
        return detail;
    }
}
