import { Type } from 'class-transformer';
import { LpMeta } from './meta';
import { LpReportingModules } from './reporting-modules';

export class LpReports extends LpMeta {
    public id: String = null;
    public details: String = null;
    @Type(() => LpReportingModules)
    public reportingModule: LpReportingModules = new LpReportingModules();
}
