import { Injectable } from '@angular/core';
import { FormStackService } from './form-stack.service';

@Injectable({
  providedIn: 'root'
})
export class ChangeService {

  private pendingUrl: String = '';
  private ChangesStack: Map<number, Set<String>> = new Map();
  private specificChangesStack: Map<String, Set<String>> = new Map();
  // private detailsChanges: Set<String> = new Set();
  private detailsChanges2: Map<number, Set<String>> = new Map();

  constructor(private formStackService: FormStackService) { }

  public getPendingUrl(): String {
    return this.pendingUrl;
  }

  public initChange(): void {
    if (!this.ChangesStack.has(this.formStackService.stack.size)) {
      this.ChangesStack.set(this.formStackService.stack.size, new Set());
    }
  }

  public initSpecificChange(key: String): void {
    this.specificChangesStack.set(key, new Set());
  }

  public getHasChanged(id?: number): Boolean {
    if (id === null || id === undefined) {
      return false;
    }
    if (this.ChangesStack.get(id) !== null && this.ChangesStack.get(id) !== undefined) {
      return (this.ChangesStack.get(id).size > 0);
    } else {
      return false
    }
  }

  /*public isDetailsChanged(): Boolean {
    return (this.detailsChanges.size > 0);
  }*/

  public areDetailsChanged2(): Boolean {
    return (this.detailsChanges2.size > 0);
  }

  public isDetailsChanged2(): Boolean {
    let isChanged: boolean = false;
    if (this.detailsChanges2.get(this.formStackService.stack.size) !== null && this.detailsChanges2.get(this.formStackService.stack.size) !== undefined) {
      isChanged = true;
    }
    return isChanged;
  }

  public isSpecificChanged(key: String): Boolean { 
    return (this.specificChangesStack.has(key) && this.specificChangesStack.get(key).size > 0);
  }

  public removeIndexes(id: number): void {
    for (let i: number = (this.ChangesStack.size - 1); i > id; i--) {
      this.ChangesStack.delete(i);
    }
  }

  public checkIfpreviousChanged(id: number): Boolean {
    for (let i: number = (this.ChangesStack.size - 1); i > id; i--) {
      if (this.ChangesStack.get(i).size > 0) {
        return true;
      }
    }
    return false || this.isDetailsChanged2();
  }

  public clearCurrentChanges(): void {
    this.ChangesStack.delete(this.formStackService.stack.size);
  }

  public clearCurrentSpecificChanges(key: String): void {
    this.specificChangesStack.delete(key);
  }

  public push(name: String): void {
    if(!this.formStackService.currentData._readOnly){
      if (name.indexOf('.') > 0) {
        let key: String = name.substring(0, name.indexOf('.'))
        if (this.specificChangesStack.has(key)) {
          this.specificChangesStack.get(key).add(name);
        } else if (this.formStackService && this.formStackService.currentData && this.formStackService.currentData.isSubObject(key)) {
          this.ChangesStack.get(this.formStackService.stack.size).add(name);
        } else {
          this.detailsChanges2.set(this.formStackService.stack.size, new Set(name));
          // this.detailsChanges.add(name);
        }
      } else {
        this.ChangesStack.get(this.formStackService.stack.size).add(name);
      }
    }
  }

  public formSubmited(): void {
    if (this.ChangesStack.size === 0) {
      return null;
    }
    // let lastStackModified: Set<String> = this.getLast()
    this.deleteLast();
    /* if (this.ChangesStack.size > 0) {
      this.updateLast(lastStackModified);
    } */
  }

  public getIsLastChanged(): boolean {
    if (this.ChangesStack.size > 0) {
      let lastkey: number = Array.from(this.ChangesStack)[this.ChangesStack.size - 1][0];
      return this.ChangesStack.get(lastkey).size > 0;
    } else {
      return false;
    }

  }

  public clearAll(): void {
    this.clearAllDetailsChanged2();
    // this.clearDetailsChanged();
    this.ChangesStack.clear();
  }

  /*public clearDetailsChanged(): void {
    this.detailsChanges.clear();
  }*/

  public clearAllDetailsChanged2(): void {
    this.detailsChanges2.clear();
  }

  public clearDetailsChanged2(): void {
    this.detailsChanges2.delete(this.formStackService.stack.size);
  }

  public clearAndReinit(): void {
    this.clearCurrentChanges();
    this.initChange();
  }


  private deleteLast(): void {
    if (this.ChangesStack && this.ChangesStack.size > 0) {
      let lastkey: number = Array.from(this.ChangesStack)[this.ChangesStack.size - 1][0];
      this.ChangesStack.delete(lastkey)
    }
  }

  /*
  private updateLast(val: Set<String>): void {
    let lastkey: number = Array.from(this.ChangesStack)[this.ChangesStack.size - 1][0];
    this.ChangesStack.set(lastkey, val);
  }

  private getLast(): Set<String> {
    if (this.ChangesStack.size > 0) {
      let lastkey: number = Array.from(this.ChangesStack)[this.ChangesStack.size - 1][0];
      return this.ChangesStack.get(lastkey);
    } else {
      return new Set();
    }

  }
  */

  /*
  private setPendingUrl(url: String): void {
    this.pendingUrl = url;
  }
  */

}
