<!-- passer en input tout les champs renvoyer par l'appel /parameters -->

<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" titleTranslationKey="pricingTimes.pricingTimes"></lp-top-banner>
<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">
  <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''"
    (dblclick)="reInitCols()"></div>
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>
  <div class="container p-0">
    <div class="container-2-cols left" #containerCols>
      <div class="card-box col-md-6 label-2-colonnes" #col1>
        <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
          <lp-common-tools *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id"
            [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="deleteButton"
            (showPrintOutput)="changeShowZoomPrint($event)" [showPrintInput]="showZoomPrint"></lp-common-tools>
            <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
            <lp-ui-accordion class="row" [menu]=true>

            <lp-ui-accordion-group heading="general.other.general" [open]=true key="general">
              <div class="container">
                <div class="row mb-4">
                  <div class="form-group col-xl-6">
                  <lp-date-time [(ngModel)]="data.startDate" class="{{arrayClassesElements.get('defaultInput')}}" id="startDate"
                      cssClass="form-control" name="startDate" label="pricingTimes.startDate"></lp-date-time>
                  </div>
                  <div class="form-group col-xl-6">
                    <lp-date-time [(ngModel)]="data.endDate"  class="{{arrayClassesElements.get('defaultInput')}}"
                      id="endDate" cssClass="form-control" name="endDate" label="pricingTimes.endDate"></lp-date-time>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="form-group col-xl-6">
                    <lp-zoom-field id="pricing" class="{{arrayClassesElements.get('defaultInput')}}" zoomId='pricing' className="zoomField"  
                    cssClass="form-control" [(ngModel)]="data.pricing" name="pricing" label="pricingTimes.pricing">
                    </lp-zoom-field>
                  </div>
                  <div class="form-group col-xl-6">
                    <lp-text-field id="wording" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                      cssClass="form-control" name="wording" label="pricingTimes.wording"
                      [(ngModel)]="data.wording" type="text"></lp-text-field>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="form-group col-xl-6">
                      <lp-boolean-switch-box name="priority" switchName="priority" label="pricingTimes.priority" id="priority" [(ngModel)]="data.priority"></lp-boolean-switch-box>
                  </div>
                  <div class="form-group col-xl-6">
                      <lp-boolean-switch-box name="isActive" switchName="isActive" id="isActive" label="pricingTimes.isActive" [(ngModel)]="data.isActive"></lp-boolean-switch-box>
                  </div>
                </div>
              </div>
            </lp-ui-accordion-group>

            <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
              <div>
                <pre>{{ data | json }}</pre>
              </div>
            </lp-ui-accordion-group>
          </lp-ui-accordion>
        </div>
          <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
        </form>
      </div>
      <div class="col-md-6 card-box" #col2>
        <lp-right-col></lp-right-col>
      </div>
    </div>
  </div>

  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>