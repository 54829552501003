import { Component, Input, OnInit} from '@angular/core';
import { FormStackService } from 'app/services/form-stack.service';
import { RepositoryProvider } from 'app/providers/repository.provider';
import { Preference } from 'app/models/user.interface';
import { EditRows } from 'app/models/edit-rows';
import { Accordion } from 'app/models/accordion';
import { Util } from 'app/statics/utils';
import { SubGroups } from 'app/models/sub-groups';
import * as util from 'util' // has no default export
import { LpMeta } from 'app/meta/meta';
import { ApplicationItem } from 'app/models/application-item';
import { InputField, INPUT_FIELD_TYPE_LABEL } from 'app/models/input-field';
import * as _ from 'underscore';
import { EvalService } from 'app/services/eval.service';
import { UiSyncService } from 'app/services/ui-sync.service';
import { Subscription } from 'rxjs-compat';
@Component({
  selector: 'lp-dynamic-main-form',
  templateUrl: './dynamic-main-form.component.html',
  styleUrls: ['./dynamic-main-form.component.scss']
})
export class DynamicMainFormComponent implements OnInit {

    @Input() public data: LpMeta;
    @Input() public specificApplicationItem: Boolean = false;
    private subscriptionRefreshDynamicMainForm: Subscription;
    public applicationItem: ApplicationItem;
    public debugg: Boolean;
    public currentData: any;
    public dataInput: any;
    public namePrefix: string ="";

    constructor(
      public formStackService: FormStackService,
      private repositoryProvider: RepositoryProvider,
      private evalService: EvalService,
      private uiSyncService: UiSyncService) {
        this.repositoryProvider.getPreferences().then((preferences: Preference) => {
            if (preferences) {
              this.debugg = preferences.debug;
              this.currentData = util.inspect(this.formStackService.currentData, { showHidden: false, depth: 6 });
            }
        });
        this.subscription(); 
  }

  public ngOnInit(): void {
    if (Util.isNullOrUndefined(this.data)) {
      this.data  = this.formStackService.currentData;
    } else {
      this.dataInput = util.inspect(this.data, { showHidden: false, depth: 6 });
    }
    if (this.specificApplicationItem) {
      this.applicationItem = this.formStackService.specificApplicationItem;
      this.namePrefix = `${this.formStackService.specificApplicationItem.verb}.`;
    } else {
      this.applicationItem  = this.formStackService.currentApplicationItem;
    }
    if (this.formStackService.isNew()) {
      _.each(this.applicationItem.editForm.accordions, (async (a: Accordion)  => {
        _.each(a.rows, (async (r: EditRows)  => {
          _.each(r.fields, (async (f: InputField)  => {
            if (f.type !== INPUT_FIELD_TYPE_LABEL) {
              if (Util.isNullOrUndefined(this.data[f.prop.toString()]) || this.data[f.prop.toString()] === '') {
                if (!Util.isNullOrUndefined(f.defaultValue) && f.defaultValue !== '') {
                    this.data[f.prop.toString()] = f.defaultValue;
                }
                if (!Util.isNullOrUndefined(f.defaultValueEval) && f.defaultValueEval !== '') {
                  await this.evalService.eval('data.' + f.prop.toString() + '=' + f.defaultValueEval, this.data, f.prop.toString());
                }
              }
            }
          }));
        }));
      }));
    }
  }

  public isMultipleInputPerLine(fields: Array<EditRows>): boolean {
    return fields.length > 2;
  }

  public getRowSize(accordion: Accordion, row: EditRows, subgroup: SubGroups): string {
    if (!Util.isNullOrUndefined(row) && !Util.isNullOrUndefined(row.columns) && row.columns > 0) {
      return 'form-group col-xl-' + Math.ceil(12 / row.columns).toString();
    } else if (!Util.isNullOrUndefined(subgroup) && !Util.isNullOrUndefined(subgroup.columns) && subgroup.columns > 0) {
      return  'form-group col-xl-' + Math.ceil(12 / subgroup.columns).toString();
    } else if (!Util.isNullOrUndefined(accordion) && !Util.isNullOrUndefined(accordion.columns) && accordion.columns > 0) {
      return 'form-group col-xl-' + Math.ceil(12 / accordion.columns).toString();
    }
  }

  public getCssClass(accordion: Accordion, row: EditRows, subgroup: SubGroups): string {
    if (!Util.isNullOrUndefined(row) && !Util.isNullOrUndefined(row.columns) && row.columns === 1) {
      return 'col-lg-11 col-md-7';
    } else if (!Util.isNullOrUndefined(subgroup) && !Util.isNullOrUndefined(subgroup.columns) && subgroup.columns === 1) {
      return 'col-lg-11 col-md-7';
    } else if (!Util.isNullOrUndefined(accordion) && !Util.isNullOrUndefined(accordion.columns) && accordion.columns === 1) {
      return 'col-lg-11 col-md-7';
    } else {
      return 'col-lg-10 col-md-7';
    }
  }

  private subscription(): void {
    this.subscriptionRefreshDynamicMainForm = this.uiSyncService.refreshDynamicMainFormEvtEmitter.subscribe(() => {
      this.data = this.formStackService.currentData;
      this.currentData = this.formStackService.currentData;
      this.ngOnInit();
    });
  }

  public ngOnDestroy(): void {
    if (this.subscriptionRefreshDynamicMainForm) {
      this.subscriptionRefreshDynamicMainForm.unsubscribe();
    }
  }
}
