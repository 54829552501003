import {Pricing, SpecificPricingMethod} from '../../meta/pricings/pricings';
import {  ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ApplicationItem } from 'app/models/application-item';
import { ApplicationItemDetail, ApplicationItemDetailColumn, CellStyle} from 'app/models/application-item-detail';
import { TranslateService } from '@ngx-translate/core';
import { UiSyncService } from 'app/services/ui-sync.service';
import { ModalService } from 'app/services/modal.service';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { LpMeta } from 'app/meta/meta';
import { Util } from 'app/statics/utils';
import { HttpError } from 'app/models/http-error';
import { Subscription } from 'rxjs';
import { PaginatedData } from 'app/models/paginated-data';
import { FormStackService, SCREEN_TEST } from 'app/services/form-stack.service';
import * as _ from 'underscore';
import { ChangeService } from 'app/services/change.service';
import { RightColService } from 'app/services/root/rightCol.service';
import { UserService } from 'app/services/user.service';
import { LpModalChangeComponent } from 'app/ui/lp-modal/lp-modal-change/lp-modal-change.component';
import { LpModalConfirmComponent } from 'app/ui/lp-modal/lp-modal-confim/lp-modal-confirm.component';
import { FormService } from 'app/services/root/form.service';
import { FormatService } from 'app/services/format.service';
import { JsonCsv } from 'app/models/json-csv';
import { ProgressBarService } from 'app/services/progress-bar.service';
import { BulkService, EXECUTE } from 'app/services/bulk.service';
import { JsdataService } from 'app/services/jsdata.service';
import { FtParams } from 'app/meta/ft';
import { BEFORE_DELETE_EVENT, EvalService } from 'app/services/eval.service';
import { ConfigService } from 'app/services/config.service';
import { UserHistoryService } from 'app/services/user-history.service';
import { PricingCustomService } from 'app/services/pricing-custom.service';

const BULK_ACTION_DELETE: string = 'delete';
const BULK_ACTION_INSERT: string = 'insert';
const BULK_ACTION_UPDATE: string = 'update';
const BULK_ACTION: string = 'action';
const NO_LIMIT_FILTER: string = 'NOLIMIT';
const ON_DUPLICATE_ACTION: string = 'onDuplicate';
const NATIONAL: string = 'NATIONAL';

@Component({
  selector: 'lp-application-item-details',
  styleUrls: ['./application-item-details.component.scss'],
  templateUrl: './application-item-details.component.html',
})
export class ApplicationItemDetailsComponent implements OnDestroy, OnInit {

  @Input() public debugg: Boolean;
  public parentVerb: String = this.uiSyncService.parentVerb;
  public parentData: LpMeta = this.formStackService.currentData;

  // Propriétés relatives à l'objet applicationItem en cours.
  public applicationItem: ApplicationItem;
  public applicationItemKey: String
  public actionColumn: ApplicationItemDetailColumn;

  // Permet le rafraichissemnt d'une ligne lors d'une modification directement dans le tableau.
  public mapActiveRows: Map<String, Boolean> = new Map();
  public mapStyle: Map<String, String> = new Map();
  public mapComponentStyle: Map<String, String> = new Map();
  public currentPage: Number;
  /**
 * Permet d'afficher ou non le tableau.
 */
  public showTable: Boolean = false;

  public isLoadingID: String;

  public newdataKeyWord: string = this.configService.get('newdataKeyWord');
  public isLoading: boolean = false;
  public summaryEval: string;
  public summaryFromCurrentData: String[] = [];
  public showConfigPanel: boolean;
  public showImportBlock: boolean = false;

  // Permet de stocker l'ID de la ligne à supprimer pendant que la modal de confirmation est ouverte.
  private currentDeleteLine: LpMeta;

  private subscriptionRootformColChanged: Subscription;
  private subscriptionFromPricing: Subscription;
  private subscriptionForActivateChangeDetector: Subscription;
  private subscriptionFromEvalFunctionPricing: Subscription;
  private subscriptionLoadObjectEvtEmitter: Subscription;
  //private hasFooter: Boolean = false;
  private bulkModification: Map<number, LpMeta> = new Map()
  // Appelée lorsque l'utilisateur a choisi d'afficher un bloc sur la colonne de droite.
  @Input() set setApplicationItem(applicationItem: any) {
    // this.rightColService_V2.applciationItemDetailloadingIndicator = false;
  }
  private indexOfSelectedLine: number = 0;

  // TODO SUB
  public constructor(
    protected uiSyncService: UiSyncService,
    public configService: ConfigService,
    public jsDataService: JsdataService,
    private translate: TranslateService,
    private modalService: ModalService,
    private alocproProvider: AlocproProvider,
    public formStackService: FormStackService,
    private changeService: ChangeService,
    private rightColService: RightColService,
    private userService: UserService,
    private formService: FormService,
    private formatService: FormatService,
    private progressBarService: ProgressBarService,
    private bulkService: BulkService,
    private evalService: EvalService,
    private userHstoryService: UserHistoryService,
    private changeDetectorRef: ChangeDetectorRef,
    private pricingService: PricingCustomService
    ) {}

  public ngOnInit(): void {
    this.subscribe();
    this.init();
    this.setStyle();
  }

  public subscribe(): void {
    this.subscriptionRootformColChanged = this.uiSyncService.rootformColChangedEvtEmitter.subscribe((sizes) => {
      this.uiSyncService.loader(true, false, this.translate.instant('loading.refreshColumns'));
      this.showTable = false;
      this.showTable = true;
      this.uiSyncService.loader(false);
    });
    this.subscriptionFromPricing = this.uiSyncService.updateGrid.subscribe((sizes) => {
      this.rightColService.applciationItemDetailloadingIndicator = true; 
      this.showTable = false;
      this.showTable = true;
      this.resetStyle();
      this.rightColService.applciationItemDetailloadingIndicator = false; 
    });
    this.subscriptionForActivateChangeDetector = this.uiSyncService.changeDetector.subscribe(() => {
      this.changeDetectorRef.detectChanges();
    });
    this.subscriptionFromEvalFunctionPricing = this.uiSyncService.evalFunctionForGrid.subscribe((event) => {
      this.evalFunction(event[2], event[1]);
      this.changeDetectorRef.detectChanges();
    });
  }

  public getStyle(property: string, rowIndex: number, colIndex: number): String {
    return this.mapStyle.get(`${property},${rowIndex},${colIndex}`);
  }

  public getComponentStyle(property: string, rowIndex: number, colIndex: number): String {
    return this.mapComponentStyle.get(`${property},${rowIndex},${colIndex}`);
  }

  public eraseMapStyle(): void {
    this.mapStyle.clear();
  }

  public eraseMapComponentStyle(): void {
    this.mapComponentStyle.clear();
  }

  public setStyle(): void {
    if ( this.formStackService &&
         this.formStackService.CurrentApplicationItemDetailData &&
         this.formStackService.CurrentApplicationItemDetailData.length && 
         this.formStackService.currentApplicationItemDetail &&
         this.formStackService.currentApplicationItemDetail.columns &&
         this.formStackService.currentApplicationItemDetail.columns.length) {
          for (let indexVertical = 0; indexVertical < this.formStackService.CurrentApplicationItemDetailData.length; indexVertical++) {
            let indexOfActiveColumns: number = 0;
            for (let indexHorizontal = 0; indexHorizontal < this.formStackService.currentApplicationItemDetail.columns.length; indexHorizontal++) {
              if ( this.formStackService.CurrentApplicationItemDetailData && 
                   this.formStackService.CurrentApplicationItemDetailData[indexVertical] && 
                   this.formStackService.currentApplicationItemDetail.columns[indexHorizontal].isDisplayed === true
                  ) {
                    //let value = this.formStackService.CurrentApplicationItemDetailData[indexVertical][`${this.formStackService.currentApplicationItemDetail.columns[indexHorizontal].name}`];
                    let propertyWithIndexes = `${this.formStackService.currentApplicationItemDetail.columns[indexHorizontal].name},${indexVertical},${indexOfActiveColumns}`;
                    let property = this.formStackService.currentApplicationItemDetail.columns[indexHorizontal];
                    let stylesResults = this.getRowStyleCondition(this.formStackService.CurrentApplicationItemDetailData[indexVertical],indexVertical,property, false)
                    this.mapComponentStyle.set(propertyWithIndexes,stylesResults[1]);
                    this.mapStyle.set(propertyWithIndexes,stylesResults[0]);
                    indexOfActiveColumns++;
              }
            }
          }
    } else {
      setTimeout( () => {
        this.setStyle();
      }, 100);
    }
  }

  /*public setStyle(): void {
    if ( this.formStackService &&
      this.formStackService.CurrentApplicationItemDetailData &&
      this.formStackService.CurrentApplicationItemDetailData.length && 
      this.formStackService.currentApplicationItemDetail &&
      this.formStackService.currentApplicationItemDetail.columns &&
      this.formStackService.currentApplicationItemDetail.columns.length) {

      
      this.formStackService.CurrentApplicationItemDetailData.forEach((item: LpMeta, indexVertical: number) => {
        let indexOfActiveColumns: number = 0;
        this.formStackService.currentApplicationItemDetail.columns.forEach((columns: ApplicationItemDetailColumn, indexHorizontal: number) => {
          if ( this.formStackService.CurrentApplicationItemDetailData && 
            this.formStackService.CurrentApplicationItemDetailData[indexVertical] && 
            this.formStackService.currentApplicationItemDetail.columns[indexHorizontal].isDisplayed === true
          ) {
            //let value = this.formStackService.CurrentApplicationItemDetailData[indexVertical][`${this.formStackService.currentApplicationItemDetail.columns[indexHorizontal].name}`];
            let propertyWithIndexes = `${this.formStackService.currentApplicationItemDetail.columns[indexHorizontal].name},${indexVertical},${indexOfActiveColumns}`;
            let property = this.formStackService.currentApplicationItemDetail.columns[indexHorizontal];
            let stylesResults = this.getRowStyleCondition(this.formStackService.CurrentApplicationItemDetailData[indexVertical],indexVertical,property, false)
            console.log('evaaaaaaaaaaaaal')
            this.mapComponentStyle.set(propertyWithIndexes,stylesResults[1]);
            this.mapStyle.set(propertyWithIndexes,stylesResults[0]);
            indexOfActiveColumns++;
          }
        });
      });
    } else {
      setTimeout( () => {
        this.setStyle();
      }, 100);
    }
  }*/
  
  public getRowStyleCondition(data: any, index: number, property: any, isCell: Boolean = true): string[] {
    let enableStyleCondition: string = '';
    let enableComponentStyleCondition: string = '';
    if ( !Util.isNullOrUndefined(this.formStackService) && 
      !Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail) && 
      !Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail.cellStyles) && 
      this.formStackService.currentApplicationItemDetail.cellStyles.length > 0) {
      this.changeDetectorRef.reattach();  
      for (let index = 0; index < this.formStackService.currentApplicationItemDetail.cellStyles.length; index++) {
        const cellStyle: CellStyle = this.formStackService.currentApplicationItemDetail.cellStyles[index];
        if (this.evalService.evalSync(cellStyle.condition, data, property)) {
          if (cellStyle.cellStyle) {enableStyleCondition += cellStyle.cellStyle;} 
          if (cellStyle.componentStyle) {enableComponentStyleCondition += cellStyle.componentStyle;} 
        }
      }
      if ( 
          !Util.isNullOrUndefined(this.formStackService) && 
          !Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail) && 
          !Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail.detectChanges) &&
          this.formStackService.currentApplicationItemDetail.detectChanges === false 
        ) {
        this.changeDetectorRef.detach();  
      }
    }
    return [enableStyleCondition, enableComponentStyleCondition];    
  }

  public rowClass = (row: any) => {
    if (!Util.isNullOrUndefined(row)) {
      return {
        'footer-table': row.id === 'footer_table',
        'barre': row.action === BULK_ACTION_DELETE
      }
    }
  }

  public evalFunction(value: string | number, property: string): void {
    const numberValue: number = Number(value);
    const indexRow: number = Number(property.substring(property.indexOf('-')+1, property.length));
    const propRow: string = property.substring(0, property.indexOf('-'));
    const detailsId: number = this.formStackService.currentApplicationItem.details.findIndex(d => d.key === this.formStackService.CurrentApplicationItemDetail.key);
    const constraint: ApplicationItemDetailColumn = this.formStackService.currentApplicationItem.details[detailsId].columns.find(col => col.name === propRow);
    if (!Util.isNullOrUndefined(constraint) && !Util.isNullOrUndefined(constraint.evalFunction)) {
      this.evalService.evalForCellFunction(constraint.evalFunction, this.formStackService.CurrentApplicationItemDetailData[indexRow], indexRow, numberValue);
    }
  }

  public ngOnDestroy(): void {
    if (this.subscriptionRootformColChanged) {
      this.subscriptionRootformColChanged.unsubscribe();
    }
    if (this.subscriptionLoadObjectEvtEmitter) {
      this.subscriptionLoadObjectEvtEmitter.unsubscribe();
    }
    if (this.subscriptionFromPricing) {
      this.subscriptionFromPricing.unsubscribe();
    }
    if (this.subscriptionFromEvalFunctionPricing) {
      this.subscriptionFromEvalFunctionPricing.unsubscribe();
    }
    if (this.subscriptionForActivateChangeDetector) {
      this.subscriptionForActivateChangeDetector.unsubscribe();
    }
  }
  // pricings-national.pricing-details-national.additionnalDay
  public async export(type: string): Promise<void> {
    let params: Object[] = [];
    //if (this.changeService.getIsLastChanged()) {
      //let result: boolean = await this.modalService.modalPromise(LpModalTarifComponent, {backdropClass: 'alertBackdropClass'});
      if (true) {
        this.changeService.clearDetailsChanged2();
        for (let i: number = 0; i < this.getDisplayedColumns().length; i++) {
          const column: ApplicationItemDetailColumn = this.getDisplayedColumns()[i];
          params.push({
            'type': (column.type ? column.type : 1),
            //'wording': column['name'],
            'wording': this.translate.instant(String(`${this.formStackService.CurrentVerb}.${this.formStackService.currentApplicationItemDetail.key}.${column['name']}`)),
            'prop': column.prop,
          });
        }
        let d: any = await this.pricingService.getDetailsData(this.formStackService.currentApplicationItem.verb.toString(), this.formStackService.currentData)
        
        let jsonCsv: JsonCsv = new JsonCsv(this.formatService, this.translate, params, d, 'export' + Date.now());
        jsonCsv.generateCsv();
      }
    //}     
  }

  

  /**
   * Fonction utilisée lorsque l'utilisateur veut supprimer une ligne du tableau en BDD
   * La fonction ouvre une modal de confirmation.
   * @param row
   */
  public async deleteObject(row: LpMeta): Promise<void> {
    if (this.formStackService.CurrentVerb === SCREEN_TEST) {
      this.formStackService.CurrentApplicationItemDetailData.splice(parseInt(row.id.toString(), 0), 1);
      event.stopPropagation();
    } else {
      this.currentDeleteLine = row;
      const result: boolean = await this.modalService.modalPromise(LpModalConfirmComponent, {
        backdropClass: 'alertBackdropClass',
        id : 'modalWithHeader'
      });
      if (result) {
        await this.evalService.executeContrainte(
          BEFORE_DELETE_EVENT, this.formStackService.currentApplicationItemDetail.key.toString(), this.formStackService.currentApplicationItem.ecrId.toString(), '*', this.formStackService.currentDetailLineData
              , this.formStackService.currentData);
        this.alocproProvider.delete(this.formStackService.buildDetailUrl(
          this.formStackService.currentApplicationItemDetail.path.toString()), this.currentDeleteLine.id,
          ApplicationItemDetail.buildQueryParams('GET', this.formStackService.currentApplicationItemDetail)).then(() => {
            this.userHstoryService.addEvent(
              'delete',
              this.formStackService.currentApplicationItem,
              this.formStackService.currentData.id,
              this.formStackService.currentApplicationItem.verb,
              null,
              (this.formStackService.currentApplicationItemDetail.editForm  !== null 
              && this.formStackService.currentApplicationItemDetail.editForm  !== undefined
              && this.formStackService.currentApplicationItemDetail.editForm.title !== undefined
              && this.formStackService.currentApplicationItemDetail.editForm.title !== null) 
              ? this.translate.instant(this.formStackService.currentApplicationItemDetail.editForm.title.toString()) 
              : ''
              +' (ID '+this.currentDeleteLine.id+')'
            );
            this.modalService.success(this.translate.instant('general.modalService.deletionCompleted'),
              this.translate.instant('general.modalService.deletion'));
            this.rightColService.displaySubAPIDetailsTab(this.formStackService.currentApplicationItemDetail
              , this.formStackService.currentData);
            if (this.formStackService.currentApplicationItemDetail.reloadAfterSubmit) {
              this.formService.build(this.formStackService.CurrentVerb, this.formStackService.CurrentKy)
            }
          });
      }
      event.stopPropagation();
    }
  }

  public deleteBulk(row: LpMeta, index: number): void {
    let d: LpMeta = this.formStackService.CurrentApplicationItemDetailData.find((item: LpMeta) => {
      return item['field0'] === row['field0'];
    });
    if (!Util.isNullOrUndefined(d)) {
      if (d[BULK_ACTION] === BULK_ACTION_DELETE) {
        d[BULK_ACTION] = null;
        this.bulkModification.delete(index);
      } else {
        d[BULK_ACTION] = BULK_ACTION_DELETE;
        this.bulkModification.set(index, d);
      }
    }
  }

  public duplicate(row: LpMeta): void {
    this.formStackService.CurrentApplicationItemDetailData = [...this.formStackService.CurrentApplicationItemDetailData, row];
  }  

  public async duplicateLine(row: LpMeta, index: number): Promise<void> {
    let duplicatedRow: LpMeta = new LpMeta();
    duplicatedRow.assign(row);
    duplicatedRow.id = null;
    /* TODO : Décommenter lorsque les APIs sont disponibles.
     
    this.alocproProvider.post(this.formStackService.CurrentApplicationItemDetail.path, row)
     .then((ky: String) => {
        row.id = ky;
        let tableTemp: LpMeta[] = [].concat(this.formStackService.CurrentApplicationItemDetailData);
        tableTemp.splice(index, 0, row);
        this.formStackService.CurrentApplicationItemDetailData = tableTemp;
     }  
     */ 

    /* TODO :Supprimer lorsque les APIs sont disponibles. */
    let tableTemp: LpMeta[] = [].concat(this.formStackService.CurrentApplicationItemDetailData);
    await this.evalService.executeContrainte(ON_DUPLICATE_ACTION, this.formStackService.currentApplicationItemDetail.key.toString(), 
    this.formStackService.currentApplicationItem.ecrId.toString(), '*', duplicatedRow, null, null,this.formStackService.currentData)
    tableTemp.splice(index + 1, 0, duplicatedRow);
    this.formStackService.CurrentApplicationItemDetailData = tableTemp;
    this.changeDetectorRef.detectChanges(); 
    this.resetStyle();
  }

  public addRow(): void {
    let temp: LpMeta = new LpMeta()
    temp[BULK_ACTION] = BULK_ACTION_INSERT
    if ( Util.isNullOrUndefined(this.formStackService) || Util.isNullOrUndefined(this.formStackService.CurrentApplicationItemDetailData)) {
      this.formStackService.CurrentApplicationItemDetailData = [new LpMeta()]
    } else {
      this.formStackService.CurrentApplicationItemDetailData = [...this.formStackService.CurrentApplicationItemDetailData, temp];
    }
  }

  public saveBulk(row: LpMeta, id: number, prop: string): void {

    if (Util.isNullOrUndefined(row[BULK_ACTION]) || row[BULK_ACTION] === BULK_ACTION_UPDATE) {
      row[BULK_ACTION] = BULK_ACTION_UPDATE;
    } else {
      row[BULK_ACTION] = BULK_ACTION_INSERT
    }
    this.bulkModification.set(id, row);
  }

  public async persistBulk(): Promise<void> {
    this.uiSyncService.loader(true, true, this.translate.instant('loading.data'));
    let temp: Array<LpMeta> = this.getBulkArray(this.bulkModification, this.formStackService.CurrentApplicationItemDetailData)
    await this.rightColService.submitDetail(this.formStackService.currentData, temp);
    this.formStackService.CurrentApplicationItemDetailData = this.cleanApplicationItemDetailData(this.formStackService.CurrentApplicationItemDetailData);
    await this.bulkService.bulkConfig(this.formStackService.CurrentVerb, this.formStackService.CurrentKy);
    this.changeService.clearAll()
    this.uiSyncService.loader(false);
  }

  /**
   * Fonction utilisée pour masquer le composant.
   */
  public closeComponent(): void {
    this.uiSyncService.changeRightBlock(false);
  }

  /**
   * Fonction utilisée lorsque l'on veut afficher le formulaire d'édition en dessous du tableau.
   * @param itemId
   * @param defaultData
   */
  public showDetailForm(itemId?: string, defaultData?: LpMeta): void {
    if (this.changeService.isDetailsChanged2()) {
      this.modalService.modalPromise(LpModalChangeComponent, {
        backdropClass: 'alertBackdropClass',
        id : 'modalWithHeader'
      }).then((result: boolean) => {
        if (result) {
          this.changeService.clearDetailsChanged2();
          this.uiSyncService.loader(true, true, this.translate.instant('loading.data'));
          this.rightColService.displayOrEditSubApiDetail(itemId, defaultData);          
        }
      });
    } else {
      this.uiSyncService.loader(true, true, this.translate.instant('loading.data'));
      this.rightColService.displayOrEditSubApiDetail(itemId, defaultData);
    }
  }

  public removeLine(line: LpMeta): void {
     this.pricingService.deletePricingDetailsNew(line).then(() =>{
      this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
      this.translate.instant('general.modalService.success'));
      this.uiSyncService.filterPricings.emit();
      this.uiSyncService.updateGrid.emit();
     });
     
  }

  private async evalFocusout(line: LpMeta, rowIndex: Number): Promise<void> {
    setTimeout(() => {
      this.evalService.evalForFocusOutAction(this.formStackService.currentApplicationItemDetail.focusOutAction,[line, Number(rowIndex), this.parentData]).then(() => {
        this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
              this.translate.instant('general.modalService.success'));
      }).catch((e: HttpError) => {
        this.modalService.error(this.translate.instant('general.modalService.recordingFailed'),
              this.translate.instant('general.modalService.recordingFailed'));
      });
    }, 500);
  }

  private async put(line: LpMeta, idRow: String, rowIndex: Number): Promise<void> {
    this.mapActiveRows.set(idRow.toString(), false);
    this.alocproProvider.put(this.uiSyncService.formatURL(this.formStackService.currentApplicationItemDetail.path.toString(),
    this.parentData), idRow.toString(), line).then(() => {
      this.refreshline(idRow, true, rowIndex);
    }).catch((e: HttpError) => {
      this.refreshline(idRow, false, rowIndex, e);
    }
  ); 
  }

  private setVariables() {
    this.isLoading = false;
    this.isLoadingID = "";
    this.indexOfSelectedLine = 0;
    this.changeDetectorRef.detectChanges();
  }

  private transformLine(rowIndex: Number): LpMeta {
    /*let line: LpMeta;
      if (this.formStackService.currentApplicationItemDetail.path.toString() === 'pricing-details') {
        line = this.formStackService.CurrentApplicationItemDetailData[Number(rowIndex)];
      } else {
        line = _.find(this.formStackService.CurrentApplicationItemDetailData, function (item: LpMeta): boolean {
          return item.id === idRow;
        });
      }
      return line;*/
      return this.formStackService.CurrentApplicationItemDetailData[Number(rowIndex)];
  }

  private mustBeSaved(rowIndex: Number, col: ApplicationItemDetailColumn): boolean {
    return (Util.isNullOrUndefined(col.modifyAccordingProperty) || (!Util.isNullOrUndefined(col.modifyAccordingProperty) && !Util.isNullOrUndefined(this.formStackService.CurrentApplicationItemDetailData[+rowIndex][col.modifyAccordingProperty])
    && this.formStackService.CurrentApplicationItemDetailData[+rowIndex][col.modifyAccordingProperty]))
  }

  public savedByButtom(): boolean {
    return (!Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail.saveByButton ) && this.formStackService.currentApplicationItemDetail.saveByButton === true)
  }

  public containAfocuOutAction(): boolean {
    return !Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail.focusOutAction);
  }

  public buttonSaveLine(idRow: String, rowIndex: Number, object: string, col: ApplicationItemDetailColumn, row?: any): void {
      if(this.savedByButtom() && this.mustBeSaved(rowIndex, col)) {
        this.saveLine(idRow, rowIndex, object, col, row);
      }
  }

  public focusOutSaveLine(idRow: String, rowIndex: Number, object: string, col: ApplicationItemDetailColumn, row?: any): void {
    if(this.savedByButtom() === false && this.mustBeSaved(rowIndex, col)) {
      this.saveLine(idRow, rowIndex, object, col, row);
    }
  }

  public saveLine(idRow: String, rowIndex: Number, object: string, col: ApplicationItemDetailColumn, row?: any): void {
    this.isLoading = true;
    this.isLoadingID = idRow;
    this.indexOfSelectedLine = Number(rowIndex);
    let line: LpMeta = this.transformLine(rowIndex);
    
    line[object] = {};
    line[object]['id'] = 0;

    if (this.containAfocuOutAction()) {
      this.setVariables();
      this.evalFocusout(line, rowIndex);
    } else {
      this.put(line, idRow, rowIndex);
    }
    this.resetStyle();
  }

  public isTheGoodLine(indexLine: number): boolean {
    return this.indexOfSelectedLine === indexLine;
  }

  public createPricingMethodbis(line: any, rowIndex: number): Pricing {
    try {
      let pricing: Pricing = new Pricing(); //mettre dans le creatPricing let pricing = {'id': null, wording: null, ...}

      pricing.id = NATIONAL;
      pricing.wording = this.formStackService.CurrentApplicationItemDetailData[rowIndex-1]['wording'];

       // pricingMethod
       pricing.pricingMethods = [];
       pricing.pricingMethods.push(new SpecificPricingMethod());
       pricing.pricingMethods[0] = this.formStackService.CurrentApplicationItemDetailData[rowIndex-1] as SpecificPricingMethod;
 
       // specificPricingMethod
       pricing.specificPricingMethods = [];
       pricing.specificPricingMethods.push(new SpecificPricingMethod());
       pricing.specificPricingMethods[0].id = String(this.formStackService.CurrentApplicationItemDetailData[rowIndex-1].id);
       pricing.specificPricingMethods[0].wording = this.formStackService.CurrentApplicationItemDetailData[rowIndex-1]['wording'];
       pricing.specificPricingMethods[0].category = line['category'];
       pricing.specificPricingMethods[0].amount = line['amount'];
       pricing.specificPricingMethods[0].pricingTime = line['pricingTime'];
       pricing.specificPricingMethods[0].isActive = line['isActive'];

      return pricing;
    } catch (error) {
      throw error;
  } 
}

  public isCellReadOnly(col: ApplicationItemDetailColumn, rowId: number): boolean {
    //return col.modifyAccordingProperty ?  !formStackService.CurrentApplicationItemDetailData[rowIndex][col.modifyAccordingProperty] : false
    if(!Util.isNullOrUndefined(col.modifyAccordingProperty) && !Util.isNullOrUndefined(this.formStackService.CurrentApplicationItemDetailData[rowId][col.modifyAccordingProperty])){
      return !this.formStackService.CurrentApplicationItemDetailData[rowId][col.modifyAccordingProperty]
    } else if(!Util.isNullOrUndefined(col.modifyAccordingProperty) && Util.isNullOrUndefined(this.formStackService.CurrentApplicationItemDetailData[rowId][col.modifyAccordingProperty])){
      return true
    } else {
      return false;
    }
  }


  public async changePage(obj: any, isDetailBulk: boolean): Promise<void> {
    this.defineCurrentPage(obj);
    if(this.formStackService.CurrentApplicationItemDetail.bulk){
      let params: Array<FtParams> = await this.bulkService.bulkConfig(this.formStackService.CurrentVerb, this.formStackService.CurrentKy);
      this.formStackService.CurrentApplicationItemDetailData = await this.bulkService.getData(this.formStackService.CurrentVerb, this.formStackService.CurrentKy, Object.keys(this.formStackService.currentData), this.currentPage, null, params);
      setTimeout(() => {
        this.uiSyncService.loadObject();
      });
    } else {
      let path: String = this.formStackService.currentApplicationItemDetail.path
      this.rightColService.applciationItemDetailloadingIndicator = true;
      let search: Map<string, string> = null;
      if (isDetailBulk) {
        path += `${EXECUTE}`
        search = new Map();
        search = this.bulkService.searchCriterias
      }
      this.alocproProvider.getPaginatedData(
        this.uiSyncService.formatURL(path, this.parentData), this.currentPage, search, false, this.linesPerPagesDefinition(obj), null, null, null, true)
        .then((records: PaginatedData) => {
          this.formStackService.CurrentApplicationItemDetailData = records.body;
          this.formStackService.CurrentApplicationItemDetailDataReadonly = records._readonly;
          this.mapActiveRows.clear();
          this.formStackService.CurrentApplicationItemDetailData.forEach(row => {
            this.mapActiveRows.set(row.id, true);
          });
          this.rightColService.applciationItemDetailloadingIndicator = false;
        });
    }
  }

  public onSort(event: any): void {
    this.rightColService.applciationItemDetailloadingIndicator = true;
    this.currentPage = 1;
    this.alocproProvider.getPaginatedData(
      this.uiSyncService.formatURL(this.formStackService.currentApplicationItemDetail.path, this.parentData),
      this.currentPage,
      ApplicationItemDetail.buildQueryParams('GET', this.formStackService.currentApplicationItemDetail),
      false,
      Number(this.userService.getCurrentUser().preference.linesPerPage),
      event.sorts[0].dir.toUpperCase(),
      event.sorts[0].prop)
      .then((records: PaginatedData) => {
        this.formStackService.CurrentApplicationItemDetailData = records.body;
        this.formStackService.CurrentApplicationItemDetailDataReadonly = records._readonly;
        this.mapActiveRows.clear();
        this.formStackService.CurrentApplicationItemDetailData.forEach(row => {
          this.mapActiveRows.set(row.id, true);
        });
        this.rightColService.applciationItemDetailloadingIndicator = false;
      });
  }

  /**
   * La fonction refresline() est appelé par la fonction saveLine().
   * Elle va recharger la ligne concernée et rafraichir le footer si il y en a un.
   * @param row
   */
  public refreshline(idRow: String, success: Boolean, rowItem: Number, e?: HttpError): void {
    // Récupération des infos dans le Backend
    this.alocproProvider.find(
      this.uiSyncService.formatURL(this.formStackService.currentApplicationItemDetail.path, this.parentData), idRow)
      .then((record: LpMeta) => {
        let newRox: LpMeta;
        // récupération de la nouvelle ligne
        // TODO a changer lorsque l'appel unitaire fonctionnera
        if (idRow === record.id) {
          newRox = record;
          for (let i: number = 0; i < this.formStackService.CurrentApplicationItemDetailData.length; i++) {
            if (this.formStackService.CurrentApplicationItemDetailData[i].id === newRox.id && i === rowItem) {
              // Changement de la ligne en question par la ligne nouvellement modifié
              this.formStackService.CurrentApplicationItemDetailData[i] = newRox;
              this.mapActiveRows.set(idRow, true);

              // Affichage d'une notification succès/ error
              if (success) {
                this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
                  this.translate.instant('general.modalService.success'));
                this.isLoading = false;
              }
            }
          }
          // Rafraichissement du footer
          this.rightColService.setApplictionItemDetailFooter(+this.formStackService.currentApplicationItemDetail.id);
          this.uiSyncService.loadObject();
          this.rightColService.setDetailTitle(this.formStackService.currentApplicationItemDetail);
        }
      });
  }

  /*public async refreshlineV2(idRow: String, path: string): Promise<void> {
    try {
      const line: LpMeta = await this.alocproProvider.find(path, idRow);
      if (idRow === line.id) {
        for (let i: number = 0; i < this.formStackService.CurrentApplicationItemDetailData.length; i++) {
          if (this.formStackService.CurrentApplicationItemDetailData[i].id === newRox.id && i === rowItem) {
            // Changement de la ligne en question par la ligne nouvellement modifié
            this.formStackService.CurrentApplicationItemDetailData[i] = newRox;
            this.mapActiveRows.set(idRow, true);

            // Affichage d'une notification succès/ error
            if (success) {
              this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
                this.translate.instant('general.modalService.success'));
              this.isLoading = false;
            }
          }
        }
      }
    } catch (error) {
      throw error;
    }
    // Récupération des infos dans le Backend
    this.alocproProvider.find(
      this.uiSyncService.formatURL(this.formStackService.currentApplicationItemDetail.path, this.parentData), idRow)
      .then((record: LpMeta) => {
        let newRox: LpMeta;
        // récupération de la nouvelle ligne
        // TODO a changer lorsque l'appel unitaire fonctionnera
        if (idRow === record.id) {
          newRox = record;
          for (let i: number = 0; i < this.formStackService.CurrentApplicationItemDetailData.length; i++) {
            if (this.formStackService.CurrentApplicationItemDetailData[i].id === newRox.id && i === rowItem) {
              // Changement de la ligne en question par la ligne nouvellement modifié
              this.formStackService.CurrentApplicationItemDetailData[i] = newRox;
              this.mapActiveRows.set(idRow, true);

              // Affichage d'une notification succès/ error
              if (success) {
                this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
                  this.translate.instant('general.modalService.success'));
                this.isLoading = false;
              }
            }
          }
          // Rafraichissement du footer
          this.rightColService.setApplictionItemDetailFooter(+this.formStackService.currentApplicationItemDetail.id);
          this.uiSyncService.loadObject();
          this.rightColService.setDetailTitle(this.formStackService.currentApplicationItemDetail);
        }
      });
  }*/

  public sumNumber(prop: String): number {
    let sum: number = 0;
    for (let i: number = 0; i < this.formStackService.CurrentApplicationItemDetailData.length; i++) {
      sum += this.formStackService.CurrentApplicationItemDetailData[i][prop.toString()];
    }
    return sum;
  }

  public avgNumber(prop: String): number {
    return this.sumNumber(prop.toString()) / this.formStackService.CurrentApplicationItemDetailData.length;
  }

  public changeConfigPanel(): void {
    this.showConfigPanel = !this.showConfigPanel;
  }

  public getDisplayedColumns(): Array<ApplicationItemDetailColumn> {
    let activeColums: ApplicationItemDetailColumn[] = [];
    for (let index: number = 0; index < this.formStackService.currentApplicationItemDetail?.columns.length; index++) {
      if (this.formStackService.currentApplicationItemDetail?.columns[index].isDisplayed) {
        activeColums.push(this.formStackService.currentApplicationItemDetail?.columns[index]);
      }
    }
    return activeColums;
  }

  public getIgnoreChange(col: ApplicationItemDetailColumn): boolean {
    let ignoreChange: boolean = true;
    if (  !Util.isNullOrUndefined(col) && 
          !Util.isNullOrUndefined(col.inputField) && 
          !Util.isNullOrUndefined(col.inputField.params) && 
          !Util.isNullOrUndefined(col.inputField.params.ignoreChange)
    ) {
      ignoreChange = col.inputField.params.ignoreChange;
    }
    return ignoreChange;
  }

  public async importLines(lines: any): Promise<void> {
    if (this.changeService.getIsLastChanged()) {
      let result: boolean = await this.modalService.modalPromise(LpModalChangeComponent, {backdropClass: 'alertBackdropClass'});
      if (result) {
        this.changeService.clearDetailsChanged2();
        await this.import(lines);
      } 
    }
  }

  private async import(lines: any): Promise<void> {
    this.bulkModification.clear();
    let columns: Map<string, ApplicationItemDetailColumn> = new Map();
    // Formatage d'une map de colonne avec les clés de formatage table.field (idem aux entete de colonne de l'export).
    for (let i: number = 0; i < this.formStackService.currentApplicationItemDetail.columns.length; i++) {
      const col: ApplicationItemDetailColumn = this.formStackService.currentApplicationItemDetail.columns[i];
      columns.set(col['wording'], col);
    }
    this.progressBarService.init(lines.length, this.translate.instant('general.modalService.readFile'));
    for (let i: number = 0; i < lines.length; i++) {
      const CSVline: any = lines[i];
      if (CSVline !== null && CSVline !== undefined) {
        let finalObject: any = {};
        for (let j: number = 0; j < Object.keys(CSVline).length; j++) {
          const prop: string = Object.keys(CSVline)[j];
          if (CSVline[prop].indexOf('"') === 0 && CSVline[prop].lastIndexOf('"') === (CSVline[prop].length - 1)){
            CSVline[prop] = CSVline[prop].substr(1, CSVline[prop].length - 2);
          }
          if (prop !== 'id') {
            let col: ApplicationItemDetailColumn = columns.get(prop);
            if (col !== null && col !== undefined) {
              if (CSVline[prop] === '') {
                finalObject[col.prop.toString()] = null;
              } else {
                if (col.type === 0) {
                  finalObject[col.prop.toString()] = CSVline[prop];
                } else if (col.type === 2) {
                  finalObject[col.prop.toString()] = this.formatService.replaceFloatSeprator(CSVline[prop]);
                } else if (col.type === 4) {
                  finalObject[col.prop.toString()] = this.formatService.formatValue(4, CSVline[prop]);
                } else if (col.type === 5) {
                  finalObject[col.prop.toString()] = this.formatService.formatValue(5, CSVline[prop]);
                } else if (col.type === 7) {
                  finalObject[col.prop.toString()] = CSVline[prop].toLowerCase() === 'true';
                } else if (col.type === 10) {
                  finalObject[col.prop.toString()] = this.formatService.replaceDeviseSeprator(CSVline[prop]);
                } else {
                  finalObject[col.prop.toString()] = CSVline[prop];
                }
              }
            }
          } else {
            finalObject['field0'] = CSVline['id'];
          }
        }
        finalObject['action'] = 'update';
        this.bulkModification.set(i, finalObject);
      }
      this.progressBarService.value = i;
    }
    this.progressBarService.active = false;
    this.uiSyncService.loader(true, true, this.translate.instant('loading.data'));
    await this.persistBulk();
    this.showImportBlock = false;
    this.formStackService.CurrentApplicationItemDetailData = await this.bulkService.getData(this.bulkService.verb, this.bulkService.ky
      , Object.keys(this.formStackService.currentData), this.currentPage, null, await this.bulkService.bulkConfig(this.bulkService.verb, this.bulkService.ky));
    setTimeout(() => {
      this.uiSyncService.loadObject();
    });
    this.changeService.clearAndReinit();
    this.uiSyncService.loader(false);
    this.modalService.success(this.translate.instant('general.modalService.readCompleted'));
  }

  private addAction(): void {
    /* Ajout de la colonne contenant les actions et réglage de la taille selon le nombre d'actions. */
    this.actionColumn = new ApplicationItemDetailColumn();
    this.actionColumn.prop = 'actionColumn';
    let width: number = 0;
    if (this.formStackService.currentApplicationItemDetail.modify) { width += 40; }
    if (this.formStackService.currentApplicationItemDetail.delete) { width += 40; }
    this.actionColumn.width = width;
  }

  private init(): void {
    // this.rightColService.linesPerPage = this.userService.getCurrentUser().preference.linesPerPage;
    let applicationItem: any = this.formStackService.currentApplicationItemDetail;
    this.rightColService.applciationItemDetailloadingIndicator = true;
    this.currentPage = 1;
    this.showTable = false;
    this.showConfigPanel = false;
    let column: ApplicationItemDetailColumn;

    for (let i in applicationItem.columns) {
      // Assignation des tailles de colonnes selon les types de données si aucune taille n'est renseignée dans la configuration.
      column = applicationItem.columns[i];
      if (column.width) {
        column.canAutoResize = false;
      }
    }
    this.addAction();

    if (applicationItem && applicationItem.data && Util.isNullOrUndefined(applicationItem.data)) { applicationItem.data = null }
    this.applicationItem = applicationItem;

    // Affichage du formulaire d'édition en dessous du tableau
    if (applicationItem && applicationItem['dataRootFormdetail']) {
      // Si on revient d'un formulaire "enfant", on lui passe l'objet à afficher par défaut.
      this.showDetailForm(applicationItem['dataRootFormdetail'].verb,
        applicationItem['dataRootFormdetail']);
    } else if (!this.formStackService.currentApplicationItemDetail.externalAddForm
      && this.formStackService.currentApplicationItemDetail.editForm
      && this.formStackService.currentApplicationItemDetail.editForm.id
      && this.formStackService.currentApplicationItemDetail.editFormActive) {
      // Si on a configuré l'affichage du formulaire dès l'affichage du tableau.
      this.showDetailForm();
    }

    if (this.formStackService.CurrentApplicationItemDetailData && this.formStackService.CurrentApplicationItemDetailData.length > 0
      && this.formStackService.currentApplicationItemDetail.columns) {
      // Création du footer si besoin

      this.uiSyncService.loadObject();

      this.formStackService.CurrentApplicationItemDetailData.forEach(row => {
        this.mapActiveRows.set(row.id, true);
      });
    }
    this.uiSyncService.loader(false);

    if (this.formStackService.currentApplicationItemDetail.columns) {
      this.showTable = true;
    }
    if (
      !Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail)
      && !Util.isNullOrUndefined(this.formStackService.currentApplicationItemDetail.TableSummaryRow)) {
      this.rightColService.setApplictionItemDetailFooter(+this.formStackService.currentApplicationItemDetail.id);
    }
    this.uiSyncService.loader(false);
    this.rightColService.applciationItemDetailloadingIndicator = false;
  }

  private getBulkArray(bulk: Map<number, LpMeta>, currentDetailsTable: Array<LpMeta>): Array<LpMeta> {
    let a: Array<any> = []
    for(let line of currentDetailsTable){
      if(!Util.isNullOrUndefined(line[BULK_ACTION])){ 
        a.push(Object.assign({}, line));
      }
    }
    if(bulk.size > 0 && !Util.isNullOrUndefined(Array.from(bulk.entries())[0][1]) && Array.from(bulk.entries())[0][1][BULK_ACTION] !== BULK_ACTION_INSERT){ 
      for (let b of Array.from(bulk.entries())) {
        let id: number = this.ValueAlreadyExixtsInTable(a, b[1])
        if(id > -1){
          a[id] = this.getValuesFromObj(b[1])
        } else {
          a.push(this.getValuesFromObj(b[1]));
        }
      }
    }
    let temp: Array<any> = this.transformZoomData(a);
    return temp;
  }

  private ValueAlreadyExixtsInTable(sourceArray: Array<any>, itemToSearch: LpMeta): number {
    let id: number = 0;
    for (let i of sourceArray){
      if(i['field0'] === itemToSearch['field0']){
        return id;
      }
      id++;
    }
    return -1;
  }

  private transformZoomData(ftExecute: Array<any>): any {
    for (let d of ftExecute){
      for(let p of Object.keys(d)){
        if(d[p] instanceof Object && !Util.isNullOrUndefined(d[p].id)){
          d[p] = d[p].id
        }
      }
    }
    return ftExecute;
  }

  private getValuesFromObj(object: LpMeta): LpMeta {
    let finalObject: any = {};
    for (let key of Object.keys(object)) {
      if (object[key] instanceof LpMeta) {
        finalObject[key] = object[key].id
      } else {
        finalObject[key] = object[key]
      }
    }
    return finalObject
  }

  private linesPerPagesDefinition(obj: any): Number | null {
    if (obj && obj.linesPerPage && obj.linesPerPage === NO_LIMIT_FILTER) {
      return null;
    } else {
      this.userService.getCurrentUser().preference.linesPerPage = obj.linesPerPage;
      return Number(this.userService.getCurrentUser().preference.linesPerPage);
    }
  }

  private defineCurrentPage(obj: any): void {
    if(!Util.isNullOrUndefined(obj.linesPerPage)){
      this.bulkService.linesPerPage = obj.linesPerPage;
    }
    if (obj && obj.linesPerPage && obj.linesPerPage === NO_LIMIT_FILTER) {
      this.currentPage = null;
    } else {
      this.currentPage = obj.currentPage;
    }
  }

  private cleanApplicationItemDetailData(currentApplicationItemDetailData: Array<LpMeta>): Array<LpMeta> {
    for(let d of currentApplicationItemDetailData){
      if(!Util.isNullOrUndefined(d[BULK_ACTION])){
        delete d[BULK_ACTION]
      }
    }
    return currentApplicationItemDetailData
  }

  private resetStyle(): void {
    this.eraseMapComponentStyle();
    this.eraseMapStyle();
    this.setStyle();
  }
}
