import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, RIBS, DRIVER_RIBS, CUSTOMER_RIBS } from '../detail-forms.component';
import { LpRib } from 'app/meta/rib';

@Component({
  selector: 'lp-ribs',
  templateUrl: './ribs.component.html'
})
export class RibsComponent extends DetailFormsComponent implements OnInit {

  public data: LpRib = null;

  public ngOnInit(): void {
    super.initData();
    this.data.assign(this.formStackService.currentDetailLineData);
    if (this.formStackService.currentApplicationItemDetail.key === DRIVER_RIBS) {
      this.setId(DRIVER_RIBS);
      this.object = DRIVER_RIBS;
    } else if (this.formStackService.currentApplicationItemDetail.key === CUSTOMER_RIBS) {
      this.setId(CUSTOMER_RIBS);
      this.object = CUSTOMER_RIBS
    } else {
      this.setId(RIBS);
      this.object = RIBS;
    }
    this.initUrl();

  }

  public init(): void {
    this.data.assign(this.formStackService.currentDetailLineData);
  }
}
