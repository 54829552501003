import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { VersionProvider } from 'app/providers/version.provider';
import { Util } from 'app/statics/utils';
import { LOGIN, MAINTENANCE } from 'app/providers/repository.provider';
import { RouterService } from './router.service';
import { ConfigService } from './config.service';
import { LpSnackBarComponent } from 'app/ui/lp-snack-bar/lp-snack-bar.component';
import { SnackBarService } from './snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class SoftwareUpdateService {

  public maintenance: Boolean = false;
  private hashPlaceHolder: string = '{{POST_BUILD_ENTERS_HASH_HERE}}';
  private currentHash: string = this.hashPlaceHolder;


  constructor(
    private versionProvider: VersionProvider,
    private router: RouterService,
    private configService: ConfigService,
    private snackBarService: SnackBarService
  ) { 
    //this.updateAlert(LpSnackBarComponent)
  }

  public initApplicationUpdating(): void {
    console.log(`application init with build ${this.currentHash}`);
    if (!isNaN(this.configService.get('software_update_frequency')) && this.configService.get('software_update_frequency') > 0) {
      if (this.currentHash.indexOf('POST_BUILD_ENTERS_HASH_HERE') === -1) {
        this.runApplicationCheck();
        interval(this.configService.get('software_update_frequency')).subscribe(val => this.runApplicationCheck());
      }
    }
  }

  private runApplicationCheck(): void {
    this.versionProvider.getVersion().then((version: any) => {
      if (!Util.isNullOrUndefined(version.maintenance) && version.maintenance) {
        this.maintenance = true;
        this.router.navigate(MAINTENANCE);
      } else if (this.maintenance && (Util.isNullOrUndefined(version.maintenance) || !version.maintenance)) {
        this.maintenance = false;
        this.router.navigate(LOGIN);
      }
      if (version.hash !== this.currentHash) {
        this.updateAlert(LpSnackBarComponent);
      }
    });
  }

  public updateApplication(): void {
    window.location.reload();
  }

  private async updateAlert(component): Promise<void> {
    this.snackBarService.openSnackBar(component)
  }
}
