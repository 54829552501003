<lp-ui-accordion id="{{id}}">
    <lp-ui-accordion-group heading="metadataApplicationItemDetails.linkDamageValue.titleTab" [open]=true>
      <form id="formLinkDamageValue" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.damageType" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="damages.damageType"></lp-label-field>
            <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control"
                name="linkDamageValue.damageType" [(ngModel)]="data.damageType" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'damageType'}]}">
            </lp-dropdown-field>
        </div>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.impactSeverityLevel" class="{{arrayClassesElements.get('defaultLabel')}}"
            text="damages.impactSeverityLevel"></lp-label-field>
            <lp-number id="linkDamageValue.impactSeverityLevel" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="linkDamageValue.impactSeverityLevel"
            [(ngModel)]="data.impactSeverityLevel"></lp-number>
        </div>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.wording" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.wording"></lp-label-field>
            <lp-text-field id="linkDamageValue.wording" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="linkDamageValue.wording"
            [(ngModel)]="data.wording" placeholder="damages.wording"></lp-text-field>
        </div>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.impactASeverityLevel" class="{{arrayClassesElements.get('defaultLabel')}}"
            text="damages.impactASeverityLevel"></lp-label-field>
            <lp-number id="linkDamageValue.impactASeverityLevel" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="linkDamageValue.impactASeverityLevel"
            [(ngModel)]="data.impactASeverityLevel"></lp-number>
        </div>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.wordingA" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.wordingA"></lp-label-field>
            <lp-text-field id="linkDamageValue.wordingA" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="linkDamageValue.wordingA"
            [(ngModel)]="data.wordingA" placeholder="damages.wordingA"></lp-text-field>
        </div>

        <div class="form-group col-xl-12">  
            <lp-label-field for="linkDamageValue.expertCode" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.expertCode"></lp-label-field>
            <lp-text-field id="linkDamageValue.expertCode" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="linkDamageValue.expertCode"
            [(ngModel)]="data.expertCode" placeholder="damages.expertCode"></lp-text-field>
        </div>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.wordingExpert" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.wordingExpert"></lp-label-field>
            <lp-text-field id="linkDamageValue.wordingExpert" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="linkDamageValue.wordingExpert"
            [(ngModel)]="data.wordingExpert" placeholder="damages.wordingExpert"></lp-text-field>
        </div>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.counterExpertCode" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.counterExpertCode"></lp-label-field>
            <lp-text-field id="linkDamageValue.counterExpertCode" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="linkDamageValue.counterExpertCode"
            [(ngModel)]="data.counterExpertCode" placeholder="damages.counterExpertCode"></lp-text-field>
        </div>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.counterExpertWording" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.counterExpertWording"></lp-label-field>
            <lp-text-field id="linkDamageValue.counterExpertWording" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="linkDamageValue.counterExpertWording"
            [(ngModel)]="data.counterExpertWording" placeholder="damages.counterExpertWording"></lp-text-field>  
        </div>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.amountExpertOpnionValue" class="{{arrayClassesElements.get('defaultLabel')}}"
            text="damages.amountExpertOpnionValue"></lp-label-field>
            <lp-number id="linkDamageValue.amountExpertOpnionValue" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="linkDamageValue.amountExpertOpnionValue"
            [(ngModel)]="data.amountExpertOpnionValue"></lp-number>
        </div>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.invoicedPercentage" class="{{arrayClassesElements.get('defaultLabel')}}"
            text="damages.invoicedPercentage"></lp-label-field>
            <lp-number id="linkDamageValue.invoicedPercentage" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="linkDamageValue.invoicedPercentage"
            [(ngModel)]="data.invoicedPercentage"></lp-number>
        </div>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.hasIndicatorCustomerRecognized" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="damages.hasIndicatorCustomerRecognized"></lp-label-field>
            <lp-boolean-switch-box name="linkDamageValue.hasIndicatorCustomerRecognized" switchName="hasIndicatorCustomerRecognized" id="linkDamageValue.hasIndicatorCustomerRecognized"
            [(ngModel)]="data.hasIndicatorCustomerRecognized"></lp-boolean-switch-box>
        </div>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.hasInvoicedIndicator" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="damages.hasInvoicedIndicator"></lp-label-field>
            <lp-boolean-switch-box name="linkDamageValue.hasInvoicedIndicator" switchName="hasInvoicedIndicator" id="linkDamageValue.hasInvoicedIndicator" [(ngModel)]="data.hasInvoicedIndicator">

            </lp-boolean-switch-box>
        </div>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.hasInsuredIndicator" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="damages.hasInsuredIndicator"></lp-label-field>
            <lp-boolean-switch-box name="linkDamageValue.hasInsuredIndicator" switchName="hasInsuredIndicator" id="linkDamageValue.hasInsuredIndicator" [(ngModel)]="data.hasInsuredIndicator">

            </lp-boolean-switch-box>
        </div>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.amountVADInvoiced" class="{{arrayClassesElements.get('defaultLabel')}}"
            text="damages.amountVADInvoiced"></lp-label-field>
            <lp-number id="linkDamageValue.amountVADInvoiced" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="linkDamageValue.amountVADInvoiced"
            [(ngModel)]="data.amountVADInvoiced"></lp-number>
        </div>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.referenceLinkDamage" class="{{arrayClassesElements.get('defaultLabel')}}" text="damages.referenceLinkDamage"></lp-label-field>
            <lp-text-field id="linkDamageValue.referenceLinkDamage" class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="linkDamageValue.referenceLinkDamage"
            [(ngModel)]="data.referenceLinkDamage" placeholder="damages.referenceLinkDamage"></lp-text-field>
        </div>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.hasVOIndicator" class="{{arrayClassesElements.get('defaultLabel')}}"
            text="damages.hasVOIndicator"></lp-label-field>
            <lp-boolean-switch-box name="linkDamageValue.hasVOIndicator" switchName="hasVOIndicator" id="linkDamageValue.hasVOIndicator" [(ngModel)]="data.hasVOIndicator"></lp-boolean-switch-box>
        </div>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.hasApplicationDepreciationDiscount" class="{{arrayClassesElements.get('defaultLabel')}}"
            text="damages.hasApplicationDepreciationDiscount"></lp-label-field>
            <lp-boolean-switch-box name="linkDamageValue.hasApplicationDepreciationDiscount" switchName="hasApplicationDepreciationDiscount" id="linkDamageValue.hasApplicationDepreciationDiscount"
            [(ngModel)]="data.hasApplicationDepreciationDiscount"></lp-boolean-switch-box>
        </div>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.hasApplicationDeductible" class="{{arrayClassesElements.get('defaultLabel')}}"
               text="damages.hasApplicationDeductible"></lp-label-field>
            <lp-boolean-switch-box name="linkDamageValue.hasApplicationDeductible" switchName="hasApplicationDeductible" id="linkDamageValue.hasApplicationDeductible"
            [(ngModel)]="data.hasApplicationDeductible"></lp-boolean-switch-box>
        </div>

        <div class="form-group col-xl-12">
            <lp-label-field for="linkDamageValue.damageValue" class="{{arrayClassesElements.get('defaultLabel')}}"
                text="damages.damageValue"></lp-label-field>
            <lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" cssClass="form-control" id="linkDamageValue.damageValue"
                name="linkDamageValue.damageValue" [(ngModel)]="data.damageValue" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'damageValue'}]}">
            </lp-dropdown-field>
        </div>

        <div class="text-end">
          <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
        </div>
      </form>
    </lp-ui-accordion-group>
    <lp-ui-accordion-group heading="Debugger Mon Composant" *ngIf="debugg">
      <div>
        <pre>{{ data | json }}</pre>
      </div>
    </lp-ui-accordion-group>
  </lp-ui-accordion>