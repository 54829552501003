import { Component, Input, forwardRef} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lp-progress-spinner',
  templateUrl: 'spinner.component.html',
  styleUrls: ['spinner.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LpSpinnerComponent),
      multi: true
    }
  ]
})


export class LpSpinnerComponent {

@Input() public color: string = 'deeppurple-amber';
@Input() public mode: string = 'indeterminate';
@Input() public value: number = 50;

}
