<div class="modal-body p-0 modal-sm modalWarning">
    <form #form="ngForm">
      <!--<p class="titleError text-left m-0">{{'general.modalService.error' | translate}}</p>-->
      <p class="titleError text-left m-0 mb-3">
        {{'general.modalService.error' | translate}}
      </p>

      <div  class="text-center">
        <div *ngFor="let error of modalService.arrayHttpWarning">
          <div class="ps-2 pe-2">
            {{translateByDefault(error)}}
          </div>
          <p *ngIf="error.additionalInformation">
            ({{error.additionalInformation}})
          </p>
          <hr class="m-3"/>
        </div>

        <!--<p class="mb-0 mt-3">{{titleError | translate}}</p>-->
        <div *ngIf="showDetail && isAdminOrNotLogin()">
          <div *ngFor="let error of modalService.arrayHttpWarning" class="ps-2 pe-2">
            <p class="mb-0 m-3">{{error.statusCode}} - {{error.message}}</p>
          </div>
          <hr class="m-3"/>
        </div>

      </div>

      <div class="row w-100 m-0">
        <div  *ngIf="isAdminOrNotLogin()" class="col text-small pt-1 cursor-pointer text-dark">
          <small (click)="showDetail=!showDetail" class="cursor-pointer" [class.d-none]="!showDetail">{{'general.button.lessDetails' | translate}}</small>
          <small (click)="showDetail=!showDetail" class="cursor-pointer" [class.d-none]="showDetail">{{'general.button.moreDetails' | translate}}</small>
        </div>
        <div class="col text-end btn-actions">
          <button type="button" class="modalCloseBtn btn btn-secondary me-2" (click)="close()">{{'general.button.close' | translate}}</button>
        </div>
      </div>
    </form>
</div>
