import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AlocproProvider } from "app/providers/alocpro.provider";
import { ConfigService } from "app/services/config.service";
import { LpModalComponent } from "../lp-modal.component";


@Component({
    selector: 'lp-modal-confidentiality',
    templateUrl: './lp-modal-confidentiality.component.html'
  })
export class LpModalConfidentialityComponent extends LpModalComponent implements OnInit {

    public arrayClassesElements: Map<String, String> = new Map<String, String>();
    public cgu: string = '';
    public cnil: string ='';

    constructor(
        public dialogRef: MatDialogRef<LpModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private configService: ConfigService,
        private aloc: AlocproProvider
      ) {
        super(dialogRef, data);
      }
    
      public async ngOnInit(): Promise<void> {
        this.arrayClassesElements.set('defaultLabel', this.configService.get('constanteClasses').DEFAULT_LABEL);
        this.arrayClassesElements.set('defaultInput', this.configService.get('constanteClasses').DEFAULT_INPUT);
        this.arrayClassesElements.set('defaultPostcode', this.configService.get('constanteClasses').DEFAULT_POSTE_CODE);
        this.cnil = await this.aloc.getConstraint('HTML_CNIL&lpServices=true&screen=20125', null);
        this.cgu = await this.aloc.getConstraint('HTML_CGU&lpServices=true&screen=20125', null);
      }

}
