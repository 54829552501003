<lp-label-field *ngIf="label" for="{{id}}" class="control-label" [hidden]="this.formStackService.getFields(name).hidden"
[ignoreChange]="ignoreChange" text="{{translationPrefix}}{{label}}"  [required]="this.formStackService.getFields(name).mandatory"></lp-label-field>

<div class="codemirror-container" [class.fixed]="isFixed">
  <i *ngIf="!isFixed" class="icon vega-fullsize-on" (click)="isFixed = !isFixed" title="{{'fullsize-on' | translate}}"></i>
  <i *ngIf="isFixed" class="icon vega-fullsize-off" (click)="isFixed = !isFixed" title="{{'fullsize-off' | translate}}"></i>
  <ngx-codemirror
    [(ngModel)]="displayValue"
    name="request"
    id="request"
    class="form-control p-0"
    rows="{{rows}}"
    (focusChange)="pushChange();"
    [options]="getOptions()">
  </ngx-codemirror>
</div>
