import { Injectable } from '@angular/core';
import { MetadataDetails, MetadataDetailsColumns } from 'app/models/metadata-details';
import { LpMeta } from 'app/meta/meta';
import { RepositoryProvider } from 'app/providers/repository.provider';
import { FormStackService } from './form-stack.service';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { SchedulerLink } from 'app/meta/scheduler/scheduler-link';
import { FormatService } from './format.service';
import * as _ from 'underscore'
import { Util } from 'app/statics/utils';
import { JsdataService } from './jsdata.service';

@Injectable({
  providedIn: 'root'
}) 
export class FieldDetailsService {

  public values: Array<String> = [];
  public icons: Array<SchedulerLink> = [];
  public metadataDetails: MetadataDetails;
  public data: LpMeta;
  public test: string = 'defaut';
  public verb: string;
  public isDefault: boolean = true;

  constructor(private repositoryProvider: RepositoryProvider,
              private formStackService: FormStackService,
              private alocproProvider: AlocproProvider,
              private formatService: FormatService,
              private jsDataService: JsdataService) { }

  public async buildRightDetail(isDefaultDetail: boolean = true, verb?: string, ky?: string, customColumns?: Array<any>, customData?: LpMeta
    , customTitle?: string): Promise<any> {
      this.metadataDetails = null;
      this.values = [];
      this.isDefault = isDefaultDetail;
      this.verb = verb
      if (isDefaultDetail) {
        return this.buildDefaultDetail(verb, ky, customData);
      } else {
        this.buildCustomDetails(customColumns, customData, customTitle)
      }
  }

  public async getDetailInfoStructure(verb: string): Promise<MetadataDetails> {
      let metadataDetails: MetadataDetails =
      await this.repositoryProvider.getMetadataDetails(verb, this.formStackService.currentApplicationItem.ecrId.toString());
      return metadataDetails;
  }

  public async getData(verb: string, ky: string, headers?: any): Promise<Boolean> {
    try {
      this.data =  await this.alocproProvider.find(verb, ky, true, headers);
      return true;
    } catch (error) {
        this.data = null;
    }
      return false;
  }

  private getLpMetaFromKey(data: LpMeta, key: string, type: number): any {
      let final: string;
      if (key.indexOf('.') === -1) {
          final = data[key];
      } else {
          let temp: any = data;
          for (let i: number = 0; i < key.split('.').length; i++) {
              temp = this.jsDataService.getDataRef(data, key);
          }
          final = temp;
      }
      return this.formatService.formatValue(type, final);
  }

  private async buildDefaultDetail(verb: string, ky: string, customData?: any): Promise<Boolean> {
      this.metadataDetails = await this.getDetailInfoStructure(verb)
      if (await this.getData(this.metadataDetails.path.toString(), ky, [{prop: 'noerroralert', value: 'true'}])) {
        this.values = [];
        _.each(this.metadataDetails.columns, (k: MetadataDetailsColumns) => {
            let t: string = this.getLpMetaFromKey((!Util.isNullOrUndefined(customData)) ? customData : this.data, k.prop.toString(), k.type);
            //if (!Util.isNullOrUndefined(t)) {
                this.values.push(t)
            //}
        });
        return true;
      } else {
        this.values = null;
        return false;
      }

  }

  private async buildCustomDetails(customArray: Array<any>, customData: LpMeta, customTitle: string): Promise<void> {
      this.metadataDetails = {title: customTitle, id: '0', path: '', columns: customArray}
      _.each(this.metadataDetails.columns, (k: MetadataDetailsColumns) => {
          this.values.push(this.getLpMetaFromKey(customData, k.prop.toString(), k.type))
      })
  }
}
