import { Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { UiSyncService } from 'app/services/ui-sync.service';
import { LpMeta } from 'app/meta/meta';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'app/services/modal.service';
import { EvalService, MAIN, ON_INIT } from 'app/services/eval.service';
import { FormStackService } from 'app/services/form-stack.service';
import { LpInspectionPOST } from 'app/meta/inspection/inspection-post';
import { MenuProvider } from 'app/providers/menu.provider';
import { BreadcrumbItem } from 'app/models/breadcrumb-item';
import { BreadcrumbInfoItem } from 'app/models/breadcrumb-info-item';
import { Util } from 'app/statics/utils';
import { CustomLabelsService } from 'app/services/custom-labels.service';
import { INSPECTION } from 'app/services/router.service';
import { FormService } from 'app/services/root/form.service';
import { InputField,
   INPUT_FIELD_TYPE_LABEL, INPUT_FIELD_TYPE_SEPARATOR, INPUT_FIELD_TYPE_TEXT, INPUT_FIELD_TYPE_NUMBER,
   INPUT_FIELD_TYPE_CURRENCY, INPUT_FIELD_TYPE_DROPDOWN, INPUT_FIELD_TYPE_ZOOM, INPUT_FIELD_TYPE_DATETIME,
   INPUT_FIELD_TYPE_DATE, INPUT_FIELD_TYPE_GAUGE, INPUT_FIELD_TYPE_BOOLEAN, INPUT_FIELD_TYPE_HTML } from 'app/models/input-field';
import { LpModalComponent } from 'app/ui/lp-modal/lp-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JsdataService } from 'app/services/jsdata.service';
import { ConfigService } from 'app/services/config.service';
import { LpModalChangeComponent } from 'app/ui/lp-modal/lp-modal-change/lp-modal-change.component';
import { ChangeService } from 'app/services/change.service';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { ZoomService } from 'app/services/zoom.service';
import { LpDuplicateLpMeta } from 'app/meta/duplicate-lpmeta';
import { UserHistoryService } from 'app/services/user-history.service';

@Component({
  selector: 'lp-application-item-actions',
  templateUrl: './application-item-actions.component.html'
})
export class ApplicationItemActionsComponent extends LpModalComponent implements OnInit, OnDestroy {
  public INPUT_FIELD_TYPE_LABEL: String = INPUT_FIELD_TYPE_LABEL;
  public INPUT_FIELD_TYPE_HTML: String = INPUT_FIELD_TYPE_HTML;
  public INPUT_FIELD_TYPE_SEPARATOR: String = INPUT_FIELD_TYPE_SEPARATOR;
  public INPUT_FIELD_TYPE_TEXT: String = INPUT_FIELD_TYPE_TEXT;
  public INPUT_FIELD_TYPE_NUMBER: String = INPUT_FIELD_TYPE_NUMBER;
  public INPUT_FIELD_TYPE_CURRENCY: String = INPUT_FIELD_TYPE_CURRENCY;
  public INPUT_FIELD_TYPE_DROPDOWN: String = INPUT_FIELD_TYPE_DROPDOWN;
  public INPUT_FIELD_TYPE_ZOOM: String = INPUT_FIELD_TYPE_ZOOM;
  public INPUT_FIELD_TYPE_DATETIME: String = INPUT_FIELD_TYPE_DATETIME;
  public INPUT_FIELD_TYPE_DATE: String = INPUT_FIELD_TYPE_DATE;
  public INPUT_FIELD_TYPE_GAUGE: String = INPUT_FIELD_TYPE_GAUGE;
  public INPUT_FIELD_TYPE_BOOLEAN: String = INPUT_FIELD_TYPE_BOOLEAN;

  // dataForm du rootform
  public dataForm: LpMeta;
  // Affichage bouton submit
  public hasFields: Boolean = false;

  // Configuration de l'écran application item action
  public objectSend: LpMeta =  new LpMeta();
  private arrayClassesElements: Map<String, String> = new Map<String, String>();
  // TODO SUB

  constructor(
    private configService: ConfigService,
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public uiSyncService: UiSyncService,
    public alocproProvider: AlocproProvider,
    public translate: TranslateService,
    public modalService: ModalService,
    public evalService: EvalService,
    public formStackService: FormStackService,
    private menuProvider: MenuProvider,
    private customLabelService: CustomLabelsService,
    private formService: FormService,
    private jsdataService: JsdataService,
    private changeService: ChangeService,
    private metaFactory: MetaFactoryService,
    private zoomService: ZoomService,
    private userHstoryService: UserHistoryService) {
      super(dialogRef, data);
      this.dataForm = this.formStackService.currentData;
      
    }

  public async ngOnInit(): Promise<void> {
    this.initObjectSend();
    await this.evalService.executeContrainte(ON_INIT, MAIN, this.formStackService.currentApplicationItem.ecrId.toString(), "action_" +  this.data.rootFormAction.title,
    this.objectSend, null, null, this.formStackService.currentData);
    await this.setCss();
    await this.setDefaultValue();
    this.formStackService.setCurrentActionData(this.objectSend);
    await this.emitLoadObject();
  }

  public initObjectSend(): void {
    this.objectSend = this.metaFactory.getLpMetaFromProp(this.data.rootFormAction.fields.map(p => p.prop).filter(p=> !Util.isNullOrUndefined(p))); 
    if (Util.isNullOrUndefined(this.objectSend)) {
      this.objectSend = new LpMeta();
    }
    this.objectSend.id = this.formStackService.currentData.id;
  } 

  public ngOnDestroy(): void {
     this.formStackService.clearCurrentActionData();
  }

  public async save(): Promise<void> {
    let lpMeta: LpMeta;
    if (this.data.rootFormAction['noBody']) { lpMeta = null; } else { lpMeta = this.objectSend; }
    this.uiSyncService.loader(true, true, this.translate.instant('general.modalService.updateInProgress'));
    if (!Util.isNullOrUndefined(this.data.rootFormAction.scriptEval)) {
     this.scriptBeforeExec(this.data.rootFormAction.scriptEval, this.formStackService.currentData);
     lpMeta = this.formStackService.currentData;
    }
    if (this.data.rootFormAction.method === 'DELETE') {
      await this.deleteAction(this.uiSyncService.formatURL(this.data.rootFormAction.url, this.formStackService.currentData), this.data);
    } else if (this.data.rootFormAction.method === 'PUT') {
      await this.putAction(this.uiSyncService.formatURL(this.data.rootFormAction.url, this.formStackService.currentData), lpMeta, this.data)
    } else if (this.data.rootFormAction.method === 'POST') { // POST
      await this.postAction(this.uiSyncService.formatURL(this.data.rootFormAction.url, this.formStackService.currentData), lpMeta, this.data);
    } else if (this.data.rootFormAction.method.startsWith('INSPECTION-')) { // Inspection départ / arrivée / intermédiaire
      await this.inspection();
    } else if (this.data.rootFormAction.method === 'DUPLICATE') {
      this.uiSyncService.loader(true, true, this.translate.instant('general.modalService.duplicationInProgress'));
      await this.duplicate() ;
    } else {
      this.uiSyncService.loader(false);}
  }

  public execafterBindEval(event: any, afterBindEval: String): void {
    if (afterBindEval) {
      this.evalService.eval(afterBindEval, this.objectSend, null, this.dataForm);
    }
  }

  public setDataRef(path: any, value: any): any {
    return this.jsdataService.setDataRef(this.objectSend, path, value);
  }

  public getFieldName(field:InputField): string {
    return "action_"+(this.data.rootFormAction.name ? this.data.rootFormAction.name : this.data.rootFormAction.title)+"."+field.prop;
  }

  private async afterPostPutDelete(): Promise<void> {
    try {
      this.uiSyncService.loader(false);
      this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
      this.translate.instant('general.modalService.success'));
      this.close();
      this.formService.build(this.formStackService.CurrentVerb, this.formStackService.CurrentKy, this.formStackService.stack.size, false);
    } catch (error) {
      throw error;
    }
  }

  private async childFormLink(verb: String, ky: String): Promise<void> {
    // vérifier ordre
    await this.formStackService.addStackIem(null, null, verb.toString(), ky.toString());
    await this.formService.build(verb.toString(), ky.toString(), null, false);
  }

  private async setDefaultValue() : Promise<any> {
    for (let field of this.data.rootFormAction.fields) {
      if (field.defaultValue) {
        let value: any = field.defaultValue;
        if (typeof value === 'object' ) {
          this.objectSend[field.prop] = Object.assign({}, value);
        } else {
          this.objectSend[field.prop] = value;
        }
      } else if (field.defaultValueEval) {
        await this.evalService.eval('data.'+ field.prop.toString() + " = " + field.defaultValueEval, 
        this.objectSend, field.prop.toString(),null, null,  this.formStackService.currentData);
        this.objectSend[field.prop] = this.objectSend[field.prop.toString()]
      }
      if (field.prop && !this.objectSend[field.prop.toString()] && field.type === 'gauge') {
        this.objectSend[field.prop] = this.configService.get('defaultFuelGauge');
      }
    }
  }

  private async emitLoadObject(): Promise<void> {
    try {
      return this.uiSyncService.loadObject();
    } catch (error) {
      throw error;
    }
  }

  private async setCss(): Promise<void> {
    try {
      this.arrayClassesElements.set('defaultLabel', this.configService.get('constanteClasses').DEFAULT_LABEL);
      this.arrayClassesElements.set('defaultInput', this.configService.get('constanteClasses').DEFAULT_INPUT);
      this.arrayClassesElements.set('defaultPostcode', this.configService.get('constanteClasses').DEFAULT_POSTE_CODE);
    } catch (error) {
      throw error;
    }
  }

  private async playNextStep(): Promise<boolean> {
    try {
      if (this.changeService.getIsLastChanged() && this.changeService.getIsLastChanged() === true) {
        const result: boolean = await this.modalService.modalPromise(LpModalChangeComponent,{​
            backdropClass:'alertBackdropClass',
            id : 'modalWithHeader'
          }​
      );
        if (result && result === true) {
          this.changeService.clearAndReinit();
        }
        return result;
      } else {
        return true;
      }
    } catch (error) {
      throw error;
    }
  }

  private async scriptBeforeExec(scriptEval: String, currentData: LpMeta): Promise<void> {
    await this.evalService.eval(scriptEval, currentData, null);
  }

  private async deleteAction(deleteUrl: string, data: any): Promise<void> {
    try {
      //await this.alocproProvider.delete(this.uiSyncService.formatURL(this.data.rootFormAction.url, this.formStackService.currentData), null);
      await this.alocproProvider.delete(deleteUrl, null);
      this.addEventInUserHistory();
      if (!Util.isNullOrUndefined(data.rootFormAction.urlNavigate) && !Util.isNullOrUndefined(data.rootFormAction.idNavigate)) {
        this.close();
        await this.childFormLink(data.rootFormAction.urlNavigate, this.uiSyncService.formatURL('{{' + data.rootFormAction.idNavigate + '}}', this.formStackService.currentData));
      } else {
        await this.afterPostPutDelete();
      }
    }catch(e){
      this.uiSyncService.loader(false);
    }
  }

  private async putAction(url: string, dataToPut: LpMeta, data: any): Promise<void>{
    try{
      //this.alocproProvider.put(this.uiSyncService.formatURL(this.data.rootFormAction.url, this.formStackService.currentData), null, lpMeta)
      await this.alocproProvider.put(url, null, dataToPut);
      this.addEventInUserHistory();
      if (!Util.isNullOrUndefined(data.rootFormAction.urlNavigate) && !Util.isNullOrUndefined(data.rootFormAction.idNavigate)) {
        this.close();
        this.childFormLink(data.rootFormAction.urlNavigate,
          this.uiSyncService.formatURL('{{' + data.rootFormAction.idNavigate + '}}', this.formStackService.currentData));
      } else {
        await this.afterPostPutDelete();
      }
    } catch(error){
      this.uiSyncService.loader(false);
    }
  }

  private async postAction(url: string, dataToPost: LpMeta, data: any): Promise<void>{
  try{
    //this.alocproProvider.post(this.uiSyncService.formatURL(this.data.rootFormAction.url, this.formStackService.currentData), lpMeta)
    let id: string = await this.alocproProvider.post(url, dataToPost);
    this.addEventInUserHistory();
    if (!Util.isNullOrUndefined(data.rootFormAction.urlNavigate)) {
      this.close();
      if (!Util.isNullOrUndefined(data.rootFormAction.idNavigate)) {
        await this.childFormLink(data.rootFormAction.urlNavigate,this.uiSyncService.formatURL('{{' + data.rootFormAction.idNavigate + '}}', this.formStackService.currentData));
      } else {
        await this.childFormLink(data.rootFormAction.urlNavigate, id);
      }
    } else {
      await this.afterPostPutDelete();
    }
  } catch(error) {
    this.uiSyncService.loader(false);
  }
}

  private async inspection(): Promise<void>{
    try {
      let verb: String = this.formStackService.currentApplicationItem.verb;
      let relativeKey: String;
      let field: String;
      let object: String;
      switch (verb) {
        case 'short-term-contracts':
          relativeKey = this.dataForm['movement'].id;
          field = 'F570KY';
          object = 'F570MVT';
          break;
        case 'equipments':
          relativeKey = this.dataForm.id;
          field = 'F090KY';
          object = 'F090PARC';
          break;
        case 'movements':
          relativeKey = this.dataForm.id;
          field = 'F570KY';
          object = 'F570MVT';
          break;
        default:
      }
      let inspection: LpInspectionPOST = new LpInspectionPOST(relativeKey, field, object, this.data.rootFormAction.method.replace('INSPECTION-', ''));
      let ky: String = await this.alocproProvider.post(this.uiSyncService.formatURL(this.data.rootFormAction.url, this), inspection);
      // Récupération de l'icon correspondant au verb
      let res: BreadcrumbItem = await this.menuProvider.getBreadcrumb(verb)
      let infos: BreadcrumbInfoItem[] = [];
      let value: string = '';
      let type: Number;
      if (res.columns) {
        for (let columnsProperty of res.columns) {
          if (!Util.isNullOrUndefined(this.dataForm)) {
            value = this.customLabelService.displayLabel(columnsProperty, this.dataForm);
            infos.push(new BreadcrumbInfoItem(columnsProperty.title, value, type));
          }
        }
      }
      this.formStackService.isStackLink = true;
      this.uiSyncService.noTtestFormChange = true;
      this.uiSyncService.showTransition = false;
      this.close();
      await this.childFormLink(INSPECTION, ky);
    }catch(error)  {
      this.uiSyncService.loader(false);
    }
  }

  private async duplicate(): Promise<void> {
    if (await this.playNextStep() && await this.playNextStep() === true) {
      this.uiSyncService.loader(true);
      try {
        let sourceObject: LpDuplicateLpMeta = new LpDuplicateLpMeta().assign(this.formStackService.currentData);
        let targetObject: LpDuplicateLpMeta = new LpDuplicateLpMeta().assign(this.objectSend);
  
        await this.alocproProvider.ftDuplication(this.data.ky, sourceObject, targetObject);
  
        this.modalService.success(this.translate.instant('general.modalService.recordingDone'), this.translate.instant('general.modalService.success'));
  
        //On met à jours le formulaire principale avec les informations du formulaire de l'action      
        this.formStackService.currentData.assign(this.objectSend);
        this.zoomService.refreshZoomDisplayValue();
  
        //On fait un GET sur les lignes du formulaires pour afficher la grille à droite
        this.uiSyncService.executeFilterFunction();   
        this.uiSyncService.loader(false);
        this.close();
      } catch (error) {
        this.modalService.error(this.translate.instant('general.modalService.duplicationFailed'));
        this.uiSyncService.loader(false);
      }      
    }
  }

  private addEventInUserHistory(): void {
    this.userHstoryService.addEvent('action', this.formStackService.currentApplicationItem, this.formStackService.currentData.id, this.formStackService.currentApplicationItem.verb, null, this.translate.instant('rootFormActions.'+this.data.rootFormAction.title+'.title'));
  }
}
