import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable({
providedIn: 'root'
})

export class ConfigService {

    private appConfig: Object;
    private http : HttpClient;

    constructor(http: HttpClient) {
        this.http = http;
    }

    public async initAppConfig(): Promise<void> {
        const jsonConfig = './config.json';
        return lastValueFrom(this.http.get(jsonConfig))
          .then((config: Object) => {
            this.appConfig = config;
          })
          .catch((err: Error) => {
            console.error(`Config loading error : ${err.message}`);
          });
      }
    
      public get(key: string): any {
        if (this.appConfig.hasOwnProperty(key)) {
          return this.appConfig[key];
        } else {
          console.error(`Unknown config key ${key}`);
          return null;      
        }
      }

      public getInArray(key: string, id: string): any {
        if (
          this.appConfig.hasOwnProperty(key) && 
          this.appConfig[key].length > 0) {
            for (let index = 0; index < this.appConfig[key].length; index++) {
              if (this.appConfig[key][index].id === id) {
                return this.appConfig[key][index].link;
              }
            }
            return null;
        } else {
          return null;      
        }
      }

}
