
<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" mainZoom="mainZoom" titleTranslationKey="ft.ft"></lp-top-banner>

<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">
  <!--  bloc permettant de faire un drag & drop pour modifier la taille des colonnes -->
  <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''" (dblclick)="reInitCols()"></div>
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>

  <div class="container container-2-cols left" #containerCols >
    <div class="card-box col-lg-3 col-md-4 label-2-colonnes">
      <div class="form-group col-12">
        <lp-common-tools *ngIf="formStackService.currentApplicationItem.useJsonEditForm && formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="ky"
          [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="false" 
          [showPrintInput]="false"></lp-common-tools>
        <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
      </div>
      <button (click)="filter()" class="technical-ft-search-btn"><i class="icon vega-search" *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></i></button>
    </div>
    <div class="col-md-6 card-box col-lg-9 col-md-8 ps-3 pe-3 panel-group" #col2>
      <lp-right-col></lp-right-col>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>