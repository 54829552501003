import { Injectable } from '@angular/core';
import { LpAccounting } from 'app/meta/accounting';
import { LpAccountingEvent } from 'app/meta/accounting-event';
import { LpAdditionalDriver } from 'app/meta/additional-driver';
import { LpAddress } from 'app/meta/address';
import { LpCircumstanceLink } from 'app/meta/circumstance-link';
import { LpCompany } from 'app/meta/company';
import { LpContact } from 'app/meta/contact';
import { LpCrashLink } from 'app/meta/crash-link';
import { LpDamage } from 'app/meta/damage';
import { LpDeposit } from 'app/meta/deposit';
import { LpEquipment } from 'app/meta/equipment';
import { LpEquipmentsRelation } from 'app/meta/equipments-relation';
import { LpExample } from 'app/meta/example';
import { LpExamplesDetail } from 'app/meta/examples-detail';
import { LpExpense } from 'app/meta/expense';
import { LpFacturationLink } from 'app/meta/facturation-link';
import { LpFinancialElementsLinks } from 'app/meta/financial-elements-links';
import { LpFine } from 'app/meta/fine';
import { FtMeta } from 'app/meta/ft-meta';
import { LpHistoricalMileage } from 'app/meta/historical-mileage';
import { LpInspection } from 'app/meta/inspection/inspection';
import { LpInvoice } from 'app/meta/invoice';
import { LpKindsLink } from 'app/meta/kinds-link';
import { LpLine } from 'app/meta/line';
import { LpLinkDamageHistoricalOperation } from 'app/meta/link-damage-historical-operation';
import { LpLinkDamageValue } from 'app/meta/link-damage-value';
import { LpLocation } from 'app/meta/location';
import { LpLocationsSectors } from 'app/meta/locations-sectors';
import { LpLocationsSituation } from 'app/meta/locations-situation';
import { LpLocationsType } from 'app/meta/locations-type';
import { LpMainAccount } from 'app/meta/main-account';
import { LpMandate } from 'app/meta/mandates';
import { LpMemo } from 'app/meta/memo';
import { LpMeta } from 'app/meta/meta';
import { LpModel } from 'app/meta/model';
import { LpMovement } from 'app/meta/movement';
import { OpeningHour } from 'app/meta/opening-hour';
import { LpPaymentMethod } from 'app/meta/payment-method';
import { LpPricing } from 'app/meta/pricing';
import { LpPricingDetail } from 'app/meta/pricing-detail';
import { LpPricingMethod } from 'app/meta/pricing-method';
import { LpPricingTime } from 'app/meta/pricing-time';
import { LpQuickNote } from 'app/meta/quick-quote';
import { LpRegistration } from 'app/meta/registration';
import { LpReports } from 'app/meta/reports';
import { LpRib } from 'app/meta/rib';
import { SchedulerFilter } from 'app/meta/scheduler/scheduler-filter';
import { LpService } from 'app/meta/service';
import { LpShortTermContract } from 'app/meta/short-term-contract';
import { LpTechnicalSpecification } from 'app/meta/technical-specification';
import { LpThirdPartiesRelation } from 'app/meta/third-parties-relation';
import { LpThirdParty } from 'app/meta/third-party';
import { LpThirdPartyLink } from 'app/meta/third-party-link';
import { LpThirdPartyType } from 'app/meta/third-party-type';
import { LpUser } from 'app/meta/user';
import { LpZipCode } from 'app/meta/zip-codes';
import { LpZoom } from 'app/meta/zoom';
import { LpIHubCheckPlusSettings } from 'app/meta/ihubcheckplussettings';

@Injectable({
    providedIn: 'root'
})

export class MetaFactoryService {

    public getLpMetaFromProp(props: Array<string>): LpMeta {
        if (props && props.length > 0){
            let temp: LpMeta = new LpMeta();
            props.map((p: string) => {
                temp[p] = null;
            });
            return temp;
        } else {
            return null;
        }
        
    }

    public getRessource(r: String): LpMeta {
        if (r === 'addresses' || r === 'driverAddresses' || r === 'customerAddresses') {
            return new LpAddress();
        } else if (r === 'companies') {
            return new LpCompany();
        } else if (r === 'damages') {
            return new LpDamage();
        } else if (r === 'equipments') {
            return new LpEquipment();
        } else if (r === 'expenses') {
            return new LpExpense();
        } else if (r === 'fines') {
            return new LpFine();
        } else if (r === 'inspections') {
            return new LpInspection();
        } else if (r === 'invoices') {
            return new LpInvoice();
        } else if (r === 'locations') {
            return new LpLocation();
        } else if (r === 'main-accounts') {
            return new LpMainAccount();
        } else if (r === 'models') {
            return new LpModel();
        } else if (r === 'movements') {
            return new LpMovement();
        } else if (r === 'payment-methods') {
            return new LpPaymentMethod();
        } else if (r === 'services') {
            return new LpService();
        } else if (r === 'third-parties') {
            return new LpThirdParty();
        }  else if (r === 'customers') {
            return new LpThirdParty();
        }  else if (r === 'drivers') {
            return new LpThirdParty();
        } else if (r === 'users') {
            return new LpUser();
        } else if (r === 'short-term-contracts') {
            return new LpShortTermContract();
        } else if (r === 'quick-quotes') {
            return new LpQuickNote();
        } else if (r === 'examples') {
            return new LpExample();
        } else if (r === 'pricing-details') {
            return new LpPricingDetail();
        } else if (r === 'pricing-times') {
            return new LpPricingTime();
        } else if (r === 'planning') {
            return new SchedulerFilter();
        } else if (r === 'pricing-methods') {
            return new LpPricingMethod();
        } else if (r === 'accountingEvents' || r === 'driverAccountingEvents' || r === 'customerAccountingEvents') {
            return new LpAccountingEvent();
        } else if (r === 'accountings' || r === 'driverAccountings' || r === 'customerAccountings' ) {
            return new LpAccounting();
        } else if (r === 'additionalDrivers') {
            return new LpAdditionalDriver();
        } else if (r === 'circumstanceLinks') {
            return new LpCircumstanceLink();
        } else if (r === 'contacts') {
            return new LpContact();
        } else if (r === 'crashLinks') {
            return new LpCrashLink();
        } else if (r === 'deposits' || r === 'thirdPartiesDeposits' || r === 'driverthirdPartiesDeposits' || r === 'customerthirdPartiesDeposits' || r === 'customerDeposits') {
            return new LpDeposit();
        } else if (r === 'equipmentsRelations') {
            return new LpEquipmentsRelation();
        } else if (r === 'facturationLinks') {
            return new LpFacturationLink();
        } else if (r === 'financialElementsLinks') {
            return new LpFinancialElementsLinks();
        } else if (r === 'historicalMileages') {
            return new LpHistoricalMileage();
        } else if (r === 'invoiceLines' || r === 'shortTermContractsInvoiceLines' || r === 'customerInvoices' || r === 'shortTermContractsDriverInvoiceLines' || r === 'driverInvoices') {
            return new LpLine();
        } else if (r === 'kindsLinks') {
            return new LpKindsLink();
        } else if (r === 'linkDamageHistoricalOperations') {
            return new LpLinkDamageHistoricalOperation();
        } else if (r === 'linkDamageValue') {
            return new LpLinkDamageValue();
        } else if (r === 'locationContacts' || r === 'customerContacts' || r === 'driverContacts') {
            return new LpContact();
        } else if (r === 'openingHours') {
            return new OpeningHour();
        } else if (r === 'registrations') {
            return new LpRegistration();
        } else if (r === 'thirdPartiesRelations' || r === 'customerRelations' || r === 'driverRelations') {
            return new LpThirdPartiesRelation();
        } else if (r === 'ribs') {
            return new LpRib();
        } else if (r === 'thirdPartiesMandates') {
            return new LpMandate();
        } else if (r === 'customerMandates') {
            return new LpMandate();
        } else if (r === 'driverMandates') {
            return new LpMandate();
        } else if (r === 'shortTermContractsMedias') {
            return new LpMeta();
        } else if (r === 'technicalSpecifications') {
            return new LpTechnicalSpecification();
        } else if (r === 'thirdPartyLinks') {
            return new LpThirdPartyLink();
        } else if (r === 'thirdPartyTypes' || r === 'customerTypes' || r === 'driverTypes') {
            return new LpThirdPartyType();
        } else if (r === 'examples') {
            return new LpExample();
        } else if (r === 'pricings') {
            return new LpPricing();
        } else if (r === 'zip-codes') {
            return new LpZipCode();
        } else if (r === 'reports') {
            return new LpReports();
        } else if (r === 'zooms') {
            return new LpZoom();
        } else if (r === 'locationsSituations') {
            return new LpLocationsSituation();
        } else if (r === 'locationsTypes') {
            return new LpLocationsType();
        } else if (r === 'locationsSectors') {
            return new LpLocationsSectors();
        } else if (r === 'iHubCheckPlusSettings') {
            return new LpIHubCheckPlusSettings();
        } else if (r === 'technical/attachments/') {
            return new LpMemo();
        } else if (r === 'events') {
            return new LpMeta();
        } else if (r === 'insurance-policies') {
            return new LpMeta();
        } else if (r === 'dashboard') {
            return new LpMeta();
        } else if (r === 'driverRibs') {
            return new LpMeta();
        } else if (r === 'driverTypes') {
            return new LpMeta();
        } else if (r === 'companiesLocations') {
            return new LpMeta();
        }  else if (r === 'technical/ft' || r.search('technical/ft') === 0) {
            return new FtMeta();
        } else {
            console.warn('type ' + r + ' not yet implemented')
            return new LpMeta();
        }
    }

    public getRessourceFromObject(r: LpMeta): LpMeta {
        if (r instanceof LpAddress) {
            return new LpAddress();
        } else if (r instanceof LpCompany) {
            return new LpCompany();
        } else if (r instanceof LpDamage) {
            return new LpDamage();
        } else if (r instanceof LpEquipment) {
            return new LpEquipment();
        } else if (r instanceof LpExpense) {
            return new LpExpense();
        } else if (r instanceof LpFine) {
            return new LpFine();
        } else if (r instanceof LpInspection) {
            return new LpInspection();
        } else if (r instanceof LpInvoice) {
            return new LpInvoice();
        } else if (r instanceof LpLocation) {
            return new LpLocation();
        } else if (r instanceof LpMainAccount) {
            return new LpMainAccount();
        } else if (r instanceof LpModel) {
            return new LpModel();
        } else if (r instanceof LpMovement) {
            return new LpMovement();
        } else if (r instanceof LpPaymentMethod) {
            return new LpPaymentMethod();
        } else if (r instanceof LpService) {
            return new LpService();
        } else if (r instanceof LpThirdParty) {
            return new LpThirdParty();
        } else if (r instanceof LpUser) {
            return new LpUser();
        } else if (r instanceof LpShortTermContract) {
            return new LpShortTermContract();
        } else if (r instanceof FtMeta) {
            return new FtMeta();
        } else if (r instanceof LpQuickNote) {
            return new LpQuickNote();
        } else if (r instanceof LpExample) {
            return new LpExample();
        } else if (r instanceof LpPricingDetail) {
            return new LpPricingDetail();
        } else if (r instanceof LpPricingTime) {
            return new LpPricingTime();
        } else if (r instanceof SchedulerFilter) {
            return new SchedulerFilter();
        } else if (r instanceof LpPricingMethod) {
            return new LpPricingMethod();
        } else if (r instanceof LpAccountingEvent) {
            return new LpAccountingEvent();
        } else if (r instanceof LpAccounting) {
            return new LpAccounting();
        } else if (r instanceof LpAdditionalDriver) {
            return new LpAdditionalDriver();
        } else if (r instanceof LpAddress) {
            return new LpAddress();
        } else if (r instanceof LpCircumstanceLink) {
            return new LpCircumstanceLink();
        } else if (r instanceof LpContact) {
            return new LpContact();
        } else if (r instanceof LpCrashLink) {
            return new LpCrashLink();
        } else if (r instanceof LpDeposit) {
            return new LpDeposit();
        } else if (r instanceof LpEquipmentsRelation) {
            return new LpEquipmentsRelation();
        } else if (r instanceof LpFacturationLink) {
            return new LpFacturationLink();
        } else if (r instanceof LpFinancialElementsLinks) {
            return new LpFinancialElementsLinks();
        } else if (r instanceof LpHistoricalMileage) {
            return new LpHistoricalMileage();
        } else if (r instanceof LpLine) {
            return new LpLine();
        } else if (r instanceof LpKindsLink) {
            return new LpKindsLink();
        } else if (r instanceof LpLinkDamageHistoricalOperation) {
            return new LpLinkDamageHistoricalOperation();
        } else if (r instanceof LpLinkDamageValue) {
            return new LpLinkDamageValue();
        } else if (r instanceof LpContact) {
            return new LpContact();
        } else if (r instanceof OpeningHour) {
            return new OpeningHour();
        } else if (r instanceof LpRegistration) {
            return new LpRegistration();
        } else if (r instanceof LpThirdPartiesRelation) {
            return new LpThirdPartiesRelation();
        } else if (r instanceof LpRib) {
            return new LpRib();
        } else if (r instanceof LpMandate) {
            return new LpMandate();
        } else if (r instanceof LpTechnicalSpecification) {
            return new LpTechnicalSpecification();
        } else if (r instanceof LpThirdPartyLink) {
            return new LpThirdPartyLink();
        } else if (r instanceof LpThirdPartyType) {
            return new LpThirdPartyType();
        } else if (r instanceof LpExamplesDetail) {
            return new LpExamplesDetail();
        } else if (r instanceof LpPricing) {
            return new LpPricing();
        } else if (r instanceof LpZipCode) {
            return new LpZipCode();
        }  else {
            console.error('type ' + r + ' not yet implemented')
        }
    }

    public getResourceFromDetails(detail: string): LpMeta {
        if (detail.indexOf('/') > 0){
            let temp: Array<String> = detail.split('/')
            return this.getRessource(temp[1]);
        }
    }
}
