import {Injectable} from '@angular/core';
import { Util } from 'app/statics/utils';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
  })
export class LoggerService {

    constructor(private userService: UserService) {}

    public log(title: string, object?: any, object2?: any): void {
        if (!Util.isNullOrUndefined(this.userService.getCurrentUser())
        && !Util.isNullOrUndefined(this.userService.getCurrentUser().preference)
        && !Util.isNullOrUndefined(this.userService.getCurrentUser().preference.isLogActive)
        && this.userService.getCurrentUser().preference.isLogActive) {
            if (!this.userService.getCurrentUser().preference.isLogVerbose) {
                console.log(title);
            } else {
                console.log(title, object, object2);
            }
        }
    }

    public error(title: string, object?: any, object2?: any): void {
        if (this.userService.getCurrentUser()
        && !Util.isNullOrUndefined(this.userService.getCurrentUser().preference)
        && !Util.isNullOrUndefined(this.userService.getCurrentUser().preference.isLogActive)
        && this.userService.getCurrentUser().preference.isLogActive) {
            if (!Util.isNullOrUndefined(this.userService.getCurrentUser()) && !this.userService.getCurrentUser().preference.isLogVerbose) {
                console.error(title);
            } else {
                console.error(title, object, object2);
            }
        }
    }
}
