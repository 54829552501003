import { OnDestroy, Component, OnInit } from '@angular/core';
import { UiSyncService } from '../../services/ui-sync.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { Util } from 'app/statics/utils';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'lp-ui-zoom-txt',
  templateUrl: './lp-ui-zoom-txt.component.html'
})
export class LpUiZoomTxtComponent implements OnInit, OnDestroy {
  public txt: String;
  public height: number;

  private subscription: Subscription;
  constructor(protected uiSyncService: UiSyncService, private http: HttpClient) {}

  public ngOnInit(): void {
    this.subscription = this.uiSyncService.displayZoomTxtEvtEmitter.subscribe((pdfString: String) => {
      this.height = window.innerHeight;
      lastValueFrom(this.http.get(pdfString.toString(), {responseType: 'text'})).then((txt: String) => {
        this.txt = txt;
      });
    });
  }

  public ngOnDestroy(): void {
    if (!Util.isNullOrUndefined(this.subscription)) { this.subscription.unsubscribe(); }
  }

  public hideZoom(): void {
    this.txt = undefined;
  }
}
