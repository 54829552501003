
import { LpMeta, propertyMeta } from './meta';
import { FtMeta } from './ft-meta';
import { Type } from 'class-transformer';
import { LpCompany } from './company';
import { LpProductAccount } from './product-account';

export class LpService extends LpMeta {
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('boolean')
    public isActive: Boolean;
    @Type(() => FtMeta)
    public kind: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public calculationMode: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public level: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public family: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public vatCode: FtMeta = new FtMeta();
    @Type(() => LpProductAccount)
    public productAccount: LpProductAccount = new LpProductAccount();
    @propertyMeta('number')
    public quantity: number;
    @propertyMeta('number')
    public quantityA: number;
    @propertyMeta('number')
    public secondQuantity: number;
    @propertyMeta('number')
    public unitPrice: number;
    @propertyMeta('boolean')
    public isWithoutTax: Boolean;
    @Type(() => LpCompany)
    public company: LpCompany = new LpCompany();
    @propertyMeta('string')
    public directorWord: String;
    @propertyMeta('string')
    public ean: String;
    @Type(() => FtMeta)
    public warrantyType: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public movementBehavior: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public hourType: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public subHourType: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public fuelType: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public startPosition: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public returnPosition: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public position: FtMeta = new FtMeta();
    @propertyMeta('string')
    public order: String;
    @Type(() => FtMeta)
    public preventiveCalculationMode: FtMeta = new FtMeta();
}
