import { Injectable, EventEmitter } from '@angular/core';
import { FormMetadata } from 'app/models/form-metadata';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { FormMetadataValues } from 'app/models/form-metadata-values';

@Injectable({
    providedIn: 'root'
})

export class FormMetadataProvider {

    public formMetadataEventEmitter: EventEmitter<FormMetadataValues> = new EventEmitter();
    public metadata: Map<string, FormMetadata> = new Map();

    constructor(public aloc: AlocproProvider) {}

    public async getApiMetadata(verb: string): Promise<Array<FormMetadata>> {
        if (this.metadata.size > 0) {
            this.metadata.clear();
        }
        try {
            let data: Array<FormMetadata> = await this.aloc.getMetadata(verb)
                /*data.forEach((element: FormMetadata) => {
                    if (!Util.isNullOrUndefined(element)) {
                        if (!loadDefaultValue && !Util.isNullOrUndefined(element.defaultValue)) {
                            delete element.defaultValue;
                        }
                        this.metadata.set(element.name, element);
                    }
                });*/
            return data;
        } catch(e){
            console.error(e);
            throw e;
        }
    }

    public getDetailMetadata(verb: string, ky: string, details: string): Promise<Array<FormMetadata>> {
        return new Promise<Array<FormMetadata>>((resolve, reject) => {
                this.aloc.getDetailsMetadata(verb, ky, details).then((data: Array<FormMetadata>) => {
                resolve(data);
            }).catch((e) => {
                reject(e);
            });
        });
    }

    /*public emitFormMetadataLoaded(formMetadataValues: FormMetadataValues = {'field': '*', 'fromPlanning': false}): void {
        this.formMetadataEventEmitter.emit(formMetadataValues);
    }*/
}
