import { Component, OnInit, Inject } from '@angular/core';
import * as _ from 'underscore'
import { LpModalComponent } from '../lp-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormStackService } from 'app/services/form-stack.service';
import { ConfigService } from 'app/services/config.service';
import { LpLinks } from 'app/meta/links';
import { Util } from 'app/statics/utils';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'lp-modal-links-preview',
  templateUrl: './lp-modal-links-preview.component.html'
})
export class LpModalLinksPreviewComponent extends LpModalComponent implements OnInit {
  public arrayClassesElements: Map<String, String> = new Map<String, String>();
  public linkId: number;
  public link: LpLinks = new LpLinks();
  public sanitizedUrl: any;

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formstackService: FormStackService,
    private configService: ConfigService,
    private domSanitizer: DomSanitizer
  ) {
    super(dialogRef, data);
  }

  public async ngOnInit(): Promise<void> {
    this.arrayClassesElements.set('defaultLabel', this.configService.get('constanteClasses').DEFAULT_LABEL);
    this.arrayClassesElements.set('defaultInput', this.configService.get('constanteClasses').DEFAULT_INPUT);
    this.initURL();
  }

  public initURL(): void {
    if (!Util.isNullOrUndefined(this.dialogRef) &&
    !Util.isNullOrUndefined(this.dialogRef._containerInstance) &&
    !Util.isNullOrUndefined(this.dialogRef._containerInstance._config &&
    !Util.isNullOrUndefined(this.dialogRef._containerInstance._config['link']))) {
      this.link = this.dialogRef._containerInstance._config['link'];
      this.linkId = this.dialogRef._containerInstance._config['link'].id;
      this.sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.dialogRef._containerInstance._config['link'].url)
    }
  }

}
