import { Injectable } from '@angular/core';
import { AlocproProvider } from './alocpro.provider';
import { Util } from 'app/statics/utils';
import { HttpParams} from '@angular/common/http';
import { FtMeta } from 'app/meta/ft-meta';
import { PaginatedData } from 'app/models/paginated-data';

const INSPECTION_DAMAGES_CATEGORIES: string = 'inspection-damage-categories';
@Injectable({
  providedIn: 'root'
})
export class InspectionProvider {

    /**
   * L'évènement refreshWorkflowListEvtEmitter est utilisé lorsque l'utilisateur à fait une mise à jour sur la liste des workflows.
   */
  protected mapCache: Map<String, any> = new Map;
  constructor( private alocproProvider: AlocproProvider) {

  }

  public find(verb: String, ky: String, noPrefix?: boolean): Promise<FtMeta> {
    if (this.existInCache(verb)) {
        return new Promise<FtMeta>((resolve) => {
            resolve(this.mapCache.get(verb));
        });
    } else {
        return new Promise<FtMeta>((resolve, reject) => {
            this.alocproProvider.find(verb, ky, noPrefix).then((resp: FtMeta) => {
                this.setDataInCache(resp, verb);
                resolve(resp);
            }).catch((err) => {
                reject(err);
            });
        });
    }
  }

  public getPaginatedData(path: String, page?: Number, searched?: Map<string, string>, noPrefix?: boolean, limit?: Number)
  : Promise<PaginatedData> {
  let p: HttpParams = new HttpParams();
  if (!Util.isNullOrUndefined(searched)) {
    for (let key of Array.from(searched)) {
      p = p.set(key[0], key[1]);
    }
  }

  if (!Util.isNullOrUndefined(page)) {
    p = p.set('_page', page.toString());
    if (!Util.isNullOrUndefined(limit)) {
      p = p.set('_limit', limit.toString());
    }
  } else if (Util.isNullOrUndefined(limit)) {
    p = p.set('_limit', 'NOLIMIT');
  }
  let fullPath: String = path;
  if (p.keys().length > 0) {
    fullPath = `${fullPath}?${p.toString()}`;
  }
  if (this.existInCache(fullPath)) {
    return new Promise<PaginatedData>((resolve) => {
      resolve(this.mapCache.get(fullPath));
    });
  } else {
    return new Promise<PaginatedData>((resolve, reject) => {
        this.alocproProvider.getPaginatedData(path, page, searched, noPrefix, limit).then((resp: PaginatedData) => {
            this.setDataInCache(resp, fullPath);
            resolve(resp);
        }).catch((err) => {
            reject(err);
        });
    });
  }
}

public async loadDamageCategories(): Promise<any> {
  return new Promise<PaginatedData>((resolve, reject) => {
    this.alocproProvider.getPaginatedData(INSPECTION_DAMAGES_CATEGORIES, null, null, null, null).then((resp: PaginatedData) => {
        this.setDataInCache(resp, INSPECTION_DAMAGES_CATEGORIES);
        resolve(resp);
    }).catch((err) => {
        reject(err);
    });
});
}

  private existInCache(path: String): boolean {
    if (Util.isNullOrUndefined(path)) {
      return false;
    } else {
      return this.mapCache.has(path);
    }
  }

  private setDataInCache(data: any, path: String): void {
    this.mapCache.set(path, data);
  }

}
