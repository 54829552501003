import { LpMeta, propertyMeta } from './meta';
import { LpEquipment } from './equipment';
import { LpThirdParty } from './third-party';
import { LpAccountingPlan } from './accounting-plan';
import { LpLocation } from './location';
import { LpPaymentMethod } from './payment-method';
import { FtMeta } from './ft-meta';
import { LpAddress } from './address';
import { LpCurrency } from './currency';
import { LpModel } from './model';
import { Type } from 'class-transformer';

export class LpInvoice extends LpMeta {
    @propertyMeta('string')
    public documentNumber: String;
    @propertyMeta('date')
    public date: Date;
    @propertyMeta('date')
    public termDate: Date;
    @Type(() => FtMeta)
    public termPaymentType:FtMeta = new FtMeta();
    @Type(() => LpEquipment)
    public equipment: LpEquipment = new LpEquipment();
    @Type(() => LpLocation)
    public location: LpLocation = new LpLocation();
    @Type(() => LpThirdParty)
    public thirdParty: LpThirdParty = new LpThirdParty();
    @Type(() => LpPaymentMethod)
    public paymentMethod: LpPaymentMethod = new LpPaymentMethod();
    @Type(() => LpAccountingPlan)
    public accountingPlan: LpAccountingPlan // Will come back in back
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('string')
    public billingNumber: String;
    @propertyMeta('date')
    public billingDate: Date;
    @propertyMeta('number')
    public excludingTaxAmount: Number;
    @propertyMeta('number')
    public excludingTaxAmountCurrency: Number;
    @propertyMeta('number')
    public vatAmount: Number;
    @propertyMeta('number')
    public vatAmountCurrency: Number;
    @propertyMeta('number')
    public includingTaxAmount: Number;
    @propertyMeta('number')
    public includingTaxAmountCurrency: Number;
    @propertyMeta('number')
    public mileage: Number;
     @Type(() => FtMeta)
    public accountingType:FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public status: FtMeta = new FtMeta();
    @Type(() => FtMeta)
    public documentType: FtMeta = new FtMeta();
    @Type(() => LpAddress)
    public mainAddress: LpAddress = new LpAddress();
    @Type(() => LpModel)
    public model: LpModel = new LpModel();
    @Type(() => LpCurrency)
    public currency: LpCurrency = new LpCurrency();
    @Type(() => LpCurrency)
    public currencyDoc: LpCurrency = new LpCurrency();
    public groupingEvent: string;
    public type : string
}
