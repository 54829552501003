import { BreadcrumbInfoItem } from './breadcrumb-info-item';
import { LpMeta } from 'app/meta/meta';
import { ApplicationItem } from './application-item';
import { FormMetadata } from './form-metadata';
import { ApplicationItemDetail } from './application-item-detail';

/*export class StackItem {
    public key: String;
    public verb: String;
    public prop: String;
    // pousser les data du planning dans this.data
    public data: any;
    public applicationItem: ApplicationItem
    public applicationItemData: Array<LpMeta>;
    public schedulerConfig: SchedulerConfig;
    public icon: String;
    public infos: BreadcrumbInfoItem[];
    public idRootFormdetail: String;
    public dataRootFormdetail: any;
    public isMainData: Boolean = true;
    public propertyToAdd: Array<any>;
    public valueToAdd: Array<any>;
    public meta: LpMeta;
    public infosSupp: String;

    constructor(key: String, verb: String, prop: String,
        data: any, icon: String, infos: BreadcrumbInfoItem[], idRootFormdetail: String, meta: LpMeta, dataRootFormdetail?: any,
        isMainData?: Boolean, applicationItem?: ApplicationItem, applicationItemData?: any) {
        this.key = key;
        this.verb = verb;
        this.prop = prop;
        this.data = data;
        this.applicationItem = applicationItem
        this.applicationItemData = applicationItemData;
        this.icon = icon;
        this.infos = infos;
        this.idRootFormdetail = idRootFormdetail;
        this.dataRootFormdetail = dataRootFormdetail;
        this.meta = meta;
        if (isMainData !== undefined) {
            this.isMainData = isMainData;
        }
    }
}
*/
export class StackItem {
    public currentVerb: string;
    public currentKy: string;
    public currentApplicationItem: ApplicationItem
    public currentApplicationItemDetailData:  Array<LpMeta>
    public currentApplicationItemDetail: ApplicationItemDetail;
    public currentApplicationItemDetailDataCount: Number;
    public currentMetadata: Map<string, FormMetadata>;
    public currentData: LpMeta;
    public currentInfosBreadcrumb: BreadcrumbInfoItem[] = [];
    public bindingProp: String;
    public nextData: LpMeta
    public currentDetailLineData: LpMeta;
    public usePreviousAPIConfig: boolean;

    constructor(currentVerb: string, currentKy: string, currentApplicationItem: ApplicationItem,
        currentApplicationItemDetailData:  Array<LpMeta>, currentApplicationItemDetailDataCount: Number,
        currentApplicationItemDetail: ApplicationItemDetail,
        currentMetadata: Map<string, FormMetadata>, currentData: LpMeta, nextData: LpMeta, bindingProp?: String,
        currentDetailLineData?: LpMeta, usePreviousAPIConfig?: boolean) {
            this.currentVerb = currentVerb;
            this.currentKy = currentKy;
            this.currentApplicationItem = currentApplicationItem;
            this.currentApplicationItemDetailData = currentApplicationItemDetailData
            this.currentApplicationItemDetailDataCount = currentApplicationItemDetailDataCount
            this.currentApplicationItemDetail = currentApplicationItemDetail;
            this.currentMetadata = currentMetadata;
            this.currentData = currentData;
            this.bindingProp = bindingProp;
            this.nextData = nextData;
            this.currentDetailLineData = currentDetailLineData;
            this.usePreviousAPIConfig = usePreviousAPIConfig;
    }
}
