import { Component, OnInit, Inject } from '@angular/core';
import { ModalService } from 'app/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { RepositoryProvider } from 'app/providers/repository.provider';
import { AlocproProvider } from 'app/providers/alocpro.provider';
import { UserService } from 'app/services/user.service';
import { Preference, UserInterface } from 'app/models/user.interface';
import { ApplicationItemProvider } from 'app/providers/application-item.provider';
import * as _ from 'underscore'
import { LpModalComponent } from '../lp-modal.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BulkService } from 'app/services/bulk.service';
import { Util } from 'app/statics/utils';
import { ReportsProvider } from 'app/providers/reports.provider';
import { ZoomProvider } from 'app/providers/zoom.provider';
import { ConfigService } from 'app/services/config.service';
import { DashboardProvider } from 'app/providers/dashboard.provider';
import { FormStackService } from 'app/services/form-stack.service';
import { LpModalPromiseAlertComponent } from '../lp-modal-alert/lp-modal-alert.component';

@Component({
  selector: 'lp-modal-preferences',
  templateUrl: './lp-modal-preferences.component.html'
})
export class LpModalPreferencesComponent extends LpModalComponent implements OnInit {
  public dataPreference: Preference;
  public arrayClassesElements: Map<String, String> = new Map<String, String>();
  public availablesLinesPerPage: any;
  public ecranIdArray: Array<any>;
  public ecranNameArray: Array<any>;
  public showSavePanel: Boolean = false;

  constructor(
    public dialogRef: MatDialogRef<LpModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalService: ModalService,
    private repositoryProvider: RepositoryProvider,
    private alocproProvider: AlocproProvider,
    private userService: UserService,
    private translate: TranslateService,
    private applicationItemProvider: ApplicationItemProvider,
    private bulkService: BulkService,
    private reportProvider: ReportsProvider,
    private zoomProvider: ZoomProvider,
    private configService: ConfigService,
    private dashboardProvider: DashboardProvider,
    private formstackService: FormStackService
  ) {
    super(dialogRef, data);
  }

  public async ngOnInit(): Promise<void> {
    this.availablesLinesPerPage = this.configService.get('availablesLinesPerPage');
    this.arrayClassesElements.set('defaultLabel', this.configService.get('constanteClasses').DEFAULT_LABEL);
    this.arrayClassesElements.set('defaultInput', this.configService.get('constanteClasses').DEFAULT_INPUT);
    this.arrayClassesElements.set('defaultPostcode', this.configService.get('constanteClasses').DEFAULT_POSTE_CODE);
    this.translate.get('short-term-contracts.shortTermContract').subscribe(async (text: string) => {
      this.applicationItemProvider.getApplicationItemsScreens().then(d1 => {
        this.ecranIdArray = d1;
        _.each(this.ecranIdArray, (d2 => {
          d2.wording = this.translate.instant(d2.wording);
        }))
      });
      this.applicationItemProvider.getApplicationItemsVerbs().then(d3 => {
        this.ecranNameArray = d3;
        _.each(this.ecranNameArray, (d4 => {
            d4.wording = this.translate.instant(d4.wording);
        }))
      });
    });

    this.repositoryProvider.getPreferences().then((preference: Preference) => {
      this.dataPreference = preference;
      this.dataPreference.user = this.userService.getCurrentUser().username;
    });
  }

  public clearCache(): void {
    this.repositoryProvider.clearCache();
    this.alocproProvider.clearCache();
    this.bulkService.clearCache();
    this.zoomProvider.clearMapZoomForDropDown();
    this.reportProvider.clearCache();
    this.modalService.success(this.translate.instant('general.modalService.clearCacheOK'),
      this.translate.instant('general.modalService.clearCache'));
  }

  public async clearBackEndCache(): Promise<void> {
    await this.alocproProvider.clearBackendCache()
    await this.reportProvider.clearParametersCache();
    this.modalService.success(this.translate.instant('general.modalService.clearCacheOK'), this.translate.instant('general.modalService.clearCache'));
  }

  public async savePreference(): Promise<void> {
    if (this.dataPreference.user !== this.userService.getCurrentUser().username) {
      this.dataPreference = await this.repositoryProvider.getPreferences();
    }
    this.repositoryProvider.updateUserPreferences(this.dataPreference).then((preference: Preference) => {
      this.dataPreference = preference;
      this.userService.setCurrentUser(this.updateLogInformation(preference));
      this.modalService.success(this.translate.instant('general.modalService.recordingDone'),
      this.translate.instant('general.modalService.success'));
      this.showSavePanel = false;
    }).catch((error) => {
      this.modalService.error(this.translate.instant('general.modalService.recordingFailed'),
        this.translate.instant('general.modalService.error'));
    });
  }

  public toggleSavePanel(): void {
    if (this.userService.isAdmin()) {
      this.showSavePanel = !this.showSavePanel;
    } else {
      this.savePreference();
    }
  }

  public saveConfig(id: string): void {
    if (Util.isNullOrUndefined(id)) {
      this.showSavePanel = false;
    } else {
      const jsonData: any = JSON.parse('{' + id + ' "other":""}');
      this.dataPreference.user = jsonData.user;
      this.dataPreference.profile = jsonData.profile;
      this.dataPreference.location = jsonData.location;
      this.dataPreference.company = jsonData.company;
      this.savePreference();
    }
  }

  private updateLogInformation(data: Preference): UserInterface {
    let user: UserInterface = this.userService.getCurrentUser();
    user.preference.isLogActive = data.isLogActive;
    user.preference.isLogVerbose = data.isLogVerbose;
    user.preference.linesPerPage = data.linesPerPage;
    return user;
  }

  public async saveDashboard(): Promise<void> {
    try {
      await this.dashboardProvider.putMyDashboardConfiguration(String(this.formstackService.dashboard.id), this.formstackService.dashboard);
      this.modalService.success(this.translate.instant('general.modalService.recordingDone'), this.translate.instant('general.modalService.success'));
    } catch (error) {
      throw error;
    }
  }

  public openInfoModal(line1: string, line2: string) {
    this.modalService.modalPromise(LpModalPromiseAlertComponent, {
      backdropClass: 'alertBackdropClass',
      data: {
        line1: line1,
        line2: line2
      }
    });
  }

}
