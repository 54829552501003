<div class="modal-header">
  <h4 class="modal-title pull-left">{{'AddCompany.title' | translate}}</h4>

  <div class="pull-right">
    <lp-button-cancel (action)="close()"></lp-button-cancel>
  </div>
</div>
<div class="modal-body">
    <div class="card-box">
      <form #form="ngForm" ngNativeValidate>
        <div class="container-fluid">
          <lp-ui-accordion class="row" [menu]=true>

            <lp-ui-accordion-group heading="AddCompany.company" [open]=true key="infos">
              <div class="container">
                <div class="row">
                  <div class="form-group col-12 m-0">
                    <lp-text-field [required]="true" id="companycorporateName" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true"
                      cssClass="form-control" name="companycorporateName" [(ngModel)]="dataForm.company.corporateName" label="AddCompany.corporateName"
                      type="text"></lp-text-field>
                  </div>
                  <!--<div class="form-group col-12 m-0">
                    <lp-dropdown-field [required]="true" id="companyvatDomain" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"  [ignoreChange]="true"
                      cssClass="form-control" name="companyvatDomain" [(ngModel)]="dataForm.company.vatDomain"  label="AddCompany.vatDomain"
                      [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'vatDomain'}]}"></lp-dropdown-field>
                  </div>-->
                  <div class="form-group col-12 m-0">
                    <lp-text-field [required]="true" id="companyaddress1" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true"
                      cssClass="form-control" name="companyaddress1" [(ngModel)]="dataForm.company.address1" label="AddCompany.address1"
                      (focusout)="changeProperty(dataForm.company.address1, 'address1')"
                      type="text"></lp-text-field>
                  </div>
                  <div class="form-group col-3">
                    <lp-text-field [required]="true" id="companypostcode" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true"
                      cssClass="form-control" name="companypostcode" [(ngModel)]="dataForm.company.postcode" label="AddCompany.zipCode"
                      (focusout)="changeProperty(dataForm.company.postcode, 'zipCode')"
                      type="text"></lp-text-field>
                  </div>
                  <div class="form-group col-9">
                    <lp-text-field [required]="true" id="companycity" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true"
                      cssClass="form-control" name="companycity" [(ngModel)]="dataForm.company.city" label="AddCompany.city"
                      (focusout)="changeProperty(dataForm.company.city, 'city')"
                      type="text"></lp-text-field>
                  </div>
                </div>
              </div>
            </lp-ui-accordion-group>

            <lp-ui-accordion-group heading="AddCompany.manager" [open]=true key="infos">
              <div class="container">
                <div class="row">
                  <div class="form-group col-12 m-0">
                    <lp-text-field [required]="true" id="locationManagerFirstName" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true"
                      cssClass="form-control" name="locationManagerFirstName" [(ngModel)]="dataForm.manager.firstName" label="AddCompany.manager.firstName"
                      type="text"></lp-text-field>
                  </div>
                  <div class="form-group col-12 m-0">
                    <lp-text-field [required]="true" id="locationManagerLastName" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true"
                      cssClass="form-control" name="locationManagerLastName" [(ngModel)]="dataForm.manager.lastName" label="AddCompany.manager.lastName"
                      type="text"></lp-text-field>
                  </div>
                </div>
              </div>
            </lp-ui-accordion-group>

            <lp-ui-accordion-group heading="AddCompany.location" [open]=true key="infos">
              <div class="container">
                <div class="row">
                  <div class="form-group col-12 m-0">
                    <lp-text-field [required]="true" id="locationwording" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true"
                      cssClass="form-control" name="locationwording" [(ngModel)]="dataForm.location.wording" label="AddCompany.wording"
                      type="text"></lp-text-field>
                  </div>
                  <div class="form-group col-12 m-0">
                    <lp-text-field [required]="true" id="locationaddress1" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true"
                      cssClass="form-control" name="locationaddress1" [(ngModel)]="dataForm.location.mainAddress.address1" label="AddCompany.address1"
                      type="text"></lp-text-field>
                  </div>
                  <div class="form-group col-3">
                    <lp-text-field [required]="true" id="locationpostcode" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true"
                      cssClass="form-control" name="locationpostcode" [(ngModel)]="dataForm.location.mainAddress.zipCode" label="AddCompany.zipCode"
                      type="text"></lp-text-field>
                  </div>
                  <div class="form-group col-9">
                    <lp-text-field [required]="true" id="locationcity" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true"
                      cssClass="form-control" name="locationcity" [(ngModel)]="dataForm.location.mainAddress.city" label="AddCompany.city"
                      type="text"></lp-text-field>
                  </div>
                </div>
              </div>
            </lp-ui-accordion-group>

            <lp-ui-accordion-group heading="AddCompany.users" [open]=true key="infos">
              <ng-container *ngFor="let user of dataForm.additionnalUsers; let i = index">
                <div class="row align-items-end">
                  <div class="col-11">
                    <div clas="row">
                      <div class="form-group col-6 m-0">
                        <lp-text-field [required]="true" id="users{{i}}firstName" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true"
                          cssClass="form-control" name="users{{i}}firstName" [(ngModel)]="user.thirdParty.firstName" label="AddCompany.manager.firstName"
                          type="text"></lp-text-field>
                      </div>
                      <div class="form-group col-6 m-0">
                        <lp-text-field [required]="true" id="users{{i}}lastName" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" [ignoreChange]="true"
                          cssClass="form-control" name="users{{i}}lastName" [(ngModel)]="user.thirdParty.lastName" label="AddCompany.manager.lastName"
                          type="text"></lp-text-field>
                      </div>
                    </div>
                  </div>
                  <div class="col-1">
                    <a class="btn btn-danger" (click)="removeUser(i);">
                      <i class="fa fa-trash"></i>
                    </a>
                  </div>                  
                </div>
                <hr />
              </ng-container>                
              <div class="row">
                <div class="form-group col-12 m-0">
                  <a class="btn btn-outline-primary" (click)="addUser();">
                    <i class="fa fa-plus"></i> {{'AddCompany.addUser' | translate}}
                  </a>
                </div>
              </div>
            </lp-ui-accordion-group>

            <div class="text-end">
              <button (click)="save()" type="submit"><i class="icon vega-diskette" title="{{'general.button.save' |translate}}"></i></button>
            </div>

            <lp-ui-accordion-group heading="Debug" [open]=true key="general" >
              <pre>{{dataForm | json}}</pre>
            </lp-ui-accordion-group>
          </lp-ui-accordion>

        </div>
      </form>
    </div>
</div>
