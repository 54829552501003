<lp-ui-accordion id="{{id}}">
    <lp-ui-accordion-group heading="metadataApplicationItemDetails.equipmentsRelations.titleTab" [open]=true>
      <form id="formRelationsEquipments" #formMyComponent="ngForm" (ngSubmit)="submit()" ngNativeValidate>

        <div class="form-group col-xl-12">
          <lp-label-field for="facturationLinks.relatedInvoice" class="{{arrayClassesElements.get('defaultLabel')}}"
          text="damages.relatedInvoice"></lp-label-field>
          <lp-zoom-field id="facturationLinks.relatedInvoice"  zoomId="facturationLinks.invoice" name="facturationLinks.relatedInvoice"
          class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.relatedInvoice"
          className="zoomField form-control">
          </lp-zoom-field>
        </div>
      
        <div class="text-end">
          <button *ngIf="!formStackService.isDataReadOnly()" class="valid-detailForm"><i class="vega-diskette"></i></button>
        </div>
      </form>
    </lp-ui-accordion-group>
    <lp-ui-accordion-group heading="Debugger Mon Composant" *ngIf="debugg">
      <div>
        <pre>{{ data | json }}</pre>
      </div>
    </lp-ui-accordion-group>
  </lp-ui-accordion>