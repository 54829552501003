import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LpDuplicateLpMeta } from 'app/meta/duplicate-lpmeta';
import { LpPricingDetail } from 'app/meta/pricing-detail';
import { ZoomMeta } from 'app/meta/zoom/zoomMeta';
import { FormMetadata } from 'app/models/form-metadata';
import { UserInterface } from 'app/models/user.interface';
import { ConfigService } from 'app/services/config.service';
import { MetaFactoryService } from 'app/services/meta-factory.service';
import { Observable } from 'rxjs';
import { Util } from 'app/statics/utils';
import { LpMeta } from '../meta/meta';
import { PaginatedData, PaginatedDataWithHEader } from '../models/paginated-data';
import { UserService } from '../services/user.service';
import { HttpProvider } from './http.provider';
import { Pricing } from 'app/meta/pricings/pricings';
import { GeneralParameters } from 'app/models/general-parameters';

// const LOGOUT_URL: string = 'users/me/logout';
const LOCPRO_COUNT: String = 'X-Total-Count';
export const BEARER_AUTH: string = 'BEARER';
const LOCPRO_READONLY: String = 'x-vega-readonly';
const LOCPRO_WORKFLOWS_COUNT: String = 'x-vega-workflows-count';
const LOCPRO_EDIS_COUNT: String = 'x-vega-edis-count';
const LOCPRO_ATTACHMENTS_COUNT: String = 'x-vega-attachments-count';
const NO_BODY: String = 'noBody=true';
const ACTIVATION_STR: String = '/activation';
const DEACTIVATION_STR: String = '/deactivation';
const ATTACHMENTS_STR: String = 'technical/attachments';
const METADATA: string = 'metadata';
const TECHNICAL_CACHE: string = 'technical/cache';
const CACHE_TYPE: string = 'message';
const CACHE_TYPE_INSPECTION: string = 'inspection';
const CACHE_TYPE_REPORT: string = 'report';
const TECHNICAL_SCRIPT: string = 'technical/scripts';
const DEDUCTIBLE_BUYBACKS: string = 'deductible-buybacks';
export const PRICING_DETAILS: string = 'pricing-details';

@Injectable({
  providedIn: 'root',
})
export class AlocproProvider {

  public mapCache: Map<String, any> = new Map;
  private languages: Map<string, string> = new Map();

  constructor(protected http: HttpProvider,
              protected userService: UserService,
              private metaFactoryService: MetaFactoryService,
              private configService: ConfigService) {
  }

  public init(): void {
    this.http.addHeader('X-VEGA-token', '');
    this.http.addHeader('Content-Type', 'application/json');
    this.http.addHeader('Accept-Language', this.configService.get('requestOptionsHeaders').acceptLanguage);
    this.http.addHeader('X-VEGA-version', this.configService.get('requestOptionsHeaders').xVEGAversion);
    this.http.addHeader('X-VEGA-app', this.configService.get('requestOptionsHeaders').xVEGAapp);
    this.http.addHeader('X-VEGA-serial', this.configService.get('requestOptionsHeaders').xVEGAserial);
    if (
      this.configService.get('requestOptionsHeaders').xVEGAcontext !== undefined 
      && this.configService.get('requestOptionsHeaders').xVEGAcontext !== null
      && this.configService.get('requestOptionsHeaders').xVEGAcontext !== ""  ) {
        this.http.addHeader('X-VEGA-context', this.configService.get('requestOptionsHeaders').xVEGAcontext);
    }
    this.http.addHeader('X-GYZMO-Api-Key', '');
    this.languages.set('fr', '001');
    this.languages.set('en', '002');
  }

  public clearCache(): void {
    this.mapCache.clear();
  }

  public putActivate(path: String, ky: String): Promise<void> {
    this.checkIdentificationData();
    return new Promise<void>((resolve, reject) => {
      this.http.httpPut(`${ this.configService.get('alocproServiceUrl') }${ path }/${ ky }${ ACTIVATION_STR }`).then(() => {
        resolve();
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public putDeductibleBuyBacks(path: String, ky: String, mode: string): Promise<void> {
    this.checkIdentificationData();
    return new Promise<void>((resolve, reject) => {
      this.http.httpPut(`${ this.configService.get('alocproServiceUrl') }${ path }/${ ky }/${ DEDUCTIBLE_BUYBACKS }?mode=${ mode }`)
        .then((data: any) => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public putDeactivate(path: String, ky: String): Promise<void> {
    this.checkIdentificationData();
    return new Promise<void>((resolve, reject) => {
      this.http.httpPut(`${ this.configService.get('alocproServiceUrl') }${ path }/${ ky }${ DEACTIVATION_STR }`).then(() => {
        resolve();
      }).catch((err) => {
        reject(err);
      });
    });
  }

  // a virer dans un provider planning
  public getForPlanning(path: String): Promise<PaginatedData> {
    this.checkIdentificationData();
    return new Promise<PaginatedData>((resolve, reject) => {
      this.http.httpGet(`${ path }`, null, true).then((resp: HttpResponse<any>) => {
        let data: PaginatedData = {
          count: this.http.getCountObject(resp.headers.get(LOCPRO_COUNT.toString())),
          _readonly: !Util.isNullOrUndefined(resp.headers.get(LOCPRO_READONLY.toString()))
                     ? JSON.parse(resp.headers.get(LOCPRO_READONLY.toString())) : false,
          body: resp.body,
        };
        resolve(data);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public exists(url: string): Promise<boolean> {
    this.checkIdentificationData();
    return new Promise<boolean>((resolve, reject) => {
      let p: HttpParams = new HttpParams();
      p = p.set('_limit', '1');
      p = p.set('_page', '1');
      this.http.httpGet(`${ this.configService.get('alocproServiceUrl') }${ url.toString() }`, p, true).then((resp: HttpResponse<any>) => {
        resolve(true);
      }).catch((err) => {
        resolve(false);
      });
    });
  }

  public getPaginatedData(
    path: String,
    page?: Number,
    searched?: Map<string, string>,
    noPrefix?: boolean,
    limit?: Number | string,
    order?: String,
    sort?: String,
    noerroralert?: String,
    noCach?: Boolean,
  )
    : Promise<PaginatedData> {
    let p: HttpParams = new HttpParams();
    if (!Util.isNullOrUndefined(searched)) {
      for (let key of Array.from(searched)) {
        p = p.set(key[0], key[1]);
      }
    }

    if (!Util.isNullOrUndefined(page)) {
      p = p.set('_page', page.toString());
      if (!Util.isNullOrUndefined(limit)) {
        p = p.set('_limit', limit.toString());
      }
    } else if (Util.isNullOrUndefined(limit)) {
      p = p.set('_limit', 'NOLIMIT');
    } else {
      p = p.set('_limit', limit.toString());
    }
    if (!Util.isNullOrUndefined(order)) {
      p = p.set('_order', order.toString());
    }
    if (!Util.isNullOrUndefined(sort)) {
      p = p.set('_sort', sort.toString());
    }
    if (!noPrefix) {
      path = `${ this.configService.get('alocproServiceUrl') }${ path }`;
    }
    let fullPath: String = path;
    if (p.keys().length > 0) {
      fullPath = `${ fullPath }?${ p.toString() }`;
    }
    if (this.existInCache(fullPath)) {
      return new Promise<PaginatedData>((resolve) => {
        resolve(this.mapCache.get(fullPath));
      });
    } else {
      this.checkIdentificationData();
      return new Promise<PaginatedData>((resolve, reject) => {
        this.http.httpGet(path.toString(), p, true).then((resp: HttpResponse<any>) => {
          let data: PaginatedData = {
            count: this.http.getCountObject(resp.headers.get(LOCPRO_COUNT.toString())),
            _readonly: !Util.isNullOrUndefined(resp.headers.get(LOCPRO_READONLY.toString()))
                       ? JSON.parse(resp.headers.get(LOCPRO_READONLY.toString())) : false,
            body: resp.body
          };
          if (!noCach) {
            this.setDataInCache(data, fullPath);
          }
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  /**
   *
   * @param verb
   * @param ky
   * @param noPrefix
   * @param paramList tableau d'objet (prop/value)
   */

  public getPaginatedDataEval(path: String): Promise<PaginatedData> {
    this.checkIdentificationData();
    return new Promise<PaginatedData>((resolve, reject) => {
      this.http.httpGet(`${ this.configService.get('alocproServiceUrl') }${ path }`, null, true, null
        , [{ prop: 'noerroralert', value: 'true' }]).then((resp: HttpResponse<any>) => {
        let data: PaginatedData = {
          count: this.http.getCountObject(resp.headers.get(LOCPRO_COUNT.toString())),
          _readonly: !Util.isNullOrUndefined(resp.headers.get(LOCPRO_READONLY.toString()))
                     ? JSON.parse(resp.headers.get(LOCPRO_READONLY.toString())) : false,
          body: resp.body,
        };
        resolve(data);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public getPaginatedDataEvalHeader(path: String): Promise<PaginatedDataWithHEader> {
    this.checkIdentificationData();
    return new Promise<PaginatedDataWithHEader>((resolve, reject) => {
      this.http.httpGet(`${ this.configService.get('alocproServiceUrl') }${ path }`, null, true, null
        , [{ prop: 'noerroralert', value: 'true' }]).then((resp: HttpResponse<any>) => {
        let data: PaginatedDataWithHEader = {
          count: this.http.getCountObject(resp.headers.get(LOCPRO_COUNT.toString())),
          _readonly: !Util.isNullOrUndefined(resp.headers.get(LOCPRO_READONLY.toString()))
                     ? JSON.parse(resp.headers.get(LOCPRO_READONLY.toString())) : false,
          body: resp.body,
        };
        resolve(data);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public find(verb: String, ky?: String, noPrefix?: boolean, headerList?: any): Promise<LpMeta> {
    let params: HttpParams = new HttpParams();
    let id: String = ky;
    let path: String = verb;
    if (!noPrefix) {
      path = `${ this.configService.get('alocproServiceUrl') }${ path }`;
    }
    if (!Util.isNullOrUndefined(id)) {
      if (path.endsWith('=')) {
        path = `${ path }${ id }`;
      } else {
        path = `${ path }/${ id }`;
      }
    }

    if (this.existInCache(`${ path }`)) {
      return new Promise<LpMeta>((resolve) => {
        resolve(this.mapCache.get(`${ path }`));
      });
    } else {
      this.checkIdentificationData();
      return new Promise<LpMeta>((resolve, reject) => {
        this.http.httpGet(`${ path }`, params, true, null, headerList).then((resp: HttpResponse<any>) => {
          let data: LpMeta = this.metaFactoryService.getRessource(verb);
          try {
            data.assign(resp.body);
            this.assignHeaders(data, resp);
            this.setDataInCache(data, `${ path }`);
            resolve(data);
          } catch (e) {
            throw e;
          }
        }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  public findForZoom(url: String, noPrefix?: boolean, headerList?: any): Promise<ZoomMeta> {
    let params: HttpParams = new HttpParams();
    let path: String = url;
    if (!noPrefix) {
      path = `${ this.configService.get('alocproServiceUrl') }${ url }`;
    }
    if (this.existInCache(`${ path }`)) {
      return new Promise<ZoomMeta>((resolve) => {
        resolve(this.mapCache.get(`${ path }`));
      });
    } else {
      this.checkIdentificationData();
      return new Promise<ZoomMeta>((resolve, reject) => {
        this.http.httpGet(`${ path }`, params, false, null, headerList).then((resp: ZoomMeta) => {
          this.setDataInCache(resp, `${ path }`);
          resolve(resp);
        }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  public getMetadata(verb: String, noPrefix?: boolean): Promise<Array<FormMetadata>> {
    let path: String = verb;
    if (!noPrefix) {
      path = `${ this.configService.get('alocproServiceUrl') }${ path }/${ METADATA }`;
    }
    if (this.existInCache(path)) {
      return new Promise<Array<FormMetadata>>((resolve) => {
        resolve(this.mapCache.get(path).fields);
      });
    } else {
      this.checkIdentificationData();
      return new Promise<Array<FormMetadata>>((resolve, reject) => {
        this.http.httpGet(path.toString()).then((data: any) => {
          this.setDataInCache(data, `${ path }`);
          resolve(data.fields);
        }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  public getDetailsMetadata(verb: String, ky: string, detail: string, noPrefix?: boolean): Promise<Array<FormMetadata>> {
    let path: String = verb;
    let cacheKey: string = `${ path }/${ detail }/${ METADATA }`;
    if (!noPrefix) {
      path = `${ this.configService.get('alocproServiceUrl') }${ path }/${ ky }/${ detail }/${ METADATA }`;
    }
    if (this.existInCache(cacheKey)) {
      return new Promise<Array<FormMetadata>>((resolve) => {
        resolve(this.mapCache.get(cacheKey).fields);
      });
    } else {
      this.checkIdentificationData();
      return new Promise<Array<FormMetadata>>((resolve, reject) => {
        this.http.httpGet(path.toString()).then((data: any) => {
          this.setDataInCache(data, cacheKey);
          resolve(data.fields);
        }).catch((err) => {
          reject(err);
        });
      });
    }
  }

  /*this.checkUser();
   return new Promise<LpMeta>((resolve, reject) => {
   this.httpGet(`${path}/${ky}`).then((data: LpMeta) => {
   resolve(data);
   }).catch((error: HttpErrorResponse) => {
   if (environment.verbose) {
   console.error(error);
   }
   let e: HttpError = error.error;
   reject(e);
   });
   });
   }*/

  public async getConstraint(zone: string, moment?: string, variant?: string, lpService?: boolean, screen?: string): Promise<string> {
    let url: string = `${ this.configService.get('alocproServiceUrl') }${ TECHNICAL_SCRIPT }?zone=${ zone }`;
    if (!Util.isNullOrUndefined(moment)) {
      url += `&moment=${ moment }`;
    }
    if (!Util.isNullOrUndefined(variant)) {
      url += `&variant=${ variant }`;
    }
    if (!Util.isNullOrUndefined(lpService)) {
      url += `&lpServices=${ lpService }`;
    }
    if (!Util.isNullOrUndefined(screen)) {
      url += `&screen=${ screen }`;
    }
    let b: any = await this.http.httpPost(url, null);
    if (!Util.isNullOrUndefined(b.body)) {
      return b.body.genericJsonBody;
    }
    return;
  }

  public getEncodingByPath(path: string, isBinary: boolean, params?: HttpParams, fullHttpResponse: boolean = false): Promise<ArrayBuffer> {
    this.checkIdentificationData();
    return new Promise<ArrayBuffer>((resolve, reject) => {
      this.http.httpGet(`${ this.configService.get('alocproServiceUrl') }${ path }`, params, fullHttpResponse, isBinary).then((value: ArrayBuffer) => {
        resolve(value);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public put(verb: string, ky: string, data?: any, searched?: Map<string, string>, headers?: any): Promise<void> {
    let a: Promise<void>;
    let p: HttpParams;
    let url: string = `${ this.configService.get('alocproServiceUrl') }${ verb }`;
    this.checkIdentificationData();
    // TODO PUT methode must have an empty body
    if (Util.isNullOrUndefined(data)) {
      data = {};
    }
    let searchedString: string = '';
    if (!Util.isNullOrUndefined(searched)) {
      p = new HttpParams();
      for (let key of Array.from(searched)) {
        p = p.set(key[0], key[1]);
      }
      searchedString = `?${ p.toString() }`;
    }
    if (Util.isNullOrUndefined(ky)) {
      a = this.http.httpPut(`${ url }${ searchedString }`, data, headers);
    } else {
      a = this.http.httpPut(`${ url }/${ ky }${ searchedString }`, data, headers);
    }
    return new Promise<void>((resolve, reject) => {
      a.then(() => {
        resolve();
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public bulk(verb: string, data?: any, noCatch?: Boolean): Promise<void> {
    this.checkIdentificationData();
    // TODO PUT methode must have an empty body
    if (Util.isNullOrUndefined(data)) {
      data = {};
    }
    return new Promise<void>((resolve, reject) => {
      this.http.httpPut(`${ this.configService.get('alocproServiceUrl') }${ verb }/bulk`, data).then(() => {
        resolve();
      }).catch((err) => {
        reject(err);
      });
    });
  }
  

  public ftDuplication(ky: string, sourceObject: LpDuplicateLpMeta, targetObject: LpDuplicateLpMeta): Promise<void> {
    this.checkIdentificationData();
    return new Promise<void>((resolve, reject) => {
      this.http.httpPost(
        `${ this.configService.get('alocproServiceUrl') }technical/ft/${ky}/duplication`,
        {
          sourceObject : sourceObject.optimized(),
          targetObject: targetObject.optimized()
        })
        .then((response: any) => {
          resolve();
        }).catch((err) => {
          reject(err);
        });
    });
  }

  public async clearBackendCache(): Promise<void> {
    this.checkIdentificationData();
    await this.http.httpPut(`${ this.configService.get('alocproServiceUrl') }${ TECHNICAL_CACHE }?cacheType=${ CACHE_TYPE }`);
    await this.http.httpPut(`${ this.configService.get('alocproServiceUrl') }${ TECHNICAL_CACHE }?cacheType=${ CACHE_TYPE_INSPECTION }`);
    await this.http.httpDelete(`${ this.configService.get('alocproServiceUrl') }${ TECHNICAL_CACHE }?cacheType=${ CACHE_TYPE_REPORT }`);
  }

  public delete(verb: String, ky: String, searched?: Map<string, string>, headers?: any): Promise<void> {
    let a: Promise<void>;
    let p: HttpParams;
    let url: string = `${ this.configService.get('alocproServiceUrl') }${ verb }`;
    this.checkIdentificationData();
    let searchedString: string = '';
    if (!Util.isNullOrUndefined(searched)) {
      p = new HttpParams();
      for (let key of Array.from(searched)) {
        p = p.set(key[0], key[1]);
      }
      searchedString = `${ p.toString() }`;
    }
    if (Util.isNullOrUndefined(ky)) {
      a = this.http.httpDelete(`${ url }${ searchedString }`, headers);
    } else {
      a = this.http.httpDelete(`${ url }/${ ky }${ searchedString }`, headers);
    }
    return new Promise<void>((resolve, reject) => {
      a.then(() => {
        resolve();
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public post(verb: String, data: LpMeta, searched?: Map<string, string>, headers?: any): Promise<string> {
    this.checkIdentificationData();
    return new Promise<string>((resolve, reject) => {
      let p: HttpParams;
      let url: string = `${ this.configService.get('alocproServiceUrl') }${ verb }`;
      if (!Util.isNullOrUndefined(searched)) {
        p = new HttpParams();
        for (let key of Array.from(searched)) {
          p = p.set(key[0], key[1]);
        }
        url = `${ url }?${ p.toString() }`;
      }
      this.http.httpPost(url, data, headers).then((response: any) => {
        resolve(response.headers.get('x-vega-id'));
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public getMemos(linkedObject: String, ky: String, page: number, nbPerPage: number): Promise<any> {
    this.checkIdentificationData();
    return new Promise<PaginatedData>((resolve, reject) => {
      this.getPaginatedData(`${ ATTACHMENTS_STR }?object=${ linkedObject }&key=${ ky }`, page, null, null, nbPerPage).then((data) => {
        resolve(data);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public async getTotalCount(path: String, params?: HttpParams): Promise<number> {
    this.checkIdentificationData();
    return new Promise<number>((resolve, reject) => {
      this.http.httpGet(`${ path }?${ NO_BODY }`, params, true).then((resp: HttpResponse<any>) => {
        resolve(this.http.getCountObject(resp.headers.get(LOCPRO_COUNT.toString())));
      }).catch((err) => {
        reject(err);
      });
    });
  }

  /*   public async logout(): Promise<void> {
   this.clearCache();
   this.delete(LOGOUT_URL, '')
   .then(() => {
   this.clearToken();
   Promise.resolve();
   })
   .catch(() => {
   this.clearToken();
   Promise.resolve();
   });
   }
   */

  public logout(): void {
    this.clearCache();
    this.clearToken();
  }

  /*
   public checkUser(): void {
   const currentUser: UserInterface = this.userService.getCurrentUser();
   if (!Util.isNullOrUndefined(currentUser)
   && (!Util.isNullOrUndefined(currentUser.token) || !Util.isNullOrUndefined(currentUser.accessToken))) {
   this.setToken(currentUser);
   }
   if (!Util.isNullOrUndefined(currentUser) && !Util.isNullOrUndefined(currentUser.api_key)) {
   this.setApiKey(currentUser.api_key);
   }
   this.setLanguage();
   }
   */
    public async getGeneralParameters(url: string, noCache?: boolean): Promise<Array<GeneralParameters>> {
      try {
        let baseUrl: String = `${this.configService.get('alocproServiceUrl')}${url}`;
        if ((Util.isNullOrUndefined(noCache) || !noCache) && this.existInCache(baseUrl)) {
          let temp: any = this.mapCache.get(baseUrl)
          return temp;
        } else {
          const results = await this.http.httpGet(baseUrl.toString());
          if ((Util.isNullOrUndefined(noCache) || !noCache)) {
            this.setDataInCache(results, baseUrl);
          }
          return results;
        }
      } catch (error) {
        throw error; 
      }
  }
  
  public async getPricingDetailsList(filters: LpMeta): Promise<PaginatedData> {
    try {
      let p: Map<string, string> = new Map();
      this.checkIdentificationData();
      for (let key in filters) {
        if (filters.hasOwnProperty(key) && key !== '_readOnly' && !Util.isNullOrUndefined(filters[key])) {
          if (typeof filters[key] === 'object') {
            if (!Util.isNullOrUndefined(filters[key].id)) {
              p = p.set(key, filters[key].id);
            }
          } else {
            p = p.set(key, filters[key]);
          }
        }
      }
      return await (await this.getPaginatedData(`${ this.configService.get('alocproServiceUrl') }${ PRICING_DETAILS }`,
        null,
        p,
        true,
        null));
    } catch (e) {
      throw e;
    }
    /*let records: PaginatedData = await new Promise<PaginatedData>((resolve, reject) => {
     this.http.httpGet(`${this.configService.get('alocproServiceUrl')}pricing-details`, p, true).then((resp: HttpResponse<any>) => {
     let data: PaginatedData = {
     count: this.http.getCountObject(resp.headers.get(LOCPRO_COUNT.toString())),
     body: resp.body
     };
     resolve(data);
     }).catch((err) => {
     reject(err);
     });
     })

     if (!Util.isNullOrUndefined(records.body)) {
     for (let index: number = 0; index < records.body.length; index++) {
     const line: LpMeta = records.body[index];
     let detail: LpMeta = await this.find('pricing-details', line.id);
     let detailTemp: LpPricingDetail = new LpPricingDetail();
     detailTemp.assign(detail);
     listPricingDetails.push(detailTemp);
     }
     }

     return listPricingDetails;*/
  }
  
  public async getPricing(path: String): Promise<Pricing> {
    let p: HttpParams = new HttpParams();
    path = `${ this.configService.get('alocproServiceUrl') }${ path }`;
    this.checkIdentificationData();
    return new Promise<Pricing>((resolve, reject) => {
      this.http.httpGet(path.toString(), p, true).then((resp: HttpResponse<any>) => {
        let data: Pricing = resp.body;
        resolve(data);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public async setPricingDetails(listPricingDetails: LpPricingDetail[]): Promise<void> {
    for (let index: number = 0; index < listPricingDetails.length; index++) {
      const detail: LpPricingDetail = listPricingDetails[index];
      this.checkIdentificationData();
      await this.put('pricing-details', detail.id.toString(), detail);
    }
  }

  public getResultFromSearchBar(path: String, limit?: Number): Observable<any[]> {
    let p: HttpParams = new HttpParams();
    this.checkIdentificationData();
    if (Util.isNullOrUndefined(limit)) {
      p = p.set('_limit', 'NOLIMIT');
    } else {
      p = p.set('_limit', limit.toString());
    }
    return this.http.httpGetObservable(`${ this.configService.get('alocproServiceUrl') }${ path }`, p);
  }

  public existInCache(path: String): boolean {
    if (Util.isNullOrUndefined(path)) {
      return false;
    } else {
      return this.mapCache.has(path);
    }
  }

  public checkIdentificationData(): void {
    // if (!this.identificationDataExists()) {
    this.setIdentificationData();
    // }
    if (this.languageChanges()) {
      this.setLanguage();
    }
  }

  private setIdentificationData(): void {
    if (!Util.isNullOrUndefined(this.userService.getCurrentUser())
        && (!Util.isNullOrUndefined(this.userService.getCurrentUser().token)
            || !Util.isNullOrUndefined(this.userService.getCurrentUser().accessToken))) {
      this.setToken(this.userService.getCurrentUser());
    }
    if (!Util.isNullOrUndefined(this.userService.getCurrentUser()) && !Util.isNullOrUndefined(this.userService.getCurrentUser().api_key)) {
      this.setApiKey(this.userService.getCurrentUser().api_key);
    }
  }

  private languageChanges(): boolean {
    return !Util.isNullOrUndefined(this.userService.getCurrentLang())
           && this.http.getHeaders('Accept-Language') !== this.languages.get(this.userService.getCurrentLang());
  }

  /* private identificationDataExists(): boolean {
   return !Util.isNullOrUndefined(this.http.getHeaders('X-VEGA-token'))  && this.http.getHeaders('X-VEGA-token') !== '' &&
   !Util.isNullOrUndefined(this.http.getHeaders('X-GYZMO-Api-Key'))  && this.http.getHeaders('X-GYZMO-Api-Key') !== ''
   } */

  private setToken(user: UserInterface): void {
    this.http.addHeader('X-VEGA-token', user.token);
    if (!Util.isNullOrUndefined(user.accessToken)) {
      this.http.addHeader('Authorization', `${ BEARER_AUTH } ${ user.accessToken }`);
    }
  }

  private setApiKey(api_key: String): void {
    this.http.addHeader('X-GYZMO-Api-Key', api_key);
  }

  private setLanguage(): void {
    // modification de la langue
    this.http.addHeader('Accept-Language', this.languages.get(this.userService.getCurrentLang()));
    // viadage du cache
    // this.mapCache.clear();
  }

  private setDataInCache(data: any, path: String): void {
    if (path.startsWith(`${ this.configService.get('alocproServiceUrl') }${ this.configService.get('ftPath') }`)
        || path.startsWith(`${ this.configService.get('alocproServiceUrl') }${ this.configService.get('parametersZoomPath') }`)
        || this.configService.get('urlForCache').indexOf(path.toString()) > -1 || path.endsWith('metadata')) {
      this.mapCache.set(path, data);
    }
  }

  private clearToken(): void {
    if (!Util.isNullOrUndefined(this.http.getHeaders('X-VEGA-token'))) {
      this.http.addHeader('X-VEGA-token', '');
    }
    if (!Util.isNullOrUndefined(this.http.getHeaders('Authorization'))) {
      this.http.addHeader('Authorization', '');
    }
  }

  private assignHeaders(data: any, resp: HttpResponse<any>): void {
    data._readOnly = (Util.isNullOrUndefined(resp.headers.get(LOCPRO_READONLY.toString())) ||
                      resp.headers.get(LOCPRO_READONLY.toString()) === 'false') ? false : true;
    data._xVegaWorkflowsCount = Number(resp.headers.get(LOCPRO_WORKFLOWS_COUNT.toString()));
    data._xVegaEdisCount = Number(resp.headers.get(LOCPRO_EDIS_COUNT.toString()));
    data._xVegaAttachmentsCount = Number(resp.headers.get(LOCPRO_ATTACHMENTS_COUNT.toString()));
  }
}
