import { Injectable } from '@angular/core';
import { AlocproProvider } from './alocpro.provider';
import { MenuItem } from 'app/models/user.interface';
import { RepositoryProvider } from './repository.provider';
import { HttpProvider } from './http.provider';


@Injectable({
  providedIn: 'root'
})

export class LoginProvider {

  constructor(public aloc: AlocproProvider, public repo: RepositoryProvider, public http: HttpProvider) {}

  /** La fonction getFullMenu() permet d'envoyer une requête HTTP vers le back-end.
    * Le résultat de cette fonction est utilisé lors de recherche rapide dans le menu, champ situé dans le header de l'application.
   */
  public async getFullMenu(): Promise<MenuItem[]> {
    this.aloc.checkIdentificationData();
    return new Promise<MenuItem[]>((resolve, reject) => {
      this.repo.get('menus', null, null, true).then((menu: any) => {
        resolve(menu.menuItems);
      }, (err) => {
        reject();
      });
    });
  }

  /*public async logIn(username: String, password: String): Promise<any> {
    const params: LpLogin = new LpLogin(username, password);
    this.aloc.checkUser();
    return await this.http.httpPost(this.configService.get('alocproServiceUrl') + 'users/login?details=true', params);
  }*/
}
