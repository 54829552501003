<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" titleTranslationKey="movements.movement"></lp-top-banner>

<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">
  <!--  bloc permettant de faire un drag & drop pour modifier la taille des colonnes -->
  <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''"
    (dblclick)="reInitCols()"></div>

  <!-- div à ajouter pour l'effet de transition-->
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>

  <div class="container container-2-cols left" #containerCols>
    <div class="card-box col-md-6 label-2-colonnes" #col1>
      <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
        <!--  composant pour afficher lien flottant à droite du formulaire-->
        <lp-common-tools *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id" [deleteButton]="deleteButton"
          [positionFromRight]="dragRight ? dragRight : null"
          (showPrintOutput)="changeShowZoomPrint($event)" [showPrintInput]="showZoomPrint"></lp-common-tools>

          <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
        <lp-ui-accordion class="row" [menu]=true>
          <!-- accordion general -->
          <lp-ui-accordion-group heading="movements.informations" [open]=true key="general">
            <div class="container">

              <div class="row">
                <!-- Field id-->
                <div class="form-group col-xl-6">
                  <lp-text-field id="id" className="textField"
                    class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                    name="movements.id" label="movements.id"
                    [(ngModel)]="data.id" [disabled]=true></lp-text-field>
                </div>
                <!-- field position -->
                <div class="form-group col-xl-6">
                  <!--<lp-dropdown-field id="position" class="{{arrayClassesElements.get('defaultInput')}}"
                    className="textField" cssClass="form-control" name="position" label="movements.position"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'position'}]}"
                    [(ngModel)]="data.position"></lp-dropdown-field>-->
                    <lp-zoom-field zoomId="position" id="status" name="status" cssClass="form-control" label="movements.position"
                    class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.position"> </lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <!-- Field customer-->
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="customer" class="{{arrayClassesElements.get('defaultInput')}}" label="movements.customer"
                    zoomId='customer' className="zoomField" cssClass="form-control" [(ngModel)]="data.customer" name="customer">
                  </lp-zoom-field>
                </div>
                <!--Field driver-->
                <div class="form-group col-xl-6">
                    <lp-zoom-field id="driver" class="{{arrayClassesElements.get('defaultInput')}}" label="movements.driver"
                        zoomId='driver' className="zoomField" cssClass="form-control" [(ngModel)]="data.driver" name="driver">
                    </lp-zoom-field>
                  </div>
              </div>

              <div class="row">
                <!-- field documentType -->
                <div class="form-group col-xl-6">
                  <!--<lp-dropdown-field id="documentType" class="{{arrayClassesElements.get('defaultInput')}}" label="movements.documentType"
                    className="textField" cssClass="form-control" name="documentType" tableFilterStart="F400" tableFilterEnd="F600"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'documentType'}]}"
                    [(ngModel)]="data.documentType"></lp-dropdown-field>-->
                    <lp-zoom-field zoomId="documentType" id="status" name="status" cssClass="form-control" label="movements.documentType"
                    class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.documentType"> </lp-zoom-field>
                </div>
                <div class="form-group col-xl-6">                        
                    <lp-zoom-field id="event" class="{{arrayClassesElements.get('defaultInput')}}" label="movements.event"
                        zoomId='event' className="zoomField" cssClass="form-control" [(ngModel)]="data.event" name="event">
                    </lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <!--Field equipementComment -->
                <div class="form-group col-xl-6">
                    <lp-textarea-field id="equipmentComment" className="textField" label="movements.equipmentComment"
                      class="{{arrayClassesElements.get('defaultInput')}}" cssClass="textareaField"
                      name="equipmentComment" [(ngModel)]="data.equipmentComment">
                    </lp-textarea-field>
                  </div>

                  <!-- Field isClosed -->                        
                  <div class="form-group col-xl-6">
                      <lp-boolean-switch-box name="isClosed" switchName="isClosed" [(ngModel)]="data.isClosed" label="movements.isClosed"></lp-boolean-switch-box>
                  </div>
              </div>
            </div>
          </lp-ui-accordion-group>

          <!-- Accordéon start -->
          <lp-ui-accordion-group heading="movements.start" [open]=false key="start">
            <div class="container">

              <div class="row">
                <div class="form-group col-xl-6">
                  <!--field startLocation-->
                <lp-zoom-field id="startLocation" class="{{arrayClassesElements.get('defaultInput')}}"   label="movements.startLocation"
                    zoomId='startLocation' className="zoomField" cssClass="form-control" [(ngModel)]="data.startLocation" name="movements.startLocation">
                </lp-zoom-field>
                </div>
                <!--field startDate-->
                <div class="form-group col-xl-6">
                  <lp-date-time id="startDate" cssClass="form-control" label="movements.startDate"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="startDate"
                    [(ngModel)]="data.startDate"></lp-date-time>
                </div>
              </div>

              <div class="row">
                <!--Field startPosition-->
                <div class="form-group col-xl-6">
                  <!--<lp-dropdown-field id="startPosition" class="{{arrayClassesElements.get('defaultInput')}}"
                    className="textField" cssClass="form-control" name="startPosition" label="movements.startPosition"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'position'}]}"
                    [(ngModel)]="data.startPosition"></lp-dropdown-field>-->
                    <lp-zoom-field zoomId="startPosition" id="status" name="status" cssClass="form-control" label="movements.startPosition"
                    class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.startPosition"> </lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <!--field returnLocation-->
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="returnLocation" class="{{arrayClassesElements.get('defaultInput')}}"  label="movements.returnLocation"
                      zoomId='returnLocation' className="zoomField" cssClass="form-control" [(ngModel)]="data.returnLocation" name="movements.returnLocation">
                  </lp-zoom-field>
                </div>
                <!--field returnDate-->
                <div class="form-group col-xl-6">
                  <lp-date-time id="returnDate" cssClass="form-control" label="movements.returnDate"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="returnDate"
                    [(ngModel)]="data.returnDate"></lp-date-time>
                </div>
              </div>

              <div class="row">
                <!-- field returnPosition -->
                <div class="form-group col-xl-6">
                  <<!--lp-dropdown-field id="returnPosition" class="{{arrayClassesElements.get('defaultInput')}}"
                    className="textField" cssClass="form-control" name="returnPosition" label="movements.returnPosition"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'position'}]}"
                    [(ngModel)]="data.returnPosition"></lp-dropdown-field>-->
                    <lp-zoom-field zoomId="returnPosition" id="status" name="status" cssClass="form-control" label="movements.returnPosition"
                    class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.returnPosition"> </lp-zoom-field>
                </div>
              </div>
            </div>
          </lp-ui-accordion-group>

          <!-- Accordéon stock -->
          <lp-ui-accordion-group heading="movements.equipment" [open]=false key="stock">
            <div class="row">
              <!--Field category-->
              <div class="form-group col-xl-6">
                <!--<lp-dropdown-field id="category" class="{{arrayClassesElements.get('defaultInput')}}"
                  className="textField" cssClass="form-control" name="category" label="movements.category"
                  [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'category'}]}"
                  [(ngModel)]="data.category"></lp-dropdown-field>-->
                  <lp-zoom-field zoomId="category" id="status" name="status" cssClass="form-control" label="movements.category"
                  class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.category"> </lp-zoom-field>
              </div>
              <!--Field equipement-->
              <div class="form-group col-xl-6">
                <lp-zoom-field id="equipment" class="{{arrayClassesElements.get('defaultInput')}}" label="movements.equipment"
                  zoomId='equipment' className="zoomField" cssClass="form-control" [(ngModel)]="data.equipment" name="movements.equipment" >
                </lp-zoom-field>
              </div>

                <!--Field startMileage-->
                <div class="form-group col-xl-6">
                  <lp-number id="startMileage" cssClass="form-control" label="movements.startMileage"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="startMileage"
                    [(ngModel)]="data.startMileage" minlength="1"></lp-number>
                  <lp-fuel-gauge id="startFuel" class="{{arrayClassesElements.get('defaultInput')}}" label="movements.startFuel"
                    name="startFuel" [(ngModel)]="data.startFuel"></lp-fuel-gauge>
                    <lp-textarea-field id="startComment" className="textField" label="movements.startComment"
                      class="{{arrayClassesElements.get('defaultInput')}}" cssClass="textareaField"
                      name="movements.startComment" [(ngModel)]="data.startComment">
                    </lp-textarea-field>
                  </div>
                <!--Field returnMileage-->
                <div class="form-group col-xl-6">
                  <lp-number id="returnMileage" cssClass="form-control" label="movements.returnMileage"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="returnMileage"
                    [(ngModel)]="data.returnMileage" minlength="1"></lp-number>
                  <lp-fuel-gauge id="returnFuel" class="{{arrayClassesElements.get('defaultInput')}}" label="movements.returnFuel"
                    name="returnFuel" [(ngModel)]="data.returnFuel"></lp-fuel-gauge>
                  <lp-textarea-field id="returnComment" className="textField" label="movements.comment"
                    class="{{arrayClassesElements.get('defaultInput')}}" cssClass="textareaField"
                    name="movements.returnComment" [(ngModel)]="data.returnComment">
                  </lp-textarea-field>
                </div>

            </div>

          </lp-ui-accordion-group>

          <!-- Accordéon stock -->
          <lp-ui-accordion-group heading="movements.stock" [open]=false key="stock">
            <div class="container">

              <div class="row">
                <!--Field devUnitAmountExcludingTax-->
                <div class="form-group col-xl-6">
                  <lp-number id="devUnitAmountExcludingTax" cssClass="form-control" label="movements.devUnitAmountExcludingTax"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="devUnitAmountExcludingTax"
                    [(ngModel)]="data.devUnitAmountExcludingTax" minlength="3"></lp-number>
                </div>
                <!--Field unitAmountExcludingTax-->
                <div class="form-group col-xl-6">
                  <lp-number id="unitAmountExcludingTax" cssClass="form-control" label="movements.unitAmountExcludingTax"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="unitAmountExcludingTax"
                    [(ngModel)]="data.unitAmountExcludingTax" minlength="3"></lp-number>
                </div>
              </div>

              <div class="row">
                <!--Field stockQuantity-->
                <div class="form-group col-xl-6">
                  <lp-number id="stockQuantity" cssClass="form-control" label="movements.stockQuantity"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="stockQuantity"
                    [(ngModel)]="data.stockQuantity" minlength="3"></lp-number>
                </div>
              </div>

            </div>
          </lp-ui-accordion-group>

          <!-- Accordéon various -->
          <lp-ui-accordion-group heading="movements.various" [open]=false key="various">
            <div class="container">

              <div class="row">
                <!--Field billingStartDate-->
                <div class="form-group col-xl-6">
                  <lp-date id="billingStartDate" cssClass="form-control" label="movements.billingStartDate"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="billingStartDate"
                    [(ngModel)]="data.billingStartDate"></lp-date>
                </div>
                <!--Field billingEndDate-->
                <div class="form-group col-xl-6">
                  <lp-date id="billingEndDate" cssClass="form-control" label="movements.billingEndDate"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="billingEndDate"
                    [(ngModel)]="data.billingEndDate"></lp-date>
                </div>
              </div>

              <div class="row">
                <!--Field billingQuantity-->
                <div class="form-group col-xl-6">
                  <lp-number id="billingQuantity" cssClass="form-control" label="movements.billingQuantity"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="billingQuantity"
                    [(ngModel)]="data.billingQuantity" minlength="3"></lp-number>
                </div>
                <!--Field company-->
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="company" class="{{arrayClassesElements.get('defaultInput')}}"
                      zoomId='company' className="zoomField" cssClass="form-control" [(ngModel)]="data.company"
                      name="company" label="movements.company.corporateName">
                  </lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <!--Field failurePrevention-->
                <div class="form-group col-xl-6">
                    <lp-number id="failurePrevention" cssClass="form-control" label="movements.failurePrevention"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="failurePrevention"
                    [(ngModel)]="data.failurePrevention" minlength="0"></lp-number>
                </div>
                <!--Field grouping-->
                <div class="form-group col-xl-6">
                  <lp-text-field id="grouping" className="textField" label="movements.grouping"
                    class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                    name="movements.grouping" [(ngModel)]="data.grouping"
                    [disabled]=false></lp-text-field>
                </div>
              </div>

              <div class="row">
                <!--Field lastBillingPeriodDate-->
                <div class="form-group col-xl-6">
                  <lp-date id="lastBillingPeriodDate" cssClass="form-control" label="movements.lastBillingPeriodDate"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="lastBillingPeriodDate"
                    [(ngModel)]="data.lastBillingPeriodDate">
                  </lp-date>
                </div>
                <!--Field level-->
                <div class="form-group col-xl-6">
                  <lp-number id="level" cssClass="form-control" label="movements.level"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="level"
                    [(ngModel)]="data.level" minlength="3"></lp-number>
                </div>
              </div>

              <div class="row">
                <!--Field model-->
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="model" class="{{arrayClassesElements.get('defaultInput')}}" label="movements.model"
                    zoomId='models' className="zoomField" cssClass="form-control" [(ngModel)]="data.model" name="model">
                  </lp-zoom-field>
                </div>
                <!--Field originUpdate-->
                <div class="form-group col-xl-6">
                  <lp-text-field id="originUpdate" className="textField" label="movements.originUpdate"
                    class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                    name="movements.originUpdate" [(ngModel)]="data.originUpdate"
                    [disabled]=false></lp-text-field>
                </div>
              </div>

              <div class="row">
                <!--Field packQuantity-->
                <div class="form-group col-xl-6">
                  <lp-number id="packQuantity" cssClass="form-control" label="movements.packQuantity"
                    class="{{arrayClassesElements.get('defaultInput')}}" name="packQuantity"
                    [(ngModel)]="data.packQuantity" minlength="3" >
                  </lp-number>
                </div>
                <!--Field reason-->
                <div class="form-group col-xl-6">
                    <!--<lp-dropdown-field id="reason" class="{{arrayClassesElements.get('defaultInput')}}"
                    className="textField" cssClass="form-control" name="reason" label="movements.reason"
                    [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'patternOut'}]}"
                    [(ngModel)]="data.reason"></lp-dropdown-field>-->
                    <lp-zoom-field zoomId="reason" id="status" name="status" cssClass="form-control" label="movements.reason"
                    class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.reason"> </lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <!--Field reference-->
                <div class="form-group col-xl-6">
                  <lp-text-field id="reference" className="textField" label="movements.reference"
                    class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                    name="movements.reference" [(ngModel)]="data.reference"
                    [disabled]=false></lp-text-field>
                </div>
                <!--Field registration-->
                <div class="form-group col-xl-6">
                  <lp-text-field id="registration" className="textField" label="movements.registration"
                    class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                    name="movements.registration" [(ngModel)]="data.registration"
                    [disabled]=false></lp-text-field>
                </div>
              </div>

              <div class="row">
                <!--Field service-->
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="service"  class="{{arrayClassesElements.get('defaultInput')}}"  label="movements.service"
                    zoomId='services' className="zoomField" cssClass="form-control" [(ngModel)]="data.service"
                      name="movements.service">
                  </lp-zoom-field>
                </div>
                <!--Field unit-->
                <div class="form-group col-xl-6">
                  <lp-text-field id="unit" className="textField" label="movements.unit"
                    class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                    name="movements.unit" [(ngModel)]="data.unit" [disabled]=false>
                  </lp-text-field>
                </div>
              </div>

              <div class="row">
              <!--Field relatedTables-->
              <div class="form-group col-xl-6">
                  <lp-text-field id="relatedTables.id" className="textField" label="movements.relatedTables"
                    class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control"
                    name="movements.relatedTables.id" [(ngModel)]="data.relatedTables.wording"
                    [disabled]=true></lp-text-field>
                </div>
              </div>

            </div>
          </lp-ui-accordion-group>

          <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
            <div>
              <pre>{{ data | json }}</pre>
            </div>
          </lp-ui-accordion-group>
        </lp-ui-accordion>
      </div>
        <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
      </form>
    </div>
    <div class="col-md-6 card-box" #col2>
      <lp-right-col></lp-right-col>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>