import { Component, forwardRef, Input, OnInit, Output } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { GouvAddress } from "app/meta/gouv-address";
import { LpMeta } from "app/meta/meta";
import { FormMetadataProvider } from "app/providers/form-metadata.provider";
import { GouvProvider } from "app/providers/gouvProvider";
import { ChangeService } from "app/services/change.service";
import { ConfigService } from "app/services/config.service";
//import { DataGouvApiService } from "app/services/dataGouvApiService.service";

import { EvalService } from "app/services/eval.service";
import { FormStackService } from "app/services/form-stack.service";
import { JsdataService } from "app/services/jsdata.service";
import { MetaFactoryService } from "app/services/meta-factory.service";
import { UiSyncService } from "app/services/ui-sync.service";
import { Observable } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { InputFieldComponent } from "../input-field.component";
//import {GeoApiGouvAddressService, GeoApiGouvAddressResponse} from "@placeme/ngx-geo-api-gouv-address";

@Component({
    selector: 'lp-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => AddressComponent),
        multi: true
      }
    ]
  })
  export class AddressComponent extends InputFieldComponent implements OnInit{

    public dataSource: Observable<LpMeta[]>;
    public displayValue: any = ''
    public typeaheadLoading: Boolean = false;
    @Input() public mainDataProperty: Array<String>;
    @Input() public addressDataToBind: Array<String>;
    @Input() public prop: string;
    @Input() public type: string;
    @Output() public noResult: Boolean = false;


    constructor(protected changeService: ChangeService,
                protected gouvProvider: GouvProvider,
                protected evalService: EvalService,
                public formStackService: FormStackService,
                protected formMetadataProvider: FormMetadataProvider,
                protected uiSyncService: UiSyncService,
                protected metaFactoryService: MetaFactoryService,
                protected configService: ConfigService,
                private jsDataService: JsdataService,
                ){
                  super(changeService, evalService, formStackService, formMetadataProvider, uiSyncService, metaFactoryService, configService);
    }

    public ngOnInit(): void {
      this.formStackService.currentFields.set(this.name, {name: this.name.toString(), mandatory: !!this.required, hidden: !!this.hidden, readonly: !!this.readonly})
      this.hidden = this.formStackService.getFields(this.name).hidden;
      this.required = this.formStackService.getFields(this.name).mandatory;
      this.readonly = this.formStackService.getFields(this.name).readonly;
      this.dataSource = Observable.create((observer: any) => {
        observer.next(this.displayValue);
      }).pipe(
        mergeMap((token: string) =>this.getAddress(token))
      );
    }

    public getAddress(input: string): Observable<GouvAddress[]> {
      return this.gouvProvider.query(input, this.type);
    }

    public typeaheadOnSelect(event: any): void {
      this.mainDataProperty.forEach((element: string, index: number) => {
        this.jsDataService.setDataRef(this.formStackService.currentData, element, this.jsDataService.getDataRef(event.item, this.addressDataToBind[index]))  ;
      });
      this.setDisplayValue(this.jsDataService.getDataRef(this.formStackService.currentData, this.prop));
    }

    public changeTypeaheadLoading(e: Boolean): void {
      this.typeaheadLoading = e;
    }

    
  public typeaheadNoResults(event: Boolean): void {
    this.noResult = event;
  }

}

