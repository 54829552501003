import { LpMeta, propertyMeta } from './meta';

export class LpCalculationMode extends LpMeta {
    @propertyMeta('string')
    public wording: String;
    @propertyMeta('boolean')
    public isActive: Boolean;
}


