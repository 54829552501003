import { Component, ElementRef, forwardRef, ViewChild } from '@angular/core';
import { ValueFieldComponent } from '../value-field.component';
import SignaturePad from 'signature_pad';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lp-signature-pad',
  templateUrl: './signature-pad-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SignaturePadFieldComponent),
      multi: true
    }
  ]
})
export class SignaturePadFieldComponent extends ValueFieldComponent {
  @ViewChild('sPad', { static: true }) canvasElement;
  @ViewChild('div') div : ElementRef;

  private signaturePad: SignaturePad;

  public ngAfterViewInit (): void {
    this.resizeCanvas()
    this.signaturePad = new SignaturePad(this.canvasElement.nativeElement);

    this.signaturePad.addEventListener("endStroke", () => {
      this.displayValue = this.signaturePad.toDataURL();
      super.pushChange();
    });
    
    window.addEventListener("resize", this.resizeCanvas);
  }
  public resizeCanvas() {
    this.canvasElement.nativeElement.height = 200;
    this.canvasElement.nativeElement.width = this.div.nativeElement.offsetWidth-20;    
  }

  public clearSignature(): void {
    this.signaturePad.clear();
    this.displayValue = "";
    super.pushChange();
  }
}
