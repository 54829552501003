<!--Switch box-->
<div *ngIf="setDefaultSwitchBar()" class="form-group">
  <lp-switch-box class="{{arrayClassesElements.get('defaultInput')}}" inputBox="tabSwitch" name="state"
    [values]="tabSwitch" switchName="state" [(ngModel)]="editionType" (change)="switch()" ignoreChange="true"></lp-switch-box>
</div>

<div class="list-group col-12 mt-3 mb-2">
  <div *ngFor="let line of data" class="list-group-item list-group-item-action cursor-pointer" (click)="selectItem(line, $event)">
    <span *ngFor="let column of metaData?.columns; let first = first;">
      <span *ngIf="!first"> - </span>
        <span *ngIf="column.type === 'datetime'" class="label-print-report">{{line[column.prop] | date: 'dd/MM/yyyy HH:mm':'+0'}}</span>
        <span *ngIf="column.type === 'date'" class="label-print-report">{{line[column.prop] | date: 'dd/MM/yyyy':'+0'}}</span>
        <span *ngIf="column.type === 'currency'" class="text-end label-print-report">{{line[column.prop] | number : '1.2-2'}} €</span>
        <span *ngIf="column.type !== 'date' && column.type !== 'datetime' && column.type !== 'currency'" class="label-print-report">{{line[column.prop]}}</span>
    </span>
    <span class="pull-right" *ngIf="editionType==='EDIT'">
      <lp-mail-report [isShortcut]="false" [reportId]="line.id" [arrayOfParameters]="null"></lp-mail-report>
    </span>
  </div>
</div>