import { Component, OnInit } from '@angular/core';
import { DetailFormsComponent, THIRD_PARTY_TYPE, CUSTOMER_TYPE, DRIVER_TYPE } from '../detail-forms.component';
import { LpThirdPartyType } from 'app/meta/third-party-type';
import { Util } from 'app/statics/utils';

@Component({
  selector: 'lp-third-party-type',
  templateUrl: './third-party-type.component.html'
})
export class ThirdPartyTypeComponent extends DetailFormsComponent implements OnInit {

  public data: LpThirdPartyType = null;

  public ngOnInit(): void {
      super.initData();
      if (Util.isNullOrUndefined(this.data)) {
        this.data = new LpThirdPartyType();
      }
      if (Util.isNullOrUndefined(this.formStackService.currentDetailLineData)) {
        this.formStackService.currentDetailLineData = new LpThirdPartyType();
      }
      this.data.assign(this.formStackService.currentDetailLineData);
      if (this.formStackService.currentApplicationItemDetail.key === THIRD_PARTY_TYPE) {
        this.setId(THIRD_PARTY_TYPE);
        this.object = 'thirdParty';
        this.verb = 'third-parties';
      } else if (this.formStackService.currentApplicationItemDetail.key === CUSTOMER_TYPE) {
        this.setId(CUSTOMER_TYPE);
        this.object = 'customer'
        this.verb = 'customers';
      } else {
        this.setId(DRIVER_TYPE);
        this.object = 'driver';
        this.verb = 'drivers';
      }
      this.initUrl();
      this.api = 'third-party-types';
    // this.init();
  }

  public init(): void {
    this.loadObjectRootFormSubscription = this.uiSyncService.loadObjectEvtEmitter.subscribe(() => {
      this.loadObjectRootFormSubscription.unsubscribe();
    });
  }
}
