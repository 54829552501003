<lp-top-banner *ngIf="uiSyncService.IsReadyToDisplayForm" [mainZoom]="!uiSyncService.IsKyForm && !uiSyncService.IsNewForm" lp-top-banner 
  titleTranslationKey="expenses.expenseReport"></lp-top-banner>
<div class="container-fluid container-fluid-2-cols" *ngIf="uiSyncService.IsReadyToDisplayForm && (uiSyncService.IsKyForm || uiSyncService.IsNewForm)" (dragover)="dragover($event)" (drop)="drop($event)">

  <!--  bloc permettant de faire un drag & drop pour modifier la taille des colonnes -->
  <div draggable="true" class="dragme" id="dragBlocResizeCol" data-item="0" [style.left]="dragLeft ? dragLeft+'px' : ''"
    (dblclick)="reInitCols()"></div>
  <div class="pageTransition" [class.show]="uiSyncService.showTransition"></div>

  <div class="container container-2-cols left" #containerCols>
    <div class="card-box col-md-6 label-2-colonnes" #col1>
      <form id="formulaire" *ngIf="data" #form="ngForm" (ngSubmit)="submit($event)" ngNativeValidate>
        <lp-common-tools *ngIf="formStackService.currentApplicationItem && formStackService.currentApplicationItem.linkedObject" [ky]="data.id" [positionFromRight]="dragRight ? dragRight : null" [deleteButton]="deleteButton"
           (showPrintOutput)="changeShowZoomPrint($event)" [showPrintInput]="showZoomPrint"></lp-common-tools>
           <div *ngIf="!formStackService.currentApplicationItem.useJsonEditForm">
        <lp-ui-accordion class="row" [menu]=true>

          <!-- Accordion General-->
          <lp-ui-accordion-group heading="general.other.general" icon="fa fa-info-circle" [open]=true key="general">
            <div class="container">

              <div class="row">
                <div class="form-group col-xl-6">
                  <lp-text-field id="id" className="textField" label="expenses.id"
                    class="{{arrayClassesElements.get('defaultInput')}}" cssClass="form-control" name="id"
                    [(ngModel)]="data.id" type="text"></lp-text-field>
                </div>
                <div class="form-group col-xl-6">
                  <!--Field state Expenses-->
                  <!--<lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" id="state" label="expenses.state"
                    cssClass="form-control" name="state" [(ngModel)]="data.state" [path]="{'verb': 'technical/ft', 'queryParams': [{'key':'object', 'value': 'various'}, {'key':'filter', 'value': 'NF'}]}"></lp-dropdown-field>-->
                    <lp-zoom-field zoomId="state" id="state" name="state" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" [(ngModel)]="data.state" label="expenses.state"> </lp-zoom-field>

                  </div>
              </div>

              <div class="row">
                <div class="form-group col-xl-6">
                  <!--Field date Expenses-->
                  <lp-date id="date" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" label="expenses.date"
                    name="date" [(ngModel)]="data.date"></lp-date>
                </div>
                <!--Field employee Expenses-->
                <div class="form-group col-xl-6">
                  <lp-zoom-field id="employee" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" 
                  cssClass="form-control" [(ngModel)]="data.employee" zoomId="employee" name="employee" label="expenses.employee"
                  ></lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-xl-6">
                  <!--Field wording Expenses-->
                  <lp-text-field id="wording" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="wording" [(ngModel)]="data.wording" label="expenses.wording"
                    type="text"></lp-text-field>
                </div>

                <div class="form-group col-xl-6">
                  <!--Field expenseCategory Expenses-->
                  <lp-zoom-field id="category" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" 
                    cssClass="form-control" [(ngModel)]="data.category" zoomId="category" name="category" label="expenses.category"></lp-zoom-field>
                </div>
              </div>

              <div class="row">
                <div class="form-group col-xl-6">
                  <!--Field payment Expenses-->
                  <!--<lp-dropdown-field class="{{arrayClassesElements.get('defaultInput')}}" className="textField" id="paymentMethod" label="expenses.paymentMethod"
                    cssClass="form-control" name="paymentMethod" [(ngModel)]="data.paymentMethod" [path]="{'verb': 'payment-methods'}"></lp-dropdown-field>-->
                  <lp-zoom-field id="paymentMethod" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" 
                    cssClass="form-control" [(ngModel)]="data.paymentMethod" zoomId="paymentMethod" name="paymentMethod" label="expenses.paymentMethod"></lp-zoom-field>
                </div>
                <div class="form-group col-xl-6">
                  <!--Field amount Expenses-->
                  <lp-currency id="amount" cssClass="form-control" class="{{arrayClassesElements.get('defaultInput')}}" label="expenses.amount"
                    name="amount" [(ngModel)]="data.amount"></lp-currency>
                </div>
              </div>
              <div class="row">
                <div class="form-group col-xl-6">
                  <!--Field currency Expenses-->
                  <lp-zoom-field id="currency" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField" 
                    cssClass="form-control" [(ngModel)]="data.currency" zoomId="currency" name="currency" label="expenses.currency"
                    ></lp-zoom-field>
                </div>
              </div>

            </div>
          </lp-ui-accordion-group>

          <!-- Accordion Client-->
          <lp-ui-accordion-group heading="general.other.customer" icon="fa fa-user" [open]=false key="client">
            <div class="container">

              <div class="row">
                <!--Field customer Expense-->
                <div class="form-group col-xl-6">
                      <lp-zoom-field id="customer" class="{{arrayClassesElements.get('defaultInput')}}" className="zoomField"  label="general.other.customer"
                      cssClass="form-control" [(ngModel)]="data.customer" name="customer" zoomId="customer" >
                    </lp-zoom-field>
                </div>
                <!--Field customerReference Expense-->
                <div class="form-group col-xl-6">
                  <lp-text-field id="customerReference" className="textField" class="{{arrayClassesElements.get('defaultInput')}}" label="expenses.customerReference"
                    cssClass="form-control" name="customerReference" [(ngModel)]="data.customerReference"
                    type="text"></lp-text-field>
                </div>
              </div>

              <div class="row">
                <!--Field billableSwitchBox Expense-->
                <div class="form-group col-xl-6">
                  <lp-boolean-switch-box name="billable" id="billable" switchName="billable" [(ngModel)]="data.billable" label="expenses.rebillable" class="{{arrayClassesElements.get('defaultInput')}}"></lp-boolean-switch-box>
                </div>
              </div>

            </div>
          </lp-ui-accordion-group>
        </lp-ui-accordion>
       </div>
        <lp-dynamic-main-form *ngIf="formStackService.currentApplicationItem.useJsonEditForm"></lp-dynamic-main-form>
      
      
        <lp-ui-accordion class="row" [menu]=true>

          <!--Accordion Justificatif-->
          <lp-ui-accordion-group  *ngIf="ky !== newdataKeyWord" heading="general.other.proofs" icon="fa fa-file-text-o" [open]=false key="justificatif">

            <div class="row" *ngIf="memos && memos.length > 0">
              <div class="form-group col-xl-6" *ngFor="let memo of memos">
                  <lp-textarea-field *ngIf="memoProvider.getAttachmentType(memo.file) === 'nofile'" id="comment-{{memo.id}}" className="textField" class="{{arrayClassesElements.get('defaultInput')}}"
                    cssClass="form-control" name="comment-{{memo.id}}" rows="6" [(ngModel)]="memo.txt" [readonly]="true" label=" "
                  type="text"></lp-textarea-field>

                  <img *ngIf="memoProvider.getAttachmentType(memo.file) === 'image'" class="cursor-zoom expenses-image" src="{{memo.file}}" (click)="uiSyncService.displayZoomImage(memo.file)" />
                  
                  
                  <lp-basic-file-field *ngIf="memoProvider.getAttachmentType(memo.file) === 'pdf' || memoProvider.getAttachmentType(memo.file) === 'other'" 
                    [(ngModel)]="memo.file" name="file" filename="memo" [readonly]="true"
                    cssDownloadButton="w-100" class="{{arrayClassesElements.get('defaultInput')}}"></lp-basic-file-field>
                </div>                    
            </div>

          </lp-ui-accordion-group>

          <!--Accordion Debugger-->
          <lp-ui-accordion-group heading="Debugger" *ngIf="debugg">
          <div>
              <pre> {{data | json}} </pre>
            </div>
          </lp-ui-accordion-group>

        </lp-ui-accordion>
      </form>
    </div>

    <div class="col-md-6 card-box" #col2>
      <lp-right-col></lp-right-col>
    </div>
  </div>
  <div class="clearfix"></div>
</div>
<div class="clearfix"></div>
