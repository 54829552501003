<div class="card form-card shadow">
    <h3 class="ps-2 pe-2 pb-2 border-bottom">{{title}}</h3>
    <div class="form-img form-img-{{class}}"></div>
    <p class="border-bottom p-2">{{description}}</p>
    <div class="text-right pt-2 pb-2">
      <lp-active *ngIf="displayActive && description !== 'link'" [readonly]="false" [ignoreChange]="true" [(ngModel)]="bindingToActive" class="lp-active" name="active_{{name}}" title="isActive" id="active_{{id}}" (click)="emitClickActive();event.stopPropagation();"></lp-active>
      <span class="btn btn-sm rounded-circle pull-right rounded-circle  bg-dark text-white me-2" (click)="emitClickPreview()">
        <i class="icon fa fa-eye"></i>
      </span>
    </div>
</div>